import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const toastrError:any;
@Injectable({
  providedIn: 'root'
})

export class LivlouderTask{
    serverUrl = environment.baseUrl;
    constructor(private http: HttpClient) { }

    //Get livlouder my tast list
    getTaskList(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}/job/task`, { headers: httpHeaders ,params:obj })
    }

  }