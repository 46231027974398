import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { AuthService } from 'src/app/services/auth.service';
import { GigService } from 'src/app/services/gig-service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';


declare var $:any
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-gig-payment-card',
  templateUrl: './gig-payment-card.component.html',
  styleUrls: ['./gig-payment-card.component.css']
})
export class GigPaymentCardComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  
  paymentForm: FormGroup;
  source;
  isOneTime:Boolean = false;
  gigId;
  title="Gig Payment";
  gigData;
  livloudInfo;
  cardLists =[];
  stripeCardValid: boolean = false;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontSize: '15px',
        '::placeholder': {
          color: '#ccc'
        }
      }
    }
  };

  get validForm() {
    return this.paymentForm.valid && this.stripeCardValid;
  }


  constructor(
    private payService:PaymentService,
    private toastrService:ToastrNotificationService,
    private router:Router,
    private authService:AuthService,
    private titleService:Title,
    private gigService:GigService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    ) { 
    this.gigId = this.router.getCurrentNavigation()
    .extras.state.gigId;

    this.livloudInfo = this.router.getCurrentNavigation()
    .extras.state.data;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.authService.setUserMetaId(2);
    this.gigDetail(this.gigId);
    this.cardList();

    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  cardList(){

    showLoader();
    let stripeCustId = this.payService.getStripeCusID();

    this.payService.getCardList(stripeCustId).subscribe(res=>{
      hideLoader();
    
      this.cardLists = res.data;
    },err=>{
      this.toastrService.showError(err.error.message,'Error');
      console.log(err.error.message);
    })
  
  }


  gigDetail(gigId){
    showLoader();
    var metaId = this.authService.getUserMetaId();
    this.gigService.gigDetail(gigId,metaId).subscribe(res=>{
      hideLoader();
      if(res.status == 'success'){
        this.gigData = res.data;
      }
      
    },err=>{
      hideLoader();
      console.log(err);
    })
  }


  /**
   * confirm payment for added card
   */
  confirmPayment(){
    this.source = $('input[name="radio"]:checked').val();
    $('#confirmModal').modal('show');
  }

  


 
  /**
   * Add stripe card for payment
   */
  buy() {
   
    
    showLoader();

    this.stripeService
      .createToken(this.card.element, { name: this.paymentForm.value.name })
      .subscribe(result => {
        if (result.token) {
         
          //if one time payment than execute this block of code
          if(this.isOneTime){
            hideLoader();
            this.card.element.clear();

            $('#addCard').modal('hide');
            this.source = result.token.id;
           
            $('#confirmModal').modal('show');
          }else{
            $('#addCard').modal('hide');

            this.addCard(result.token.id,result.token.card.id)
          }
        } else if (result.error) {
          hideLoader();
          this.toastrService.showError(result.error.message,'Error')
        }
      });
  }

  /**
   * Click one time pay button
   */
  // clickOneTime(){
  //  this.isOneTime = true; 
  //  $('#addCard').modal('show');

  // }

  clickOneTime(){

    this.card.element.clear();
    this.isOneTime = true; 
    $('#addCard').modal('show');
  }
 
  addCard(tokenId,customerId){

    var formData = new FormData();
    formData.append('sourceId',tokenId)
    formData.append('stripeCustomerId',this.payService.getStripeCusID());

    this.payService.addCard(formData).subscribe(res=>{
      hideLoader();
      this.cardList();
      if (res.status == 'fail') {
        this.toastrService.showError(res.message, 'Error');
      }else {
        this.toastrService.showSuccess(res.message,'Success');
      }
    },err=>{
      console.log(err.error.message);
    })
  }

  // checkCheckBoxvalue(event,idd){
  //   console.log('idd',idd);
  //   console.log(event.target)
  // }
  // $(".devCheckedRadio").click(function () {
  //   var card_id = $("input[name='radio']:checked").val();
  // }


   /**
   * make payment to livlouder
   */
    pay(){
     
      var metaId = this.authService.getUserMetaId();
      var formData = new FormData;
      formData.append('metaId',this.livloudInfo.userMetaId);
      formData.append('gigId',this.gigId);
      formData.append('source',this.source);
      formData.append('amount',this.livloudInfo.price);
      formData.append('stripeCustomerId',this.payService.getStripeCusID());
      formData.append('stripeAccountId',this.payService.getStripeAccountID());
      formData.append('gigFor',this.gigData.gigFor);
      if(this.isOneTime){
        formData.append('isOneTimePay','1');
      }else{
        formData.append('isOneTimePay','2');
      }
     
  
      showLoader();
      this.gigService.acceptOffer(formData).subscribe(res=>{
        hideLoader()
        


       if(res.status == 'success'){
        
        this.toastrService.showSuccess(res.message,'Success')
  
        this.router.navigate(['myGigsHirer/detail',this.gigId])
       }else{

        this.toastrService.showError(res.message,'Error')
       }
      },err=>{
        hideLoader()
        this.toastrService.showError('Something Went Wrong...','Error')
      })
      
      //routerLink="/myGigsDetailsAssignedHirer"
    }
  

  closeModal(){
    this.isOneTime = false;
    $('#addCard').modal('hide');
    this.card.element.clear();
  }
}
