<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <!-- <label>
                            <input type="text" class="form-control" placeholder="Search Groups...">
                            <span class="fa fa-search"></span>
                        </label> -->
                    </div>
                </div>
                <div class="ml-auto">
                    <div class="filterIconItem gigBtn">
                        <button routerLink="/groups/createGroup" class="btn thm-btn" type="button">
                            Create New Group
                        </button>
                    </div>
                </div>
            </div>
            <div class="discoverList groupList mt-30">
                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/no_group.png">
                            <h2>Create a group and invite livlouders!</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
               
                     
                    <ng-container  *ngFor="let groupList of groupList" >
                    <div class="col-md-4 col-lg-3 col-6"  >
                        <div class="discoverItem">
                            <div class="disArtistImg">
                                <a [routerLink]="['/groups/details/',groupList.userMetaId]"><img [src]= groupList.profileImage></a>
                            </div>
                            <div class="disArtistInfo">
                                <a routerLink="/groups/details/"><h2 class="textEllipsis">{{groupList.groupName}}</h2></a>
                                <h5 *ngIf="groupList.totalMember == 1" class="textEllipsis">{{groupList.totalMember}} (Member)</h5>
                                <h5 *ngIf="groupList.totalMember != 1" class="textEllipsis">{{groupList.totalMember}} (Members)</h5>

                            </div>
                        </div>
                    </div>
                </ng-container>
                    
                </div>
            </div>
            <!-- <div class="text-center">
                <button class="btn btn-outline-info">Load More</button>
            </div> -->
        </div>
    </section>
</div>
<app-footer-livlouder></app-footer-livlouder>