import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupService } from '../../services/signup.service';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from '../../services/local-storage.service'
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { AuthService } from '../../services/auth.service';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { environment } from "../../../environments/environment";
// import * as $ from 'jquery';
declare var $: any;


declare const showLoader: any;
declare const hideLoader: any;
declare const google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  reactiveForm: FormGroup;
  forgotPwdForm: FormGroup;

  returnData: any;
  error: any;
  show: boolean = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  client: any;
  accessToken: string = '';
  googleClientId = environment.googleClientId;
  constructor(
    private signupService: SignupService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastService: ToastrNotificationService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.initClient();
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.phoneNumberVerfied != 1) {
      localStorage.setItem('currentUser', null);
      this.router.navigate(['/login']);
    }else {
      if (this.authService.isLoggedIn() == true) {

        if (this.authService.getUserRole() == 'legalName') {
          this.router.navigate(['/basicInfo']);
        } else {
          this.router.navigate(['/userType']);

        }
        //   switch(this.authService.getUserRole()) {
        //     case 'legalName': { 
        //       this.router.navigate(['/basicInfo']);
        //       break;
        //     } 
        //     case 'completeProfile': { 
        //       this.router.navigate(['/complete-profile']);
        //       break;
        //     } 
        //     case 'selectProfile': { 
        //       this.router.navigate(['/select-profile']);
        //       break;
        //     }
        //     case 'Hirer': { 
        //       this.router.navigate(['/hirer/gigs']);
        //       break;
        //     } 
        //     case 'Livlouder': { 
        //       this.router.navigate(['livlouder/gigs']);
        //       break;
        //     } 
        //  }
      }

      this.titleService.setTitle("Livloud");

      this.reactiveForm = new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'password': new FormControl(null, Validators.required),
        'deviceToken': new FormControl('asdffasfdsf'),
      })



      this.forgotPwdForm = new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),


      })
    }
  }


    initClient(): void {
        if (!google || !google.accounts || !google.accounts.oauth2) {
            console.error('Google Identity Services library is not loaded');return;
        }else {
            this.client = google.accounts.oauth2.initTokenClient({
                client_id: this.googleClientId,
                scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                // scope: 'openid profile email',
                callback: (tokenResponse: any) => {
                    if (!tokenResponse || !tokenResponse.access_token) {
                        console.error('Failed to retrieve access token:', tokenResponse);return;
                    }
                    this.accessToken = tokenResponse?.access_token;

                    const formData = new FormData();
                    formData.append('accessToken', this.accessToken);
                    formData.append('socialType', '2');
                    formData.append('deviceToken', new Date().toISOString());

                    this.signupService.checkSocialLogin(formData).subscribe(data => {

                        if (data.social_status == 1) {
                            this.filterFunction(data);
                        } else {
                            this.socialLogin(data.userDetail, '2');
                        }
                    }, err => {
                        console.error('Failed to check social login', tokenResponse);return;
                    });
                }
            });
        }
    }
  
    loginWithgoogle() {
        if (!this.client) {
            console.error('GIS client not initialized.');return;
        }
        
        this.client.requestAccessToken();
    }

//   loginWithgoogle() {
//     this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
//       console.log("GoogleLoginProvider- ",res)

//       const formData = new FormData();
//       formData.append('socialId', res.id);
//       //formData.append('socialId', '2323232321');

//       formData.append('socialType', '2');
//       formData.append('email', res.email);
//       formData.append('deviceToken', new Date().toISOString());

//       this.signupService.checkSocialLogin(formData).subscribe(data => {

//         if (data.social_status == 1) {

//           this.filterFunction(data);
//         } else {
//           this.socialLogin(res, '2');
//         }
//       }, err => {
//         console.log(err);

//       });


//       // this.socialLogin(res,'1');
//     }).catch(error => {
//       console.log('Error to login by google- ', error);
//     });
//   }
  loginWithFacebook(): void {

    // this.signupService.checkSocialLogin()

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {

      const formData = new FormData();
      formData.append('socialId', res.id);
      //formData.append('socialId', '2323232321');

      formData.append('socialType', '1');
      formData.append('email', res.email);
      formData.append('deviceToken', new Date().toISOString());

      this.signupService.checkSocialLogin(formData).subscribe(data => {

        if (data.social_status == 1) {

          this.filterFunction(data);
        } else {
          this.socialLogin(res, '1');
        }
      }, err => {
        console.log(err);

      });


      // this.socialLogin(res,'1');
    }).catch(error => {
      console.log('Error', error);
    });
    // api_v1/service/social
    //email ,socialId, socialType, socialType
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  socialLogin(socialData, socialType) {

    if (socialData) {
      showLoader();

      // this.submitted = true;
      const formData = new FormData();
      formData.append('socialId', socialData.id);
      formData.append('socialType', socialType);
      formData.append('email', socialData.email);

    //   formData.append('userType', '1');
    //   formData.append('deviceType', '1');
      formData.append('deviceToken', new Date().toISOString());

      this.signupService.socialSignup(formData).subscribe((data) => {
        hideLoader();

        // this.toastService.showSuccess(data.message, "Success");
        this.filterFunction(data)
      }, err => {
        hideLoader();
        if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
          this.authService.checkValidToken(err.error);
          this.toastService.showError('Session Expired', 'Error');

        } else {
          this.toastService.showError(err.error.message, 'Error');
        }
      });

    }

  }
  password() {
    this.show = !this.show;
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  onSubmit() {
    if (!this.reactiveForm.valid) {

      this.validateAllFormFields(this.reactiveForm);

      this.toastService.showError("Please fill required field before procceding", 'Error');
      return false;
    }

    showLoader();

    const formData = new FormData();
    const deviceToken = localStorage.getItem("device-token");
    formData.append('email', this.reactiveForm.value.email)
    formData.append('password', this.reactiveForm.value.password);
    formData.append('deviceToken', deviceToken);

    this.signupService.login(formData).subscribe((data) => {
      hideLoader();
      this.returnData = data;
      this.filterFunction(data)
      //this.localStorageService.set('userDetail',JSON.stringify())
    }, error => {
      hideLoader();

      this.toastService.showError(error.error.message, "Error");
      this.error = error.message;
    });
  }


  forgotPass() {
    if (!this.forgotPwdForm.valid) {
      this.validateAllFormFields(this.forgotPwdForm);

      this.toastService.showError("Please fill required field before procceding", 'Error');
      return false;
    }
    showLoader();

    const formData = new FormData();
    formData.append('email', this.forgotPwdForm.value.email)

    this.signupService.forgotPassword(formData).subscribe((data) => {

      hideLoader();
      this.returnData = data;
      this.toastService.showSuccess(data.message, "Success");

      // $('#floatingInput1').val('');
      this.forgotPwdForm.reset();
      $('#forgotPWD').modal('hide');
      //this.localStorageService.set('userDetail',JSON.stringify())
    }, error => {
      hideLoader();

      this.toastService.showError(error.error.message, "Error");
      this.error = error.message;
    });
  }

  filterFunction(data: any) {

    if (data.status == 'fail') {
      this.toastService.showError(data.message, "Error");
      return false;
    } else {
      this.toastService.showSuccess(data.message, "Success");
      localStorage.setItem('currentUser', JSON.stringify(data.userDetail));

      if (this.authService.getUserRole() == 'legalName' || data.userDetail.phoneNumberVerfied == 0) {

        this.router.navigate(['/basicInfo']);
        return;
      }
      this.router.navigate(['/userType']);
    }
  }

  continueGuest() {
    // alert('hiiiii');
    this.router.navigate(['/gigs']);

    // OpenAcessGig

  }
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
}
