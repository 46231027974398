<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="discoverDetails mainContent" *ngIf="data">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                    <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar >
                            <div class="discoverItem sidebar__inner">
                                <div class="disArtistImg">
                                    <a href="javascript:void(0);"><img [src]="data.profileImage"></a>
                                </div>
                                <div class="disArtistInfo">
                                    <a href="javascript:void(0);"><h2 class="">{{data.liveLouderName}}</h2></a>
                                    <div class="livLouderTypes">
                                        <h5>{{data.type}}</h5>
                                        <p class="mb-2"><span>{{data.subType}}</span></p>
                                    </div>
                                    <p class="loc"><i class="fa fa-map-marker-alt"></i> <span class="">{{data.location}}</span></p>
                                    <div class="ratePriceBlk">
                                        <div class="rateBlk">
                                            <p><span class="fa fa-star"></span>{{data.rating}}</p><span class="totalReeview">(652)</span>
                                        </div>
                                        <div class="priceBlk">
                                            <p>{{data.currency}}{{data.price}}/<span>Hr</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="btnActGroup">
                                    <!-- <div class="btnActItem bookMeBtn">
                                        <button data-toggle="modal" data-target="#bookMe" class="btn thm-btn btn-block">Invite to Group</button>
                                    </div> -->
                                </div>
                                <div class="availability">
                                    <h2>Availability</h2>
                                    <p>{{data.availability}}</p>
                                </div>
                                <div *ngIf="data.social_media.length != 0" class="socialLinkAdded">
                                    <ul *ngFor="let socialLink of data.social_media">
                                         
                                        <li *ngIf="socialLink.type == 'facebook'" class="active"><a [href]="socialLink.socialLink" target="_blank"><span class="fab fa-facebook-square"></span> Facebook</a></li>
                                        <li *ngIf="socialLink.type == 'twitter'" class="active"><a [href]="socialLink.socialLink" target="_blank"><span class="fab fa-twitter-square"></span> Twitter</a></li>
                                        <li *ngIf="socialLink.type == 'instagram'" class="active"><a [href]="socialLink.socialLink" target="_blank"><span class="fab fa-instagram"></span> Instagram</a></li>
                                 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">{{data.about}}</p>
                                </div>
                               
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Experience</h2>
                                    <div *ngIf="experience.length == 0">Not available</div>

                                    <ul class="infoList" *ngFor="let ex of experience">
                                        <li>{{ex}}</li>
                                      
                                    </ul>
                                </div>
                               
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div *ngIf="data.image_media.data.length == 0 ">Not available</div>

                                    <div id="imageGallery" class="galleryImgAdd galleryProfile" *ngIf="data.image_media.data.length > 0">
                                        <ng-container *ngFor="let imgData of data.image_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{imgData.image}}">
                                                    <img [src]="imgData.thumbImage">
                                                </div>
                                            </div>
                                        </ng-container>
                                       
                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Videos</h2>
                                    <div *ngIf="data.video_media.data.length == 0">Not available</div>

                                        <ng-container *ngFor="let video of data.video_media.data">
                                            <div style="display:none;" [id]="video.id">
                                                <video class="lg-video-object lg-html5" controls preload="none">
                                                    <source [src]="video.video" type="video/mp4">
                                                    Your browser does not support HTML5 video.
                                                </video>
                                            </div>
                                        </ng-container>
                                    <div id="galleryVideos" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor="let video of data.video_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-poster="{{video.videoImageThumb}}" attr.data-html="{{'#'+video.id}}">
                                                    <img [src]="video.videoImageThumb">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30" *ngIf="data.rating_review.count > 0">
                            <div class="userInfoItem" >
                                <h2 class="infoHead">Reviews <span class="NoCount">({{data.rating_review.count}})</span></h2>
                                <div class="reviewsList">
                                    <ng-container *ngFor="let review of data.rating_review.data">
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img [src]="review.profileImage">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>{{review.fullName}}</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>{{review.agotime}}</p>
                                                </div>
                                            </div>
                                            <div class="ratingView ml-auto">
                                                <div class="ratingBlk">
                                                    <i [class.filled]="review.rating >= '1'" class="fas fa-star"></i>
                                                    <i [class.filled]="review.rating >= '2'" class="fas fa-star"></i>
                                                    <i [class.filled]="review.rating >= '3'" class="fas fa-star"></i>
                                                    <i [class.filled]="review.rating >= '4'" class="fas fa-star"></i>
                                                    <i [class.filled]="review.rating >= '5'" class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p *ngIf="review.review != 'null' && review.review != ''">{{review.review}}</p>
                                        </div>
                                    </div>
                                    </ng-container>
                                    <!-- <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/3.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Edmund Richards</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>5 min ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView ml-auto">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                    </div> -->
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="bookMe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Choose an group to invite the livlouder for</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="invitedMemberList GigListBooking modalListScroll">
            <div class="GigListBookingNoInfo mb-4">
                <div class="noDataView">
                    <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                    <p>You currently have no groups. <br/> Please create an group first.</p>
                </div>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/8.png">
                        </div>
                        <div class="membName">
                            <h2>DJ The Great and Terrible</h2>
                            <p>10 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/9.png">
                        </div>
                        <div class="membName">
                            <h2>Blue in the Face</h2>
                            <p>15 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/5.png">
                        </div>
                        <div class="membName">
                            <h2>On a Sensual Note</h2>
                            <p>5 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/6.png">
                        </div>
                        <div class="membName">
                            <h2>DJ The Great and Terrible</h2>
                            <p>12 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/8.png">
                        </div>
                        <div class="membName">
                            <h2>Blue in the Face</h2>
                            <p>10 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/9.png">
                        </div>
                        <div class="membName">
                            <h2>On a Sensual Note</h2>
                            <p>15 Members</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <a routerLink="/groups/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
        <button type="button" class="btn thm-btn">Apply</button>
      </div>
    </div>
  </div>
</div>
<app-footer-livlouder></app-footer-livlouder>