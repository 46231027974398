import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const toastrError:any;
@Injectable({
  providedIn: 'root'
})

export class LivlouderTaskDetail{
    serverUrl = environment.baseUrl;
    constructor(private http: HttpClient) { }

    //Get hirer all tast list
    getTaskDetail(gigId){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      const params = new HttpParams().set('gigId', gigId);
      return this.http.get<any>(`${this.serverUrl}/job/detail`, { headers: httpHeaders ,params:params })
    }
  }