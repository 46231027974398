import { Component, OnInit } from '@angular/core';
import { LivlouderTaskDetail } from 'src/app/services/livlouder-task-detail';

declare var $: any

@Component({
  selector: 'app-my-gig-assign-details-livlouder',
  templateUrl: './my-gig-assign-details-livlouder.component.html',
  styleUrls: ['./my-gig-assign-details-livlouder.component.css']
})
export class MyGigAssignDetailsLivlouderComponent implements OnInit {
  show = false;
  public show_dialog: boolean = false;

  metaId;
  gigId;
  taskListDetail;
  mustHave;

  constructor(

    // private authService: AuthService,
    private LivlouderTaskDetail: LivlouderTaskDetail
  ) { }

  ngOnInit(): void {

    $('#imageGallery').lightGallery({
      thumbnail: true,
      selector: '.addedImg',
      download: false
    });
    $('.modal').on('hidden.bs.modal', function () {
      if ($(".modal:visible").length > 0) {
        $('body').addClass('modal-open');
      } else {
        $('body').removeClass('modal-open');
      }
    });

    this.gigId = location.href.match(/([^\/]*)\/*$/)[1];
    this.LivlouderTaskDetail.getTaskDetail(this.gigId).subscribe((data) => {

      this.taskListDetail = data.data;
      this.mustHave = this.taskListDetail.mustHave;
    });
  }
  toggle() {
    this.show_dialog = !this.show_dialog;
  }

}
