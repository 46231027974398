<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="form-wizard" id="msform">
                <form>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-wizard-steps WizardSteps">
                           
                            <div class="form-wizard-step" [ngClass]="{'active': stepNo === 2}">
                                <div class="media">
                                    <div class="bd-wizard-step-icon">
                                        <i class="fa fa-user"></i>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="bd-wizard-step-title">Basic Info</h6>
                                        <p class="bd-wizard-step-subtitle">Enter some basic info about your profile below</p>
                                    </div>
                                </div>
                            </div>
                           
                            <!-- <div class="form-wizard-step" [ngClass]="{'active': stepNo === 3}">
                                <div class="media">
                                    <div class="bd-wizard-step-icon">
                                        <i class="fa fa-info-circle"></i>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="bd-wizard-step-title">More Info</h6>
                                        <p class="bd-wizard-step-subtitle">Write a description about what is your profile are and what you do</p>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="form-wizard-step" [ngClass]="{'active': stepNo === 4}">
                                <div class="media">
                                    <div class="bd-wizard-step-icon">
                                        <i class="fa fa-images"></i>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="bd-wizard-step-title">Upload Photos</h6>
                                        <p class="bd-wizard-step-subtitle">Share some fun photos of you doing gigs or just your doing you!</p>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="form-wizard-step" [ngClass]="{'active': stepNo === 5}">
                                <div class="media">
                                    <div class="bd-wizard-step-icon">
                                        <i class="fa fa-video"></i>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="bd-wizard-step-title">Upload Videos</h6>
                                        <p class="bd-wizard-step-subtitle">Share some fun videos of you doing gigs or just your doing you!</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Step 4 -->
                            <!-- Step 4 -->
                            <!-- <div class="form-wizard-step" [ngClass]="{'active': stepNo === 6}">
                                <div class="media">
                                    <div class="bd-wizard-step-icon">
                                        <i class="fa fa-share-square"></i>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="bd-wizard-step-title">Social Media Links</h6>
                                        <p class="bd-wizard-step-subtitle">Share your social links for your livlouder profile so hirers can get to know you better!</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Step 4 -->
                        </div>
                    </div>
                    <div class="col-lg-8">
                       
                        <fieldset style="display:block;" *ngIf="stepNo == 2">
                            <form [formGroup]="basicInfoFrom" (ngSubmit)="stepOne($event)">
                            <div class="boxView">
                                <div class="boxViewForm">
                                    <div class="">
                                        <div class="text-center">
                                            <div class="upload-pic mb-5">
                                                <img src="{{profileImage}}" id="pImg">
                                                <!-- <img  [src]="data.profileImage" id="pImg"> -->

                                                <div class="text-center upload-icon">
                                                    <input accept="image/*" class="inputfile hideDiv" id="file-2" (change)="onFileChange($event)" onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" style="display: none;" type="file">
                                                    <label for="file-2" class="upload_pic">
                                                        <span class="fas fa-camera"></span>
                                                    </label>
                                                    <!-- <small class="text-danger" *ngIf="basicInfoFrom.get('profileImage').touched && basicInfoFrom.get('profileImage').errors.required">
                                                        Profile image is required field.
                                                    </small> -->
                                                </div>
                                                
                                            </div>
                                            <!-- <small class="text-danger" *ngIf="basicInfoFrom.get('profileImage').touched && basicInfoFrom.get('profileImage').invalid">
                                                Image is required field.
                                            </small> -->
                                        </div>
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" formControlName="name" id="floatingInput" placeholder="Enter Name">
                                            <label for="floatingInput">Name <span class="reqField">*</span></label>
                                            <small class="text-danger" *ngIf="basicInfoFrom.get('name').touched && basicInfoFrom.get('name').invalid">
                                              <span *ngIf="basicInfoFrom.get('name').hasError('required')" > Name is required field.</span>
                                              <span *ngIf="!basicInfoFrom.get('name').hasError('maxlength') && basicInfoFrom.get('name').hasError('noWhitespace')">
                                                Required field.</span>
                                              <span *ngIf="basicInfoFrom.get('name').hasError('maxlength')" >Please enter no more than 30 characters.</span>
                                            </small>
                                        </div>
                                        <!-- <div class="form-floating mb-3">
                                            <input type="text" class="form-control" formControlName="venue" id="floatingInput" placeholder="Enter Name">
                                            <label for="floatingInput">Venue Name <span class="reqField">*</span></label>
                                            <small class="text-danger" *ngIf="basicInfoFrom.get('venue').touched && basicInfoFrom.get('venue').invalid">
                                                Venue name is required field.
                                            </small>
                                        </div> -->
                                        <div class="form-floating mb-3">
                                            <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event);" type="text" class="form-control" formControlName="location" id="floatingInput" placeholder="Enter Location">
                                            <label for="floatingInput">Location <span class="reqField">*</span></label>
                                            <small class="text-danger" *ngIf="basicInfoFrom.get('location').touched && basicInfoFrom.get('location').invalid">
                                                Location is required field.
                                            </small>
                                        </div>
                                        <div class="form-floating mb-3">
                                            <textarea rows="4" type="text" class="form-control textArea" formControlName="about" id="floatingInput" placeholder="Write here..."></textarea>
                                            <label for="floatingInput">Write Short About Venue</label>
                                            <small class="text-danger" *ngIf="basicInfoFrom.get('about').hasError('maxlength')">
                                                Please enter no more than 300 characters.
                                            </small>
                                            <small class="text-danger"
                                                    *ngIf="basicInfoFrom.get('about').touched && basicInfoFrom.get('about').hasError('noWhitespace') && !basicInfoFrom.get('about').hasError('minlength')">
                                                    Required field.
                                            </small>
                                            <small class="text-danger" *ngIf="basicInfoFrom.get('about').hasError('minlength')">
                                                Please enter at least 5 characters.
                                            </small>
                                        </div>
                                        <!-- <div class="form-floating mb-3">
                                            <select type="text" class="form-control" id="floatingInput" formControlName="businessType" placeholder="Enter Location">
                                               
                                                <option value="btype.id"  *ngFor="let btype of businessType">{{btype.businessType}}</option>
                                               
                                            </select>
                                            <label for="floatingInput">Business Type <span class="reqField">*</span></label>
                                        </div> -->
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <a routerLink="/profileHirer" class="btn thm-btnGray">Cancel</a>
                                        &nbsp;
                                        <button type="submit" class="btn thm-btn">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </fieldset>
                        <!-- <fieldset style="display:block;" *ngIf="stepNo == 3">
                            <form [formGroup]="completeStepTwo" (ngSubmit)="stepTwo();">
                            <div class="boxView">
                                <div class="boxViewForm">
                                    <div class="">
                                        <div class="form-floating mb-3">
                                            <textarea rows="4" type="text" class="form-control textArea" formControlName="about" id="floatingInput" placeholder="Write here..."></textarea>
                                            <label for="floatingInput">Write Short About Venue <span class="reqField">*</span></label>
                                        </div>
                                        <div class="csLabelBox mb-3">
                                            <h2>Opening Hours</h2>
                                            <div class="addMoreBlk" formArrayName="openingHour">
                                                <ng-container *ngFor="let ex of completeStepTwo.get('openingHour').controls; index as i">
                                                <div class="input-group rightIcon">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control phone" formControlName="{{i}}" id="floatingInput" placeholder="Enter...">
                                                        <label for="floatingInput">Enter <span class="reqField">*</span></label>
                                                    </div>
                                                    <div class="removeField" *ngIf="i > 0" (click)="removeOpeningHour(i);">
                                                        <span class="fa fa-times"></span>
                                                    </div>
                                                </div>
                                                </ng-container>
                                               
                                            </div>
                                            <div class="textLink text-right">
                                                <a href="javascript:void(0);" (click)="addOpeningHour();">Add More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <a href="javascript:void(0);" class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp;
                                        <button type="submit" class="btn thm-btn btn-next">Continue</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </fieldset>
                        <fieldset style="display:block !important;"  *ngIf="stepNo == 4">
                            <form [formGroup]="uploadImageForm" (ngSubmit)="UploadImgStepThree();">
                            <div class="boxView">
                                <div class="boxViewForm1">
                                    <div class="setupPrHead">
                                        <h2>Add Photos</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    <div class="">
                                        <div class="galleryImgAdd" >
                                            <div class="log_div gallery-img text-center mt-30">
                                                <label class="uploadLabel">
                                                    <input accept="images/*" formControlName="file" class="inputfile hideDiv" (change)="onUploadFile($event);" id="file-2" name="profileImage" onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" style="display: none;" type="file">
                                                   <img src="assets/img/camera_placeholder.png" id="pImg">
                                                </label>
                                            </div>
                                           
                                          
                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <a href="javascript:void(0);" class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp;
                                        <button type="submit" class="btn thm-btn btn-next">{{photoSkip == 1 ? 'Skip' : 'Next' }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 5">
                            <div class="boxView">
                                <div class="boxViewForm1">
                                    <div class="setupPrHead">
                                        <h2>Add Videos</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    <div class="">
                                        <div class="galleryImgAdd">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <label class="uploadLabel">
                                                    <input accept="video/*" class="inputfile hideDiv" id="file-2" name="profileImage" (change)="onSelectedVideo($event)" onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" style="display: none;" type="file">
                                                    <img src="assets/img/video_placeholder.png" id="pImg">
                                                </label>
                                            </div>
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg">
                                                    <img src="assets/img/1.jpg">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                        <label class="upload_picNew">
                                                            <span data-toggle="tooltip" data-placement="top" title="Remove Image" class="fas fa-times"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <a href="javascript:void(0);" class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp;
                                        <a href="javascript:void(0);" class="btn thm-btn btn-next">Continue</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 6">
                            <form [formGroup]="socialLinksForm" (ngSubmit)="uploadLinks();">
                            <div class="boxView">
                                <div class="boxViewForm">
                                    <div class="">
                                        <div class="csLabelBox mb-3">
                                            <h2>Add Social Media</h2>
                                            <div class="addMoreBlk" formArrayName="links">
                                                <ng-container *ngFor="let ex of socialLinksForm.get('links').controls; index as i">
                                                <div class="socialBlk">
                                                    <div class="socialType">
                                                        <div class="dropdown">
                                                            <button data-toggle="tooltip" data-placement="top" title="Social Type" type="button" class="form-control dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fab fa-facebook-square"></i></button>
                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" href="#"><i class="fab fa-facebook-square"></i> Facebook</a>
                                                                <a class="dropdown-item" href="#"><i class="fab fa-twitter-square"></i> Twitter</a>
                                                                <a class="dropdown-item" href="#"><i class="fab fa-instagram"></i> Instagram</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="input-group rightIcon">
                                                        <div class="form-floating">
                                                            <input type="text" class="form-control phone"  formControlName="{{i}}" (keyup)="changeLinks($event)" id="floatingInput" placeholder="Enter...">
                                                            <label for="floatingInput">Enter Social Link</label>
                                                        </div>
                                                        <div class="removeField" *ngIf="i > 0" (click)="removeLinks(i);">
                                                            <span class="fa fa-times"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                </ng-container>
                                               
                                            </div>
                                            <div class="textLink text-right">
                                                <a href="javascript:void(0);" (click)="addLinks();">Add More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <a href="javascript:void(0);" class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp;
                                        <button type="submit" class="btn thm-btn btn-next">{{skipLinks == true ? 'Skip' : 'Next' }}</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </fieldset> -->
                    </div>
                </div>
                </form>
            </div>
        </div>
    </section>
</div>
<app-footer-hirer></app-footer-hirer>