import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const toastrError:any;
@Injectable({
  providedIn: 'root'
})

export class GroupRequest{
    serverUrl = environment.baseUrl;
    constructor(private http: HttpClient) { }

    //Get livlouder my tast list
    getgroupRequest(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}group/group_request`, { headers: httpHeaders ,params:obj })
    }

    //Get Book Me Detail
    getGroupDetail(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      let param = new HttpParams() .set('metaId', obj);
      return this.http.get<any>(`${this.serverUrl}group/detail`, { headers: httpHeaders ,params:param })
    }
    
    acceptRejectRequest(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/accept_reject_request`, formData, { headers: httpHeaders })
    }

    //Get livlouder my tast list
    getgroupList(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}group/group`, { headers: httpHeaders ,params:obj })
    }

    
    deleteGroup(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/remove_group`,formData, { headers: httpHeaders })

    }
    leaveGroup(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/leave_group`,formData, { headers: httpHeaders })

    }


    removeGroupMember(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/remove_member`,formData, { headers: httpHeaders })

    }

    stepOne(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/basic_info_one`,formData, { headers: httpHeaders })

    }

    stepTwo(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.post<any>(`${this.serverUrl}/group/basic_info_two`,formData, { headers: httpHeaders })

    }


    //Get invited user list 
    invitedMember(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}group/invited_members`, { headers: httpHeaders ,params:obj })
    }
      //  member list for send invitation 
      memberList(obj){
        const httpHeaders = new HttpHeaders({
          'authorization': 'asdffdsafdfdsfsdf',
          'Device-Id': '23232332',
          'Device-Type': '3',
          'Device-Timezone': 'UTC'
        })
  
        return this.http.get<any>(`${this.serverUrl}group/members`, { headers: httpHeaders ,params:obj })
      }

      // send Invitation request 
      sendRequest(formData){
        const httpHeaders = new HttpHeaders({
          'authorization': 'asdffdsafdfdsfsdf',
          'Device-Id': '23232332',
          'Device-Type': '3',
          'Device-Timezone': 'UTC'
        })
  
        return this.http.post<any>(`${this.serverUrl}/group/invitation`,formData, { headers: httpHeaders })
  
      }
      // send Invitation request 
      cancelRequest(formData){
        const httpHeaders = new HttpHeaders({
          'authorization': 'asdffdsafdfdsfsdf',
          'Device-Id': '23232332',
          'Device-Type': '3',
          'Device-Timezone': 'UTC'
        })
  
        return this.http.post<any>(`${this.serverUrl}/group/cancel_invitation`,formData, { headers: httpHeaders })
  
      }

    //Get group info step 1
    getStepOne(obj){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      let param = new HttpParams() .set('metaId', obj);
      return this.http.get<any>(`${this.serverUrl}group/step_one_info`, { headers: httpHeaders ,params:param })
    }


        // Get group info step 2
        getStepTwo(obj){
          const httpHeaders = new HttpHeaders({
            'authorization': 'asdffdsafdfdsfsdf',
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC'
          })
          let param = new HttpParams() .set('metaId', obj);
          return this.http.get<any>(`${this.serverUrl}group/step_two_info`, { headers: httpHeaders ,params:param })
        }

        // Get group info step 3 and 4 
        getMedia(obj, type){
          const httpHeaders = new HttpHeaders({
            'authorization': 'asdffdsafdfdsfsdf',
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC'
          })
          let param = new HttpParams() .set('metaId', obj) . set('type', type);


          return this.http.get<any>(`${this.serverUrl}group/media`, { headers: httpHeaders ,params:param })
        }


         // Get  Social Media 
         getSocialLink(obj){
          const httpHeaders = new HttpHeaders({
            'authorization': 'asdffdsafdfdsfsdf',
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC'
          })
          let param = new HttpParams() .set('metaId', obj);


          return this.http.get<any>(`${this.serverUrl}group/social_media`, { headers: httpHeaders ,params:param })
        }
  }