<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <!-- <div class="row">
                <div class="col-lg-12">
                    <div class="text-left head-txt mb-30">
                        <h2>Gig Bookings Request</h2>
                    </div>
                </div>
            </div> -->
            <div class="topFilterBlk">
                <div class="filterItemHead">
                    <div class="text-left head-txt">
                        <h2>Gig Bookings Request</h2>
                    </div>
                </div>
<!--                 <div class="filterItemSearch ml-auto">
                    <div class="searchBar searchbarPage">
                        <label>
                            <input type="text" class="form-control" placeholder="Search request...">
                            <span class="fa fa-search"></span>
                        </label>
                    </div>
                </div> -->
            </div>
            <div class="requestList mt-30">

                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/book_me.png">
                            <h2>No Request Found</h2>
                        </div>
                    </div>
                </div>
            <div class="row">

                <ng-container *ngFor="let bookMeList of bookMeList">
                    <div class="col-md-12 col-lg-6">
                        <div class="requestItem requestdesignitem">
                            
                            <div class="requestInfo">
                                <a [routerLink]="['/gigrequestDetails',bookMeList.gigId,bookMeList.request_to_meta_id]">
                                    
                                    <h2 class="textEllipsis"> {{bookMeList.title}}</h2></a>
                                        <!-- {{bookMeList.isRemote}} -->
                                <div class="mt-2 timedetailscls">
                                    <p><i class="far fa-clock pr-2"></i> {{bookMeList.time}}</p>
                                <p *ngIf="bookMeList.isRemote != 1"><i  class="fas fa-map-marker-alt pr-2"></i> {{bookMeList.location}}</p>
                                    <p *ngIf="bookMeList.isRemote == 1" ><i class="fas fa-map-marker-alt pr-2"></i> Remote</p>
                                    <p><i class="far fa-calendar-alt pr-2"></i> {{bookMeList.startDateTime}}</p> 
                                    <p><i *ngIf="bookMeList.is_profile_or_group == '3'"  class="fa fa-users"></i> {{bookMeList.groupName}}</p> 
                                </div>
                            </div>
                            <div class="rightconTxt">
                            <div class="requestImg mb-3 mr-0">
                                <a [routerLink]="['/gigrequestDetails',bookMeList.gigId,bookMeList.request_to_meta_id]"><img *ngIf="bookMeList.gig_images.length > 0"  src="{{bookMeList.gig_images[0].image_url}}" onerror="this.src='assets/img/defaultJob.png';">
                                    <img *ngIf="bookMeList.gig_images.length == 0"  src="/assets/img/defaultJob.png" onerror="this.src='assets/img/defaultJob.png';"></a>
                            </div>
                            <div class="requestAct">
                                <button data-toggle="tooltip" title="Accept" (click)="acceptrejectReq(1,bookMeList.gigId,bookMeList.request_to_meta_id)" class="csIcon acceptIcon  ml-1"><span class="far fa-check-circle"></span></button>
                                <button data-toggle="tooltip" title="Reject" (click)="acceptrejectReq(0,bookMeList.gigId,bookMeList.request_to_meta_id)" class="csIcon rejectIcon"><span class="far fa-times-circle"></span></button>
                            </div>
                        </div><!-- righticon -->
                        </div>
                    </div>
                </ng-container>


                </div>
            </div>
<!--             <div class="text-center">
                <button class="btn btn-outline-info">Load More</button>
            </div> -->

<!--             <div class="text-center" *ngIf="total > (offset+limit)">
                <button class="btn btn-outline-info" (click)="loadMore();">Load More</button>
             </div>  -->

        </div>
    </section>
</div>
<div class="modal fade csModal" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="profileDoneCnt bookConfirm">
            <svg-icon class="animate__animated animate__tada animate__slow animate__infinite" src="assets/img/fireworks.svg"></svg-icon>
            <h2>Congratulations</h2>
            <!-- <h5>Lorem ipsum dolor sit amet.</h5> -->
            <p>If you want to create group, you can create now or can create later</p>
            <button data-dismiss="modal" type="button" class="btn thm-btn mt-20">OK</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a>
        <button type="button" class="btn thm-btn">Apply</button>
      </div> -->
    </div>
  </div>
</div>
<app-footer-livlouder></app-footer-livlouder>


<!-- about: "Heyy hi hi"
endTime: "4:00 AM"
gigFor: "0"
gigId: "57"
gig_images: []
hirerMetaId: "25"
isPrivate: "2"
location: "Indore, Madhya Pradesh, India"
startDateTime: "Saturday, 13 Nov 3:00 AM"
suburb: "Indore"
time: "25 Hours"
title: "Gig2"
userId: "12" -->