import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable,throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators'
import { ToastrNotificationService } from './toastr-notification.service';



@Injectable({
  providedIn: 'root'
})
export class HirerProfileService {
    serverUrl = environment.baseUrl;
    constructor(
      private http: HttpClient,
      private toastrService: ToastrNotificationService
      ) { }
  

    getBusinessType(){
        const httpHeaders = new HttpHeaders({
            'authorization': 'asdffdsafdfdsfsdf',
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC'
          })
      
          //var body = 'email=myusername@gmail.com&password=Pass123@$';
          return this.http.get<any>(`${this.serverUrl}/category/business_type`, { headers: httpHeaders })
      
    }

    detail(metaId){
      const httpHeaders = new HttpHeaders({
          'authorization': 'asdffdsafdfdsfsdf',
          'Device-Id': '23232332',
          'Device-Type': '3',
          'Device-Timezone': 'UTC'
        })

        let params = new HttpParams().set('userMetaId',metaId);
    
        //var body = 'email=myusername@gmail.com&password=Pass123@$';
        return this.http.get<any>(`${this.serverUrl}/user/hirer_detail`, { headers: httpHeaders,params:params })
           .pipe(
             retry(1),
             catchError(this.handleError)
           );
           
    
  }


  switchAccound(switch_user_type,current_user_type){
    
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

   let params = new HttpParams().set('switch_user_type', switch_user_type).set('current_user_type',current_user_type);
   
   // return this.http.get<any>(`${this.serverUrl}/job/detail?metaId=411&gigId=30`, { headers: httpHeaders })

    return this.http.get<any>(`${this.serverUrl}/service/switch_profile`, { headers: httpHeaders,params:params })

  }

  currentUserLogeedIn(formData){
    
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })     

    // return this.http.post<any>(`${this.serverUrl}/service/current_logged_user`, { headers: httpHeaders,params:params })
      return this.http.post<any>(`${this.serverUrl}/service/current_logged_user`,formData, { headers: httpHeaders })


  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
