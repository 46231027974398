import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HirerProfileGuard implements CanActivate {

  constructor(public router:Router){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser.userTypeList[1].isProfileComplete == 1){
      return true;
    }else{
      return false;
    }
  }
  
}
