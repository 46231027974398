import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpBackend, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const toastrError: any;
@Injectable({
  providedIn: 'root'
})

export class GigService {
  serverUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  uploadGigImage(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })


    return this.http.post<any>(`${this.serverUrl}/job/gig_image`, formData, { headers: httpHeaders })

  }

  createGig(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })


    return this.http.post<any>(`${this.serverUrl}/job/gig`, formData, { headers: httpHeaders })
  }

  //Get hirer all created gig list
  getGigList(params) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // const params = new HttpParams().set('offset', offest)
    // .set('search_text', search_text);

    return this.http.get<any>(`${this.serverUrl}/job/my_gig`, { headers: httpHeaders, params: params })
  }

  getGigLists() {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // const params = new HttpParams().set('offset', offest)
    // .set('search_text', search_text);

    return this.http.get<any>(`${this.serverUrl}/job/my_gig`, { headers: httpHeaders })
  }

  // on hirer side private gig list 
  getPrivateGig(userMetaId) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    const params = new HttpParams().set('onlyPrivateGig', '1')
    .set('metaId', userMetaId);
    return this.http.get<any>(`${this.serverUrl}/job/my_gig`, { headers: httpHeaders, params: params })
  }

  gigRequest(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // const params = new HttpParams().set('offset', offest)
    // .set('search_text', search_text);

    return this.http.post<any>(`${this.serverUrl}/job/gig_request`, formData, { headers: httpHeaders })
  }

  gigDetail(gigId, metaId) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams().set('gigId', gigId)
      .set('metaId', metaId);

    // return this.http.get<any>(`${this.serverUrl}/job/detail?metaId=411&gigId=30`, { headers: httpHeaders })

    return this.http.get<any>(`${this.serverUrl}/job/detail`, { headers: httpHeaders, params: params })

  }

  deleteGig(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/delete_gig`, formData, { headers: httpHeaders })

  }

  /**
   * Edit Gig (Hirer side)
   * @param formData 
   * @returns 
   */
  editGig(formData) {
    // for(var pair of formData.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]);
    // }
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })


    return this.http.post<any>(`${this.serverUrl}/job/edit_gig`, formData, { headers: httpHeaders })
  }

  livLouderGigList(paramObj) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // let params = new HttpParams() .set('offset', '0')
    // .set('currentLat', paramObj.currentLat)
    // .set('currentLong', paramObj.currentLong)
    // .set('metaId', paramObj.metaId)
    // .set('search_text', paramObj.search_text);;

    return this.http.get<any>(`${this.serverUrl}/job/livlouder_gig`, { headers: httpHeaders, params: paramObj })

  }

  livlouderOfferGig(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/offer`, formData, { headers: httpHeaders })

  }

  gigQuestion(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/gig_ques`, formData, { headers: httpHeaders })
  }

  /**
   * 
   * @param formData 
   * @returns response
   */
  replyAns(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/gig_ans`, formData, { headers: httpHeaders })

  }

  /**
   * Gig on map livlouder side
   */
  gigOnMap(paramObj) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams()
    // .set('currentLat', paramObj.currentLat)
    // .set('currentLong', paramObj.currentLng)
    // .set('metaId', paramObj.metaId);


    //  return this.http.get<any>(`${this.serverUrl}/job/livlouder_gig`, { headers: httpHeaders,params:params })

    return this.http.get<any>(`${this.serverUrl}/job/livlouder_gig_map`, { headers: httpHeaders, params: paramObj })
  }

  /**
   * My Gig on map livlouder side
   */
  myGigOnMap(paramObj) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams()
    // .set('currentLat', paramObj.currentLat)
    // .set('currentLong', paramObj.currentLng)
    // .set('metaId', paramObj.metaId);


    //  return this.http.get<any>(`${this.serverUrl}/job/livlouder_gig`, { headers: httpHeaders,params:params })

    return this.http.get<any>(`${this.serverUrl}/job/task_map`, { headers: httpHeaders, params: paramObj })
  }

  /**
   * Get report title
   * @returns 
   */
  getReportTitle() {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })


    return this.http.get<any>(`${this.serverUrl}/job/report_title`, { headers: httpHeaders })

  }

  reportGig(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/report`, formData, { headers: httpHeaders })
  }

  reportLivlouder(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/user/report`, formData, { headers: httpHeaders })
  }
  /**
   * 
   */
  saveGig(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/save_gig`, formData, { headers: httpHeaders })
  }
  // like livloder
  likeLivlouder(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/user/like_livlouder `, formData, { headers: httpHeaders })
  }
  /**
   * Get hirer gig on map
   */
  hirerGigOnMap(param) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.get<any>(`${this.serverUrl}/job/my_gig_map`, { headers: httpHeaders, params: param })

  }

  acceptOffer(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/accept_offer`, formData, { headers: httpHeaders })

  }

  trackLivlouder(userMetaId) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // console.log('httpHeaders',httpHeaders)

    let params = new HttpParams()
      .set('userMetaId', userMetaId)

    return this.http.get<any>(`${this.serverUrl}/user/track_livlouder`, { headers: httpHeaders, params: params })

  }

  cancelGig(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/cancel_gig`, formData, { headers: httpHeaders })

  }

  giveReview(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/user/rating_review`, formData, { headers: httpHeaders })

  }

  // realse payment 
  realsePayment(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/change_gig_status`, formData, { headers: httpHeaders })

  }

  // give tip



  giveTip(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/tip`, formData, { headers: httpHeaders })

  }

  /**
   * get favorite gig list
   */
  savedGig(paramObj) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams()
      .set('offset', paramObj.offset)
      .set('search_text', paramObj.search_text)

    return this.http.get<any>(`${this.serverUrl}/job/favourite_gig`, { headers: httpHeaders, params: params })

  }



  /**
   * get Liked Livlouder  list
   */
  likedLivlouder(paramObj) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams()
      .set('offset', paramObj.offset)
      .set('search_text', paramObj.search_text)

    return this.http.get<any>(`${this.serverUrl}/user/liked_livlouder`, { headers: httpHeaders, params: params })

  }

  changeGigStatus(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/job/change_gig_status`, formData, { headers: httpHeaders })

  }

  livLouderFilter(paramObj) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })



    return this.http.get<any>(`${this.serverUrl}/user/livlouder`, { headers: httpHeaders, params: paramObj })

  }

  livLouderMinMaxPrice(paramObj) {
    const checkMetaId = paramObj.keys().includes("userMetaId");
    
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    if (checkMetaId) {
      return this.http.get<any>(`${this.serverUrl}/user/livlouderMinMaxPrice`, { headers: httpHeaders, params: paramObj });
    }else {
      return this.http.get<any>(`${this.serverUrl}/open_acess/livlouderMinMaxPrice`, { headers: httpHeaders, params: paramObj });
    }
  }

  discoverDetail(userMetaId) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams()
      .set('userMetaId', userMetaId)

    return this.http.get<any>(`${this.serverUrl}/user/livlouder_detail`, { headers: httpHeaders, params: params })

  }
  getBasicInfo(metaId) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams().set('metaId', metaId);

    // return this.http.get<any>(`${this.serverUrl}/job/detail?metaId=411&gigId=30`, { headers: httpHeaders })

    return this.http.get<any>(`${this.serverUrl}/service/basic_info`, { headers: httpHeaders, params: params })

  }


  switchAccound(switch_user_type, current_user_type) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    let params = new HttpParams().set('switch_user_type', switch_user_type).set('current_user_type', current_user_type);

    // return this.http.get<any>(`${this.serverUrl}/job/detail?metaId=411&gigId=30`, { headers: httpHeaders })

    return this.http.get<any>(`${this.serverUrl}/service/switch_profile`, { headers: httpHeaders, params: params })

  }

  currentUserLogeedIn(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    // return this.http.post<any>(`${this.serverUrl}/service/current_logged_user`, { headers: httpHeaders,params:params })
    return this.http.post<any>(`${this.serverUrl}/service/current_logged_user`, formData, { headers: httpHeaders })


  }

  // open access
  getList(param) {
    // console.log(param)


    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams().set('', param);
    return this.http.get<any>(`${this.serverUrl}/open_acess/gig`, { headers: httpHeaders, params: param })

  }
  //open access
  discoverlist(param) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams().set('', param);
    return this.http.get<any>(`${this.serverUrl}/open_acess/livlouder`, { headers: httpHeaders, params: param })

  }
  // group list for admin
  groupListAdmin(param) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams().set('', param);
    return this.http.get<any>(`${this.serverUrl}/group/group_admin`, { headers: httpHeaders, params: param })

  }




  // group list for admin
  groupMember(param) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // let params = new HttpParams().set('', param);
    return this.http.get<any>(`${this.serverUrl}/group/group_member`, { headers: httpHeaders, params: param })

  }


  // invite member for group 
  inviteMember(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/group/invitation`, formData, { headers: httpHeaders })


  }
  // openacess
  gigDetailOpen(gigId) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    let params = new HttpParams().set('gigId', gigId);
    return this.http.get<any>(`${this.serverUrl}/open_acess/detail`, { headers: httpHeaders, params: params })
  }


  discoverDetailOpen(userMetaId) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    let params = new HttpParams().set('userMetaId', userMetaId);
    return this.http.get<any>(`${this.serverUrl}/open_acess/livlouder_detail`, { headers: httpHeaders, params: params })
  }

  getDisputeDetail(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}dispute/disputeDetail`, formData, { headers: httpHeaders })


  }

  createDispute(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}dispute/dispute`, formData, { headers: httpHeaders })

  }

  sendDisputeChatNotification(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}dispute/disputeChatNotification`, formData, { headers: httpHeaders })

  }

  addCount(formData) {

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/user/adCount`, formData, { headers: httpHeaders })
  }
}