import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
  userRef: AngularFireObject<any>;
  date: any;
  constructor(
    private fbDB: AngularFireDatabase,
    private fireStorage: AngularFireStorage,
    private commonService: CommonService,
    private router: Router,
  ) { }


  //livload
  userAddFB(userId: any, userData: any) {
    this.fbDB.object(`users/${userId}` + "L").set(userData);
  }

  userHirerPvAddFB(userId: any, userData: any) {
    this.fbDB.object(`users/${userId}` + "H").set(userData);
  }

  userMetaAddFB(userId: any, userData: any) {

    this.fbDB.object(`user_meta_id/${userId}`).set(userData);
  }

  getDataBase() {
    return this.fbDB.database;
  }

  setOffline(userId) {
    this.fbDB.object(`users/${userId}`).update({ "online": "" });
  }

  isOfferExist(chatData: any) {
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
    return this.fbDB.object(`chat/product_chat/${chat_node}/offer`).query.once("value");
  }

  sendMsgFirebase(chatData: any, downloadURL = '') {
    let chat_node = `${chatData.LMetaId}-${chatData.HMetaId}`;
    if (parseInt(chatData.LMetaId) > parseInt(chatData.HMetaId)) {
      chat_node = `${chatData.HMetaId}-${chatData.LMetaId}`;
    }
    let imageUrl = downloadURL ? downloadURL : "";
    let image = downloadURL ? 1 : 0;
    let message = downloadURL ? "" : chatData.message;
    let chat_message = {
      image: image,
      image_url: imageUrl,
      message: message,
      msg_read_tick: 1,
      receiver_id: chatData.LMetaId,
      sender_id: chatData.HMetaId,
      timestamp: Date.now()
    }

    this.fbDB.list(`chat/${chat_node}/messages`).push(chat_message);
    this.chatHistory(chatData, '1', downloadURL);
    this.getChatList(chatData);
    //this.sendNotification(chatData,'2',downloadURL);
  }
  //save chat history data on firebase
  chatHistory(chatData, chatType, downloadURL = '') {

    var chat_history_node_L;
    var chat_history_node_H;
    var chat_history_L;
    var chat_history_H;
    // if(chatType=='1') { // for type
    chat_history_node_L = `${chatData.LMetaId}-${chatData.HMetaId}`;
    chat_history_node_H = `${chatData.HMetaId}-${chatData.LMetaId}`;
    let imageUrl = downloadURL ? downloadURL : "";
    let image = downloadURL ? 1 : 0;
    let message = downloadURL ? "" : chatData.message;
    chat_history_L = {
      image: image,
      image_url: imageUrl,
      last_msg: message,
      other_user_Image: chatData.H_other_user_Image,
      other_user_id: chatData.Hid,
      other_user_name: chatData.H_other_user_name,
      other_user_type: 2,
      phone_no: "7777777744",
      room: chat_history_node_L,
      timestamp: Date.now(),
      unread_count: 0
    }
    chat_history_H = {
      image: image,
      image_url: imageUrl,
      last_msg: message,
      other_user_Image: chatData.L_other_user_Image,
      other_user_id: chatData.Lid,
      other_user_name: chatData.L_other_user_name,
      other_user_type: 1,
      phone_no: "9899977767",
      room: chat_history_node_H,
      timestamp: Date.now(),
      unread_count: 0
    }
    let Hids = chatData.Hid + "H";
    let Lids = chatData.Lid + "L";
    this.fbDB.object(`chat_history/${Lids}/${chat_history_node_L}`).set(chat_history_L);
    this.fbDB.object(`chat_history/${Hids}/${chat_history_node_H}`).set(chat_history_H);

  }
  //get chat history list
  getChatList(chatData: any) {
    let chat_node = `${chatData.LMetaId}-${chatData.HMetaId}`;
    if (parseInt(chatData.LMetaId) > parseInt(chatData.HMetaId)) {
      chat_node = `${chatData.HMetaId}-${chatData.LMetaId}`;
    }

    if (chatData.lastTimeStamp) {
      return this.fbDB.list(`chat/${chat_node}/messages`, ref => ref.orderByChild('timestamp')).snapshotChanges().pipe(
        map(chat => {
          this.updateUnreadStatus(chatData, `${chat_node}`);
          return chat;
        })
      );
    } else {
      return this.fbDB.list(`chat/${chat_node}/messages`).valueChanges();
    }

  }

  updateUnreadStatus(chatData, chat_history_node) {
    setTimeout(() => {
      this.fbDB.object(`chat_history/${chatData.LMetaId}/${chat_history_node}`).query.once("value").then(data => {
        if (data.val()) {

          if (data.val().unread_count > 0) {
            this.fbDB.object(`chat_history/${chatData.LMetaId}/${chat_history_node}`).update({ 'unread_count': 0 });
          }

        }
      });
    }, 800);

  }

  getChatHistoryList(chatData: any) {
    return this.fbDB.list<any>(`chat_history/${chatData.Hid + "H"}`, ref => ref.orderByChild("timestamp")).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      ),

      map((arr) => { return arr.reverse(); })
    );
  }

  //chat history
  chatHistoryData(chatData: any) {

    let chat_node = `${chatData.HMetaId}-${chatData.LMetaId}`;
    if (parseInt(chatData.HMetaId) > parseInt(chatData.LMetaId)) {
      chat_node = `${chatData.HMetaId}-${chatData.LMetaId}`;
    }
    return this.fbDB.object(`chat_history/${chatData.Hid + "H"}/${chat_node}`).snapshotChanges();
  }

  timeSince(timestamp) {
    var date = new Date(timestamp);

    var seconds = Math.floor((new Date().getTime() - new Date(timestamp).getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  blockUser(chatData: any) {

    let chat_node = (Number(chatData.LMetaId) > Number(chatData.HMetaId)) ? `${chatData.HMetaId}-${chatData.LMetaId}` : `${chatData.LMetaId}-${chatData.HMetaId}`;

    this.fbDB.object(`block_users/${chat_node}`).query.once("value").then(data => {

      if (data.val()) {
        this.fbDB.object(`block_users/${chat_node}`).update({ 'blocked_by': 'Both' });
      } else {
        this.fbDB.object(`block_users/${chat_node}`).update({ 'blocked_by': chatData.HMetaId });
      }

    });

  }

  unBlockUser(chatData: any) {
    let chat_node = (Number(chatData.LMetaId) > Number(chatData.HMetaId)) ? `${chatData.HMetaId}-${chatData.LMetaId}` : `${chatData.LMetaId}-${chatData.HMetaId}`;

    this.fbDB.object(`block_users/${chat_node}`).query.once("value").then(data => {
      var block_id = data.val().blocked_by;

      if (block_id == 'Both') {
        this.fbDB.object(`block_users/${chat_node}`).update({ 'blocked_by': chatData.LMetaId });
      } else {
        if (block_id == chatData.HMetaId) {
          this.fbDB.object(`block_users/${chat_node}`).set(null);
        }
      }

    });

  }

  // unBlockUser(chatData:any){

  //   let chat_node = (Number(chatData.LMetaId) > Number(chatData.HMetaId))? `${chatData.HMetaId}-${chatData.LMetaId}` : `${chatData.LMetaId}-${chatData.HMetaId}`;
  //   this.fbDB.object(`block_users/${chat_node}`).query.once("value").then(data => {
  //     var block_id = data.val().blocked_by;
  //        if (block_id == chatData.HMetaId) {
  //       this.fbDB.object(`block_users/${chat_node}`).set(null);
  //     } 

  //   });

  // }

  getBlockStatus(chatData: any) {

    let chat_node = (Number(chatData.LMetaId) > Number(chatData.HMetaId)) ? `${chatData.HMetaId}-${chatData.LMetaId}` : `${chatData.HMetaId}-${chatData.LMetaId}`;
    return this.fbDB.object(`block_users/${chat_node}`).snapshotChanges();
  }

  deleteChat(chatData: any) {
    let chat_node = `${chatData.LMetaId}-${chatData.HMetaId}`;
    this.fbDB.object(`chat/${chat_node}/clear_chat/${chatData.LMetaId}`).update({ 'delete_time': Date.now() });

  }


}
