import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any
import intlTelInput from "intl-tel-input";
import { SignupService } from '../../services/signup.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service'
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { environment } from "../../../environments/environment";

declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;
declare const google: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  reactiveForm: FormGroup;
  returnData: any;
  show: boolean = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  client: any;
  accessToken: string = '';
  googleClientId = environment.googleClientId;

  constructor(
    private signupService: SignupService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastService: ToastrNotificationService,
    private socialAuthService: SocialAuthService


  ) { }

  ngOnInit(): void {
    this.initClient();
    //this.toastService.showInfo("working fine","Done");

    this.reactiveForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]),
      'deviceToken': new FormControl('asdffasfdsf'),
    })

    // const input = document.querySelector(".phone");
    // intlTelInput(input, {
    //     // any initialisation options go here
    // });

    // $("#otpView").click(function(){
    //   $("#otpForm").show();
    //   $("#signupForm").hide();
    // });
    // $("#changeNo").click(function(){
    //   $("#otpForm").hide();
    //   $("#signupForm").show();
    // });
  }

    initClient(): void {
        if (!google || !google.accounts || !google.accounts.oauth2) {
            console.error('Google Identity Services library is not loaded');return;
        }else {
            this.client = google.accounts.oauth2.initTokenClient({
                client_id: this.googleClientId,
                scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                // scope: 'openid profile email',
                callback: (tokenResponse: any) => {
                    if (!tokenResponse || !tokenResponse.access_token) {
                        console.error('Failed to retrieve access token:', tokenResponse);return;
                    }
                    this.accessToken = tokenResponse?.access_token;
                    console.log('Access Token:', this.accessToken);
                    const formData = new FormData();
                    formData.append('accessToken', this.accessToken);
                    formData.append('socialType', '2');
                    formData.append('deviceToken', new Date().toISOString());

                    this.signupService.checkSocialLogin(formData).subscribe(data => {
                        if (data.social_status == 1) {
                            this.filterFunction(data);
                        } else {
                            this.socialLogin(data.userDetail, '2');
                        }
                    }, err => {
                        console.error('Failed to check social login', tokenResponse);return;
                    });
                }
            });
        }
    }

    loginWithgoogle() {
        if (!this.client) {
            console.error('GIS client not initialized.');return;
        }
        
        this.client.requestAccessToken();
    }

//   loginWithgoogle() {
//     // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {


//     // })
//     this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {

//       const formData = new FormData();
//       formData.append('socialId', res.id);
//       //formData.append('socialId', '2323232321');

//       formData.append('socialType', '2');
//       formData.append('email', res.email);
//       formData.append('deviceToken', new Date().toISOString());

//       this.signupService.checkSocialLogin(formData).subscribe(data => {
//         if (data.social_status == 1) {

//           this.filterFunction(data);
//         } else {
//           this.socialLogin(res, '2');
//         }
//       }, err => {
//         console.log(err);

//       });


//       // this.socialLogin(res,'1');
//     }).catch(error => {
//       console.log('Error', error);
//     });
//   }
  loginWithFacebook(): void {

    // this.signupService.checkSocialLogin()

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {

      const formData = new FormData();
      formData.append('socialId', res.id);
      //formData.append('socialId', '2323232321');

      formData.append('socialType', '1');
      formData.append('email', res.email);
      formData.append('deviceToken', new Date().toISOString());

      this.signupService.checkSocialLogin(formData).subscribe(data => {
        if (data.social_status == 1) {

          this.filterFunction(data);
        } else {
          this.socialLogin(res, '1');
        }
      }, err => {
        console.log(err);

      });


      // this.socialLogin(res,'1');
    }).catch(error => {
      console.log('Error', error);
    });
    // api_v1/service/social
    //email ,socialId, socialType, socialType
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  socialLogin(socialData, socialType) {

    if (socialData) {
      showLoader();

      // this.submitted = true;
      const formData = new FormData();
      formData.append('socialId', socialData.id);
      formData.append('socialType', socialType);
      formData.append('email', socialData.email);
      // formData.append('email', 'manaasddsdfss@gmail.com');

    //   formData.append('userType', '1');
    //   formData.append('deviceType', '1');
      formData.append('deviceToken', new Date().toISOString());

      this.signupService.socialSignup(formData).subscribe((data) => {
        hideLoader();

        // this.toastService.showSuccess(data.message, "Success");
        this.filterFunction(data)
      }, err => {
        hideLoader();
        this.toastService.showError(err.error.message, "Error");
      });

    }

  }
  // convenience getter for easy access to form fields
  get f() { return this.reactiveForm.controls; }



  //submit form
  onSubmit() {
    if (!this.reactiveForm.valid) {

      this.validateAllFormFields(this.reactiveForm)

      // this.toastService.showError("Please fill required field before procceding",'Error')  ;
      return false;
    }

    showLoader();

    const formData = new FormData();
    formData.append('email', this.reactiveForm.value.email)
    formData.append('password', this.reactiveForm.value.password);
    formData.append('device_token', '4354565675676');


    //return false;
    this.signupService.signup(formData).subscribe((data) => {
      hideLoader();
      this.returnData = data;
      this.filterFunction(data)
      //this.localStorageService.set('userDetail',JSON.stringify())
    }, err => {
      hideLoader();
      this.toastService.showError(err.error.message, 'Error');

    });


    // this.router.navigate(['/basicInfo']);

  }

  /**
   * store value in local storage and show success and error message
   */
  filterFunction(data: any) {
    if (data.status == 'fail') {
      this.toastService.showError(data.message, "Error");
      return false;
    } else {
      this.toastService.showSuccess(data.message, "Success");
      localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
      this.router.navigate(['/basicInfo']);
    }
  }

  password() {
    this.show = !this.show;
  }
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }



  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


}
