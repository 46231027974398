
<app-header-hirer *ngIf="selectedUser == 2"></app-header-hirer>
<app-header-livlouder *ngIf="selectedUser == 1"></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="sec-pd-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="statusPage">
                        <div class="statusImg shadowImg">
                            <img src="assets/img/check.png">
                        </div>
                        <div class="statusCnt">
                            <h2>Verified Successfully</h2>                            
                            <p>Your account has been verified successfully.</p>
                            
                            <a *ngIf="selectedUser == 1" [href]="serverUrl + '#/bankSettings'" class="btn thm-btn">Login Account</a>
                            <a *ngIf="selectedUser == 2" [href]="serverUrl + '#/settingsHirer'" class="btn thm-btn">Login Account</a>
                        </div>
                    </div>
                    <!-- <div class="statusPage mt-3">
                        <div class="statusImg shadowImg">
                            <img src="/assets/img/cancel.png">
                        </div>
                        <div class="statusCnt">
                            <h2>Verification Failed</h2>
                            <p>Your account verification is failed, please try again.</p>
                            <a href="" class="btn thm-btn">Try Again</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
      
      </section>
</div>
<app-footer-livlouder *ngIf="selectedUser == 1"></app-footer-livlouder>
<app-footer-hirer *ngIf="selectedUser == 2"></app-footer-hirer>


<!-- <app-header-livlouder></app-header-livlouder>
<div class="wrapper img-bg form-img">
    <section class="sec-pd-50">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="form-sec login-width mx-auto form-bg">
                      <h1 class="text-center">Successfully</h1>
                  </div>
              </div>
          </div>
      </div>
    
    </section>
    </div>
<app-footer-livlouder></app-footer-livlouder>     -->