<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="discoverDetails mainContent" *ngIf="data">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                        <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar>
                            <div class="discoverItem sidebar__inner">
                                <div class="disArtistImg">
                                    <a href="javascript:void(0);"><img [src]="data.profileImage"></a>
                                </div>
                                <div class="disArtistInfo profileInfoBlk">
                                    <a href="javascript:void(0);">
                                        <h2 class="">{{data.liveLouderName}}</h2>
                                    </a>
                                    <div class="livLouderTypes">
                                        <h5>{{data.type}}</h5>
                                        <p class="mb-2"><span>{{data.subType}}</span></p>
                                    </div>
                                    <p class="loc"><i class="fa fa-map-marker-alt"></i> <span
                                            class="">{{data.suburb}}</span></p>
                                    <div class="ratePriceBlk">
                                        <div class="rateBlk">
                                            <p><span class="fa fa-star"></span> {{data.rating}}</p><span
                                                class="totalReeview">({{data.totalUserRating}})</span>
                                        </div>
                                        <div class="priceBlk">
                                            <p>{{data.currency}}{{data.price}}/<span>Hr</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="btnActGroup">
                                    <div class="btnActItem bookMeBtn">
                                        <!-- <button routerLink="/profileHirer" class="btn thm-btn btn-block">Switch To Hirer</button> -->
                                        <!--  <button class="btn thm-btn btn-block">Switch To Hirer</button> -->
                                        <button (click)="switchAccound()" class="btn thm-btn btn-block">Switch To
                                            Hirer</button>

                                    </div>
                                    <div class="btnActItem ml-auto">
                                        <div class="">
                                            <span class="d-inline-block">
                                                <!-- <button routerLink="/createHirerProfile" class="btn iconBtn" title="Edit Profile" data-toggle="tooltip" type="button"> -->
                                                <button routerLink="/updateLivlouderProfile" class="btn iconBtn"
                                                    title="Edit Profile" data-toggle="tooltip" type="button">
                                                    <span class="fa fa-edit"></span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="availability">
                                    <h2>Availability</h2>
                                    <p>{{data.availability}}</p>
                                </div>
                                <div *ngIf="socialMedia.length != 0" class="socialLinkAdded">
                                    <ul>
                                        <ng-container *ngFor="let social of socialMedia">
                                            <li *ngIf="social.type == 'facebook'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-facebook-square"></span> Facebook</a></li>
                                            <li *ngIf="social.type == 'twitter'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-twitter-square"></span> Twitter</a></li>
                                            <li *ngIf="social.type == 'instagram'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-instagram"></span> Instagram</a></li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">{{data.about?data.about:'Not available'}}</p>
                                </div>

                                <div class="userInfoItem">
                                    <h2 class="infoHead">Experience</h2>
                                    <div *ngIf="experience.length == 0">Not available</div>
                                    <ul class="infoList" *ngFor="let ex of experience">
                                        <li>{{ex}}</li>
                                    </ul>
                                </div>

                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div *ngIf="data.image_media.data.length == 0 ">Not available</div>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile"
                                        *ngIf="data.image_media.data.length > 0">
                                        <ng-container *ngFor="let imgData of data.image_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{imgData.image}}">
                                                    <img [src]="imgData.thumbImage">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Videos</h2>
                                    <div *ngIf="data.video_media.data.length == 0">Not available</div>

                                    <ng-container *ngFor="let video of data.video_media.data">
                                        <div style="display:none;" [id]="video.id">
                                            <video class="lg-video-object lg-html5" controls preload="none">
                                                <source [src]="video.video" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </ng-container>
                                    <div id="galleryVideos" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor="let video of data.video_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-poster="{{video.videoImageThumb}}"
                                                    attr.data-html="{{'#'+video.id}}">
                                                    <img [src]="video.videoImageThumb">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top"
                                                                title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--  group data show  -->

                        <!-- <div class="col-md-7 col-lg-8"> -->
                        <div *ngIf="groupMemberCount != 0 || groupMemberCount !=''" class="widgetBox mt-30">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Group Members <span class="NoCount">({{groupMemberCount}})</span>
                                </h2>
                                <div class="invitedMemberList groupMemberList mt-20">


                                    <ng-container *ngFor="let groupMember of groupMember">

                                        <div class="membItem">
                                            <div class="membInfoBlk">
                                                <div class="membImg">
                                                    <img [src]="groupMember.profileImage">
                                                </div>
                                                <div class="membName">
                                                    <h2>{{groupMember.groupName }}</h2>
                                                    <p *ngIf="groupMember.totalMember == 1">{{groupMember.totalMember}}
                                                        (Member)</p>
                                                    <p *ngIf="groupMember.totalMember != 1">{{groupMember.totalMember}}
                                                        (Members)</p>
                                                </div>
                                                <div *ngIf="groupMember.isAdmin == 1" class="membAct">
                                                    <a class="btn btnSmall" href="javascript:void(0)">Admin</a>
                                                </div>

                                                <!-- <div *ngIf="groupMember.userMetaId  !=  metaId && groupMember.isAdmin != 1" class="membAct">
                                                <a  (click) = "remove_Member();" class="btn btnSmall btnDanger" href="javascript:void(0)">Remove</a>

                                            </div> -->
                                                <!-- <div *ngIf="groupMember.userMetaId  ==  metaId && groupMember.isAdmin != 1" class="membAct">
                                                <a  (click) = "leave_group_pop_up();" class="btn btnSmall btnDanger" href="javascript:void(0)">Leave</a>

                                            </div> -->
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                                <!-- <div class="">
                                <button data-toggle="modal" data-target="#inviteMember" class="btn thm-btn">Invite Members</button>
                            </div> -->
                            </div>
                        </div>
                        <!-- </div> -->

                        <div class="widgetBox mt-30" *ngIf="data.rating_review.count > 0">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Reviews <span class="NoCount">({{data.rating_review.count}})</span>
                                </h2>
                                <div class="reviewsList">
                                    <ng-container *ngFor="let review of data.rating_review.data">
                                        <div class="reviewsItem">
                                            <div class="reviewsCntBlk">
                                                <div class="reviewUserImg">
                                                    <a href="javascript:void(0);">
                                                        <img [src]="review.profileImage">
                                                    </a>
                                                </div>
                                                <div class="reviewsCnt">
                                                    <a href="javascript:void(0);">
                                                        <h2>{{review.fullName}}</h2>
                                                    </a>
                                                    <div class="rateTimeMeta">
                                                        <p>{{review.agotime}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratingView ml-auto">
                                                    <div class="ratingBlk">
                                                        <i [class.filled]="review.rating >= '1'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '2'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '3'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '4'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '5'"
                                                            class="fas fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="retDec">
                                                <p *ngIf="review.review != 'null' && review.review != ''">{{review.review}}</p>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                    <h2>Report User</h2>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="giveReviewBox">
                        <div class="">
                            <div class="form-floating mb-3">
                                <select type="text" class="form-control" id="floatingInput">
                                    <option>Report Title 1</option>
                                    <option>Report Title 2</option>
                                    <option>Report Title 3</option>
                                    <option>Report Title 4</option>
                                </select>
                                <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea rows="4" type="text" class="form-control textArea" id="floatingInput"
                                    placeholder="Write something..."></textarea>
                                <label for="floatingInput">Description <span class="reqField">*</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="bookMe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose an gig to book the livlouder for</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">
                    <div class="GigListBookingNoInfo mb-4">
                        <div class="noDataView">
                            <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                            <p>You currently have no gigs. <br /> Please create an gig first.</p>
                        </div>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>DJ The Great and Terrible</h2>
                                    <p>Sat Jan 24, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>Blue in the Face</h2>
                                    <p>Mon Feb 20, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>On a Sensual Note</h2>
                                    <p>Sat Jan 24, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>DJ The Great and Terrible</h2>
                                    <p>Sat Jan 24, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>Blue in the Face</h2>
                                    <p>Mon Feb 20, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membName">
                                    <h2>On a Sensual Note</h2>
                                    <p>Sat Jan 24, 2021</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a>
                <button type="button" class="btn thm-btn">Apply</button>
            </div>
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>