
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { GigService } from 'src/app/services/gig-service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpParams } from '@angular/common/http';
import { SignupService } from 'src/app/services/signup.service';

declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-gig-list',
	templateUrl: './gig-list.component.html',
	styleUrls: ['./gig-list.component.css']
})
export class GigListComponent implements OnInit, AfterViewInit {
	hidghValue: any = '0';
	lowValue: any = '0';
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([0, 300]),
		sliderControlPrice: new FormControl([this.lowValue, this.hidghValue])
	});

	sliderValue;
	sliderEvents: boolean = false;

	options: Options = {
		floor: 0,
		ceil: 300,
		step: 5
	};
	// priceSlider: Options = {
	// 	floor: 0,
	// 	ceil: 450,
	// 	step: 5
	// };


	priceSlider: Options = {
		floor: this.lowValue,
		ceil: this.hidghValue,
		step: 5
	};

	locationoptions = {
		// componentRestrictions: {
		// 	country: ['UK']
		// }

		componentRestrictions: { country: 'UK' }
	};
	endModel
	model;

	lat;
	long;
	gigLists;

	dataFound = false;
	dataCount: any;
	title = 'Livloud - Gig Lists';
	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	typeIdSelected = [];
	subTypeIdSelected = [];


	//added code...
	typeSubtype1: any;
	typeIdSelected1 = [];

	typeListData1 = [];
	subTypeSelected1 = [];

	subTypeArray1 = [];
	subTypeIdSelected1 = [];



	lng: any;
	location: any;
	suburb: any;
	limit: any = 20;
	offset: any = 0;
	total: any;
	flag: any;
	isLogin;
	isLiked = false;

	// priceFilter;
	//added code...
	priceFilter: string = "";
	hot: string = "";
	minvalue;
	maxvalue;
	setPriceValue: boolean = false;
	minDate: any;
	// priceFilter;
	locationValue: string;
	// priceFilters:string = "descc";
	constructor(
		private gigService: GigService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private authService: AuthService,
		private signupService: SignupService
	) {
		// this.getLocation();
	 }

	ngOnInit(): void {
		
		$("#allF").addClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');
		// $('#')
		// showLoader();

		this.isLogin = this.authService.isLoggedIn() == true;
		this.titleService.setTitle(this.title);

		if (this.authService.isLoggedIn() == true) {

			this.authService.setUserMetaId(1);
		}

		//showLoader();
		this.getLocation()

		// $(document).on('hover', '[data-toggle="tooltip"]', function () { $(this).tooltip('show'); });
		$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });

		this.signupService.getTypeSubType().subscribe((data) => {

			this.typeSubtype = data.data;
		}, (err) => {
			hideLoader();
		});

		this.signupService.getGroupTypeSubType().subscribe((data) => {

			this.typeSubtype1 = data.data;
		}, (err) => {
			hideLoader();
		});

		this.minDate = this.getCurrentDateObject();
	}

	// date format with object
	getCurrentDateObject() {
		return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	ngAfterViewInit(): void { }

	public handleAddressChange(address: any) {

		this.lat = address.geometry.location.lat();
		this.long = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;


	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}

	sideBarViewOpen() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.add("overflow");
		modalOverlay.classList.add("overlay-show");
		withcalc.classList.add("withcalc");

	}
	sideBarViewClose() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");
	}

	applyFilters() {

		this.offset = 0;
		this.applyFilter();
		$("#FilterIconActive").addClass('activeFilterIcon');
		$("#activeicon").removeClass('activeFilterIcon');
	}


	/**
	 * Apply Filter
	 */
	async applyFilter(offset?, flag?) {

		this.isLiked = false;
		this.hot = '';
		// this.priceFilter = '';
		this.offset = 0;

		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");

		let searchText = $('#search').val();

		//added code...
		// let locationName = $('#floatingInput').val();

		var obj: any = {}
		obj.search = searchText;
		obj.date = $('#startDate').val();
		obj.endDate = $('#endDate').val();
		let distanceMin = $('#distance_filter b span:first').text();
		let distanceMax = $('#distance_filter b').find('span:eq(1)').text();

		//sort by price -> if distance is default then don't send
		//all and apply -> if distance is default then don't send
		//hot and apply -> if distance is default then don't send

		if(distanceMin>0 || distanceMax<300){
			obj.distanceMin = distanceMin;
			obj.distanceMax = distanceMax;
		}

		if($("input[name='filterOption']:checked").val() == 'featured'){
			this.hot = "1";
		}

		/*if($("input[name='filterOption']:checked").val() == 'all'){
		
			obj.distanceMin = distanceMin;
			obj.distanceMax = distanceMax;
		}

		if(this.priceFilter == ''){
		
			obj.distanceMin = distanceMin;
			obj.distanceMax = distanceMax;
		}*/

		/*if($("input[name='filterOption']:checked").val() == 'featured'){
		
			// obj.distanceMin = "";
			// obj.distanceMax = "";

			this.isLiked = false;
			this.hot = "1";
			this.offset = 0;
		}*/

		obj.price = this.priceFilter;
		obj.hot = this.hot;

		//added code...		
		// obj.location = locationName;

		var startDate = new Date($('#startDate').val());
		var endDate = new Date($('#endDate').val());  
		if (startDate > endDate) {
  
		  this.toastrService.showError('End date must be equal or greater than start date.', "Error");
		  return false;
		}

		//price slider filter apply than this code will execute
		if (this.sliderEvents) {
			let priceFrom = $('#priceTo b span:first').text();
			let priceTo = $('#priceTo b').find('span:eq(1)').text();

			if ((priceFrom != 0) || (priceTo != 450)) {

				obj.priceFrom = priceFrom;
				obj.priceTo = priceTo;
			}

		}

		//type subtype filter
		let type = this.typeIdSelected.toString();
		let subType = this.subTypeIdSelected.toString();
		
		if (type != '' && subType == '') {

			obj.onlyType = type;
		} else {

			obj.type = type;
			obj.subtype = subType;
		}

		let grouptype = this.typeIdSelected1.toString();
		let groupsubtype = this.subTypeIdSelected1.toString();

		if (grouptype != '' && groupsubtype == '') {

			obj.onlyGtype = grouptype;
		} else {

			obj.grouptype = grouptype;
			obj.groupsubtype = groupsubtype;
		}

		if (!offset) {
			obj.offset = '0';
		} else {
			obj.offset = this.offset;
		}

		let paramObj = await this.createParam(obj);

		if (flag == 1) {
			this.getLivlouderGigNew(paramObj);
		} else {

			this.gigLists = this.getLivlouderGig(paramObj);
		}

	}

	//Reset Filter
	sideBarReset() {

		location.reload();
	}


	//Price slider event .if slide than price range between 0-450 
	sliderEvent() {
		this.sliderEvents = true;
	}


	getLocation() {

		hideLoader();
		this.locationValue = '';
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				// console.log('position', position.coords);
				
				this.long = position.coords.longitude;
				this.lat = position.coords.latitude;

				this.callApi(this.long, this.lat);
				this.getList(this.lat, this.long);

				this.reverseGeocode(this.lat, this.long)

			},
				function (error) {
					if (error.code == error.PERMISSION_DENIED) {

						alert('Please enable your location permission if you want to access gigs list.');
					}
				});
		} else {

			hideLoader();
			this.toastrService.showError('No support for geolocation', 'Error');

		}
	}

	reverseGeocode(lat, long) {
		
		const geocoder = new google.maps.Geocoder();
		const latlng = { lat: lat, lng: long };
	
		geocoder.geocode({ 'location': latlng }, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					const locationName = results[0].formatted_address;
					// Now you can use the location name as needed
					this.locationValue = locationName;

				} else {
					console.log('No results found');
					this.locationValue = '';
				}
			} else {
				console.log('Geocoder failed due to: ' + status);
			}
		});
	}

	// filterhot(hot) {
	// 	this.isLiked = false;

	// 	$("#FilterIconActive").removeClass('activeFilterIcon');
	// 	$("#activeicon").removeClass('activeFilterIcon');
	// 	$("#allF").removeClass('activeFilterIcon');
	// 	$("#hotF").addClass('activeFilterIcon');

	// 	$("#asc").removeClass('activeFilterIcon');
	// 	$("#desc").removeClass('activeFilterIcon');
	// 	$("#priceF").removeClass('activeFilterIcon');

	// 	this.hot = hot;
	// 	alert(this.hot)


	// 	this.offset = 0;

	// 	this.applyFilter();
	// }
	filterhot(hot) {

		$("#allF").removeClass('activeFilterIcon');

		$("#priceasc").removeClass('activeFilterIcon');
		$("#pricedesc").removeClass('activeFilterIcon');
		$("#priceF").removeClass('activeFilterIcon');

		$("#rateF").removeClass('activeFilterIcon');
		$("#rateasc").removeClass('activeFilterIcon');
		$("#ratedesc").removeClass('activeFilterIcon');

		$("#hotF").addClass('activeFilterIcon');


		this.isLiked = false;

		//$("#activeicon").css("color", '#01d48e');
		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');


		this.hot = hot;
		this.offset = 0;

		this.applyFilters();
	}



	getList(lat, long) {

		let searchText = $('#search').val();
		if (this.authService.isLoggedIn() == true) {

			var metaId = this.authService.getUserMetaId();
			var paramObj = { 'metaId': metaId, 'currentLat': this.lat, 'currentLong': this.long, 'offset': 0, 'search_text': searchText };

		}

		var paramObj = { 'metaId': metaId, 'currentLat': this.lat, 'currentLong': this.long, 'offset': 0, 'search_text': searchText };

		this.getLivlouderGig(paramObj);

	}

	/**
	 * Seach Gig 
	 * @param searchValue 
	 */
	searchGig(searchValue) {
		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#activeicon").removeClass('activeFilterIcon');
		this.isLiked = false;

		this.offset = 0;
		this.applyFilter();
	}
	searchGig1() {
		alert('hiiii');
	}
	savedGig() {
		$("#activeicon").removeClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');
		this.isLiked = true;

		let searchText = $('#search').val();
		var paramObj = { 'offset': 0, 'search_text': searchText };
		showLoader();
		this.gigService.savedGig(paramObj).subscribe(res => {
			hideLoader();
			if (res.data_found == false) {
				this.gigLists = [];
				this.dataFound = false;
				this.total = 0;
			} else {
				this.gigLists = res.data.gig;
				this.dataFound = true;
				this.total = res.data.total_record;
			}
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}

		})
	}

	callApi(Longitude: number, Latitude: number) {
		const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`

		//Call API
	}

	/**
	 * Create http param for filter gig
	 * @param obj 
	 * @returns 
	 */
	createParam(obj) {
		if (this.authService.isLoggedIn() == true) {

			var metaId = this.authService.getUserMetaId();
		}

		let params = new HttpParams()
			.set('offset', ("offset" in obj) ? obj.offset : "0")
			.set('distanceMax', '')
			.set('search_text', ("search" in obj) ? obj.search : "")
			.set('distanceMin', ("distanceMin" in obj) ? obj.distanceMin : "")
			.set('distanceMax', ("distanceMax" in obj) ? obj.distanceMax : "")
			.set('currentLat', this.lat.toString())
			.set('currentLong', this.long.toString())
			.set('metaId', metaId)
			.set('type', ("type" in obj) ? obj.type : "")
			.set('subtype', ("subtype" in obj) ? obj.subtype : "")
			.set('grouptype', ("grouptype" in obj) ? obj.grouptype : "")
			.set('groupsubtype', ("groupsubtype" in obj) ? obj.groupsubtype : "")
			.set('onlyType', ("onlyType" in obj) ? obj.onlyType : "")
			.set('onlyGroupType', ("onlyGtype" in obj) ? obj.onlyGtype : "")
			.set('price', ("price" in obj) ? obj.price : "")
			.set('hot', ("hot" in obj) ? obj.hot : "")
			.set('prices', ("prices" in obj) ? obj.prices : "")
			.set('all', '')
			// .set('location', ("location" in obj) ? obj.location : "")
			.set('priceTo', ("priceTo" in obj) ? obj.priceTo : "")
			.set('rating', '')
			.set('priceFrom', ("priceFrom" in obj) ? obj.priceFrom : "")
			.set('date', ("date" in obj) ? obj.date : "")
			.set('endDate', ("endDate" in obj) ? obj.endDate : "")


		return params;
	}

	/**
	 * Get all gig list
	 */
	allGig() {
		$('#startDate').val('');
		$('#endDate').val('');


		this.hot = '';
		this.priceFilter = "";

		$("#allF").addClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');
		$("#hotF").removeClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#priceF").removeClass('activeFilterIcon');
		$("#asc").removeClass('activeFilterIcon');
		$("#desc").removeClass('activeFilterIcon');



		this.isLiked = false;

		this.hot = '';
		this.priceFilter = '';
		this.offset = 0;
		this.applyFilter();
	}





	getLivlouderGig(paramObj) {


		if (this.authService.isLoggedIn() == true) {
			
			showLoader();
			this.gigService.livLouderGigList(paramObj).subscribe(res => {
				if (!this.setPriceValue) {
					this.setPriceValue = true;
					this.minvalue = res.min_max_price.minPrice;
					this.maxvalue = res.min_max_price.maxPrice;
					this.setNewCeil(this.maxvalue, this.minvalue);
				}
    			
				$('#priceFilter').hide();
				hideLoader();

				if (res.data_found == true) {
					this.dataFound = res.data_found;
					this.gigLists = res.final_data;
					this.total = res.total_records;

				} else {
					this.dataFound = res.data_found;
					this.gigLists = res.final_data;
					this.total = 0;
				}

			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					//this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			})
		} else {
			this.gigService.getList(paramObj).subscribe(
				(res) => {
					this.minvalue = res.min_max_price.minPrice;
					this.maxvalue = res.min_max_price.maxPrice;
					this.setNewCeil(this.maxvalue, this.minvalue)
					hideLoader();

					if (res.data_found == true) {
						this.dataFound = res.data_found;
						this.gigLists = res.data;



						this.total = res.total_records;

					} else {
						this.dataFound = res.data_found;
						this.gigLists = res.data;
						this.total = 0;
					}
				},
				(err) => {
					hideLoader();
					//this.authService.checkValidToken(err.error);
					this.toastrService.showError(err.error.message, "Error");
				}
			);
		}


	}

	getLivlouderGigNew(paramObj) {
		$('#priceFilter').hide();

		showLoader();
		if (this.authService.isLoggedIn() == true) {

			this.gigService.livLouderGigList(paramObj).subscribe(res => {
				$('#priceFilter').hide();
				hideLoader();
				this.minvalue = res.min_max_price.minPrice;
				this.maxvalue = res.min_max_price.maxPrice;
				this.setNewCeil(this.maxvalue, this.minvalue)
				if (res.data_found == true) {
					this.dataFound = res.data_found;

					var newList = res.final_data;
					this.gigLists = this.gigLists.concat(newList);
					this.total = res.total_records;

				} else {
					this.dataFound = res.data_found;
					this.gigLists = res.final_data;
					this.total = 0;
				}

			}, (err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			})
		}
		else {
			this.gigService.getList(paramObj).subscribe(
				(res) => {
					this.minvalue = res.min_max_price.minPrice;
					this.maxvalue = res.min_max_price.maxPrice;
					this.setNewCeil(this.maxvalue, this.minvalue)
					hideLoader();

					if (res.data_found == true) {
						this.dataFound = res.data_found;
						//this.gigLists = res.data;

						var newList = res.data;
						this.gigLists = this.gigLists.concat(newList);

						this.total = res.total_records;

					} else {
						this.dataFound = res.data_found;
						this.gigLists = res.data;
						this.total = 0;
					}
				},
				(err) => {
					hideLoader();
					//this.authService.checkValidToken(err.error);
					this.toastrService.showError(err.error.message, "Error");
				}
			);
		}

	}

	/**
	 * Filter gig according to asc or desc
	 * 1 means low to high and 2 high to low
	 * @param order 
	 */
	filterPrice(order) {

		if (order == 'asc') {

			$("#desc").removeClass('activeFilterIcon');
			$("#hotF").removeClass('activeFilterIcon');
			$("#priceF").addClass('activeFilterIcon');
			$("#asc").addClass('activeFilterIcon');
			$("#activeicon").removeClass('activeFilterIcon');
			$("#allF").removeClass('activeFilterIcon');
		}
		if (order == 'desc') {

			$("#hotF").removeClass('activeFilterIcon');
			$("#asc").removeClass('activeFilterIcon');
			$("#desc").addClass('activeFilterIcon');
			$("#priceF").addClass('activeFilterIcon');
			$("#allF").removeClass('activeFilterIcon');
		}
		// alert(order);
		this.isLiked = false;
		$("#hotF").removeClass('activeFilterIcon');

		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');
		this.priceFilter = order;

		this.offset = 0;
		this.applyFilter();

	}

	setNewCeil(newCeil: number, newfloor: number): void {
		// Parse new values
		const parsedFloor = typeof newfloor === 'string' ? parseFloat(newfloor) : newfloor;
		const parsedCeil = typeof newCeil === 'string' ? parseFloat(newCeil) : newCeil;

		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, this.priceSlider);
		newOptions.ceil = parsedCeil;
		newOptions.floor = parsedFloor;
		this.priceSlider = newOptions; 
		this.hidghValue = parsedCeil;
	}

	// Method to handle changes in the slider value
	onSliderChange(newValue: number) {
		this.hidghValue = newValue;
	}

	filterLivlouder(order) {

		var metaId = this.authService.getUserMetaId();
		let params = new HttpParams()
			.set('offset', this.offset)
			.set('distanceMax', '')
			.set('search_text', '')
			.set('distanceMin', '')
			.set('userMetaId', metaId)
			.set('rate', '')
			.set('subtype', '')
			.set('hot', this.hot)
			.set('price', order)
			.set('all', '')
			.set('location', '')
			.set('priceTo', '')
			.set('rating', '')
			.set('priceFrom', '')
		showLoader();
		this.gigService.livLouderFilter(params).subscribe(res => {

			$('#priceFilter').hide();
			hideLoader();
			if (res.data_found == true) {
				this.dataFound = res.data_found;
				this.gigLists = res.data;
				this.dataCount += res.total_records;


			}

		}, err => {
			hideLoader();

			this.toastrService.showError(err.error.message, 'Error');

		})
		//livlouder
	}

	loadMore() {

		this.offset = this.offset + this.limit;
		var flag = 1;
		this.applyFilter(this.offset, flag);
	}

	over() {
		$('#priceFilter').show();
	}

	out() {
		$('#priceFilter').hide();
	}


	onCheckboxChangeRole(type, event) {

		// if (event.target.checked) {
		// 	this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray.push(type);
		// 	this.typeIdSelected.push(type.categoryId);

		// } else {
		// 	for (var i = 0; i < this.typeListData.length; i++) {
		// 		if (this.typeListData[i].categoryId == type.categoryId) {
		// 			this.typeListData.splice(i, 1);


		// 		}
		// 	}

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray.length; i++) {
		// 		if (this.subTypeArray[i] == type.categoryId) {
		// 			this.subTypeArray.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected.splice(itemindex, 1);
		// 	}

		// }

		// // if (this.typeListData.length == 0) {
		// // 	this.completeProfileForm.patchValue({ typeBlankCheck: "" });
		// // } else {
		// // 	this.completeProfileForm.patchValue({ typeBlankCheck: "1" });
		// // }

		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.subTypeIdSelected = [];
			this.subTypeSelected = [];
		}
	
		if (event.target.checked) {
			const checkTypeData = this.typeListData.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected = [];
				this.subTypeIdSelected = [];
				// this.subTypeSelected = [];
				this.typeListData = [];
				this.typeListData.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.typeIdSelected.indexOf(type.categoryId);
			
			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray = [];
				this.typeIdSelected = [];
				this.subTypeArray.push(type);
				this.typeIdSelected.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}
	}


	removeType(index) {
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected = this.subTypeSelected.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {

				this.subTypeArray[i].subType.forEach(element => {

					if (this.subTypeIdSelected.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected.indexOf(element.categoryId);
						this.subTypeIdSelected.splice(idexitem, 1);

					}
				});
				this.subTypeArray.splice(i, 1);
			}
		}


	}


	onCheckboxChangesubType(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected.push(subType.categoryId);

		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	//  group filter code


	// code for group type filter 

	removeType1(index) {
		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype1 = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype1);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected1 = this.subTypeSelected1.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {

				this.subTypeArray1[i].subType.forEach(element => {

					if (this.subTypeIdSelected1.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected1.indexOf(element.categoryId);
						this.subTypeIdSelected1.splice(idexitem, 1);

					}
				});
				this.subTypeArray1.splice(i, 1);
			}
		}


	}


	onCheckboxChangeRole1(type, event) {

		// if (event.target.checked) {
		// 	this.typeListData1.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray1.push(type);
		// 	this.typeIdSelected1.push(type.categoryId);

		// } else {



		// 	for (var i = 0; i < this.typeListData1.length; i++) {
		// 		if (this.typeListData1[i].categoryId == type.categoryId) {
		// 			this.typeListData1.splice(i, 1);


		// 		}
		// 	}

		// 	for (var i = 0; i < this.subTypeArray1.length; i++) {
		// 		if (this.subTypeArray1[i].categoryId == type.categoryId) {
		// 			this.subTypeArray1.splice(i, 1);


		// 		}
		// 	}

		// 	// console.log( 'subcat',this.subTypeArray)

		// 	// this.removeType(type);

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray1.length; i++) {
		// 		if (this.subTypeArray1[i] == type.categoryId) {
		// 			this.subTypeArray1.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected1.splice(itemindex, 1);
		// 	}

		// }

		if (event.target.checked == false) {
			this.typeIdSelected1 = [];
			this.typeListData1 = [];
			this.subTypeArray1 = [];
			this.subTypeIdSelected1 = [];
			this.subTypeSelected1 = [];
		}

		if (event.target.checked) {
			const checkTypeData = this.typeListData1.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected1 = [];
				this.subTypeSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.typeListData1 = [];
				this.typeListData1.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray1.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.subTypeIdSelected1.indexOf(type.categoryId);

			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeArray1 = [];
				this.typeIdSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.subTypeArray1.push(type);
				this.typeIdSelected1.push(type.categoryId);
				// this.subTypeIdSelected1.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected1.splice(itemindex, 1);
			}
		}
	}



	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected1.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected1.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}

	removeSubType1(index) {
		let selectedSubType1 = this.subTypeSelected1[index].categoryId;
		this.subTypeSelected1.splice(index, 1);
		const itemindex = this.subTypeIdSelected1.indexOf(selectedSubType1);
		if (index > -1) {
			this.subTypeIdSelected1.splice(itemindex, 1);
		}
	}

	addCount(id) {

		if (this.authService.isLoggedIn() == true) {

			const formData = new FormData();
			formData.append("adId", id);
			this.gigService.addCount(formData).subscribe((data) => {

			}, err => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			})
		}
	}


}
