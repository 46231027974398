import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GigService } from "src/app/services/gig-service";
import { BookMeRequest } from 'src/app/services/book-me-request';
import { AuthService } from "src/app/services/auth.service";
import { SignupService } from 'src/app/services/signup.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  } from "@angular/forms";
  import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

declare var $:any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-gig-request-details',
  templateUrl: './gig-request-details.component.html',
  styleUrls: ['./gig-request-details.component.css']
})
export class GigRequestDetailsComponent implements OnInit {
  show = false;
  public show_dialog : boolean = false;

  gigId;
  assignedMetaId;
  metaId;
  bookMeDetail:any = [];
  subtype;
  mustHave;
  qusAns:any = [];
  agoTime;
  timeout: any;
  dataFound = false;

  constructor(

    private bookMeRequest: BookMeRequest,
    private toastrService: ToastrNotificationService,
    private titleService: Title,
    private authService: AuthService,
    private signupService:SignupService,
    private activatedRoute: ActivatedRoute,
    private gigService: GigService,
    private router: Router

    ) { }

  ngOnInit(): void {
    $('#imageGallery').lightGallery({
      thumbnail:true,
      selector: '.addedImg',
      download: false
    }); 
    $('[data-toggle="tooltip"]').tooltip({ trigger:'hover' });

    this.activatedRoute.paramMap.subscribe(param => {
      this.gigId = param.get("id");
      this.assignedMetaId = param.get("assignedMetaId");
    });

    this.metaId = localStorage.getItem('metaId');
    // console.log('aaa',this.metaId);
        this.getdetail();
  }

  getdetail(){

    this.gigDetail(this.metaId);
  }

  // Ask Question
  askQuestion(question) {
    var formData = new FormData();
    formData.append("metaId", this.metaId);
    formData.append("gigId", this.gigId);
    formData.append("question", question);

    this.gigService.gigQuestion(formData).subscribe(
      (res) => {
        this.gigDetail(this.metaId);
        $("#question1").val("");
      },
      (err) => {
        hideLoader();

        this.toastrService.showError(err.error.message, "Error");
        this.authService.checkValidToken(err.error);
      }
    );
  }

    gigDetail(metaId) {
    this.gigService.gigDetail(this.gigId, metaId).subscribe((res) => {
      this.filterDetail(res);
    });
  }

  filterDetail(res) {
    hideLoader();

    this.bookMeDetail = res.data;
    this.subtype = this.bookMeDetail.subtype.split(',');
    this.mustHave = JSON.parse(this.bookMeDetail.mustHave);
    this.qusAns = this.bookMeDetail.qusAns;

    this.qusAns.forEach((data) => {
      data.agoTime = this.diffHours(
        data.posted_time,
        this.bookMeDetail.current_time
      );
    });

    this.agoTime = this.diffHours(
      this.bookMeDetail.posted_time,
      this.bookMeDetail.current_time
    );

    return;
  }

  diffHours(t1, t2) {
    var date1 = new Date(t1).getTime() / 1000;

    var date2 = new Date(t2).getTime() / 1000;

    var difference = Math.abs(date2 - date1);
    var diffHr = difference / 60 / 60;
    var daysDiff = difference / 60 / 60 / 24;
    if (daysDiff < 1) {
      if (diffHr < 1) {
        difference = difference / 60;
        var hourDiff = Math.floor(difference) + " Min Ago";
      } else {
        difference = difference / 60 / 60;
        var hourDiff = Math.floor(difference) + " Hour Ago";
      }
    } else {
      var hourDiff = Math.floor(daysDiff) + " Days Ago";
    }

    return hourDiff;
  }

    acceptrejectReq(status,gigId,metaId){
      
      var formData = new FormData();
      formData.append('metaId',this.assignedMetaId)
      formData.append('gigId',gigId)
      formData.append('status',status)

      showLoader();
      this.bookMeRequest.acceptRejectRequest(formData).subscribe(res => {
      hideLoader();
      this.toastrService.showSuccess(res.message,'Success');

      this.timeout = setTimeout(function () {

        location.reload();
      }, 1500);

      this.router.navigate(["/bookRequest"]);
      },err=>{

        hideLoader();
        this.toastrService.showError(err.error.message,'Error');
      })
    }

  // toggle() {
  //   this.show_dialog = !this.show_dialog;
  // }

}
