import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DiscoverComponent } from './discover/discover.component';
import { HeaderLivlouderComponent } from '../common/header-livlouder/header-livlouder.component';
import { FooterLivlouderComponent } from '../common/footer-livlouder/footer-livlouder.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DiscoverDetailsComponent } from './discover-details/discover-details.component';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { DiscoverGroupDetailsComponent } from './discover-group-details/discover-group-details.component';
import { GigListComponent } from './gig-list/gig-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GigDetailsComponent } from './gig-details/gig-details.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MyGigLivlouderComponent } from './my-gig-livlouder/my-gig-livlouder.component';
import { ChatLivlouderComponent } from './chat-livlouder/chat-livlouder.component';
import { MyGigAssignDetailsLivlouderComponent } from './my-gig-assign-details-livlouder/my-gig-assign-details-livlouder.component';
import { MyGigCompletedDetailsLivlouderComponent } from './my-gig-completed-details-livlouder/my-gig-completed-details-livlouder.component';
import { GroupsListComponent } from './groups-list/groups-list.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { MyGigAssignDisputeDetailsLivlouderComponent } from './my-gig-assign-dispute-details-livlouder/my-gig-assign-dispute-details-livlouder.component';
import { DisputeDetailsComponent } from './dispute-details/dispute-details.component';
import { ProfileLivlouderComponent } from './profile-livlouder/profile-livlouder.component';
import { BookMeRequestComponent } from './book-me-request/book-me-request.component';
import { GroupRequestComponent } from './group-request/group-request.component';
import { SettingsComponent } from './settings/settings.component';
import { NgxStripeModule } from 'ngx-stripe';
import { GigMapComponent } from './gig-map/gig-map.component';
import { GigRequestDetailsComponent } from './gig-request-details/gig-request-details.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { LivlouderBankSettingComponent } from './livlouder-bank-setting/livlouder-bank-setting.component';
import { NotificationListLivlouderComponent } from '../common/notification-list-livlouder/notification-list-livlouder.component';
import { DisputeChatComponent } from './dispute-chat/dispute-chat.component';
import { PostedByDetailsComponent } from './posted-by-details/posted-by-details.component';
import { ContactNumberLivlouderUpdateComponent } from './contact-number-livlouder-update/contact-number-livlouder-update.component';
import { GigMapListComponent } from './gig-map-list/gig-map-list.component';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    DiscoverComponent,
    HeaderLivlouderComponent,
    FooterLivlouderComponent,
    DiscoverDetailsComponent,
    DiscoverGroupDetailsComponent,
    GigListComponent,
    // NgbModule,
    GigDetailsComponent,
    MyGigLivlouderComponent,
    ChatLivlouderComponent,
    MyGigAssignDetailsLivlouderComponent,
    MyGigCompletedDetailsLivlouderComponent,
    GroupsListComponent,
    GroupDetailsComponent,
    MyGigAssignDisputeDetailsLivlouderComponent,
    DisputeDetailsComponent,
    ProfileLivlouderComponent,
    BookMeRequestComponent,
    GroupRequestComponent,
    SettingsComponent,
    GigMapComponent,
    GigRequestDetailsComponent,
    LivlouderBankSettingComponent,
    NotificationListLivlouderComponent,
    DisputeChatComponent,
    PostedByDetailsComponent,
    ContactNumberLivlouderUpdateComponent,
    GigMapListComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    NgxSliderModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgxStickySidebarModule,
    AngularSvgIconModule.forRoot(),
    NgxStripeModule.forRoot(environment.striePkKey),
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: environment.placeApiKey
    })
  ],
  exports: [
    HeaderLivlouderComponent,
    FooterLivlouderComponent
  ]
})
export class LivlouderModule { }
