import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationService {

  constructor(private toastr: ToastrService) { }
  
  showSuccess(message, title){
    this.toastr.clear();
    this.toastr.success(message, title)
  }
  
  showError(message, title){
    this.toastr.clear();
    this.toastr.error(message, title)
  }
  
  showInfo(message, title){
    this.toastr.clear();
    this.toastr.info(message, title)
  }
  
  showWarning(message, title){
    this.toastr.clear();
    this.toastr.warning(message, title)
  }

}
