import { Component, OnInit } from '@angular/core';

declare var $:any

@Component({
  selector: 'app-my-gig-completed-details-livlouder',
  templateUrl: './my-gig-completed-details-livlouder.component.html',
  styleUrls: ['./my-gig-completed-details-livlouder.component.css']
})
export class MyGigCompletedDetailsLivlouderComponent implements OnInit {
  show = false;
  public show_dialog : boolean = false;
  constructor() { }

  ngOnInit(): void {

    $('#imageGallery').lightGallery({
      thumbnail:true,
      selector: '.addedImg',
      download: false
    }); 
    $('.modal').on('hidden.bs.modal', function () {
      if($(".modal:visible").length > 0) {
      $('body').addClass('modal-open');
      }else{
      $('body').removeClass('modal-open');
      }
      });
  }
  toggle() {
    this.show_dialog = !this.show_dialog;
  }

}
