import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GroupRequest } from "src/app/services/group-request";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { Title } from "@angular/platform-browser";
import { AuthService } from "src/app/services/auth.service";
import { SignupService } from "src/app/services/signup.service";
import { HttpParams } from "@angular/common/http";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-groups-list",
	templateUrl: "./groups-list.component.html",
	styleUrls: ["./groups-list.component.css"]
})
export class GroupsListComponent implements OnInit {
	limit: any = 1;
	offset: any = 0;
	total: any;
	dataFound = false;
	groupList;
	metaId;
	timeout: any;
	title = " Livloud Group - List";
	currentuser;
	currentuserId;

	constructor(
		private groupRequest: GroupRequest,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private authService: AuthService,
		private signupService: SignupService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		this.titleService.setTitle(this.title);

		$('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
		this.authService.setUserMetaId("1");
		this.metaId = this.authService.getUserMetaId();
		this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
		this.currentuserId = this.currentuser.userId

		this.getList();
	}

	getList() {
		var obj: any = {};
		obj.userId = this.currentuserId;

		showLoader();
		this.groupRequest.getgroupList(obj).subscribe(
			data => {
				hideLoader();

				if (data.message == "Record found") {
					this.groupList = data.data;
					this.total = data.length;
					this.dataFound = true;
				} else {
					this.groupList = [];
					this.dataFound = false;
					this.total = 0;
				}
			},
			err => {
				hideLoader();
				if(err.error.status=='fail' && err.error.message=='Invalid token'){
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				}else{
				this.toastrService.showError(err.error.message, 'Error');
				}
	
			}
		);
	}

	
}
