import { Component, OnInit } from '@angular/core';

declare var $:any

@Component({
  selector: 'app-my-gigs-details-completed-hirer',
  templateUrl: './my-gigs-details-completed-hirer.component.html',
  styleUrls: ['./my-gigs-details-completed-hirer.component.css']
})
export class MyGigsDetailsCompletedHirerComponent implements OnInit {
  show = false;
  public show_dialog : boolean = false;

  constructor() { }

  ngOnInit(): void {

    $('#imageGallery').lightGallery({
      thumbnail:true,
      selector: '.addedImg',
      download: false
    }); 
    $('.modal').on('hidden.bs.modal', function () {
      if($(".modal:visible").length > 0) {
      $('body').addClass('modal-open');
      }else{
      $('body').removeClass('modal-open');
      }
    });
  }

  toggle() {
    this.show_dialog = !this.show_dialog;
  }

}
