import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderHirerComponent } from '../common/header-hirer/header-hirer.component';
import { FooterHirerComponent } from '../common/footer-hirer/footer-hirer.component';
import { MyGigsHirerComponent } from './my-gigs-hirer/my-gigs-hirer.component';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';

import { DiscoverHirerComponent } from './discover-hirer/discover-hirer.component';
import { MyGigsDetailsHirerComponent } from './my-gigs-details-hirer/my-gigs-details-hirer.component';
import { CreateGigComponent } from './create-gig/create-gig.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MyGigsDetailsAssignedHirerComponent } from './my-gigs-details-assigned-hirer/my-gigs-details-assigned-hirer.component';
import { MyGigsConfirmHirerComponent } from './my-gigs-confirm-hirer/my-gigs-confirm-hirer.component';
import { MyGigsDetailsCompletedHirerComponent } from './my-gigs-details-completed-hirer/my-gigs-details-completed-hirer.component';
import { DiscoverDetailsHirerComponent } from './discover-details-hirer/discover-details-hirer.component';
import { ChatHirerComponent } from './chat-hirer/chat-hirer.component';
import { DiscoverGroupDetailsHirerComponent } from './discover-group-details-hirer/discover-group-details-hirer.component';
import { ProfileHirerComponent } from './profile-hirer/profile-hirer.component';
import { ProfilePrivateHirerComponent } from './profile-private-hirer/profile-private-hirer.component';
import { GigPaymentCardComponent } from './gig-payment-card/gig-payment-card.component';
import { NgxStripeModule } from 'ngx-stripe';
import { SettingsHirerComponent } from './settings-hirer/settings-hirer.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditGigComponent } from './edit-gig/edit-gig.component';
import { GigMapHirerComponent } from './gig-map-hirer/gig-map-hirer.component';
import { AgmCoreModule } from '@agm/core';
import { TrackGigComponent } from './track-gig/track-gig.component';
import { AgmDirectionModule } from 'agm-direction';
import { NotificationListComponent } from '../common/notification-list/notification-list.component';
import { HirerDisputeChatComponent } from './hirer-dispute-chat/hirer-dispute-chat.component';
import { ContactNumberUpdateComponent } from './contact-number-update/contact-number-update.component';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    HeaderHirerComponent,
    FooterHirerComponent,
    MyGigsHirerComponent,
    DiscoverHirerComponent,
    MyGigsDetailsHirerComponent,
    CreateGigComponent,
    MyGigsDetailsAssignedHirerComponent,
    MyGigsConfirmHirerComponent,
    MyGigsDetailsCompletedHirerComponent,
    DiscoverDetailsHirerComponent,
    ChatHirerComponent,
    DiscoverGroupDetailsHirerComponent,
    ProfileHirerComponent,
    ProfilePrivateHirerComponent,
    GigPaymentCardComponent,
    SettingsHirerComponent,
    EditGigComponent,
    GigMapHirerComponent,
    TrackGigComponent,
    NotificationListComponent,
    HirerDisputeChatComponent,
    ContactNumberUpdateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxStickySidebarModule,
    AngularSvgIconModule.forRoot(),
    NgxStripeModule.forRoot(environment.striePkKey),
    GooglePlaceModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: environment.placeApiKey
    }),

  ],
  exports: [
    HeaderHirerComponent,
    FooterHirerComponent,
  ],
})
export class HirerModule { }
