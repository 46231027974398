import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { Options } from "@angular-slider/ngx-slider";
import { FormControl, FormGroup } from "@angular/forms";
import { GigService } from "src/app/services/gig-service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { AuthService } from "src/app/services/auth.service";
import { HttpParams } from "@angular/common/http";
import { SignupService } from "src/app/services/signup.service";
import { Openaccess } from "src/app/services/openaccess";
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: "app-open-gig-list",
  templateUrl: "./open-gig-list.component.html",
  styleUrls: ["./open-gig-list.component.css"],
})
export class OpenGigListComponent implements OnInit, AfterViewInit {
  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([0, 300]),
    sliderControlPrice: new FormControl([0, 450]),
  });

  sliderValue;
  sliderEvents: boolean = false;

  options: Options = {
    floor: 0,
    ceil: 300,
    step: 5,
  };
  priceSlider: Options = {
    floor: 0,
    ceil: 450,
    step: 5,
  };
  locationoptions = {
    // componentRestrictions: {
    //   country: ["UK"];
    // };

    componentRestrictions: { country: 'UK' }
  };

  lat;
  long;
  gigLists;

  dataFound = false;
  dataCount: any;
  title = "Livloud - Gig Lists";
  typeSubtype: any;
  typeListData = [];
  subTypeArray = [];
  subTypeSelected = [];
  typeIdSelected = [];
  subTypeIdSelected = [];

  lng: any;
  location: any;
  suburb: any;
  limit: any = 20;
  offset: any = 0;
  total: any;
  flag: any;
  // priceFilter;
  //added code...
  priceFilter: string = "desc";
  priceFilters: string = "descc";
  constructor(
    private gigService: GigService,
    private toastrService: ToastrNotificationService,
    private titleService: Title,
    private authService: AuthService,
    private signupService: SignupService,
    private Openaccess: Openaccess,
    private router: Router,

  ) { }
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    // this.authService.setUserMetaId(1);

    showLoader();
    this.getLocation();

    // $(document).on('hover', '[data-toggle="tooltip"]', function () { $(this).tooltip('show'); });
    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

    this.Openaccess.getTypeSubType().subscribe((data) => {

      this.typeSubtype = data.data;
    });
  }

  ngAfterViewInit(): void {
    // $('#radio12').trigger('click');
    // $('#clickid').trigger('click');
  }

  public handleAddressChange(address: any) {
    this.lat = address.geometry.location.lat();
    this.long = address.geometry.location.lng();
    this.location = address.formatted_address;
    this.suburb = address.name;
  }

  sideBarViewOpen() {
    let body = document.getElementsByTagName("body")[0];
    let withcalc = document.getElementById("sideBarView");
    let modalOverlay = document.getElementById("modalOverlay");

    body.classList.add("overflow");
    modalOverlay.classList.add("overlay-show");
    withcalc.classList.add("withcalc");
  }
  sideBarViewClose() {
    let body = document.getElementsByTagName("body")[0];
    let withcalc = document.getElementById("sideBarView");
    let modalOverlay = document.getElementById("modalOverlay");

    body.classList.remove("overflow");
    withcalc.classList.remove("withcalc");
    modalOverlay.classList.remove("overlay-show");
  }
  applyFilters() {

    this.offset = 0;
    this.applyFilter();
  }
  /**
   * Apply Filter
   */
  // applyFilter(offset?) {
  async applyFilter(offset?, flag?) {



    let body = document.getElementsByTagName("body")[0];
    let withcalc = document.getElementById("sideBarView");
    let modalOverlay = document.getElementById("modalOverlay");

    body.classList.remove("overflow");
    withcalc.classList.remove("withcalc");
    modalOverlay.classList.remove("overlay-show");

    let searchText = $("#search").val();

    //added code...
    let locationName = $("#floatingInput").val();

    var obj: any = {};
    obj.search = searchText;
    let distanceMin = $("#distance_filter b span:first").text();
    let distanceMax = $("#distance_filter b").find("span:eq(1)").text();
    obj.distanceMin = distanceMin;
    obj.distanceMax = distanceMax;

    //added code...
    obj.locationName = locationName;

    //price slider filter apply than this code will execute
    if (this.sliderEvents) {
      let priceFrom = $("#priceTo b span:first").text();
      let priceTo = $("#priceTo b").find("span:eq(1)").text();

      if (priceFrom != 0 || priceTo != 450) {
        obj.priceFrom = priceFrom;
        obj.priceTo = priceTo;
      }
    }

    obj.price = this.priceFilter;
    obj.prices = this.priceFilters;

    //type subtype filter
    let type = this.typeIdSelected.toString();
    let subType = this.subTypeIdSelected.toString();

    obj.type = type;
    obj.subtype = subType;

    if (!offset) {
      obj.offset = 0;
    } else {
      obj.offset = this.offset;
    }
    // console.log('offset',obj.offset)
    let paramObj = await this.createParam(obj);
    if (flag == 1) {
      this.getLivlouderGigNew(paramObj);
    } else {
      this.gigLists = this.getLivlouderGig(paramObj);
      // console.log('gigLists',this.gigLists);
    }

    //this.getLivlouderGig(paramObj);
  }

  //Reset Filter
  sideBarReset() {
    location.reload();
  }

  //Price slider event .if slide than price range between 0-450
  sliderEvent() {
    this.sliderEvents = true;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.long = position.coords.longitude;
          this.lat = position.coords.latitude;

          this.callApi(this.long, this.lat);
          this.getList(this.lat, this.long);
        },
        function (error) {
          if (error.code == error.PERMISSION_DENIED) {
            alert(
              "Please enable your location permission if you want to access gigs list."
            );
          }
        }
      );
    } else {
      hideLoader();
      this.toastrService.showError("No support for geolocation", "Error");
    }
  }

  getList(lat, long) {
    let searchText = $("#search").val();
    // var metaId = this.authService.getUserMetaId();

    var paramObj = {
      currentLat: this.lat,
      currentLong: this.long,
      offset: 0,
      search_text: searchText,
    };

    this.getLivlouderGig(paramObj);
  }

  /**
   * Seach Gig
   * @param searchValue
   */
  searchGig(searchValue) {
    this.offset = 0;
    this.applyFilter();
  }

  savedGig() {
    let searchText = $("#search").val();
    var paramObj = { offset: 0, search_text: searchText };
    showLoader();
    this.gigService.savedGig(paramObj).subscribe((res) => {
      hideLoader();
      if (res.data_found == false) {
        this.gigLists = [];
        this.dataFound = false;
        this.total = 0;
      } else {
        this.gigLists = res.data.gig;
        this.dataFound = true;
        this.total = res.data.total_record;
      }
    });
  }

  callApi(Longitude: number, Latitude: number) {
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`;

    //Call API
  }

  /**
   * Create http param for filter gig
   * @param obj
   * @returns
   */
  createParam(obj) {

    let params = new HttpParams()
      .set("offset", "offset" in obj ? obj.offset : "0")
      .set("distanceMax", "")
      .set("search_text", "search" in obj ? obj.search : "")
      .set("distanceMin", "distanceMin" in obj ? obj.distanceMin : "")
      .set("distanceMax", "distanceMax" in obj ? obj.distanceMax : "")
      .set("currentLat", this.lat.toString())
      .set("currentLong", this.long.toString())
      // .set('metaId', metaId)
      .set("rate", "")
      .set("type", "type" in obj ? obj.type : "")
      .set("subtype", "subtype" in obj ? obj.subtype : "")
      .set("hot", "")
      .set("price", "price" in obj ? obj.price : "")
      .set("prices", "prices" in obj ? obj.prices : "")
      .set("all", "")
      .set("location", "location" in obj ? obj.location : "")
      .set("priceTo", "priceTo" in obj ? obj.priceTo : "")
      .set("rating", "")
      .set("priceFrom", "priceFrom" in obj ? obj.priceFrom : "")
      .set("locationName", "locationName" in obj ? obj.locationName : "");

    return params;
  }

  /**
   * Get all gig list
   */
  allGig() {
    // var metaId = this.authService.getUserMetaId();
    let searchText = $("#search").val();
    this.offset = 0;
    var paramObj = {
      currentLat: this.lat,
      currentLong: this.long,
      offset: 0,
      search_text: searchText,
    };


    this.getLivlouderGig(paramObj);
  }

  getLivlouderGig(paramObj) {
    showLoader();
    this.Openaccess.getList(paramObj).subscribe(
      (res) => {

        hideLoader();
        if (res.data_found == true) {
          this.dataFound = res.data_found;
          this.gigLists = res.data;



          this.total = res.total_records;

        } else {
          this.dataFound = res.data_found;
          this.gigLists = res.data;
          this.total = 0;
        }
      },
      (err) => {
        hideLoader();

        this.toastrService.showError(err.error.message, "Error");
      }
    );
  }
  getLivlouderGigNew(paramObj) {
    showLoader();

    this.Openaccess.getList(paramObj).subscribe(
      (res) => {

        hideLoader();
        if (res.data_found == true) {
          this.dataFound = res.data_found;
          //this.gigLists = res.data;

          var newList = res.data;
          this.gigLists = this.gigLists.concat(newList);

          this.total = res.total_records;

        } else {
          this.dataFound = res.data_found;
          this.gigLists = res.data;
          this.total = 0;
        }
      },
      (err) => {
        hideLoader();

        this.toastrService.showError(err.error.message, "Error");
      }
    );
  }
  /**
   * Filter gig according to asc or desc
   * 1 means low to high and 2 high to low
   * @param order
   */
  filterPrice(order) {
    // alert(order);
    this.priceFilter = order;

    this.offset = 0;

    this.applyFilter();
  }

  filterLivlouder(order) {
    // /    var metaId = this.authService.getUserMetaId();
    let params = new HttpParams()
      .set("offset", this.offset)
      .set("distanceMax", "")
      .set("search_text", "")
      .set("distanceMin", "")
      // .set('userMetaId', metaId)
      .set("rate", "")
      .set("subtype", "")
      .set("hot", "")
      .set("price", order)
      .set("all", "")
      .set("location", "")
      .set("priceTo", "")
      .set("rating", "")
      .set("priceFrom", "");
    showLoader();
    this.gigService.livLouderFilter(params).subscribe(
      (res) => {
        $("#priceFilter").hide();
        hideLoader();
        if (res.data_found == true) {
          this.dataFound = res.data_found;
          this.gigLists = res.data;
          this.dataCount += res.total_records;
        }
      },
      (err) => {
        hideLoader();

        this.toastrService.showError(err.error.message, "Error");
      }
    );
    //livlouder
  }

  // loadMore() {
  //   this.offset = this.offset + this.limit;
  //   this.applyFilter(this.offset);
  // }
  loadMore() {

    this.offset = this.offset + this.limit;
    var flag = 1;
    this.applyFilter(this.offset, flag);
  }

  over() {
    $("#priceFilter").show();
  }

  out() {
    $("#priceFilter").hide();
  }

  //Type subtype flter code
  //Type subtype code here

  onCheckboxChangeRole(type, event) {
    if (event.target.checked) {
      this.typeListData.push({
        categoryId: type.categoryId,
        name: type.name,
        parent_id: type.parent_id,
      });

      this.subTypeArray.push(type);
      this.typeIdSelected.push(type.categoryId);
    } else {
      for (var i = 0; i < this.typeListData.length; i++) {
        if (this.typeListData[i].categoryId == type.categoryId) {
          this.typeListData.splice(i, 1);
        }
      }

      for (var i = 0; i < this.subTypeArray.length; i++) {
        if (this.subTypeArray[i].categoryId == type.categoryId) {
          this.subTypeArray.splice(i, 1);
        }
      }

      //Remove subtype object from array
      for (i = 0; i < this.subTypeArray.length; i++) {
        if (this.subTypeArray[i] == type.categoryId) {
          this.subTypeArray.splice(i, 1);
        }
      }

      //remove typeid from selected type id array
      const itemindex = this.typeIdSelected.indexOf(type.categoryId);
      if (itemindex > -1) {
        this.typeIdSelected.splice(itemindex, 1);
      }
    }

    // if (this.typeListData.length == 0) {
    //  this.completeProfileForm.patchValue({ typeBlankCheck: "" });
    // } else {
    //  this.completeProfileForm.patchValue({ typeBlankCheck: "1" });
    // }
  }

  removeType(index) {
    let parent_id = this.typeListData[index].categoryId;
    var selectedtype = this.typeListData[index].categoryId;
    this.typeListData.splice(index, 1);
    const itemindex = this.typeIdSelected.indexOf(selectedtype);

    if (itemindex > -1) {
      this.typeIdSelected.splice(itemindex, 1);
    }

    //remove subtype

    this.subTypeSelected = this.subTypeSelected.filter(function (obj) {
      return obj.parentId !== parent_id;
    });

    for (var i = 0; i < this.subTypeArray.length; i++) {
      if (this.subTypeArray[i].categoryId == parent_id) {
        this.subTypeArray[i].subType.forEach((element) => {
          if (this.subTypeIdSelected.includes(element.categoryId)) {
            let idexitem = this.subTypeIdSelected.indexOf(element.categoryId);
            this.subTypeIdSelected.splice(idexitem, 1);
          }
        });
        this.subTypeArray.splice(i, 1);
      }
    }
  }

  onCheckboxChangesubType(subType, event) {
    if (event.target.checked) {
      this.subTypeSelected.push({
        categoryId: subType.categoryId,
        name: subType.name,
        parentId: subType.parent_id,
      });
      this.subTypeIdSelected.push(subType.categoryId);
    } else {
      for (var i = 0; i < this.subTypeSelected.length; i++) {
        if (this.subTypeSelected[i].categoryId == subType.categoryId) {
          this.subTypeSelected.splice(i, 1);
          //this.checkUncheckRole(option.id,false);
        }
      }

      //Remove selected sub type id
      const index = this.subTypeIdSelected.indexOf(subType.categoryId);
      if (index > -1) {
        this.subTypeIdSelected.splice(index, 1);
      }
    }

    // if (this.subTypeSelected.length == 0) {
    //  this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
    // } else {
    //  this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
    // }
  }

  loginForopenAccess() {
    // alert('hiiiiiii');
    this.router.navigate(['/login']);
  }


  removeSubType(index) {
    let selectedSubType = this.subTypeSelected[index].categoryId;
    this.subTypeSelected.splice(index, 1);
    const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
    if (index > -1) {
      this.subTypeIdSelected.splice(itemindex, 1);
    }
  }
}
