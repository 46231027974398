<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="form-wizard" id="msform">
                <form>
                    <div class="row">
                        <div class="col-lg-4">
                            <div stickySidebar topSpacing="100">
                                <div class="form-wizard-steps WizardSteps sidebar__inner">
                                    <!-- Step 2 -->
                                    <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 1}">
                                        <div class="media">
                                            <div class="bd-wizard-step-icon">
                                                <i class="fa fa-user"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="bd-wizard-step-title">Gig Info</h6>
                                                <p class="bd-wizard-step-subtitle">Enter some basic info about your gig
                                                    below</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Step 2 -->
                                    <!-- Step 3 -->
                                    <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 2}">
                                        <div class="media">
                                            <div class="bd-wizard-step-icon">
                                                <i class="fa fa-info-circle"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="bd-wizard-step-title">More Info</h6>
                                                <p class="bd-wizard-step-subtitle">Write a description about what is
                                                    your gig are and what you do</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Step 3 -->
                                    <!-- Step 4 -->
                                    <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 3}">
                                        <div class="media">
                                            <div class="bd-wizard-step-icon">
                                                <i class="fa fa-images"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="bd-wizard-step-title">Photos</h6>
                                                <p class="bd-wizard-step-subtitle">Share some fun photos of you doing
                                                    gigs or just your doing you!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Step 4 -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8" *ngIf="editData">
                            <fieldset style="display:block;" *ngIf="stepNo == 1">
                                <form [formGroup]="createGigStepOne" (ngSubmit)="createGig($event);">
                                    <div class="boxView">
                                        <div class="boxViewForm">
                                            <div class="">

                                                <!-- 
                                        <div class="grpType radioGroup mb-3">
                                            <div class="csRadio" id="radio12">
                                                <label>
                                                    <input type="radio" formControlName="gigType" id="radio1" value="1" name="gigType">
                                                    <div class="csRadioBox" id="clickid">
                                                        <span class="radioIcon"><i class="far fa-circle"></i></span>
                                                        <div>
                                                            Private 
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div> -->


                                                <!-- <div class="csRadio">
                                                <label>
                                                    <input type="radio" value="0" formControlName="gigType" name="gigType" checked>
                                                    <div class="csRadioBox">
                                                        <span class="radioIcon"><i class="far fa-circle"></i></span>
                                                        <div>
                                                            Public 
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                                        </div>
                                                    </div>
                                                </label>
                                                <small class="text-danger" *ngIf="createGigStepOne.get('gigType').invalid">
                                                    Title is required field.
                                                </small>   
                                            </div>
                                        </div> -->



                                                <!-- <div class="grpType radioGroup mb-3">
                                            <div class="csRadio" id="radio12">
                                                <label>
                                                    <input type="radio" formControlName="gigType" id="radio1" value="1" name="gigType">
                                                    <div class="csRadioBox" id="clickid">
                                                        <span class="radioIcon"><i class="far fa-circle"></i></span>
                                                        <div>
                                                            Private 
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="csRadio" id="radio123">
                                                <label>
                                                    <input type="radio" value="0" formControlName="gigType" name="gigType">
                                                    <div class="csRadioBox" id="clickid123">
                                                        <span class="radioIcon"><i class="far fa-circle"></i></span>
                                                        <div>
                                                            Public 
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                                        </div>
                                                    </div>
                                                </label>
                                              
                                            </div>
                                        </div> -->





                                                <div class="grpType radioGroup mb-3">
                                                    <div id="radio12" class="csRadio">
                                                        <label class="decBoxToggle">

                                                            <input type="radio" (click)="Private(1)" id="Public"
                                                                value="1" name="gigType" [checked]="public == 1">
                                                            <div class="csRadioBox">
                                                                <span class="radioIcon"><i
                                                                        class="far fa-circle"></i></span>
                                                                <div>
                                                                    Private
                                                                    <p>Private gigs are not showcased publicly and can
                                                                        only be seen by invited Livlouders</p>
                                                                </div> <!-- <div>Private</div> -->
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div class="csRadio ng-star-inserted">
                                                        <label class="decBoxToggle">
                                                            <input type="radio" value="0" (click)="Private(0)"
                                                                name="gigType" id="remote2" [checked]="public == 0">
                                                            <!-- <div class="csRadioBox ">
                                                        <span class="radioIcon"><i class="far fa-circle"></i></span>
                                                        <div> Public </div>
                                                    </div> -->
                                                            <div class="csRadioBox" id="clickid123">
                                                                <span class="radioIcon"><i
                                                                        class="far fa-circle"></i></span>
                                                                <div>
                                                                    Public
                                                                    <p>Publicly posted gigs is seen by many lvilouders
                                                                        and bid on give you more options for price and
                                                                        quality</p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="text" class="form-control" formControlName="title"
                                                        id="floatingInput" placeholder="Write your Gig Title">
                                                    <label for="floatingInput">Gig Title <span
                                                            class="reqField">*</span></label>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('title').touched && createGigStepOne.get('title').invalid">
                                                        Title is required field.
                                                    </small>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="number" class="form-control" formControlName="price"
                                                        id="floatingInput" placeholder="Enter rough budget for gig" (keypress)="onKeyPress($event)">
                                                    <label for="floatingInput">Gig Price <span
                                                            class="reqField">*</span></label>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('price').touched && createGigStepOne.get('price').hasError('required')">
                                                        Price is required field.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('price').hasError('pattern')">
                                                        Price must be valid.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('price').hasError('maxlength')">
                                                        Please enter minimum 6 digits.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('price').errors && createGigStepOne.get('price').errors.min">
                                                        Price must be minimum $1.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepOne.get('price').errors && createGigStepOne.get('price').errors.max">
                                                        Price should be less than or equals to $999999.99
                                                    </small>
                                                </div>




                                                <div>
                                                    <h3 class="locationtyp">Location Type</h3>
                                                    <div class="grpType radioGroup d-flex mb-2">
                                                        <div id="radio12" class="csRadio">
                                                            <label class="decBoxToggle">
                                                                <input type="radio" formcontrolname="remote1"
                                                                    (click)="changerdo(1)" id="remote1" value="1"
                                                                    name="remote1" [checked]="radioButton == 1">
                                                                <div class="csRadioBox border-0 pl-0">
                                                                    <span class="radioIcon"><i
                                                                            class="far fa-circle"></i></span>
                                                                    <div> Remote Location </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="csRadio ng-star-inserted">
                                                            <label class="decBoxToggle">
                                                                <input type="radio" value="0" formcontrolname="remote2"
                                                                    (click)="changerdo(0)" name="remote1" id="remote2"
                                                                    [checked]="radioButton == 0">
                                                                <div class="csRadioBox  border-0">
                                                                    <span class="radioIcon"><i
                                                                            class="far fa-circle"></i></span>
                                                                    <div> Actual Location </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="gigLc mb-4" *ngIf="radioButton == 0">
                                                    <div class="form-floating">
                                                        <!-- <input ngx-google-places-autocomplete [options]='options' formControlName="location" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/> -->

                                                        <input ngx-google-places-autocomplete [options]='options'
                                                            #placesRef="ngx-places"
                                                            (onAddressChange)="handleAddressChange($event);" type="text"
                                                            class="form-control" formControlName="location"
                                                            id="floatingInput" placeholder="Enter Location">

                                                        <!-- <input type="text" class="form-control" formControlName="location" id="floatingInput" placeholder="Enter"> -->
                                                        <label for="floatingInput">Gig Location <span
                                                                class="reqField">*</span></label>
                                                        <small class="text-danger"
                                                            *ngIf="createGigStepOne.get('location').touched && createGigStepOne.get('location').invalid">
                                                            Location is required field.
                                                        </small>
                                                    </div>
                                                    <p>Your gig Address will remain hidden to suburb until you have
                                                        assigned a livlouder</p>
                                                </div>
                                                <div class="csLabelBox mb-3">
                                                    <h2>Gig Start Date & Time</h2>
                                                    <div class="row">
                                                        <div class="dateIcon col-6">
                                                            <div class="form-floating">
                                                                <input class="form-control" placeholder="yyyy-mm-dd"
                                                                    id="startDate" name="dp" formControlName="startDate"
                                                                    [(ngModel)]="pickerModel" [minDate]="minDate"
                                                                    ngbDatepicker #d="ngbDatepicker"
                                                                    (click)="d.toggle()" readonly>
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary calendar"
                                                                        (click)="d.toggle()" type="button"></button>
                                                                </div>
                                                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                                                <label for="floatingInput">Select Date</label>
                                                                <small class="text-danger"
                                                                    *ngIf="createGigStepOne.get('startDate').touched && createGigStepOne.get('startDate').invalid">
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('startDate').hasError('required')">Start
                                                                        date is required field.</span>
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('startDate').hasError('inValidStartDate')">Start
                                                                        date must be equal or less than end date.</span>
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-floating">
                                                                <input type="time"
                                                                    class="form-control dateNtime startTime" value=""
                                                                    formControlName="startTime" id="floatingInput"
                                                                    placeholder="Select">
                                                                <label for="floatingInput">Select Time</label>
                                                                <small class="text-danger"
                                                                    *ngIf="createGigStepOne.get('startTime').touched && createGigStepOne.get('startTime').invalid">
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('startTime').hasError('inValidTime')">
                                                                        Start time should be less than end time.</span>
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('startTime').hasError('required')">
                                                                        Start time is required field.</span>

                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="csLabelBox mb-3">
                                                    <h2>Gig End Date & Time</h2>
                                                    <div class="row">
                                                        <div class="dateIcon col-6">
                                                            <div class="form-floating">
                                                                <input class="form-control" placeholder="yyyy-mm-dd"
                                                                    id="endDate" formControlName="endDate" name="dp1"
                                                                    [(ngModel)]="endDatePicker" [minDate]="minDate"
                                                                    ngbDatepicker #d1="ngbDatepicker"
                                                                    (click)="d1.toggle()" readonly>
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary calendar"
                                                                        (click)="d1.toggle()" type="button"></button>
                                                                </div>
                                                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                                                <label for="floatingInput">Select Date</label>
                                                                <small class="text-danger"
                                                                    *ngIf="createGigStepOne.get('endDate').touched && createGigStepOne.get('endDate').invalid">
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('endDate').hasError('required')">End
                                                                        date is required field.</span>
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('endDate').hasError('inValidStartDate')">End
                                                                        date must be equal or greater than start
                                                                        date.</span>

                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-floating">
                                                                <input type="time"
                                                                    class="form-control dateNtime endTime"
                                                                    formControlName="endTime" id="floatingInput"
                                                                    placeholder="Select">
                                                                <label for="floatingInput">Select Time</label>
                                                                <small class="text-danger"
                                                                    *ngIf="createGigStepOne.get('endTime').touched && createGigStepOne.get('endTime').invalid">
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('endTime').hasError('inValidTime')">
                                                                        End time should be greater than start
                                                                        time.</span>
                                                                    <span
                                                                        *ngIf="createGigStepOne.get('endTime').hasError('required')">End
                                                                        time is required field </span>


                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="csLabelBox mb-3">
                                            <h2>Gig End Date & Time</h2>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control dateNtime" id="floatingInput" placeholder="Select" readonly>
                                                        <label for="floatingInput">Select Date</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control dateNtime" id="floatingInput" placeholder="Select" readonly>
                                                        <label for="floatingInput">Select Time</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                            </div>
                                            <div class="appAction text-center mt-30">
                                                <!-- <a routerLink="/userType" class="btn thm-btnGray">Cancel</a>
                                        &nbsp; -->
                                                <button type="submit" class="btn thm-btn">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                            <fieldset style="display:block;" *ngIf="stepNo == 2">
                                <form [formGroup]="createGigStepTwo" (ngSubmit)="stepTwo($event)">
                                    <div class="boxView">
                                        <div class="boxViewForm">
                                            <div class="">



                                                <h3 class="locationtyp">Type</h3>

                                                <div>
                                                    <div class="grpType radioGroup d-flex mb-2">
                                                        <div id="radio12" class="csRadio">
                                                            <label class="decBoxToggle">
                                                                <input type="radio" formcontrolname="solo_group"
                                                                    (click)="solo_group_type(1)" id="solo_group1"
                                                                    value="1" name="solo_group"
                                                                    [checked]="editData.typeId != null">
                                                                <div class="csRadioBox border-0 pl-0">
                                                                    <span class="radioIcon"><i
                                                                            class="far fa-circle"></i></span>
                                                                    <div>Solo </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="csRadio ng-star-inserted">
                                                            <label class="decBoxToggle">
                                                                <input type="radio" value="2"
                                                                    formcontrolname="solo_group"
                                                                    (click)="solo_group_type(2)" name="solo_group"
                                                                    id="solo_group2"
                                                                    [checked]="editData.typeId == null">
                                                                <div class="csRadioBox  border-0">
                                                                    <span class="radioIcon"><i
                                                                            class="far fa-circle"></i></span>
                                                                    <div>Group </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="addTagBlk mb-3" *ngIf="value1 == 1">
                                                    <div class="addTagBlkTitle">
                                                        <label>Type <span class="reqField">*</span></label>
                                                        <div class="textLink">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#roleModal" class="">Add Role</a>
                                                        </div>
                                                    </div>
                                                    <div class="TypeSlect"
                                                        *ngFor="let type of typeListData, let i=index;">

                                                        <div class="mediaItem">
                                                            <h6>{{type.name}}<span (click)="removeType(i)"><i
                                                                        class="fa fa-times"></i></span></h6>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="addTagBlk mb-3" *ngIf="value1 == 2">
                                                    <div class="addTagBlkTitle">
                                                        <label>Type <span class="reqField">*</span></label>
                                                        <div class="textLink">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#roleModal1" class="">Add Role</a>
                                                        </div>
                                                    </div>
                                                    <div class="TypeSlect"
                                                        *ngFor="let type of typeListData1, let i=index;">

                                                        <div class="mediaItem">
                                                            <h6>{{type.name}}<span (click)="removeType1(i)"><i
                                                                        class="fa fa-times"></i></span></h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="createGigStepTwo.get('typeBlankCheck').invalid && (createGigStepTwo.get('typeBlankCheck').dirty || createGigStepTwo.get('typeBlankCheck').touched)"
                                                    class="error">
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepTwo.get('typeBlankCheck').errors.required">
                                                        Type is required field.
                                                    </small>
                                                </div>
                                                <div class="addTagBlk mb-3" *ngIf="value1 == 1">
                                                    <div class="addTagBlkTitle">
                                                        <label>Subtypes <span class="reqField">*</span></label>
                                                        <div class="textLink">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#typeModal" class="">Add Type</a>
                                                        </div>
                                                    </div>
                                                    <div class="TypeSlect">

                                                        <div class="mediaItem"
                                                            *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                                            <h6>{{selectedSubType.name}}<span
                                                                    (click)="removeSubType(i)"><i
                                                                        class="fa fa-times"></i></span></h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="addTagBlk mb-3" *ngIf="value1 == 2">
                                                    <div class="addTagBlkTitle">
                                                        <label>Subtypes <span class="reqField">*</span></label>
                                                        <div class="textLink">
                                                            <a href="javascript:void(0);" data-toggle="modal"
                                                                data-target="#typeModal1" class="">Add Type</a>
                                                        </div>
                                                    </div>
                                                    <div class="TypeSlect">

                                                        <div class="mediaItem"
                                                            *ngFor="let selectedSubType of subTypeSelected1; let i=index;">
                                                            <h6>{{selectedSubType.name}}<span
                                                                    (click)="removeSubType1(i)"><i
                                                                        class="fa fa-times"></i></span></h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="createGigStepTwo.get('subTypeBlankCheck').invalid && (createGigStepTwo.get('subTypeBlankCheck').dirty || createGigStepTwo.get('subTypeBlankCheck').touched)"
                                                    class="error">
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepTwo.get('subTypeBlankCheck').errors.required">
                                                        Sub type is required field.
                                                    </small>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <textarea rows="4" type="text" formControlName="about"
                                                        class="form-control textArea" id="floatingInput"
                                                        placeholder="Write here..."></textarea>
                                                    <label for="floatingInput">Write Short About Gig <span
                                                            class="reqField">*</span></label>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepTwo.get('about').touched && createGigStepTwo.get('about').hasError('required')">
                                                        About is required field.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepTwo.get('about').hasError('maxlength')">
                                                        Please enter no more than 300 characters.
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="createGigStepTwo.get('about').hasError('minlength')">
                                                        Please enter at least 5 characters.
                                                    </small>
                                                </div>
                                                <div class="csLabelBox mb-3">
                                                    <h2>Must Haves</h2>
                                                    <div class="addMoreBlk" formArrayName="mustHave">
                                                        <ng-container
                                                            *ngFor="let ex of createGigStepTwo.get('mustHave').controls; index as i">

                                                            <div class="input-group rightIcon">
                                                                <div class="form-floating">
                                                                    <input type="text" formControlName="{{i}}"
                                                                        class="form-control phone" id="floatingInput"
                                                                        placeholder="Enter...">
                                                                    <label for="floatingInput">Enter <span
                                                                            class="reqField">*</span></label>
                                                                    <small class="text-danger"
                                                                        *ngIf="formSubmit && createGigStepTwo.get('mustHave').controls[i].hasError('required')">
                                                                        This is required field.
                                                                    </small>
                                                                </div>
                                                                <div class="removeField" *ngIf="i > 0"
                                                                    (click)="removeMustHave(i);">
                                                                    <span class="fa fa-times"></span>
                                                                </div>
                                                            </div>

                                                        </ng-container>
                                                    </div>
                                                    <div class="textLink text-right">
                                                        <a href="javascript:void(0);" (click)="addMoreMustHave();">Add
                                                            More</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="appAction text-center mt-30">
                                                <a href="javascript:void(0);" (click)="previousStep(1)"
                                                    class="btn thm-btnGray btn-previous">Previous</a>
                                                &nbsp;
                                                <button type="submit" class="btn thm-btn">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                            <fieldset style="display:block;" *ngIf="stepNo == 3">
                                <div class="boxView">
                                    <div class="boxViewForm1">
                                        <div class="setupPrHead">
                                            <h2>Add Photos</h2>
                                            <p>Share some fun photos of you doing gigs or just your doing you!</p>
                                        </div>
                                        <div class="">
                                            <div class="galleryImgAdd">
                                                <div class="log_div gallery-img text-center mt-30">

                                                    <label class="uploadLabel">
                                                        <input accept="image/png, image/jpeg" class="inputfile hideDiv"
                                                            id="file-2" (change)="onUploadFile($event);"
                                                            name="profileImage" style="display: none;" type="file">
                                                        <img src="assets/img/camera_placeholder.png" id="pImg">
                                                    </label>
                                                </div>
                                                <ng-container *ngFor="let images of editData.gig_images">
                                                    <div class="log_div gallery-img text-center mt-30">
                                                        <div class="addedImg">
                                                            <img src="{{images.image_url}}">
                                                            <div class="text-center upload_pic_in_album bsnes-cam">
                                                                <label class="upload_picNew">
                                                                    <span data-toggle="tooltip"
                                                                        (click)="removeImage(images.imageId);"
                                                                        data-placement="top" title=""
                                                                        class="fas fa-times"
                                                                        data-original-title="Remove Image"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <small class="text-danger"
                                                *ngIf="uploadImageForm.get('fileSource').touched && uploadImageForm.get('fileSource').invalid">
                                                Image is required field.
                                            </small>
                                        </div>
                                        <div class="appAction text-center mt-30">
                                            <a href="javascript:void(0);" (click)="previousStep(2)"
                                                class="btn thm-btnGray btn-previous">Previous</a>
                                            &nbsp;
                                            <a href="javascript:void(0);" (click)="saveGig();"
                                                class="btn thm-btn">Update Gig</a>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>


<!-- <ng-template *ngIf="value1 == 1"> -->

<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype">
                        <div class="typesHead"> {{ category.name }}</div>


                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>

                                    <!-- <div class="checkbox"> -->
                                    <div class="checkbox12">
                                        <label>
                                            <input type="radio" name="radiobtn"
                                                [checked]="(type.categoryId==selectedTypeID)"
                                                (click)="onCheckboxChangeRole(type,$event)">

                                            <!--  <ng-template *ngIf="typeIdSelected">
                                    <input type="radio" *ngIf="typeIdSelected.includes( type.categoryId)" name="radiobtn" checked value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)" >
                                    <label>hui</label>
                                    </ng-template>

                                     <ng-template *ngIf="!typeIdSelected">
                                    <input type="radio" name="radiobtn" value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)">
                                                                        <label>hello</label>

                                    </ng-template> -->

                                            <span class="cr">
                                                <!-- <i class="cr-icon fa fa-check" ></i> -->
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </ng-template> -->


<!-- <ng-template *ngIf="value1 == 2"> -->
<div class="modal fade csModal" id="roleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype1">
                        <div class="typesHead"> {{ category.name }}</div>


                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox12">
                                        <label>
                                            <!--                                     <input type="radio" *ngIf="typeIdSelected1.includes( type.categoryId)" name="radiobtn" checked value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)" >
                                    <input type="radio" *ngIf="!typeIdSelected1.includes( type.categoryId)" name="radiobtn"  value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)" > -->

                                            <input type="radio" name="radiobtn1"
                                                [checked]="(type.categoryId==selectedGroupTypeID)"
                                                (click)="onCheckboxChangeRole1(type,$event)">

                                            <!-- <ng-template *ngIf="typeIdSelected1">
                                    <input type="radio" *ngIf="typeIdSelected1.includes( type.categoryId)" name="radiobtn" checked value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)" >
                                    </ng-template>
                                    <input type="radio" *ngIf="typeIdSelected1" name="radiobtn"  value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)" > -->


                                            <span class="cr">
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- </ng-template> -->

<div class="modal fade csModal" id="typeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Sybtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="selectedIdSubType.includes(subT.categoryId)"
                                                class="subtypeBox" checked value="{{subT.categoryId}}"
                                                (click)="onCheckboxChangesubType(subT,$event)">
                                            <input type="checkbox" *ngIf="!selectedIdSubType.includes(subT.categoryId)"
                                                class="subtypeBox" value="{{subT.categoryId}}"
                                                (click)="onCheckboxChangesubType(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray.length == 0">Please select type first</div>



                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade csModal" id="typeModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray1">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="selectedIdSubType1.includes(subT.categoryId)"
                                                class="subtypeBox" checked value="{{subT.categoryId}}"
                                                (click)="onCheckboxChangesubType1(subT,$event)">
                                            <input type="checkbox" *ngIf="!selectedIdSubType1.includes(subT.categoryId)"
                                                class="subtypeBox" value="{{subT.categoryId}}"
                                                (click)="onCheckboxChangesubType1(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray1.length == 0">Please select type first</div>



                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>