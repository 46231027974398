import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import { SignupService } from "src/app/services/signup.service";
import { FirebaseService } from '../../services/firebaseL.service';
import { ToastrNotificationService } from "../../services/toastr-notification.service";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-login-profile",
	templateUrl: "./login-profile.component.html",
	styleUrls: ["./login-profile.component.css"]
})
export class LoginProfileComponent implements OnInit {
	title = "List";

	groupChoose: any;
	list;
	userMetaId;
	data;
	experience = [];
	type = [];
	subtype = [];
	socialMedia = [];
	profile;
	check_profile;
	metaId;
	getvalue;
	returnData;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private gigService: GigService,
		private titleService: Title,
		private authService: AuthService,
		private signupService: SignupService,
		private toastService: ToastrNotificationService,
		private firebaseService:FirebaseService
	) {}

	ngOnInit(): void {
		const currentUser = JSON.parse(localStorage.getItem("currentUser"));
		// console.log('check_local_storage_list',JSON.parse(localStorage.getItem("currentUser")));
		this.list = currentUser.livlouderList;
		// console.log('list',this.list);
	}

	// onMetaIdGet(userMetaId, event) {

	// }
	userMetaIdGet(e) {
		this.userMetaId = e.target.value;
		var data = "as_" + this.userMetaId;
		this.profile = $("#" + data)
			.closest("div")
			.find(".cp")
			.val();
	}

	test() {
		this.userMetaId;
		this.profile;

		if (this.userMetaId == undefined) {
		
			return false;
		}

		localStorage.setItem("metaId", this.userMetaId);
		// set user
		var currentUser = JSON.parse(localStorage.getItem("currentUser"));
		this.metaId = localStorage.getItem("metaId");

		this.check_profile = currentUser.livlouderList;

		for (let i = 0; i < this.check_profile.length; i++) {
			this.getvalue = this.check_profile[i];

			if (this.check_profile[i].userMetaId == this.metaId) {
				currentUser.userTypeList[0] = this.getvalue;
				localStorage.setItem("currentUser", JSON.stringify(currentUser));
			}
		}
		if (this.profile === "0") {
			this.router.navigate(["/createLivlouderProfile"]);
			return false;
		} else {

			let index = currentUser.livlouderList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')),
			livlouderUserSignupObj = {

							"meta_id":localStorage.getItem('metaId'),
							"online":1,
							"token":localStorage.getItem('device-token'),
							"type":"1",
							"user_id": currentUser.userId,
							"last_seen":""
			},

			livlouderUserMetaSignupObj = {

							"user_id": currentUser.userId,
							"typing":0,
							"name": currentUser.livlouderList[index].fullName,
							"profile_image": currentUser.livlouderList[index].profileImage,
							"online": 1,
							"user_type": 1
			};

			this.firebaseService.userAddFB(currentUser.userId,livlouderUserSignupObj);
			this.firebaseService.userMetaAddFB(localStorage.getItem('metaId'),livlouderUserMetaSignupObj);
			this.router.navigate(["/gigs"]);
		}
	}

    // create new profile  for liveloders 
	createNewProfile(formdata) {
		const formData = new FormData();

		this.signupService.createNewProfile(formData).subscribe(
			data => {
				// console.log('check_list',data.userMetaId);

			//return false;
				hideLoader();
				this.returnData = data;
				// console.log('returnData=', this.returnData.userMetaId);
				//return false;
				localStorage.setItem("metaId", this.returnData.userMetaId);
				// console.log(localStorage.getItem("metaId"));
				var currentUser = JSON.parse(localStorage.getItem("currentUser"));
                currentUser.livlouderList = data.livlouderList ;
				localStorage.setItem("currentUser", JSON.stringify(currentUser));
				this.router.navigate(["/createLivlouderProfile"]);
				


			},
			err => {
				hideLoader();
				this.toastService.showError(err.error.message, "Error");
			}
		);
	}
}
