import { Component, OnInit } from '@angular/core';

//import {StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
declare var $:any

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  // cardOptions: StripeCardElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: '#666EE8',
  //       color: '#31325F',
  //       fontWeight: '300',
  //       fontSize: '15px',
  //       '::placeholder': {
  //         color: '#ccc'
  //       }
  //     }
  //   }
  // };

  constructor() { }

  ngOnInit(): void {
    $('[data-toggle="tooltip"]').tooltip({ trigger:'hover' });
  }

}
