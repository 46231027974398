<footer>
    <!-- <div class="footer-top pt-30 pb-30">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="top-txt">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="media">
                                    <div class="icon mr-3">
                                        <i class="fas fa-home"></i>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mt-0">Website:</h5>
                                        <p>www.livloud.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="media">
                                    <div class="icon mr-3">
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mt-0">Email:</h5>
                                        <p>livloud@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="media">
                                    <div class="icon mr-3">
                                        <i class="fas fa-phone"></i>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mt-0">Phone:</h5>
                                        <p>+44-20-7328-1235</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="footer-down">
        <div class="container">
            <div class="row">
                <div
                    class="col-lg-6 col-md-6 col-sm-12 text-center text-sm-center text-md-left text-lg-left text-xl-left col-12">
                    <p>&copy; {{currentYear}} All Rights Reserved By <a class="color-base"
                            href="javascript:void(0);">Livloud</a></p>
                </div>
                <!-- <div
                    class="col-lg-6 col-md-6 col-sm-12 col-12 text-center text-sm-center text-md-right text-lg-right text-xl-right">
                    <ul class="follow">
                        <li>
                            <a href="javascript:void(0);" class="fb"><i class="fab fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="twitter"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="instagram"><i class="fab fa-instagram"></i></a>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>