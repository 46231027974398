import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
// import { HeaderLivlouderComponent } from './common/header-livlouder/header-livlouder.component';
// import { FooterLivlouderComponent } from './common/footer-livlouder/footer-livlouder.component';
import { LivlouderModule } from './livlouder/livlouder.module';
import { HirerModule } from './hirer/hirer.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoginComponent } from './common/login/login.component';
import { SignupComponent } from './common/signup/signup.component';
import { LivlouderCreateProfileComponent } from './common/livlouder-create-profile/livlouder-create-profile.component';
import { ProfileCreateDoneComponent } from './common/profile-create-done/profile-create-done.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GroupSetupComponent } from './common/group-setup/group-setup.component';
import { UserTypeComponent } from './common/user-type/user-type.component';
import { HirerCreateProfileComponent } from './common/hirer-create-profile/hirer-create-profile.component';
import { HirerCreateProfilePrivateComponent } from './common/hirer-create-profile-private/hirer-create-profile-private.component';
import { BasicInfoPageComponent } from './common/basic-info-page/basic-info-page.component';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { FaqPageComponent } from './common/faq-page/faq-page.component';

import { ToastrModule } from 'ngx-toastr';
import { HeaderInterceptor } from './auth/HeaderInterceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { AgmCoreModule } from '@agm/core';
import { StripeSuccessComponent } from './stripe-success/stripe-success.component';
import { LoginProfileComponent } from './common/login-profile/login-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { LivlouderUpdateProfileComponent } from './common/livlouder-update-profile/livlouder-update-profile.component';
import { OpenGigListComponent } from './common/open-gig-list/open-gig-list.component';
import { OpenGigDetailsComponent } from './common/open-gig-details/open-gig-details.component';
import { OpenDiscoverListComponent } from './common/open-discover-list/open-discover-list.component';
import { OpenDiscoverDetailsComponent } from './common/open-discover-details/open-discover-details.component';
import { HirerUpdateProfilePrivateComponent } from './common/hirer-update-profile-private/hirer-update-profile-private.component';
import { HirerUpdateProfileComponent } from './common/hirer-update-profile/hirer-update-profile.component';
import { CommonMapRouteComponent } from './common/common-map-route/common-map-route.component';
import { AgmDirectionModule } from 'agm-direction';
import { HelpPageComponent } from './common/help-page/help-page.component';
import { EditGroupComponent } from './common/edit-group/edit-group.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { PriceSliderDemoComponent } from './common/price-slider-demo/price-slider-demo.component';
import { TermsAndConditionsComponent } from './common/terms-and-conditions/terms-and-conditions.component';
import { PrivacyAndPolicyComponent } from './common/privacy-and-policy/privacy-and-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    // HeaderLivlouderComponent,
    // FooterLivlouderComponent,
    LoginComponent,
    SignupComponent,
    LivlouderCreateProfileComponent,
    ProfileCreateDoneComponent,
    GroupSetupComponent,
    UserTypeComponent,
    HirerCreateProfileComponent,
    HirerCreateProfilePrivateComponent,
    BasicInfoPageComponent,
    FaqPageComponent,
    StripeSuccessComponent,
    LoginProfileComponent,
    LivlouderUpdateProfileComponent,
    OpenGigListComponent,
    OpenGigDetailsComponent,
    OpenDiscoverListComponent,
    OpenDiscoverDetailsComponent,
    HirerUpdateProfilePrivateComponent,
    HirerUpdateProfileComponent,
    CommonMapRouteComponent,
    HelpPageComponent,
    EditGroupComponent,
    PriceSliderDemoComponent,
    TermsAndConditionsComponent,
    PrivacyAndPolicyComponent,
  ],
  imports: [
    BrowserModule,
    NgxSliderModule,
    AppRoutingModule,
    LivlouderModule,
    HirerModule,
    CarouselModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxStickySidebarModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    GooglePlaceModule,
    SocialLoginModule,
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: environment.placeApiKey
    }),
    AgmDirectionModule,

    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)

  ],
  providers: [MessagingService, AsyncPipe,
    // providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true, },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.FacebookClientId
            )
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
