// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-hirer-update-profile-private',
//   templateUrl: './hirer-update-profile-private.component.html',
//   styleUrls: ['./hirer-update-profile-private.component.css']
// })
// export class HirerUpdateProfilePrivateComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HirerProfileService } from '../../services/hirer-profile.service';
import { AuthService } from '../../services/auth.service';
import { SignupService } from '../../services/signup.service';
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { CommonService } from '../../services/common.service'
//import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';


declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
	selector: 'app-hirer-update-profile-private',
	templateUrl: './hirer-update-profile-private.component.html',
	styleUrls: ['./hirer-update-profile-private.component.css']
})
export class HirerUpdateProfilePrivateComponent implements OnInit {

	businessType: any;
	basicInfoFrom: FormGroup;
	completeStepTwo: FormGroup;
	socialLinksForm: FormGroup;
	uploadImageForm: FormGroup;
	photoSkip = 1;

	stepNo = 2;
	userType = 2;
	skipLinks: boolean = true;

	// options: {
	// 	componentRestrictions: {
	// 		country: ['AU']
	// 	}
	// }


	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}

	lat: any;
	lng: any;
	suburb: any;
	data: any;
	title = "Hirer- Detail"
	openingHour = [];
	image = [];
	videos = [];
	socialMedia = [];
	current_user;
	switched_user;
	profileImage;
	location

	constructor(
		private hirerService: HirerProfileService,
		private authService: AuthService,
		private signupService: SignupService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private commonService: CommonService,
		private hirerDetail: HirerProfileService,

	) { }

	ngOnInit(): void {
		this.details();

		//if user profile is complete than redirect to gigs .
		// return true if prfole is complete otherwise return false
		localStorage.setItem("SelectedUser", '2');

		// if(this.authService.getHirerProfile()){
		// 	this.router.navigate(['/myGigsHirer']);

		//   }

		this.basicInfoFrom = new FormGroup({
			'name': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
			'about': new FormControl(null, [Validators.maxLength(300), Validators.minLength(5), this.noWhitespaceValidator]),

			'location': new FormControl(null, [Validators.required]),
			//'venue':new FormControl (null, [Validators.required]),


			'profileImage': new FormControl(null, [Validators.required]),

		})

		var stepNo = this.authService.getHirerBoardingProfileCount();

		this.authService.setUserMetaId(this.userType);

		this.stepNo = 2;//parseInt(stepNo)+1;

		this.hirerService.getBusinessType().subscribe(data => {

			this.businessType = data.data;
		}, err => {
		})

		this.hirerDetail.detail(this.authService.userMetaId).subscribe(res => {
			this.data = res.detail;
			this.profileImage = this.data.profileImage;
			this.location = this.data.location;
			this.lat = this.data.currentLat;
			this.lng = this.data.currentLong;
			this.suburb = this.data.suburb;

			this.basicInfoFrom = new FormGroup({
				'name': new FormControl(this.data.name, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
				'about': new FormControl(this.data.about, [Validators.maxLength(300), Validators.minLength(5), this.noWhitespaceValidator]),

				'location': new FormControl(this.data.location, [Validators.required]),
				//'venue':new FormControl (null, [Validators.required]),


				'profileImage': new FormControl(this.data.profileImage, [Validators.required]),

			})
			// })

			// this.completeStepTwo = new FormGroup({
			//   'about': new FormControl(null,[Validators.maxLength(300),Validators.minLength(5)]),

			//   'openingHour': new FormArray([
			//     new FormControl(null,[Validators.required]),

			//   ])
			// })

			// this.uploadImageForm = new FormGroup({
			//   'file': new FormControl(null),
			//   "fileSource": new FormControl(null)
			// })


			// this.socialLinksForm = new FormGroup({
			//   'links': new FormArray([
			//     new FormControl(null),

			//   ]),
			//   "socialMedia":new FormControl(null)
			// })
		})

	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	public handleAddressChange(address: any) {
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		this.location = address.formatted_address;

		//console.log(suburb);


	}
	// details 
	details() {
		this.authService.setUserMetaId(this.userType);

		this.hirerDetail.detail(this.authService.userMetaId).subscribe(res => {
			this.data = res.detail;
			// alert(this.data.name);

			// if(this.data.venueOpeningHours){
			//   this.openingHour = this.data.venueOpeningHours.split(',');
			// }
			if (this.data.venueOpeningHours) {
				this.openingHour = JSON.parse(this.data.venueOpeningHours);
			}

			if (this.data.image_media.count > 0) {
				this.image = this.data.image_media.data;
			}

			if (this.data.video_media.count > 0) {
				this.videos = this.data.video_media.data;
			}

			this.socialMedia = this.data.social_media;

		}, err => {
			this.toastService.showError(err, 'Error');
		})
	}

	onFileChange(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.basicInfoFrom.patchValue({

				'profileImage': file

			});

		}

	}


	stepOne(event) {
		if (this.basicInfoFrom.invalid) {

			this.validateAllFormFields(this.basicInfoFrom);
			return false;

		} else {


			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append('location', this.location);
			//	formData.append('venueName', this.basicInfoFrom.value.venue)
			formData.append('businessType', '1');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '2')
			formData.append('isPrivate', '1')
			formData.append('userType', '2')
			formData.append('isUpdate', '1')

			formData.append('profileImage', this.basicInfoFrom.value.profileImage)
			formData.append('latitude', this.lat)
			formData.append('longitude', this.lng)
			formData.append('suburb', this.suburb)
			formData.append('name', this.basicInfoFrom.value.name)
			formData.append('name', this.basicInfoFrom.value.name)

			if (this.basicInfoFrom.value.about) {
				formData.append('about', this.basicInfoFrom.value.about)

			}

			showLoader();
			this.signupService.stepOne(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");
				this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)
				localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
				this.details();
				this.router.navigate(['/profileHirer']);
			}, error => {
				hideLoader();

				this.toastService.showError(error.error.message, "Error");
				//this.error = error.message;
			});
		}
	}

	addOpeningHour() {
		(<FormArray>this.completeStepTwo.get('openingHour')).push(new FormControl(null, [Validators.required]));

	}

	removeOpeningHour(index) {
		(<FormArray>this.completeStepTwo.get('openingHour')).removeAt(index)

	}

	stepTwo() {

		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append('venueAbout', this.completeStepTwo.value.about);
			formData.append('openingTime', JSON.stringify(this.completeStepTwo.value.openingHour))
			formData.append('userType', '2');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '3')
			formData.append('isPrivate', '1')

			showLoader();

			this.signupService.stepTwo(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)

				var step = this.authService.getHirerBoardingProfileCount();
				this.stepNo = parseInt(step) + 1;

			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}



	}

	onUploadFile(event) {
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file
			});

			this.photoSkip = 2;

		}
	}

	UploadImgStepThree() {
		var metaId = this.authService.getUserMetaId();
		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append('metaId', metaId)
			formData.append('onBordingStep', '4')
			formData.append('type', '2')
			this.signupService.skipPhoto(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

				//    var step = this.authService.getOnBoardingProfileCount();
				//    console.log(step);
				//    this.stepNo = parseInt(step)+1;
				this.stepNo = 5;
			}, error => {
				hideLoader();
				this.toastService.showError(error.error.message, "Error");
				this.stepNo = 5;
				//this.error = error.message;
			});
		} else {




			const formData = new FormData();
			formData.append('media', this.uploadImageForm.value.fileSource);
			formData.append('userType', '2');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '4')
			formData.append('mediaType', '1')


			//console.log(JSON.stringify(mediaType));
			showLoader();
			this.signupService.uploadImage(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

				//    var step = this.authService.getOnBoardingProfileCount();
				//    console.log(step);
				//    this.stepNo = parseInt(step)+1;
				this.stepNo = 5;
			});
		}
	}



	onSelectedVideo(event) {
		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append('authToken', this.authService.getAuthorizationToken());
					formData.append('metaId', userMetaId);
					formData.append('userType', '2');
					formData.append('mediaType', '2');
					formData.append('onBordingStep', '4')
					formData.append('media', file);
					formData.append('video_thumb', res.blobImage, 'video_thumb.png');

					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();
						this.authService.checkValidToken(data);

						if (data.status == 'fail') {
							this.toastService.showError(data.message, 'Error');
						} else {
							this.toastService.showSuccess(data.message, 'Success');

							this.authService.updateOnBoardingProfileCount(2, data.mediaData.onBoardingProfileCount)

							var step = this.authService.getHirerBoardingProfileCount();
							this.stepNo = parseInt(step) + 2;

							//this.stepNo = 6; 
							//this.getMediaList();
						}
					}, error => {
						hideLoader();
						this.toastService.showError(error.error.message, "Error");
					});
				} else {
					hideLoader();
					this.toastService.showError(res.message, 'Error');
					//  toastrError(res.message);
				}
			});

		}
	}



	addLinks() {
		(<FormArray>this.socialLinksForm.get('links')).push(new FormControl(null, [Validators.required]));
	}

	removeLinks(index) {
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index)
	}

	changeLinks(event) {
		var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;

		if (val) {
			this.skipLinks = false;
		} else {
			this.skipLinks = true;
		}



	}

	uploadLinks() {

		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm)
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();
			var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;
			if (!val) {
				this.skipPhoto(metaId);

				return false;
			}

			var linksArray = [];


			var linkSocial = this.socialLinksForm.value.links
			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({ type: 1, link: linkSocial[i] });
					}
				}


				this.socialLinksForm.patchValue({
					socialMedia: linksArray
				});

			}


			showLoader();
			const formData = new FormData();
			formData.append('socialMedia', JSON.stringify(this.socialLinksForm.value.socialMedia))

			formData.append('metaId', metaId)
			formData.append('onBordingStep', '5')

			this.signupService.uploadSocialLinks(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				//this.authService.updateOnBoardingProfileCount(2,data.mediaData.onBoardingProfileCount)

				// var step = this.authService.getHirerBoardingProfileCount();
				// console.log(step);
				localStorage.setItem('currentUser', JSON.stringify(data.userDetail));


				this.router.navigate(['/createProfileDone']);
			}, error => {
				hideLoader();
				this.toastService.showError(error.error.message, "Error");
				//this.error = error.message;
			});
		}
	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});

		return false;
	}


	skipPhoto(metaId) {

		showLoader();

		const formData = new FormData();

		formData.append('metaId', metaId)
		formData.append('onBordingStep', '5')
		formData.append('type', '1')
		this.signupService.skipPhoto(formData).subscribe((data) => {
			hideLoader();
			this.toastService.showSuccess(data.message, "Success");

			//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

			//    var step = this.authService.getOnBoardingProfileCount();
			//    console.log(step);
			//    this.stepNo = parseInt(step)+1;
			this.router.navigate(['/createProfileDone']);
		}, error => {
			hideLoader();
			this.toastService.showError(error.error.message, "Error");
			this.stepNo = 5;
			//this.error = error.message;
		});

	}

}
