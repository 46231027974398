<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
<section class="lsPage sec-pd-30">
    <div class="container">
        <div class="row no-gutter justify-content-center">
            <div class="col-md-6 col-lg-6 lsForm">
                <div class="lsFormView">
                    <div class="lsHead">
                        <h2 class="headText">Login your account</h2>
                    </div>
                    <div class="socialLogin">
                        <!-- <div class="socialItem" (click)="loginWithFacebook()">
                            <a class="fbIcon" ><i class="fab fa-facebook-f"></i></a>
                        </div> -->
                        
                        <!-- <div class="socialItem">  -->

                        <div class="socialItem" (click)="loginWithgoogle()"> 
                            <a class="gIcon" href="javascript:void(0)"><i class="fab fa-google"></i></a>
                        </div>
                        <!-- <div class="socialItem">
                            <a class="appleIcon" href="javascript:void(0)"><i class="fab fa-apple"></i></a>
                        </div> -->
                    </div>
                    <div class="emailtext">
                        <p>Or use your email account</p>
                    </div>
                    <form class="csForm" [formGroup]='reactiveForm' (ngSubmit)="onSubmit()">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" formControlName="email" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Email</label>
                            <small class="text-danger" *ngIf="reactiveForm.get('email').touched">
                               <span *ngIf="reactiveForm.get('email').hasError('required')">Email is required field.</span> 
                               <span *ngIf="reactiveForm.get('email').hasError('email')">Email address is not valid.</span> 
                            </small>
                            
                        </div>
                        <div class="form-floating mb-3">
                            <input [type]="show_button ? 'text' : 'password'" class="form-control" formControlName="password" id="floatingInput2" autocomplete="off" placeholder="••••••••">
                            <!-- <input [type]="show_button ? 'text' : 'password'"> -->
                            <span id="showPass" [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></span>

                            <label for="floatingInput">Password</label>
                            <small class="text-danger" *ngIf="reactiveForm.get('password').touched && reactiveForm.get('password').invalid">
                                Password is required field
                            </small>
                        </div>

                        <div class="text-right forgot-txt"><a data-toggle="modal" data-target="#forgotPWD" href="javascript:void(0);">Forgot Password?</a></div>
                        <div class="">
                            <!-- <button routerLink="/loginProfile" type="button" class="btn thm-btn btn-block">Log In</button> -->
                            <button  type="submit" class="btn thm-btn btn-block">Log In</button>

                        </div>
                    </form>
                    
                </div>
                <div class="text-center mb-3">
                    <!-- <button (click) = "createNewProfile(1)" class="textBtn">CREATE NEW PROFILE</button> -->
                    <button (click)="continueGuest()" class="textBtn">Continue as Guest</button>
                </div>
            </div>
         
            <div class="col-md-6 col-lg-4 lsBG">
                <div class="lsTextBlk">
                    <div class="lsTextView">
                        <h2>Welcome to Livloud</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <button routerLink="/signup" class="btn btn-themeW">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
<div class="modal fade csModal" id="forgotPWD" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-headerIcon">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
            <div class="modalHeadCnt">
                <svg-icon class="svgIcon" src="assets/img/forgot_password.svg"></svg-icon>
                <h2>Forgot Password?</h2>
                <p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
                <div class="giveReviewBox">
                    <div class="px-4">
                        <!-- <div class="form-floating mb-3">
                           <input class="form-control" type="text" placeholder="Enter Email">
                            <label for="floatingInput">Email <span class="reqField">*</span></label>
                        </div> -->
                        <!-- <form class="csForm" [formGroup]='forgotPwdForm' (ngSubmit)="forgotPass()"> -->
                            <form class="csForm" [formGroup]='forgotPwdForm' (ngSubmit)="forgotPass()">

                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" formControlName="email" id="floatingInput1" placeholder="name@example.com">
                                <label for="floatingInput">Email</label>
                                <small class="text-danger" *ngIf="forgotPwdForm.get('email').touched">
                                   <span *ngIf="forgotPwdForm.get('email').hasError('required')">Email is required field.</span> 
                                   <span *ngIf="forgotPwdForm.get('email').hasError('email')">Email address is not valid.</span> 
                                </small>
                                
                            </div>
                            <!-- <div class="text-right forgot-txt"><a data-toggle="modal" data-target="#forgotPWD" href="javascript:void(0);">Forgot Password?</a></div> -->
                            <!-- <div class="">
                                <button  type="submit" class="btn thm-btn btn-block">Log In</button>
    
                            </div> -->
                            <div class="modal-footer">
                                <button type="submit" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn thm-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn thm-btn">Submit</button>
      </div> -->
    </div>
  </div>
</div>
<app-footer-livlouder></app-footer-livlouder>