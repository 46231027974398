import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../services/local-storage.service'
import { FirebaseService } from '../../services/firebaseL.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { HirerProfileService } from 'src/app/services/hirer-profile.service';
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import { FirebaseDisputeService } from '../../services/firebase-dispute.service';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
// import { stat } from 'fs';
declare var $: any;
@Component({
  selector: 'app-hirer-dispute-chat',
  templateUrl: './hirer-dispute-chat.component.html',
  styleUrls: ['./hirer-dispute-chat.component.css']
})
export class HirerDisputeChatComponent implements OnInit {

  gigId;
  metaId;
  groupId;
  disputeData;
  is_dispute;
  isGroup: any = 0;
  userType: any = 2;
  chatNode;
  lastText;
  LivlouderName: any = '';
  hirerName: any = '';
  message;
  disputeTitle;
  other: any = {};
  otherDetail;
  hirerID;
  livLouderID;
  isClosed;
  chatNodeObj: any = {};
  chatBoxAllow = true;
  chatSideList: any = [];
  chatList: any = [];
  orderListSubscription: any = [];
  previewImage:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private FirebaseDisputeService: FirebaseDisputeService,
    private commonService: CommonService,
    private angularFireStorage: AngularFireStorage,
    private storage: AngularFireStorage,
    private hirerIDs: HirerProfileService,
    private toastrService: ToastrNotificationService,
    private authService: AuthService,
    private gigService: GigService,
  ) {

  }

  ngOnInit(): void {


    this.authService.setUserMetaId(this.userType);
    var metaId = this.authService.getUserMetaId();

    //  get giID grom url
    this.activatedRoute.paramMap.subscribe((param) => {
      this.gigId = param.get("id"); // get Url + used for convert string to number.
      this.metaId = localStorage.getItem('metaId');//get meta id from local 

      // set gig id for get dispute detail
      this.gigService.gigDetail(this.gigId, this.metaId).subscribe(
        (res) => {
          this.LivlouderName = res.data.assignedLivlouderName;
          this.hirerName = res.data.postedByName;
          this.isClosed = res.data.isClosed;
          this.disputeTitle = "Inprogress"
          if (this.isClosed == 1) {
            this.disputeTitle = "Closed"
          }
          this.groupId = res.data.groupId;
          this.is_dispute = res.data.is_dispute;

          if (res.data.userType == 3) {
            this.isGroup = 1;
          }

          this.lastText = '-P';
          if (res.data.userType == 3) {
            this.lastText = '-G';
          }

          // if (res.data.userType == 3) {
          //   this.livLouderID = res.data.assignUserId;
          // } else {
          //   this.livLouderID = res.data.assignUserMetaId;
          // }
          this.livLouderID = res.data.assignUserMetaId;

          this.hirerID = res.data.postedBymetaId;
          this.chatNode = this.hirerID + '-' + this.livLouderID + this.lastText;
          this.chatNodeObj = {
            gigId: this.gigId,
            chatNode: this.chatNode,
          }
          if (this.is_dispute == 1) {

            this.getOtherDetail();

          } else {
            this.createDispute();
          }

        },

        (err) => {
          // hideLoader();
          if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
            this.authService.checkValidToken(err.error);
          } else {
            this.toastrService.showError(err.error.message, 'Error');
          }

        }
      );

    });
  }


  createDispute() {

    var formData = new FormData();
    formData.append("gigId", this.gigId);
    formData.append("metaId", this.hirerID);
    formData.append("userType", this.userType);
    formData.append("otherId", this.livLouderID);
    formData.append("groupType", this.isGroup);

    this.gigService.createDispute(formData).subscribe(
      (res) => {

        if (res.status == "success") {
          let chatMessage: any = {
            closed: 0,
            closed_timestamp: '',
            disputed_by: this.hirerID,
            hirer_visibility: 1,
            livloud_visibility: 1,
            resolved_in_favour_id: "",
            resolved_in_favour_type: ''
          }

          this.otherDetail = this.FirebaseDisputeService.createNode(this.chatNodeObj, chatMessage);
          this.isClosed = 0;
          this.disputeTitle = "Inprogress"
        } else {
          this.toastrService.showError('Something went wrong please try again', 'Error');

        }
      })
  }


  getChatList() {

    // this.chatList = [];
    this.orderListSubscription = this.FirebaseDisputeService.getChatList(this.chatNodeObj).subscribe(chatData => {

      this.chatList = [];
      chatData.forEach(element => {

        let msg: any = element.payload.val();
        if (this.other.hirer_visibility == 1 && msg.last_sender_id == this.livLouderID) {
          msg.name = this.LivlouderName;
          this.chatList.push(msg);
        } else if (msg.last_sender_id != this.livLouderID) {
          msg.name = '';
          if (msg.last_sender_id == 'A') {
            msg.name = 'Admin';
          }
          this.chatList.push(msg);
        }
      });
    });
  }

  getOtherDetail() {
    
    this.otherDetail = this.FirebaseDisputeService.getOtherDetail(this.chatNodeObj).subscribe(otherData => {
      let i = 0;
      otherData.forEach(element => {

        if (i != 0) {
          this.other = element.payload.val();
        }
        i++;
      });

      this.getChatList();
    });


  }

  sendMsg() {
    if (this.message) {

      let chatMessage: any = {
        gig_id: this.gigId,
        hirer_id: this.hirerID,
        image: 0,
        image_url: "",
        isAdmin: 0,
        message: this.message,
        last_sender_id: this.hirerID,
        livlouder_id: this.livLouderID,
        msg_read_tick: 0,
        timestamp: Date.now(),
      }

      this.FirebaseDisputeService.sendMessageFirebase(this.chatNodeObj, chatMessage);
      // this.getChatList();
      if (this.other.livloud_visibility == 1) {
        this.sentNotification(this.message);
      }

    } else {
    }

    this.message = "";
  }


  //*************upload image********************
  fileUpload(event) {

    if (event.target.files.length > 0) {
      this.commonService.showLoader();
      const file = event.target.files[0];
      var ext = file.name.split('.').pop().toLowerCase();
      const filePath = environment.storageBucketFile + Date.now();
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file).then(() => {
        const ref = this.storage.ref(filePath);

        const downloadURL = ref.getDownloadURL().subscribe(url => {
          this.commonService.hideLoader();

          let chatMessage: any = {
            gig_id: this.gigId,
            hirer_id: this.hirerID,
            image: 1,
            image_url: url,
            isAdmin: 0,
            message: '',
            last_sender_id: this.hirerID,
            livlouder_id: this.livLouderID,
            msg_read_tick: 0,
            timestamp: Date.now(),
          }

          this.FirebaseDisputeService.sendMessageFirebase(this.chatNodeObj, chatMessage);
          // this.getChatList();
          if (this.other.livloud_visibility == 1) {
            this.sentNotification('Image');
          }
        });
      });
    }
  }
  //*************End upload image********************


  sentNotification(lastMessage) {

    var formData = new FormData();
    formData.append("gigId", this.gigId);
    formData.append("userType", '2');
    formData.append("chatNode", this.isGroup);
    formData.append("lastMsg", lastMessage);
    formData.append("otherDetail", this.other);


    this.gigService.sendDisputeChatNotification(formData).subscribe(
      (res) => {

        if (res.status == "success") {

        } else {
          // this.toastrService.showError('Something went wrong please try again', 'Error');

        }
      })
  }

  imagePreviewModal(imgUrl) {
    this.previewImage = imgUrl
    $("#imgPreview").modal("show");
  }

}
