import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  reviewSliderOption: OwlOptions= {
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
    ],
  }

  testimonialItem = [
    {
      userImg : "assets/img/1.jpg",
      userReview: "Understood instrument or do connection no appearance do invitation. Dried quick round it or order. Add past see west felt did any. plate you share. My resolve arrived is we chamber be removal.",
      userName: "Makhon Daino",
      userTitle: "Musician"
    },
    {
      userImg : "assets/img/2.png",
      userReview: "Understood instrument or do connection no appearance do invitation. Dried quick round it or order. Add past see west felt did any. plate you share. My resolve arrived is we chamber be removal.",
      userName: "Junl Sarukh",
      userTitle: "Guitarist"
    },
    {
      userImg : "assets/img/3.png",
      userReview: "Understood instrument or do connection no appearance do invitation. Dried quick round it or order. Add past see west felt did any. plate you share. My resolve arrived is we chamber be removal.",
      userName: "Makhon Daino",
      userTitle: "Musician"
    }
  ]

  constructor(
    private authService:AuthService,
    private router:Router
  ) { }

  ngOnInit() {
    if(this.authService.isLoggedIn()==true){

      if(this.authService.getUserRole() == 'legalName'){
        this.router.navigate(['/basicInfo']);
      }else{
        this.router.navigate(['/userType']);
        
      }
    }
  }

}
