<app-header-hirer></app-header-hirer>

<style>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container" *ngIf="gigData">
            <div class="gigsList">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <div>
                                        <h2>{{gigData.title}}</h2>
                                    </div>
                                    <div class="d-flex">

                                        <div _ngcontent-qvv-c58="" class="gigBtnActView">

                                            <a
                                                [routerLink]="['/chatHirer',gigData.postedBymetaId,gigData.assignUserMetaId]">
                                                <button *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0"
                                                    type="button" class="btn thm-btn-sm btnCircle"><span
                                                        class="fa fa-comment-alt"></span></button>

                                                <button *ngIf="gigData.gigStatus == 3 && gigData.isGigCancel == 0"
                                                    type="button" class="btn thm-btn-sm btnCircle"><span
                                                        class="fa fa-comment-alt"></span></button>
                                            </a>

                                        </div>

                                        <button *ngIf="gigData.isRemote !=1"
                                            [routerLink]="['/mapLiveRoute',gigData.gigId]" title="View on Map"
                                            data-toggle="tooltip" class="btn iconBtn" type="button">
                                            <span class="fa fa-map"></span>
                                        </button>
                                        <div *ngIf="gigData.gigStatus < 2" class="dropdown smallDropdown ml-2">
                                            <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                    data-toggle="tooltip" type="button">
                                                    <span class="fa fa-ellipsis-v"></span>
                                                </button>
                                            </span>

                                            <div class="dropdown-menu dropdown-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a *ngIf="gigData.gigStatus == 1 && gigData.offers.length == 0 && gigData.groupOffers.length == 0"
                                                    class="dropdown-item" href="javascript:void(0)"
                                                    [routerLink]="['/editGig',gigData.gigId]">Edit</a>
                                                <!-- <a *ngIf="gigData.gigStatus == 1 && gigData.is_open == 1" class="dropdown-item" href="javascript:void(0)" [routerLink]="['/editGig',gigData.gigId]">Edit</a> -->
                                                <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="deleteGigPopup()">Delete</a> -->
                                                <a *ngIf="gigData.gigStatus < 2" class="dropdown-item"
                                                    href="javascript:void(0)" (click)="deleteGigPopup()">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="jobOpen">
                                    <label>{{gigData.type}}</label><span *ngIf="gigData.typeId != null">(solo)</span>
                                    <span *ngIf="gigData.typeId == null">(group)</span>
                                </div>
                                <div class="priceBlk">
                                    <p>{{gigData.currency}}{{gigData.price}}</p>
                                </div>

                                <p *ngIf="gigData.isRemote == 0 || gigData.isRemote == null" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>{{gigData.location}}</span></p>
                                <p *ngIf="gigData.isRemote == 1" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>Remote</span></p>
                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">Sub Type</h2>
                                    <div class="typeBlk">
                                        <ng-container *ngFor="let subType of subtype">
                                            <div class="typeItem">{{subType}}</div>
                                        </ng-container>

                                    </div>
                                    <div>
                                        <p *ngIf="gigData.transactionType == 1">${{gigData.tipAmount}}(-5% Admin
                                            Commission)
                                        </p>
                                    </div>
                                </div>
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>{{gigData.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{gigData.endDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{gigData.time}}</h4>
                                        </div>
                                    </div>
                                </div>

                                <!-- {{isTrack}} -->
                                <div class="allbuttns">
                                    <div class="assgigAct">
                                        <button
                                            *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0 && isTrack ==true"
                                            type="button" [routerLink]="['/trackLivlouder',gigData.assignUserMetaId]"
                                            class="btn thm-btn-sm">Track Gig</button>
                                        <button *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0"
                                            data-toggle="modal" data-target="#cancelGig" type="button"
                                            class="btn thm-btn-sm btnRed">Cancel Gig</button>
                                    </div>
                                    <div *ngIf="gigData.isGigCancel == 1">
                                        <button data-toggle="modal" type="button"
                                            class="btn thm-btn-sm btnRed">Canceled</button>
                                    </div>
                                    <div class="assgigAct">
                                        <button *ngIf="gigData.gigStatus == 5 && gigData.isGigCancel == 0"
                                            data-toggle="modal" data-target="#releasePayment" type="button"
                                            class="btn thm-btn-sm">Release Payment</button>
                                        <button
                                            *ngIf="gigData.gigStatus == 6 && gigData.gigOwnerReview != 1 && gigData.isGigCancel == 0 "
                                            type="button" data-toggle="modal" data-target="#reviewModal"
                                            class="btn thm-btn-sm">Give Review</button>
                                        <button
                                            *ngIf="gigData.gigStatus == 6 && gigData.tipAmount == null || gigData.gigStatus == 5  && gigData.tipAmount == null || gigData.gigStatus == 4 && gigData.tipAmount == null"
                                            class="btn thm-btn-sm" href="javascript:void(0)"
                                            (click)="giveTipPopup()">Give
                                            Tip</button>
                                    </div>
                                    <!-- {{gigData.gigStatus}} -->
                                    <div *ngIf="(gigData.gigStatus >= '3' && gigData.gigStatus < 6 )|| (gigData.is_dispute ==1) ">
                                        <button [routerLink]="['/dispute-chat-hirer',gigData.gigId]" title="Dispute "
                                            data-toggle="tooltip" class="btn thm-btn-sm"
                                            type="button">{{disputeBtn}}</button>
                                    </div>
                                </div>
                                <p *ngIf="disputeClosed==1"><b>{{disputeMsg}}</b></p>
                                <!-- <div class="assgigAct" *ngIf="gigData.gigStatus == 5">
                                    <button type="button" data-toggle="modal" data-target="#reviewModal"  class="btn thm-btn-sm">Give Review</button>
                                </div> -->
                                <!-- <div class="ratePriceBlk">
                                    <div class="jobOpen">
                                        <label>Assigned</label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Gig</h2>
                                    <p class="aboutText statusCss">{{gigData.about}}</p>

                                </div>


                                <div *ngIf="gigData.gigStatus == 3 && gigData.isGigCancel ==0 " class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Work In Progress</p>
                                </div>



                                <div *ngIf="gigData.gigStatus == 4 && gigData.isGigCancel ==0 " class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Completed</p>
                                </div>

                                <div *ngIf="gigData.gigStatus == 5 && gigData.isGigCancel ==0 " class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Completed</p>
                                </div>



                                <div *ngIf="gigData.gigStatus == 6  &&  gigData.gigOwnerReview !=1 && gigData.isGigCancel ==0 "
                                    class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Completed</p>
                                </div>

                                <div *ngIf="gigData.gigStatus == 6  &&  gigData.gigOwnerReview ==1 && gigData.isGigCancel == 0 "
                                    class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Completed</p>
                                </div>

                                <div *ngIf="gigData.gigStatus > 1  && gigData.isGigCancel == 1" class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <p class="aboutText statusCss" id="statusCss">Completed</p>
                                </div>


                                <div class="userInfoItem">
                                    <h2 class="infoHead">Must Have</h2>
                                    <ul class="infoList" *ngFor="let must_have of mustHave">
                                        <li>{{must_have}}</li>

                                    </ul>
                                </div>
                                <div class="userInfoItem">
                                    <!-- <ng-container *ngFor=" let images of gigData.gig_images"> -->

                                    <h2 class="infoHead">Photos</h2>
                                    <!-- </ng-container> -->

                                    <div *ngIf="imgCheck.length == 0">
                                        Not available
                                    </div>

                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor=" let images of gigData.gig_images">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{images.image_url}}">
                                                    <img src="{{images.image_url}}">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <ul class="nav nav-tabs csTabs sideCsTab" id="myTab" role="tablist">
                                <li *ngIf="gigData.gigFor != 1" class="nav-item">
                                    <a class="nav-link {{ isQuestion ? '' : 'active'}}" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Offers
                                        ({{offers.length}})</a>
                                </li>
                                <!-- <li *ngIf="offers.length == 0 && gigData.assignUserMetaId == 0" class="nav-item">
                                    <a class="nav-link active" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Offers
                                        ({{offers.length}})</a>
                                </li> -->

                                <li *ngIf="gigData.gigFor == 1 && gigData.assignUserMetaId != 0" class="nav-item">
                                    <a class="nav-link active" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Assigned</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link {{ isQuestion ? 'active' : ''}}" id="csTab2-tab" data-toggle="tab" href="#csTab2" role="tab"
                                        aria-controls="csTab2" aria-selected="false">Questions ({{qusAns.length}})</a>
                                    <!-- <p *ngIf="qusAns.length == 0">No Question yet</p> -->
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade {{ isQuestion ? '' : 'show'}} {{ isQuestion ? '' : 'active'}}" id="csTab1" role="tabpanel"
                                    aria-labelledby="csTab1-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <h2 class="infoHead">Offers <span class="NoCount">(150)</span></h2> -->
                                        <!-- <div class="gigNodata" *ngIf="offers.length < 1 && gigData.gigFor !=1"> -->
                                        <!-- <div class="gigNodata" *ngIf="offers.length < 1 && gigData.gigFor !=1"> -->
                                        <!-- <div class="gigNodata" *ngIf="offers.length < 1 && gigData.gigFor !=1">

                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/no_offer.svg"></svg-icon>
                                                <h2>No Offers Yet</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry.
                                                </p>
                                            </div>

                                           
                                        </div> -->
                                        <div class="gigNodata"
                                            *ngIf="offers.length == 0 && gigData.assignUserMetaId == 0">

                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/no_offer.svg"></svg-icon>
                                                <h2>No Offers Yet</h2>
                                                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry.
                                            </p> -->
                                            </div>
                                        </div>
                                        <!-- {{gigData.assignedLivlouderName}} -->

                                        <div *ngIf="gigData.gigFor == 1 && gigData.assignUserMetaId !=0"
                                            class="reviewsList">

                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="{{gigData.assignedLivlouderProfileImage}}">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>{{gigData.groupName ? gigData.groupName : gigData.assignedLivlouderName}}</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <!-- <p>{{gigOffer.agoTime}}</p> -->
                                                        </div>

                                                    </div>

                                                    <!-- <div class="ratingView ml-auto">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>
                                                        </div> -->
                                                    <container_element [ngSwitch]="gigData.assignedLivlouderRating">
                                                        <inner_element *ngSwitchCase="0">
                                                            <div class="ratingView ml-auto">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </div>
                                                        </inner_element>

                                                        <inner_element *ngSwitchCase="1">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star "></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>
                                                        </inner_element>

                                                        <inner_element *ngSwitchCase="2">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>

                                                        </inner_element>

                                                        <inner_element *ngSwitchCase="3">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>

                                                        </inner_element>
                                                        <inner_element *ngSwitchCase="4">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>

                                                        </inner_element>
                                                        <inner_element *ngSwitchCase="5">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                            </div>

                                                        </inner_element>
                                                    </container_element>
                                                </div>

                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <p>{{gigData.currency}}{{gigData.price}}</p>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div *ngIf="gigData.gigFor == 1 && gigData.assignUserMetaId != 0"
                                                class="jobOpen">
                                                <label>Awarded</label>
                                            </div>
                                        </div>

                                        <ng-container *ngFor="let gigOffer of offers">
                                            <div class="reviewsList">

                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{gigOffer.profileImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{gigOffer.fullName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <!-- <p>{{gigOffer.agoTime}}</p> -->
                                                            </div>
                                                        </div>

                                                        <!-- <div class="ratingView ml-auto">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </div> -->
                                                        <container_element [ngSwitch]="gigOffer.rating">
                                                            <inner_element *ngSwitchCase="0">
                                                                <div class="ratingView ml-auto">
                                                                    <div class="ratingBlk">
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="1">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star "></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="2">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="3">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>
                                                            <inner_element *ngSwitchCase="4">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>
                                                            <inner_element *ngSwitchCase="5">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                </div>

                                                            </inner_element>
                                                        </container_element>
                                                    </div>

                                                    <div class="retDec offersInfo">
                                                        <div class="offerActBlk">
                                                            <div class="offerPrice">
                                                                <label>Offer Price</label>
                                                                <h3>${{gigOffer.price}}</h3>
                                                            </div>
                                                            <div class="" *ngIf="gigData.gigStatus == 1">
                                                                <!-- <button  (click)="navigateRoute(gigOffer)" type="button" class="btn thm-btn-sm">Accept</button> -->
                                                                <!-- <button  (click)="acceptOfferPopup" type="button" class="btn thm-btn-sm">Accept</button> -->
                                                                <button class="dropdown-item" href="javascript:void(0)"
                                                                    (click)="acceptOfferPopup(gigOffer)"
                                                                    class="btn thm-btn-sm">Accept</button>


                                                            </div>
                                                            <div class=""
                                                                *ngIf="gigData.assignUserMetaId == gigOffer.userMetaId">
                                                                <button disabled type="button"
                                                                    class="btn thm-btn-sm">Accepted</button>
                                                            </div>
                                                        </div>
                                                        <div class="offferText" *ngIf="gigOffer.comment != 'null'">

                                                            <p [class.show]="show">{{gigOffer.comment}}</p>
                                                            <button *ngIf="gigOffer.comment.length > 122"
                                                                (click)="show = !show">{{ show ? 'Show less': 'Show
                                                                More' }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>


                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="tab-pane fade {{ isQuestion ? 'show' : ''}} {{ isQuestion ? 'active' : ''}}" id="csTab2" role="tabpanel" aria-labelledby="csTab2-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <div class="gigNodata" *ngIf="qusAns.length < 1">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/conversation.svg"></svg-icon>
                                                <h2>No Questions Asked Yet</h2>
                                                <!--                                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry.
                                                </p> -->
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let Questions of qusAns; index as i;">
                                            <div class="reviewsList">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{Questions.postedByImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{Questions.postedByName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <!-- <p>{{Questions.agoTime}}</p> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="questionBox">
                                                            <h2><span>Q)</span>{{Questions.qus}}</h2>
                                                            <p *ngIf="Questions.ans"><span>Ans.</span>{{Questions.ans}}
                                                            </p>
                                                            <p *ngIf="!Questions.ans"><span>Ans.</span>
                                                                <i class="noreplay">Replied Not Yet!</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div *ngIf="Questions.ans == ''"
                                                            class="replyBox questionBoxView">
                                                            <label>Reply</label>
                                                            <div class="replyInBox">
                                                                <input type="text" class="form-control"
                                                                    (keyup)="enableDisableBtn(i,$event);" #question
                                                                    placeholder="Enter Answer">
                                                                <button type="button" [disabled]="!msgText[i]"
                                                                    (click)="replyAns(question.value,Questions.qusAnsId)"
                                                                    class="btn iconBtn"><i
                                                                        class="fa fa-paper-plane"></i></button>
                                                            </div>
                                                            <!-- <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/3.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Edmund Richards</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <a (click)="toggle()" href="javascript:void(0);">Reply</a></p>
                                                    </div>
                                                    <div class="replyBox" *ngIf="show_dialog">
                                                        <div class="replyInBox">
                                                            <input type="text" class="form-control" placeholder="Enter">
                                                            <button type="button" class="btn iconBtn"><i class="fa fa-paper-plane"></i></button>
                                                        </div>
                                                        <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/4.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Sophie Flowers</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/5.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Danny Reed</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <a (click)="toggle()" href="javascript:void(0);">Reply</a></p>
                                                    </div>
                                                    <div class="replyBox" *ngIf="show_dialog">
                                                        <div class="replyInBox">
                                                            <input type="text" class="form-control" placeholder="Enter">
                                                            <button type="button" class="btn iconBtn"><i class="fa fa-paper-plane"></i></button>
                                                        </div>
                                                        <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/6.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <a (click)="toggle()" href="javascript:void(0);">Reply</a></p>
                                                    </div>
                                                    <div class="replyBox" *ngIf="show_dialog">
                                                        <div class="replyInBox">
                                                            <input type="text" class="form-control" placeholder="Enter">
                                                            <button type="button" class="btn iconBtn"><i class="fa fa-paper-plane"></i></button>
                                                        </div>
                                                        <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>




<div class="modal fade csModal" id="addCard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add New Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="formModal">
                    <div class="formModalBlk">
                        <div class="cardAddBlk">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="inLabel">Enter Card Details <span class="req">*</span></label>
                                        <div class="addCardFieldBlk">
                                            <div class="cardFieldView">
                                                <ngx-stripe-card (change)="onChange($event)"
                                                    [elementsOptions]="elementsOptions" [options]="cardOptions">
                                                </ngx-stripe-card>
                                            </div>
                                            <button (click)="buy()" type="button" class="btn thm-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cardSequrity">
                                <p>Safe payment online using your credit card. We are secure, we will not share your
                                    details.
                                </p>
                                <div class="cardType">
                                    <img src="assets/img/card/american-express.png">
                                    <img src="assets/img/card/discover.png">
                                    <img src="assets/img/card/mastercard.png">
                                    <img src="assets/img/card/visa.png">
                                </div>
                            </div>
                            <!-- <div class="cardListBlk addedCard">
                                <h2 class="addedCardTitle">Added Card</h2>
                                <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                        <div class="crInfo">
                                            <h2>Discover Network</h2>
                                            <p>xxxx xxxx xxxx 1542</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" name="radio" checked=""><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div> -->
                            <h2 class="addedCardTitle">Added Card</h2>

                            <div class="cardListBlk" *ngFor="let list of cardLists">

                                <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/credit-card.svg"></div>
                                        <div class="crInfo">
                                            <h2>{{list.brand}}</h2>
                                            <p>xxxx xxxx xxxx {{list.last4}}</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" value="{{list.id}}"
                                                    class="devCheckedRadio" name="radio" checked><span
                                                    class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div>
                                <!-- <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                        <div class="crInfo">
                                            <h2>Discover Network</h2>
                                            <p>xxxx xxxx xxxx 1542</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" name="radio"><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Close</button>
                <!-- <button  type="button" class="btn thm-btn">Continue</button> -->
                <button data-toggle="modal" (click)="confirmPayment()" class="btn thm-btn ">Continue</button>

            </div>
        </div>
    </div>
</div>






<!--  Cancal  gig  -->
<div class="modal fade csModal" id="cancelGig" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/cancel_gig.svg"></svg-icon>
                    <h2>Cancel Gig</h2>
                    <p>Are you sure want to cancel the gig ? 10% will get deducted from your Job Price </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" data-toggle="modal" data-dismiss="modal" data-target="#cancelGigAlert"
                    class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="cancelGigAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                    <h2>Alert</h2>
                    <p>Are you sure want to cancel the gig ?</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" (click)="cancelGig()" class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you want to delete this gig.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" (click)="deleteGig(gigData.gigId)" class="btn btn-danger">Delete</button>
            </div>
        </div>
    </div>
</div>
<!-- Accept Model -->
<div class="modal fade" id="acceptModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you really want to accept this offer?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <!-- <button  (click)="navigateRoute(gigOffer)" type="button" class="btn thm-btn-sm">Accept</button> -->
                <!-- {{neha}} -->
                <button type="button" (click)="navigateRoute(neha)" class="btn btn-danger">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="releasePayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/payment.svg"></svg-icon>
                    <h2>Release Payment</h2>
                    <div class="paymentDeduct mt-3">
                        <h1>{{gigData.currency}}{{gigData.price}}
                        </h1>
                    </div>
                    <p>Amount will get debited from Escrow account and the Tasker receives payment into their verified
                        bank account within 3-5 business days.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button (click)="realsePayment()" type="button" class="btn thm-btn">Release</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="giveTip1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/tip.svg"></svg-icon>
                    <h2>Give Tip</h2>

                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="tipBlk mt-3">
                        <div class="defaultTip">
                            <div class="tipItem">
                                <label class="tipAmt">
                                    <input [(ngModel)]="duration" type="radio" name="tip" value="5">
                                    <span>$5</span>
                                </label>
                            </div>
                            <div class="tipItem">
                                <label class="tipAmt">
                                    <input [(ngModel)]="duration" type="radio" name="tip" value="10">
                                    <span>$10</span>
                                </label>
                            </div>
                            <div class="tipItem">
                                <label class="tipAmt">
                                    <input [(ngModel)]="duration" type="radio" name="tip" value="15">
                                    <span>$15</span>
                                </label>
                            </div>
                            <div class="tipItem">
                                <label class="tipAmt">
                                    <input [(ngModel)]="duration" type="radio" name="tip" value="20">
                                    <span>$20</span>
                                </label>
                            </div>
                        </div>
                        <div class="tipDevide">
                            <p>Or Enter Manual</p>
                        </div>
                        <div class="tipField mb-2">
                            <div class="input-group">
                                <div class="input-group-prepend" style="margin-right: -1px;height: 58px !important;">
                                    <span class="input-group-text" id="basic-addon1">$</span>
                                </div>
                                <div class="form-floating">
                                    <input type="text" class="form-control phone" id="tipamount"
                                        placeholder="Enter Amount" type="number" (keypress)="onKeyPress($event)">
                                    <label for="floatingInput">Tip Amount <span class="reqField">*</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <!-- <button type="button" class="btn thm-btn">Pay</button> -->
                <button (click)="giveTip()" type="button" class="btn thm-btn">Pay</button>

            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>

            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/rating.svg"></svg-icon>
                    <h2>Give Review</h2>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="giveReviewBox">
                        <div class="px-4">
                            <!-- <div class="form-floating mb-3">
                       <input class="form-control" type="text" placeholder="Enter Email">
                        <label for="floatingInput">Email <span class="reqField">*</span></label>
                    </div> -->
                            <!-- <form class="csForm" [formGroup]='forgotPwdForm' (ngSubmit)="forgotPass()"> -->
                            <form id="giveReview" class="csForm" [formGroup]='forgotPwdForm'
                                (ngSubmit)="reviewSubmit()">

                                <div class="ratingBlk">

                                    <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
                                    <!-- <p>Rate: {{currentRate}}</p> -->
                                </div>

                                <div class="form-floating mb-3">
                                    <textarea rows="4" type="text" class="form-control textArea"
                                        formControlName="comments" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Write Your Comments</label>
                                    <!-- <small class="text-danger" *ngIf="forgotPwdForm.get('comments').touched">
                                        <span *ngIf="forgotPwdForm.get('comments').hasError('required')">Comments is
                                            required field.</span>
                                    </small> -->
                                </div>

                                <div class="modal-footer">
                                    <button type="submit" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn thm-btn" id="myApplyId">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="modal-body">
              <div class="modalHeadCnt">
                  <svg-icon class="svgIcon" src="assets/img/rating.svg"></svg-icon>
                  <h2>Give Review</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <div class="giveReviewBox">
                     
                      <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
                <p>Rate: {{currentRate}}</p>
                      <div class="">
                          <div class="form-floating mb-3">
                              <textarea rows="4" type="text" class="form-control textArea" id="floatingInput" placeholder="Write something..."></textarea>
                              <label for="floatingInput">Write Your Comments <span class="reqField">*</span></label>
                          </div>
                      </div>
                  </div>
              </div>
        </div> -->
            <!-- <div class="modal-footer">
          <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn thm-btn">Submit</button>
        </div> -->
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>