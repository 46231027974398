import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import * as firebase from 'firebase';
var firebaseTime = firebase.database.ServerValue.TIMESTAMP;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDisputeService {

  constructor(
    private fbDB: AngularFireDatabase,
    private fireStorage: AngularFireStorage,
    private commonService: CommonService,
    private router: Router,
    private http: HttpClient
  ) { }


  getChatList(chatData: any) {

    return this.fbDB.list(`dispute_chat/${chatData.gigId}/${chatData.chatNode}/messages`, ref => ref.orderByChild('timestamp')).snapshotChanges().pipe(
      map(chat => {

        return chat;
      })
    );
  }

  getOtherDetail(chatData: any) {

    return this.fbDB.list(`dispute_chat/${chatData.gigId}/${chatData.chatNode}`).snapshotChanges();
  }

  sendMessageFirebase(chatData: any, message:any = '') {

    message.timestamp = firebaseTime;
    this.fbDB.list(`dispute_chat/${chatData.gigId}/${chatData.chatNode}/messages`).push(message);
  }

  createNode(chatData: any, message = '') {
    this.fbDB.object(`dispute_chat/${chatData.gigId}/${chatData.chatNode}/other`).set(message);
  }


}
