// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-posted-by-details',
//   templateUrl: './posted-by-details.component.html',
//   styleUrls: ['./posted-by-details.component.css']
// })
// export class PostedByDetailsComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Title } from "@angular/platform-browser";
import { AuthService } from "src/app/services/auth.service";
import { HirerProfileService } from "src/app/services/hirer-profile.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GigService } from "src/app/services/gig-service";

declare var $: any;

declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-posted-by-details',
	templateUrl: './posted-by-details.component.html',
	styleUrls: ['./posted-by-details.component.css']
})
export class PostedByDetailsComponent implements OnInit, AfterViewChecked {
	data: any;
	title = "Hirer- Detail";
	openingHour = [];
	image = [];
	videos = [];
	socialMedia = [];
	current_user;
	switched_user;
	current_user_data;
	reportForm: any;
	reportTitle: any;
	receiverLMId: string | Blob;
	SenderHMId: string | Blob;
	metaId;
	constructor(
		private hirerDetail: HirerProfileService,
		private authService: AuthService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private gigService: GigService,


	) { }

	ngOnInit(): void {


		this.activatedRoute.paramMap.subscribe(param => {
			this.metaId = param.get('id');
			this.titleService.setTitle(this.title);
			// this.authService.setUserMetaId("2");
			// var metaId = this.authService.getUserMetaId();

			this.hirerDetail.detail(this.metaId).subscribe(
				res => {
					this.data = res.detail;

					if (this.data.venueOpeningHours) {
						this.openingHour = JSON.parse(this.data.venueOpeningHours);
					}

					if (this.data.image_media.count > 0) {
						this.image = this.data.image_media.data;
					}

					if (this.data.video_media.count > 0) {
						this.videos = this.data.video_media.data;
					}

					this.socialMedia = this.data.social_media;
				},
				err => {
					this.toastrService.showError(err, "Error");
				}

			);
		}


		)
		this.gigService.getReportTitle().subscribe((res) => {
			this.reportTitle = res.data;
		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				// this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		})

		this.reportForm = new FormGroup({
			title: new FormControl(null, Validators.required),
			description: new FormControl(null, [
				Validators.required,
				Validators.maxLength(300),
				Validators.minLength(5),
			]),
		});
	}


	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}

	// report Livlouder
	reportLivlouder() {
		if (this.reportForm.invalid) {
			this.validateAllFormFields(this.reportForm);
			return false;
		} else {
			var id = localStorage.getItem('metaId');

			//alert(id);
			//(this.metaId);
		//	return false;
			const formData = new FormData();
			formData.append("titleId", this.reportForm.value.title);
			formData.append("description", this.reportForm.value.description);
			formData.append("reportToMetaId", this.metaId);
			formData.append("reportByMetaId", id);
			formData.append("reportByUserType", "1");

			showLoader();
			this.gigService.reportLivlouder(formData).subscribe(
				res => {
					if ((status = "success")) {
						$("#reportUser").modal("hide");
						hideLoader();
						this.reportForm.reset();

						this.toastrService.showSuccess(res.message, "Success");
					}
				},
				err => {
					hideLoader();
					this.authService.checkValidToken(err.error);
					this.toastrService.showError(err.error.message, "Error");
				}
			);
		}
	}


	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({
					onlySelf: true
				});
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}

}
