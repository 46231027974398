import { Component, OnInit } from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import { FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { LivlouderTask } from "src/app/services/livlouder-task";
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';


declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
// typeSubtype;
@Component({
    selector: "app-my-gig-livlouder",
    templateUrl: "./my-gig-livlouder.component.html",
    styleUrls: ["./my-gig-livlouder.component.css"]
})
export class MyGigLivlouderComponent implements OnInit {
    sliderForm: FormGroup = new FormGroup({
        sliderControl: new FormControl([20, 80]),
        sliderControlPrice: new FormControl([0, 90])
    });
    // minValue: number = 50;
    // maxValue: number = 200;
    options: Options = {
        floor: 0,
        ceil: 100,
        step: 5
    };

    check: any = 0;
    metaId;
    taskList;
    dataFound = false;

    limit: any = 20;
    offset: any = 0;
    total: any;
    timeout: any;
    price;

    constructor(
        private authService: AuthService,
        private LivlouderTask: LivlouderTask,
        private toastrService: ToastrNotificationService,

    ) { }

    ngOnInit(): void {
        $("#allF").addClass('activeFilterIcon');
        $("#activeicon").addClass('activeFilterIcon');

        $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

        this.metaId = localStorage.getItem("metaId");

        let search_text = $('#searchText').val();
        var obj: any = {}
        obj.metaId = this.metaId;
        obj.search = search_text;

        this.getList(obj);
    }

    loadMore() {
        this.offset = this.offset + this.limit;
        this.applyFilter(this.offset);
    }

    applyFilter(offset?) {


        var obj: any = {}


        if (!offset) {
            obj.offset = 0;
        } else {
            obj.offset = this.offset;
        }

        let search_text = $('#searchText').val();

        obj.search = search_text;
        obj.metaId = this.metaId;
        obj.offset = this.offset;

        if (this.check == 4) {

            obj.completed = 4;
        }

        if (this.check == 2) {

            obj.assigned = 2;
        }

        showLoader();
        this.LivlouderTask.getTaskList(obj).subscribe(data => {

            hideLoader();
            var newList = data.data;
            this.taskList = this.taskList.concat(newList);
        });

    }

    /**
 * Search discover
 * @param text 
 */
    searchMyTask(text) {
        if (text == '' || text == undefined) {
            $("#allF").addClass('activeFilterIcon');
            $("#activeicon").addClass('activeFilterIcon');


        }

        $("#allF").removeClass('activeFilterIcon');
        $("#activeicon").removeClass('activeFilterIcon');
        $("#showAssignSelected").removeClass('activeFilterIcon');
        $("#showCompletedSelected").removeClass('activeFilterIcon');
        $("#activeicon").removeClass('activeFilterIcon');

        this.offset = 0;
        var obj: any = {}
        obj.metaId = this.metaId;
        obj.search = text;
        obj.offset = this.offset;
        clearTimeout(this.timeout);
        var $this = this;
        $this.timeout = setTimeout(function () {
            $this.getList(obj);

        }, 1000);
    }

    /**
    * get all gig
    */
    allGig() {
        $("#allF").addClass('activeFilterIcon');
        $("#activeicon").addClass('activeFilterIcon');
        $("#showAssignSelected").removeClass('activeFilterIcon');
        $("#showCompletedSelected").removeClass('activeFilterIcon');

        this.offset = 0;
        let search_text = $('#searchText').val();
        var obj: any = {}
        obj.metaId = this.metaId;
        obj.offset = this.offset;
        obj.search = search_text;
        obj.assigned = '';
        obj.complete = '';


        this.check = 0;
        this.getList(obj);
    }

    /**
    * get assigned gig
    */
    assignedGig() {
        $("#activeicon").addClass('activeFilterIcon');
        $("#allF").removeClass('activeFilterIcon');

        $("#showAssignSelected").addClass('activeFilterIcon');
        $("#showCompletedSelected").removeClass('activeFilterIcon');

        let search_text = $('#searchText').val();
        this.offset = 0;
        var obj: any = {}
        obj.metaId = this.metaId;
        obj.offset = this.offset;
        obj.search = search_text;
        obj.assigned = 2;

        this.check = 2;
        this.getList(obj);
    }

    /**
    * get completed gig
    */
    completedGig() {

        $("#allF").removeClass('activeFilterIcon');

        $("#showAssignSelected").removeClass('activeFilterIcon');
        $("#showCompletedSelected").addClass('activeFilterIcon');
        $("#activeicon").addClass('activeFilterIcon');

        let search_text = $('#searchText').val();
        this.offset = 0;
        var obj: any = {}
        obj.metaId = this.metaId;
        obj.offset = this.offset;
        obj.search = search_text;
        obj.completed = 4;

        this.check = 4;
        this.getList(obj);
    }

     in_array(array, id) {
        for(var i=0;i<array.length;i++) {
            
            if(array[i].metaId === id){
                return array[i];
            }
        }
        return false;
    }

    getList(obj) {

        showLoader();
        this.LivlouderTask.getTaskList(obj).subscribe(data => {
            hideLoader();
            if (data.message == 'Record found') {

                this.taskList = data.data;
                this.total = data.total;
                this.dataFound = true;
                this.taskList.forEach(element => {

                    if (element.group_member != null) {

                        var amt_exist = this.in_array(element.group_member, this.metaId);
                        element.amount = amt_exist.amount;
                    }
                })
            } else {

                this.taskList = [];
                this.dataFound = false;
                this.total = 0;
            }
        }, (err) => {
            hideLoader();
            if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
                this.authService.checkValidToken(err.error);
                this.toastrService.showError('Session Expired', 'Error');

            } else {
                this.toastrService.showError(err.error.message, 'Error');
            }
        });
    }

}
