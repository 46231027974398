import { Component, OnInit ,OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../services/local-storage.service'
import { FirebaseService } from '../../services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HirerProfileService } from 'src/app/services/hirer-profile.service';
import { CommonService } from '../../services/common.service';
import Swal from 'sweetalert2';
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
  selector: 'app-chat-hirer',
  templateUrl: './chat-hirer.component.html',
  styleUrls: ['./chat-hirer.component.css']
})
export class ChatHirerComponent implements OnInit {
  reportForm: FormGroup;

  SenderHMId :any;
  receiverLMId :any;
  data :any=[];
  chatHistoryList:any=[];
  message:any=[];
  receiverId:any;
  lastTimeStamp:any;
  chatList:any=[];
  orderListSubscription:any=[];
  livID:any;
  hirerDetail:any=[];
  livMtaId:any=[];
  livloudeUser:any;
  blockMessage:any;
  blockByMe:any;
  chatBoxAllow:any;
  chatSideList:any=[];
  reportTitle;
  previewImage:any;

  constructor(
   private activatedRoute:ActivatedRoute,
   private router:Router, 
   private livLouderID:HirerProfileService,
   private firebaseService:FirebaseService,
   private commonService: CommonService,
   private angularFireStorage:AngularFireStorage,
   private storage: AngularFireStorage,
   private toastrService: ToastrNotificationService,
   private authService: AuthService,
       private gigService: GigService,
   ) { }

  ngOnInit(): void {
    //get meta id livlouder
    this.activatedRoute.paramMap.subscribe((param) => {
    this.SenderHMId = param.get("id"); // get Url + used for convert string to number.
    });
    this.activatedRoute.paramMap.subscribe((param) => {
    this.receiverLMId = param.get("LmetaID"); // get Url + used for convert string to number.
    });
    this.hirerDetail = JSON.parse(localStorage.getItem('currentUser'));
     this.livMtaId=this.hirerDetail.livlouderList[0].userMetaId;
    this.livLouderID.detail(this.receiverLMId).subscribe(res=>{
    this.livID = res.detail;
    this.getChatList()
    this.getChatHistory()
    this.getBlockStatus()




    })
    this.gigService.getReportTitle().subscribe(res => {
      this.reportTitle = res.data;
    },(err)=>{
      //this.authService.checkValidToken(err.error);
      //this.toastrService.showError(err.error.message, "Error");
    });
    this.reportForm = new FormGroup({
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, [
        Validators.required,
        Validators.maxLength(300),
        Validators.minLength(5)
      ])
    });
   }

    // Report Gig 
  reportGig() {
    if (this.reportForm.invalid) {
      this.validateAllFormFields(this.reportForm);
      return false;
    } else {
      var metaId = this.authService.getUserMetaId();
      const formData = new FormData();
      formData.append("titleId", this.reportForm.value.title);
      formData.append("description", this.reportForm.value.description);
      formData.append("reportToMetaId", this.receiverLMId);
      formData.append("reportByMetaId", this.SenderHMId);
      formData.append("reportByUserType", "1");

      showLoader();
      this.gigService.reportLivlouder(formData).subscribe(
        res => {
          if ((status = "success")) {
            $("#reportUser").modal("hide");
            hideLoader();
            this.reportForm.reset();

            this.toastrService.showSuccess(res.message, "Success");
          }
        },
        err => {
          hideLoader();
          this.authService.checkValidToken(err.error);
          this.toastrService.showError(err.error.message, "Error");
        }
      );
    }
  }


  // function for validate form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });

    return false;
  }

   //*******send Message******.
  sendMsg(){
  if(this.message.trim()){
  let chatData = {
            image:'',
            image_url:"",
            H_other_user_Image:this.hirerDetail.userTypeList[1].profileImage,
            H_other_user_name:this.hirerDetail.userTypeList[1].fullName,
            L_other_user_Image:this.livID.profileImage,
            L_other_user_name:this.livID.name,
            LMetaId:this.receiverLMId,
            HMetaId:this.SenderHMId,
            Lid:this.livID.userId,
            Hid:this.hirerDetail.userId,
            message:this.message.trim(),
  }
  this.firebaseService.sendMsgFirebase(chatData);
  this.getChatList();
  }  else{
      console.log('No chat');
    }

   this.message = "";
  }

  //*************upload image********************
  onSelectedFile(event) {
     if (event.target.files.length > 0) {
       this.commonService.showLoader();
       const file = event.target.files[0];
       var ext = file.name.split('.').pop().toLowerCase();
       const filePath = environment.storageBucketFile+Date.now();
       const fileRef = this.storage.ref(filePath);
       const task = this.storage.upload(filePath, file).then(() => {
       const ref = this.storage.ref(filePath);
       const downloadURL = ref.getDownloadURL().subscribe(url => {
       this.commonService.hideLoader();
            let chatData = {
              H_other_user_Image:this.hirerDetail.userTypeList[1].profileImage,
              H_other_user_name:this.hirerDetail.userTypeList[1].fullName,
              L_other_user_Image:this.livID.profileImage,
              L_other_user_name:this.livID.name,
               LMetaId:this.receiverLMId,
               HMetaId:this.SenderHMId,
               Lid:this.livID.userId,
               Hid:this.hirerDetail.userId,
               message:this.message
              }
          
              this.firebaseService.sendMsgFirebase(chatData,url);
              this.getChatList();

            

          });
      });
    }
  }
      //*************End upload image********************
  //get side bar history
  getChatHistory(){
   let chatData = {
        LMetaId:this.receiverLMId,
        HMetaId:this.SenderHMId,
        Hid: this.hirerDetail.userId,
        lastTimeStamp:this.lastTimeStamp,
      }
      
      this.firebaseService.chatHistoryData(chatData).subscribe(allData=>{
       if(allData.payload.val()){
       this.chatSideList=allData.payload.val();
      } else{

       this.chatSideList= "";
      }
      }) 
      
     }
//get chat history  list
  getChatList(){
   let chatData = {
        HMetaId:this.SenderHMId, 
        LMetaId: this.receiverLMId, 
        lastTimeStamp:this.lastTimeStamp,
      }
      this.orderListSubscription = this.firebaseService.getChatList(chatData).subscribe(chatData=>{
        this.chatList = chatData;
     });

    
    
  }
 
 getTimeAgo(timestamp){
     return this.firebaseService.timeSince(timestamp);
  }

blockConfirm() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Blocked user will no longer be able to send you messages and images',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure!',
      cancelButtonText: 'No, cancel it!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.blockUser();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your blocked is safe :)',
          'error'
        )
      }
    });
  }

    blockUser(){
    let chatData = {
       LMetaId:this.receiverLMId,
       HMetaId:this.SenderHMId,
    }
    this.firebaseService.blockUser(chatData);
  }

  unBlockUser(){
    let chatData = {
       LMetaId:this.receiverLMId,
       HMetaId:this.SenderHMId,
    }
    this.firebaseService.unBlockUser(chatData);
  }

   getBlockStatus(){
     let chatData = {
        LMetaId:this.receiverLMId,
        HMetaId:this.SenderHMId,
    }
    this.firebaseService.getBlockStatus(chatData).subscribe(data=>{
    let block;
      if(data.payload.val()){
        block = data.payload.val();

        if(block.blocked_by == this.SenderHMId || block.blocked_by == "Both") {
           this.chatBoxAllow = false;
           this.blockMessage = "You blocked this user, unblock to chat.";
           this.blockByMe = true;
          } else {
            
            this.chatBoxAllow = false;
            this.blockByMe = false;
            this.blockMessage = "User you are sending message has blocked you.";
        }

      } else{

        this.chatBoxAllow = true;
        this.blockByMe = false;
      }

    });
  
}

  imagePreviewModal(imgUrl) {
    this.previewImage = imgUrl
    $("#imgPreview").modal("show");
  }
}