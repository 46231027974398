<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-7">
                    <div class="widgetBox">
                        <div class="setupPrHead">
                            <h2>Select Card For Payment</h2>
                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        </div>
                        <div class="gigsPayment">
                            <div class="cardListBlk" *ngFor="let list of cardLists">
                                <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/credit-card.svg"></div>
                                        <div class="crInfo">
                                            <h2>{{list.brand}}</h2>
                                            <p>xxxx xxxx xxxx {{list.last4}}</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" value="{{list.id}}" class="devCheckedRadio" name="radio" checked><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div>
                                <!-- <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                        <div class="crInfo">
                                            <h2>Discover Network</h2>
                                            <p>xxxx xxxx xxxx 1542</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" name="radio" checked=""><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div> -->
                            </div>
                            <div class="assgigAct">
                                <button data-toggle="modal" data-target="#addCard" type="button" class="btn btn-outline-info">Add New Card</button>
                                <button data-toggle="modal" (click)="clickOneTime();" type="button" class="btn btn-outline-info">One Time Payment</button>
                            </div>
                            <!-- <div class="textLink">
                                <button data-toggle="modal" data-target="#addCard" type="button" class="btn btn-outline-info">Add New Card</button>
                                <button data-toggle="modal" data-target="#addCard" type="button" class="btn btn-outline-info">One Time Payment</button>
                            </div> -->
                           
                        </div>
                        <div  *ngIf="cardLists.length > 0" class="text-center d-flex justify-content-center mt-20">
                            <button [routerLink]="['/myGigsHirer/detail',gigId]" type="button" class="btn thm-btnGray">Cancel</button>
                            &nbsp;&nbsp;

                            <button data-toggle="modal" (click)="confirmPayment()" class="btn thm-btn ">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Payment Deduction</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
            <div class="paymentDeduct">
                <svg-icon class="svgIcon" src="assets/img/payment.svg"></svg-icon>
                <h3>Amount to be paid</h3>
                <h1>${{livloudInfo.price}}</h1>
                <p>The Amount will get debited from your account and  securely held within an Escrow account until the  the task is complete.</p>
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
        <button  (click)="pay()" data-dismiss="modal" type="button" class="btn thm-btn">Pay</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade csModal" id="addCard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ isOneTime ? 'Make Payment': 'Add New Card'}}</h5>
                <button type="button" class="close" (click)="closeModal()" aria-label="Close">
                <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="formModal">
                    <div class="formModalBlk">
                        <div class="cardAddBlk">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="inLabel">Enter Card Details <span class="req">*</span></label>
                                        <div class="cardFieldView">
                                            <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cardSequrity">
                                <p>Safe payment online using your credit card. We are secure, we will not share your details.</p>
                                <div class="cardType">
                                    <img src="assets/img/card/american-express.png">
                                    <img src="assets/img/card/discover.png">
                                    <img src="assets/img/card/mastercard.png">
                                    <img src="assets/img/card/visa.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" (click)="closeModal()">Close</button>
                <button type="button" (click)="buy()" class="btn thm-btn">{{ isOneTime ? 'Continue': 'Save'}}</button>
            </div>
        </div>
    </div>
</div>

<app-footer-hirer></app-footer-hirer>