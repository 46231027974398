import { Component, OnInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
	selector: 'app-user-type',
	templateUrl: './user-type.component.html',
	styleUrls: ['./user-type.component.css']
})
export class UserTypeComponent implements OnInit {

	optradio: any;
	optradio1: any;
	selectedUser:any;
	profileCompleteHirer;
	profileCompleteLivlouder;

	constructor(
		private signupService: SignupService,
		private router: Router,
		private authService: AuthService

	) { }

	ngOnInit(): void {

		var currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser.phoneNumberVerfied != 1) {
			this.router.navigate(['/login']);
		}else {
			this.profileCompleteLivlouder = currentUser.userTypeList[0].isProfileComplete;
			this.profileCompleteHirer = currentUser.userTypeList[1].isProfileComplete;
			this.selectedUser =  localStorage.getItem("SelectedUser");

			if(this.profileCompleteHirer != this.profileCompleteLivlouder){

				if(this.profileCompleteHirer != 1 && this.profileCompleteLivlouder == 1 ){

					localStorage.setItem('SelectedUser','1');
					this.router.navigate(['/loginProfile']);
				}

				if(this.profileCompleteHirer == 1 && this.profileCompleteLivlouder != 1 ){

					localStorage.setItem('SelectedUser','2');
					this.router.navigate(['/myGigsHirer']);
				}

				//change profile check...
				if(this.selectedUser == 2 && this.profileCompleteHirer != 1 ){
					
					this.optradio = 2;
					this.router.navigate(['/userType']);
				}
			}

			this.signupService.getTypeSubType().subscribe((data) => {


			}, err => {
				if(err.error.status=='fail' && err.error.message=='Invalid token'){
					this.authService.checkValidToken(err.error);
					// this.toastrService.showError('Session Expired', 'Error');

				}else{
				// this.toastrService.showError(err.error.message, 'Error');
				}
			});

		}

	}

	checkHirer() {
		// alert('check hirer')
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser.userTypeList[1].isProfileComplete == 1) {
			this.authService.setUserMetaId(2)
			localStorage.setItem("SelectedUser", '2');
			this.router.navigate(['/myGigsHirer']);
			//myGigsHirer
		}
	}

	checkLivlouder() {

		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser.userTypeList[0].isProfileComplete == 1) {
			
			this.authService.setUserMetaId(1)
			localStorage.setItem("SelectedUser", '1');
			this.router.navigate(['/loginProfile']);
		}
	}

}
