import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import intlTelInput from "intl-tel-input";
import { Router } from "@angular/router";
import { ToastrNotificationService } from "../../services/toastr-notification.service";
import { SignupService } from "../../services/signup.service";
import { PickerModel } from "../../picker-model";
import { AuthService } from '../../services/auth.service';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-contact-number-update',
  templateUrl: './contact-number-update.component.html',
  styleUrls: ['./contact-number-update.component.css']
})
export class ContactNumberUpdateComponent implements OnInit {

  reactiveForm: FormGroup;
  otpForm: FormGroup;
  model;
  dialCode: any;
  mobileNo: any;
  // maxDate;
  pickerModel: PickerModel;
  datemodel;
  abc;
  currentUser;
  constructor(
    private router: Router,
    private toastrService: ToastrNotificationService,
    private signupService: SignupService, // private pickerModel:PickerModel,
    private authService: AuthService,

  ) //  private calendar: NgbCalendar
  { }

  ngOnInit(): void {

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // this.maxDate = {
    //   year: new Date().getFullYear(),
    //   month: new Date().getMonth() + 1,
    //   day: new Date().getDate(),
    // };

    //this.pickerModel = new PickerModel();
    const input = document.querySelector(".phone");
    // Initialize intlTelInput with the desired options
    const iti = intlTelInput(input, {
      separateDialCode: true,
      allowDropdown: true,
      initialCountry: 'auto', // Use 'auto' to automatically select the country based on the dial code
    });

    // Set the phone number using setNumber
    iti.setNumber(this.currentUser.phoneDialCode);

    // Listen for change event on the input field
    input.addEventListener('change', function() {
      // Get the updated dial code after the phone number changes
      const newDialCode = iti.getSelectedCountryData().dialCode;

    });

    $("#changeNo").click(function () {
      $("#otpForm").hide();
      $("#signupForm").show();
    });

    this.reactiveForm = new FormGroup({
      // legalName: new FormControl(null, Validators.required),
      // DOB: new FormControl(""),
      mobileNo: new FormControl(this.currentUser.phoneNumber, [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        // Validators.pattern("^[0-9]*$"),
        Validators.minLength(5),
        Validators.maxLength(15)
      ]),
    });

    this.otpForm = new FormGroup({
      otp: new FormControl(null),
    });
  }

  trimValue(formControl) {
    ((typeof formControl.value) && formControl.value.trim() == "") ?
      formControl.setValue("") : formControl.setValue(formControl.value);
  }

  onSubmit() {

    if (!this.reactiveForm.valid) {
      this.validateAllFormFields(this.reactiveForm);
      return false;
    }
    var abd = $(".iti__selected-flag").attr("title");
    var country_code = abd.split("+")[1];
    this.dialCode = country_code;
    this.mobileNo = this.reactiveForm.value.mobileNo;

    const formData = new FormData();
    if (this.reactiveForm.value.mobileNo) {
      
      formData.append("dialCode", "+" + country_code);
      formData.append("phoneNumber", this.reactiveForm.value.mobileNo);
    }

    if (!this.reactiveForm.value.mobileNo) {
      $("#otpForm").hide();
    }

    showLoader();
    this.signupService.sendOtp(formData).subscribe(
      (data) => {

        hideLoader();
        if (data.status == "fail") {
          this.toastrService.showError(data.message, "Error");
          return false;
        }else{

          $("#otpForm").show();
          $("#signupForm").hide();
          this.toastrService.showSuccess(
            data.message,
            "Success"
          );
        }
      },
      (error) => {
        hideLoader();

        this.authService.checkValidToken(error.error);
        this.toastrService.showError(error.error.message, "Error");
        //this.error = error.message;
      }
    );
  }

  checkOtp() {
    const otp = this.otpForm.value.otp;

    const otpData = new FormData();
    otpData.append("otp", otp);
    otpData.append("isUpdate", "1");
    otpData.append("dialCode", "+"+this.dialCode);
    otpData.append("phoneNumber", this.mobileNo);
    showLoader();

    this.signupService.checkOtp(otpData).subscribe(
      (data) => {
        hideLoader();

        if (data.status == "success") {
          
          localStorage.setItem("currentUser", JSON.stringify(data.userDetail));
          this.router.navigate(["/settingsHirer"]);
        } else {
          this.toastrService.showError(data.message, "Error");
        }

        if (data.status == "fail") {
          this.toastrService.showError(data.message, "Error");
          return false;
        }
      },
      (error) => {
        hideLoader();

        this.authService.checkValidToken(error);
        this.toastrService.showError(error.error.message, "Error");

        //this.error = error.message;
      }
    );
  }

  resendOtp() {
    showLoader();
    const formData = new FormData();

    formData.append("dialCode", "+" + this.dialCode);
    formData.append("phoneNumber", this.reactiveForm.value.mobileNo);

    this.signupService.sendOtp(formData).subscribe(
      (data) => {
        hideLoader();
        this.toastrService.showSuccess(
          data.message,
          "Success"
        );
        if (data.status == "fail") {
          this.toastrService.showError(data.message, "Error");
          return false;
        }
      },
      (error) => {
        hideLoader();
        console.log("error");
        console.log(error.error);
        this.toastrService.showError(error.error.message, "Error");
        //this.error = error.message;
      }
    );
  }

  onKeyPress(event: KeyboardEvent) {

    const inputElement = event.target as HTMLInputElement;
    const maxLength = 14; // Maximum allowed length

    if (event.key === 'e' || inputElement.value.length > maxLength) {
        event.preventDefault();
    }    
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4};
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

}
