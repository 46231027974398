<app-header-livlouder *ngIf="current_user == 1"></app-header-livlouder>
<app-header-hirer *ngIf="current_user == 2"></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="widgetBox profileDone">
                <div class="profileDoneCnt">
                    <svg-icon class="animate__animated animate__tada animate__slow animate__infinite" src="assets/img/fireworks.svg"></svg-icon>
                    <h2>Congratulations</h2>
                    <h5>Your profile is completely done</h5>
                    <p>Now start getting out there and applying for some gigs, create a group and start developing your profile! Livloud!</p>
                    <button type="button" (click)="profileDone()" class="btn thm-btn mt-20">OK</button>

                    <!-- <div class="prBtn">
                        <button routerLink="/createGroup" type="button" class="btn thm-btn btn-block">Yes, Create group</button>
                        <button routerLink="/discover" type="button" class="btn thm-btn btn-block">No, I will create later</button>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder *ngIf="current_user == 1"></app-footer-livlouder>
<app-footer-hirer *ngIf="current_user == 2"></app-footer-hirer>