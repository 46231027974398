<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="chatPage py-3">
            <div class="container">
                <div class="chatApp chatDetailsSingle">
                    <div class="chatMainBlock">
                        <div class="conversation">
                            <div class="chatHeader chatHeaderMsg">
                                <div class="chatHeaderAvatar">
                                    <div class="chatHeaderAvatarIcon">
                                        <span routerLink="/myGigAssignedDispute" class="chatBack"><i class="fa fa-arrow-left"></i></span>
                                    </div>
                                    <div class="">
                                        <a href="javascript:void(0);" class="chatHeaderUser">
                                            <h2>Payment Dispute</h2>
                                            <p class="disputeStatus disputePending">In Progress</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="messageBox" id="conversation">
                                <div class="messageContainer">
                                    <div class="message-body">
                                        <div class="message-main-receiver">
                                            <h2 class="chatBy">Admin</h2>
                                            <div class="receiver">
                                            <div class="message-text">
                                                Hi, what are you doing?
                                            </div>
                                            <span class="message-time">
                                                11:00 am
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-sender">
                                            <div class="sender">
                                            <div class="message-text">
                                                I am doing nothing man!
                                            </div>
                                            <span class="message-time">
                                                11:20 am
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-receiver">
                                            <h2 class="chatBy">Admin</h2>
                                            <div class="receiver">
                                            <div class="message-text">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </div>
                                            <span class="message-time pull-right">
                                                12:00 pm
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-sender">
                                            <div class="sender">
                                            <div class="message-text">
                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                            </div>
                                            <span class="message-time pull-right">
                                                12:10 pm
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-sender">
                                            <div class="sender">
                                            <div class="message-text">
                                                Sed eget felis dignissim nunc fermentum tincidunt.
                                            </div>
                                            <span class="message-time pull-right">
                                                01:30 pm
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-receiver">
                                            <h2 class="chatBy">John Smith</h2>
                                            <div class="receiver">
                                            <div class="message-text">
                                                Hi, what are you doing?!
                                            </div>
                                            <span class="message-time pull-right">
                                                Sun
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-sender">
                                            <div class="sender">
                                            <div class="message-text">
                                                Ut accumsan arcu vel quam molestie imperdiet.
                                            </div>
                                            <span class="message-time pull-right">
                                                01:34 pm
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="message-body">
                                        <div class="message-main-sender">
                                            <div class="sender imgSend">
                                            <div class="message-text">
                                                    <img src="assets/img/9.png">
                                            </div>
                                            <span class="message-time pull-right">
                                                5:12 pm
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chatReply">
                                <!-- <p class="disputeSolved">Dispute is resolved and goes to <b>Hirer</b> favour.</p> -->
                                <div class="reply-main">
                                    <div id="ta-frame">
                                        <textarea oninput="autosize();" class="form-control" rows="1" id="comment"></textarea>
                                    </div>
                                </div>
                                <div class="reply-icon reply-recording">
                                    <i class="fa fa-paperclip fa-2x" aria-hidden="true"></i>
                                </div>
                                <div class="reply-icon reply-send">
                                    <i class="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</div>
<app-footer-livlouder></app-footer-livlouder>