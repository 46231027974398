import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import intlTelInput from "intl-tel-input";
import { Router } from "@angular/router";
import { ToastrNotificationService } from "../../services/toastr-notification.service";
import { SignupService } from "../../services/signup.service";
import { PickerModel } from "../../picker-model";
import { AuthService } from '../../services/auth.service';

//import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: "app-basic-info-page",
  templateUrl: "./basic-info-page.component.html",
  styleUrls: ["./basic-info-page.component.css"],
})
export class BasicInfoPageComponent implements OnInit {
  reactiveForm: FormGroup;
  otpForm: FormGroup;
  model;
  dialCode: any;
  mobileNo: any;
  maxDate;
  pickerModel: PickerModel;
  datemodel;
  abc;
  constructor(
    private router: Router,
    private toastrService: ToastrNotificationService,
    private signupService: SignupService, // private pickerModel:PickerModel,
    private authService: AuthService,

  ) //  private calendar: NgbCalendar
  { }

  ngOnInit(): void {
    this.maxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };

    //this.pickerModel = new PickerModel();

    const input = document.querySelector(".phone");
    
    intlTelInput(input, {
      // any initialisation options go here
      separateDialCode: true,
      // initialCountry: "au",
      initialCountry: "gb",
      allowDropdown: true,
    });

    const currentFormData = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    
    if (currentFormData.legalName != "" && currentFormData.phoneNumberVerfied == "1") {
      this.router.navigate(["/userType"]);
    }else if (currentFormData.legalName != "" && currentFormData.phoneNumberVerfied == "0") {
      this.dialCode = currentFormData.phoneDialCode;
      this.mobileNo = currentFormData.phoneNumber;
      $("#signupForm").hide();
      $("#otpForm").show();
    }else {
      $("#changeNo").click(function () {
        $("#otpForm").hide();
        $("#signupForm").show();
      });
    }

    this.reactiveForm = new FormGroup({
      legalName: new FormControl(null, Validators.required),
      DOB: new FormControl(""),
      mobileNo: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        // Validators.pattern("^[0-9]*$"),
        Validators.minLength(6),
        Validators.maxLength(15)
      ]),
    });

    this.otpForm = new FormGroup({
      otp: new FormControl(null, [Validators.required,Validators.minLength(4),Validators.maxLength(4), Validators.pattern('^[0-9]*$')]),
    });
  }

  trimValue(formControl) {
    ((typeof formControl.value) && formControl.value.trim() == "") ?
      formControl.setValue("") : formControl.setValue(formControl.value);
  }

  onSubmit() {
    if (!this.reactiveForm.valid) {
      this.validateAllFormFields(this.reactiveForm);
      return false;
    }

    const txt = this.reactiveForm.value.DOB;
    const obj = JSON.stringify(txt);
    const check = JSON.parse(obj);
    // this.abc  = "'"+check.year+"-"+check.month+"-"+check.day+"'";
    this.abc = check.year;
    var year_born = this.abc;
    var d = new Date();
    var n = d.getFullYear();
    function getAge(birthYear) {
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var age = currentYear - birthYear;
      return age;
    }
    var calculatedAge = getAge(year_born);
    // alert(calculatedAge);
    if (calculatedAge < 18) {
      this.toastrService.showError("DOB should be 18 or more", "Error");
    } else {

      var abd = $(".iti__selected-flag").attr("title");

      var country_code = abd.split("+")[1];
      this.dialCode = "+" + country_code;
      this.mobileNo = this.reactiveForm.value.mobileNo;

      const formData = new FormData();
      formData.append("legalName", this.reactiveForm.value.legalName);
      formData.append("dob", this.reactiveForm.value.DOB);
      if (this.reactiveForm.value.mobileNo) {
        formData.append("dialCode", "+" + country_code);
        formData.append("phoneNumber", this.reactiveForm.value.mobileNo);
      }

      // if (this.dialCode != 61) {
      //   this.toastrService.showError(
      //     "Please Enter Only Australia Based Number",
      //     "Error"
      //   );
      //   return false;
      // }

      if (!this.reactiveForm.value.mobileNo) {
        $("#otpForm").hide();
      }

      showLoader();
      this.signupService.legalInfo(formData).subscribe((data) => {
        hideLoader();
        localStorage.setItem("currentUser", JSON.stringify(data.userDetail));
        // if (data.userDetail.phoneNumberVerfied == 1) {
        //   this.router.navigate(["/userType"]);
        // }

        if (
          data.userDetail.phoneNumberVerfied == 0 &&
          !data.userDetail.phoneNumber
        ) {
          this.router.navigate(["/userType"]);
        }

        // if (
        //   data.userDetail.phoneNumberVerfied == 0 &&
        //   data.userDetail.phoneNumber
        // ) {
          $("#otpForm").show();
          $("#signupForm").hide();
          this.signupService.sendOtp(formData).subscribe(
            (data) => {
              this.toastrService.showSuccess(
                data.message,
                "Success"
              );

              // this.toastrService.showSuccess(
              //   data.message + " : OTP is " + data.otp,
              //   "Success"
              // );

              if (data.status == "fail") {
                this.toastrService.showError(data.message, "Error");
                return false;
              }
            },
            (error) => {
              hideLoader();

              this.authService.checkValidToken(error.error);
              this.toastrService.showError(error.error.message, "Error");
              //this.error = error.message;
            }
          );
          //this.router.navigate(['/userType']);
        // }
      }, (error) => {
        hideLoader();

        this.authService.checkValidToken(error.error);
        this.toastrService.showError(error.error.message, "Error");
      }
      );

      //return false;
      //this.router.navigate(['/userType']);
    }
  }
  yearDiff(dt1, dt2) {
    var diffYear = (dt2.getTime() - dt1.getTime()) / 1000;
    diffYear /= 60 * 60 * 24;
    return Math.abs(Math.round(diffYear / 365.25));
  }
  onKeyPress(event: KeyboardEvent) {

    const inputElement = event.target as HTMLInputElement;
    const maxLength = 14; // Maximum allowed length

    if (event.key === 'e' || inputElement.value.length > maxLength) {
        event.preventDefault();
    }    
  }
  checkOtp() {
    if (!this.otpForm.valid) {
      this.validateAllFormFields(this.otpForm);
      return false;
    }
    const otp = this.otpForm.value.otp;

    const otpData = new FormData();
    otpData.append("otp", otp);
    showLoader();

    this.signupService.checkOtp(otpData).subscribe(
      (data) => {
        hideLoader();

        if (data.status == "success") {
          localStorage.setItem("currentUser", JSON.stringify(data.userDetail));
          this.router.navigate(["/userType"]);
        } else {
          this.toastrService.showError(data.message, "Error");
        }

        if (data.status == "fail") {
          this.toastrService.showError(data.message, "Error");
          return false;
        }
      },
      (error) => { 
        hideLoader();
        if (error.error.status == "fail" && error.error.message == "Invalid token") {
          this.authService.checkValidToken(error.error);
          this.toastrService.showError("Session Expired", "Error");
        }else {
          this.toastrService.showError(error.error.message, "Error");
        }
      }
    );
  }

  resendOtp() {
    showLoader();
    const formData = new FormData();

    formData.append("dialCode", this.dialCode);
    formData.append("phoneNumber", this.mobileNo);

    this.signupService.sendOtp(formData).subscribe(
      (data) => {
        hideLoader();
        this.toastrService.showSuccess(
          data.message,
          "Success"
        );
        if (data.status == "fail") {
          this.toastrService.showError(data.message, "Error");
          return false;
        }
      },
      (error) => {
        hideLoader();
        console.log("error");
        console.log(error.error);
        this.toastrService.showError(error.error.message, "Error");
        //this.error = error.message;
      }
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4};
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
}
