import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-my-gigs-confirm-hirer",
	templateUrl: "./my-gigs-confirm-hirer.component.html",
	styleUrls: ["./my-gigs-confirm-hirer.component.css"]
})
export class MyGigsConfirmHirerComponent implements OnInit {
	gigId;
	gigData: any = {};
	title = "Hirer- Confirm Payment";
	livloudInfo;
	agoTime;
	mustHave: any;
	offers: any = [];
	qusAns;
	metaId;
	msgText = [];
	subtype: any = [];
	constructor(
		private activatedRoute: ActivatedRoute,
		private gigService: GigService,
		private authService: AuthService,
		private router: Router,
		private titleService: Title
	) {
		//this.livloudInfo = this.router.getCurrentNavigation().extras.state.data;
		//this.gigId = this.router.getCurrentNavigation().extras.state.gigId;

	}

	ngOnInit(): void {
		this.livloudInfo = JSON.parse(localStorage.getItem("stateData"));
		

		this.gigId = localStorage.getItem("stateGigId");
		this.titleService.setTitle(this.title);
		this.authService.setUserMetaId(2);
		this.gigDetail(this.gigId);
	}

	gigDetail(gigId) {
		showLoader();
		var metaId = this.authService.getUserMetaId();
		this.gigService.gigDetail(gigId, metaId).subscribe(
			res => {
				hideLoader();
				if (res.status == "success") {


					this.gigData = res.data;
					console.log('gigData gigData', this.gigData);

					this.gigData.offers.forEach(element => {
						if (this.livloudInfo.userId == element.userId) {
							this.offers.push(element);
						}

					});

					this.gigData.groupOffers.forEach(element => {
						if (this.livloudInfo.userId == element.userId) {
							this.offers.push(element);
						}
					});

					this.agoTime = this.diffHours(
						this.livloudInfo.posted_time,
						this.gigData.current_time
					);

					this.offers.forEach((data) => {
						data.agoTime = this.diffHours(
							data.posted_time,
							this.gigData.current_time
						);
					});



				}

			},
			err => {
				hideLoader();
				console.log(err);
			}
		);
	}

	gigPayment() {
		// routerLink="/GigsPayment"
		this.router.navigate(["GigsPayment"], {
			state: { data: this.livloudInfo, gigId: this.gigId }
		});
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;

		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs(date2 - date1);
		var diffHr = difference / 60 / 60;
		var daysDiff = difference / 60 / 60 / 24;
		if (daysDiff < 1) {
			if (diffHr < 1) {
				difference = difference / 60;
				var hourDiff = Math.floor(difference) + " Min Ago";
			} else {
				difference = difference / 60 / 60;
				var hourDiff = Math.floor(difference) + " Hour Ago";
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + " Days Ago";
		}

		return hourDiff;
	}
}
