<header class="header" [ngClass]="{'innerHeader': router.url !== '/'}">
    <nav id="navbar" class="navbar our-header navbar-expand-lg navbar-light fixed-top"
        [ngClass]="{'our-nav': router.url !== '/'}">
        <div class="container NotifiContainer">
            <a class="navbar-brand"><span>Livloud</span></a>
            <div class="headerItemBlk">
                <div class="headerItem" *ngIf="isLogin == false && (router.url == '/faq' || router.url == '/help' || router.url == '/' || router.url == '/login' || router.url == '/signup')">
                    <div class="lsBtn" routerLinkActive="active">
                        <a class="nav-link" routerLink="/login">Login</a>
                    </div>
                </div>
                <div class="headerItem" *ngIf="isLogin == true && router.url != '/' && router.url != '/login' && router.url != '/signup' ">
                    <div class="profile dropdown csDropdown">
                        <a id="dropdownMenuLink1" href="javascript:void(0);" class="img-toggle" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false"><img *ngIf="isLogin == true"
                                src="{{profileImage}}"><img *ngIf="isLogin == false"
                                src="https://giglooker.com/dev/livloud_admin/placeholder/defaultUser.png"></a>

                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink1">
                            <li
                                *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'">
                                <a routerLink="/ProfileLivlouder"><i class="fas fa-user"></i>My Profile</a>
                            </li>
                            <!-- <li *ngif="isLogin == false">
                                <a  routerLink="/login"><i class="fas fa-random"></i>My Profile</a>
                            </li> -->
                            <li *ngIf="router.url != '/userType'  && router.url !='/basicInfo'  &&  router.url !='/createLivlouderProfile'  && isLogin == true"
                                (click)="switchAccound()">
                                <a href="javascript:void(0)"><i class="fas fa-random"></i>Switch To Hirer</a>
                            </li>


                            <li *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'">
                                <a routerLink="/loginProfile"><i class="fas fa-user-circle"></i>Change Profile</a>
                            </li>
                            <!--                             <li>
                                <a routerLink="/groupRequest"><i class="fas fa-users"></i>Group Request</a>

                            </li> -->

                            <li>
                                <a *ngIf="router.url != '/userType'  && router.url !='/basicInfo'  &&  router.url !='/createLivlouderProfile'  && isLogin == true"
                                    routerLink="/bookRequest"><i class="fas fa-calendar-alt"></i>Book Me Request <span
                                        class="reqCount">{{total}}</span></a>
                            </li>
                            <li
                                *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'">
                                <a routerLink="/groupRequest"><i class="fas fa-users"></i>Group Request</a>
                            </li>
                            <li
                                *ngIf="router.url != '/userType'&&  router.url !='/createLivlouderProfile'  && router.url !='/basicInfo'">
                                <a routerLink="/bankSettings"><i class="fas fa-cog"></i>Settings</a>
                            </li>

                            <li *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'">

                                <a routerLink="/faq"><i class="fas fa-question-circle"></i>FAQ</a>
                            </li>
                            <li *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'">
                                <a routerLink="/help"><i class="fas fa-headset"></i>Help</a>
                            </li>
                            <li *ngIf="isLogin == true" (click)="logout()">
                                <a href="javascript:void(0)"><i class="fas fa-sign-out-alt"></i>Logout</a>
                            </li>
                            <li *ngIf="isLogin == false" routerLink="/login">
                                <a href="javascript:void(0)"><i class="fas fa-sign-in-alt"></i>Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="headerItem"
                    *ngIf="isLogin == true && router.url != '/' && router.url != '/login' && router.url != '/signup' && router.url != '/loginProfile' && router.url != '/basicInfo' && router.url != '/userType'">
                    <app-notification-list-livlouder></app-notification-list-livlouder>
                </div>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto"
                    *ngIf="router.url == '/' || router.url == '/login' || router.url == '/signup' || router.url == '/loginProfile' || router.url == '/basicInfo' || router.url == '/userType' ">

                    <li *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'" class="nav-item" routerLinkActive="active">
                        <!-- <a class="nav-link" routerLink="/faq">FAQ</a> -->
                        <!-- <a class="nav-link" href="javascript:void(0)">FAQ</a> -->
                        <a class="nav-link" id="" href="#/faq" (click)="faq()">FAQ</a>

                    </li>
                    <li *ngIf="router.url != '/userType' &&  router.url !='/createLivlouderProfile' && router.url !='/basicInfo'" class="nav-item" routerLinkActive="active">
                        <!-- <a class="nav-link" routerLink="/help">Help</a> -->
                        <a class="nav-link" href="#/help">Help</a>
                    </li>

                </ul>
                <ul class="navbar-nav ml-auto"
                    *ngIf="isLogin == true && router.url != '/' && router.url != '/login' && router.url != '/createLivlouderProfile' && router.url != '/signup' && router.url != '/loginProfile' && router.url != '/basicInfo' && router.url != '/userType'">
                    <li class="nav-item" *ngIf="router.url != '/'" routerLinkActive="active">
                        <a class="nav-link" routerLink="/gigs" (click)="reloadPage();">Gigs</a>
                    </li>
                    <!-- <li class="nav-item" routerLinkActive="active" >
                        <a class="nav-link" (click)="openacessgig();" routerLink="/OpenAcessGig">Gigs</a>
                    </li> -->
                    <!-- <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" (click)="openacessDiscover();" routerLink="/OpenAcessDiscover">Discover</a>
                    </li> -->


                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/discover">Discover</a>
                    </li>


                    <!-- {{isLogin}} -->
                    <li *ngIf=" isLogin == true" class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/mygig">My Gigs</a>
                    </li>
                    <li *ngIf=" isLogin == false" class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/login">My Gigs</a>
                    </li>

                    <li class="nav-item " routerLinkActive="active">
                        <a *ngIf=" isLogin == true" class="nav-link" routerLink="/groups">Groups</a>
                        <a *ngIf=" isLogin == false" class="nav-link" routerLink="/login">Groups</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <!-- <a class="nav-link" routerLink="/chatLivlouder">Messages</a> -->
                        <!-- <a class="nav-link" href="javascript:void(0)">Messages</a> -->
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>