<app-header-livlouder></app-header-livlouder>

<style>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .imgHeight {
        height: 165px;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="form-wizard" id="msform">
                <div class="row mainContent">
                    <div class="col-lg-4">
                        <div stickySidebar topSpacing="100" containerSelector=".mainContent">
                            <div class="form-wizard-steps WizardSteps sidebar__inner">
                                <!-- Step 1 -->
                                <!-- <div class="form-wizard-step active novisible">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-user-edit"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Select User Type</h6>
                                            <p class="bd-wizard-step-subtitle">Lorem ipsum dolar sit amet it's simply dummy text</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- Step 1 -->
                                <!-- Step 2 -->
                                <div class="form-wizard-step" [ngClass]="{'active': stepNo === 2}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-user"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Basic Info</h6>
                                            <p class="bd-wizard-step-subtitle">Enter some basic info about your group
                                                profile below</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 2 -->
                                <!-- Step 3 -->
                                <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 3}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-info-circle"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">More Info</h6>
                                            <p class="bd-wizard-step-subtitle">Write a description about what is your
                                                group are and what you do</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 3 -->
                                <!-- Step 4 -->
                                <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 4}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-images"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Upload Photos</h6>
                                            <p class="bd-wizard-step-subtitle"> Share some fun photos of your group
                                                doing gigs or just being yourselves!</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 4 -->
                                <!-- Step 4 -->
                                <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 5}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-video"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Upload Videos</h6>
                                            <p class="bd-wizard-step-subtitle">Upload videos of your group below. -
                                                Share some fun videos of your group doing gigs or just being yourselves!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 4 -->
                                <!-- Step 4 -->
                                <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 6}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-share-square"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Social Media Links</h6>
                                            <p class="bd-wizard-step-subtitle">Share your social links for your group
                                                livlouder profile so hirers can get to know your group better!</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 4 -->
                                <!-- Step 1 -->
                                <div class="form-wizard-step active" [ngClass]="{'active': stepNo === 7}">
                                    <div class="media">
                                        <div class="bd-wizard-step-icon">
                                            <i class="fa fa-user-plus"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="bd-wizard-step-title">Invite Member</h6>
                                            <p class="bd-wizard-step-subtitle">Invite Other livlouders to join your
                                                group below! - You can search livlouders and invite them join your
                                                group, they will get a notification and can accept or decline</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step 1 -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <!-- <fieldset style="display:block;">
                            <div class="boxView">
                                <div class="boxViewForm">
                                    <div class="setupPrHead">
                                        <svg-icon class="svgIcon" [svgStyle]="{ 'width.px':90 }" src="assets/img/group.svg"></svg-icon>
                                        <h2>Add Group</h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <div class="appAction text-center mt-10">
                                            <a href="javascript:void(0);" class="btn thm-btnGray">Cancel</a>
                                            &nbsp;
                                            <a href="javascript:void(0);" class="btn thm-btn btn-next">Continue</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="csLabelBox mb-3">
                                    <h2>Added Group</h2>
                                    <div class="invitedMemberList mt-20">
                                        <div class="membItem">
                                            <div class="membInfoBlk">
                                                <div class="membImg">
                                                    <img src="assets/img/8.png">
                                                </div>
                                                <div class="membName">
                                                    <h2>DJ The Great and Terrible</h2>
                                                    <p>DJ</p>
                                                </div>
                                                <div class="membAct">
                                                    <a class="btn btnSmall btnDanger" href="">Remove Group</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="membItem">
                                            <div class="membInfoBlk">
                                                <div class="membImg">
                                                    <img src="assets/img/6.png">
                                                </div>
                                                <div class="membName">
                                                    <h2>Blue in the Face</h2>
                                                    <p>Singer</p>
                                                </div>
                                                <div class="membAct">
                                                    <a class="btn btnSmall btnDanger" href="">Remove Group</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="membItem">
                                            <div class="membInfoBlk">
                                                <div class="membImg">
                                                    <img src="assets/img/9.png">
                                                </div>
                                                <div class="membName">
                                                    <h2>On a Sensual Note</h2>
                                                    <p>Singer</p>
                                                </div>
                                                <div class="membAct">
                                                    <a class="btn btnSmall btnDanger" href="">Remove Group</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-20">
                                        <a href="javascript:void(0);" class="btn thm-btn btn-next">Add New Group</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset> -->
                        <fieldset style="display:block;" *ngIf="stepNo == 2">
                            <div class="boxView">
                                <form [formGroup]="completeProfileForm" (ngSubmit)="basicInfoStepOne($event);">
                                    <div class="boxViewForm">
                                        <div class="">
                                            <div class="text-center">
                                                <div class="upload-pic mb-5">
                                                    <img src="assets/img/group_user_ico.png" id="pImg">
                                                    <div class="text-center upload-icon">
                                                        <input accept="image/*" class="inputfile hideDiv" id="file-2"
                                                            (change)="onFileChange($event)"
                                                            onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])"
                                                            style="display: none;" type="file" />
                                                        <!-- <input accept="image/*" class="inputfile hideDiv"  id="file-2" (change)="onFileChange($event);" formControlName="profileImage" onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" style="display: none;" type="file"> -->
                                                        <label for="file-2" class="upload_pic">
                                                            <span class="fas fa-camera"></span>
                                                        </label>

                                                    </div>

                                                </div>
                                                <small class="text-danger"
                                                    *ngIf="completeProfileForm.get('profileImage').touched && completeProfileForm.get('profileImage').invalid">
                                                    Image is required field.
                                                </small>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"
                                                    formControlName="fullname" placeholder="Group Name">
                                                <label for="floatingInput">Group Name <span
                                                        class="reqField">*</span></label>
                                                <small class="text-danger"
                                                    *ngIf="completeProfileForm.get('fullname').touched">
                                                    <span
                                                        *ngIf="completeProfileForm.get('fullname').invalid && completeProfileForm.get('fullname').hasError('required')">
                                                        Group name is required field.</span>
                                                    <span
                                                        *ngIf="completeProfileForm.get('fullname').invalid && completeProfileForm.get('fullname').hasError('maxlength')">Please
                                                        enter no more than 30 characters.</span>
                                                    <span 
                                                        *ngIf="completeProfileForm.get('fullname').touched && completeProfileForm.get('fullname').hasError('noWhitespace')">
                                                        Required field.
                                                    </span>
                                                </small>
                                            </div>
                                            <!-- <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="floatingInput" formControlName="availability" placeholder="Enter Availability">
                                            <label for="floatingInput">Availability <span class="reqField">*</span></label>
                                            <small class="text-danger" *ngIf="completeProfileForm.get('availability').touched && completeProfileForm.get('availability').invalid">
                                                Availability is required field.
                                            </small>
                                        </div> -->
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend"
                                                    style="margin-right: -1px;height: 58px !important;">
                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                </div>
                                                <div class="form-floating">
                                                    <input type="number" (keypress)="onKeyPress($event)" class="form-control phone" id="floatingInput"
                                                        formControlName="price" placeholder="Enter Price">
                                                    <label for="floatingInput">Price (Per Hour) <span
                                                            class="reqField">*</span></label>
                                                    <!-- <small class="text-danger" *ngIf="completeProfileForm.get('price').touched && completeProfileForm.get('price').invalid">
                                                    Price is required field
                                                </small><br> -->
                                                    <div *ngIf="completeProfileForm.get('price').invalid && (completeProfileForm.get('price').dirty || completeProfileForm.get('price').touched)"
                                                        class="error">
                                                        <small class="text-danger"
                                                            *ngIf="completeProfileForm.get('price').errors.required">
                                                            Price is required field.
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="completeProfileForm.get('price').hasError('pattern')">
                                                            Price must be valid.
                                                        </small>
                                                        
                                                        <small class="text-danger"
                                                            *ngIf="completeProfileForm.get('price').errors && completeProfileForm.get('price').errors.min">
                                                            Price must be minimum $1.
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="completeProfileForm.get('price').errors && completeProfileForm.get('price').errors.max">
                                                            Price should be less than or equals to $999999.99
                                                        </small>

                                                        <small class="text-danger"
                                                            *ngIf="completeProfileForm.get('price').hasError('maxlength')">
                                                            Please enter minimum 6 digits.
                                                        </small>

                                                    </div>

                                                </div>
                                            </div>
                                            <!-- <div class="form-floating mb-3">
                                            <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" type="text" class="form-control" formControlName="location" id="floatingInput" placeholder="Enter Location">
                                            <label for="floatingInput">Location <span class="reqField">*</span></label>
                                            <small class="text-danger" *ngIf="completeProfileForm.get('location').touched && completeProfileForm.get('location').invalid">
                                                Location is required field.
                                            </small>
                                        </div> -->
                                            <div class="addTagBlk mb-3">
                                                <div class="addTagBlkTitle">
                                                    <label>Type <span class="reqField">*</span></label>
                                                    <div class="textLink">
                                                        <a href="javascript:void(0);" data-toggle="modal"
                                                            data-target="#roleModal" class="">Add Type</a>
                                                        <input type="hidden" formControlName="typeBlankCheck">
                                                        <!-- <div *ngIf="typeBlankCheck.invalid && (typeBlankCheck.dirty || typeBlankCheck.touched)" class="error"> -->

                                                        <!-- </div> -->
                                                    </div>


                                                </div>

                                                <div class="TypeSlect">
                                                    <!---->

                                                    <div class="mediaItem"
                                                        *ngFor="let type of typeListData, let i=index;">
                                                        <h6>{{type.name}}<span (click)="removeType(i)"><i
                                                                    class="fa fa-times"></i></span></h6>
                                                    </div>
                                                    <!-- <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                                    <h6>Actor<span (click)="removeSubType(i)"><i class="fa fa-times"></i></span></h6>
                                                </div> -->
                                                </div>
                                            </div>
                                            <small
                                                *ngIf="completeProfileForm.get('typeBlankCheck').invalid && (completeProfileForm.get('typeBlankCheck').dirty || completeProfileForm.get('typeBlankCheck').touched)"
                                                class="error">
                                                <span class="text-danger"
                                                    *ngIf="completeProfileForm.get('typeBlankCheck').errors.required">
                                                    Type is required field.
                                                </span>
                                            </small>
                                            <div class="addTagBlk mb-3">
                                                <div class="addTagBlkTitle">
                                                    <label>Subtypes <span class="reqField">*</span></label>
                                                    <div class="textLink">
                                                        <a href="javascript:void(0);" data-toggle="modal"
                                                            data-target="#typeModal" class="">Add Subtypes</a>
                                                        <input type="hidden" formControlName="subTypeBlankCheck">

                                                    </div>
                                                </div>
                                                <div class="TypeSlect">

                                                    <div class="mediaItem"
                                                        *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                                        <h6>{{selectedSubType.name}}<span (click)="removeSubType(i)"><i
                                                                    class="fa fa-times"></i></span></h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="completeProfileForm.get('subTypeBlankCheck').invalid && (completeProfileForm.get('subTypeBlankCheck').dirty || completeProfileForm.get('subTypeBlankCheck').touched)"
                                                class="error">
                                                <small class="text-danger"
                                                    *ngIf="completeProfileForm.get('subTypeBlankCheck').errors.required">
                                                    Sub type is required field.
                                                </small>
                                            </div>
                                        </div>
                                        <div class="appAction text-center mt-30">
                                            <a routerLink="/groups" class="btn thm-btnGray">Cancel</a>
                                            &nbsp;
                                            <!-- <a href="javascript:void(0);" class="btn thm-btn btn-next">Continue</a> -->
                                            <button type="submit" class="btn thm-btn">Continue</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 3">
                            <form [formGroup]="completeStepTwo" (ngSubmit)="basicInfoStepTwo($event);">
                                <div class="boxView">
                                    <div class="boxViewForm">
                                        <div class="">
                                            <div class="form-floating mb-3">
                                                <textarea rows="4" type="text" class="form-control textArea"
                                                    formControlName="about" id="floatingInput"
                                                    placeholder="Write a description about who you are and what you do, use this to give hirer’s a window into your life as a livlouder!"></textarea>
                                                <label for="floatingInput">About You <span
                                                        class="reqField"></span></label>
                                                <small class="text-danger"
                                                    *ngIf="completeStepTwo.get('about').hasError('maxlength')">
                                                    Please enter no more than 300 characters.
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="completeStepTwo.get('about').hasError('minlength') && !completeStepTwo.get('about').hasError('noWhitespace')">
                                                    Please enter at least 5 characters.
                                                </small>
                                                <small class="text-danger" 
                                                    *ngIf="completeStepTwo.get('about').touched && completeStepTwo.get('about').hasError('required')">
                                                    This field is required field.
                                                </small>
                                                <small class="text-danger" 
                                                    *ngIf="completeStepTwo.get('about').touched && completeStepTwo.get('about').hasError('noWhitespace')">
                                                    Required field.
                                                </small>

                                            </div>
                                            <div class="csLabelBox mb-3">
                                                <h2>Experience</h2>
                                                <div class="addMoreBlk" formArrayName="experience">
                                                    <ng-container
                                                        *ngFor="let ex of completeStepTwo.get('experience').controls; index as i">

                                                        <div class="input-group rightIcon">

                                                            <div class="form-floating">
                                                                <input type="text" class="form-control addExpInput"
                                                                    formControlName="{{i}}" id="floatingInput"
                                                                    placeholder="Share your past experience">
                                                                <label for="floatingInput">Enter Experience <span
                                                                        class="reqField">*</span></label>
                                                                <small class="text-danger"
                                                                    *ngIf="completeStepTwo.get('experience').controls[i].touched && completeStepTwo.get('experience').controls[i].hasError('required')">
                                                                    Experience is required field
                                                                </small>
                                                                <small class="text-danger"
                                                                    *ngIf="completeStepTwo.get('experience').controls[i].touched && completeStepTwo.get('experience').controls[i].hasError('noWhitespace')">
                                                                    Required field.
                                                                </small>
                                                                <!-- <small class="text-danger"
                                                                    *ngIf="completeStepTwo.get('experience').controls[i].errors?.required">
                                                                    Experience is required field
                                                                </small> -->

                                                            </div>

                                                            <div class="removeField" *ngIf="i > 0"
                                                                (click)="removeExperience(i);">
                                                                <span class="fa fa-times"></span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <!-- <div class="input-group rightIcon">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control phone" formControlName="qualification" id="floatingInput" placeholder="Enter...">
                                                        <label for="floatingInput">Enter Qualification <span class="reqField">*</span></label>
                                                    </div>
                                                    <div class="removeField">
                                                        <span class="fa fa-times"></span>
                                                    </div>
                                                </div> -->
                                                </div>

                                                <div class="textLink text-right">
                                                    <a href="javascript:void(0);" (click)="addExperience();">Add
                                                        More</a>
                                                </div>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"
                                                    formControlName="availability"
                                                    placeholder="Please share rough time and dates you are free for gigs">
                                                <label for="floatingInput">Availability <span
                                                        class="reqField">*</span></label>
                                                <small class="text-danger"
                                                    *ngIf="completeStepTwo.get('availability').touched && completeStepTwo.get('availability').hasError('required')">
                                                    Availability is required field.
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="completeStepTwo.get('availability').touched && completeStepTwo.get('availability').hasError('noWhitespace')">
                                                    Required field.
                                                </small>

                                                <small class="text-danger"
                                                    *ngIf="completeStepTwo.get('availability').hasError('maxlength')">
                                                    Please enter no more than 30 characters.

                                                </small>
                                            </div>
                                            <!-- <div class="csLabelBox mb-3">
                                            <h2>Qualifications</h2>
                                            <div class="addMoreBlk">
                                                <div class="input-group rightIcon">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control phone" id="floatingInput" placeholder="Enter...">
                                                        <label for="floatingInput">Enter Qualification <span class="reqField">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="input-group rightIcon">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control phone" id="floatingInput" placeholder="Enter...">
                                                        <label for="floatingInput">Enter Qualification <span class="reqField">*</span></label>
                                                    </div>
                                                    <div class="removeField">
                                                        <span class="fa fa-times"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="textLink text-right">
                                                <a href="javascript:void(0);">Add More</a>
                                            </div>
                                        </div> -->
                                        </div>
                                        <div class="appAction text-center mt-30">
                                            <!-- <a href="javascript:void(0);" class="btn thm-btnGray">Previous</a>
                                        &nbsp; -->
                                            <!-- <a href="javascript:void(0);" class="btn thm-btn btn-next">Continue</a> -->
                                            <button type="submit" class="btn thm-btn">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                        <fieldset style="display:block !important;" *ngIf="stepNo == 4">
                            <form [formGroup]="uploadImageForm" (ngSubmit)="UploadImgStepThree();">
                                <div class="boxView">
                                    <div class="boxViewForm1">
                                        <div class="setupPrHead">
                                            <h2>Add Photos</h2>
                                            <p>Share some fun photos of your group doing gigs or just being yourselves!
                                            </p>
                                        </div>
                                        <div class="">

                                            <div class="galleryImgAdd" id="imgdiv">
                                                <div class="log_div gallery-img text-center mt-30">
                                                    <label class="uploadLabel">
                                                        <!-- <input accept="image/png, image/jpeg" formControlName="file" class="inputfile hideDiv" (change)="onUploadFile($event);" id="file-2" name="profileImage" onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" style="display: none;" type="file"> -->
                                                        <input accept="images/*" class="inputfile hideDiv"
                                                            formControlName="file" (change)="onUploadFile($event);"
                                                            id="file-2" name="profileImage" style="display: none;"
                                                            type="file" multiple="">

                                                        <!-- <input type="file" accept="images/*" formControlName="file"  (change)="onUploadFile();" class="inputfile hideDiv" id="file-2" name="profileImage" > -->
                                                        <img src="assets/img/camera_placeholder.png" id="pImg">
                                                    </label>
                                                </div>
                                                <ng-container *ngFor="let img of galleryImg; index as i">
                                                    <div class="log_div gallery-img text-center mt-30 one">
                                                        <div class="addedImg imgHeight">
                                                            <img [src]="img.media">
                                                            <div class="text-center upload_pic_in_album bsnes-cam">
                                                                <label class="upload_picNew">
                                                                    <span data-toggle="tooltip" data-placement="top"
                                                                        (click)="removeImg(img.mediaId,i);" title=""
                                                                        class="fas fa-times"
                                                                        data-original-title="Remove Image"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                        <div class="appAction text-center mt-30">
                                            <!-- <a href="javascript:void(0);" class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp; -->
                                            <button type="submit" class="btn thm-btn">{{galleryImg.length > 0 ? 'Next' :
                                                'Skip' }}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 5">
                            <div class="boxView">
                                <div class="boxViewForm1">
                                    <div class="setupPrHead">
                                        <h2>Add Videos</h2>
                                        <p>Share some fun videos of your group doing gigs or just being yourselves!
                                        </p>
                                    </div>
                                    <div class="">
                                        <div class="galleryImgAdd">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <label class="uploadLabel">
                                                    <input accept="video/*" class="inputfile hideDiv" id="file-2"
                                                        name="profileImage" (change)="onSelectedVideo($event)"
                                                        style="display: none;" type="file">
                                                        <!-- onchange="document.getElementById('pImg').src = window.URL.createObjectURL(this.files[0])" -->
                                                    <img src="assets/img/video_placeholder.png" id="pImg">
                                                </label>
                                            </div>
                                            <!-- <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg">
                                                    <img src="assets/img/1.jpg">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                        <label class="upload_picNew">
                                                            <span data-toggle="tooltip" data-placement="top" title="Remove Image" class="fas fa-times"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                    <div class="appAction text-center mt-30">
                                        <!-- <a href="javascript:void(0);" class="btn thm-btnGray">Previous</a>
                                        &nbsp; -->
                                        <a href="javascript:void(0);" (click)="skipVideo();"
                                            class="btn thm-btn">Skip</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 6">
                            <form [formGroup]="socialLinksForm" (ngSubmit)="uploadLinks();">
                                <div class="boxView">
                                    <div class="boxViewForm">
                                        <div class="">
                                            <div class="csLabelBox mb-3">
                                                <h2>Add Social Media</h2>
                                                <div class="addMoreBlk" formArrayName="links">
                                                    <ng-container
                                                        *ngFor="let ex of socialLinksForm.get('links').controls; index as i">
                                                        <div class="socialBlk">

                                                            <div class="socialType">
                                                                <div class="dropdown">
                                                                    <button data-toggle="tooltip" class="iconClass{{i}}"
                                                                        data-placement="top" title="Social Type"
                                                                        type="button"
                                                                        class="form-control dropdown-toggle"
                                                                        id="dropdownMenuButton" data-toggle="dropdown"
                                                                        aria-haspopup="true" aria-expanded="false">

                                                                        <i class="fab fa-twitter-square1"></i>

                                                                        <i *ngIf="linkDyna[i].type !='globe'"
                                                                            class="fab fa-{{linkDyna[i].type}} "></i>
                                                                        <i *ngIf="linkDyna[i].type =='globe'"
                                                                            class="fas fa-globe"></i>
                                                                        <!-- <i  class="fab fa-twitter-square ll"></i>
																			<i  class="fab fa-instagram-square ll"></i>
																			<i  class="fab fa-facebook-square ll"></i>
																			-->
                                                                    </button>
                                                                    <div class="dropdown-menu"
                                                                        aria-labelledby="dropdownMenuButton">
                                                                        <ng-container
                                                                            *ngFor="let social of allSocialLink;index as j ">
                                                                            <a *ngIf="social.isShow == true || social.type == linkDyna[i].type"
                                                                                class="dropdown-item linSelected"
                                                                                href="javascript:void(0)"
                                                                                (click)="changeIcon(j,i)"><i
                                                                                    class="fab fa-{{social.type}}"></i>{{social.type}}</a>
                                                                            <!-- <a class="dropdown-item linSelected" href="javascript:void(0)" (click)="changeIcon(i,1,2)"><i class="fab fa-twitter-square"></i> Twitter</a>
                                        <a class="dropdown-item linSelected" href="javascript:void(0)" (click)="changeIcon(i,2,3)"><i class="fab fa-instagram"></i> Instagram</a> -->
                                                                        </ng-container>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="input-group rightIcon">
                                                                <div class="form-floating">
                                                                    <input type="text" class="form-control phone"
                                                                        formControlName="{{i}}" id="floatingInput"
                                                                        placeholder="Share your social links for your livlouder profile"
                                                                        autocomplete="off">
                                                                    <label for="floatingInput">Enter Social Link</label>
                                                                    <small class="text-danger"
                                                                    *ngIf="socialLinksForm.get('links').controls[i].invalid && socialLinksForm.get('links').controls[i].touched">
                                                                    <!-- <span
                                                                        *ngIf="socialLinksForm.get('links').controls[i].hasError('required')">
                                                                        The field is required.</span> -->
                                                                    <span 
                                                                        *ngIf="socialLinksForm.get('links').controls[i].hasError('noWhitespace')">
                                                                        Required field.
                                                                    </span>
                                                                </small>
                                                                </div>

                                                                <div class="removeField" *ngIf="i > 0"
                                                                    (click)="removeLinks(i);">
                                                                    <span class="fa fa-times"></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-container>

                                                </div>
                                                <div class="textLink text-right">
                                                    <a href="javascript:void(0);" *ngIf="showAddMoreBtn"
                                                        (click)="addLinks();">Add More</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="appAction text-center mt-30">
                                            <!-- {{stepNo}} -->
                                            <!-- <a (click)="previous()" href="javascript:void(0);"
                                                class="btn thm-btnGray btn-previous">Previous</a>
                                            &nbsp; -->
                                            <!-- <a routerLink="/createProfileDone" class="btn thm-btn">Create Profile</a> -->
                                            <!-- <button type="submit" #uploadLinksBtn class="btn thm-btn">continue</button> -->

                                            <button type="submit" #uploadLinksBtn
                                                class="btn thm-btn">{{socialLinksForm.get('links.0').value ?
                                                'Continue':'Skip'}}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                        <fieldset style="display:block;" *ngIf="stepNo == 7">
                            <div class="boxView">
                                <div class="boxViewForm">
                                    <div class="setupPrHead">
                                        <svg-icon class="svgIcon" [svgStyle]="{ 'width.px':90 }"
                                            src="assets/img/group.svg"></svg-icon>
                                        <h2>Invite Member</h2>
                                        <p>You can search livlouders and invite them join your group, they will get a
                                            notification and can accept or decline</p>
                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#inviteMember"
                                            class="mt-10 btn thm-btn">Invite</a>
                                    </div>
                                </div>
                                <div class="csLabelBox mb-3">
                                    <h2>Invited Member</h2>
                                    <div class="invitedMemberList mt-20">
                                        <div *ngIf="dataFound == false">
                                            <div class="noDataBlk">
                                                <div class="noDataCnt">
                                                    <img src="assets/img/no_group.png" />
                                                    <h2>You haven't add any member yet!</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let invitedMember of invitedMember">

                                            <div class="membItem">
                                                <div class="membInfoBlk">
                                                    <div class="membImg">
                                                        <img src="{{invitedMember.profileImage}}">
                                                    </div>
                                                    <div class="membName">
                                                        <h2>{{invitedMember.liveLouderName}}</h2>
                                                        <p>{{invitedMember.type}}</p>
                                                    </div>
                                                    <div class="membAct">
                                                        <a (click)="cancleInvitation(invitedMember.groupRequestId)"
                                                            class="btn btnSmall btnDanger"
                                                            href="javascript:void(0);">Cancel Request</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div class="appAction text-center mt-20">
                                        <a (click)="previous()" href="javascript:void(0);"
                                            class="btn thm-btnGray btn-previous">Previous</a>
                                        &nbsp;
                                        <a routerLink='/groups' href="javascript:void(0);" class="btn thm-btn">Create
                                            Group</a>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype">
                        <div class="typesHead"> {{ category.name }}</div>


                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="typeIdSelected.includes( type.categoryId)"
                                                checked value="type.categoryId"
                                                (click)="onCheckboxChangeRole(type,$event)">
                                            <input type="checkbox" *ngIf="!typeIdSelected.includes( type.categoryId)"
                                                value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>


<div class="modal fade csModal" id="typeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="subTypeIdSelected.includes( subT.categoryId)"
                                                id="check" checked value="subT.categoryId"
                                                (click)="onCheckboxChangesubType(subT,$event)">
                                            <input type="checkbox" *ngIf="!subTypeIdSelected.includes( subT.categoryId)"
                                                value="subT.categoryId" (click)="onCheckboxChangesubType(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray.length == 0">Please select type first</div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>



<div class="modal fade csModal" id="inviteMember" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Members</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times" (click)="ResetForm()"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="searchBar">
                    <!-- <label>
                    <input type="text"  id="search" class="form-control" placeholder="Search Member...">
                    <span class="fa fa-search"></span>
                </label> -->
                    <label>
                        <input #searchText type="text" id="search" (keyup)="searchMember(searchText.value)"
                            class="form-control" placeholder="Search Member..."
                            style="padding: 0px 46px 0px 12px;overflow: hidden !important;">
                        <span class="fa fa-search" (click)="searchMember(searchText.value)"></span>
                    </label>
                </div>
                <div class="modalUserList">
                    <div class="invitedMemberList">
                        <div *ngIf="member.length == 0">
                            <div class="noDataBlk">
                                <div class="noDataCnt">
                                    <img src="assets/img/no_group.png">
                                    <h2>You haven't add any member yet!</h2>
                                </div>
                            </div>
                        </div>


                        <ng-container *ngFor="let member of member">


                            <div class="membItem">
                                <div class="membInfoBlk">
                                    <div class="membImg">
                                        <img src="{{member.profileImage}}">
                                    </div>
                                    <div class="membName">
                                        <h2>{{member.liveLouderName}}</h2>
                                        <p>{{member.type}}</p>
                                    </div>
                                    <div class="membAct">
                                        <a (click)="sendRequest(member.userMetaId,member.userId)"
                                            class="btn btnSmall btnPrimary" href="javascript:void(0);">Send Request</a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- {{membertotal}} {{limit}} {{offset}} {{(offset+limit)}} -->
                        <div class="text-center" *ngIf="membertotal > (offset+limit)">
                            <!-- <div class="text-center"> -->
                            <button class="btn btn-outline-info" (click)="loadMore()">Load More</button>
                        </div>
                        <!-- <div class="membItem">
                        <div class="membInfoBlk">
                            <div class="membImg">
                                <img src="assets/img/3.png">
                            </div>
                            <div class="membName">
                                <h2>John Smith</h2>
                                <p>Singer</p>
                            </div>
                            <div class="membAct">
                                <a class="btn btnSmall btnDanger" href="">Cancel Request</a>
                            </div>
                        </div>
                    </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>