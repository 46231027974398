import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Title } from "@angular/platform-browser";
import { AuthService } from "src/app/services/auth.service";
import { HirerProfileService } from "src/app/services/hirer-profile.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

declare var $: any;

declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-profile-hirer",
	templateUrl: "./profile-hirer.component.html",
	styleUrls: ["./profile-hirer.component.css"]
})
export class ProfileHirerComponent implements OnInit, AfterViewChecked {
	data: any;
	title = "Hirer- Detail";
	openingHour = [];
	image = [];
	videos = [];
	socialMedia = [];
	current_user;
	switched_user;
	current_user_data;
	constructor(
		private hirerDetail: HirerProfileService,
		private authService: AuthService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private router: Router
	) { }

	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.authService.setUserMetaId("2");
		var metaId = this.authService.getUserMetaId();

		this.hirerDetail.detail(metaId).subscribe(
			res => {
				this.data = res.detail;
				// console.log('data data', this.data);


				if (this.data.venueOpeningHours) {
					this.openingHour = JSON.parse(this.data.venueOpeningHours);
				}

				if (this.data.image_media.count > 0) {
					this.image = this.data.image_media.data;
				}

				if (this.data.video_media.count > 0) {
					this.videos = this.data.video_media.data;
				}

				this.socialMedia = this.data.social_media;
			},
			err => {
				// this.toastrService.showError(err, "Error");

				this.authService.checkValidToken(err.error);
                if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
                    this.authService.checkValidToken(err.error);
                    this.toastrService.showError('Session Expired', 'Error');

                } else {
                    this.toastrService.showError(err.error.message, 'Error');
                }
			}
		);
	}

	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}
	// update private profile
	updateProfile() {
		if (this.data.isPrivate == 1) {
			this.router.navigate(["/updateHirerProfilePrivate"]);
		} else {
			this.router.navigate(["/updateHirerProfile"]);
		}
	}


	switchAccound() {
		this.current_user = localStorage.getItem("SelectedUser");

		this.current_user_data = JSON.parse(
			localStorage.getItem("currentUser")
		);

		if (this.current_user_data.userTypeList[0].isProfileComplete == 0) {
			this.router.navigate(["/createLivlouderProfile"]);
			return false;
		}

		// if (this.current_user == 2) {
		// 	this.switched_user = 1;
		// } else {
		// 	this.switched_user = 2;
		// }

		if (this.current_user == 2) {
			this.switched_user = 1;
			var metaid = this.current_user_data.userTypeList[0].userMetaId
		} else {
			this.switched_user = 2;
			var metaid = this.current_user_data.userTypeList[1].userMetaId


		}
		this.hirerDetail
			.switchAccound(this.switched_user, this.current_user)
			.subscribe(
				res => {

					this.data = res.res;
					showLoader();
					var formData = new FormData();
					formData.append("metaId", "1");
					this.hirerDetail.currentUserLogeedIn(formData).subscribe(
						res1 => {
							localStorage.setItem(
								"currentUser",
								JSON.stringify(res1.userDetail)
							);
							localStorage.setItem("SelectedUser", "1");


							hideLoader();
							this.router.navigate(["/loginProfile"]);
						},
						err => {
							this.authService.checkValidToken(err.error);
							this.toastrService.showError(
								err.error.message,
								"Error"
							);
						}
					);
				},
				err => {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError(err.error.message, "Error");
				}
			);
	}
}
