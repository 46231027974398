<app-header-hirer></app-header-hirer>
<style>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <form>
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <fieldset style="display:block;">
                            <div class="boxView">
                                <form [formGroup]='reactiveForm' (ngSubmit)="onSubmit()">
                                    <div class="boxViewForm" id="signupForm">
                                        <div class="setupPrHead text-left">
                                            <h2>Fill Some Basic Info</h2>
                                            <p class="mr-auto ml-0">Enter some basic info about your profile below</p>
                                        </div>
                                        <div>
                                            <!-- <div class="form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"
                                                    formControlName="legalName" placeholder="Enter Name"
                                                    (keyup)="trimValue(reactiveForm.get('legalName'))">
                                                <label for="floatingInput">Legal Name <span
                                                        class="reqField">*</span></label>
                                                <small class="text-danger"
                                                    *ngIf="reactiveForm.get('legalName').touched && reactiveForm.get('legalName').invalid">
                                                    Legal name is required field.
                                                </small>

                                            </div>
                                            <div class="input-group dateIcon mb-3">
                                                <div class="form-floating">
                                                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                        formControlName="DOB" [(ngModel)]="datemodel"
                                                        [minDate]="{year: 1970, month: 1, day: 1}" [maxDate]="maxDate"
                                                        ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" readonly>
                                                    <div class="input-group-append">
                                                        <button class="calendar" (click)="d.toggle()"
                                                            type="button"></button>
                                                    </div>
                                                    <label for="floatingInput">Date of Birth </label>
                                                </div>
                                            </div> -->
                                            <div class="form-floating mb-3 mobileField ausMobile">
                                                <input type="text" class="form-control phone" id="floatingInput"
                                                    formControlName="mobileNo" placeholder="Enter Number" type="number" (keypress)="onKeyPress($event)">
                                                <label for="floatingInput">Mobile Number <span
                                                        class="reqField">*</span></label>
                                                <small class="text-danger"
                                                    *ngIf="reactiveForm.get('mobileNo').touched && reactiveForm.get('mobileNo').hasError('required')">
                                                    Phone is required field.
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="reactiveForm.get('mobileNo').hasError('pattern')">
                                                    Please enter digits only.
                                                </small>
                                                <small class="text-danger"
                                                    *ngIf="reactiveForm.get('mobileNo').hasError('minlength')">
                                                    Please enter number greater than 5.
                                                </small>
                                                <small class="text-danger"
                                                *ngIf="reactiveForm.get('mobileNo').hasError('maxlength')">
                                                Please enter no more than 15 number.
                                            </small>
                                            </div>
                                            <div class="appAction text-center mt-30">
                                                <!-- <a id="otpView" href="javascript:void(0);" class="btn thm-btn">Continue</a> -->
                                                <button type="submit" id="otpView" class="btn thm-btn">Continue</button>

                                                <!-- userType -->
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div class="boxViewForm otpConfirmation" id="otpForm">
                                    <div class="otptextView">
                                        <img src="assets/img/otp.png">
                                        <h2>Verify Your Number</h2>
                                        <p>Please enter the verification code sent to your given mobile number
                                            <br><b>+{{dialCode}}-{{mobileNo}}</b>.
                                        </p>
                                        <div class="textLink mt-20">
                                            <a id="changeNo" href="javascript:void(0)">Change Number</a>
                                        </div>
                                    </div>
                                    <div class="">
                                        <form class="csForm" [formGroup]='otpForm' (ngSubmit)="checkOtp()">
                                            <div class="form-floating mb-3">
                                                <input type="number" (keypress)="onKeyPress($event)" class="form-control" formControlName="otp"
                                                    id="floatingInput" placeholder="Ex: 1536"
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    maxlength="4">
                                                <label for="floatingInput">Enter Code<span
                                                        class="reqField">*</span></label>
                                            </div>
                                            <div class="text-right forgot-txt"><a href="javascript:void(0);"
                                                    (click)="resendOtp();">Resend Code</a></div>
                                            <div class="text-center">
                                                <!-- <button routerLink="/userType" type="button" class="btn thm-btn">Submit</button> -->
                                                <button type="submit" class="btn thm-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>
<app-footer-hirer></app-footer-hirer>