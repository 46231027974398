import { Component, OnInit } from '@angular/core';
declare var $:any

@Component({
  selector: 'app-discover-group-details',
  templateUrl: './discover-group-details.component.html',
  styleUrls: ['./discover-group-details.component.css']
})
export class DiscoverGroupDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $('#imageGallery').lightGallery({
      thumbnail:true,
      selector: '.addedImg',
      download: false
    }); 
    $('#galleryVideos').lightGallery({
      selector: '.addedImg'
    }); 
  }

}
