import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    
    constructor(private authService:AuthService){

    }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token =this.authService.getAuthorizationToken();

    // if token not exists than send original request without auth token
    if(!token){
        return next.handle(httpRequest);

    }

    //if token find than add token and other things
    const modifiedReq = httpRequest.clone({
        headers: new HttpHeaders({
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC',
            'authToken': token
        })
      });
    
    return next.handle(modifiedReq);
  }
}
