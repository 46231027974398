<app-header-livlouder></app-header-livlouder>
<section class="banner-slider">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                <div class="BnnrSc">
                    <div class="banner-txt">
                        <h2>The Best Start Up For Your <span>Elegant App</span></h2>
                        <p class="mt-30">To take a trivial example, which of us ever undertakes laborious physical To take a trivial example, which of us ever undertakes laborious physical</p>
                        <div class="bannerAppSec">
                            <a href="https://apps.apple.com/us/app/livloud/id6476505109" target="_blank"><img src="assets/img/app-store.png" alt=""></a>
                            <a href="javascript:void(0);"><img src="assets/img/google-store.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center head-txt mb-50">
                    <h2>About <span>Us</span></h2>
                    <p>Understanding the easy to use process</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                <div class="about-img">
                    <img src="assets/img/phone2.png" alt="">
                </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="about-txt">
                    <h2>Designed for startups with expert musice</h2>
                    <p>
                        Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion be zealously as. Preference inquietude ask now are dispatched led appearance. Small meant in so
                        doubt hopes.
                    </p>
                    <ul>
                        <li>
                            <h5>Rocket Fast</h5>
                            <p>Elderly is detract tedious assured private</p>
                        </li>
                        <li>
                            <h5>Custom Framework</h5>
                            <p>Do travelling companions contrasted</p>
                        </li>
                        <li>
                            <h5>Minimal Design</h5>
                            <p>Mistress strongly remember up</p>
                        </li>
                    </ul>
                    <button type="button" class="btn btn-theme btn-md">Read more</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-pd-50 light-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center head-txt mb-50">
                    <h2>How does Livloud work?</h2>
                    <p>Check out the video below to see exactly how Livloud can help you get those to-dos done once and for all.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="processVideoBlk">
                    <div class="processVideo">
                        <img src="assets/img/video_thumb.png">
                        <div class="videoPalyIcon">
                            <span class="fa fa-play"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="workProcess">
                            <div class="work-sec text-center">
                                <i class="fas fa-user-lock"></i>
                                <h4>Create An Account</h4>
                                <p>It is a long established fact that a reader will be distracted.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="workProcess">
                            <div class="work-sec text-center">
                                <i class="fas fa-search-plus"></i>
                                <h4>Find Jobs</h4>
                                <p>It is a long established fact that a reader will be distracted.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="workProcess">
                            <div class="work-sec text-center">
                                <i class="fas fa-briefcase"></i>
                                <h4>Apply job</h4>
                                <p>It is a long established fact that a reader will be distracted.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="workProcess">
                            <div class="work-sec text-center">
                                <i class="fas fa-briefcase"></i>
                                <h4>Apply job</h4>
                                <p>It is a long established fact that a reader will be distracted.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="user-sec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 artist-clr text-center">
                <div class="entertain-txt">
                    <img src="assets/img/img1.png" alt="">
                    <h3> i am an entertainer</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint voluptatibus blanditiis exercitationem provident explicabo minima quasi dolores.</p>
                    <a href="#/signup" class="btn mt-20">hire us</a>
                </div>
            </div>
            <div class="col-lg-6 book-clr text-center">
                <div class="entertain-txt">
                    <img src="assets/img/img2.png" alt="">
                    <h3>book entertainement</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint voluptatibus blanditiis exercitationem provident explicabo minima quasi dolores.</p>
                    <a href="#/signup" class="btn mt-20">join us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-pd-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center head-txt mb-50">
                    <h2>Features</h2>
                    <p>Rimply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen standard daand scrambled</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-briefcase"></i></div>
                        <div class="media-body">
                            <h4>Create Job</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-search-plus"></i></div>
                        <div class="media-body">
                            <h4>Search Job</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-comments"></i></div>
                        <div class="media-body">
                            <h4>Quick messaging</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-calendar-check"></i></div>
                        <div class="media-body">
                            <h4>Online Booking</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-headphones"></i></div>
                        <div class="media-body">
                            <h4>24x7 Support</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <div class="features-sec mb-15">
                    <div class="media">
                        <div class="mr-3 feat-ico"><i class="fas fa-cogs"></i></div>
                        <div class="media-body">
                            <h4>Unlimited Features</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-pd-50 our-account img-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="our-account-txt">
                    <h4>The Easiest Way to Get Your <span>New Job</span></h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit Non perferendis</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-pd-50 testimonial-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center head-txt mb-50">
                    <h2>Customer <span>Review</span></h2>
                    <p>Rimply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen standard daand scrambled</p>
                </div>
                <owl-carousel-o [options]="reviewSliderOption" class="testimonial-items">
                    <ng-template carouselSlide *ngFor="let reviews of testimonialItem">
                    <div class="item testimonial-item">
                        <div class="media">
                            <img class="" src="{{reviews.userImg}}" alt="">
                            <div class="media-body">
                                <p>{{reviews.userReview}}</p>
                                <h5 class="mt-10">{{reviews.userName}}</h5>
                                <h4>{{reviews.userTitle}}</h4>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<section class="sec-pd-50 dowload-sec img-bg light-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center dowload-txt">
                <h2>Download Our <span>Best Apps</span></h2>
                <p class="mt-20">Aliquam vestibulum cursus felis. In iaculis iaculis sapien ac condimentum. Vestibulum congue posuere lacus, id tincidunt nisi porta sit amet. Suspendisse et sapien varius, pellentesque dui non, semper orci. Curabitur blandit, diam ut ornare
                    ultricies.
                </p>
                <a href="https://apps.apple.com/us/app/livloud/id6476505109" target="_blank" class="mt-30 mr-10"><i class="fab fa-apple"></i>App Store</a>
                <a href="javascript:void(0);" class="mt-30"><i class="fab fa-android"></i>Google Play</a>
            </div>
        </div>
    </div>
</section>
<app-footer-livlouder></app-footer-livlouder>