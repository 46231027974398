<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container" *ngIf="data">
            <div class="profilePage mainContent">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                        <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar>
                            <div class="discoverItem sidebar__inner profileSideInfo">
                                <div class="dropdown smallDropdown">

                                    <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <button class="btn iconBtn dropdown-toggle buttonCss" title="Sort By"
                                            data-toggle="tooltip" type="button">
                                            <span class="fa fa-ellipsis-v"></span>
                                        </button>
                                    </span>
                                    <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">
                                        <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                            href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>


                                    </div>
                                </div>
                                <div class="disArtistImg profileImage">
                                    <a href="javascript:void(0);"><img [src]="data.profileImage"></a>
                                </div>

                                <div class="disArtistInfo profileInfoBlk">
                                    <a href="javascript:void(0);">
                                        <h2 class="">{{data.name}}</h2>
                                    </a>
                                    <p class="loc"><i class="fa fa-map-marker-alt"></i>
                                        <span class="" *ngIf="data.location != '' && data.venueLocation == ''">{{data.location}}</span>
                                        <span class="" *ngIf="data.location == '' && data.venueLocation != ''">{{data.venueLocation}}</span>
                                        <span class="" *ngIf="data.location == '' && data.venueLocation == ''">NA</span>
                                    </p>
                                </div>


                                <div class="btnActGroup">
                                    <div class="btnActItem bookMeBtn">
                                        <!-- <button routerLink="/ProfileLivlouder" class="btn thm-btn btn-block">Switch To Livlouder</button> -->
                                        <!-- <button (click)="switchAccound()" class="btn thm-btn btn-block">Switch To Livlouder</button> -->

                                    </div>
                                    <div class="btnActItem ml-auto">
                                        <div class="">
                                            <span class="d-inline-block">
                                                <!-- <button    (click)="updateProfile()"class="btn iconBtn" title="Edit Profile" data-toggle="tooltip" type="button">
                                                <span class="fa fa-edit"></span>
                                            </button> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="availability">

                                    <h2>Business Type</h2>
                                    <div *ngIf="data.businessType == null">NA</div>

                                    <p>{{data.businessType}}</p>
                                </div>
                                <div *ngIf="socialMedia.length != 0" class="socialLinkAdded">

                                    <ul>
                                        <ng-container *ngFor="let social of socialMedia">
                                            <li *ngIf="social.type == 'facebook'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-facebook-square"></span> Facebook</a></li>
                                            <li *ngIf="social.type == 'twitter'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-twitter-square"></span> Twitter</a></li>
                                            <li *ngIf="social.type == 'instagram'" class="active"><a
                                                    [href]="social.socialLink" target="_blank"><span
                                                        class="fab fa-instagram"></span> Instagram</a></li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">{{data.about}}</p>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Opening Hours</h2>
                                    <ul class="infoList">
                                        <div *ngIf="openingHour.length == 0">Not available</div>
                                        <ng-container *ngFor="let opening of openingHour">
                                            <li>{{opening}}</li>
                                        </ng-container>

                                    </ul>
                                </div>
                                <!-- <div class="userInfoItem">
                                    <h2 class="infoHead">Qualification</h2>
                                    <ul class="infoList">
                                        <li>Diploma in music art</li>
                                        <li>Lorem Ipsum is simply dummy text of the printing</li>
                                        <li>It is a long established fact that a reader</li>
                                    </ul>
                                </div> -->
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div *ngIf="image.length == 0">Not available</div>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor="let img of image">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" [attr.data-src]="img.thumbImage">
                                                    <img [src]="img.image">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Videos</h2>
                                    <div *ngIf="videos.length == 0">Not available</div>
                                    <ng-container *ngFor="let video of videos">
                                        <div style="display:none;" id="{{video.id}}">
                                            <video class="lg-video-object lg-html5" controls preload="none">
                                                <source [src]="video.video" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </ng-container>
                                    <!-- <div style="display:none;" id="video2">
                                        <video class="lg-video-object lg-html5" controls preload="none">
                                            <source src="videos/video2.mp4" type="video/mp4">
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div> -->
                                    <div id="galleryVideos" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor="let video of videos">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-poster="{{video.videoImageThumb}}"
                                                    attr.data-html="{{'#'+video.id}}">
                                                    <img [src]="video.videoImageThumb">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top"
                                                                title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-poster="assets/img/images-2.jpg" data-html="#video2">
                                                <img src="assets/img/images-2.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/images-3.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/images-4.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/images-5.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="widgetBox mt-30" *ngIf="data.rating_review.count > 0">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Reviews <span class="NoCount">({{data.rating_review.count}})</span>
                                </h2>
                                <div class="reviewsList">
                                    <ng-container *ngFor="let review of data.rating_review.data">
                                        <div class="reviewsItem">
                                            <div class="reviewsCntBlk">
                                                <div class="reviewUserImg">
                                                    <a href="javascript:void(0);">
                                                        <img [src]="review.profileImage">
                                                    </a>
                                                </div>
                                                <div class="reviewsCnt">
                                                    <a href="javascript:void(0);">
                                                        <h2>{{review.fullName}}</h2>
                                                    </a>
                                                    <div class="rateTimeMeta">
                                                        <p>{{review.agotime}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratingView ml-auto">
                                                    <div class="ratingBlk">
                                                        <i [class.filled]="review.rating >= '1'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '2'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '3'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '4'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '5'"
                                                            class="fas fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="retDec">
                                                <p *ngIf="review.review != 'null' && review.review != ''">{{review.review}}</p>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="swithProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Switch Profile To</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList switchProfileList modalListScroll">
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/8.png">
                                </div>
                                <div class="membName">
                                    <h2>DJ The Great and Terrible</h2>
                                    <p>Group</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/2.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Hirer</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/3.png">
                                </div>
                                <div class="membName">
                                    <h2>Isabella</h2>
                                    <p>Livlouder</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/9.png">
                                </div>
                                <div class="membName">
                                    <h2>DJ The Great and Terrible</h2>
                                    <p>Group</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/4.png">
                                </div>
                                <div class="membName">
                                    <h2>Jamse</h2>
                                    <p>Hirer</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="membItem">
                        <label class="selectGroupBook">
                            <input type="radio" name="groupChoose">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/5.png">
                                </div>
                                <div class="membName">
                                    <h2>John Williams</h2>
                                    <p>Livlouder</p>
                                </div>
                                <div class="membAct radioCheck">
                                    <span class="fa fa-check-circle"></span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a> -->
                <button type="button" class="btn thm-btn">Continue</button>
            </div>
        </div>
    </div>
</div>


<!--  report Model -->



<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="reportForm" (ngSubmit)="reportLivlouder();">
            <div class="modal-content">
                <div class="modal-headerIcon">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                        <h2>Report Hirer</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="form-floating mb-3">
                                    <select type="text" class="form-control" formControlName="title" id="floatingInput">
                                        <ng-container *ngFor="let title of reportTitle">
                                            <option [value]="title.id">{{title.title}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="reportForm.get('title').touched && reportForm.get('title').invalid">
                                        <span *ngIf="reportForm.get('title').hasError('required')">Title is required
                                            field.</span>

                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" formControlName="description" type="text"
                                        class="form-control textArea" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="reportForm.get('description').touched">

                                        <span *ngIf="reportForm.get('description').hasError('required')">Description is
                                            required field.</span>
                                        <span *ngIf="reportForm.get('description').hasError('maxlength')"> Please enter
                                            no more than 300 characters.</span>
                                        <span *ngIf="reportForm.get('description').hasError('minlength')"> Please enter
                                            at least 5 characters.</span>
                                    </small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>



<app-footer-livlouder></app-footer-livlouder>