<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <!-- <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <label>
                            <input type="text" id="search"  class="form-control" (keyup)="search()" placeholder="Search Member...">
                            <span class="fa fa-search"></span>
                        </label>
                    </div>
                </div> -->
                <div class="filterItem ml-auto">
                    <div class="filterIconGrp">
                        <!-- <div class="filterIconItem">
                            <div class="dropdown smallDropdown">
                                <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <button class="btn iconBtn dropdown-toggle" title="Sort By" data-toggle="tooltip" type="button">
                                    <span class="fa fa-sort-amount-down"></span>
                                </button>
                                </span>
                                <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="allGig()" href="javascript:void(0)">All</a>
                                    <a class="dropdown-item" href="javascript:void(0)">Featured</a>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="filterIconItem">
                            <button title="Saved Gigs" data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span class="fa fa-heart"></span>
                            </button>
                        </div> -->
                        <!-- <div class="filterIconItem">
                            <button title="View on Map" data-toggle="tooltip12"  [routerLink]="['/myGigsHirer']"   class="btn iconBtn" type="button">
                                <span class="fa fa-list"></span>
                            </button>
                        </div> -->
                        <div class="filterIconItem">
                            <button title="Filter" data-toggle="tooltip" (click)="sideBarViewOpen();" class="btn iconBtn" type="button">
                                <span class="fa fa-filter"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gigsMap mt-30">
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="4">
                    <ng-container *ngFor="let location of locations">
                        <agm-marker  *ngIf="location.latitude != '' && location.longitude !=''" [latitude]="location.latitude" [longitude]="location.longitude">
                            <agm-info-window>
                                <strong>{{location.title}}</strong>
                                <br> <strong>{{location.location}} </strong>
                                <br><button id="mapButton"  class="btn btn-primary"   [routerLink]="['/myGigsHirer/detail/',location.gigId]">View Detail </button>
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </agm-map>
            </div>
        </div>
    </section>
</div>

<div id="sideBarView" class="sideBarSec">
    <div class="sideBarHead clearfix">
        <h3>Filter
            <span class="filterAct">
            <button type="button" (click)="sideBarReset();" class="btn btn-light">Reset</button>
            <button type="button" (click)="applyFilter();" class="btn thm-btn">Apply</button>
            <span class="closeCateg" (click)="sideBarViewClose();"><span class="fa fa-times"></span></span>
            </span>
        </h3>
    </div>
    <div class="sideBarBody">
        <div class="filterBody">
            <div class="csForm">
                <div class="form-floating mb-30">
                    <input ngx-google-places-autocomplete [options]='locationoptions' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event);" type="text" class="form-control" id="floatingInput" placeholder="Enter Location">

                    <!-- <input type="email" class="form-control" id="floatingInput" placeholder="Enter Location"> -->
                    <label for="floatingInput">Location</label>
                </div>
                <!-- <div class="dateIcon form-floating mb-30">
                    <input class="form-control" placeholder="yyyy-mm-dd" id="startDate" name="dp" [(ngModel)]="dateModel" [minDate]="{year: 1970, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                    <label for="floatingInput">Date</label>
                </div> -->




                <div class="csLabelBox mb-3">
                    <!-- <h2>Start Date</h2> -->
                    <div class="row">
                        <div class="dateIcon col-12">
                            <div class="form-floating">
                                <input class="form-control" placeholder="yyyy-mm-dd" id="startDate"
                                name="dp"  [(ngModel)]="model"  [minDate]="{year: 1970, month: 1, day: 1}" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" readonly>
                                <div class="input-group-append"  >
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div >    
                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                <label for="floatingInput">Select Start Date</label>
                               
                            </div>
                        </div>
                        <!-- <div class="col-6">
                            <div class="form-floating">
                                <input type="time" class="form-control dateNtime startTime" id="floatingInput" placeholder="Select">
                                <label for="floatingInput">Select Time</label>
                               
                            </div>
                        </div> -->
                    </div>
                </div>







                <div class="csLabelBox mb-3">
                    <!-- <h2>End Date</h2> -->
                    <div class="row">
                        <div class="dateIcon col-12">
                            <div class="form-floating">
                                <input class="form-control" placeholder="yyyy-mm-dd" id="endDate"
                                 name="dp1" [(ngModel)]="endModel" [minDate]="{year: 1970, month: 1, day: 1}" ngbDatepicker #d1="ngbDatepicker" (click)="d1.toggle()" readonly>
                         <div class="input-group-append"  >
                           <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                         </div>    
                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                <label for="floatingInput">Select End Date</label>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>



                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Distance</label>
                            <div id="distance_filter"><b><span>{{ sliderForm.value.sliderControl[0] }}</span> Km - <span>{{ sliderForm.value.sliderControl[1] }}</span> Km</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControl" (mouseup)="distanceSlider()"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Price</label>
                            <div id="priceTo"><b>$<span>{{ sliderForm.value.sliderControlPrice[0] }}</span> - $<span>{{ sliderForm.value.sliderControlPrice[1] }}</span></b></div>
                        </div>
                        <ngx-slider [options]="priceSlider" formControlName="sliderControlPrice" (mouseup)="sliderEvent()"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <!-- <label class="inLabel">By Role</label> -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add Type</a>
                            </div>
                        </div>
                        <div class="TypeSlect">
                            <!---->
                            <div class="mediaItem" *ngFor="let type of typeListData, let i=index;">
                                <h6>{{type.name}}<span (click)="removeType(i)"><i class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>


                    <!--  group type  -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Group Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal1"
                                    class="">Add Group Type</a>
                            </div>
                        </div>
                        <div class="TypeSlect">
                            <!---->
                            <div class="mediaItem" *ngFor="let type of typeListData1, let i=index;">
                                <h6>{{type.name}}<span (click)="removeType1(i)"><i class="fa fa-times"></i></span>
                                </h6>
                            </div>
                        </div>
                    </div>






                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal" class="">Add Subtypes</a>
                            </div>
                        </div>
                        <div class="TypeSlect">

                            <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                <h6>{{selectedSubType.name}}<span (click)="removeSubType(i)"><i class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>





                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Group Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal1"
                                    class="">Add Group Subtypes</a>
                            </div>
                        </div>
                        <div class="TypeSlect">

                            <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected1; let i=index;">
                                <h6>{{selectedSubType.name}}<span (click)="removeSubType1(i)"><i
                                            class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    </div>
</div>
<div id="modalOverlay" class="sidebarOverlay"></div>
<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype">
                        <div class="typesHead"> {{ category.name }}</div>
                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                        <input type="checkbox" *ngIf="typeIdSelected.includes( type.categoryId)" checked value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)" >
                                        <input type="checkbox" *ngIf="!typeIdSelected.includes( type.categoryId)" value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)" >

                                        <span class="cr">
                                            <i class="cr-icon fa fa-check" ></i>
                                        </span> {{type.name}} 
                                    </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>
<div class="modal fade csModal" id="subtypeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span class="fa fa-times"></span>
          </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                      <input type="checkbox" *ngIf="subTypeIdSelected.includes( subT.categoryId)" checked value="subT.categoryId" (click)="onCheckboxChangesubType(subT,$event)">
                                      <input type="checkbox" *ngIf="!subTypeIdSelected.includes( subT.categoryId)" value="subT.categoryId" (click)="onCheckboxChangesubType(subT,$event)">
  
                                      <span class="cr">
                                          <i class="cr-icon fa fa-check"></i>
                                      </span> {{subT.name}} 
                                  </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray.length == 0">Please select type first</div>

                </div>
            </div>

        </div>
    </div>
</div>


<!--  group model -->


<div class="modal fade csModal" id="roleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Choose Group Type</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span class="fa fa-times"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="typeListBlk">
                <div class="typesBlock" *ngFor="let category of typeSubtype1">
                    <div class="typesHead"> {{ category.name }}</div>
                    <div class="typesOption">
                        <ul *ngFor="let type of category.Type">
                            <li>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" *ngIf="typeIdSelected1.includes( type.categoryId)"
                                            checked value="type.categoryId"
                                            (click)="onCheckboxChangeRole1(type,$event)">
                                        <input type="checkbox" *ngIf="!typeIdSelected1.includes( type.categoryId)"
                                            value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)">

                                        <span class="cr">
                                            <i class="cr-icon fa fa-check"></i>
                                        </span> {{type.name}}
                                    </label>
                                </div>
                            </li>



                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary">Save changes</button>
  </div> -->
    </div>
</div>
</div>






<div class="modal fade csModal" id="subtypeModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Choose Group Subtypes</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span class="fa fa-times"></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="typeListBlk">
                  <div class="typesBlock" *ngFor=" let subType of subTypeArray1">
                      <div class="typesHead">{{subType.name}}</div>
                      <div class="typesOption">
                          <ul *ngFor="let subT of subType.subType">
                              <li>
                                  <div class="checkbox">
                                      <label>
                                          <input type="checkbox" *ngIf="subTypeIdSelected1.includes( subT.categoryId)"
                                              checked value="subT.categoryId"
                                              (click)="onCheckboxChangesubType1(subT,$event)">
                                          <input type="checkbox"
                                              *ngIf="!subTypeIdSelected1.includes( subT.categoryId)"
                                              value="subT.categoryId" (click)="onCheckboxChangesubType1(subT,$event)">

                                          <span class="cr">
                                              <i class="cr-icon fa fa-check"></i>
                                          </span> {{subT.name}}
                                      </label>
                                  </div>
                              </li>

                              <!--                      
onCheckboxChangeRole1
onCheckboxChangesubType1 -->

                          </ul>
                      </div>
                  </div>

                  <div *ngIf="subTypeArray1.length == 0">Please select group type first</div>

              </div>
          </div>
          <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
  </div>
</div>


<app-footer-hirer></app-footer-hirer>