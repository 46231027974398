import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GigService } from 'src/app/services/gig-service';
import { AuthService } from 'src/app/services/auth.service';
import { StripeService, StripeCardComponent } from "ngx-stripe";
import { PaymentService } from "src/app/services/payment.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import socialshares from 'socialshares/dist/socialshares.noicons';
import twitter from 'socialshares/dist/icons/twitter';
import facebook from 'socialshares/dist/icons/facebook';
import linkedin from 'socialshares/dist/icons/linkedin';
import email from 'socialshares/dist/icons/email';
import more from 'socialshares/dist/icons/more';
import googleplus from 'socialshares/dist/icons/googleplus';
import {
	StripeCardElementOptions,
	StripeElementsOptions,
} from "@stripe/stripe-js";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-discover-details-hirer',
	templateUrl: './discover-details-hirer.component.html',
	styleUrls: ['./discover-details-hirer.component.css']
})
export class DiscoverDetailsHirerComponent implements OnInit {
	@ViewChild(StripeCardComponent) card: StripeCardComponent;
	reportForm: FormGroup;

	title = "Hirer - Discover Detail";
	userMetaId;
	data;
	experience = [];
	type = [];
	subtype = [];
	discoverList = [];
	total: any;
	dataFound = false;
	show = false;
	paymentForm: FormGroup;
	stripeCardValid: boolean = false;
	cardLists = [];
	source = "";
	is_favourite;
	reportTitle;
	getUserType;

	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: "#666EE8",
				color: "#31325F",
				fontWeight: "300",
				fontSize: "15px",
				"::placeholder": {
					color: "#ccc",
				},
			},
		},
	};
	elementsOptions: StripeElementsOptions = {
		locale: "en",
	};
	get validForm() {
		return this.paymentForm.valid && this.stripeCardValid;
	}
	constructor(

		private activatedRoute: ActivatedRoute,
		private router: Router,
		private gigService: GigService,
		private authService: AuthService,
		private titleService: Title,
		private stripeService: StripeService,
		private fb: FormBuilder,
		private payService: PaymentService,
		private toastrService: ToastrNotificationService,



	) { }

	ngOnInit(): void {
		// alert('hiiiii');
		this.paymentForm = this.fb.group({
			name: ["", [Validators.required]],
		});
		this.cardList();

		this.titleService.setTitle(this.title);

		this.authService.setUserMetaId(2);
		this.activatedRoute.paramMap.subscribe(param => {
			this.userMetaId = param.get('id');

		})
		showLoader();
		this.gigService.discoverDetail(this.userMetaId).subscribe(res => {
			hideLoader();
			this.data = res.detail;
			this.getUserType = res.detail.userType;			
			this.is_favourite = this.data.is_favourite;
			if (this.data.experience) {
				this.experience = JSON.parse(this.data.experience);
			}
			this.data.rating_review.data.forEach(element => {

				element.agotime = this.diffHours(element.created_date, element.current_time);
			});

			if (this.data.type) {
				this.type = this.data.type.split("|");
			}
			if (this.data.subType) {
				this.subtype = this.data.subType.split("|");
			}

		},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');
		
				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}

			});

		//get report title
		this.gigService.getReportTitle().subscribe((res) => {
			this.reportTitle = res.data;
		});

		this.reportForm = new FormGroup({
			title: new FormControl(null, Validators.required),
			description: new FormControl(null, [
				Validators.required,
				Validators.maxLength(300),
				Validators.minLength(5),
			]),
		});
		//  social sharing code 
		socialshares.configure({
			icons: {
				twitter,
				facebook,
				linkedin,
				email,
				googleplus,
				more,
				// others: googleplus, linkedin, pinterest, reddit, slack, tumblr, vk, more
			}
		})
		socialshares.configure({
			//serverUrl = environment.baseUrl;
			//console.log(environment)
			url: `${location.hostname}${this.router.url}`,
			title: 'Your page title',
			text: 'Your page description',
			size: 'medium',
			theme: 'light',
			icononly: false,
			responsive: true,
			dialog: {
				width: 650,
				height: 450,
			},
		})

		socialshares.mount() // render with new config

	}






	myDiscoverList() {
		this.gigService.getPrivateGig(this.getUserType).subscribe(res => {
			hideLoader();

			if (res.message == "Found") {

				this.discoverList = res.data.gig;
				this.total = res.data.total_records;
				this.dataFound = true;
			} else {

				this.discoverList = [];
				this.dataFound = false;
				this.total = 0;
			}
		}, err =>{
			$('#bookMe').modal('hide');
			// hideLoader();


			// if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
			// 	this.authService.checkValidToken(err.error);
			// 	this.toastrService.showError('Session Expired', 'Error');
	
			// } else {
			// 	this.toastrService.showError(err.error.message, 'Error');
			// }
		});
	}

	checkGigPopup() {

		var gigList = [];
		$('input[name="groupChoose"]:checked').each(function () {
			gigList.push(this.value);
		});

		if (gigList.length == 0) {

			this.toastrService.showError("Please choose a gig first", "Error");
		} else {
			$('#bookMe').modal('hide');
			$('#bookMeRequest').modal('show');
		}

	}

	addCard(tokenId, customerId) {
		var formData = new FormData();
		formData.append("sourceId", tokenId);
		formData.append("stripeCustomerId", this.payService.getStripeCusID());

		this.payService.addCard(formData).subscribe(
			(res) => {
				hideLoader();
				// console.log(res);
				this.cardList();
				// $('#addCard').modal('hide');
				if (res.status == 'fail') {
					this.toastrService.showError(res.message, 'Error');
				}else {
					this.toastrService.showSuccess(res.message,'Success');
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');
		
				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}
	//  card list 
	cardList() {
		let stripeCustId = this.payService.getStripeCusID();

		this.payService.getCardList(stripeCustId).subscribe(
			(res) => {
				this.cardLists = res.data;
			},
			(err) => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					//this.toastrService.showError('Session Expired', 'Error');
		
				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	onChange({ type, event }) {
		// console.log("type", type);
		if (type === "change") {
			this.stripeCardValid = event.complete;
		}
	}
	// confm payment 
	resetCardForm() {

		this.card.element.clear();
	}

	// alert(this.)
	confirmRequest() {
		this.source = $('input[name="radio1"]:checked').val();
		if (!this.source) {
			this.toastrService.showError('Please enter card details', 'Error'); return false;
		}
		$('#addCard').modal('hide');
		var gigList = [];
		$('input[name="groupChoose"]:checked').each(function () {
			gigList.push(this.value);
		});

		this.authService.setUserMetaId(2);
		var formData = new FormData();
		formData.append('gigId', gigList.toString())
		formData.append('hirerMetaId', this.authService.getUserMetaId())
		formData.append('metaId', this.userMetaId)
		formData.append('userId', this.data.userId)
		formData.append('source', this.source)


		// console.log('what',formData);return false;
		showLoader();
		this.gigService.gigRequest(formData).subscribe(res => {
			// console.log('formData',this.activatedRoute.snapshot.params.id);return false;
			// console.log('source',res);
			hideLoader();

			// {status: "success", message: "Gig request has sent successfully"}
			$('#bookMe').modal('hide');
			this.toastrService.showSuccess(res.message, 'Success');
			// var idd = this.activatedRoute.snapshot.params.id;
			this.router.navigate(['discoverHirer/detail/', this.userMetaId]);
		}, err => {
			hideLoader();
			this.toastrService.showError(err.error.message, 'Error');

		})
	}


	getAll() {

		$('#bookMeRequest').modal('hide');
		$('#addCard').modal('show');
	}


	reportGig() {
		if (this.reportForm.invalid) {
			this.validateAllFormFields(this.reportForm);
			return false;
		} else {

			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append("titleId", this.reportForm.value.title);
			formData.append("description", this.reportForm.value.description);
			formData.append("reportToMetaId", this.userMetaId);
			formData.append("reportByMetaId", metaId);
			formData.append("reportByUserType", '2');



			showLoader();
			this.gigService.reportLivlouder(formData).subscribe(
				(res) => {
					if ((status = "success")) {
						$("#reportUser").modal("hide");
						hideLoader();
						this.reportForm.reset();

						this.toastrService.showSuccess(res.message, "Success");
					}
				},
				(err) => {
					$("#reportUser").modal("hide");

					hideLoader();

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');
			
					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}

				}
			);
		}
	}

	likeLivlouder() {
		var metaId = this.authService.getUserMetaId();

		var formData = new FormData();
		formData.append("userId", metaId);
		formData.append("metaId", this.userMetaId);

		showLoader();
		this.gigService.likeLivlouder(formData).subscribe((res) => {
			hideLoader();
			// console.log(res)
			// console.log('isFav');
			// console.log(res.is_favourite);

			this.is_favourite = res.is_favourite;

			this.toastrService.showSuccess(res.message, "Success");
		}, err =>{
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');
	
			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		});
	}
	buy() {
		showLoader();

		this.stripeService
			.createToken(this.card.element, { name: this.paymentForm.value.name })
			.subscribe((result) => {
				if (result.token) {
					this.resetCardForm();

					this.cardList();

					this.addCard(result.token.id, result.token.card.id);

					// console.log(result.token);
					this.source = result.token.id;

				} else if (result.error) {
					hideLoader();
					this.toastrService.showError(result.error.message, "Error");
					// console.log(result.error.message);
				}
			});
	}

	ngAfterViewChecked() {
		$('#imageGallery').lightGallery({
			thumbnail: true,
			selector: '.addedImg',
			download: false
		});
		$('#galleryVideos').lightGallery({
			selector: '.addedImg'
		});
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;
		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs((date2 - date1));
		var diffHr = (difference / 60 / 60);
		var daysDiff = (difference / 60 / 60 / 24)
		if (isNaN(daysDiff)) {
			return 'N/A';
		}
		if (daysDiff < 1) {
			if (diffHr < 1) {

				difference = (difference / 60);
				var hourDiff = Math.floor(difference) + ' Min Ago'

			} else {

				difference = (difference / 60 / 60);
				var hourDiff = Math.floor(difference) + ' Hour Ago'
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + ' Days Ago'
		}

		return hourDiff;

	}
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}

}
