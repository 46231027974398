import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-create-done',
  templateUrl: './profile-create-done.component.html',
  styleUrls: ['./profile-create-done.component.css']
})
export class ProfileCreateDoneComponent implements OnInit {
  current_user: any;
  constructor(
    private router:Router,
  ) { }

  ngOnInit(): void {

    this.current_user = localStorage.getItem('SelectedUser');
    
  }

  profileDone(){

    // const SelectedUser = JSON.parse(localStorage.getItem('SelectedUser'));
    // console.log(localStorage.getItem('metaId'));

    if(this.current_user == 2){
      // alert('2')
      this.router.navigate(['/myGigsHirer']);
    }
    
    if(this.current_user == 1){
      var userMetaId = localStorage.getItem('metaId');

      localStorage.setItem("metaId", userMetaId);
      // set user
      var currentUser = JSON.parse(localStorage.getItem("currentUser"));
      var metaId = localStorage.getItem("metaId");
  
      var check_profile = currentUser.livlouderList;
  
      for (let i = 0; i < check_profile.length; i++) {
        var getvalue = check_profile[i];
  
        if (check_profile[i].userMetaId == metaId) {
          currentUser.userTypeList[0] = getvalue;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
      }

      this.router.navigate(['/gigs']);
    }
  }

}
