import {
	Component,
	OnInit
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
	AbstractControl,
	ValidationErrors
} from "@angular/forms";
import {
	ActivatedRoute,
	Router
} from "@angular/router";
import {
	subscribeOn
} from "rxjs/operators";
import {
	AuthService
} from "src/app/services/auth.service";
import {
	GigService
} from "src/app/services/gig-service";
import {
	ToastrNotificationService
} from "src/app/services/toastr-notification.service";
import {
	BrowserModule,
	Title
} from "@angular/platform-browser";
import {
	PaymentService
} from "src/app/services/payment.service";
import {
	mdTransitionAnimation
} from "@ionic/angular";
import socialshares from 'socialshares/dist/socialshares.noicons';
import twitter from 'socialshares/dist/icons/twitter';
import facebook from 'socialshares/dist/icons/facebook';
import linkedin from 'socialshares/dist/icons/linkedin';
import email from 'socialshares/dist/icons/email';
import more from 'socialshares/dist/icons/more';
import googleplus from 'socialshares/dist/icons/googleplus';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-gig-details",
	templateUrl: "./gig-details.component.html",
	styleUrls: ["./gig-details.component.css"],
})
export class GigDetailsComponent implements OnInit {
	show = false;
	forgotPwdForm: FormGroup;

	public show_dialog: boolean = false;
	stripeUlr;
	gigData;
	gigId;
	mustHave;
	images;
	agoTime;
	myOffer;
	offers: any = [];
	qusAns: any = [];
	reportTitle;
	is_favourite;
	offerForm: FormGroup;
	reportForm: FormGroup;
	bankAccountForm: FormGroup;
	title = "Livloud- Gig Detail";
	metaId;
	popupStatus;
	subtype: any = [];
	group_subtype: any = [];
	isLogin;
	currentRate = 1;
	rating;
	groupList;
	dataFound;
	check;
	id; // meta id for profile ang group
	groupId;
	assignUserMetaId;
	groupMemberList: any = [];
	asddddddd: any = [];
	gigPrice;
	group_member;
	price;
	equalDate;
	LivlouderName: any = '';
	hirerName: any = '';
	disputeMsg;
	disputeBtn = "Raise dispute";
	disputeClosed: any = 0;
	amount;
	Openprice;
	is_profile_or_group: any;
	rPrice;
	admin_price;
	currentUserMetaId;
	isButtonDisabled = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private gigService: GigService,
		private authService: AuthService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private payService: PaymentService,
		private fb: FormBuilder
	) { }

	ngOnInit(): void {
		// console.log(`${location.hostname}${this.router.url}`)



		this.isLogin = this.authService.isLoggedIn() == true;
		// if(this.isLogin == false){
		//   this.router['/login'];

		// }

		this.titleService.setTitle(this.title);
		if (this.authService.isLoggedIn() == true) {
			this.authService.setUserMetaId(1);
		}

		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false,
		});

		this.offerForm = new FormGroup({
			offerDescription: new FormControl(null, [
				Validators.required,
				Validators.maxLength(300),
				Validators.minLength(5),
				this.noWhitespaceValidator
			]),
			// offerPrice: new FormControl(null, [
			// 	Validators.required,
			// 	// Validators.pattern("^[0-9]*$"),
			// 	Validators.maxLength(6)


			// ]),
			offerPrice: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(999999.99),
				// Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
				Validators.pattern("^([0-9]{1,6})((.[0-9]{2})?)$"),
				Validators.maxLength(9)])
		});
		this.forgotPwdForm = new FormGroup({
			'comments': new FormControl(null, [Validators.required]),


		})
		this.reportForm = new FormGroup({
			title: new FormControl(null, Validators.required),
			description: new FormControl(null, [
				Validators.required,
				Validators.maxLength(300),
				Validators.minLength(5),
			]),
		});

		showLoader();
		if (this.authService.isLoggedIn() == true) {
			var metaId = this.authService.getUserMetaId();
			this.metaId = metaId;
		}

		this.activatedRoute.paramMap.subscribe((param) => {
			this.gigId = param.get("id");
		});



		if (this.authService.isLoggedIn() == true) {
			this.gigService.gigDetail(this.gigId, metaId).subscribe(
				(res) => {

		this.currentUserMetaId = localStorage.getItem("metaId");

					// console.log('currentUser', currentUserMetaId );
					console.log('aaasssddd', res.data );
					
					this.disputeBtn = "Raise dispute";
					this.disputeClosed = res.data.isClosed;
					this.LivlouderName = res.data.assignedLivlouderName;
					this.hirerName = res.data.postedByName;
					this.is_profile_or_group = res.data.is_profile_or_group
					// this.disputeClosed = res.data.favourOfUserType;
					if (res.data.isClosed == 0 || res.data.isClosed == 1) {
						this.disputeBtn = "View dispute";
						if (res.data.isClosed == 1) {
							this.disputeMsg = "Dispute is resolved in favour of " + res.data.in_favour_name;
						}
					}

					var startDate = res.data.startDate.split(" ");
					var endDate = res.data.endDate.split(" ");

					if (startDate[0] == endDate[0]) {

						this.equalDate = true;
					} else {

						this.equalDate = false;
					}

					this.groupId = res.data.groupId;
					this.assignUserMetaId = res.data.assignUserMetaId;
					// alert(this.assignUserMetaId);
					this.gigPrice = res.data.price;
					this.price = this.gigPrice;

					var obj: any = {};
					obj.groupId = this.groupId;
					obj.userMetaId = this.assignUserMetaId;

					if (res.data.group_member != null) {
						res.data.group_member.forEach((element, index) => {
							// console.log(index)
							if (element.metaId == this.metaId) {
								this.price = element.amount
								// alert(this.price);
							}

						});
					} else {
						this.price = this.gigPrice;

					}

					// alert(this.price);

					this.gigService.groupMember(obj).subscribe(
						res => {
							this.groupMemberList = res.data.accepted;


						})


					this.filterDetail(res);
				},

				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						//this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}

				}
			);
		} else {
			this.gigService.gigDetailOpen(this.gigId).subscribe(
				(res) => {

					this.Openprice = res.data.price;
					this.filterDetail(res);
				},
				(err) => {
					hideLoader();

				}
			);
		}

		// if (this.authService.isLoggedIn() == true) {

		// 	var obj: any = {};
		// 	obj.groupId = this.groupId;
		// 	obj.userMetaId = this.assignUserMetaId;
		// 	showLoader();
		// 	this.gigService.groupMember(obj).subscribe(
		// 		res => {
		// 			console.log(res.data.accepted);
		// 			hideLoader();

		// 			this.groupMemberList = res.data.accepted;
		// 			console.log('hellllo', this.groupMemberList.length)


		// 		})
		// }


		if (this.authService.isLoggedIn() == true) {
			//get report title
			showLoader();
			this.gigService.getReportTitle().subscribe((res) => {
				this.reportTitle = res.data;
			}, (err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					// this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});
		}


		this.bankAccountForm = this.fb.group({
			accountName: ["", Validators.required],
			bsdCode: ["", Validators.required],
			accountNo: ["", Validators.required],
			// address:['',Validators.required]
		});


		//  social sharing code 
		socialshares.configure({
			icons: {
				twitter,
				facebook,
				linkedin,
				email,
				googleplus,
				more,
				// others: googleplus, linkedin, pinterest, reddit, slack, tumblr, vk, more
			}
		})
		socialshares.configure({
			//serverUrl = environment.baseUrl;
			//console.log(environment)
			url: `${location.hostname}${this.router.url}`,
			title: 'Your page title',
			text: 'Your page description',
			size: 'medium',
			theme: 'light',
			icononly: false,
			responsive: true,
			dialog: {
				width: 650,
				height: 450,
			},
		})

		socialshares.mount() // render with new config


	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false,
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg",
		});
	}
	checkLogin() {
		this.router.navigate(['/login']);

	}
	// Ask Question
	askQuestion(question) {
		this.isButtonDisabled = true;
		if (this.authService.isLoggedIn() == false) {

			this.router.navigate(["/login"]);
		} else {
			if (question.trim() == '') {
				this.toastrService.showError('The Question field is required.', 'Error');
				this.isButtonDisabled = false;
				return false;
			}
			var metaId = this.authService.getUserMetaId();
			var formData = new FormData();
			formData.append("metaId", metaId);
			formData.append("gigId", this.gigId);
			formData.append("question", question);

			this.gigService.gigQuestion(formData).subscribe(
				(res) => {
					this.gigDetail(metaId);
					$("#question1").val("");
					this.isButtonDisabled = false;
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
					this.isButtonDisabled = false;
				}
			);
		}
	}

	/**
	 * Report gig
	 *
	 */
	reportGig() {

		if (this.reportForm.invalid) {
			this.validateAllFormFields(this.reportForm);
			return false;
		} else {
			var metaId = localStorage.getItem('metaId');
			const formData = new FormData();
			formData.append("titleId", this.reportForm.value.title);
			formData.append("description", this.reportForm.value.description);
			formData.append("userType", "1");
			formData.append("gigId", this.gigId);
			formData.append("userMetaId", metaId);

			showLoader();
			this.gigService.reportGig(formData).subscribe(
				(res) => {
					if ((status = "success")) {
						$("#reportUser").modal("hide");
						hideLoader();
						this.reportForm.reset();

						this.toastrService.showSuccess(res.message, "Success");
					}
				},
				(err) => {
					hideLoader();
					$("#reportUser").modal("hide");

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	/**
	 * save gig make as favorite
	 */
	saveGigAsFavorite() {

		var metaId = this.authService.getUserMetaId();

		var formData = new FormData();
		formData.append("gigId", this.gigId);
		formData.append("metaId", metaId);

		showLoader();
		this.gigService.saveGig(formData).subscribe((res) => {
			hideLoader();

			this.is_favourite = res.is_favourite;

			this.toastrService.showSuccess(res.message, "Success");
		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		});
	}

	toggle() {
		this.show_dialog = !this.show_dialog;
	}

	/**
	 *
	 * @param event
	 * @returns void
	 */
	offerGig(event) {


		if (this.check != 1) {
			var id = $('input[name="groupChoose"]:checked').val();

			if (id == undefined) {
				this.toastrService.showError('Please select group', "Error");
				return false;
			}
			this.id = id;

		} else {
			var metaId = this.authService.getUserMetaId();
			this.id = metaId;
		}


		// alert(this.id);
		// alert(this.gigId);

		if (!this.offerForm.valid) {
			this.validateAllFormFields(this.offerForm);

			// return false;
		} else {
			var metaId = this.authService.getUserMetaId();
			var formData = new FormData();
			formData.append("metaId", this.id);
			formData.append("gigId", this.gigId);
			formData.append("price", this.offerForm.value.offerPrice);
			formData.append("comment", this.offerForm.value.offerDescription);
			showLoader();
			this.gigService.livlouderOfferGig(formData).subscribe((res) => {
				hideLoader();
				$("#makeAnOffer").modal("hide");
				this.offerForm.reset();

				this.toastrService.showSuccess(res.message, "Success");

				this.gigDetail(metaId);
			}, err => {
				hideLoader();
				$("#makeAnOffer").modal("hide");
				$("#groupModel123").modal("hide");
				this.offerForm.reset();


				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});
		}
	}



	gigDetail(metaId) {
		this.gigService.gigDetail(this.gigId, metaId).subscribe((res) => {
			this.groupId = res.groupId;
			this.assignUserMetaId = res.assignUserMetaId;
			this.gigPrice = res.price;
			this.filterDetail(res);
		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		});
	}

	filterDetail(res) {
		this.offers = [];
		hideLoader();
		this.gigData = res.data;
		this.mustHave = JSON.parse(res.data.mustHave);
		this.myOffer = this.gigData.myOffer;
		this.groupId = this.gigData.groupId;
		this.assignUserMetaId = this.gigData.assignUserMetaId;
		this.gigPrice = this.gigData.price;

		this.gigData.offers.forEach(element => {
			this.offers.push(element);
		});

		if (this.gigData.groupOffers != undefined) {

			this.gigData.groupOffers.forEach(element => {
				this.offers.push(element);


			});
		}

		this.is_favourite = this.gigData.is_favourite;
		this.offers.forEach((data) => {
			data.agoTime = this.diffHours(
				data.posted_time,
				this.gigData.current_time
			);
		});
		// this.subtype = this.gigData.subtype.split(",");
		this.qusAns = this.gigData.qusAns;
		this.qusAns.forEach((data) => {
			data.agoTime = this.diffHours(
				data.posted_time,
				this.gigData.current_time
			);
		});

		this.agoTime = this.diffHours(
			this.gigData.posted_time,
			this.gigData.current_time
		);
		if (this.gigData.subtype != null) {
			this.subtype = this.gigData.subtype.split(",");

		}
		//added code...
		if (this.gigData.group_subtype != null) {

			this.group_subtype = this.gigData.group_subtype.split(",");
		}

		return;
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;

		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs(date2 - date1);
		var diffHr = difference / 60 / 60;
		var daysDiff = difference / 60 / 60 / 24;
		if (daysDiff < 1) {
			if (diffHr < 1) {
				difference = difference / 60;
				var hourDiff = Math.floor(difference) + " Min Ago";
			} else {
				difference = difference / 60 / 60;
				var hourDiff = Math.floor(difference) + " Hour Ago";
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + " Days Ago";
		}

		return hourDiff;
	}

	checkBankAccount(id1) {
		$("#groupModel123").modal("hide");

		// alert(id1);
		this.check = id1
		if (this.check == 2) {
			var id = $('input[name="groupChoose"]:checked').val();
			if (id == undefined) {
				this.toastrService.showError('Please select group', "Error");
				return false;
			}
		}
		//"ba_1J2GezQbwiQe07U7br4qGXk2"
		if (this.payService.getStripeBankId()) {
			$("#makeAnOffer").modal("show");
		} else {
			showLoader();
			var formData = new FormData();
			formData.append("accountId", this.payService.getStripeAccountID());

			//

			//

			this.payService.verifyLinkAccount(formData).subscribe((res) => {
				hideLoader();
				if (res.data.for == 3) {
					var linkUrl = res.data.linkData.url;
					this.stripeUlr = res.data.linkData.url;
					this.popupStatus = 1;
					$("#confirmModal").modal("show");
				}
				if (res.data.for == 2) {
					hideLoader();
					this.popupStatus = 2;
					$("#confirmModal").modal("show");
					// if(confirm("Please add account ")){
					//   $('#addBank').modal('show');
					// }
				}
			}, (err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});
		}
	}

	addBankAccount() {
		if (this.bankAccountForm.invalid) {
			this.validateAllFormFields(this.bankAccountForm);
			return false;
		} else {
			showLoader();
			
			var formData = new FormData();
			formData.append("holderName", this.bankAccountForm.value.accountName);
			// formData.append("routingNumber", "110000");
			formData.append("routingNumber", this.bankAccountForm.value.bsdCode);
			formData.append("accountNo", this.bankAccountForm.value.accountNo);

			this.payService.addBankAccount(formData).subscribe(
				(res) => {
					hideLoader();
					if (res.status == "success") {
						this.toastrService.showSuccess(res.message, "Success");
						this.payService.updateStripeBankId(res.stripeBankId);
						this.resetBankForm();
					} else {
						this.toastrService.showError(res.message, "Error");
					}
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	stripeVerifyLink() {
		$("#confirmModal").modal("hide");
		// 1 for verify stripe account , 2 for add bank detail
		if (this.popupStatus == 1) {
			//https://giglooker.com/dev/livloud/stripe-success
			//https://giglooker.com/livloudNew/stripe-success
			window.location.href = this.stripeUlr;
		} else {
			$("#addBank").modal("show");
		}
	}

	resetBankForm() {
		this.bankAccountForm.reset();
		// this.bankAccountForm.pristine ;
		// this.bankAccountForm.reset(this.bankAccountForm.value); //to remove only validation error
		$("#addBank").modal("hide");
	}

	resetOfferForm() {
		$("#makeAnOffer").modal("hide");
		this.offerForm.reset();
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({
					onlySelf: true
				});
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}


	reviewSubmit() {
		// if (!this.forgotPwdForm.valid) {
		// 	this.validateAllFormFields(this.forgotPwdForm);
		// 	//this.toastr.remove()

		// 	this.toastrService.showError("Please fill required field before procceding", 'Error');
		// 	return false;
		// }
		$("#myApplyId").prop('disabled', true);
		this.rating = this.currentRate;
		//alert(this.assignUserMetaId);
		//return false
		// if(this.is_profile_or_group == 3){
		// 	var id = 
		// }
		//return false;
		// var metaId = this.authService.getUserMetaId();
		var metaId = localStorage.getItem('metaId');
		var formData = new FormData();
		formData.append("ratingBy", this.assignUserMetaId);
		formData.append("ratingFor", this.gigData.postedBymetaId);
		formData.append("gigId", this.gigId);
		formData.append("rating", this.rating);
		formData.append("review", this.forgotPwdForm.value.comments);

		showLoader();
		this.gigService.giveReview(formData).subscribe(
			(res) => {
				$("#reviewModal").modal("hide");
				this.forgotPwdForm.reset();

				hideLoader();
				this.toastrService.showSuccess(res.message, "Success");
				this.gigDetail(metaId)
				// this.router.navigate(["/myGigsHirer"]);
			},
			(err) => {
				hideLoader();
				$("#reviewModal").modal("hide");
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);

	}


	/**
	 * Cancel gig
	 */
	cancelGig() {
		var metaId = this.authService.getUserMetaId();
		var price = this.getOfferPrice();
		// var price = this.gigData.price;
		// console.log('gigId', this.gigData.price); return false;

		var formData = new FormData();
		formData.append("gigId", this.gigId);
		formData.append("userType", "1");
		formData.append("amount", price ? price : this.gigData.price);
		formData.append("hirerMetaId", this.gigData.postedBymetaId);
		formData.append("livlouderMetaId", metaId);

		showLoader();
		this.gigService.cancelGig(formData).subscribe(
			(res) => {
				$("#cancelGigAlert").modal("hide");
				hideLoader();
				this.toastrService.showSuccess(res.message, "Success");
				this.router.navigate(["/gigs"]);
			},
			(err) => {
				$("#cancelGigAlert").modal("hide");
				this.toastrService.showError(err.error.message, "Error");
			}
		);
	}

	getOfferPrice() {
		let price;
		this.gigData.offers.forEach((element) => {
			if (element.isAssign == 1) {
				price = element.price;
			}
		});

		return price;
	}

	startGig() {
		var formData = new FormData();
		formData.append("gigStatus", "3");
		formData.append("gigId", this.gigId);

		showLoader();
		this.gigService.changeGigStatus(formData).subscribe(
			(res) => {
				hideLoader();
				if (res.status == "success") {
					this.toastrService.showSuccess(res.message, "Success");
					// this.groupMember();
					location.reload();
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	endGig() {
		var formData = new FormData();
		formData.append("gigStatus", "4");
		formData.append("gigId", this.gigId);

		this.gigService.changeGigStatus(formData).subscribe(
			(res) => {
				hideLoader();
				if (res.status == "success") {
					this.toastrService.showSuccess(res.message, "Success");
					location.reload();
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	requestPayment() {
		// alert('hello');
		// alert(this.gigId);
		var formData = new FormData();
		formData.append("gigStatus", "5");
		formData.append("gigId", this.gigId);

		this.gigService.changeGigStatus(formData).subscribe(
			(res) => {
				hideLoader();
				if (res.status == "success") {
					this.toastrService.showSuccess(res.message, "Success");
					location.reload();
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {

					this.toastrService.showError(err.error.message, 'Error');

				}
			}
		);
	}


	// My Group List For Invite Member
	groupListForAdmin() {
		$("#groupModel").modal("hide");
		// $("#groupModel123").modal("hide");


		const currentUser = JSON.parse(localStorage.getItem("currentUser"));

		var obj: any = {};
		obj.userId = currentUser.userId;
		obj.gigId = this.gigId;
		showLoader();
		this.gigService.groupListAdmin(obj).subscribe(
			res => {
				hideLoader();
				var data = res;
				if (data.message == 'Found') {

					this.groupList = data.data;
					this.dataFound = true;
				} else {

					this.groupList = [];
					this.dataFound = false;
				}
			},
			err => {
				hideLoader();
				// if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				// 	this.authService.checkValidToken(err.error);
				// 	this.toastrService.showError('Session Expired', 'Error');

				// } else {
				// 	this.toastrService.showError(err.error.message, 'Error');
				// }


			}
		);
	}


	// My Group List For Invite Member
	groupMember() {

		const currentUser = JSON.parse(localStorage.getItem("currentUser"));

		var obj: any = {};
		obj.groupId = this.groupId;
		obj.userMetaId = this.assignUserMetaId;
		showLoader();
		this.gigService.groupMember(obj).subscribe(
			res => {
				hideLoader();

				this.groupMemberList = res.data.accepted;




			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}


			}
		);
	}

	onKeyPress(event: KeyboardEvent) {
		// if (event.key === 'e') {
		// 	event.preventDefault();
		// }
		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
		event.preventDefault();
		return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}

	test1() {

		$("#groupMemberPayment").modal("hide");
		$("#paymentAlert").modal("show");
	}
	requestPaymentPopUp() {

		$("#groupMemberPayment").modal("show");
		this.groupMember();
	}

	adminPrice() {

		var array = [];
		var sum = 0;
		this.groupMemberList.forEach((element, index) => {

			if (element.isAdmin == 1) {

				sum += parseInt(this.asddddddd[index]);
				var obj = {
					metaId: element.userMetaId,
					amount: this.gigPrice,
					accountId: element.stripeAccountId

				}
				array.push(obj)
			}

		});
		this.group_member = array;

		var formData = new FormData();
		formData.append("gigStatus", "5");
		formData.append("gigId", this.gigId);
		formData.append("group_member", JSON.stringify(this.group_member));
		formData.append("groupId", this.groupId);


		showLoader();
		this.gigService.changeGigStatus(formData).subscribe(
			(res) => {
				hideLoader();
				if (res.status == "success") {
					this.toastrService.showSuccess(res.message, "Success");
					location.reload();
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
		// }
	}




	//  member price
	// test() {
	// 	var array = [];
	// 	var sum = 0;
	// 	var isLess = 0;
	// 	this.groupMemberList.forEach((element, index) => {
	// 		if (element.isAdmin != 1) {
	// 			// console.log(element)
	// 			// console.log('sachin',this.asddddddd[index]);
	// 			console.log(this.asddddddd[index])
	// 			if(this.asddddddd[index] == undefined){
	// 				this.amount = '0';
	// 			}
	// 			if(this.asddddddd[index] < 1){
	// 				isLess = 1;

	// 			}

	// 			else{
	// 				this.amount = this.asddddddd[index]
	// 			}

	// 			sum += parseInt(this.asddddddd[index]);

	// 			var obj = {
	// 				metaId: element.userMetaId,
	// 				amount: this.amount,
	// 				accountId: element.stripeAccountId

	// 			}
	// 			array.push(obj)
	// 		}

	// 	});
	// 	this.group_member = array;
	// 	console.log('groupModule',this.group_member);

	// 	if(isLess==1){
	// 		this.toastrService.showError("You can't enter amount less than $1  for any member", 'Error');
	// 		isLess = 0;
	// 		return false
	// 	}
	// 	if (sum > this.gigPrice) {
	// 		this.toastrService.showError('Distribute amount is greter than gig amount', 'Error');
	// 		return false;

	// 	} 
	// 	else {
	// 		//return false;

	// 		console.log(this.asddddddd.length);
	// 		if (this.asddddddd.length == 0) {
	// 			this.adminPrice();
	// 		} else {
	// 			console.log('this', JSON.stringify(this.group_member));

	// 			// return false;
	// 			var formData = new FormData();
	// 			formData.append("gigStatus", "5");
	// 			formData.append("gigId", this.gigId);
	// 			formData.append("groupId", this.groupId);
	// 			formData.append("group_member", JSON.stringify(this.group_member));

	// 			showLoader();
	// 			this.gigService.changeGigStatus(formData).subscribe(
	// 				(res) => {
	// 					hideLoader();
	// 					if (res.status == "success") {
	// 						this.toastrService.showSuccess(res.message, "Success");
	// 						location.reload();
	// 					}
	// 				},
	// 				(err) => {
	// 					hideLoader();
	// 					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
	// 						this.authService.checkValidToken(err.error);
	// 						this.toastrService.showError('Session Expired', 'Error');

	// 					} else {
	// 						this.toastrService.showError(err.error.message, 'Error');
	// 					}
	// 				}
	// 			);
	// 		}
	// 	}
	// }

	// add(accumulator, a) {

	// 	return accumulator + parseInt(a);
	// }

	add(accumulator, a) {
		// Parse the current element as an integer
		const parsedValue = parseInt(a);
	
		// Check if parsedValue is a valid number
		if (!isNaN(parsedValue)) {
			// Add the parsedValue to the accumulator
			return accumulator + parsedValue;
		} else {
			// If parsedValue is NaN, return the accumulator unchanged
			return accumulator;
		}
	}

	// member price
	test() {
		
		var array = [];
		var sum = 0;
		var isLess = 0;

		this.rPrice = this.asddddddd.reduce(this.add, 0); // with initial value to avoid when the array is empty
		this.admin_price = this.gigPrice - this.rPrice;
		
		if(this.gigPrice == this.admin_price){

			this.toastrService.showError('Please enter amount', 'Error');
			return false;
		}

		// if(this.admin_price <= 0){

		// 	this.toastrService.showError('Entered price should be not greater than gig price', 'Error');
		// 	return false;
		// }

		this.groupMemberList.forEach((element, index) => {
			
			if (this.asddddddd[index] == undefined) {
				this.amount = 0;
			}else {
				this.amount = this.asddddddd[index]
			}

			if (this.asddddddd[index] == '' || (this.asddddddd[index] == undefined && index != 0)  ) {

				isLess = 1;
			}
		
			if(this.asddddddd[index] != undefined && this.asddddddd[index] != ''){

				sum += parseInt(this.asddddddd[index]);
			}	

			if (element.isAdmin == 1) {
				this.amount = this.admin_price.toString();
			}

			if (this.amount == undefined) {
				this.amount = '0';
			}

			var obj = {
				metaId: element.userMetaId,
				amount: this.amount,
				accountId: element.stripeAccountId

			}
			array.push(obj)
			//}

		});
		this.group_member = array;

		if (isLess == 1) {
			this.toastrService.showError("Please enter amount for all members", 'Error');
			isLess = 0;
			return false
		}
		
		if (sum > this.gigPrice) {
			this.toastrService.showError('Entered price should be not greater than gig price', 'Error');
			return false;
		}
		// else if (this.admin_price == 0) {
		// 	this.toastrService.showError('Remaining amount cannot be less than $1.5 for admin', 'Error');
		// 	return false;
		// }
		else {
			
			if (this.asddddddd.length == 0) {
				this.adminPrice();
			} else {

				var formData = new FormData();
				formData.append("gigStatus", "5");
				formData.append("gigId", this.gigId);
				formData.append("groupId", this.groupId);
				formData.append("group_member", JSON.stringify(this.group_member));

				showLoader();
				this.gigService.changeGigStatus(formData).subscribe(
					(res) => {
						hideLoader();
						if (res.status == "success") {
							this.toastrService.showSuccess(res.message, "Success");
							location.reload();
						}
					},
					(err) => {
						hideLoader();
						if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
							this.authService.checkValidToken(err.error);
							this.toastrService.showError('Session Expired', 'Error');

						} else {
							this.toastrService.showError(err.error.message, 'Error');
						}
					}
				);
			}
		}
	}


}