// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-price-slider-demo',
//   templateUrl: './price-slider-demo.component.html',
//   styleUrls: ['./price-slider-demo.component.css']
// })
// export class PriceSliderDemoComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

interface SliderDetails {
  value: number;
  highValue: number;
  floor: number;
  ceil: number;
}

@Component({
  selector: 'app-price-slider-demo',
  templateUrl: './price-slider-demo.component.html',
  styleUrls: ['./price-slider-demo.component.css']
})
export class PriceSliderDemoComponent {
  sliders: SliderDetails[] = [
    {
      value: -1,
      highValue: 2,
      floor: -5,
      ceil: 5
    },
    
  ];
  highValue: number;

  sliderOptions(slider: SliderDetails): Options {
    return {
      floor: 0,
      ceil: this.highValue
    };
  }
}