import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BookMeRequest } from 'src/app/services/book-me-request';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { SignupService } from 'src/app/services/signup.service';
import { HttpParams } from '@angular/common/http';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
    selector: 'app-book-me-request',
    templateUrl: './book-me-request.component.html',
    styleUrls: ['./book-me-request.component.css']
})
export class BookMeRequestComponent implements OnInit {

    limit: any = 1;
    offset: any = 0;
    total: any;
    dataFound = false;
    bookMeList;
    metaId;
    timeout: any;

    constructor(

        private bookMeRequest: BookMeRequest,
        private toastrService: ToastrNotificationService,
        private titleService: Title,
        private authService: AuthService,
        private signupService: SignupService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {

        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
        this.metaId = localStorage.getItem('metaId');
        this.getList();
    }

    getList() {

        var obj: any = {}
        obj.metaId = this.metaId;

        showLoader();
        this.bookMeRequest.getBookMeRequest(obj).subscribe(data => {
            hideLoader();
            if (data.message == 'Record found') {

                this.bookMeList = data.data;
                console.log('aassaa', this.bookMeList);

                this.total = data.length;
                this.dataFound = true;
            } else {

                this.bookMeList = [];
                this.dataFound = false;
                this.total = 0;
            }
        }, err => {
            hideLoader();
            if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
                this.authService.checkValidToken(err.error);
                this.toastrService.showError('Session Expired', 'Error');

            } else {
                this.toastrService.showError(err.error.message, 'Error');
            }
        });
    }

    acceptrejectReq(status, gigId, metaId) {

        var formData = new FormData();
        formData.append('metaId', metaId)
        formData.append('gigId', gigId)
        formData.append('status', status)

        showLoader();
        this.bookMeRequest.acceptRejectRequest(formData).subscribe(res => {
            hideLoader();
            this.toastrService.showSuccess(res.message, 'Success');
            this.timeout = setTimeout(function () {

                location.reload();
            }, 1500);
        }, err => {

            hideLoader();
            if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
                this.authService.checkValidToken(err.error);
                this.toastrService.showError('Session Expired', 'Error');

            } else {
                this.toastrService.showError(err.error.message, 'Error');
            }
        })
    }

}