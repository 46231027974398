import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { MessagingService } from './services/messaging.service';


declare var $:any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'livloud';
  message;
  constructor(
    private router: Router,private messageService : MessagingService) { }
    // private router: Router { }

  ngOnInit() {

    this.messageService.receiveMessage();
    this.message = this.messageService.currentMessage;
    this.messageService.requestPermission();
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });

    $('[data-toggle="tooltip"]').tooltip({ trigger:'hover' });
}
}
