import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
 

  serverUrl = environment.baseUrl;
  siteUrl = environment.siteUrl;

  constructor (  private http: HttpClient,

    ) { }

    getNotificationList(params){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}alert/alertList`, { headers: httpHeaders ,params:params })
    }

    getNotificationListCount(){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.get<any>(`${this.serverUrl}alert/badgeCount`, { headers: httpHeaders })
    }

    readNotification(params){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      return this.http.patch<any>(`${this.serverUrl}alert/read/${params.alert_id}`, { headers: httpHeaders})
    }

    getNotificationListRedirectionUrl(payload: any) {

      // console.log('payload',payload);return false;


      var type = payload.type;
      var id = payload.reference_id;
      const receiverMetaId = payload.recipient_user_meta_id;
      const groupId = payload.sender_user_meta_id;
      //FOR DISPUTE CHAT IF PAYLOAD COMES DIFFERENT...
      if(type == undefined){

        if(payload.disputeChat != undefined){

          id = payload.gig_id;
          type = payload.disputeChat;
        }

      }
      switch (type) {
        // send  invitaion 
        case 'send_invitation':
  
          return  '/groupRequest/';
          break;

        // payment release request 
        case 'payment_release_request':
  
          return '/myGigsHirer/detail/' + id;
          break;

        // payment request by livlouder 
        case 'payment_request_by_livlouder':
  
          return  '/mygig/details/' + id;
          break;

        // make offer by group 
        case 'make_offer_by_group':
  
          return  '/mygig/details/' + id;
          break;
          
        // change gig status
        case 'changed_status_by_livlouder':
  
          return '/myGigsHirer/detail/' + id;
          break;
        // review 
        case 'review_by_livlouder':
          // set url for hirer 
          let url = '/profileHirer';
          // if user type is livlouder than se livlouder profile
          if (payload.receiver_user_type == 1) {
            url = '/ProfileLivlouder';
          }
          return url
          break;
  
        case 'group_req_accept':
  
          return '/discover/details/' + groupId;
          break;
  
        case 'group_req_reject':
  
          return '/groups';
          break;
  
        case 'make_offer':
  
          return '/myGigsHirer/detail/' + id;

          // set url for hirer 
          let urloffer = '/myGigsHirer/detail/' + id;;
          // if user type is livlouder than se livlouder profile
          if(payload.receiver_user_type == 1){
            urloffer = '/gigs/details/' + id;
          }
          return urloffer;
          break;

        case 'make_offer_by_group':

          return '/gigs/details/' + id;
          break;
  
        case 'accept_offer':
  
          return  '/mygig/details/' + id;
          break;
  
        case 'payment_released_by_hirer':

          return '/mygig/details/' + id;
          break;

        case 'review_by_hirer':

          return '/ProfileLivlouder';
          break;

        case 'tip_by_hirer':
  
          return '/mygig/details/' + id;
          break;
  
        case 'book_me_request':
  
          // return '/gigs/details/' + id;
          return '/bookRequest';
          break;

        case 'reject_book_me_request':
      
          // return '/ProfileLivlouder';
          // set url for hirer 
         let rurl = '/profileHirer';
         // if user type is livlouder than se livlouder profile
         if (payload.receiver_user_type == 1) {
           rurl = '/ProfileLivlouder';
         }
         return rurl
          break;
    
  
        case 'accept_book_me_request':
  
          return '/myGigsHirer/detail/' + id;
          break;

//added code...
        case  'payment_requested_by_livlouder':
          return '/myGigsHirer/detail/' + id;
          break;

          case  'payment_released_by_livlouder':
          return '/groups/details/' + groupId;
          break;

        case 'give_answer':

        return '/mygig/details/' + id;
          break;

        case 'ask_question':

          return '/myGigsHirer/detail/' + id;
          break;
  
          
          // set url for hirer 
          let urlNew = '/myGigsHirer/detail/' + id;
          // if user type is livlouder than se livlouder profile
          if(payload.receiver_user_type == 1){
            urlNew = '/gigs/details/' + id;
          }
          return urlNew;
          break;

        case 'send_report_by_livlouder':
  
          return '/discoverHirer/detail/' + groupId;
          break;

        case 'send_report_by_hirer':
          return '/posted-by-profile/' + groupId;
          break;
  
        case 'send_report':
  
          return '/myGigsHirer/detail/' + id;
          break;

          case 'dispute':
          let disputeUrl = '/myGigsHirer/detail/' + id;
          if(payload.receiver_user_type == 1){
            disputeUrl = '/gigs/details/' + id;
          }
          return disputeUrl;
          break;

          case 'disputeChat':
          let disputeChat = '/myGigsHirer/detail/' + id;
          if(payload.receiver_user_type == 1){
            disputeChat = '/gigs/details/' + id;
          }
          return disputeChat;
          break;
  
        default:
          return '/';
          break;
      }
  
  
    }

    getTimeAgo(t1, t2) {
      const date1 = new Date(t1);
      const date2 = new Date(t2);
      const seconds = Math.floor((date1.getTime() - date2.getTime()) / 1000);
  
      let interval = seconds / 31536000;
  
      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    }
  
}
