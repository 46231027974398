<app-header-livlouder></app-header-livlouder>
<style>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container" *ngIf="gigData">
            <div class="gigsList">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <div>
                                        <h2>{{gigData.title}}</h2>
                                    </div>
                                    <div class="d-flex">

                                        <!-- <div class="assgigAct" *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0"> -->
                                        <!-- <div *ngIf="gigData.assignUserMetaId == metaId && gigData.userType != 3"> -->



                                        <div style="margin-right: 5px;"
                                            *ngIf="gigData.gigStatus ==2 && gigData.assignUserMetaId == metaId && gigData.userType != 3 && gigData.isGigCancel == 0 ||gigData.gigStatus ==3 && gigData.assignUserMetaId == metaId && gigData.userType != 3 && gigData.isGigCancel == 0">
                                            <a
                                                [routerLink]="['/chatLivlouder',gigData.postedBymetaId,gigData.assignUserMetaId]">
                                                <button type="button" class="btn thm-btn-sm btnCircle"><span
                                                        class="fa fa-comment-alt"></span></button>
                                            </a>
                                        </div>
                                        <div
                                            *ngIf="gigData.gigStatus ==2  && gigData.isAdmin == 1 && gigData.userType == 3 && gigData.isGigCancel == 0 || gigData.gigStatus ==3  && gigData.isAdmin == 1 && gigData.userType == 3 && gigData.isGigCancel == 0">
                                            <a
                                                [routerLink]="['/chatLivlouder',gigData.postedBymetaId,gigData.assignUserMetaId]">
                                                <button type="button" class="btn thm-btn-sm btnCircle"><span
                                                        class="fa fa-comment-alt"></span></button>
                                            </a>
                                        </div>
                                        <button *ngIf="gigData.isRemote !=1 "
                                            [routerLink]="['/mapLiveRoute',gigData.gigId]" title="View on Map"
                                            data-toggle="tooltip" class="btn iconBtn mr-2" type="button">
                                            <span class="fa fa-map"></span>
                                        </button>
                                        <div class="dropdown smallDropdown ml-2"
                                            *ngIf="gigData.gigStatus == 1 && isLogin == true">
                                            <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                    data-toggle="tooltip" type="button">
                                                    <span class="fa fa-ellipsis-v"></span>
                                                </button>
                                            </span>

                                            <div class="dropdown-menu dropdown-right dropIcon"
                                                aria-labelledby="dropdownMenuButton">
                                                <!-- <a class="dropdown-item" href="javascript:void(0)">Share <i class="fa fa-share-alt"></i></a> -->
                                                <a data-toggle="modal" data-target="#Shareone" class="dropdown-item"
                                                    href="javascript:void(0);">Share <i class="fa fa-share-alt"></i></a>
                                                <a class="dropdown-item" (click)="saveGigAsFavorite();"
                                                    href="javascript:void(0)">Save <i
                                                        [ngClass]="is_favourite == '1' ? 'fas fa-heart' : 'far fa-heart'"></i></a>
                                                <a *ngIf="isLogin == true" data-toggle="modal" data-target="#reportUser"
                                                    class="dropdown-item" href="javascript:void(0);">Report <i
                                                        class="fa fa-flag"></i></a>
                                                <a routerLink="/login" *ngIf="isLogin == false" class="dropdown-item"
                                                    href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>

                                            </div>
                                        </div>
                                        <!-- {{gigData.gigSatus}} -->

                                        <!-- Assigned Gig -->
                                        <!-- <div class="dropdown smallDropdown" *ngIf="gigData.gigStatus == 2 || gigData.gigStatus == 3 && gigData.isAdmin"> -->
                                        <div class="dropdown smallDropdown"
                                            *ngIf="gigData.gigStatus==2 && gigData.assignUserMetaId == metaId && gigData.userType != 3 && gigData.isGigCancel == 0">
                                            <!-- *ngIf="gigData.gigStatus==2 && gigData.assignUserMetaId == metaId && gigData.userType != 3 && gigData.isGigCancel == 0 || gigData.gigStatus==3 && gigData.assignUserMetaId == metaId && gigData.userType != 3 && gigData.isGigCancel == 0" -->
                                            <!-- <div class="dropdown smallDropdown" *ngIf="gigData.gigStatus == 2 && metaId == gigData.assignUserMetaId"> -->
                                            <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                    data-toggle="tooltip" type="button">
                                                    <span class="fa fa-ellipsis-v"></span>
                                                </button>
                                            </span>
                                            <div class="dropdown-menu dropdown-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a *ngIf="gigData.isGigCancel == 0 " data-toggle="modal"
                                                    data-target="#Shareone" class="dropdown-item"
                                                    href="javascript:void(0);">Share <i class="fa fa-share-alt"></i></a>

                                                <!-- <a *ngIf="gigData.isGigCancel == 0 " class="dropdown-item" href="javascript:void(0)">Share <i class="fa fa-share-alt"></i></a> -->
                                                <a *ngIf="gigData.isGigCancel == 0 " class="dropdown-item"
                                                    (click)="saveGigAsFavorite();" href="javascript:void(0)">Save <i
                                                        [ngClass]="is_favourite == '1' ? 'fas fa-heart' : 'far fa-heart'"></i></a>
                                                <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                                    href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>

                                                <a *ngIf="gigData.isGigCancel == 0 " data-toggle="modal"
                                                    data-target="#cancelGig" class="dropdown-item"
                                                    href="javascript:void(0);">Cancel Gig</a>
                                            </div>
                                        </div>


                                        <!--  profile -->



                                        <div class="dropdown smallDropdown"
                                            *ngIf="gigData.gigStatus!=1 && gigData.isAdmin == 1 && gigData.userType == 3 && gigData.isGigCancel == 0">

                                            <!-- <div class="dropdown smallDropdown" *ngIf="gigData.gigStatus == 2 && metaId == gigData.assignUserMetaId"> -->
                                            <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                    data-toggle="tooltip" type="button">
                                                    <span class="fa fa-ellipsis-v"></span>
                                                </button>
                                            </span>
                                            <div class="dropdown-menu dropdown-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a *ngIf="gigData.isGigCancel == 0 " data-toggle="modal"
                                                    data-target="#Shareone" class="dropdown-item"
                                                    href="javascript:void(0);">Share <i class="fa fa-share-alt"></i></a>

                                                <!-- <a *ngIf="gigData.isGigCancel == 0 " class="dropdown-item" href="javascript:void(0)">Share <i class="fa fa-share-alt"></i></a> -->
                                                <a *ngIf="gigData.isGigCancel == 0 " class="dropdown-item"
                                                    (click)="saveGigAsFavorite();" href="javascript:void(0)">Save <i
                                                        [ngClass]="is_favourite == '1' ? 'fas fa-heart' : 'far fa-heart'"></i></a>
                                                <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                                    href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>

                                                <a *ngIf="gigData.isGigCancel == 0 && gigData.gigStatus == 2" data-toggle="modal"
                                                    data-target="#cancelGig" class="dropdown-item"
                                                    href="javascript:void(0);">Cancel Gig</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ng-template [ngIf]="gigData.type != null">
                                    <div class="jobOpen">

                                        <label>{{gigData.type}}</label>
                                        <span *ngIf="gigData.typeId != null">(solo)</span>
                                    </div>
                                </ng-template>

                                <ng-template [ngIf]="gigData.group_type != null">
                                    <div class="jobOpen">

                                        <label>{{gigData.group_type}}</label>
                                        <span *ngIf="gigData.typeId == null">(group)</span>
                                    </div>
                                </ng-template>

                                <div *ngIf="isLogin == true" class="priceBlk">
                                    <p>{{gigData.currency}}{{gigData.price}}</p>
                                </div>
                                <div *ngIf="isLogin == false" class="priceBlk">
                                    <p>{{gigData.currency}}{{Openprice}}</p>
                                </div>
                                <p *ngIf="gigData.isRemote == 0 && gigData.gigStatus == 1 && isLogin == true|| gigData.isRemote == null && gigData.gigStatus==1 && isLogin == true"
                                    class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                    <span>{{gigData.suburb}}</span>
                                </p>

                                <p *ngIf="gigData.isRemote == 0 && gigData.gigStatus == 2 && isLogin == true || gigData.isRemote == null && gigData.gigStatus==2 && isLogin == ture"
                                    class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                    <span>{{gigData.location}}</span>
                                </p>

                                <p *ngIf="gigData.isRemote == 1 && isLogin == true" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>Remote</span></p>


                                <p *ngIf="gigData.isRemote == 0 && gigData.gigStatus == 1 && isLogin == false|| gigData.isRemote == null && gigData.gigStatus==1 && isLogin == false"
                                    class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                    <span>{{gigData.suburb}}</span>
                                </p>

                                <p *ngIf="gigData.isRemote == 0 && gigData.gigStatus == 2 && isLogin == false || gigData.isRemote == null && gigData.gigStatus==2 && isLogin == ture"
                                    class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                    <span>{{gigData.location}}</span>
                                </p>

                                <p *ngIf="gigData.isRemote == 1 && isLogin == false" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>Remote</span></p>

                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">Sub Type</h2>

                                    <div class="typeBlk" *ngIf="subtype != '' || subtype != null">
                                        <ng-container *ngFor="let subType of subtype">
                                            <div class="typeItem">{{subType}}</div>
                                        </ng-container>

                                    </div>

                                    <div class="typeBlk" *ngIf="group_subtype != '' || group_subtype != null">
                                        <ng-container *ngFor="let subType of group_subtype">
                                            <div class="typeItem">{{subType}}</div>
                                        </ng-container>

                                    </div>

                                </div>

                                <!-- <div *ngIf="equalDate == false" class="dateFlex"> -->
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>{{gigData.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{gigData.endDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{gigData.time}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="equalDate == true" class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Date</p>
                                            <h4>{{gigData.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <!-- <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{gigData.endDateTime}}</h4>
                                        </div>
                                    </div> -->
                                    <!-- <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{gigData.time}}</h4>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="postedByBlk" *ngIf="isLogin == true">
                                    <div class="postedByImg">
                                        <a [routerLink]="['/posted-by-profile/',gigData.postedBymetaId] "><img
                                                src="{{gigData.postedByImage}}"></a>
                                    </div>
                                    <div class="postedByCnt">
                                        <div class="postedBy">
                                            <p>Posted By</p>
                                        </div>
                                        <a [routerLink]="['/posted-by-profile/',gigData.postedBymetaId]">
                                            <h2>{{gigData.postedByName}}</h2>
                                        </a>
                                    </div>
                                    <div class="postedTime ml-auto">
                                        <p>{{agoTime}}</p>
                                    </div>
                                </div>

                                <div class="postedByBlk" *ngIf="isLogin == false">
                                    <div class="postedByImg">
                                        <a><img src="{{gigData.postedByImage}}"></a>
                                    </div>
                                    <div class="postedByCnt">
                                        <div class="postedBy">
                                            <p>Posted By</p>
                                        </div>
                                        <a>
                                            <h2>{{gigData.postedByName}}</h2>
                                        </a>
                                    </div>
                                    <div class="postedTime ml-auto">
                                        <p>{{agoTime}}</p>
                                    </div>
                                </div>

                                <div class="allButtons">
                                    <div
                                        *ngIf="gigData.gigStatus == 6 && gigData.gigAssignReview == 0 && isLogin == true && gigData.isAdmin == 1 && gigData.is_profile_or_group == 3 && gigData.assigned_user_meta_id == currentUserMetaId">
                                        <button type="button" data-toggle="modal" data-target="#reviewModal"
                                            class="btn thm-btn-sm">Give Review</button>
                                    </div>

                                    <div
                                        *ngIf="gigData.gigStatus == 6 && gigData.gigAssignReview == 0 && isLogin == true && gigData.is_profile_or_group == 1">
                                        <button type="button" data-toggle="modal" data-target="#reviewModal"
                                            class="btn thm-btn-sm">Give Review</button>
                                    </div>
                                    <!-- <div class="assgigAct" *ngIf="(myOffer | json) == '{}' && gigData.gigStatus == 1">
                                    <button data-toggle="modal" (click)="checkBankAccount();" data-target="#" type="button" class="btn thm-btn-sm">Make an Offer</button>
                                </div> -->

                                    <div class="assgigAct" *ngIf="gigData.gigStatus == 1 && isLogin == true">
                                        <button data-toggle="modal" (click)="groupListForAdmin();"
                                            data-target="#groupModel" type="button" class="btn thm-btn-sm">Make an
                                            Offer</button>
                                    </div>
                                    <!-- <div class="assgigAct" >
                                    <button data-toggle="modal" (click)="groupListForAdmin()"   data-target="#groupModel" class="btn thm-btn btn-block">Invite to Group</button>
                                </div> -->

                                    <!--  as profile  -->
                                    <div class="assgigAct" *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0">
                                        <div *ngIf="gigData.assignUserMetaId == metaId && gigData.userType != 3">
                                            <button type="button" (click)="startGig();" class="btn thm-btn-sm">Start
                                                Gig</button>
                                            <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                            <!-- <button data-toggle="modal" data-target="#cancelGig" type="button" class="btn thm-btn-sm btnRed">Cancel Gig</button> -->
                                        </div>
                                    </div>


                                    <!-- 
                                        group 
                                     -->



                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0 && gigData.isAdmin == 1 && gigData.userType == 3">
                                        <!-- <div *ngIf="gigData.assignUserMetaId == metaId"> -->
                                        <button type="button" (click)="startGig();" class="btn thm-btn-sm">Start
                                            Gig</button>
                                        <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                        <!-- <button data-toggle="modal" data-target="#cancelGig" type="button" class="btn thm-btn-sm btnRed">Cancel Gig</button> -->
                                        <!-- </div> -->
                                    </div>

                                    <!--  end gig for profile -->





                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 3 && gigData.isGigCancel == 0 && gigData.userType != 3">
                                        <div *ngIf="gigData?.assignUserMetaId == metaId">
                                            <button type="button" (click)="endGig()" class="btn thm-btn-sm">End
                                                Gig</button>
                                            <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                        </div>
                                    </div>









                                    <!--  eng gig  for group   -->

                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 3 && gigData.isGigCancel == 0 && gigData.isAdmin == 1">
                                        <!-- <div *ngIf="gigData?.assignUserMetaId == metaId"> -->
                                        <button type="button" (click)="endGig()" class="btn thm-btn-sm">End Gig</button>
                                        <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                        <!-- </div> -->
                                    </div>



                                    <!-- as a profile  -->
                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 4 && gigData.isGigCancel == 0 && gigData.userType !=3 && isLogin == true">

                                        <button data-toggle="modal" (click)="requestPayment()" type="button"
                                            class="btn thm-btn-sm">Request Payment</button>
                                        <!-- <button *ngIf="gigData?.assignUserMetaId == metaId" data-toggle="modal" (click)="requestPayment()" type="button" class="btn thm-btn-sm">Request Payment</button> -->

                                    </div>



                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 4 && gigData.isGigCancel == 0 && gigData.userType ==3 && groupMemberList.length > 1">

                                        <button *ngIf="gigData.isAdmin==1" data-toggle="modal"
                                            (click)="requestPaymentPopUp()" type="button" class="btn thm-btn-sm">Request
                                            Payment</button>
                                        <!-- <button *ngIf="gigData?.assignUserMetaId == metaId" data-toggle="modal" (click)="requestPayment()" type="button" class="btn thm-btn-sm">Request Payment</button> -->

                                    </div>





                                    <div class="assgigAct"
                                        *ngIf="gigData.gigStatus == 4 && gigData.isGigCancel == 0 && gigData.userType ==3 && groupMemberList.length == 1 && isLogin == true">

                                        <button *ngIf="gigData.isAdmin==1" data-toggle="modal" (click)="adminPrice()"
                                            type="button" class="btn thm-btn-sm">Request Payment</button>
                                        <!-- <button *ngIf="gigData?.assignUserMetaId == metaId" data-toggle="modal" (click)="requestPayment()" type="button" class="btn thm-btn-sm">Request Payment</button> -->

                                    </div>





                                    <div class="assgigAct" *ngIf="gigData.isGigCancel == 1">
                                        <button data-toggle="modal" disabled type="button"
                                            class="btn thm-btn-sm btnRed">Canceled</button>
                                    </div>
                                    <div class="assgigAct ml-2"
                                        *ngIf="gigData.gigStatus == '3' || gigData.is_dispute ==1 && isLogin == true ">

                                        <button [routerLink]="['/dispute-chat',gigData.gigId]" title="Dispute "
                                            data-toggle="tooltip" class="btn thm-btn-sm"
                                            type="button">{{disputeBtn}}</button>
                                    </div>

                                </div>
                                <p *ngIf="disputeClosed==1"><b>{{disputeMsg}}</b></p>
                            </div>
                        </div>

                        <div class="widgetBox mt-30">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Gig</h2>
                                    <p class="aboutText">{{gigData.about}}</p>
                                </div>

                                <!--  <div *ngIf="gigData.gigStatus == 5 &&  gigData.assignUserMetaId == metaId && gigData.isGigCancel == 0  || gigData.userType == 3 &&  gigData.gigSatus == 5 && gigData.isGigCancel == 0" class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <ul class="aboutText">
                                        <li id="statusPending"> Payment Request Pending </li>

                                    </ul>

                                </div> -->

                                <div *ngIf="gigData.gigStatus == 5 && gigData.assignUserMetaId == metaId && gigData.isGigCancel == 0 || gigData.userType == 3 && gigData.gigStatus == 5 && gigData.isGigCancel == 0"
                                    class="userInfoItem">
                                    <h2 class="infoHead">Status</h2>
                                    <ul class="aboutText">
                                        <li id="statusPending"> Payment Request Pending </li>

                                    </ul>

                                </div>



                                <div class="userInfoItem">
                                    <h2 class="infoHead">Must Have</h2>
                                    <ul class="infoList" *ngFor="let must_have of mustHave">
                                        <li>{{must_have}}</li>

                                    </ul>
                                </div>

                                <div class="userInfoItem">


                                    <h2 class="infoHead">Photos</h2>
                                    <p *ngIf="gigData.gig_images.length == 0">Not Available</p>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor=" let images of gigData.gig_images">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{images.image_url}}">
                                                    <img src="{{images.image_url}}">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <ul class="nav nav-tabs csTabs sideCsTab" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Offers
                                        ({{offers.length}})</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="csTab2-tab" data-toggle="tab" href="#csTab2" role="tab"
                                        aria-controls="csTab2" aria-selected="false">Questions ({{qusAns.length}})</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="csTab1" role="tabpanel"
                                    aria-labelledby="csTab1-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <h2 class="infoHead">Offers <span class="NoCount">(150)</span></h2> -->
                                        <div class="gigNodata" *ngIf="offers.length == 0">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/no_offer.svg"></svg-icon>
                                                <h2>No Offers Yet</h2>
                                                <!--                                                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">
                                            <ng-container *ngFor="let livOffers of offers">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{livOffers.profileImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{livOffers.fullName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <!-- <p>{{livOffers.agoTime}}</p> -->
                                                            </div>
                                                        </div>
                                                        <!--                                                     <div class="ratingView ml-auto">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div> -->
                                                        <container_element [ngSwitch]="livOffers.rating">
                                                            <inner_element *ngSwitchCase="0">
                                                                <div class="ratingView ml-auto">
                                                                    <div class="ratingBlk">
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                        <i class="fas fa-star"></i>
                                                                    </div>
                                                                </div>
                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="1">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star "></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>
                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="2">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>

                                                            <inner_element *ngSwitchCase="3">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>
                                                            <inner_element *ngSwitchCase="4">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star"></i>
                                                                </div>

                                                            </inner_element>
                                                            <inner_element *ngSwitchCase="5">
                                                                <div class="ratingBlk">
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                    <i class="fas fa-star filled"></i>
                                                                </div>

                                                            </inner_element>
                                                        </container_element>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="offerActBlk">
                                                            <div class="offerPrice">
                                                                <label>Offer Price</label>
                                                                <h3>${{livOffers.price}}</h3>
                                                            </div>
                                                            <div class=""
                                                                *ngIf="gigData?.assignUserMetaId == livOffers?.userMetaId">
                                                                <div class="jobOpen">
                                                                    <label>Awarded</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="offferText" *ngIf="livOffers.comment != 'null'">
                                                            <p [class.show]="show">{{livOffers.comment}}</p>
                                                            <button *ngIf="livOffers.comment.length > 122"
                                                                (click)="show = !show">{{ show ? 'Show less': 'Show
                                                                More' }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>



                                            <!-- <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/6.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$450</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                                        <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="csTab2" role="tabpanel" aria-labelledby="csTab2-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <div class="gigNodata">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/conversation.svg"></svg-icon>
                                                <h2>No Questions Asked Yet</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </div> -->
                                        <div class="">
                                            <div class="replyBox questionBoxView">
                                                <label>Ask your question</label>
                                                <div class="replyInBox">
                                                    <input type="text" id="question1" class="form-control" #question
                                                        placeholder="Enter Question">
                                                    <button type="button" (click)="askQuestion(question.value)" [disabled]="isButtonDisabled"
                                                        class="btn iconBtn"><i class="fa fa-paper-plane"></i></button>
                                                </div>
                                                <!-- <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">
                                            <ng-container *ngFor="let qustAns of qusAns">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{qustAns.postedByImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{qustAns.postedByName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <!-- <p>{{qustAns.agoTime}}</p> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="questionBox">
                                                            <h2><span>Q)</span>{{qustAns.qus}}</h2>
                                                            <p *ngIf="qustAns.ans"><span>Ans.</span>{{qustAns.ans}}</p>
                                                            <p *ngIf="!qustAns.ans"><span>Ans.</span>
                                                                <i class="noreplay">Replied Not Yet</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!-- <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/3.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Edmund Richards</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span></h2>
                                                        <p><span>Ans.</span> 
                                                            <i class="noreplay">No Reply Yet</i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>








<!--  give  review model -->



<div class="modal fade csModal" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>

            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/rating.svg"></svg-icon>
                    <h2>Give Review</h2>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="giveReviewBox">
                        <div class="px-4">
                            <!-- <div class="form-floating mb-3">
                       <input class="form-control" type="text" placeholder="Enter Email">
                        <label for="floatingInput">Email <span class="reqField">*</span></label>
                    </div> -->
                            <!-- <form class="csForm" [formGroup]='forgotPwdForm' (ngSubmit)="forgotPass()"> -->
                            <form id="giveReview" class="csForm" [formGroup]='forgotPwdForm'
                                (ngSubmit)="reviewSubmit()">

                                <div class="ratingBlk">

                                    <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
                                    <!-- <p>Rate: {{currentRate}}</p> -->
                                </div>

                                <div class="form-floating mb-3">
                                    <textarea rows="4" type="text" class="form-control textArea"
                                        formControlName="comments" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Write Your Comments</label>
                                    <!-- <small class="text-danger" *ngIf="forgotPwdForm.get('comments').touched">
                                        <span *ngIf="forgotPwdForm.get('comments').hasError('required')">Comments is
                                            required field.</span>
                                    </small> -->
                                </div>

                                <div class="modal-footer">
                                    <button type="submit" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn thm-btn" id="myApplyId">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="modal-body">
              <div class="modalHeadCnt">
                  <svg-icon class="svgIcon" src="assets/img/rating.svg"></svg-icon>
                  <h2>Give Review</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <div class="giveReviewBox">
                     
                      <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
                <p>Rate: {{currentRate}}</p>
                      <div class="">
                          <div class="form-floating mb-3">
                              <textarea rows="4" type="text" class="form-control textArea" id="floatingInput" placeholder="Write something..."></textarea>
                              <label for="floatingInput">Write Your Comments <span class="reqField">*</span></label>
                          </div>
                      </div>
                  </div>
              </div>
        </div> -->
            <!-- <div class="modal-footer">
          <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn thm-btn">Submit</button>
        </div> -->
        </div>
    </div>
</div>








<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="reportForm" (ngSubmit)="reportGig();">
            <div class="modal-content">
                <div class="modal-headerIcon">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                        <h2>Report Gig</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="form-floating mb-3">
                                    <select type="text" class="form-control" formControlName="title" id="floatingInput">
                                        <ng-container *ngFor="let title of reportTitle">
                                            <option [value]="title.id">{{title.title}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="reportForm.get('title').touched && reportForm.get('title').invalid">
                                        <span *ngIf="reportForm.get('title').hasError('required')">Title is required
                                            field.</span>

                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" formControlName="description" type="text"
                                        class="form-control textArea" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="reportForm.get('description').touched">

                                        <span *ngIf="reportForm.get('description').hasError('required')">Description is
                                            required field.</span>
                                        <span *ngIf="reportForm.get('description').hasError('maxlength')"> Please enter
                                            no more than 300 characters.</span>
                                        <span *ngIf="reportForm.get('description').hasError('minlength')"> Please enter
                                            at least 5 characters.</span>
                                    </small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>


<!--  model for choose payment  -->


<div class="modal fade csModal" id="groupModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">How you want to make an offer?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">
                    <div class="assgigAct">


                        <button data-toggle="modal" (click)="checkBankAccount(1);" data-target="#groupModel"
                            type="button" class="btn btn-secondary offerbutton">As a profile</button>
                    </div>
                    <br>
                    <!-- <div class="assgigAct" *ngIf="(myOffer | json) == '{}' && gigData.gigStatus == 1"> -->
                    <div class="assgigAct">
                        <button data-toggle="modal" (click)="groupListForAdmin();" data-target="#groupModel123"
                            type="button" class="btn btn-secondary offerbutton">As a group</button>

                    </div>
                    <br>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <a routerLink="/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
        </div> -->
        </div>
    </div>
</div>





<div class="modal fade csModal" id="groupModel123" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose an group to invite the livlouder for</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">


                    <div *ngIf="dataFound == false" class="GigListBookingNoInfo mb-4">
                        <div class="noDataView">
                            <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                            <p>You currently have no groups. <br /> Please create an group first.</p>
                        </div>
                    </div>



                    <ng-container *ngFor="let groupList of groupList">

                        <div class="membItem">
                            <label class="selectGroupBook">

                                <input type="radio" value="{{groupList.userMetaId}}" name="groupChoose">
                                <div class="membInfoBlk">
                                    <div class="membImg">
                                        <img [src]="groupList.profileImage">
                                    </div>
                                    <div class="membName">
                                        <h2>{{groupList.groupName}}</h2>
                                        <p *ngIf="groupList.totalMember == 1">{{groupList.totalMember}} Member</p>
                                        <p *ngIf="groupList.totalMember != 1">{{groupList.totalMember}} Members</p>

                                    </div>
                                    <div class="membAct radioCheck">
                                        <span class="fa fa-check-circle"></span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div class="modal-footer">
                <a routerLink="/groups/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
                <button *ngIf="dataFound == true" (click)="checkBankAccount(2)" type="button"
                    class="btn thm-btn">Apply</button>
            </div>
        </div>
    </div>
</div>
<!--  sharimg model -->


<div class="modal fade csModal" id="Shareone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Do you want to share?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll socialmodelCls">
                    <div class="assgigAct socialmainIco">
                        <div class="socialshares">
                            <div class="socialshares-twitter social_ico"></div>
                            <div class="socialshares-facebook social_ico"></div>
                            <!--<div class="socialshares-reddit"></div>
                <div class="socialshares-tumblr"></div> -->
                            <div class="socialshares-linkedin social_ico"></div>
                            <!-- <div class="socialshares-pinterest"></div>
                <div class="socialshares-slack"></div>
                <div class="socialshares-vk"></div>-->
                            <div class="socialshares-email social_ico"></div>
                        </div>
                    </div>
                    <br>

                    <br>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <a routerLink="/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
        </div> -->
        </div>
    </div>
</div>





<div class="modal fade csModal" id="groupModel123" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose an group to invite the livlouder for</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">


                    <div *ngIf="dataFound == false" class="GigListBookingNoInfo mb-4">
                        <div class="noDataView">
                            <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                            <p>You currently have no groups. <br /> Please create an group first.</p>
                        </div>
                    </div>



                    <ng-container *ngFor="let groupList of groupList">

                        <div class="membItem">
                            <label class="selectGroupBook">

                                <input type="radio" value="{{groupList.userMetaId}}" name="groupChoose">
                                <div class="membInfoBlk">
                                    <div class="membImg">
                                        <img [src]="groupList.profileImage">
                                    </div>
                                    <div class="membName">
                                        <h2>{{groupList.groupName}}</h2>
                                        <p *ngIf="groupList.totalMember == 1">{{groupList.totalMember}} Member</p>
                                        <p *ngIf="groupList.totalMember != 1">{{groupList.totalMember}} Members</p>

                                    </div>
                                    <div class="membAct radioCheck">
                                        <span class="fa fa-check-circle"></span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div class="modal-footer">
                <a routerLink="/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
                <button (click)="checkBankAccount(2)" type="button" class="btn thm-btn">Apply</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="makeAnOffer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form [formGroup]="offerForm" (ngSubmit)="offerGig($event)">
                <div class="modal-headerIcon">
                    <button type="button" class="close" (click)="resetOfferForm()" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/make_offer.svg"></svg-icon>
                        <h2>Make An Offer</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend"
                                        style="margin-right: -1px;height: 58px !important;">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <div class="form-floating">
                                        <input type="text" class="form-control phone" formControlName="offerPrice"
                                            id="floatingInput" placeholder="Enter Price" type="number" (keypress)="onKeyPress($event)">
                                        <label for="floatingInput">Price <span class="reqField">*</span></label>
                                        <!-- <small class="text-danger"
                                            *ngIf="offerForm.get('offerPrice').touched && offerForm.get('offerPrice').invalid">
                                            Price is required field.
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="offerForm.get('offerPrice').hasError('maxlength')">
                                            Please enter no more than 6 digit.
                                        </small> -->
                                        <div *ngIf="offerForm.get('offerPrice').invalid && (offerForm.get('offerPrice').dirty || offerForm.get('offerPrice').touched)" class="error">
                                            <small class="text-danger"
                                                *ngIf="offerForm.get('offerPrice').errors.required">
                                                Price is required field.
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="offerForm.get('offerPrice').errors.pattern">
                                                Price must be valid.
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="offerForm.get('offerPrice').hasError('maxlength')">
                                                Please enter no more than 6 digit.
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="offerForm.get('offerPrice').errors && offerForm.get('offerPrice').errors.min">
                                                Price must be minimum $1.
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="offerForm.get('offerPrice').errors && offerForm.get('offerPrice').errors.max">
                                                Price should be less than or equals to $999999.99
                                            </small>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" type="text" class="form-control textArea"
                                        formControlName="offerDescription" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField"></span></label>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').touched && offerForm.get('offerDescription').hasError('required')">
                                        Description is required field.
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').touched && offerForm.get('offerDescription').hasError('noWhitespace')">
                                        Required field.
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').hasError('maxlength')">
                                        Please enter no more than 300 characters.
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').hasError('minlength') && !offerForm.get('offerDescription').hasError('noWhitespace')">
                                        Please enter at least 5 characters.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" (click)="resetOfferForm()">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="addBank" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="bankAccountForm" (ngSubmit)="addBankAccount();">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Bank Acount</h5>
                    <button type="button" class="close" aria-label="Close">
                        <span (click)="resetBankForm();" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="formModal">
                        <div class="formModalBlk">
                            <div class="bankFormBlk">
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountName" class="form-control"
                                        placeholder="Enter Name">
                                    <label for="floatingInput">Name <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountName').touched && bankAccountForm.get('accountName').invalid">
                                        Account name is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="bsdCode" class="form-control"
                                        placeholder="Enter BSB Code">
                                    <label for="floatingInput">BSB Code <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('bsdCode').touched && bankAccountForm.get('bsdCode').invalid">
                                        BSD code is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountNo" class="form-control"
                                        placeholder="Enter Account Number">
                                    <label for="floatingInput">Account Number <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountNo').touched && bankAccountForm.get('accountNo').invalid">
                                        Account no is required field.
                                    </small>
                                </div>
                                <!-- <div class="form-floating mb-3">
                                    <input type="text" formControlName="address" class="form-control" placeholder="Enter Address">
                                    <label for="floatingInput">Registered Address <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="bankAccountForm.get('address').touched && bankAccountForm.get('address').invalid">
                                        Address is required field.
                                    </small>
                                </div> -->
                                <!-- <div class="form-floating mb-3">
                                    <select type="text" class="form-control" id="floatingInput" >
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>Canada</option>
                                        <option>Japan</option>
                                    </select>
                                    <label for="floatingInput">Select Country <span class="reqField">*</span></label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" (click)="resetBankForm();">Close</button>
                    <button type="submit" class="btn thm-btn">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Please add account.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" (click)="stripeVerifyLink()" class="btn btn-primary">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="requestPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Request Payment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalUserList">
                    <div class="invitedMemberList">
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/1.jpg">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/2.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/3.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/2.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/3.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>

<!-- Cancel Gig -->
<div class="modal fade csModal" id="cancelGig" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/cancel_gig.svg"></svg-icon>
                    <h2>Cancel Gig</h2>
                    <p>Are you sure want to cancel the gig ? Your review will get come 0. </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" data-toggle="modal" data-dismiss="modal" data-target="#cancelGigAlert"
                    class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="cancelGigAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                    <h2>Alert</h2>
                    <p>Are you sure want to cancel the gig ?</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" (click)="cancelGig();" class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>

<!--  group member model  -->



<div class="modal fade csModal" id="groupMemberPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Request Payment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">


                    <div *ngIf="dataFound == false" class="GigListBookingNoInfo mb-4">
                        <div class="noDataView">
                            <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                            <p>You currently have no groups. <br /> Please create an group first.</p>
                        </div>
                    </div>

                    <ng-container *ngFor="let groupMemberList of groupMemberList let i = index">

                        <div *ngIf="groupMemberList.isAdmin !=1" class="membItem requestPayment">
                            <label class="selectGroupBook123">

                                <input [(ngModel)]="asddddddd[i]" type="number" (keypress)="onKeyPress($event)" value="" name="groupChoose123"
                                    placeholder="Enter Amount" class="inputPrice">
                                <div class="membInfoBlk">
                                    <div class="membImg">
                                        <!-- <img [src]="groupList.profileImage"> -->
                                    </div>
                                    <div class="membName">
                                        <h2>{{groupMemberList.liveLouderName}}</h2>
                                        <!-- <p *ngIf ="groupList.totalMember == 1">{{groupList.totalMember}} Member</p> -->
                                        <!-- <p *ngIf ="groupList.totalMember != 1">{{groupList.totalMember}} Members</p> -->

                                    </div>
                                    <!-- <div class="membAct radioCheck">
                              <span class="fa fa-check-circle"></span>
                          </div> -->
                                </div>
                            </label>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div class="modal-footer">
                <!-- <a routerLink="/groups/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a> -->
                <button (click)="test1()" type="button" class="btn thm-btn">Apply</button>
            </div>
        </div>
    </div>
</div>
<!-- payment conform  model -->

<div class="modal fade csModal" id="paymentAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                    <h2>Alert</h2>
                    <p>Are you sure want to proceed further ?</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" (click)="test();" class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>