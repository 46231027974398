import {
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { SignupService } from '../../services/signup.service';
import {
	FormBuilder,
	Validators,
	FormGroup,
	FormControl,
	FormArray,
} from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { GigService } from '../../services/gig-service';
import { Router } from '@angular/router';
import { GroupRequest } from 'src/app/services/group-request';

import { ToastrNotificationService } from '../../services/toastr-notification.service';

declare var $: any;
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-livlouder-update-profile',
	templateUrl: './livlouder-update-profile.component.html',
	styleUrls: ['./livlouder-update-profile.component.css'],
})
export class LivlouderUpdateProfileComponent implements OnInit {
	completeProfileForm: FormGroup;
	completeStepTwo: FormGroup;
	uploadImageForm: FormGroup;
	socialLinksForm: FormGroup;

	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}
	optradio: any;
	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	userType = 1;
	stepNo = 2;
	defaultValue;
	images = [];
	photoSkip = 1; // 1== true , 2== false (means photo is selected)
	selectLinkIcon = 0;
	typeIdSelected = [];
	subTypeIdSelected = [];
	galleryImg = [];
	editData;
	profileImage;
	type: any;
	userSelects = [];
	data;
	experience = [];
	lat;
	lng;
	suburb;
	location;
	linkDyna = [];
	stype = [];
	socialtype;
	mediaType = ['facebook', 'twitter', 'instagram'];
	asd1 = [];
	selectedLink;
	allSocialLink = [];
	media = [];
	video = [];
	showAddMoreBtn = true;
	isLinkStatus;
	newLink: any = {
		'id': '',
		'type': 'globe',
		'socialLink': '',
		'social_media_type_id': '',
		'image': '',
		'isShow': 'true',
	};




	@ViewChild('imgDiv', {
		static: false,
	})
	d1: ElementRef;
	@ViewChild('uploadLinksBtn') uploadLinksBtn: ElementRef;
	constructor(
		private signupService: SignupService,
		private fb: FormBuilder,
		private commonService: CommonService,
		private authService: AuthService,
		private gigService: GigService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private groupRequest: GroupRequest
	) { }

	ngOnInit(): void {
		showLoader();


		this.signupService.socialType().subscribe(
			(data) => {
				hideLoader();
				data.data.forEach((element) => {
					element.isShow = true;
					this.allSocialLink.push(element);
				});
			},
			(err) => {
				this.toastService.showError(err.error.message, 'Error');
				this.authService.checkValidToken(err.error);


			}
		);

		this.signupService.getTypeSubType().subscribe(
			(data) => {
				hideLoader();

				this.typeSubtype = data.data;
			},
			(err) => {
				hideLoader();

				this.toastService.showError(err.error.message, 'Error');
				this.authService.checkValidToken(err.error);
			}
		);
		this.signupService.getTypeSubType().subscribe(
			(data) => {

				this.typeSubtype = data.data;
				hideLoader();

				this.addTypeListData(this.typeSubtype);

			},

			(err) => {
				hideLoader();

				this.toastService.showError(err.error.message, 'Error');
				this.authService.checkValidToken(err.error);
			}
		);

		// this.completeProfileForm = new FormGroup({
		// 	fullname: new FormControl(null, [
		// 		Validators.required,
		// 		Validators.maxLength(30),
		// 	]),
		// 	// price: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(6),
		// 	// ]),
		// 	'price': new FormControl(null, [Validators.required, Validators.min(1),
		// 		Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
		// 		Validators.maxLength(9)]),
		// 	typeBlankCheck: new FormControl(null, [Validators.required]),
		// 	subTypeBlankCheck: new FormControl(null, [Validators.required]),
		// 	profileImage: new FormControl(null, [Validators.required]),
		// 	location: new FormControl(null, [Validators.required]),
		// });

		this.completeStepTwo = new FormGroup({
			about: new FormControl(null, [
				Validators.maxLength(300),
				Validators.minLength(5),
			]),
			availability: new FormControl(null, [Validators.required]),
			experience: new FormArray([new FormControl(null, [Validators.required])])
		});

		this.authService.setUserMetaId(this.userType);
		var metaId = this.authService.getUserMetaId();
		this.groupRequest.getMedia(metaId, 1).subscribe((res) => {
			hideLoader();
			this.media = res.data;
		});

		this.groupRequest.getMedia(metaId, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;
		});

		this.authService.setUserMetaId(this.userType);
		var metaId = this.authService.getUserMetaId();

		this.gigService.discoverDetail(metaId).subscribe(
			(res) => {
				this.profileImage = res.detail.profileImage;
				// var temp = new Array();
				// This will return an array with strings "1", "2", etc.

				// console.log('typeId',res.detail);
				// console.log('typeId',res.detail.typeId);
				// console.log('subTypeId',res.detail.subTypeId);
				// console.log('split typeId',res.detail.typeId.replace(/\s/g, '').split(','));
				// console.log('split',res.detail.subTypeId.replace(/\s/g, '').split(','));
				


					this.typeIdSelected = res.detail.typeId.split(',');
					// this.subTypeIdSelected = res.detail.subTypeId.split(',');
					// this.subTypeIdSelected = res.detail.subTypeId.replace(/\s/g, '').split(',');

					if (res.detail.subTypeId) {
						this.subTypeIdSelected = res.detail.subTypeId.replace(/\s/g, '').split(',');
					}

				if (res.detail.experience) {
					this.experience = JSON.parse(res.detail.experience);
				}
				if (res.detail.social_media.length > 0) {
					this.linkDyna = res.detail.social_media;
					this.linkDyna.forEach((element) => {
						let index = this.allSocialLink.findIndex(
							(x) => x.id == element.social_media_type_id
						);

						if (index != -1) {

							this.allSocialLink[index].isShow = false;
						}
						this.stype.push(element.social_media_type_id);
						this.isLinkStatus = true;

					});

				} else {
					this.isLinkStatus = false;
					this.linkDyna.push(this.newLink);
				}

				this.checkSocialTypeExist();


				this.location = res.detail.location;
				// this.lat = res.detail.currentLat;
				// this.lng = res.detail.currentLong;

				this.lat = res.detail.profileLat;
				this.lng = res.detail.profileLong;
				this.suburb = res.detail.suburb;
				this.socialLink(this.linkDyna);


				this.completeProfileForm = new FormGroup({
					fullname: new FormControl(res.detail.liveLouderName, [
						Validators.required,
						Validators.maxLength(30),
					]),
					// price: new FormControl(res.detail.price, [
					// 	Validators.required,
					// 	Validators.pattern('^[0-9]*$'),
					// 	Validators.maxLength(6)
					// ]),
					price: new FormControl(res.detail.price, [Validators.required, Validators.min(1), Validators.max(999999.99),
						Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
						Validators.maxLength(9)]),
					typeBlankCheck: new FormControl(res.detail.type, [
						Validators.required,
					]),
					subTypeBlankCheck: new FormControl(res.detail.subType, [
						Validators.required,
					]),
					profileImage: new FormControl(res.detail.profileImage, [
						Validators.required,
					]),

					location: new FormControl(res.detail.location, [Validators.required]),

				});

				// if(res.detail.about){
				// 	res.detail.about = res.detail.about;
				// }else{
				// 	res.detail.about = null
				// }


				this.completeStepTwo = new FormGroup({
					about: new FormControl(res.detail.about, [
						Validators.maxLength(300),
						Validators.minLength(5),
					]),
					availability: new FormControl(res.detail.availability, [
						Validators.required, Validators.maxLength(30)

					]),
					// experience: new FormArray([
					// 	//new FormControl(res.detail.experience),
					// ]),
					experience: new FormArray([])
					// experience: new FormArray([new FormControl(null, [Validators.required])])
				});


				// if(this.experience){
				// 	this.experience = this.experience;
				// }else{
				// 	this.experience = [];
				// }
				this.patchMustHave(this.experience);
			},
			(err) => {
				hideLoader();

				this.authService.checkValidToken(err.error);

				this.toastService.showError(err.error.message, 'Error');
			}
		);

		this.uploadImageForm = new FormGroup({
			file: new FormControl(null),
			fileSource: new FormControl(null),
		});

		localStorage.setItem('SelectedUser', '1');

		var stepNo = this.authService.setUserMetaId(this.userType); //this.authService.getOnBoardingProfileCount();

		this.stepNo = 2; //parseInt(stepNo) + 1;

		$('[data-toggle="tooltip"]').tooltip();



		this.socialLinksForm = new FormGroup({
			links: new FormArray([]),

			socialMedia: new FormControl(null),
		});
	}

	socialLink(data) {
		let link = data;
		if (link.length == 0) {
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required])
			);
		} else {
			link.forEach((element) => {
				(<FormArray>this.socialLinksForm.get('links')).push(
					new FormControl(element.socialLink, [Validators.required])
				);
			});
		}
	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}
	  
	checkSocialTypeExist() {

		var asd = this.allSocialLink;
		this.showAddMoreBtn = false;
		//setTimeout(function () {
		asd.forEach((element) => {
			if (element.isShow == true) {
				this.showAddMoreBtn = true;

			}
		})

		//}, 4000)


	}

	addLinks() {


		if (this.stype.length != this.linkDyna.length) {
			this.toastService.showError('Please select Social Link type', 'Error');
			return false;
		}

		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');
			return false;
		} else {
			// this.linkDyna = this.linkDyna;
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required])
			);

			let newLinks: any = {
				'id': '',
				'type': 'globe',
				'socialLink': '',
				'social_media_type_id': '',
				'image': '',
				'isShow': 'true',
			};
			this.linkDyna.push(newLinks);

		}
	}

	removeLinks(index) {

		this.stype.splice(index, 1);
		let indexCheck = this.allSocialLink.findIndex((x) => x.id == this.linkDyna[index].social_media_type_id);

		if (indexCheck != -1) {

			this.allSocialLink[indexCheck].isShow = true;
		}

		this.linkDyna.splice(index, 1);
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index);
		this.checkSocialTypeExist();
	}

	changeIcon(index, val) {

		var arr = [
			`<i class="fab fa-facebook-square"></i>`,
			`<i class="fab fa-twitter-square"></i>`,
			`<i class="fab fa-instagram"></i>`,
		];


		var oldId = this.linkDyna[val].social_media_type_id;


		this.linkDyna[val].type = this.allSocialLink[index].type;

		this.linkDyna[val].social_media_type_id = this.allSocialLink[index].id;

		this.allSocialLink[index].isShow = false;
		if (oldId != this.allSocialLink[index].id) {

			let indexnew = this.allSocialLink.findIndex((x) => x.id == oldId);

			if (indexnew != -1) {

				this.allSocialLink[indexnew].isShow = true;
			}
		}

		this.stype[val] = this.allSocialLink[index].id;
		this.checkSocialTypeExist();
	}

	onFileChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.completeProfileForm.patchValue({
				profileImage: file,
			});
		}
	}

	patchMustHave(data) {console.log("experiance data- ", data);
	
		let experiance = data;
		// console.log('helloneha',mustHave);
		console.log("log---", this.completeStepTwo.get('experience'));
		console.log("values---", this.completeStepTwo.get('experience').value);
		
		experiance.forEach((element) => {console.log("element- ", element);
		
			if (element) {
				(<FormArray>this.completeStepTwo.get('experience')).push(
					new FormControl(element, [Validators.required])
				);
			}
		});
	}


	public handleAddressChange(address: any) {
		//console.log(address.formatted_address)
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		this.location = address.formatted_address;
	}

	previous() {
		showLoader();
		this.stepNo = this.stepNo - 1;
		hideLoader();
		var metaId = this.authService.getUserMetaId();

		this.groupRequest.getMedia(metaId, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;
		});
	}

	onCheckboxChangeRole(type, event) {
		if (event.target.checked) {
			this.typeListData = [];
			this.subTypeArray = [];
			this.typeIdSelected = [];
			this.subTypeIdSelected = [];
			this.subTypeSelected = [];
			this.typeListData.push({
				categoryId: type.categoryId,
				name: type.name,
				parent_id: type.parent_id,
			});

			this.subTypeArray.push(type);
			this.typeIdSelected.push(type.categoryId);
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}

			//remove subtype
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].parentId == type.categoryId) {
					this.subTypeSelected.splice(i, 1);
				}
			}

			for (var i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i].categoryId == type.categoryId) {
					// Remove selected subtype
					let subArray = this.subTypeArray[i].subType;
					for (let si = 0; si < subArray.length; si++) {
						if (subArray[si].parent_id == type.categoryId) {
							let sIndex = this.subTypeIdSelected.indexOf(subArray[si].categoryId);
							if (sIndex > -1) {
								this.subTypeIdSelected.splice(sIndex, 1);
							}
						}
					}
					this.subTypeArray.splice(i, 1);
				}
			}

			//Remove subtype object from array
			// for (i = 0; i < this.subTypeArray.length; i++) {
			// 	if (this.subTypeArray[i] == type.categoryId) {
			// 		this.subTypeArray.splice(i, 1);
			// 	}
			// }

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}

		if (this.typeListData.length == 0) {
			this.completeProfileForm.patchValue({
				typeBlankCheck: '',
			});
		} else {
			this.completeProfileForm.patchValue({
				typeBlankCheck: '1',
			});
		}
	}

	removeType(index) {
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}
		//remove subtype
		for (var i = 0; i < this.subTypeSelected.length; i++) {
			if (this.subTypeSelected[i].parentId == parent_id) {
				this.subTypeSelected.splice(i, 1);
			}
		}

		for (i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {
				// Remove selected subtype
				let subArray = this.subTypeArray[i].subType;
				for (let si = 0; si < subArray.length; si++) {
					if (subArray[si].parent_id == parent_id) {
						let sIndex = this.subTypeIdSelected.indexOf(subArray[si].categoryId);
						if (sIndex > -1) {
							this.subTypeIdSelected.splice(sIndex, 1);
						}
					}
				}
				this.subTypeArray.splice(i, 1);
			}
		}
	}

	onCheckboxChangesubType(subType, event) {
		if (event.target.checked) {
			this.subTypeSelected.push({
				categoryId: subType.categoryId,
				name: subType.name,
				parentId: subType.parent_id,
			});
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		if (this.subTypeSelected.length == 0) {
			this.completeProfileForm.patchValue({
				subTypeBlankCheck: '',
			});
		} else {
			this.completeProfileForm.patchValue({
				subTypeBlankCheck: '1',
			});
		}
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	// 1 step api

	basicInfoStepOne(event) {
		event.stopPropagation();


		if (this.completeProfileForm.invalid) {
			this.validateAllFormFields(this.completeProfileForm);

			return;
		} else {
			
			var typeStore = this.commonService.dataArrangType(this.typeListData);
			var subTypeStore = this.commonService.dataArrangSubType(
				this.typeListData,
				this.subTypeSelected
			);
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append('userType', '1');
			formData.append('name', this.completeProfileForm.value.fullname);
			formData.append('price', this.completeProfileForm.value.price);
			formData.append('typeId', typeStore);

			formData.append('typeSubtype', JSON.stringify(subTypeStore));
			formData.append(
				'profileImage',
				this.completeProfileForm.value.profileImage
			);
			formData.append('metaId', metaId);
			formData.append('onBordingStep', '2');
			formData.append('isUpdate', '1');
			formData.append('location', this.location);
			formData.append('latitude', this.lat);
			formData.append('longitude', this.lng);
			formData.append('suburb', this.suburb);

			showLoader();
			this.signupService.stepOne(formData).subscribe(
				(data) => {
					hideLoader();
					this.toastService.showSuccess(data.message, 'Success');
					this.authService.updateOnBoardingProfileCount(
						1,
						data.data.onBoardingProfileCount
					);
					var step = this.authService.getOnBoardingProfileCount();
					this.stepNo = parseInt(step) + 1;
				},
				(err) => {
					this.authService.checkValidToken(err.error);
					this.toastService.showError(err.error.message, 'Error');

					hideLoader();
					console.log(err);
				}
			);
		}

		return;
	}

	handleClick() {
		// Handle the click event logic here
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}

	// ngAfterViewChecked() {
	// 	$('#imageGallery').lightGallery({
	// 		thumbnail: true,
	// 		selector: '.addedImg',
	// 		download: false,
	// 	});
	// 	$('#galleryVideos').lightGallery({
	// 		selector: '.addedImg',
	// 	});
	// }

	// addTypeListData(typeSubtype) {
	// 	console.log('typeSubtypetypeSubtype',typeSubtype);
	// 	typeSubtype.forEach((element) => {
	// 		element.Type.forEach((element1) => {
	// 			if (this.typeIdSelected.includes(element1.categoryId)) {
	// 				this.typeListData.push({
	// 					categoryId: element1.categoryId,
	// 					name: element1.name,
	// 					parent_id: element1.parent_id,
	// 				});
	// 				this.subTypeArray.push(element1);
	// 				element1.subType.forEach((element2) => {
	// 					if (this.subTypeIdSelected.includes(element2.categoryId)) {
	// 						this.subTypeSelected.push({
	// 							categoryId: element2.categoryId,
	// 							name: element2.name,
	// 							parentId: element2.parent_id,
	// 						});
	// 					}
	// 				});
	// 			}
	// 		});
	// 	});

	// 	console.log('aaaqqqwww', this.typeListData);

	// }


	addTypeListData(typeSubtype) {
		// console.log('typeSubtypetypeSubtype', typeSubtype);
	
		setTimeout(() => {
			typeSubtype.forEach((element) => {
				element.Type.forEach((element1) => {
					if (this.typeIdSelected.includes(element1.categoryId)) {
						this.typeListData.push({
							categoryId: element1.categoryId,
							name: element1.name,
							parent_id: element1.parent_id,
						});
						this.subTypeArray.push(element1);
						element1.subType.forEach((element2) => {
							if (this.subTypeIdSelected.includes(element2.categoryId)) {
								this.subTypeSelected.push({
									categoryId: element2.categoryId,
									name: element2.name,
									parentId: element2.parent_id,
								});
							}
						});
					}
				});
			});
		}, 50);
	}
	


	
	
	
	
	
	
	
	
	
	


	
	
	  


	  

	basicInfoStepTwo(event) {
		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			//return false;
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();

			if (this.completeStepTwo.value.about) {
				formData.append('about', this.completeStepTwo.value.about);
			}

			formData.append(
				'experience',
				JSON.stringify(this.completeStepTwo.value.experience)
			);
			formData.append('userType', '1');
			formData.append('metaId', metaId);
			formData.append('onBordingStep', '3');
			formData.append('availability', this.completeStepTwo.value.availability);

			showLoader();
			this.signupService.stepTwo(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, 'Success');

				this.authService.updateOnBoardingProfileCount(
					1,
					data.data.onBoardingProfileCount
				);

				var step = this.authService.getOnBoardingProfileCount();

				this.stepNo = parseInt(step) + 1;
			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
			);
		}
	}

	addExperience() {
		(<FormArray>this.completeStepTwo.get('experience')).push(
			new FormControl(null, [Validators.required])
		);
	}

	removeExperience(index) {
		(<FormArray>this.completeStepTwo.get('experience')).removeAt(index);
	}

	//STEP 4 and 5 UPLOAD IMAGE AND VIDEO

	onUploadFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file,
			});

			this.photoSkip = 2;
			this.uploadSingleImg();
		}
	}

	UploadImgStepThree() {
		var metaId = this.authService.getUserMetaId();

		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '4');
			formData.append('type', '1');

			this.signupService.skipPhoto(formData).subscribe(
				(data) => {
					hideLoader();

					this.toastService.showSuccess(data.message, 'Success');

					this.stepNo = 5;
				},
				(error) => {
					hideLoader();
					console.log(error);
					this.toastService.showError(error.error.message, 'Error');
					this.stepNo = 5;
				}
			);
		} else {
			//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)
			var step = this.authService.getOnBoardingProfileCount();
			this.stepNo = parseInt(step) + 1;
			this.stepNo = 5;
		}
	}

	uploadSingleImg() {
		var metaId = this.authService.getUserMetaId();
		const formData = new FormData();
		formData.append('media', this.uploadImageForm.value.fileSource);
		formData.append('userType', '1');
		formData.append('metaId', metaId);
		formData.append('onBordingStep', '4');
		formData.append('mediaType', '1');

		showLoader();
		this.signupService.uploadImage(formData).subscribe((data) => {
			hideLoader();
			this.toastService.showSuccess(data.message, 'Success');
			
			let imgData = data.mediaData;
			this.media.push(imgData);
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		});
	}

	/**
	 * Remove added image
	 * @param imgId
	 * @param index
	 */
	removeImg(imgId, index) {
		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			(res) => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}

				var metaId = this.authService.getUserMetaId();
				this.groupRequest.getMedia(metaId, 1).subscribe((res) => {
					hideLoader();
					this.media = res.data;
				});
				this.toastService.showSuccess(res.message, 'Success');
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}
	/**
	 * Remove added image
	 * @param imgId
	 * @param index
	 */
	removideo(imgId, index) {
		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			(res) => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}
				var metaId = this.authService.getUserMetaId();

				this.groupRequest.getMedia(metaId, 2).subscribe((res) => {
					hideLoader();
					this.video = res.data;
				});
				this.toastService.showSuccess(res.message, 'Success');
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}
	/**
	 * skip upload video
	 */
	skipVideo() {

		this.checkSocialTypeExist();

		var metaId = this.authService.getUserMetaId();

		showLoader();

		const formData = new FormData();

		formData.append('metaId', metaId);
		formData.append('onBordingStep', '4');
		formData.append('type', '2');

		this.signupService.skipPhoto(formData).subscribe(
			(data) => {
				hideLoader();

				this.toastService.showSuccess(data.message, 'Success');

				this.stepNo = 6;
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
				//this.error = error.message;
			}
		);
	}

	onSelectedVideo(event) {

		this.checkSocialTypeExist();

		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append(
						'authToken',
						this.authService.getAuthorizationToken()
					);
					formData.append('metaId', userMetaId);
					formData.append('userType', '1');
					formData.append('mediaType', '2');
					formData.append('onBordingStep', '4');
					formData.append('media', file);
					formData.append('video_thumb', res.blobImage, 'video_thumb.png');
					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();
						this.authService.checkValidToken(data);

						if (data.status == 'fail') {
							this.toastService.showError(data.message, 'Error');
						} else {
							this.toastService.showSuccess(data.message, 'Success');

							this.authService.updateOnBoardingProfileCount(
								1,
								data.mediaData.onBoardingProfileCount
							);

							var step = this.authService.getOnBoardingProfileCount();

							this.stepNo = parseInt(step) + 1;

							this.groupRequest.getMedia(userMetaId, 2).subscribe((res) => {
								this.video = res.data;
							});
						}
					}, (err) => {
						hideLoader();
						if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
							this.authService.checkValidToken(err.error);
							this.toastService.showError('Session Expired', 'Error');

						} else {
							this.toastService.showError(err.error.message, 'Error');
						}
					});
				} else {
					hideLoader();
					this.toastService.showError(res.message, 'Error');
				}
			});
		}
	}



	/**
	 *
	 * upload medial links
	 */
	uploadLinks() {

		var metaId = this.authService.getUserMetaId();

		if (this.uploadLinksBtn.nativeElement.textContent == 'Skip') {
			let formData = new FormData();

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '5');
			formData.append('type', this.socialtype);

			this.signupService.skipPhoto(formData).subscribe(
				(data) => {
					hideLoader();
					this.toastService.showSuccess(data.message, 'Success');
					localStorage.setItem(
						'currentUser',
						JSON.stringify(data.userDetail)
					);
					this.router.navigate(['/ProfileLivlouder']);
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
			return;
		}
		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');

			return false;
		} else {
			var linksArray = [];

			var linkSocial = this.socialLinksForm.value.links;

			var type = this.stype;

			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({
							link: linkSocial[i],
							type: this.stype[i],
						});
					}
				}


				if (this.stype.length != this.linkDyna.length) {
					this.toastService.showError('Please select Social Link type', 'Error');
					return false;
				}


				linksArray.forEach(function (value) {

					if (value.type == undefined) {
						alert("Please select Type");
						return false;

					}

				});

				this.socialLinksForm.patchValue({
					socialMedia: linksArray,
				});
			}



			showLoader();
			const formData = new FormData();
			formData.append(
				'socialMedia',
				JSON.stringify(this.socialLinksForm.value.socialMedia)
			);

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '5');

			this.signupService.uploadSocialLinks(formData).subscribe(
				(data) => {
					hideLoader();

					localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
					this.toastService.showSuccess(data.message, 'Success');

					this.router.navigate(['/ProfileLivlouder']);
				},
				(err) => {
					hideLoader();

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	skipPhoto(formData) {
		this.signupService.skipPhoto(formData).subscribe(
			(data) => {
				hideLoader();

				this.toastService.showSuccess(data.message, 'Success');

				this.stepNo = 5;
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
			}
		);
	}


	validateAllFormFields(formGroup: FormGroup) {
		//{1}
		Object.keys(formGroup.controls).forEach((field) => {
			//{2}
			const control = formGroup.get(field); //{3}
			if (control instanceof FormControl) {
				//{4}
				control.markAsTouched({
					onlySelf: true,
				});
			} else if (control instanceof FormGroup) {
				//{5}
				this.validateAllFormFields(control); //{6}
			}
		});

		return;
	}

	onKeyPress(event: KeyboardEvent) {
		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
			event.preventDefault();
			return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}
}
