<app-header-hirer></app-header-hirer>
<style type="text/css">
    .social_ico {
        background-color: unset;
        border-radius: 5px;
        width: 46px !important;
        overflow: hidden;
        padding-left: 12px;
        font-size: 22px;
        white-space: nowrap;
        display: inline-block;
        border-radius: 50%;
        height: 46px;
        line-height: 31px;
    }

    .socialmainIco {
        text-align: center;
        margin-top: 20px;
    }

    .socialmodelCls {
        max-height: unset !important;
        overflow: unset !important;
        margin: 0px !important;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="discoverDetails mainContent" *ngIf="data">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                        <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar>
                            <div class="discoverItem sidebar__inner">
                                <div class="disArtistImg">
                                    <a href="javascript:void(0);"><img [src]="data.profileImage"></a>
                                </div>
                                <div class="disArtistInfo">
                                    <a href="javascript:void(0);">
                                        <h2 class="">{{data.liveLouderName}}</h2>
                                    </a>

                                    <p class="loc"><i class="fa fa-map-marker-alt"></i> <span
                                            class="">{{data.suburb}}</span></p>
                                    <div class="ratePriceBlk">
                                        <div class="rateBlk">
                                            <p><span class="fa fa-star"></span>{{data.rating}}</p><span
                                                class="totalReeview">({{data.totalUserRating}})</span>
                                        </div>
                                        <div class="priceBlk">
                                            <p>{{data.currency}}{{data.price}}/<span>Hr</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="btnActGroup">
                                    <div class="btnActItem bookMeBtn">
                                        <button data-toggle="modal" data-target="#bookMe" (click)="myDiscoverList()"
                                            class="btn thm-btn btn-block">Book Me</button>
                                    </div>
                                    <div class="btnActItem ml-auto">
                                        <div class="dropdown smallDropdown">
                                            <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                    data-toggle="tooltip" type="button">
                                                    <span class="fa fa-ellipsis-v"></span>
                                                </button>
                                            </span>
                                            <div class="dropdown-menu dropdown-right dropIcon"
                                                aria-labelledby="dropdownMenuButton">
                                                <!-- {{is_favourite}} -->
                                                <a data-toggle="modal" data-target="#Shareone" class="dropdown-item"
                                                    href="javascript:void(0)">Share <i class="fa fa-share-alt"></i></a>
                                                <a class="dropdown-item" (click)="likeLivlouder();"
                                                    href="javascript:void(0)">Like User <i
                                                        [ngClass]="is_favourite == '1' ? 'fas fa-heart' : 'far fa-heart'"></i></a>
                                                <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                                    href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="availability">
                                    <h2>Availability</h2>
                                    <p>{{data.availability}}</p>
                                </div>
                                <div *ngIf="data.social_media.length != 0" class="socialLinkAdded">
                                    <ul *ngFor="let socialLink of data.social_media">

                                        <li *ngIf="socialLink.type == 'facebook'"
                                            [class.active]="socialLink.type=='facebook'"><a
                                                [href]="socialLink.socialLink" target="_blank"><span
                                                    class="fab fa-facebook-square"></span> Facebook</a></li>
                                        <li *ngIf="socialLink.type == 'twitter'"
                                            [class.active]="socialLink.type=='twitter'"><a
                                                [href]="socialLink.socialLink" target="_blank"><span
                                                    class="fab fa-twitter-square"></span> Twitter</a></li>
                                        <li *ngIf="socialLink.type == 'instagram'"
                                            [class.active]="socialLink.type=='instagram'"><a
                                                [href]="socialLink.socialLink" target="_blank"><span
                                                    class="fab fa-instagram"></span> Instagram</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">{{data.about?data.about:'Not available'}}</p>
                                </div>

                                <div class="userInfoItem">
                                    <h2 class="infoHead">Experience</h2>
                                    <div *ngIf="experience.length == 0">Not available</div>
                                    <ul class="infoList" *ngFor="let ex of experience">
                                        <li>{{ex}}</li>
                                    </ul>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Type</h2>
                                    <div class="typeBlk">
                                        <ng-container *ngFor="let typ of type">
                                            <div class="typeItem">{{typ}}</div>
                                        </ng-container>


                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Subtypes</h2>
                                    <div class="typeBlk">
                                        <ng-container *ngFor="let styp of subtype">
                                            <div class="typeItem">{{styp}}</div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div *ngIf="data.image_media.data.length == 0 ">Not available</div>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile"
                                        *ngIf="data.image_media.data.length > 0">
                                        <ng-container *ngFor="let imgData of data.image_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{imgData.image}}">
                                                    <img [src]="imgData.thumbImage">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Videos</h2>
                                    <div *ngIf="data.video_media.data.length == 0">Not available</div>

                                    <ng-container *ngFor="let video of data.video_media.data">
                                        <div style="display:none;" [id]="video.id">
                                            <video class="lg-video-object lg-html5" controls preload="none">
                                                <source [src]="video.video" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </ng-container>
                                    <div id="galleryVideos" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor="let video of data.video_media.data">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-poster="{{video.videoImageThumb}}"
                                                    attr.data-html="{{'#'+video.id}}">
                                                    <img [src]="video.videoImageThumb">
                                                    <div class="text-center upload_pic_in_album bsnes-cam">
                                                        <label class="upload_picNew videoPlay">
                                                            <span data-toggle="tooltip" data-placement="top"
                                                                title="Play Video" class="fas fa-play"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30" *ngIf="data.rating_review.count > 0">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Reviews <span class="NoCount">({{data.rating_review.count}})</span>
                                </h2>
                                <div class="reviewsList">
                                    <ng-container *ngFor="let review of data.rating_review.data">
                                        <div class="reviewsItem">
                                            <div class="reviewsCntBlk">
                                                <div class="reviewUserImg">
                                                    <a href="javascript:void(0);">
                                                        <img [src]="review.profileImage">
                                                    </a>
                                                </div>
                                                <div class="reviewsCnt">
                                                    <a href="javascript:void(0);">
                                                        <h2>{{review.fullName}}</h2>
                                                    </a>
                                                    <div class="rateTimeMeta">
                                                        <p>{{review.agotime}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratingView ml-auto">
                                                    <div class="ratingBlk">
                                                        <i [class.filled]="review.rating >= '1'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '2'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '3'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '4'"
                                                            class="fas fa-star"></i>
                                                        <i [class.filled]="review.rating >= '5'"
                                                            class="fas fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="retDec">
                                                <p *ngIf="review.review != 'null' && review.review != ''">{{review.review}}</p>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<!-- report Model -->



<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="reportForm" (ngSubmit)="reportGig();">
            <div class="modal-content">
                <div class="modal-headerIcon">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                        <h2>Report Livlouder</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="form-floating mb-3">
                                    <select type="text" class="form-control" formControlName="title" id="floatingInput">
                                        <ng-container *ngFor="let title of reportTitle">
                                            <option [value]="title.id">{{title.title}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="reportForm.get('title').touched && reportForm.get('title').invalid">
                                        <span *ngIf="reportForm.get('title').hasError('required')">Title is required
                                            field.</span>

                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" formControlName="description" type="text"
                                        class="form-control textArea" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="reportForm.get('description').touched">

                                        <span *ngIf="reportForm.get('description').hasError('required')">Description is
                                            required field.</span>
                                        <span *ngIf="reportForm.get('description').hasError('maxlength')"> Please enter
                                            no more than 300 characters.</span>
                                        <span *ngIf="reportForm.get('description').hasError('minlength')"> Please enter
                                            at least 5 characters.</span>
                                    </small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>








<!-- <div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-headerIcon">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
            <div class="modalHeadCnt">
                <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                <h2>Report User</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="giveReviewBox">
                    <div class="">
                        <div class="form-floating mb-3">
                            <select type="text" class="form-control" id="floatingInput" >
                                <option>Report Title 1</option>
                                <option>Report Title 2</option>
                                <option>Report Title 3</option>
                                <option>Report Title 4</option>
                            </select>
                            <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                        </div>
                        <div class="form-floating mb-3">
                            <textarea rows="4" type="text" class="form-control textArea" id="floatingInput" placeholder="Write something..."></textarea>
                            <label for="floatingInput">Description <span class="reqField">*</span></label>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn thm-btn">Submit</button>
      </div>
    </div>
  </div>
</div> -->
<div class="modal fade csModal" id="bookMe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose an gig to book the livlouder for</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">

                    <div class="GigListBookingNoInfo mb-4" *ngIf="total == 0">
                        <div class="noDataView">
                            <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                            <p>You currently have no gigs, please create gig first.</p>
                        </div>
                    </div>

                    <!-- discoverList -->

                    <ng-container *ngFor="let discoverList of discoverList">

                        <div class="membItem checkbox_multi">
                            <label class="selectGroupBook">

                                <!-- <input type="radio" name="groupChoose" value="{{discoverList.gigId}}" name="radio"> -->
                                <input type="checkbox" value="{{discoverList.gigId}}" name="groupChoose"
                                    id="chk1{{discoverList.gigId}}">
                                <div class="membInfoBlk">
                                    <div class="membName">

                                        <h2>{{discoverList.title}}</h2>
                                        <p>{{discoverList.startDateTime}}</p>
                                        <!-- <p>{{discoverList.userMetaId}}</p> -->
                                    </div>
                                    <div class="membAct radioCheck">

                                        <span class="fa fa-check-circle"></span>
                                    </div>
                                </div>
                            </label>
                        </div>

                    </ng-container>

                </div>
            </div>
            <div class="modal-footer">
                <!-- " [routerLink]="['/discoverHirer/detail',list.userMetaId]" -->
                <!-- {{data.userMetaId}} -->
                <!-- <a routerLink="/createGig/1" class="btn thm-btn" data-dismiss="modal">Create New Gig</a> -->
                <!-- <a *ngIf="list.liveLouderName != '' " [routerLink]="['/discoverHirer/detail',list.userMetaId]"><h2 class="textEllipsis">{{list.liveLouderName | uppercase}}</h2></a> -->

                <a [routerLink]="['/createGig',userMetaId]" class="btn thm-btn" data-dismiss="modal">Create New Gig</a>
                <button *ngIf="total != 0" type="button" class="btn thm-btn" (click)="checkGigPopup()">Apply</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="Shareone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Do you want to share?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll socialmodelCls">
                    <div class="assgigAct socialmainIco">
                        <div class="socialshares">
                            <div class="socialshares-twitter social_ico"></div>
                            <div class="socialshares-facebook social_ico"></div>
                            <!--<div class="socialshares-reddit"></div>
                <div class="socialshares-tumblr"></div> -->
                            <div class="socialshares-linkedin social_ico"></div>
                            <!-- <div class="socialshares-pinterest"></div>
                <div class="socialshares-slack"></div>
                <div class="socialshares-vk"></div>-->
                            <div class="socialshares-email social_ico"></div>
                        </div>
                    </div>
                    <br>

                    <br>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <a routerLink="/createGroup" class="btn thm-btn" data-dismiss="modal">Create New Group</a>
        </div> -->
        </div>
    </div>
</div>

<!-- card model  -->

<div class="modal fade csModal" id="addCard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add New Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="formModal">
                    <div class="formModalBlk">
                        <div class="cardAddBlk">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="inLabel">Enter Card Details <span class="req">*</span></label>
                                        <div class="addCardFieldBlk">
                                            <div class="cardFieldView">
                                                <ngx-stripe-card (change)="onChange($event)"
                                                    [elementsOptions]="elementsOptions"
                                                    [options]="cardOptions"></ngx-stripe-card>
                                            </div>
                                            <button (click)="buy()" type="button" class="btn thm-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cardSequrity">
                                <p>Safe payment online using your credit card. We are secure, we will not share your
                                    details.</p>
                                <div class="cardType">
                                    <img src="assets/img/card/american-express.png">
                                    <img src="assets/img/card/discover.png">
                                    <img src="assets/img/card/mastercard.png">
                                    <img src="assets/img/card/visa.png">
                                </div>
                            </div>
                            <!-- <div class="cardListBlk addedCard">
                                <h2 class="addedCardTitle">Added Card</h2>
                                <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                        <div class="crInfo">
                                            <h2>Discover Network</h2>
                                            <p>xxxx xxxx xxxx 1542</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" name="radio" checked=""><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div> -->
                            <h2 class="addedCardTitle">Added Card</h2>

                            <div class="cardListBlk" *ngFor="let list of cardLists">

                                <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/credit-card.svg"></div>
                                        <div class="crInfo">
                                            <h2>{{list.brand}}</h2>
                                            <p>xxxx xxxx xxxx {{list.last4}}</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" value="{{list.id}}"
                                                    class="devCheckedRadio" name="radio1" checked><span
                                                    class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div>
                                <!-- <div class="acrdItems">
                                    <label class="crItemInfo ripple">
                                        <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                        <div class="crInfo">
                                            <h2>Discover Network</h2>
                                            <p>xxxx xxxx xxxx 1542</p>
                                        </div>
                                        <div class="cardAct">
                                            <label class="radioContainer"><input type="radio" name="radio"><span class="checkmark"></span></label>
                                        </div>
                                    </label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Close</button>
                <button data-toggle="modal" (click)="confirmRequest();"
                    class="btn thm-btn ">Continue</button>

            </div>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="bookMeRequest" tabindex="-1" style="top: 10%;" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 93%;margin: 0 16px;">
            <div class="modal-header">
                <h5 class="modal-title" style="margin: 0 auto;" id="exampleModalLabel">Alert</h5>
                <!--         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button> -->
            </div>
            <div class="modal-body">
                <div class="invitedMemberList GigListBooking modalListScroll">
                    <div class="GigListBookingNoInfo mb-4">
                        <div class="noDataView">
                            <!-- <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon> -->
                            <p>Before send request for your private gig please select your card for payment first,
                                Please make sure do not select any inactive card or any card which has insufficient
                                balance, Otherwise livlouder or group admin will not able to accept your request.</p>
                        </div>
                    </div>

                    <!-- discoverList -->

                    <!--     <ng-container *ngFor="let discoverList of discoverList">

            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose" value="{{discoverList.gigId}}" name="radio" checked="">
                    <div class="membInfoBlk">
                        <div class="membName">
    
                            <h2>{{discoverList.title}}</h2>
                            <p>{{discoverList.startDateTime}}</p>
                        </div>
                        <div class="membAct radioCheck">

                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>

</ng-container> -->



                </div>
            </div>
            <div class="modal-footer">
                <a class="btn thm-btn" data-dismiss="modal" style="color: red;">Cancel</a>
                <button (click)="getAll()" type="button" class="btn thm-btn" style="color: blue;">Select Card</button>
            </div>
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>