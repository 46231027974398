<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
<section class="lsPage sec-pd-30">
    <div class="container">
        <div class="row no-gutter justify-content-center">
            <div class="col-md-6 lsForm">
                <div class="lsFormView" id="signupForm">
                    <div class="lsHead">
                        <h2 class="headText">Create your account</h2>
                    </div>
                    <!-- <div class="socialLogin">
                        <div class="socialItem">
                            <a class="fbIcon" href=""><i class="fab fa-facebook-f"></i></a>
                        </div>
                        <div class="socialItem">
                            <a class="gIcon" href=""><i class="fab fa-google"></i></a>
                        </div>
                        <div class="socialItem">
                            <a class="appleIcon" href=""><i class="fab fa-apple"></i></a>
                        </div>
                    </div> -->
                    <div class="socialLogin">
                        <!-- <div class="socialItem" (click)="loginWithFacebook()">
                            <a class="fbIcon" ><i class="fab fa-facebook-f"></i></a>
                        </div> -->
                        <!-- <div class="socialItem">  -->

                        <div class="socialItem" (click)="loginWithgoogle()"> 
                            <a class="gIcon" href="javascript:void(0)"><i class="fab fa-google"></i></a>
                        </div>
                        
                    </div>
                    <div class="emailtext">
                        <p>Or use your email account</p>
                    </div>
                    <form class="csForm" [formGroup]='reactiveForm' (ngSubmit)="onSubmit()">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="floatingInput" formControlName="email" placeholder="name@example.com">
                            <label for="floatingInput">Email<span class="reqField">*</span></label>
                            <small class="text-danger" *ngIf="reactiveForm.get('email').touched && reactiveForm.get('email').invalid">
                            <span *ngIf="reactiveForm.get('email').hasError('required')">Email is required field. </span>
                            <span *ngIf="reactiveForm.get('email').hasError('email')">Please enter valid email. </span>
                            </small>
                        </div>
                        <!-- <div class="form-floating mb-3 mobileField">
                            <input type="text" class="form-control phone" id="floatingInput" placeholder="Enter Number">
                            <label for="floatingInput">Mobile Number</label>
                        </div> -->
                        <div class="form-floating mb-3">
                            <input [type]="show_button ? 'text' : 'password'" class="form-control" formControlName="password" id="floatingInput4" autocomplete="off" placeholder="••••••••">
                            <!-- <input [type]="show_button ? 'text' : 'password'"> -->
                            <span id="showPass" [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="showPassword()"></span>                            <label for="floatingInput">Password<span class="reqField">*</span></label>
                            <small class="text-danger" *ngIf="reactiveForm.get('password').touched && reactiveForm.get('password').hasError('required')">
                                Password is required field.
                            </small>
                            <div class="text-danger" *ngIf="reactiveForm.get('password').errors?.pattern">
                                Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                                letter, 1 number and 1 special character.
                            </div>
                        </div>
                        <!-- <a (click)="password()">{{show ? 'Show' : 'Hide'}}</a> -->

                        <div class="mt-10">
                            <!-- <button routerLink="/basicInfo" type="button" class="btn thm-btn btn-block">Create Account</button> -->
                         <button type="submit" class="btn thm-btn btn-block">Create Account</button>
                        </div>
                    </form>
                </div>
                <!-- <div class="lsFormView otpConfirmation" id="otpForm">
                    <div class="otptextView">
                        <img src="assets/img/otp.png">
                        <h2>Verify Your Number</h2>
                        <p>Please enter the verification code sent to your given mobile number <b>+14-14521458152</b>.</p>
                        <div class="textLink mt-20">
                            <a id="changeNo" href="javascript:void(0)">Change Number</a>
                        </div>
                    </div>
                    <div class="">
                        <form class="csForm">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="floatingInput" placeholder="Ex: 1536">
                                <label for="floatingInput">Enter Code</label>
                            </div>
                            <div class="text-right forgot-txt"><a href="javascript:void(0);">Resend Code</a></div>
                            <div class="">
                                <button routerLink="/basicInfo" type="button" class="btn thm-btn btn-block">Submit</button>
                            </div>
                        </form>
                    </div>
                </div> -->
            </div>
            <div class="col-md-4 lsBG">
                <div class="lsTextBlk">
                    <div class="lsTextView">
                        <h2>Welcome to Livloud</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <button routerLink="/login" class="btn btn-themeW">Log In Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
<app-footer-livlouder></app-footer-livlouder>