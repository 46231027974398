<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <!-- <div class="row">
                <div class="col-lg-12">
                    <div class="text-left head-txt mb-30">
                        <h2>Group Request</h2>
                    </div>
                </div>
            </div> -->
            <div class="topFilterBlk">
                <div class="filterItemHead">
                    <div class="text-left head-txt">
                        <h2>Group Request</h2>
                    </div>
                </div>
                <div class="filterItemSearch ml-auto">
                    <!-- <div class="searchBar searchbarPage">
                        <label>
                            <input type="text" class="form-control" placeholder="Search Groups...">
                            <span class="fa fa-search"></span>
                        </label>
                    </div> -->
                </div>
            </div>
            <div class="requestList mt-30">
                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/group_request.png">
                            <h2>No Group Requests</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                   
                    <ng-container *ngFor="let bookMeList of bookMeList">


                    <div class="col-md-12 col-lg-6">
                        <div class="requestItem">
                            <div class="requestImg">

                                <a [routerLink]="['/groups/detail',bookMeList.userMetaId]"><img [src]= bookMeList.profileImage></a>
                            </div> 
                            <div class="requestInfo">
                                <a [routerLink]="['/groups/detail',bookMeList.userMetaId]"><h2 class="textEllipsis">{{bookMeList.groupName}}</h2></a>
                                <div class="reqRating">
                                    <!-- <div class="ratingBlk">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <span class="totalReeview">({{bookMeList.totalUserRating}})</span>
                                    </div> -->
                                    <div class="ratingBlk">
                                        <i [class.filled]="bookMeList.rating >= '1'" class="fas fa-star"></i>
                                        <i [class.filled]="bookMeList.rating >= '2'" class="fas fa-star"></i>
                                        <i [class.filled]="bookMeList.rating >= '3'" class="fas fa-star"></i>
                                        <i [class.filled]="bookMeList.rating >= '4'" class="fas fa-star"></i>
                                        <i [class.filled]="bookMeList.rating >= '5'" class="fas fa-star"></i>
                                        <span class="totalReeview">({{bookMeList.totalUserRating}})</span>

                                    </div>
                                </div>
                                <h5 class="textEllipsis">{{bookMeList.totalMember}} Members</h5>
                            </div>
                            <div class="requestAct">
                                <!-- <button data-toggle="tooltip" title="Accept" class="csIcon acceptIcon"><span class="far fa-check-circle"></span></button>
                                <button data-toggle="tooltip" title="Reject" class="csIcon rejectIcon"><span class="far fa-times-circle"></span></button> -->
                                <button data-toggle="tooltip" title="Accept" (click)="acceptrejectReq(1,bookMeList.groupRequestId)" class="csIcon acceptIcon  ml-1"><span class="far fa-check-circle"></span></button>
                                <button data-toggle="tooltip" title="Reject" (click)="acceptrejectReq(2,bookMeList.groupRequestId)" class="csIcon rejectIcon"><span class="far fa-times-circle"></span></button>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="text-center">
                <button class="btn btn-outline-info">Load More</button>
            </div> -->
        </div>
    </section>
</div>
<div class="modal fade csModal" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="profileDoneCnt bookConfirm">
            <svg-icon class="animate__animated animate__tada animate__slow animate__infinite" src="assets/img/fireworks.svg"></svg-icon>
            <h2>Congratulations</h2>
            <!-- <h5>Lorem ipsum dolor sit amet.</h5> -->
            <p>If you want to create group, you can create now or can create later</p>
            <button data-dismiss="modal" type="button" class="btn thm-btn mt-20">OK</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a>
        <button type="button" class="btn thm-btn">Apply</button>
      </div> -->
    </div>
  </div>
</div>
<app-footer-livlouder></app-footer-livlouder>