import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-hirer',
  templateUrl: './footer-hirer.component.html',
  styleUrls: ['./footer-hirer.component.css']
})
export class FooterHirerComponent implements OnInit {
  currentYear = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
    
  }

}
