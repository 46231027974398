<div class="nav-item dropdown notifyDrop">
    <a class="profile-pd" href="#!" (click)="getListInilialList()" id="dropdownMenu2" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="user-menu-icon"><i class="fas fa-bell"></i></span>
        <span class="badge badge-danger badge-pill">{{notifyCount}} </span>
    </a>
    <div class="dropdown-menu dropdown-menu-right profile-drpdwn animate__animated animate__fadeIn"
        aria-labelledby="dropdownMenu2" [ngClass]="{ 'show': isMenuOpen }" (click)="toggleMenu($event)">
        <div class="scrollbar">
            <div *ngIf="notificationsList.length >0" class="force-overflow">
                <div *ngFor="let items of notificationsList;" class="notifications-item"
                    [ngStyle]="{'background': (items.is_read=='0') ? '#faebd7' : '#ffff'}"
                    (click)="viewNotification(items.alertID,items.recipient_user_meta_id,items.receiver_user_type,items)">
                    <div class="ntfctn-cell">
                        <img src="{{items.postedByImage}}" alt="img">
                        <div class="noti-text">
                            <div class="timetext">
                                <h4>{{items.title}}</h4>
                                <span>{{getTimeAgo(items.current_time, items.created_at)}} ago</span>
                                <!-- <span>{{items.created_at| date:'d, MMM, y, h:mm a'}}</span> -->
                            </div>
                            <p>{{items.body}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="recordCount > notificationsList.length" class="mt-2 mb-2 justify-content-center d-flex">
                    <a href="#!" (click)="getLoadmoreList($event)" class="btn thm-btn-sm loadMore_notification">Load
                        More</a>
                </div>
            </div>
            <div *ngIf="notificationsList.length ==0" class="force-overflow">
                <div class="text-center mt-2 ">
                    <img src="assets/img/2Icon.png">
                </div>

                <p class="no-record mt-2 mb-2">Notifications not found!</p>
            </div>
        </div>
    </div>
</div>