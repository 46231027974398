import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/services/auth.service";
import { Settingdata } from "src/app/services/settings";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  isLogin: Boolean;
  terms: SafeResourceUrl;
  selectedUser:any;

  constructor(
    private authService: AuthService,
    private settingdata: Settingdata,
    private toastrService: ToastrNotificationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()==true){
      this.isLogin = true;
      this.selectedUser =  localStorage.getItem("SelectedUser");
    }else{
      this.isLogin = false;
    }
    
    showLoader();
    this.settingdata.getTermsPrivacy().subscribe(
      result => {
        hideLoader();
        
        if (result.data && result.data.terms) {
          this.terms = this.sanitizer.bypassSecurityTrustResourceUrl(result.data.terms);
        } else {
          this.toastrService.showError('No terms URL found.', 'Error');
        }
      },
      err => {
        hideLoader();
        this.toastrService.showError(err.error.message, 'Error');
      }
    );
  }

}
