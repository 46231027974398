import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { GigService } from 'src/app/services/gig-service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { HttpParams } from '@angular/common/http';
import { find } from 'rxjs/operators';
import { SignupService } from 'src/app/services/signup.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;


@Component({
	selector: 'app-discover-hirer',
	templateUrl: './discover-hirer.component.html',
	styleUrls: ['./discover-hirer.component.css']
})

export class DiscoverHirerComponent implements OnInit {
	neha: FormGroup;

	hidghValue: any = '0';
	lowValue: any = '0';
	isLoadMore: boolean = false;
	amountFrom: any;
	amountTo: any;
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([0, 300]),
		sliderControlPrice: new FormControl([this.lowValue, this.hidghValue])
	});
	// minValue: number = 50;
	// maxValue: number = 200;
	options: Options = {
		floor: 0,
		ceil: 300,
		step: 5
	};

	locationoptions = {
		// componentRestrictions: {
		// 	country: ['UK']
		// }

		componentRestrictions: { country: 'UK' }
	};

	// priceSlider: Options = {
	// 	floor: 0,
	// 	ceil: 450,
	// 	step: 5
	// };
	priceSlider: Options = {
		floor: this.lowValue,
		ceil: this.hidghValue,
		step: 1
	};

	dataFound: boolean;
	lists;
	title = 'Hirer - Discover';
	timeout: any;
	ispricesame;

	typeSubtype: any;
	typeIdSelected = [];

	typeListData = [];
	subTypeSelected = [];

	subTypeArray = [];
	subTypeIdSelected = [];

	//added code...
	typeSubtype1: any;
	typeIdSelected1 = [];

	typeListData1 = [];
	subTypeSelected1 = [];

	subTypeArray1 = [];
	subTypeIdSelected1 = [];

	lat: any;
	lng: any;
	location: any;
	suburb: any;
	limit: any = 20;
	offset: any = 0;
	total: any;
	flag: any;
	lat1: number;
	lng1: number;
	priceFilter: string = "";
	rate: string = "";
	hot: string = "";
	isLiked = false;
	minvalue;
	maxvalue;
	setPriceValue: boolean = false;
	isApplyFilter: any = 0;
	// priceFilters:string = "descc";
	// distanceMin:number = 0 ; 
	// distanceMax:number = 300;
	// price:string = ''
	@ViewChild('search') search: ElementRef;
	//@ViewChild("placesRef") placesRef : GooglePlaceDirective;
	locationValue: string;
	constructor(
		private gigService: GigService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private authService: AuthService,
		private signupService: SignupService
	) { }

	ngOnInit(): void {
		$("#allF").addClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');

		this.titleService.setTitle(this.title);
		if (this.authService.isLoggedIn() == true) {

			this.authService.setUserMetaId(1);
		}
		this.neha = new FormGroup({
		})

		$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
		let search_text = $('#searchText').val();
		var obj: any = {}
		obj.search = search_text;
		// this.discover(obj);
		// if(this.authService.isLoggedIn()==false){

		this.getLocation();
		// }

		this.signupService.getTypeSubType().subscribe((data) => {

			this.typeSubtype = data.data;
		});

		this.signupService.getGroupTypeSubType().subscribe((data) => {

			//added code...
			this.typeSubtype1 = data.data;
		});

	}






	callApi(Longitude: number, Latitude: number) {
		const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${Longitude}&lat=${Latitude}`;

		//Call API
	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}
	// get current lat long 
	async getLocation() {
		const isLocationOff = await new Promise((res, rej) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						this.lng = position.coords.longitude;
						this.lat = position.coords.latitude;

						// this.lat1 = this.lat;
						this.lng = this.lng;


						this.callApi(this.lng, this.lat);

						this.reverseGeocode(this.lat, this.lng);

						var obj: any = {}

						obj.currentLat = this.lat;
						obj.currentLong = this.lng;
						this.discover(obj);
						this.getList(this.lat, this.lng);
						res(false);
					},
					function (error) {
						res(true);
						// if (error.code == error.PERMISSION_DENIED) {
						// 	alert(
						// 		"Please enable your location permission if you want to access discover list."
						// 	);
						// }
					}
				);
			} else {
				res(false);
				hideLoader();
				this.toastrService.showError("No support for geolocation", "Error");
			}
		});
		
		if (isLocationOff) {
			this.locationValue = '';
			var obj: any = {}
			obj.currentLat = '';
			obj.currentLong = '';
			this.discover(obj);
		}
	}

	reverseGeocode(lat, long) {
		const geocoder = new google.maps.Geocoder();
		const latlng = { lat: lat, lng: long };
	
		geocoder.geocode({ 'location': latlng }, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					const locationName = results[0].formatted_address;
					// Now you can use the location name as needed
					this.locationValue = locationName;
				} else {
					this.locationValue = '';
				}
			} else {
				console.log('Geocoder failed due to: ' + status);
			}
		});
	}

	getList(lat, lng) {
		this.lat1 = lat;
		this.lng1 = lng;



	}
	public async handleAddressChange(address: any) {
		if (this.authService.isLoggedIn() == true) {
			var metaId = this.authService.getUserMetaId();
		}
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;

		const priceParams = new HttpParams()
			.set('userMetaId', metaId)
			.set('currentLat', this.lat ? this.lat : "")
			.set('currentLong', this.lng ? this.lng : "")

		await this.gigService.livLouderMinMaxPrice(priceParams).subscribe(async(resData) => {
			if (!this.setPriceValue) {
				this.setPriceValue = true;
				this.maxvalue = resData.data.maxPrice;
				this.minvalue = resData.data.minPrice;
				this.setNewCeil(this.maxvalue, this.minvalue);
			}
		}, err => {// Set default value when get error to fetch price data
			this.maxvalue = this.hidghValue;
			this.minvalue = this.lowValue;
			this.setNewCeil(this.maxvalue, this.minvalue);
		});
	}

	sideBarViewOpen() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.add("overflow");
		modalOverlay.classList.add("overlay-show");
		withcalc.classList.add("withcalc");
		//   if(screen.width<=767){
		//       withcalc100.classList.add("withcalc100");
		//   }else{
		//       withcalc.classList.add("withcalc");
		// }applyFilter
	}

	sideBarViewClose() {
		$("#activeicon").removeClass('activeFilterIcon');

		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");


	}



	//Reset
	sideBarReset() {
		$("#activeicon").removeClass('activeFilterIcon');

		location.reload();
	}

	applyFilter123() {
		this.offset = 0;
		this.isLoadMore = false;

		$("#FilterIconActive").addClass('activeFilterIcon');
		$("#activeicon").removeClass('activeFilterIcon');
		$("#allF").removeClass('activeFilterIcon');

		this.applyFilter();
	}

	applyFilter(offset?) {
		this.isApplyFilter = 1;

		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");

		let search_text = $('#searchText').val();

		//added code...
		let location = $('#floatingInput').val();

		var obj: any = {}
		obj.search = search_text;
		let distanceMin = $('#distance_filter b span:first').text();
		let distanceMax = $('#distance_filter b').find('span:eq(1)').text();
		obj.distanceMin = distanceMin;
		obj.distanceMax = distanceMax;

		//added code...
		obj.location = location;

		let priceFrom = $('#priceTo b span:first').text();
		let priceTo = $('#priceTo b').find('span:eq(1)').text();
		
		if ((priceFrom != 0 || priceTo != 1) && !this.isLoadMore) {
			this.amountFrom = priceFrom;
			this.amountTo = priceTo;
			obj.priceFrom = priceFrom;
			obj.priceTo = priceTo;
		}else if (this.isLoadMore && this.amountFrom && this.amountTo) {
			obj.priceFrom = this.amountFrom;
			obj.priceTo = this.amountTo;
		}

		obj.price = this.priceFilter;
		obj.rate = this.rate;
		obj.hot = this.hot;


		//added code...
		let type = this.typeIdSelected.toString();
		let subtype = this.subTypeIdSelected.toString();

		if (type != '' && subtype == '') {

			obj.onlyType = type;
		} else {

			obj.type = type;
			obj.subtype = subtype;
		}

		let gtype = this.typeIdSelected1.toString();
		let gsubType = this.subTypeIdSelected1.toString();

		if (gtype != '' && gsubType == '') {

			obj.onlyGtype = gtype;
		} else {

			obj.gtype = gtype;
			obj.gsubType = gsubType;
		}

		if (this.lat) {
			obj.currentLat = this.lat.toString();
			obj.currentLong = this.lng.toString();
		}


		// if offset is there it meanse load more 
		if (!offset) {
			obj.offset = 0;

		} else {
			obj.offset = this.offset;
		}
		this.discover(obj)
	}

	/**
	 * Discover list
	 */
	discover(obj) {
		
		if (this.authService.isLoggedIn() == true) {

			var metaId = this.authService.getUserMetaId();
		}
		let params = new HttpParams()
			.set('offset', ("offset" in obj) ? obj.offset : "0")
			//added code...
			// .set('offset', ("offset" in obj) ? obj.offset : "20")
			// .set('distanceMax', '')
			.set('search', ("search" in obj) ? obj.search : "")
			.set('distanceMin', ("distanceMin" in obj) ? obj.distanceMin : "")
			.set('distanceMax', ("distanceMax" in obj) ? obj.distanceMax : "")
			.set('userMetaId', metaId)
			.set('rate', '')
			.set('currentLat', ("currentLat" in obj) ? obj.currentLat : "")
			.set('currentLong', ("currentLong" in obj) ? obj.currentLong : "")
			.set('type', ("type" in obj) ? obj.type : "")
			.set('subtype', ("subtype" in obj) ? obj.subtype : "")
			.set('gtype', ("gtype" in obj) ? obj.gtype : "")
			.set('gsubType', ("gsubType" in obj) ? obj.gsubType : "")
			.set('onlyType', ("onlyType" in obj) ? obj.onlyType : "")
			.set('onlyGtype', ("onlyGtype" in obj) ? obj.onlyGtype : "")
			.set('price', ("price" in obj) ? obj.price : "")
			.set('rate', ("rate" in obj) ? obj.rate : "")
			.set('hot', ("hot" in obj) ? obj.hot : "")
			.set('all', '')
			// .set('location', ("location" in obj) ? obj.location : "")
			.set('priceTo', ("priceTo" in obj) ? obj.priceTo : "")
			.set('rating', '')
			.set('priceFrom', ("priceFrom" in obj) ? obj.priceFrom : "")
		showLoader();
		
		if (this.authService.isLoggedIn() == true) {
			
			this.gigService.livLouderFilter(params).subscribe(async(res) => {
				
				// const gig_list = res.data.filter(e => e.price !== res.data[0].price);
				// this.ispricesame = gig_list.length;
				if (res.data.length > 0 && !this.setPriceValue) {
					this.maxvalue = res.maxPrice;
					this.minvalue = res.minPrice;
					this.setNewCeil(this.maxvalue, this.minvalue);
				}
				// else {
				// 	this.maxvalue = this.hidghValue;
				// 	this.minvalue = this.lowValue;
				// 	this.setNewCeil(this.maxvalue, this.minvalue);
				// }

				$('#priceFilter').hide();
				hideLoader();
				if (res.status == 'success') {
					if (res.data.length == 0) {
						this.dataFound = false;
					} else {
						this.dataFound = true;
					}

					this.lists = res.data;
					this.total = res.total;
				}

			}, err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}

			})
		} else {
			hideLoader();
			return false;
			// this.gigService.discoverlist(params).subscribe(res => {
			// 	console.log('aaa bbb', res); return false;

			// 	$('#priceFilter').hide();
			// 	hideLoader();
			// 	this.minvalue = res.minPrice;
			// 	this.maxvalue = res.maxPrice;
			// 	this.setNewCeil(this.maxvalue, this.minvalue)
			// 	if (res.status == 'success') {
			// 		if (res.data.length == 0) {
			// 			this.dataFound = false;
			// 		} else {
			// 			this.dataFound = true;
			// 		}

			// 		this.lists = res.data;
			// 		this.total = res.total;
			// 	}

			// }, err => {
			// 	hideLoader();

			// 	this.toastrService.showError(err.error.message, 'Error');

			// })
		}
		//livlouder
	}
	
	setNewCeil(newCeil: number, newfloor: number): void {
		// Parse new values
		const parsedFloor = typeof newfloor === 'string' ? parseFloat(newfloor) : newfloor;
		const parsedCeil = typeof newCeil === 'string' ? parseFloat(newCeil) : newCeil;

		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, this.priceSlider);
		newOptions.ceil = parsedCeil;
		newOptions.floor = parsedFloor;
		this.priceSlider = newOptions; 
		this.hidghValue = parsedCeil;
	}

	// Method to handle changes in the slider value
	onSliderChange(newValue: number) {
		this.hidghValue = newValue;
	}

	// loadMore() {

	// 	//added code...
	// 	let location = $('#floatingInput').val();

	// 	var obj: any = {}
	// 	this.offset = this.offset + this.limit;
	// 	obj.offset = this.offset;
	// 	let search_text = $('#searchText').val();
	// 	obj.search = search_text;

	// 	//added code...
	// 	obj.location = location;

	// 	//added code...
	// 	let priceFrom = $('#priceTo b span:first').text();
	// 	let priceTo = $('#priceTo b').find('span:eq(1)').text();

	// 	if ((priceFrom != 0) || (priceTo != 450)) {

	// 		obj.priceFrom = priceFrom;
	// 		obj.priceTo = priceTo;
	// 	}

	// 	//added code...
	// 	let type = this.typeIdSelected.toString();
	// 	let subtype = this.subTypeIdSelected.toString();

	// 	if (type != '' && subtype == '') {

	// 		obj.onlyType = type;
	// 	} else {

	// 		obj.type = type;
	// 		obj.subtype = subtype;
	// 	}

	// 	let gtype = this.typeIdSelected1.toString();
	// 	let gsubType = this.subTypeIdSelected1.toString();

	// 	if (gtype != '' && gsubType == '') {

	// 		obj.onlyGtype = gtype;
	// 	} else {

	// 		obj.gtype = gtype;
	// 		obj.gsubType = gsubType;
	// 	}

	// 	if (this.lat) {

	// 		obj.currentLat = this.lat.toString();
	// 		obj.currentLong = this.lng.toString();
	// 	}

	// 	//added code...
	// 	let distanceMin = $('#distance_filter b span:first').text();
	// 	let distanceMax = $('#distance_filter b').find('span:eq(1)').text();
	// 	obj.distanceMin = distanceMin;
	// 	obj.distanceMax = distanceMax;

	// 	//added code...
	// 	obj.price = this.priceFilter;
	// 	obj.rate = this.rate;
	// 	obj.hot = this.hot;

	// 	var metaId = this.authService.getUserMetaId();
	// 	let params = new HttpParams()
	// 		.set('offset', ("offset" in obj) ? obj.offset : "0")
	// 		.set('distanceMax', '')
	// 		.set('search', ("search" in obj) ? obj.search : "")
	// 		.set('distanceMin', ("distanceMin" in obj) ? obj.distanceMin : "")
	// 		.set('distanceMax', ("distanceMax" in obj) ? obj.distanceMax : "")
	// 		.set('userMetaId', metaId)
	// 		.set('currentLat', ("currentLat" in obj) ? obj.currentLat : "")
	// 		.set('currentLong', ("currentLong" in obj) ? obj.currentLong : "")
	// 		.set('type', ("type" in obj) ? obj.type : "")
	// 		.set('subtype', ("subtype" in obj) ? obj.subtype : "")
	// 		.set('gtype', ("gtype" in obj) ? obj.gtype : "")
	// 		.set('gsubType', ("gsubType" in obj) ? obj.gsubType : "")
	// 		.set('onlyType', ("onlyType" in obj) ? obj.onlyType : "")
	// 		.set('onlyGtype', ("onlyGtype" in obj) ? obj.onlyGtype : "")
	// 		.set('price', ("price" in obj) ? obj.price : "")
	// 		.set('rate', ("rate" in obj) ? obj.rate : "")
	// 		.set('hot', ("hot" in obj) ? obj.hot : "")
	// 		.set('all', '')
	// 		.set('location', ("location" in obj) ? obj.location : "")
	// 		.set('priceTo', ("priceTo" in obj) ? obj.priceTo : "")
	// 		.set('priceFrom', ("priceFrom" in obj) ? obj.priceFrom : "")
	// 	showLoader();
	// 	if (this.authService.isLoggedIn() == true) {

	// 		this.gigService.livLouderFilter(params).subscribe(res => {
	// 			// this.minvalue = res.minPrice;

	// 			const gig_list = res.data.filter(e => e.price !== res.data[0].price);
	// 			this.ispricesame = gig_list.length;
	// 			if (this.ispricesame == 0) {

	// 				this.minvalue = 0;
	// 			} else {

	// 				this.minvalue = res.minPrice;
	// 			}
	// 			this.maxvalue = res.maxPrice;
	// 			this.setNewCeil(this.maxvalue, this.minvalue)
	// 			$('#priceFilter').hide();
	// 			hideLoader();
	// 			if (res.status == 'success') {
	// 				if (res.data.length == 0) {
	// 					this.dataFound = false;
	// 				} else {
	// 					this.dataFound = true;
	// 				}

	// 				res.data.forEach(element => {
	// 					this.lists.push(element);
	// 				});
	// 				this.total = res.total;
	// 			}

	// 		}, err => {
	// 			hideLoader();
	// 			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
	// 				this.authService.checkValidToken(err.error);
	// 				//this.toastrService.showError('Session Expired', 'Error');

	// 			} else {
	// 				this.toastrService.showError(err.error.message, 'Error');
	// 			}

	// 		})
	// 	} else {
	// 		this.gigService.discoverlist(params).subscribe(res => {
	// 			this.minvalue = res.minPrice;
	// 			this.maxvalue = res.maxPrice;
	// 			this.setNewCeil(this.maxvalue, this.minvalue)
	// 			$('#priceFilter').hide();
	// 			hideLoader();
	// 			if (res.status == 'success') {
	// 				if (res.data.length == 0) {
	// 					this.dataFound = false;
	// 				} else {
	// 					this.dataFound = true;
	// 				}


	// 				res.data.forEach(element => {
	// 					this.lists.push(element);
	// 				});
	// 				this.total = res.total;

	// 			}

	// 		}, err => {
	// 			hideLoader();

	// 			this.toastrService.showError(err.error.message, 'Error');

	// 		})
	// 	}
	// }

	loadMore() {
		var obj: any = {}
		this.offset = this.offset + this.limit;
		obj.offset = this.offset;

		if (this.lat) {
			obj.currentLat = this.lat.toString();
			obj.currentLong = this.lng.toString();
		}
		this.isLoadMore = true;
		if(this.isApplyFilter == 1) {
			this.applyFilter(1);
		}else {
			this.discover(obj);
		}
	}

	/**
	 * Search discover
	 * @param text 
	 */
	searchDiscover(text) {
		if (text.trim() == '') {
			return false;
		}else {
			this.isLiked = false;
			$("#FilterIconActive").removeClass('activeFilterIcon');
			$("#activeicon").removeClass('activeFilterIcon');
			var obj: any = {}
			obj.search = text.trim();
			obj.currentLat = this.lat;
			obj.currentLong = this.lng
			clearTimeout(this.timeout);
			var $this = this;
			this.timeout = setTimeout(function () {

				$this.discover(obj);

			}, 1000);
		}
	}


	allDiscover() {
		$('#searchText').val('');

		$("#allF").addClass('activeFilterIcon');

		// $("#neha").removeAttr("style");
		$("#priceasc").removeClass('activeFilterIcon');
		$("#pricedesc").removeClass('activeFilterIcon');
		$("#priceF").removeClass('activeFilterIcon');

		$("#rateF").removeClass('activeFilterIcon');
		$("#rateasc").removeClass('activeFilterIcon');
		$("#ratedesc").removeClass('activeFilterIcon');

		$("#hotF").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');


		this.isLiked = false;

		let search_text = $('#searchText').val();
		var obj: any = {}
		obj.search = search_text;

		this.hot = '';
		this.priceFilter = '';
		this.rate = '';
		this.offset = 0;

		this.getLocation();


		// this.discover(obj);
	}

	/**
	* Filter gig according to asc or desc
	* 1 means low to high and 2 high to low
	* @param order 
	*/
	filterPrice(order) {
		if (order == 'asc') {
			$("#allF").removeClass('activeFilterIcon');

			$("#ratedesc").removeClass('activeFilterIcon');
			$("#rateasc").removeClass('activeFilterIcon');
			$("#pricedesc").removeClass('activeFilterIcon');
			$("#priceasc").addClass('activeFilterIcon');
			$("#rateF").removeClass('activeFilterIcon');
			$("#hotF").removeClass('activeFilterIcon');

			$("#priceF").addClass('activeFilterIcon');

		}
		if (order == 'desc') {
			$("#allF").removeClass('activeFilterIcon');

			$("#hotF").removeClass('activeFilterIcon');
			$("#ratedesc").removeClass('activeFilterIcon');
			$("#rateasc").removeClass('activeFilterIcon');
			$("#rateF").removeClass('activeFilterIcon');

			$("#priceasc").removeClass('activeFilterIcon');
			$("#pricedesc").addClass('activeFilterIcon');
			$("#priceF").addClass('activeFilterIcon');



		}
		this.rate = "";
		this.hot = "";
		this.isLiked = false;
		$("#activeicon").addClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');

		this.priceFilter = order;

		this.offset = 0;

		this.applyFilter();

	}

	filterhot(hot) {

		$("#allF").removeClass('activeFilterIcon');

		$("#priceasc").removeClass('activeFilterIcon');
		$("#pricedesc").removeClass('activeFilterIcon');
		$("#priceF").removeClass('activeFilterIcon');

		$("#rateF").removeClass('activeFilterIcon');
		$("#rateasc").removeClass('activeFilterIcon');
		$("#ratedesc").removeClass('activeFilterIcon');

		$("#hotF").addClass('activeFilterIcon');


		this.isLiked = false;

		//$("#activeicon").css("color", '#01d48e');
		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');


		this.hot = hot;

		this.offset = 0;

		this.applyFilter();
	}

	filterRate(order1) {

		// add and remove active and inactive class
		if (order1 == 'asc') {
			$("#allF").removeClass('activeFilterIcon');

			$("#priceasc").removeClass('activeFilterIcon');
			$("#pricedesc").removeClass('activeFilterIcon');
			$("#priceF").removeClass('activeFilterIcon');
			$("#hotF").removeClass('activeFilterIcon');

			$("#ratedesc").removeClass('activeFilterIcon');
			$("#rateF").addClass('activeFilterIcon');
			$("#rateasc").addClass('activeFilterIcon');


		}
		if (order1 == 'desc') {
			$("#allF").removeClass('activeFilterIcon');

			$("#priceasc").removeClass('activeFilterIcon');
			$("#pricedesc").removeClass('activeFilterIcon');
			$("#priceF").removeClass('activeFilterIcon');
			$("#hotF").removeClass('activeFilterIcon');

			$("#rateasc").removeClass('activeFilterIcon');
			$("#ratedesc").addClass('activeFilterIcon');
			$("#rateF").addClass('activeFilterIcon');


		}
		this.priceFilter = "";
		this.hot = "";

		$("#activeicon").addClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');

		// $("#activeicon").css("color", '#01d48e');


		this.rate = order1;

		this.offset = 0;

		this.applyFilter();

	}


	priceToFilter() {


		let search_text = $('#searchText').val();
		var obj: any = {}



		this.discover(obj);

	}

	likedLivlouder() {

		$("#activeicon").removeClass('activeFilterIcon');
		$("#FilterIconActive").removeClass('activeFilterIcon');
		$("#allF").removeClass('activeFilterIcon');

		this.isLiked = true;

		let searchText = $('#searchText').val();
		var paramObj = { 'offset': 0, 'search_text': searchText };
		showLoader();
		this.gigService.likedLivlouder(paramObj).subscribe(res => {
			hideLoader();
			if (res.data_found == false) {
				this.lists = [];
				this.dataFound = false;
				this.total = 0;
			} else {
				this.lists = res.data.livlouder;
				this.dataFound = true;
				this.total = res.data.total_record;
			}
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}

		})
	}
	over() {
		$('#priceFilter').show();
	}

	out() {
		$('#priceFilter').hide();
	}

	over1() {
		$('#priceFilter1').show();
	}

	out1() {
		$('#priceFilter1').hide();
	}


	//Type subtype code here
	onCheckboxChangeRole(type, event) {

		// if (event.target.checked) {
		// 	this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray.push(type);
		// 	this.typeIdSelected.push(type.categoryId);

		// } else {
		// 	for (var i = 0; i < this.typeListData.length; i++) {
		// 		if (this.typeListData[i].categoryId == type.categoryId) {
		// 			this.typeListData.splice(i, 1);


		// 		}
		// 	}

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray.length; i++) {
		// 		if (this.subTypeArray[i] == type.categoryId) {
		// 			this.subTypeArray.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected.splice(itemindex, 1);
		// 	}

		// }

		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.subTypeIdSelected = [];
			this.subTypeSelected = [];
		}
	
		if (event.target.checked) {
			const checkTypeData = this.typeListData.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected = [];
				this.subTypeIdSelected = [];
				this.subTypeSelected = [];
				this.typeListData = [];
				this.typeListData.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.typeIdSelected.indexOf(type.categoryId);
			
			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray = [];
				this.typeIdSelected = [];
				this.subTypeArray.push(type);
				this.typeIdSelected.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}

	}


	removeType(index) {
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected = this.subTypeSelected.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {

				this.subTypeArray[i].subType.forEach(element => {

					if (this.subTypeIdSelected.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected.indexOf(element.categoryId);
						this.subTypeIdSelected.splice(idexitem, 1);

					}
				});
				this.subTypeArray.splice(i, 1);
			}
		}


	}

	onCheckboxChangesubType(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}


	}




	//group type subtype code here...


	onCheckboxChangeRole1(type, event) {

		// if (event.target.checked) {
		// 	this.typeListData1.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray1.push(type);
		// 	this.typeIdSelected1.push(type.categoryId);

		// } else {
		// 	for (var i = 0; i < this.typeListData1.length; i++) {
		// 		if (this.typeListData1[i].categoryId == type.categoryId) {
		// 			this.typeListData1.splice(i, 1);


		// 		}
		// 	}

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray1.length; i++) {
		// 		if (this.subTypeArray1[i] == type.categoryId) {
		// 			this.subTypeArray1.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected1.splice(itemindex, 1);
		// 	}

		// }

		// // if (this.typeListData.length == 0) {
		// //  this.completeProfileForm.patchValue({ typeBlankCheck: "" });
		// // } else {
		// //  this.completeProfileForm.patchValue({ typeBlankCheck: "1" });
		// // }

		if (event.target.checked == false) {
			this.typeIdSelected1 = [];
			this.typeListData1 = [];
			this.subTypeArray1 = [];
			this.subTypeIdSelected1 = [];
			this.subTypeSelected1 = [];
		}

		if (event.target.checked) {
			const checkTypeData = this.typeListData1.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected1 = [];
				this.subTypeSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.typeListData1 = [];
				this.typeListData1.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray1.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.subTypeIdSelected1.indexOf(type.categoryId);

			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeArray1 = [];
				this.typeIdSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.subTypeArray1.push(type);
				this.typeIdSelected1.push(type.categoryId);
				// this.subTypeIdSelected1.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected1.splice(itemindex, 1);
			}
		}
	}

	removeType1(index) {
		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype1 = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype1);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected1 = this.subTypeSelected1.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {

				this.subTypeArray1[i].subType.forEach(element => {

					if (this.subTypeIdSelected1.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected1.indexOf(element.categoryId);
						this.subTypeIdSelected1.splice(idexitem, 1);

					}
				});
				this.subTypeArray1.splice(i, 1);
			}
		}


	}

	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected1.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected1.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		//  this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		//  this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	removeSubType1(index) {
		let selectedSubType1 = this.subTypeSelected1[index].categoryId;
		this.subTypeSelected1.splice(index, 1);
		const itemindex = this.subTypeIdSelected1.indexOf(selectedSubType1);
		if (index > -1) {
			this.subTypeIdSelected1.splice(itemindex, 1);
		}
	}

}


