// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://admin.giglooker.com/api_v1/',
  userImgUrl: 'http://localhost/livloud_admin/uploads/user_avatar/',
  siteUrl: 'https://giglooker.com/',
  notificationKey: 'AAAA_E13OXU:APA91bG6ykePKfOQZCUj_qGcaAmN4h5-MjRBiS-Ct8JjAKAIVOXqTb3g4KPQTa9IMcI4_45LJ7nURBeP9n-sv4fcycLQ3s99mw3_GUyoEV2Q7W_C8lbvhvyXNcmmAwh4d4jt2wcDN_En',
  stripeSkKey: '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',
  striePkKey: 'pk_live_51OdzxTBnRPytgGAGqr5ia86vkqBghJvFPbxjJaXcOu7qok1vmdgpsdJT1a74D2DVbSMN9tTXPY9l2rEZ9aQwTlpS00Pm8hkIAY',
  // googleClientId: '338623771980-crc11off8457o032g0bn3679kl72p2qa.apps.googleusercontent.com',
  googleClientId: '1083631417717-41did78a4ml7v5slb92kgqcuepqciuvd.apps.googleusercontent.com',
  FacebookClientId: '456134318382425',
  storageBucketFile: 'chat_photos/',
  placeApiKey: 'AIzaSyDW3WL-jVP7XiuR8TCoC3QlpVItwJIRs8s',
  firebase: {
    // apiKey: "AIzaSyDDIr95QtMPRl4sOAHwR77JFBq_mCD8iTM",
    // authDomain: "livloud-6de58.firebaseapp.com",
    // databaseURL: "https://livloud-6de58.firebaseio.com",
    // projectId: "livloud-6de58",
    // storageBucket: "livloud-6de58.appspot.com",
    // messagingSenderId: "338623771980",
    // appId: "1:338623771980:web:7976393572dbb330"
    databaseURL: 'https://livloud-411314-default-rtdb.firebaseio.com/',
    projectId: 'livloud-411314',
    apiKey: 'AIzaSyCBXwc_VwX2qfuEK-VfUD9xQaThip94TlE',
    storageBucket: 'livloud-411314.appspot.com',
    authDomain: 'livloud-411314.firebaseapp.com',
    messagingSenderId: '1083631417717',
    measurementId: "G-T8CSNNDSCV",
    appId: "1:1083631417717:web:09ef1c2e66519430bdbb3c"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
