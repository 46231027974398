<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container">
            <div class="gigsList">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <h2>{{bookMeDetail.title}}</h2>
                                </div>
                                <div class="jobOpen">
                                    <label>{{bookMeDetail.type}}</label>
                                </div>
                                <div class="priceBlk">
                                    <p>{{bookMeDetail.currency}} {{bookMeDetail.price}}</p>
                                </div>
                                <p *ngIf="bookMeDetail.isRemote !=1" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>{{bookMeDetail.location}}</span></p>
                                <p *ngIf="bookMeDetail.isRemote ==1" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>Remote</span></p>
                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">Subtype</h2>


                                    <div class="typeBlk">
                                        <ng-container *ngFor="let subtype of subtype">
                                            <div class="typeItem">{{subtype}}</div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>{{bookMeDetail.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{bookMeDetail.endDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{bookMeDetail.time}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="postedByBlk">
                                    <div class="postedByImg">
                                        <a href="javascript:void(0);"><img src="{{bookMeDetail.postedByImage}}"></a>
                                    </div>
                                    <div class="postedByCnt">
                                        <div class="postedBy">
                                            <p>Posted By</p>
                                        </div>
                                        <a href="javascript:void(0);">
                                            <h2>{{bookMeDetail.postedByName}}</h2>
                                        </a>
                                    </div>
                                    <div class="postedTime ml-auto">
                                        <p>{{agoTime}}</p>
                                    </div>
                                </div>
                                <div class="requestAct requestActDetails">
                                    <!-- <button data-toggle="tooltip" title="Accept" class="csIcon acceptIcon"><span data-toggle="modal" data-target="#confirmationModal" class="far fa-check-circle"></span></button>
                                    <button data-toggle="tooltip" title="Reject" class="csIcon rejectIcon"><span class="far fa-times-circle"></span></button> -->

                                    <button data-toggle="tooltip" title="Accept"
                                        (click)="acceptrejectReq(1,bookMeDetail.gigId,metaId)"
                                        class="csIcon acceptIcon  ml-1"><span
                                            class="far fa-check-circle"></span></button>
                                    <button data-toggle="tooltip" title="Reject"
                                        (click)="acceptrejectReq(0,bookMeDetail.gigId,metaId)"
                                        class="csIcon rejectIcon"><span class="far fa-times-circle"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Gig</h2>
                                    <p class="aboutText">{{bookMeDetail.about}}</p>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Must Have</h2>
                                    <ul class="infoList">
                                        <ng-container *ngFor="let must_have of mustHave">
                                            <li>{{must_have}}</li>
                                        </ng-container>
                                        <!-- <li>Two years in playback singing</li>
                                        <li>Over 150 Gigs + Completed</li>
                                        <li>5 Years as teaching Guitar at Music Acadamy</li>
                                        <li>3 Years as lead gutarist at Left Hand God</li> -->
                                    </ul>
                                </div>
                                <!--                                 <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <ul class="nav nav-tabs csTabs sideCsTab" id="myTab" role="tablist">

                                <li class="nav-item active">
                                    <a class="nav-link active" id="csTab2-tab" data-toggle="tab" href="#csTab2"
                                        role="tab" aria-controls="csTab2" aria-selected="false">Questions
                                        ({{qusAns.length}})</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">

                                <div class="tab-pane fade show active" id="csTab2" role="tabpanel"
                                    aria-labelledby="csTab2-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <div class="gigNodata">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/conversation.svg"></svg-icon>
                                                <h2>No Questions Asked Yet</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </div> -->
                                        <div class="">
                                            <div class="replyBox questionBoxView">
                                                <label>Ask your question</label>
                                                <div class="replyInBox">
                                                    <!-- <input type="text" class="form-control" placeholder="Enter Question">
                                                    <button type="button" class="btn iconBtn"><i class="fa fa-paper-plane"></i></button> -->

                                                    <input type="text" id="question1" class="form-control" #question
                                                        placeholder="Enter Question">
                                                    <button type="button" (click)="askQuestion(question.value)"
                                                        class="btn iconBtn"><i class="fa fa-paper-plane"></i></button>

                                                </div>
                                                <!-- <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">

                                            <ng-container *ngFor="let qustAns of qusAns">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{qustAns.postedByImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{qustAns.postedByName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <p>{{qustAns.agoTime}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="questionBox">
                                                            <h2><span>Q)</span>{{qustAns.qus}}</h2>
                                                            <p *ngIf="qustAns.ans"><span>Ans.</span>{{qustAns.ans}}</p>
                                                            <p *ngIf="!qustAns.ans"><span>Ans.</span>
                                                                <i class="noreplay">No Reply Yet</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                    <h2>Report Gig</h2>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="giveReviewBox">
                        <div class="">
                            <div class="form-floating mb-3">
                                <select type="text" class="form-control" id="floatingInput">
                                    <option>Report Title 1</option>
                                    <option>Report Title 2</option>
                                    <option>Report Title 3</option>
                                    <option>Report Title 4</option>
                                </select>
                                <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea rows="4" type="text" class="form-control textArea" id="floatingInput"
                                    placeholder="Write something..."></textarea>
                                <label for="floatingInput">Description <span class="reqField">*</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="makeAnOffer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/make_offer.svg"></svg-icon>
                    <h2>Make An Offer</h2>
                    <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="giveReviewBox">
                        <div class="">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend" style="margin-right: -1px;height: 58px !important;">
                                    <span class="input-group-text" id="basic-addon1">$</span>
                                </div>
                                <div class="form-floating">
                                    <input type="text" class="form-control phone" id="floatingInput"
                                        placeholder="Enter Price">
                                    <label for="floatingInput">Price <span class="reqField">*</span></label>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea rows="4" type="text" class="form-control textArea" id="floatingInput"
                                    placeholder="Write something..."></textarea>
                                <label for="floatingInput">Description <span class="reqField">*</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="profileDoneCnt bookConfirm">
                    <svg-icon class="animate__animated animate__tada animate__slow animate__infinite"
                        src="assets/img/fireworks.svg"></svg-icon>
                    <h2>Congratulations</h2>
                    <!-- <h5>Lorem ipsum dolor sit amet.</h5> -->
                    <p>If you want to create group, you can create now or can create later</p>
                    <button data-dismiss="modal" type="button" class="btn thm-btn mt-20">OK</button>
                </div>
            </div>
            <!-- <div class="modal-footer">
        <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a>
        <button type="button" class="btn thm-btn">Apply</button>
      </div> -->
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>