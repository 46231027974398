import { Component, OnInit } from '@angular/core';

import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { GigService } from 'src/app/services/gig-service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpParams } from '@angular/common/http';
import { SignupService } from 'src/app/services/signup.service';


declare var $: any

@Component({
	selector: 'app-gig-map',
	templateUrl: './gig-map.component.html',
	styleUrls: ['./gig-map.component.css']
})
export class GigMapComponent implements OnInit {
	hidghValue: any = '100000000';
	lowValue: any = '0';
	dateModel;
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([0, 300]),
		sliderControlPrice: new FormControl([this.lowValue, this.hidghValue])
	});

	model;
	endModel;


	options: Options = {
		floor: 0,
		ceil: 300,
		step: 5
	};
	sliderValue;
	sliderEvents: boolean = false;
	sliderDistance: boolean = false;
	// priceSlider: Options = {
	// 	floor: 0,
	// 	ceil: 450,
	// 	step: 5
	// };

	priceSlider: Options = {
		floor: this.lowValue,
		ceil: this.hidghValue,
		step: 5
	};
	locationoptions = {
		// componentRestrictions: {
		// 	country: ['UK']
		// }

		componentRestrictions: { country: 'UK' }
	};


	locations = [];
	lat; //22.7198365;//51.678418;
	lng;//75.9059978; //7.809007;
	// maplat: any  = -24.551592970359344;
	// maplng: any  = 132.08605293441414; 
	suburb;
	location;
	limit: any = 20;
	offset: any = 0;
	priceFilter;
	timeout: any;

	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	typeIdSelected = [];
	subTypeIdSelected = [];




	//added code...
	typeSubtype1: any;
	typeIdSelected1 = [];

	typeListData1 = [];
	subTypeSelected1 = [];

	subTypeArray1 = [];
	subTypeIdSelected1 = [];
	minvalue;
	maxvalue;

	constructor(
		private gigService: GigService,
		private authService: AuthService,
		private signupService: SignupService
	) { }

	ngOnInit(): void {

		this.authService.setUserMetaId(1);
		var metaId = this.authService.getUserMetaId();

		$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
		$('[data-toggle="tooltip"]').tooltip("hide");
		$('[data-toggle=popover]').popover({
			html: true
		});

		// let searchText=$('#search').val();
		// var offset = '0';
		// const params = new HttpParams().set('offset', offset)
		// .set('search_text', searchText);



		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {

				this.lng = position.coords.longitude;
				this.lat = position.coords.latitude;
				this.applyFilter();

			});
		} else {

			console.log("No support for geolocation")
		}

		this.signupService.getTypeSubType().subscribe((data) => {

			this.typeSubtype = data.data;
			this.typeSubtype1 = data.data;



		});

	}

	public handleAddressChange(address: any) {

		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;


	}

	//Price slider event .if slide than price range between 0-450 
	sliderEvent() {
		this.sliderEvents = true;
	}

	distanceSlider() {
		this.sliderDistance = true;
	}

	/**
	 * Apply Filter
	 */
	applyFilter(offset?) {

		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");

		// let searchText = $('#search').val();
		let searchText = '';
		var obj: any = {}
		obj.search = searchText;

		obj.date = $('#startDate').val();
		obj.endDate = $('#endDate').val();

		if (this.sliderDistance) {
			let distanceMin = $('#distance_filter b span:first').text();
			let distanceMax = $('#distance_filter b').find('span:eq(1)').text();
			obj.distanceMin = distanceMin;
			obj.distanceMax = distanceMax;
		}

		//price slider filter apply than this code will execute
		if (this.sliderEvents) {
			let priceFrom = $('#priceTo b span:first').text();
			let priceTo = $('#priceTo b').find('span:eq(1)').text();
			obj.priceFrom = priceFrom;
			obj.priceTo = priceTo;
		}

		obj.price = this.priceFilter;


		//type subtype filter
		let type = this.typeIdSelected.toString();
		let subType = this.subTypeIdSelected.toString();



		if (type != '' && subType == '') {

			obj.onlyType = type;
		} else {

			obj.type = type;
			obj.subtype = subType;
		}

		let grouptype = this.typeIdSelected1.toString();
		let groupsubtype = this.subTypeIdSelected1.toString();

		if (grouptype != '' && groupsubtype == '') {

			obj.onlyGtype = grouptype;
		} else {

			obj.grouptype = grouptype;
			obj.groupsubtype = groupsubtype;
		}



		if (!offset) {
			obj.offset = '0';
		} else {
			obj.offset = this.offset;
		}
		let paramObj = this.createParam(obj);
		this.getList(paramObj);
	}

	allGig() {
		this.sliderDistance = false;
		this.sliderEvents = false;
		$('#startDate').val('');
		$('#endDate').val('');

		this.applyFilter();
	}
	/**
   * Create http param for filter gig
   * @param obj 
   * @returns 
   */
	createParam(obj) {
		var metaId = this.authService.getUserMetaId();

		let params = new HttpParams()
			.set('offset', ("offset" in obj) ? obj.offset : "0")
			.set('distanceMax', '')
			.set('search_text', ("search" in obj) ? obj.search : "")
			.set('distanceMin', ("distanceMin" in obj) ? obj.distanceMin : "")
			.set('distanceMax', ("distanceMax" in obj) ? obj.distanceMax : "")
			.set('currentLat', this.lat)
			.set('currentLong', this.lng)
			.set('metaId', metaId)
			.set('rate', '')
			.set('type', ("type" in obj) ? obj.type : "")
			.set('subtype', ("subtype" in obj) ? obj.subtype : "")


			.set('grouptype', ("grouptype" in obj) ? obj.grouptype : "")
			.set('groupsubtype', ("groupsubtype" in obj) ? obj.groupsubtype : "")

			.set('onlyType', ("onlyType" in obj) ? obj.onlyType : "")
			.set('onlyGtype', ("onlyGtype" in obj) ? obj.onlyGtype : "")

			.set('hot', '')
			.set('price', ("price" in obj) ? obj.price : "")
			.set('all', '')
			.set('location', ("location" in obj) ? obj.location : "")
			.set('priceTo', ("priceTo" in obj) ? obj.priceTo : "")
			.set('rating', '')
			.set('priceFrom', ("priceFrom" in obj) ? obj.priceFrom : "")
			.set('date', ("date" in obj) ? obj.date : "")
			.set('endDate', ("endDate" in obj) ? obj.endDate : "")


		return params;
	}

	/**
	 * Reset filter 
	 */
	sideBarReset() {

		location.reload();
	}


	sideBarViewOpen() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.add("overflow");
		modalOverlay.classList.add("overlay-show");
		withcalc.classList.add("withcalc");

	}


	sideBarViewClose() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");
	}

	setNewCeil(newCeil: number, newfloor: number): void {
		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, this.priceSlider);
		newOptions.ceil = newCeil;
		newOptions.floor = newfloor;
		this.priceSlider = newOptions;
	}
	getList(param) {

		this.gigService.myGigOnMap(param).subscribe(res => {
			
			this.minvalue = res.min_max_price.minPrice;
			this.maxvalue = res.min_max_price.maxPrice;
			this.setNewCeil(this.maxvalue, this.minvalue)
			if (res.data_found == false) {
				this.locations = [];
			} else {

				this.locations = res.data;
			}
		})		
	}

	/**
	 * push data in location array
	 * @param data 
	 */
	getlatLng(data) {
		data.forEach(element => {
			this.locations.push({ "lat": element.latitude, 'lng': element.longitude });
		});


	}

	search() {

		clearTimeout(this.timeout);
		var $this = this;
		this.timeout = setTimeout(function () {

			$this.applyFilter();

		}, 1000);


	}


	//Type subtype code here

	onCheckboxChangeRole(type, event) {

		if (event.target.checked) {
			this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

			this.subTypeArray.push(type);
			this.typeIdSelected.push(type.categoryId);

		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);


				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array 
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}

		}

		// if (this.typeListData.length == 0) {
		// 	this.completeProfileForm.patchValue({ typeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ typeBlankCheck: "1" });
		// }
	}


	removeType(index) {
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected = this.subTypeSelected.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {

				this.subTypeArray[i].subType.forEach(element => {

					if (this.subTypeIdSelected.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected.indexOf(element.categoryId);
						this.subTypeIdSelected.splice(idexitem, 1);

					}
				});
				this.subTypeArray.splice(i, 1);
			}
		}


	}


	onCheckboxChangesubType(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}





	// code for group type filter 

	removeType1(index) {
		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype1 = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype1);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected1 = this.subTypeSelected1.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {

				this.subTypeArray1[i].subType.forEach(element => {

					if (this.subTypeIdSelected1.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected1.indexOf(element.categoryId);
						this.subTypeIdSelected1.splice(idexitem, 1);

					}
				});
				this.subTypeArray1.splice(i, 1);
			}
		}


	}


	onCheckboxChangeRole1(type, event) {



		// if(event.target.checked == false){
		//   // this.subTypeArray=[];
		//   // this.typeIdSelected =[];
		//    this. removeType(type) 

		// }

		if (event.target.checked) {
			this.typeListData1.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

			this.subTypeArray1.push(type);
			this.typeIdSelected1.push(type.categoryId);

		} else {



			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);


				}
			}

			for (var i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i].categoryId == type.categoryId) {
					this.subTypeArray1.splice(i, 1);


				}
			}

			// console.log( 'subcat',this.subTypeArray)

			// this.removeType(type);

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}

			//remove typeid from selected type id array 
			const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected1.splice(itemindex, 1);
			}

		}
	}



	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected1.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected1.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}


	removeSubType1(index) {
		let selectedSubType1 = this.subTypeSelected1[index].categoryId;
		this.subTypeSelected1.splice(index, 1);
		const itemindex = this.subTypeIdSelected1.indexOf(selectedSubType1);
		if (index > -1) {
			this.subTypeIdSelected1.splice(itemindex, 1);
		}
	}

}
