<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container" *ngIf="gigData">
            <div class="gigsList">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <h2>{{gigData.title}}</h2>
                                    <div class="dropdown smallDropdown" *ngIf="gigData.gigStatus == 1">
                                        <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                data-toggle="tooltip" type="button">
                                                <span class="fa fa-ellipsis-v"></span>
                                            </button>
                                        </span>
                                        <div class="dropdown-menu dropdown-right dropIcon"
                                            aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#">Share <i class="fa fa-share-alt"></i></a>
                                            <a class="dropdown-item" (click)="saveGigAsFavorite();"
                                                href="javascript:void(0)">Save <i
                                                    [ngClass]="is_favourite == '1' ? 'fas fa-heart' : 'far fa-heart'"></i></a>
                                            <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                                href="javascript:void(0);">Report <i class="fa fa-flag"></i></a>
                                        </div>
                                    </div>
                                    <!-- Assigned Gig -->
                                    <div class="dropdown smallDropdown"
                                        *ngIf="gigData.gigStatus == 2 && metaId == gigData.assignUserMetaId">
                                        <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <button class="btn iconBtn dropdown-toggle" title="Sort By"
                                                data-toggle="tooltip" type="button">
                                                <span class="fa fa-ellipsis-v"></span>
                                            </button>
                                        </span>
                                        <div *ngIf="isLogin == true" class="dropdown-menu dropdown-right"
                                            aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" routerLink="/myGigAssignedDispute">Raise
                                                Dispute</a>
                                            <a data-toggle="modal" data-target="#cancelGig" class="dropdown-item"
                                                href="javascript:void(0);">Cancel Gig</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="jobOpen">
                                    <label>{{gigData.type}}</label>
                                </div>
                                <div class="priceBlk">
                                    <p>{{gigData.currency}}{{gigData.price}}</p>
                                </div>
                                <p class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                    <span>{{gigData.location}}</span>
                                </p>
                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">Sub Type</h2>
                                    <div class="typeBlk">
                                        <ng-container *ngFor="let subType of subtype">
                                            <div class="typeItem">{{subType}}</div>
                                        </ng-container>

                                    </div>
                                </div>
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>{{gigData.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{gigData.endDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{gigData.time}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="postedByBlk">
                                    <div class="postedByImg">
                                        <a href="javascript:void(0);"><img src="{{gigData.postedByImage}}"></a>
                                    </div>
                                    <div class="postedByCnt">
                                        <div class="postedBy">
                                            <p>Posted By</p>
                                        </div>
                                        <a href="javascript:void(0);">
                                            <h2>{{gigData.postedByName}}</h2>
                                        </a>
                                    </div>
                                    <div class="postedTime ml-auto">
                                        <p>{{agoTime}}</p>
                                    </div>
                                </div>
                                <div class="assgigAct" *ngIf="(myOffer | json) == '{}' && gigData.gigStatus == 1">
                                    <button data-toggle="modal" (click)="checkBankAccount();" data-target="#"
                                        type="button" class="btn thm-btn-sm">Make an Offer</button>
                                </div>
                                <div class="assgigAct" *ngIf="gigData.gigStatus == 2 && gigData.isGigCancel == 0">
                                    <div *ngIf="gigData?.assignUserMetaId == metaId">
                                        <button type="button" (click)="startGig();" class="btn thm-btn-sm">Start
                                            Gig</button>
                                        <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                        <!-- <button data-toggle="modal" data-target="#cancelGig" type="button" class="btn thm-btn-sm btnRed">Cancel Gig</button> -->
                                    </div>
                                </div>
                                <div class="assgigAct" *ngIf="gigData.gigStatus == 3 && gigData.isGigCancel == 0">
                                    <div *ngIf="gigData?.assignUserMetaId == metaId">
                                        <button type="button" (click)="endGig()" class="btn thm-btn-sm">End Gig</button>
                                        <!-- <button data-toggle="modal" data-target="#requestPayment" type="button" class="btn thm-btn-sm">Request Payment</button> -->
                                    </div>
                                </div>
                                <div class="assgigAct" *ngIf="gigData.gigStatus == 4 && gigData.isGigCancel == 0">

                                    <button *ngIf="gigData?.assignUserMetaId == metaId" data-toggle="modal"
                                        (click)="requestPayment()" type="button" class="btn thm-btn-sm">Request
                                        Payment</button>

                                </div>
                                <div class="assgigAct" *ngIf="gigData.isGigCancel == 1">
                                    <button data-toggle="modal" disabled type="button"
                                        class="btn thm-btn-sm btnRed">Canceled</button>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Gig</h2>
                                    <p class="aboutText">{{gigData.about}}</p>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Must Have</h2>
                                    <ul class="infoList" *ngFor="let must_have of mustHave">
                                        <li>{{must_have}}</li>

                                    </ul>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <ng-container *ngFor=" let images of gigData.gig_images">
                                            <div class="log_div gallery-img text-center mt-30">
                                                <div class="addedImg" attr.data-src="{{images.image_url}}">
                                                    <img src="{{images.image_url}}">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <ul class="nav nav-tabs csTabs sideCsTab" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Offers
                                        ({{offers.length}})</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="csTab2-tab" data-toggle="tab" href="#csTab2" role="tab"
                                        aria-controls="csTab2" aria-selected="false">Questions ({{qusAns.length}})</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="csTab1" role="tabpanel"
                                    aria-labelledby="csTab1-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <h2 class="infoHead">Offers <span class="NoCount">(150)</span></h2> -->
                                        <div class="gigNodata" *ngIf="offers.length == 0">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/no_offer.svg"></svg-icon>
                                                <h2>No Offers Yet</h2>
                                                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">
                                            <ng-container *ngFor="let livOffers of offers">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{livOffers.profileImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{livOffers.fullName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <p>{{livOffers.agoTime}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="ratingView ml-auto">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star filled"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="offerActBlk">
                                                            <div class="offerPrice">
                                                                <label>Offer Price</label>
                                                                <h3>${{livOffers.price}}</h3>
                                                            </div>
                                                            <div class=""
                                                                *ngIf="gigData?.assignUserMetaId == livOffers?.userMetaId">
                                                                <div class="jobOpen">
                                                                    <label>Awarded</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="offferText">
                                                            <p [class.show]="show">{{livOffers.comment}}</p>
                                                            <button *ngIf="livOffers.comment.length > 122"
                                                                (click)="show = !show">{{ show ? 'Show less': 'Show
                                                                More' }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>



                                            <!-- <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/6.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$450</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                                        <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="csTab2" role="tabpanel" aria-labelledby="csTab2-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <div class="gigNodata">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/conversation.svg"></svg-icon>
                                                <h2>No Questions Asked Yet</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </div> -->
                                        <div class="">
                                            <div class="replyBox questionBoxView">
                                                <label>Ask your question</label>
                                                <div class="replyInBox">
                                                    <input type="text" id="question1" class="form-control" #question
                                                        placeholder="Enter Question">
                                                    <button type="button" (click)="test()" class="btn iconBtn"><i
                                                            class="fa fa-paper-plane"></i></button>
                                                </div>
                                                <!-- <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">
                                            <ng-container *ngFor="let qustAns of qusAns">
                                                <div class="reviewsItem">
                                                    <div class="reviewsCntBlk">
                                                        <div class="reviewUserImg">
                                                            <a href="javascript:void(0);">
                                                                <img src="{{qustAns.postedByImage}}">
                                                            </a>
                                                        </div>
                                                        <div class="reviewsCnt">
                                                            <a href="javascript:void(0);">
                                                                <h2>{{qustAns.postedByName}}</h2>
                                                            </a>
                                                            <div class="rateTimeMeta">
                                                                <p>{{qustAns.agoTime}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="retDec offersInfo">
                                                        <div class="questionBox">
                                                            <h2><span>Q)</span>{{qustAns.qus}}</h2>
                                                            <p *ngIf="qustAns.ans"><span>Ans.</span> It is a long
                                                                established fact that a reader will be distracted by the
                                                                readable content of a page when looking at its layout.
                                                            </p>
                                                            <p *ngIf="!qustAns.ans"><span>Ans.</span>
                                                                <i class="noreplay">No Reply Yet</i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!-- <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                        <img src="assets/img/3.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Edmund Richards</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span></h2>
                                                        <p><span>Ans.</span> 
                                                            <i class="noreplay">No Reply Yet</i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="reportForm" (ngSubmit)="reportGig();">
            <div class="modal-content">
                <div class="modal-headerIcon">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                        <h2>Report Gig</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="form-floating mb-3">
                                    <select type="text" class="form-control" formControlName="title" id="floatingInput">
                                        <ng-container *ngFor="let title of reportTitle">
                                            <option [value]="title.id">{{title.title}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="reportForm.get('title').touched && reportForm.get('title').invalid">
                                        <span *ngIf="reportForm.get('title').hasError('required')">Title is required
                                            field.</span>

                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" formControlName="description" type="text"
                                        class="form-control textArea" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="reportForm.get('description').touched">

                                        <span *ngIf="reportForm.get('description').hasError('required')">Description is
                                            required field.</span>
                                        <span *ngIf="reportForm.get('description').hasError('maxlength')"> Please enter
                                            no more than 300 characters.</span>
                                        <span *ngIf="reportForm.get('description').hasError('minlength')"> Please enter
                                            at least 5 characters.</span>
                                    </small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade csModal" id="makeAnOffer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form [formGroup]="offerForm" (ngSubmit)="offerGig($event)">
                <div class="modal-headerIcon">
                    <button type="button" class="close" (click)="resetOfferForm()" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/make_offer.svg"></svg-icon>
                        <h2>Make An Offer</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend"
                                        style="margin-right: -1px;height: 58px !important;">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <div class="form-floating">
                                        <input type="text" class="form-control phone" formControlName="offerPrice"
                                            id="floatingInput" placeholder="Enter Price">
                                        <label for="floatingInput">Price <span class="reqField">*</span></label>
                                        <small class="text-danger"
                                            *ngIf="offerForm.get('offerPrice').touched && offerForm.get('offerPrice').invalid">
                                            Price is required field.
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="offerForm.get('offerPrice').hasError('pattern')">
                                            Please enter digits only.
                                        </small>
                                    </div>

                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" type="text" class="form-control textArea"
                                        formControlName="offerDescription" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField"></span></label>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').hasError('maxlength')">
                                        Please enter no more than 300 characters.
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="offerForm.get('offerDescription').hasError('minlength')">
                                        Please enter at least 5 characters.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" (click)="resetOfferForm()">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade csModal" id="addBank" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="bankAccountForm" (ngSubmit)="addBankAccount();">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Bank Acount</h5>
                    <button type="button" class="close" aria-label="Close">
                        <span (click)="resetBankForm();" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="formModal">
                        <div class="formModalBlk">
                            <div class="bankFormBlk">
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountName" class="form-control"
                                        placeholder="Enter Name">
                                    <label for="floatingInput">Name <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountName').touched && bankAccountForm.get('accountName').invalid">
                                        Account name is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="bsdCode" class="form-control"
                                        placeholder="Enter BSB Code">
                                    <label for="floatingInput">BSB Code <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('bsdCode').touched && bankAccountForm.get('bsdCode').invalid">
                                        BSD code is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountNo" class="form-control"
                                        placeholder="Enter Account Number">
                                    <label for="floatingInput">Account Number <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountNo').touched && bankAccountForm.get('accountNo').invalid">
                                        Account no is required field.
                                    </small>
                                </div>
                                <!-- <div class="form-floating mb-3">
                                    <input type="text" formControlName="address" class="form-control" placeholder="Enter Address">
                                    <label for="floatingInput">Registered Address <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="bankAccountForm.get('address').touched && bankAccountForm.get('address').invalid">
                                        Address is required field.
                                    </small>
                                </div> -->
                                <!-- <div class="form-floating mb-3">
                                    <select type="text" class="form-control" id="floatingInput" >
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>Canada</option>
                                        <option>Japan</option>
                                    </select>
                                    <label for="floatingInput">Select Country <span class="reqField">*</span></label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" (click)="resetBankForm();">Close</button>
                    <button type="submit" class="btn thm-btn">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Please add account.
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button> -->
                <!-- <button type="button" (click)="stripeVerifyLink()" class="btn btn-primary">Ok</button> -->
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="requestPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Request Payment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalUserList">
                    <div class="invitedMemberList">
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/1.jpg">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/2.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/3.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/2.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                        <div class="membItem">
                            <div class="membInfoBlk">
                                <div class="membImg">
                                    <img src="assets/img/3.png">
                                </div>
                                <div class="membName">
                                    <h2>John Smith</h2>
                                    <p>Singer</p>
                                </div>
                                <div class="paymetEnter">
                                    <input type="text" placeholder="Enter Payment">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>

<!-- Cancel Gig -->
<div class="modal fade csModal" id="cancelGig" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/cancel_gig.svg"></svg-icon>
                    <h2>Cancel Gig</h2>
                    <p>Are you sure want to cancel the gig ? Your review will get come 0. </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button>
                <button type="button" data-toggle="modal" data-dismiss="modal" data-target="#cancelGigAlert"
                    class="btn thm-btn">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="cancelGigAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/alert.svg"></svg-icon>
                    <h2>Alert</h2>
                    <p>Are you sure want to cancel the gig ?</p>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn thm-btnGray" data-dismiss="modal">No</button> -->
                <!-- <button type="button" (click)="cancelGig();" class="btn thm-btn">Yes</button> -->
            </div>
        </div>
    </div>
</div>


<app-footer-livlouder></app-footer-livlouder>