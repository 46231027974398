<app-header-hirer></app-header-hirer>
<style type="text/css">
    .chat-area {
        text-align: center;
    }

    .chat-area h2 {
        font-size: 25px;
        font-weight: 600;
        margin: 13px 0px;
    }

    .noChatFound.chat-area.chat-side h2 {
        font-size: 20px;
        padding-top: 25px;
    }
    .customModalSize {
        margin-top: 10rem;
        max-width: 600px !important;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="chatPage py-3">
        <div class="container">
            <div class="chatApp">
                <div class="chatMainBlock">
                    <div class="sideHistory">
                        <div class="side-one">
                            <div class="chatHeader">
                                <div class="chatHeaderAvatar">
                                    <div class="chatHeaderAvatarIcon historyHead">
                                        <h2>Messages</h2>
                                    </div>
                                </div>
                            </div>

                            <div class="historySideBar">
                                <!-- <div class="noChatFoundBlock noChatSidebar" *ngIf="chatSideList.length==0">
                                    <div class="noChatFound chat-area chat-side">
                                        <h2>Messages will appear here!</h2>
                                    </div>
                                </div> -->
                                <div class="chatHistoryItem">
                                    <div class="chatHistoryAvatar">
                                        <div class="chatHistoryAvatarIcon">
                                            <img src="{{livID.profileImage}}">
                                        </div>
                                    </div>
                                    <div class="chatHistoryInfo">
                                        <div class="ChatUserName">
                                            <div class="chatName">
                                                <h2 class="name-meta"> {{livID.name}} </h2>
                                            </div>
                                            <div class="chatTime" *ngIf="chatSideList.length!=0"><span
                                                    class="time-meta">{{getTimeAgo(chatSideList.timestamp)}}</span>
                                            </div>
                                        </div>
                                        <div class="chatHistoryMsg" *ngIf="chatSideList.length!=0">
                                            <div class="chatMsg">
                                                <p>{{chatSideList.last_msg}} </p>
                                            </div>
                                            <div class="chatCount">
                                                <span class="count-meta"
                                                    *ngIf="chatSideList.unread_count!='0'">{{chatSideList.unread_count}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="conversation">

                        <div class="chatHeader chatHeaderMsg" *ngIf="chatSideList.length!=0">
                            <div class="chatHeaderAvatar">
                                <div class="chatHeaderAvatarIcon">
                                    <span class="chatBack"><i class="fa fa-arrow-left"></i></span>
                                    <img src="{{livID.profileImage}}">
                                </div>
                                <div class="">
                                    <a href="javascript:void(0);" class="chatHeaderUser">
                                        <h2>{{livID.name}}</h2>
                                        <p>Online</p>
                                    </a>
                                </div>
                            </div>
                            <div class="chatHeaderIcon">
                                <div class="dropdown">
                                    <i class="fa fa-ellipsis-v fa-2x dropdown-toggle" aria-hidden="true"
                                        data-toggle="dropdown" aria-haspopup="true"></i>
                                    <ul class="dropdown-menu animate__animated animate__zoomIn"
                                        aria-labelledby="dropdownMenu1">
                                        <a *ngIf="!blockByMe" class="dropdown-item" href="javascript:void(0);"
                                            (click)="blockConfirm()">Block</a>
                                        <a *ngIf="blockByMe" class="dropdown-item" href="javascript:void(0)"
                                            (click)="unBlockUser()">Unblock</a>
                                        <!-- <a class="dropdown-item" href="javascript:void(0)"><span class="material-icons-outline md-delete_forever"></span> Delete Chat</a> -->
                                        <a data-toggle="modal" data-target="#reportUser" class="dropdown-item"
                                            href="javascript:void(0);">Report</a>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="messageBox" id="conversation">
                            <div class="noChatFoundBlock noChatSidebar" *ngIf="chatSideList.length==0">
                                <!-- <div class="noChatFound chat-area">
                                             <h2>Sorry! No chat record found yet.</h2>
                                        </div> -->
                                <div class="noChatFound chat-area chat-side mt-5 pt-3">
                                    <div>
                                        <img src="assets/img/3Icon.png" />
                                        <h2>No chat found!</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="messageContainer">

                                <div class="message-body" *ngFor="let message of chatList">
                                    <p class="centerTimeShow text-center" *ngIf="message.centerTime">
                                        <span>{{message.centerTime}}</span>
                                    </p>
                                    <div
                                        [ngClass]="{'message-main-sender':message.sender_id == SenderHMId,'message-main-receiver':message.sender_id != SenderHMId}">
                                        <div
                                            [ngClass]="{'sender':message.sender_id == SenderHMId,'receiver':message.sender_id != SenderHMId,'imgSend':message.image=='1'}">
                                            <div class="message-text" *ngIf="message.image=='0'">
                                                {{message.message}}
                                            </div>
                                            <div class="message-text" *ngIf="message.image=='1'">
                                                <img (click)="imagePreviewModal(message.image_url)" height="100px"
                                                    width="100px" src="{{message.image_url}}">
                                            </div>
                                            <span class="message-time">
                                                {{message.timestamp | date:'shortTime'}}
                                                <span class="read-tick"
                                                    [style.color]="message.msg_read_tick ? '#595ca4' : '#b9b8b8' "
                                                    *ngIf="message.sender_id == SenderHMId">
                                                    <fa-icon [icon]="faCheckDouble"></fa-icon>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="chatReply" *ngIf="chatBoxAllow">
                            <!-- <div class="reply-icon reply-emojis">
                                    <i class="fa fa-smile-o fa-2x"></i>
                                </div> -->
                            <div class="reply-main">
                                <div id="ta-frame">
                                    <textarea [(ngModel)]="message" (keyup)="typing()" class="form-control" rows="1"
                                        id="comment"></textarea>
                                </div>
                            </div>
                            <div class="reply-icon reply-recording">
                                <label class="attachFile fileuplodNw">
                                    <input type="file" id="send_image" class="send_image" style="display: none;"
                                        (change)="onSelectedFile($event)">
                                    <input type="hidden">
                                    <i class="fas fa-paperclip fa-rotate-45" aria-hidden="true"></i>
                                </label>
                            </div>
                            <div class="reply-icon reply-send" (click)="sendMsg()">
                                <i class="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="chatReply" *ngIf="!chatBoxAllow">
                            <p class="block-chat-css">{{blockMessage}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="reportUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="reportForm" (ngSubmit)="reportGig();">
            <div class="modal-content">
                <div class="modal-headerIcon">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modalHeadCnt">
                        <svg-icon class="svgIcon" src="assets/img/report_user.svg"></svg-icon>
                        <h2>Report Gig</h2>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                        <div class="giveReviewBox">
                            <div class="">
                                <div class="form-floating mb-3">
                                    <select type="text" class="form-control" formControlName="title" id="floatingInput">
                                        <ng-container *ngFor="let title of reportTitle">
                                            <option [value]="title.id">{{title.title}}</option>
                                        </ng-container>
                                    </select>
                                    <label for="floatingInput">Select Title <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="reportForm.get('title').touched && reportForm.get('title').invalid">
                                        <span *ngIf="reportForm.get('title').hasError('required')">Title is required
                                            field.</span>

                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea rows="4" formControlName="description" type="text"
                                        class="form-control textArea" id="floatingInput"
                                        placeholder="Write something..."></textarea>
                                    <label for="floatingInput">Description <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="reportForm.get('description').touched">

                                        <span *ngIf="reportForm.get('description').hasError('required')">Description is
                                            required field.</span>
                                        <span *ngIf="reportForm.get('description').hasError('maxlength')"> Please enter
                                            no more than 300 characters.</span>
                                        <span *ngIf="reportForm.get('description').hasError('minlength')"> Please enter
                                            at least 5 characters.</span>
                                    </small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                    <button type="submit" class="btn thm-btn">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade csModal" id="imgPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog customModalSize" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <!-- <div class="modal-body"> -->
                <img src="{{previewImage}}">
            <!-- </div>  -->
            <!-- <div class="modal-footer">
            </div> -->
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>