import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { GigService } from 'src/app/services/gig-service';
import { AuthService } from 'src/app/services/auth.service';
import { SignupService } from 'src/app/services/signup.service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any
declare const showLoader: any;
declare const hideLoader: any;
@Component({
  selector: 'app-common-map-route',
  templateUrl: './common-map-route.component.html',
  styleUrls: ['./common-map-route.component.css']
})
export class CommonMapRouteComponent implements OnInit {
  locationoptions = {
    // componentRestrictions: {
    //   country: ['UK']
    // }

    componentRestrictions: { country: 'UK' }
  };
  locations = [];
  lat;//=22.7198365;//51.678418;
  lng;//=75.9059978; //7.809007;
  gigId;
  metaId;
  routeCredencial;
  origin;
  destination;
  gigMapType = 1;
  userType = '';
  timeList = 5000;
  selectedUser:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private gigService: GigService,
    private authService: AuthService,
    private signupService: SignupService,
    private toastrService: ToastrNotificationService

  ) { }

  ngOnInit(): void {

    this.metaId = localStorage.getItem('metaId');
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = currentUser.userTypeList[0].userType;
    this.selectedUser =  localStorage.getItem("SelectedUser");
    this.activatedRoute.paramMap.subscribe((param) => {

      this.gigId = param.get("id"); // used for convert string to number.

    });
    this.getGigDetail();
  }


  getGigDetail() {


    showLoader();
    this.gigService.gigDetail(this.gigId, this.metaId).subscribe(
      (res) => {
        hideLoader();

        this.destination = { lat: parseFloat(res.data.latitude), lng: parseFloat(res.data.longitude) };

        this.lat = res.data.latitude;
        this.lng = res.data.longitude;
        let latlong = {
          "latitude": res.data.latitude,
          'longitude': res.data.longitude,
          'location': res.data.location,
          'title': res.data.title,
          'gigId': res.data.gigId
        };

        this.locations.push(latlong);

        if (res.data.assignUserMetaId != '' && res.data.assignUserMetaId != '0' && (res.data.gigStatus == '2' || res.data.gigStatus == '3')) {

          this.getGigAssignedDetail(res.data.assignUserMetaId);

        } else {

          this.gigMapType = 1;

        }

        if (navigator.geolocation) {

          navigator.geolocation.getCurrentPosition((position) => {

            this.lng = position.coords.longitude;
            this.lat = position.coords.latitude;
          },
            function (error) {
              if (error.code == error.PERMISSION_DENIED) {

                alert('Please enable your location permission if you want to access map.');
              }
            }

          );

        }

        else {

          console.log("No support for geolocation")

        }


      },
      (err) => {
        hideLoader();
        this.authService.checkValidToken(err.error);

        this.toastrService.showError(err.error.message, 'Error');

        // this.authService.logout();

      }


    );
  }

  getGigAssignedDetail(assignedId) {

    this.gigService.getBasicInfo(assignedId).subscribe((res) => {
      if (res.detail.currentLat != '') {
        this.gigMapType = 2;
        this.origin = { lat: parseFloat(res.detail.currentLat), lng: parseFloat(res.detail.currentLong) };
        this.routeCredencial = { origin: this.origin, destination: this.destination };

      } else {

        this.origin = { lat: parseFloat(this.lng), lng: parseFloat(this.lat) };
        this.gigMapType = 1;
      }

    },
      (err) => {

        // this.authService.logout();
        hideLoader();
        this.authService.checkValidToken(err.error);

        this.toastrService.showError(err.error.message, 'Error');

      }
    );
  }



}
