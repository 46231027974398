import {
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	FormArray,
	FormControl,
	FormGroup,
	Validators, AbstractControl, ValidationErrors
} from "@angular/forms";
import {
	HirerProfileService
} from "../../services/hirer-profile.service";
import {
	AuthService
} from "../../services/auth.service";
import {
	SignupService
} from "../../services/signup.service";
import {
	ToastrNotificationService
} from "../../services/toastr-notification.service";
import {
	CommonService
} from "../../services/common.service";
import {
	Router
} from "@angular/router";
import {
	GroupRequest
} from "src/app/services/group-request";

// import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';

declare var $: any;
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-hirer-update-profile",
	templateUrl: "./hirer-update-profile.component.html",
	styleUrls: ["./hirer-update-profile.component.css"]
})

export class HirerUpdateProfileComponent implements OnInit {
	businessType: any;
	basicInfoFrom: FormGroup;
	completeStepTwo: FormGroup;
	socialLinksForm: FormGroup;
	uploadImageForm: FormGroup;
	photoSkip = 1;
	submitStepTwoBtn;
	stepNo = 2;
	userType = 2;
	skipLinks: boolean = true;
	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}
	lat: any;
	lng: any;
	suburb: any;
	profileDone;
	galleryImg = [];
	type = [];
	data: any = {};
	title = "Hirer- Detail";
	openingHour = [];
	image = [];
	videos = [];
	socialMedia = [];
	current_user;
	switched_user;
	metaId;
	location;
	businessType1: any;
	stype = [];
	link = [];
	media = [];
	video;
	linkDyna = [];
	socialtype;
	mediaType = ['facebook', 'twitter', 'instagram'];
	asd1 = [];
	selectedLink;
	allSocialLink = [];
	showAddMoreBtn = true;
	isLinkStatus;
	businessTypeAdded;
	newLink: any = {
		'id': '',
		'type': 'globe',
		'socialLink': '',
		'social_media_type_id': '',
		'image': '',
		'isShow': 'true',
	};
	@ViewChild('imgDiv', {
		static: false,
	})
	d1: ElementRef;
	@ViewChild('uploadLinksBtn') uploadLinksBtn: ElementRef;
	constructor(
		private hirerService: HirerProfileService,
		private authService: AuthService,
		private signupService: SignupService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private commonService: CommonService,
		private fb: FormBuilder,
		private groupRequest: GroupRequest,
		private elementRef: ElementRef,

	) { }

	ngOnInit(): void {
		localStorage.setItem("SelectedUser", "2");

		this.authService.setUserMetaId(this.userType);

		this.metaId = this.authService.getUserMetaId();

		var stepNo = 2;

		this.authService.setUserMetaId(this.userType);

		// this.stepNo = parseInt(stepNo)+1;


		this.signupService.socialType().subscribe(
			(data) => {
				hideLoader();
				data.data.forEach((element) => {
					element.isShow = true;
					this.allSocialLink.push(element);
				});
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);


		this.groupRequest.getMedia(this.metaId, 1).subscribe(res => {
			hideLoader();
			this.media = res.data;
		});

		this.groupRequest.getMedia(this.metaId, 2).subscribe(res => {
			hideLoader();
			this.video = res.data;
		});
		this.hirerService.getBusinessType().subscribe(
			data => {
				this.businessType = data.data;
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
		// this.basicInfoFrom = new FormGroup({
		// 	name: new FormControl(this.data.name, [Validators.required]),
		// 	location: new FormControl(this.data.location, [Validators.required]),
		// 	venue: new FormControl(this.data.name, [Validators.required]),
		// 	businessType: new FormControl(this.businessTypeAdded),

		// 	profileImage: new FormControl(null, [Validators.required])
		// });

		// this.completeStepTwo = new FormGroup({
		// 	about: new FormControl(this.data.about, [
		// 		Validators.maxLength(300),
		// 		Validators.minLength(5)
		// 	]),

		// 	openingHour: new FormArray([
		// 		// new FormControl(null,[Validators.required]),
		// 	])
		// });

		this.hirerService.detail(this.metaId).subscribe(
			res => {



				if (res.detail.social_media.length > 0) {
					this.linkDyna = res.detail.social_media;
					this.linkDyna.forEach((element) => {
						let index = this.allSocialLink.findIndex(
							(x) => x.id == element.social_media_type_id
						);

						if (index != -1) {

							this.allSocialLink[index].isShow = false;
						}
						this.stype.push(element.social_media_type_id);
						this.isLinkStatus = true;

					});

				} else {
					this.isLinkStatus = false;
					this.linkDyna.push(this.newLink);
				}

				this.checkSocialTypeExist();

				this.data = res.detail;
				//   console.log(this.data);

				// if (this.data.venueOpeningHours) {
				// 	this.openingHour = this.data.venueOpeningHours.split(",");
				// }

				if (this.data.venueOpeningHours) {
					this.openingHour = JSON.parse(this.data.venueOpeningHours);
				}

				if (this.data.image_media.count > 0) {
					this.image = this.data.image_media.data;
				}

				if (this.data.video_media.count > 0) {
					this.videos = this.data.video_media.data;
				}

				this.socialMedia = this.data.social_media;
				this.location = this.data.venueLocation;
				// this.lat = this.data.currentLat;
				// this.lng = this.data.currentLong;
				this.lat = this.data.profileLat;
				this.lng = this.data.profileLong;
				this.suburb = this.data.suburb;
				this.businessType1 = this.data.businessId;
				this.businessTypeAdded = (this.data.businessId != 'undefined') ? this.data.businessId : '';
				// this.basicInfoFrom.value.businessType = this.data.businessId;
				this.socialLink(this.linkDyna);
				this.basicInfoFrom = new FormGroup({
					name: new FormControl(this.data.name, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
					location: new FormControl(this.data.venueLocation, [
						Validators.required
					]),
					venue: new FormControl(this.data.name, [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]),
					businessType: new FormControl(this.businessType),
					profileImage: new FormControl(this.data.profileImage, [
						Validators.required
					])
				});
				// alert(this.data.businessId);
				this.basicInfoFrom.get('businessType').setValue(this.data.businessId);
				this.completeStepTwo = new FormGroup({
					about: new FormControl(this.data.about, [
						Validators.maxLength(300),
						Validators.minLength(5),
						this.noWhitespaceValidator
					]),

					openingHour: new FormArray([
						//new FormControl(null,[Validators.required, this.noWhitespaceValidator])
					])
				});

				//console.log(this.openingHour)
				this.patchMustHave(this.openingHour);
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);

		this.uploadImageForm = new FormGroup({
			file: new FormControl(null),
			fileSource: new FormControl(null)
		});

		// this.socialLinksForm = new FormGroup({
		// 	links: new FormArray([new FormControl(null, [this.noWhitespaceValidator])]),
		// 	socialMedia: new FormControl(null)
		// });
		this.socialLinksForm = new FormGroup({
			links: new FormArray([]),
			socialMedia: new FormControl(null)
		});



	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	public handleAddressChange(address: any) {
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		this.location = address.formatted_address;
	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}

	patchMustHave(data) {
		let mustHave = data;
		// console.log('helloneha',mustHave);
		mustHave.forEach((element) => {
			(<FormArray>this.completeStepTwo.get('openingHour')).push(
				new FormControl(element, [Validators.required, this.noWhitespaceValidator])
			);
		});
	}

	handleClick() {
		// Handle the click event logic here
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}

	// ngAfterViewChecked() {
	// 	$("#imageGallery").lightGallery({
	// 		thumbnail: true,
	// 		selector: ".addedImg",
	// 		download: false
	// 	});

	// $("#galleryVideos").lightGallery({
	// 	selector: ".addedImg"
	// });
	// }



	onFileChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.basicInfoFrom.patchValue({
				profileImage: file
			});
		}
	}





	stepOne(event) {

		if (this.basicInfoFrom.value.businessType == null) {
			var type = this.businessType1;

		} else {
			var type = this.basicInfoFrom.value.businessType
		}

		if (this.basicInfoFrom.invalid) {
			this.validateAllFormFields(this.basicInfoFrom);
			return false;
		} else {

			var metaId = this.authService.getUserMetaId();
			const formData = new FormData();
			formData.append("location", this.location);
			formData.append("venueName", this.basicInfoFrom.value.venue);
			formData.append("businessType", type);
			formData.append("metaId", metaId);
			formData.append("onBordingStep", "2");
			formData.append("isPrivate", "2");
			formData.append("userType", "2");
			formData.append("isUpdate", "1");

			formData.append("profileImage", this.basicInfoFrom.value.profileImage);
			formData.append("latitude", this.lat);
			formData.append("longitude", this.lng);
			formData.append("suburb", this.suburb);
			formData.append("name", this.basicInfoFrom.value.name);

			showLoader();
			this.signupService.stepOne(formData).subscribe(data => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");
				this.authService.updateOnBoardingProfileCount(
					2,
					data.data.onBoardingProfileCount
				);

				// console.log('asas asas', data);

				//this.returnData = data;
				//this.filterFunction(data)
				//this.localStorageService.set('userDetail',JSON.stringify())

				var step = this.authService.getHirerBoardingProfileCount();

				this.stepNo = parseInt(step) + 1;
			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}
	}

	addOpeningHour() {
		(<FormArray>this.completeStepTwo.get("openingHour")).push(
			new FormControl(null, [Validators.required, this.noWhitespaceValidator])
		);
	}

	removeOpeningHour(index) {
		(<FormArray>this.completeStepTwo.get("openingHour")).removeAt(index);
	}

	stepTwo() {
		this.submitStepTwoBtn = true;

		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			if (this.completeStepTwo.value.about) {
				formData.append("venueAbout", this.completeStepTwo.value.about);
			}

			formData.append('openingTime', JSON.stringify(this.completeStepTwo.value.openingHour))
			formData.append("userType", "2");
			formData.append("metaId", metaId);
			formData.append("onBordingStep", "3");
			formData.append("isPrivate", "2");

			showLoader();

			this.signupService.stepTwo(formData).subscribe(data => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				this.authService.updateOnBoardingProfileCount(
					2,
					data.data.onBoardingProfileCount
				);

				var step = this.authService.getHirerBoardingProfileCount();

				this.stepNo = parseInt(step) + 1;
			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}
	}

	onUploadFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file
			});

			this.photoSkip = 2;
			this.uploadSingleImg();
		}
	}

	uploadSingleImg() {
		var metaId = this.authService.getUserMetaId();
		const formData = new FormData();
		formData.append("media", this.uploadImageForm.value.fileSource);
		formData.append("userType", "2");
		formData.append("metaId", metaId);
		formData.append("onBordingStep", "4");
		formData.append("mediaType", "1");

		showLoader();
		this.signupService.uploadImage(formData).subscribe(res => {
			hideLoader();

			let imgData = res.mediaData;

			this.galleryImg.push(imgData);
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		});
	}

	// remove video 
	removideo(imgId, index) {
		$(this)
			.parent(".one")
			.remove();
		const formData = new FormData();
		formData.append("mediaId", imgId);
		formData.append("onBordingStep", "4");

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			res => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}
				var metaId = this.authService.getUserMetaId();

				this.groupRequest.getMedia(metaId, 2).subscribe((res) => {
					hideLoader();
					this.video = res.data;
				})
				this.toastService.showSuccess(res.message, "Success");
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}
	/**
	  /**
	   * Remove added image
	   * @param imgId
	   * @param index
	   */
	removeImg(imgId, index) {
		$(this)
			.parent(".one")
			.remove();
		const formData = new FormData();
		formData.append("mediaId", imgId);
		formData.append("onBordingStep", "4");

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			res => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}

				this.groupRequest.getMedia(this.metaId, 1).subscribe(res => {
					hideLoader();
					this.media = res.data;
				});

				this.toastService.showSuccess(res.message, "Success");
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	// prevoius steps 
	previous() {
		showLoader();
		this.stepNo = this.stepNo - 1;
		hideLoader();
		var metaId = this.authService.getUserMetaId();

		this.groupRequest.getMedia(metaId, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;

		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})
	}

	UploadImgStepThree() {
		var metaId = this.authService.getUserMetaId();

		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append("metaId", metaId);
			formData.append("onBordingStep", "4");
			formData.append("type", "2");

			this.signupService.skipPhoto(formData).subscribe(
				data => {
					hideLoader();

					this.toastService.showSuccess(data.message, "Success");

					this.stepNo = 5;
				},
				err => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}

					this.stepNo = 5;
					//this.error = error.message;
				}
			);
		} else {
			this.stepNo = 5;
		}
	}

	onSelectedVideo(event) {

		this.checkSocialTypeExist();

		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, res => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append(
						"authToken",
						this.authService.getAuthorizationToken()
					);
					formData.append("metaId", userMetaId);
					formData.append("userType", "2");
					formData.append("mediaType", "2");
					formData.append("onBordingStep", "4");
					formData.append("media", file);
					formData.append("video_thumb", res.blobImage, "video_thumb.png");

					this.signupService.uploadVideo(formData).subscribe(
						data => {
							hideLoader();
							this.authService.checkValidToken(data);

							if (data.status == "fail") {
								this.toastService.showError(data.message, "Error");
							} else {
								this.toastService.showSuccess(data.message, "Success");

								this.authService.updateOnBoardingProfileCount(
									2,
									data.mediaData.onBoardingProfileCount
								);

								// var step = this.authService.getHirerBoardingProfileCount();

								// this.stepNo = parseInt(step) + 2;
								this.groupRequest.getMedia(userMetaId, 2).subscribe(res => {
									hideLoader();
									this.video = res.data;
								});
							}
						},
						err => {
							hideLoader();

							if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
								this.authService.checkValidToken(err.error);
								this.toastService.showError('Session Expired', 'Error');

							} else {
								this.toastService.showError(err.error.message, 'Error');
							}
						}
					);
				} else {
					hideLoader();
					this.toastService.showError(res.message, "Error");
					//  toastrError(res.message);
				}
			});
		}
	}

	skipPhoto(metaId) {

		showLoader();

		const formData = new FormData();

		formData.append("metaId", metaId);
		formData.append("onBordingStep", "5");
		formData.append("type", "1");

		this.signupService.skipPhoto(formData).subscribe(
			data => {
				hideLoader();

				localStorage.setItem("currentUser", JSON.stringify(data.userDetail));
				this.toastService.showSuccess(data.message, "Success");

				// this.router.navigate(['/createProfileDone']);
				this.details(this.metaId);
				this.router.navigate(["/profileHirer"]);
			},
			err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
				//this.error = error.message;
			}
		);
	}

	/**
	 * skip upload video
	 */
	skipVideo() {
		this.checkSocialTypeExist();

		var metaId = this.authService.getUserMetaId();

		showLoader();

		const formData = new FormData();

		formData.append("metaId", metaId);
		formData.append("onBordingStep", "4");
		formData.append("type", "1");

		this.signupService.skipPhoto(formData).subscribe(
			data => {
				hideLoader();

				this.toastService.showSuccess(data.message, "Success");

				this.stepNo = 6;
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
				//this.error = error.message;
			}
		);
	}

	addLinks() {


		if (this.stype.length != this.linkDyna.length) {
			this.toastService.showError('Please select Social Link type', 'Error');
			return false;
		}

		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');
			return false;
		} else {
			// this.linkDyna = this.linkDyna;
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required, this.noWhitespaceValidator])
			);

			let newLinks: any = {
				'id': '',
				'type': 'globe',
				'socialLink': '',
				'social_media_type_id': '',
				'image': '',
				'isShow': 'true',
			};
			this.linkDyna.push(newLinks);

		}
	}

	removeLinks(index) {

		// console.log('indexCheck1', index);
		// console.log('allSocialLink', this.allSocialLink);
		// console.log('linkDyna', this.linkDyna);
		// console.log('aassaa', this.stype);
		this.stype.splice(index, 1);

		// console.log('aassaa', this.stype);

		let indexCheck = this.allSocialLink.findIndex((x) => x.id == this.linkDyna[index].social_media_type_id);
		// console.log('indexCheck2', indexCheck);


		if (indexCheck != -1) {

			this.allSocialLink[indexCheck].isShow = true;
		}

		// console.log('allSocialLink111', this.allSocialLink);

		this.linkDyna.splice(index, 1);
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index);
		this.checkSocialTypeExist();
	}

	changeLinks(event) {
		var val = (<FormArray>this.socialLinksForm.get("links")).at(0).value;

		if (val) {
			this.skipLinks = false;
		} else {
			this.skipLinks = true;
		}
	}

	// uploadLinks() {

	// 		var metaId = this.authService.getUserMetaId();
	// 		if (this.socialLinksForm.invalid) {
	// 			this.validateAllFormFields(this.socialLinksForm);
	// 			this.toastService.showError('Please Add Social Link', 'Error');

	// 			return false;
	// 		} else {
	// 		var val = ( < FormArray > this.socialLinksForm.get("links")).at(0).value;
	// 		if (!val) {
	// 			this.skipPhoto(metaId);

	// 			return false;
	// 		}


	// 		var linksArray = [];

	// 		var linkSocial = this.socialLinksForm.value.links;
	// 		this.type = this.stype;

	// 		console.log("linkSocial", linkSocial);
	// 		if (linkSocial) {
	// 			for (var i = 0; i < linkSocial.length; i++) {
	// 				if (linkSocial[i]) {
	// 					linksArray.push({
	// 						type: this.type[i],
	// 						link: linkSocial[i]
	// 					});
	// 				}
	// 			}

	// 			if (this.stype.length != this.linkDyna.length) {
	// 				this.toastService.showError('Please select Social Link type', 'Error');
	// 				return false;
	// 			}

	// 			linksArray.forEach(function(value) {
	// 				console.log("value", value.type);

	// 				if (value.type == undefined) {
	// 				  alert("Please select Type");
	// 				  return false;

	// 				}

	// 			  });

	// 			this.socialLinksForm.patchValue({
	// 				socialMedia: linksArray
	// 			});
	// 		}

	// 		showLoader();
	// 		const formData = new FormData();
	// 		formData.append(
	// 			"socialMedia",
	// 			JSON.stringify(this.socialLinksForm.value.socialMedia)
	// 		);

	// 		formData.append("metaId", metaId);
	// 		formData.append("onBordingStep", "5");

	// 		this.signupService.uploadSocialLinks(formData).subscribe(
	// 			data => {
	// 				hideLoader();

	// 				this.toastService.showSuccess(data.message, "Success");

	// 				localStorage.setItem("currentUser", JSON.stringify(data.userDetail));

	// 				this.details(this.metaId);

	// 				this.router.navigate(["/profileHirer"]);
	// 			},
	// 			error => {
	// 				hideLoader();

	// 				console.log(error.error);
	// 				this.toastService.showError(error.error.message, "Error");
	// 				//this.error = error.message;
	// 			}
	// 		);
	// 	}
	// }




	uploadLinks() {
		var metaId = this.authService.getUserMetaId();
		if (this.uploadLinksBtn.nativeElement.textContent == 'Skip') {
			let formData = new FormData();

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '5');
			formData.append('type', this.socialtype);

			this.signupService.skipPhoto(formData).subscribe(
				(data) => {

					hideLoader();
					this.toastService.showSuccess(data.message, 'Success');
					localStorage.setItem(
						'currentUser',
						JSON.stringify(data.userDetail)
					);
					this.router.navigate(["/profileHirer"]);
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}

				}
			);
			return;
		}else if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link ', 'Error');

			return false;
		} else {
			var linksArray = [];
			var linkSocial = this.socialLinksForm.value.links;
			var type = this.stype;

			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({
							link: linkSocial[i],
							type: this.stype[i],
						});
					}
				}

				if (this.stype.length != this.linkDyna.length) {
					this.toastService.showError('Please select Social Link type', 'Error');
					return false;
				}
				this.socialLinksForm.patchValue({
					socialMedia: linksArray,
				});
			}



			showLoader();
			const formData = new FormData();
			formData.append(
				'socialMedia',
				JSON.stringify(this.socialLinksForm.value.socialMedia)
			);

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '5');

			this.signupService.uploadSocialLinks(formData).subscribe(
				(data) => {
					hideLoader();

					this.toastService.showSuccess(data.message, 'Success');

					this.details(this.metaId);
					localStorage.setItem(
						'currentUser',
						JSON.stringify(data.userDetail)
					);
					this.router.navigate(["/profileHirer"]);
				},
				(err) => {
					hideLoader();

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
					//this.error = error.message;
				}
			);
		}
	}

	// details
	details(metaId) {
		this.metaId = this.authService.getUserMetaId();

		this.hirerService.detail(this.metaId).subscribe(
			res => {

				if (res.detail.social_media.length > 0) {
					this.linkDyna = res.detail.social_media;
					this.linkDyna.forEach((element) => {
						let index = this.allSocialLink.findIndex(
							(x) => x.id == element.social_media_type_id
						);

						if (index != -1) {

							this.allSocialLink[index].isShow = false;
						}
						this.stype.push(element.social_media_type_id);

					});

				} else {
					this.linkDyna.push(this.newLink);
				}

				this.checkSocialTypeExist();
				this.data = res.detail;
				if (this.data.venueOpeningHours) {
					this.openingHour = JSON.parse(this.data.venueOpeningHours);
				}

				if (this.data.image_media.count > 0) {
					this.image = this.data.image_media.data;
				}

				if (this.data.video_media.count > 0) {
					this.videos = this.data.video_media.data;
				}

			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	socialLink(data) {
		let link = data;
		if (link.length == 0) {
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required, this.noWhitespaceValidator])
			);
		} else {
			link.forEach((element) => {
				(<FormArray>this.socialLinksForm.get('links')).push(
					new FormControl(element.socialLink, [Validators.required, this.noWhitespaceValidator])
				);
			});
		}
	}

	validateAllFormFields(formGroup: FormGroup) {
		//{1}
		Object.keys(formGroup.controls).forEach(field => {
			//{2}
			const control = formGroup.get(field); //{3}
			if (control instanceof FormControl) {
				//{4}
				control.markAsTouched({
					onlySelf: true
				});
			} else if (control instanceof FormGroup) {
				//{5}
				this.validateAllFormFields(control); //{6}
			}
		});

		return false;
	}

	changeIcon(index, val) {

		var arr = [
			`<i class="fab fa-facebook-square"></i>`,
			`<i class="fab fa-twitter-square"></i>`,
			`<i class="fab fa-instagram"></i>`,
		];


		var oldId = this.linkDyna[val].social_media_type_id;


		this.linkDyna[val].type = this.allSocialLink[index].type;

		this.linkDyna[val].social_media_type_id = this.allSocialLink[index].id;

		this.allSocialLink[index].isShow = false;
		if (oldId != this.allSocialLink[index].id) {

			let indexnew = this.allSocialLink.findIndex((x) => x.id == oldId);

			if (indexnew != -1) {

				this.allSocialLink[indexnew].isShow = true;
			}
		}

		this.stype[val] = this.allSocialLink[index].id;
		this.checkSocialTypeExist();
	}


	checkSocialTypeExist() {

		var asd = this.allSocialLink;
		// console.log('asd', asd); return false;

		this.showAddMoreBtn = false;
		//setTimeout(function () {
		asd.forEach((element) => {
			if (element.isShow == true) {
				this.showAddMoreBtn = true;

			}
		})

	}

	addTypeListData(businessType) {
		businessType.forEach(element => {
			if (this.businessType.includes(element.id)) {
				this.businessType.push({
					id: element.id,
					businessType: element.businessType
				});
			}
			// });
		});
	}
}