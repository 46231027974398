import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignupService {
  serverUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getUrl() {
    return this.serverUrl;
  }

  checkSocialLogin(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    //var body = 'email=myusername@gmail.com&password=Pass123@$';
    return this.http.post<any>(`${this.serverUrl}/service/check_social_signup`, formdata, { headers: httpHeaders })
  }
  //Signup users
  signup(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    //var body = 'email=myusername@gmail.com&password=Pass123@$';
    return this.http.post<any>(`${this.serverUrl}/service/signup`, formdata, { headers: httpHeaders })

  }

  forgotPassword(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    // return this.http.post<any>(`${this.serverUrl}service/forgotPassword`, formdata, { headers: httpHeaders })
    return this.http.post<any>(`${this.serverUrl}/service/forgot_password`, formdata, { headers: httpHeaders })

  }

  socialSignup(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    //var body = 'email=myusername@gmail.com&password=Pass123@$';
    return this.http.post<any>(`${this.serverUrl}/service/social`, formdata, { headers: httpHeaders })

  }

  login(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/service/login`, formdata, { headers: httpHeaders })

  }

  legalInfo(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/service/legal_info`, formdata, { headers: httpHeaders })


  }

  sendOtp(formdata) {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/service/send_otp`, formdata)

  }

  checkOtp(data) {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/service/verifiy_otp`, data)

  }

  getTypeSubType() {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.get<any>(`${this.serverUrl}/category/typeSubtype`, { headers: httpHeaders })
  }

  getGroupTypeSubType() {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.get<any>(`${this.serverUrl}/group/typeSubtype`, { headers: httpHeaders })
  }


  stepOne(formdata) {

    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/basic_info`, formdata, { headers: httpHeaders });
  }

  stepTwo(formdata) {

    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/second_basic_info`, formdata, { headers: httpHeaders });
  }


  uploadImage(formdata) {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/media`, formdata, { headers: httpHeaders });
  }

  uploadVideo(mediaData) {
    return this.http.post<any>(`${this.serverUrl}service/media`, mediaData)
    //.pipe(catchError(this.handleError));
  }

  uploadSocialLinks(formdata) {
    // for(var pair of formdata.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]);
    // }

    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/social_media`, formdata, { headers: httpHeaders })
  }

  skipPhoto(formdata) {

    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/skip`, formdata, { headers: httpHeaders })
  }

  deleteMedia(formData) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}service/delete_media`, formData, { headers: httpHeaders })

    //return this.http.post<any>(`${this.serverUrl.baseUrl}/service/delete_media`,formData, { headers: httpHeaders })

  }

  // Create  Multiple profile for Liveloders 
  createNewProfile(formdata) {
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.post<any>(`${this.serverUrl}/service/livlouder_profile`, formdata, { headers: httpHeaders })

  }
  socialType() {
    const httpHeaders = new HttpHeaders({
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })
    return this.http.get<any>(`${this.serverUrl}/service/social_type`, { headers: httpHeaders })
  }

}
