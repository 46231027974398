import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicInfoPageComponent } from './common/basic-info-page/basic-info-page.component';

import { ContactNumberUpdateComponent } from './hirer/contact-number-update/contact-number-update.component';
import { ContactNumberLivlouderUpdateComponent } from './livlouder/contact-number-livlouder-update/contact-number-livlouder-update.component';

import { FaqPageComponent } from './common/faq-page/faq-page.component';
import { HelpPageComponent } from './common/help-page/help-page.component';

import { GroupSetupComponent } from './common/group-setup/group-setup.component';
import { EditGroupComponent } from './common/edit-group/edit-group.component';

import { HirerCreateProfilePrivateComponent } from './common/hirer-create-profile-private/hirer-create-profile-private.component';
import { HirerUpdateProfilePrivateComponent } from './common/hirer-update-profile-private/hirer-update-profile-private.component';

import { HirerCreateProfileComponent } from './common/hirer-create-profile/hirer-create-profile.component';
import { HirerUpdateProfileComponent } from './common/hirer-update-profile/hirer-update-profile.component';

import { LivlouderCreateProfileComponent } from './common/livlouder-create-profile/livlouder-create-profile.component';
import { LoginComponent } from './common/login/login.component';
import { ProfileCreateDoneComponent } from './common/profile-create-done/profile-create-done.component';
import { SignupComponent } from './common/signup/signup.component';
import { UserTypeComponent } from './common/user-type/user-type.component';
import { ChatHirerComponent } from './hirer/chat-hirer/chat-hirer.component';
import { CreateGigComponent } from './hirer/create-gig/create-gig.component';
import { DiscoverDetailsHirerComponent } from './hirer/discover-details-hirer/discover-details-hirer.component';
import { DiscoverGroupDetailsHirerComponent } from './hirer/discover-group-details-hirer/discover-group-details-hirer.component';
import { DiscoverHirerComponent } from './hirer/discover-hirer/discover-hirer.component';
import { GigPaymentCardComponent } from './hirer/gig-payment-card/gig-payment-card.component';
import { MyGigsConfirmHirerComponent } from './hirer/my-gigs-confirm-hirer/my-gigs-confirm-hirer.component';
import { MyGigsDetailsAssignedHirerComponent } from './hirer/my-gigs-details-assigned-hirer/my-gigs-details-assigned-hirer.component';
import { MyGigsDetailsCompletedHirerComponent } from './hirer/my-gigs-details-completed-hirer/my-gigs-details-completed-hirer.component';
import { MyGigsDetailsHirerComponent } from './hirer/my-gigs-details-hirer/my-gigs-details-hirer.component';
import { MyGigsHirerComponent } from './hirer/my-gigs-hirer/my-gigs-hirer.component';
import { EditGigComponent } from './hirer/edit-gig/edit-gig.component';
import { ProfileHirerComponent } from './hirer/profile-hirer/profile-hirer.component';
import { ProfilePrivateHirerComponent } from './hirer/profile-private-hirer/profile-private-hirer.component';
import { SettingsHirerComponent } from './hirer/settings-hirer/settings-hirer.component';
import { HomeComponent } from './home/home.component';
import { BookMeRequestComponent } from './livlouder/book-me-request/book-me-request.component';
import { ChatLivlouderComponent } from './livlouder/chat-livlouder/chat-livlouder.component';
import { DiscoverDetailsComponent } from './livlouder/discover-details/discover-details.component';
import { DiscoverGroupDetailsComponent } from './livlouder/discover-group-details/discover-group-details.component';
import { DiscoverComponent } from './livlouder/discover/discover.component';
import { DisputeDetailsComponent } from './livlouder/dispute-details/dispute-details.component';
import { GigDetailsComponent } from './livlouder/gig-details/gig-details.component';
import { GigListComponent } from './livlouder/gig-list/gig-list.component';
import { GigMapComponent } from './livlouder/gig-map/gig-map.component';
import { GigMapListComponent } from './livlouder/gig-map-list/gig-map-list.component';
import { GigRequestDetailsComponent } from './livlouder/gig-request-details/gig-request-details.component';
import { GroupDetailsComponent } from './livlouder/group-details/group-details.component';
import { GroupRequestComponent } from './livlouder/group-request/group-request.component';
import { GroupsListComponent } from './livlouder/groups-list/groups-list.component';
import { MyGigAssignDetailsLivlouderComponent } from './livlouder/my-gig-assign-details-livlouder/my-gig-assign-details-livlouder.component';
import { MyGigAssignDisputeDetailsLivlouderComponent } from './livlouder/my-gig-assign-dispute-details-livlouder/my-gig-assign-dispute-details-livlouder.component';
import { MyGigCompletedDetailsLivlouderComponent } from './livlouder/my-gig-completed-details-livlouder/my-gig-completed-details-livlouder.component';
import { MyGigLivlouderComponent } from './livlouder/my-gig-livlouder/my-gig-livlouder.component';
import { ProfileLivlouderComponent } from './livlouder/profile-livlouder/profile-livlouder.component';
import { SettingsComponent } from './livlouder/settings/settings.component';
import {AuthGuard} from './auth.guard';
import { GigMapHirerComponent } from './hirer/gig-map-hirer/gig-map-hirer.component';
import { LivlouderBankSettingComponent } from './livlouder/livlouder-bank-setting/livlouder-bank-setting.component';
import { TrackGigComponent } from './hirer/track-gig/track-gig.component';
import { HirerProfileGuard } from './hirerProfile.guard';
import { StripeSuccessComponent } from './stripe-success/stripe-success.component';
import { CheckHirerGuard } from './auth/check-hirer.guard';
import { CheckLivlouderGuard } from './auth/check-livlouder.guard';
import { LoginProfileComponent } from './common/login-profile/login-profile.component';

import { LivlouderUpdateProfileComponent } from './common/livlouder-update-profile/livlouder-update-profile.component';

import { OpenGigListComponent } from './common/open-gig-list/open-gig-list.component';
import { OpenGigDetailsComponent } from './common/open-gig-details/open-gig-details.component';
import { OpenDiscoverDetailsComponent } from './common/open-discover-details/open-discover-details.component';
import { CommonMapRouteComponent } from './common/common-map-route/common-map-route.component';
// open acesss Discover list 
import { OpenDiscoverListComponent } from './common/open-discover-list/open-discover-list.component';
// import { DisputeDetailsComponent } from './livlouder/dispute-details/dispute-details.component';
import { DisputeChatComponent } from './livlouder/dispute-chat/dispute-chat.component';
import {HirerDisputeChatComponent} from './hirer/hirer-dispute-chat/hirer-dispute-chat.component'
import { PriceSliderDemoComponent } from './common/price-slider-demo/price-slider-demo.component';
import { PostedByDetailsComponent } from './livlouder/posted-by-details/posted-by-details.component';
import { TermsAndConditionsComponent } from './common/terms-and-conditions/terms-and-conditions.component';
import { PrivacyAndPolicyComponent } from './common/privacy-and-policy/privacy-and-policy.component';

// import { LivlouderTermsAndConditionsComponent } from './livlouder/livlouder-terms-and-conditions/livlouder-terms-and-conditions.component';
// import { LivlouderPrivacyAndPolicyComponent } from './livlouder/livlouder-privacy-and-policy/livlouder-privacy-and-policy.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'signup', component: SignupComponent},
  { path: 'termsAndConditions', component: TermsAndConditionsComponent},
  { path: 'privacyAndPolicy', component: PrivacyAndPolicyComponent},
  // { path: 'livlouderTermsAndConditions', component: LivlouderTermsAndConditionsComponent},
  // { path: 'livlouderPrivacyAndPolicy', component: LivlouderPrivacyAndPolicyComponent},
  { path: 'basicInfo',
    component: BasicInfoPageComponent,
    canActivate:[AuthGuard]  
  },

  { path: 'contactNumberUpdate', 
  component: ContactNumberUpdateComponent,
  canActivate:[AuthGuard]  
  },
  { path: 'contactNumberLivlouderUpdate', 
  component: ContactNumberLivlouderUpdateComponent,
  canActivate:[AuthGuard]  
  },

  { path: 'loginProfile', component: LoginProfileComponent,canActivate:[AuthGuard]},
  { path: 'userType', component: UserTypeComponent, canActivate:[AuthGuard] },
  { path: 'createLivlouderProfile', component: LivlouderCreateProfileComponent, canActivate:[AuthGuard]},
  { path: 'createHirerProfile', component: HirerCreateProfileComponent, canActivate:[AuthGuard]},
  { path: 'updateHirerProfile', component: HirerUpdateProfileComponent, canActivate:[AuthGuard]},

  { path: 'createHirerProfilePrivate', component: HirerCreateProfilePrivateComponent, canActivate:[AuthGuard]},

  { path: 'updateHirerProfilePrivate', component: HirerUpdateProfilePrivateComponent, canActivate:[AuthGuard]},

  { path: 'createProfileDone', component: ProfileCreateDoneComponent, canActivate:[AuthGuard]},
  { path: 'faq', component: FaqPageComponent},
  { path: 'help', component: HelpPageComponent},


  { path: 'updateLivlouderProfile', component: LivlouderUpdateProfileComponent, canActivate:[AuthGuard]},

  { path: 'OpenAcessGig', component: OpenGigListComponent},

  { path: 'OpenAcessDiscover', component: OpenDiscoverListComponent},


  { path: 'openAcessGig/details/:id', component: OpenGigDetailsComponent},
  { path: 'openAcessDiscover/details/:id', component: OpenDiscoverDetailsComponent},


  { path: 'myGigsHirer', component: MyGigsHirerComponent, canActivate:[AuthGuard] },
  // { path: 'myGigsHirer', component: MyGigsHirerComponent, canActivate:[AuthGuard,HirerProfileGuard,CheckHirerGuard] },
  { path: 'createGig', component: CreateGigComponent, canActivate:[AuthGuard]},
  { path: 'createGig/:id', component: CreateGigComponent, canActivate:[AuthGuard]},
  { path: 'createGigGroup/:id', component: CreateGigComponent, canActivate:[AuthGuard]},
  { path: 'myGigsHirer/detail/:id', component: MyGigsDetailsHirerComponent},
  { path: 'editGig/:id', component: EditGigComponent, canActivate:[AuthGuard]},
  { path: 'myGigsconfirm', component: MyGigsConfirmHirerComponent},
  { path: 'GigsPayment', component: GigPaymentCardComponent},
  { path: 'myGigsDetailsAssignedHirer', component: MyGigsDetailsAssignedHirerComponent},
  { path: 'myGigsDetailsCompletedHirer', component: MyGigsDetailsCompletedHirerComponent},
  { path: 'discoverHirer', component: DiscoverHirerComponent},
  { path: 'discoverHirer/detail/:id', component: DiscoverDetailsHirerComponent},
  // { path: 'discoverHirer/detail/:id/:usermetaid', component: DiscoverDetailsHirerComponent},


  // { path: 'chatHirer', component: ChatHirerComponent},
  { path: 'chatHirer/:id/:LmetaID', component: ChatHirerComponent},
  { path: 'profileHirer', component: ProfileHirerComponent,canActivate:[AuthGuard,CheckHirerGuard]},
  { path: 'profilePrivateHirer', component: ProfilePrivateHirerComponent},
  { path: 'settingsHirer', component: SettingsHirerComponent,canActivate:[AuthGuard]},
  { path: 'myGigsHirer/map', component: GigMapHirerComponent,canActivate:[AuthGuard,HirerProfileGuard]},
  { path: 'trackLivlouder/:id', component: TrackGigComponent},
  { path: 'bankSettings', component: LivlouderBankSettingComponent,canActivate:[AuthGuard]},


  { path: 'groups/createGroup', component: GroupSetupComponent},
  { path: 'groups/editGroup/:id', component: EditGroupComponent},


  { path: 'discover', component: DiscoverComponent},
  { path: 'discover/details/:id', component: DiscoverDetailsComponent},
  { path: 'discoverGroupDetails', component: DiscoverGroupDetailsComponent},
  { path: 'gigs', component: GigListComponent},
  { path: 'gigsMap', component: GigMapComponent,canActivate:[AuthGuard]},
  { path: 'gigMapList', component: GigMapListComponent,canActivate:[AuthGuard]},
  { path: 'gigs/details/:id', component: GigDetailsComponent},
  { path: 'mygig', component: MyGigLivlouderComponent},
  { path: 'mygig/details/:id', component: GigDetailsComponent},

  // { path: 'chatLivlouder', component: ChatLivlouderComponent},
  { path: 'chatLivlouder/:id/:LmetaID', component: ChatLivlouderComponent,canActivate:[AuthGuard]},
  // { path: 'myGigAssigned/:id/:id', component: MyGigAssignDetailsLivlouderComponent},
  { path: 'myGigAssignedDispute', component: MyGigAssignDisputeDetailsLivlouderComponent},
  { path: 'disputeDetails', component: DisputeDetailsComponent},
  { path: 'myGigCompleted', component: MyGigCompletedDetailsLivlouderComponent},
  { path: 'groups', component: GroupsListComponent},
  { path: 'groups/details/:id', component: GroupDetailsComponent},
  { path: 'groups/detail/:id', component: GroupDetailsComponent},
  { path: 'hirerGroups/details/:id', component: DiscoverGroupDetailsHirerComponent},


  { path: 'discoverGroupDetailsHirer', component: DiscoverGroupDetailsHirerComponent},


  { path: 'ProfileLivlouder', component: ProfileLivlouderComponent,canActivate:[AuthGuard,CheckLivlouderGuard]},
  { path: 'bookRequest', component: BookMeRequestComponent},
  { path: 'gigrequestDetails/:id/:assignedMetaId', component: GigRequestDetailsComponent},
  { path: 'groupRequest', component: GroupRequestComponent,canActivate:[AuthGuard]},
  { path: 'settings', component: SettingsComponent},

  { path: 'mapLiveRoute/:id', component: CommonMapRouteComponent,canActivate:[AuthGuard]},
  { path: 'dispute-chat/:id', component: DisputeChatComponent,canActivate:[AuthGuard]},
  { path: 'dispute-chat-hirer/:id', component: HirerDisputeChatComponent,canActivate:[AuthGuard]},
  { path:'stripe-success', component:StripeSuccessComponent},
  { path:'price', component:PriceSliderDemoComponent},
  { path:'posted-by-profile/:id', component:PostedByDetailsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
