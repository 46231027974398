import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl,
	ValidationErrors } from '@angular/forms';
import { HirerProfileService } from '../../services/hirer-profile.service';
import { AuthService } from '../../services/auth.service';
import { SignupService } from '../../services/signup.service';
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { CommonService } from '../../services/common.service'
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';


declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
	selector: 'app-hirer-create-profile-private',
	templateUrl: './hirer-create-profile-private.component.html',
	styleUrls: ['./hirer-create-profile-private.component.css']
})
export class HirerCreateProfilePrivateComponent implements OnInit {

	businessType: any;
	basicInfoFrom: FormGroup;
	completeStepTwo: FormGroup;
	socialLinksForm: FormGroup;
	uploadImageForm: FormGroup;
	photoSkip = 1;

	stepNo = 2;
	userType = 2;
	skipLinks: boolean = true;

	// options: {
	// 	componentRestrictions: {
	// 		country: ['AU']
	// 	}
	// }

	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}

	lat: any;
	lng: any;
	suburb: any;

	constructor(
		private hirerService: HirerProfileService,
		private authService: AuthService,
		private signupService: SignupService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private commonService: CommonService,
		private firebaseService: FirebaseService


	) { }

	ngOnInit(): void {

		//if user profile is complete than redirect to gigs .
		// return true if prfole is complete otherwise return false
		localStorage.setItem("SelectedUser", '2');

		if (this.authService.getHirerProfile()) {
			this.router.navigate(['/myGigsHirer']);

		}

		var stepNo = this.authService.getHirerBoardingProfileCount();

		this.authService.setUserMetaId(this.userType);

		this.stepNo = 2;//parseInt(stepNo)+1;

		this.hirerService.getBusinessType().subscribe(data => {

			this.businessType = data.data;
		}, err => {

			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})

		this.basicInfoFrom = new FormGroup({
			'name': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
			'about': new FormControl(null, [Validators.maxLength(300), Validators.minLength(5), this.noWhitespaceValidator]),

			'location': new FormControl(null, [Validators.required]),
			//'venue':new FormControl (null, [Validators.required]),


			'profileImage': new FormControl(null, [Validators.required]),

		});

		// this.completeStepTwo = new FormGroup({
		//   'about': new FormControl(null,[Validators.maxLength(300),Validators.minLength(5)]),

		//   'openingHour': new FormArray([
		//     new FormControl(null,[Validators.required]),

		//   ])
		// })

		// this.uploadImageForm = new FormGroup({
		//   'file': new FormControl(null),
		//   "fileSource": new FormControl(null)
		// })


		// this.socialLinksForm = new FormGroup({
		//   'links': new FormArray([
		//     new FormControl(null),

		//   ]),
		//   "socialMedia":new FormControl(null)
		// })


	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	public handleAddressChange(address: any) {
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		//console.log(suburb);


	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}

	onFileChange(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.basicInfoFrom.patchValue({

				'profileImage': file

			});

		}

	}


	stepOne(event) {
		if (this.basicInfoFrom.invalid) {

			this.validateAllFormFields(this.basicInfoFrom);
			return false;

		} else {


			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append('location', this.basicInfoFrom.value.location);
			//	formData.append('venueName', this.basicInfoFrom.value.venue)
			formData.append('businessType', '1');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '2')
			formData.append('isPrivate', '1')
			formData.append('userType', '2')
			formData.append('profileImage', this.basicInfoFrom.value.profileImage)
			formData.append('latitude', this.lat)
			formData.append('longitude', this.lng)
			formData.append('suburb', this.suburb)
			formData.append('name', this.basicInfoFrom.value.name)
			if (this.basicInfoFrom.value.about) {
				formData.append('about', this.basicInfoFrom.value.about)

			}

			showLoader();
			this.signupService.stepOne(formData).subscribe((data) => {

				hideLoader();
				this.toastService.showSuccess(data.message, "Success");
				this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)
				localStorage.setItem('currentUser', JSON.stringify(data.userDetail));


				let index = data.userDetail.userTypeList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')),
					hirerUserSignupObj = {

						"online": 1,
						"token": localStorage.getItem('device-token'),
						"type": "2",
						"user_id": data.userDetail.userId,
						"name": data.userDetail.userTypeList[index].fullName,
						"profile_image": data.userDetail.userTypeList[index].profileImage,
						"last_seen": ""
					},

					hirerUserMetaSignupObj = {

						"user_id": data.userDetail.userId,
						"typing": 0,
						"name": data.userDetail.userTypeList[index].fullName,
						"profile_image": data.userDetail.userTypeList[index].profileImage,
						"online": 1,
						"user_type": 2
					};

				this.firebaseService.userHirerPvAddFB(data.userDetail.userId, hirerUserSignupObj);
				this.firebaseService.userMetaAddFB(data.userDetail.userId, hirerUserMetaSignupObj);

				this.router.navigate(['/createProfileDone']);
			}, err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}

				// this.toastService.showError(error.error.message, "Error");
				//this.error = error.message;
			});
		}
	}

	addOpeningHour() {
		(<FormArray>this.completeStepTwo.get('openingHour')).push(new FormControl(null, [Validators.required]));

	}

	removeOpeningHour(index) {
		(<FormArray>this.completeStepTwo.get('openingHour')).removeAt(index)

	}

	stepTwo() {

		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append('venueAbout', this.completeStepTwo.value.about);
			formData.append('openingTime', JSON.stringify(this.completeStepTwo.value.openingHour))
			formData.append('userType', '2');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '3')
			formData.append('isPrivate', '1')

			showLoader();

			this.signupService.stepTwo(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)

				var step = this.authService.getHirerBoardingProfileCount();
				this.stepNo = parseInt(step) + 1;

			}, err => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}



	}

	onUploadFile(event) {
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file
			});

			this.photoSkip = 2;

		}
	}

	UploadImgStepThree() {
		var metaId = this.authService.getUserMetaId();
		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append('metaId', metaId)
			formData.append('onBordingStep', '4')
			formData.append('type', '2')
			this.signupService.skipPhoto(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

				//    var step = this.authService.getOnBoardingProfileCount();
				//    console.log(step);
				//    this.stepNo = parseInt(step)+1;
				this.stepNo = 5;
			}, err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				// this.toastService.showError(error.error.message, "Error");
				this.stepNo = 5;
				//this.error = error.message;
			});
		} else {




			const formData = new FormData();
			formData.append('media', this.uploadImageForm.value.fileSource);
			formData.append('userType', '2');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '4')
			formData.append('mediaType', '1')


			//console.log(JSON.stringify(mediaType));
			showLoader();
			this.signupService.uploadImage(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

				//    var step = this.authService.getOnBoardingProfileCount();
				//    console.log(step);
				//    this.stepNo = parseInt(step)+1;
				this.stepNo = 5;
			});
		}
	}



	onSelectedVideo(event) {
		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append('authToken', this.authService.getAuthorizationToken());
					formData.append('metaId', userMetaId);
					formData.append('userType', '2');
					formData.append('mediaType', '2');
					formData.append('onBordingStep', '4')
					formData.append('media', file);
					formData.append('video_thumb', res.blobImage, 'video_thumb.png');

					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();
						this.authService.checkValidToken(data);

						if (data.status == 'fail') {
							this.toastService.showError(data.message, 'Error');
						} else {
							this.toastService.showSuccess(data.message, 'Success');

							this.authService.updateOnBoardingProfileCount(2, data.mediaData.onBoardingProfileCount)

							var step = this.authService.getHirerBoardingProfileCount();
							this.stepNo = parseInt(step) + 2;

							//this.stepNo = 6; 
							//this.getMediaList();
						}
					}, error => {
						hideLoader();
						this.toastService.showError(error.error.message, "Error");
					});
				} else {
					hideLoader();
					this.toastService.showError(res.message, 'Error');
					//  toastrError(res.message);
				}
			});

		}
	}



	addLinks() {
		(<FormArray>this.socialLinksForm.get('links')).push(new FormControl(null, [Validators.required]));
	}

	removeLinks(index) {
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index)
	}

	changeLinks(event) {
		var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;

		if (val) {
			this.skipLinks = false;
		} else {
			this.skipLinks = true;
		}



	}

	uploadLinks() {

		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm)
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();
			var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;
			if (!val) {
				this.skipPhoto(metaId);

				return false;
			}

			var linksArray = [];


			var linkSocial = this.socialLinksForm.value.links
			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({ type: 1, link: linkSocial[i] });
					}
				}


				this.socialLinksForm.patchValue({
					socialMedia: linksArray
				});

			}


			showLoader();
			const formData = new FormData();
			formData.append('socialMedia', JSON.stringify(this.socialLinksForm.value.socialMedia))

			formData.append('metaId', metaId)
			formData.append('onBordingStep', '5')

			this.signupService.uploadSocialLinks(formData).subscribe((data) => {

				hideLoader();
				this.toastService.showSuccess(data.message, "Success");
				localStorage.setItem('currentUser', JSON.stringify(data.userDetail));

				// console.log('testit',data.userDetail);
				this.router.navigate(['/createProfileDone']);
			}, error => {
				hideLoader();
				this.toastService.showError(error.error.message, "Error");
				//this.error = error.message;
			});
		}
	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});

		return false;
	}


	skipPhoto(metaId) {

		showLoader();

		const formData = new FormData();

		formData.append('metaId', metaId)
		formData.append('onBordingStep', '5')
		formData.append('type', '1')
		this.signupService.skipPhoto(formData).subscribe((data) => {

			hideLoader();
			this.toastService.showSuccess(data.message, "Success");

			//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)

			//    var step = this.authService.getOnBoardingProfileCount();
			//    console.log(step);
			//    this.stepNo = parseInt(step)+1;
			this.router.navigate(['/createProfileDone']);
		}, error => {
			hideLoader();
			this.toastService.showError(error.error.message, "Error");
			this.stepNo = 5;
			//this.error = error.message;
		});

	}

}
