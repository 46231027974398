import {
	AfterViewChecked,
	AfterViewInit,
	ViewChild,
	Component,
	OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { BrowserModule, Title } from "@angular/platform-browser";
import { StripeService, StripeCardComponent } from "ngx-stripe";

import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { PaymentService } from "src/app/services/payment.service";
import {
	StripeCardElementOptions,
	StripeElementsOptions,
} from "@stripe/stripe-js";
declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-my-gigs-details-hirer",
	templateUrl: "./my-gigs-details-hirer.component.html",
	styleUrls: ["./my-gigs-details-hirer.component.css"],
})
export class MyGigsDetailsHirerComponent
	implements OnInit, AfterViewChecked {
	@ViewChild(StripeCardComponent) card: StripeCardComponent;
	//project = {duration: 0};
	duration = 0;
	forgotPwdForm: FormGroup;
	isQuestion: any = localStorage.getItem("isQuestion") ? true : false; // Initialize with a default value to active Offers/Questions section
	show = false;
	paymentForm: FormGroup;
	stripeCardValid: boolean = false;
	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: "#666EE8",
				color: "#31325F",
				fontWeight: "300",
				fontSize: "15px",
				"::placeholder": {
					color: "#ccc",
				},
			},
		},
	};
	elementsOptions: StripeElementsOptions = {
		locale: "en",
	};

	public show_dialog: boolean = false;
	gigData: any = {};
	mustHave: any;
	offers: any = [];
	qusAns: any = [];
	gigId;
	metaId;
	msgText = [];
	currentRate = 1;
	neha;
	livloudInfo;
	disputeClosed: any = 0;
	stripAccount;
	payTip;
	rating;
	equalDate;
	isTrack = false;
	disputeMsg;
	disputeBtn = "Raise dispute";
	title = "Hirer- Gig Detail";
	subtype: any = [];
	cardLists = [];
	accountDetails;
	source;
	imgCheck = [];
	LivlouderName: any = '';
	hirerName: any = '';

	get validForm() {
		return this.paymentForm.valid && this.stripeCardValid;
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private gigService: GigService,
		private authService: AuthService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private stripeService: StripeService,
		private fb: FormBuilder,
		private payService: PaymentService,
		private router: Router
	) { }


	ngOnInit(): void {
		this.paymentForm = this.fb.group({
			name: ["", [Validators.required]],
		});


		this.titleService.setTitle(this.title);
		this.authService.setUserMetaId(2);


		var metaId = this.authService.getUserMetaId();

		this.activatedRoute.paramMap.subscribe((param) => {
			this.gigId = param.get("id"); // + used for convert string to number.
			this.metaId = metaId;

			this.getGigDetail();
		});

		this.forgotPwdForm = new FormGroup({
			'comments': new FormControl(null, [Validators.required]),


		})
	}

	// ngAfterViewInit() { }

	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false,
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg",
		});
	}

	getGigDetail() {
		showLoader();
		this.gigService.gigDetail(this.gigId, this.metaId).subscribe(
			(res) => {
				// console.log('res', res.data.gig_images);
				this.imgCheck = res.data.gig_images;
				this.disputeBtn = "Raise dispute";
				this.disputeClosed = res.data.isClosed;
				this.LivlouderName = res.data.assignedLivlouderName;
				this.hirerName = res.data.postedByName;
				// this.disputeClosed = res.data.favourOfUserType;
				if (res.data.isClosed == 0 || res.data.isClosed == 1) {
					this.disputeBtn = "View dispute";
					if (res.data.isClosed == 1) {
						this.disputeMsg = "Dispute is resolved in favour of " + res.data.in_favour_name;
					}
				}

				// console.log(res.data.startDate.toString())
				hideLoader();
				// this.trackTime(res.data.startDate.toString(),res.data.endDate.toString())
				// console.log("\res.data.startDate"\)

				// "+check.year+"
				// console.log(`\"${res.date.startDate}\"`);

				var a = "'" + res.data.startDate + "'";
				var b = res.data.startDate;


				// condition remainning 
				this.trackTime(a, b)





				this.filterData(res);
				this.cardList;
			},
			(err) => {
				hideLoader()
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {

					if (err.error.status == 'fail' && err.error.message == 'Gig not found') {
						// this.toastrService.showError(err.error.message, 'Error');
						this.router.navigate(["/myGigsHirer"]);


					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			}
		);
	}

	toggle() {
		this.show_dialog = !this.show_dialog;
	}

	filterData(res) {
		this.gigData = res.data;
		// console.log('gigData',this.gigData.gig_images);
		this.offers = [];
		var startDate = this.gigData.startDate.split(" ");
		var endDate = this.gigData.endDate.split(" ");
		// alert(startDate[0]);
		// alert(endDate[0]);

		if (startDate[0] == endDate[0]) {
			this.equalDate = true;
			// alert('hiii');

		} else {
			this.equalDate = false;

			// alert('hello');
		}
		this.mustHave = JSON.parse(res.data.mustHave);


		this.gigData.offers.forEach(element => {
			this.offers.push(element);


		});

		this.gigData.groupOffers.forEach(element => {
			this.offers.push(element);


		});


		this.offers.forEach((data) => {
			data.agoTime = this.diffHours(
				data.posted_time,
				this.gigData.current_time
			);
		});

		// console.log('offers', this.offers);



		if (this.gigData.subtype != null) {

			this.subtype = this.gigData.subtype.split(",");
		}



		this.qusAns = this.gigData.qusAns;
		this.qusAns.forEach((data) => {
			data.agoTime = this.diffHours(
				data.posted_time,
				this.gigData.current_time
			);
		});
	}

	deleteGigPopup() {
		$("#confirmModal").modal("show");
	}

	giveTipPopup() {

		this.cardList();
		$("#addCard").modal("show");
	}
	addBankPopup() {
		$("#giveTip").modal("hide");
		$("#giveTip1").modal("show");
	}

	acceptOfferPopup(gigOffer) {
		this.neha = gigOffer;
		$("#acceptModel").modal("show");
	}

	/**
	 *
	 * @param gigId
	 */
	deleteGig(gigId) {
		const formData = new FormData();
		formData.append("gigId", gigId);

		if (gigId) {
			showLoader();
			this.gigService.deleteGig(formData).subscribe(
				(res) => {
					$("#confirmModal").modal("hide");
					hideLoader();
					this.toastrService.showSuccess(res.message, "Success");
					this.router.navigate(["/myGigsHirer"]);
				},
				(err) => {
					$("#confirmModal").modal("hide");

					hideLoader();

					this.toastrService.showSuccess(err.error.message, "Error");
					this.router.navigate(["/myGigsHirer"]);
				}
			);
		}
	}

	addCard(tokenId, customerId) {
		var formData = new FormData();
		formData.append("sourceId", tokenId);
		formData.append("stripeCustomerId", this.payService.getStripeCusID());

		this.payService.addCard(formData).subscribe(
			(res) => {
				hideLoader();
				this.cardList();
				if (res.status == 'fail') {
					this.toastrService.showError(res.message, 'Error');
				}else {
					this.toastrService.showSuccess(res.message,'Success');
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	cardList() {
		let stripeCustId = this.payService.getStripeCusID();
		showLoader();
		this.payService.getCardList(stripeCustId).subscribe(
			(res) => {
				hideLoader();
				this.cardLists = res.data;
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	replyAns(text, qusId) {
		if (text) {
			var formData = new FormData();
			formData.append("id", qusId);
			formData.append("answer", text);

			this.gigService.replyAns(formData).subscribe(
				(res) => {
					this.getGigDetail();
					this.msgText = [];
				},
				(err) => {
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	enableDisableBtn(i, event) {
		this.msgText[i] = event.target.value;
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;

		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs(date2 - date1);
		var diffHr = difference / 60 / 60;
		var daysDiff = difference / 60 / 60 / 24;
		if (daysDiff < 1) {
			if (diffHr < 1) {
				difference = difference / 60;
				var hourDiff = Math.floor(difference) + " Min Ago";
			} else {
				difference = difference / 60 / 60;
				var hourDiff = Math.floor(difference) + " Hour Ago";
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + " Days Ago";
		}

		return hourDiff;
	}

	trackTime(a, b) {
		var date1, date2;
		date1 = new Date();
		date2 = new Date(b);

		// get total seconds between two dates
		var seconds = Math.abs(date1 - date2) / 1000;
		var min = seconds / 60;
		// alert(min)


		if (min >= 60) {
			this.isTrack = false;
			// alert(this.isTrack);

		} else {
			this.isTrack = true;
		}

	}

	navigateRoute(data) {
		$("#acceptModel").modal("hide");

		if (data) {
			this.router.navigate(["myGigsconfirm"], {
				state: { data: data, gigId: this.gigId },
			});

			localStorage.removeItem("stateData");
			localStorage.removeItem("stateGigId");

			localStorage.setItem("stateData", JSON.stringify(data));

			localStorage.setItem("stateGigId", this.gigId);
		} else {
			this.toastrService.showSuccess("Something Went Wrong", "Error");
		}
	}
	onChange({ type, event }) {
		if (type === "change") {
			this.stripeCardValid = event.complete;
		}
	}
	resetCardForm() {

		this.card.element.clear();
	}

	onKeyPress(event: KeyboardEvent) {
		// if (event.key === 'e') {
		// 	event.preventDefault();
		// }

		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
		event.preventDefault();
		return;
		}
		
		const newValue = inputElement.value + event.key;
		
		if ((event.key != '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) || (inputElement.value.includes('.') && inputElement.value.length == 9) || (event.key == '.' && inputElement.value.length == 9)) {
			event.preventDefault();
			return;
		}
		
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
			return;
		}
	}

	buy() {
		showLoader();

		this.stripeService
			.createToken(this.card.element, { name: this.paymentForm.value.name })
			.subscribe((result) => {
				if (result.token) {
					this.resetCardForm();

					this.cardList();

					this.addCard(result.token.id, result.token.card.id);

					this.source = result.token.id;

				} else if (result.error) {
					hideLoader();
					this.toastrService.showError(result.error.message, "Error");
				}
			});
	}

	realsePayment() {

		var formData = new FormData();
		formData.append("gigStatus", '6');
		formData.append("gigId", this.gigId);
		formData.append("stripeAccountId", this.payService.getStripeAccountID());


		formData.append("amount", this.gigData.price);
		formData.append("livlouderMetaId", this.gigData.assignUserMetaId);

		showLoader();
		this.gigService.realsePayment(formData).subscribe(
			(res) => {
				hideLoader();
				this.toastrService.showSuccess('Payment Release Sucessfully', "Success");
				$('#releasePayment').modal('hide');
				this.getGigDetail()


			},
			(err) => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}        //$('#releasePayment').modal('hide');

			}
		);
	}


	// Give tip 
	giveTip() {

		var tipAmount = $('#tipamount').val();

		if (tipAmount == "" && this.duration == 0) {
			this.toastrService.showError("Please select amount first", "Error");
			return false;

		}

		if (tipAmount != "" && this.duration != 0) {
			this.toastrService.showError("Please select only one amount", "Error");
			return false;

		}
		if (Number(tipAmount) > 999999.99) {
			this.toastrService.showError("Amount should be less than or equal to 999999.99", "Error");
			return false;
		}

		if (tipAmount != "") {
			// if (tipAmount < 1) {
			// 	this.toastrService.showError("Tip amount should greater or equal to $1", "Error");
			// 	return false;
	
			// }else {
				this.payTip = tipAmount;
			// }
		}
		if (this.duration != 0) {
			this.payTip = this.duration;

		}

		var formData = new FormData();
		formData.append("gigId", this.gigId);
		formData.append("source", this.source);
		formData.append("hirerMetaId", this.metaId);
		formData.append("amount", this.payTip);
		formData.append("livlouderMetaId", this.gigData.assignUserMetaId);

		showLoader();
		this.gigService.giveTip(formData).subscribe(
			(res) => {
				hideLoader();
				this.toastrService.showSuccess(res.message, "Success");
				$('#giveTip1').modal('hide');
				this.getGigDetail()


			},
			(err) => {
				hideLoader();
				this.duration = 0;
				var tipAmount = $('#tipamount').val('');
				this.toastrService.showError(err.error.message, "Error");
				//$('#giveTip1').modal('hide');

			}
		);
	}

	reviewSubmit() {
		// if (!this.forgotPwdForm.valid) {
		// 	this.validateAllFormFields(this.forgotPwdForm);
		// 	//this.toastr.remove()

		// 	this.toastrService.showError("Please fill required field before proceeding", 'Error');
		// 	return false;
		// }
		
		$("#myApplyId").prop('disabled', true);
		this.rating = this.currentRate;
		
		var formData = new FormData();
		formData.append("ratingBy", this.metaId);
		formData.append("ratingFor", this.gigData.assignUserMetaId);
		formData.append("gigId", this.gigId);
		formData.append("rating", this.rating);
		formData.append("review", this.forgotPwdForm.value.comments);

		showLoader();
		this.gigService.giveReview(formData).subscribe(
			(res) => {
				$("#reviewModal").modal("hide");
				this.forgotPwdForm.reset();

				hideLoader();
				this.toastrService.showSuccess(res.message, "Success");
				this.getGigDetail()
			},
			(err) => {
				$("#reviewModal").modal("hide");
				this.toastrService.showError(err.error.message, "Error");
			}
		);

	}

	// Give tip

	confirmPayment() {
		this.source = $('input[name="radio"]:checked').val();
		$('#addCard').modal('hide');

		$('#giveTip1').modal('show');
	}

	// Cancel gig
	cancelGig() {
		var formData = new FormData();
		formData.append("gigId", this.gigId);
		formData.append("userType", "2");
		formData.append("amount", this.gigData.price);
		formData.append("hirerMetaId", this.metaId);
		formData.append("livlouderMetaId", this.gigData.assignUserMetaId);

		showLoader();
		this.gigService.cancelGig(formData).subscribe(
			(res) => {
				$("#cancelGigAlert").modal("hide");
				hideLoader();
				this.toastrService.showSuccess(res.message, "Success");
				this.router.navigate(["/myGigsHirer"]);
			},
			(err) => {
				$("#cancelGigAlert").modal("hide");
				this.toastrService.showError(err.error.message, "Error");
			}
		);
	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
}
