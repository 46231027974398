import { Component, OnInit } from "@angular/core";

import { Options } from "@angular-slider/ngx-slider";
import { FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { Settingdata } from "src/app/services/settings";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
// typeSubtype;

@Component({
	selector: "app-help-page",
	templateUrl: "./help-page.component.html",
	styleUrls: ["./help-page.component.css"]
})
export class HelpPageComponent implements OnInit {
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([20, 80]),
		sliderControlPrice: new FormControl([0, 90])
	});
	// minValue: number = 50;
	// maxValue: number = 200;
	options: Options = {
		floor: 0,
		ceil: 100,
		step: 5
	};

	help = [];
	selectedUser:any;
	
	constructor(
		private authService: AuthService,
		private Settingdata: Settingdata,
		private toastrService: ToastrNotificationService
	) { }

	ngOnInit(): void {

		this.selectedUser =  localStorage.getItem("SelectedUser");
		$('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

		// console.log(this.metaId);
		showLoader();
		this.Settingdata.getHelp().subscribe(
			result => {
				hideLoader(); 
				this.help = result.data;
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}
}
