<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <label>
                            <!-- <input type="text" class="form-control" placeholder="Search Member..."> -->
                            <!-- <span class="fa fa-search"></span> -->
                        </label>
                    </div>
                </div>
                <!-- <div class="filterItem ml-auto">
                    <div class="filterIconGrp">
                        <div class="filterIconItem">
                            <div class="dropdown smallDropdown">
                                <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <button class="btn iconBtn dropdown-toggle" title="Sort By" data-toggle="tooltip" type="button">
                                    <span class="fa fa-sort-amount-down"></span>
                                </button>
                                </span>
                                <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="#">All</a>
                                    <a class="dropdown-item" href="#">Featured</a>
                                    <a class="dropdown-item" href="#">Price</a>
                                </div>
                            </div>
                        </div>
                        <div class="filterIconItem">
                            <button title="Saved Gigs" data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span class="fa fa-heart"></span>
                            </button>
                        </div>
                        <div class="filterIconItem">
                            <button routerLink="/gigs" title="View on Map" data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span class="fa fa-list"></span>
                            </button>
                        </div>
                        <div class="filterIconItem">
                            <button title="Filter" data-toggle="tooltip" (click)="sideBarViewOpen();" class="btn iconBtn" type="button">
                                <span class="fa fa-filter"></span>
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="gigsMap mt-30" *ngIf="mapShow">
                <agm-map [latitude]="currentLat" [longitude]="currentLong" [zoom]="zoom" >
                    <agm-marker [latitude]="currentLat" [longitude]="currentLong"></agm-marker>
                </agm-map>
            </div>
        </div>
    </section>
</div>

<div id="sideBarView" class="sideBarSec">
    <!-- <div class="sideBarHead clearfix">
        <h3>Filter 
        <span class="filterAct">
            <button type="button" class="btn btn-light">Reset</button>
            <button type="button" (click)="sideBarViewClose();" class="btn thm-btn">Apply</button>
            <span class="closeCateg" (click)="sideBarViewClose();"><span class="fa fa-times"></span></span>
        </span>
        </h3>
    </div> -->
    <div class="sideBarBody">
        <div class="filterBody">
            <div class="csForm">
                <div class="form-floating mb-30">
                    <input type="email" class="form-control" id="floatingInput" placeholder="Enter Location">
                    <label for="floatingInput">Location</label>
                </div>
                <!-- <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Distance</label>
                            <div><b>{{ sliderForm.value.sliderControl[0] }} Km - {{ sliderForm.value.sliderControl[1] }} Km</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControl"></ngx-slider>
                    </form>
                </div> -->
                <!-- <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Price</label>
                            <div><b>${{ sliderForm.value.sliderControlPrice[0] }} - ${{ sliderForm.value.sliderControlPrice[1] }}</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControlPrice"></ngx-slider>
                    </form>
                </div> -->
                <div class="form-group">
                    <!-- <label class="inLabel">By Role</label> -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add Type</a>
                            </div>
                        </div>
                        <div class="TypeSlect"><!---->
                            <div class="mediaItem">
                                <h6>Dancer<span><i class="fa fa-times"></i></span></h6>
                            </div>
                            <div class="mediaItem">
                                <h6>Actor<span><i class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add Subtypes</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="modalOverlay" class="sidebarOverlay"></div>
<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="typeListBlk">
            <div class="typesBlock">
                <div class="typesHead">Dancer</div>
                <div class="typesOption">
                    <ul>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="typesBlock">
                <div class="typesHead">Singer</div>
                <div class="typesOption">
                    <ul>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                        <li>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="7">
                                    <span class="cr">
                                        <i class="cr-icon fa fa-check"></i>
                                    </span> Hip Hop 
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
<app-footer-hirer></app-footer-hirer>