import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
declare const $: any;
declare const showLoader: any;
declare const hideLoader: any;
declare const getTimeZone: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private authService: AuthService
  ) { }

  dataArrang(arrangArray: any, key: number) {
    var string;
    var array = [];

    arrangArray.forEach(element => {
      switch (key) {
        case 1: // type or genres
          array.push(element.id);
          break;
        case 2: // experience
          array.push(element.experienceName);
          break;
        case 3: // qualification
          array.push(element.qualificationName);
          break;
        case 4: // feature
          array.push(element.featuresName);
          break;
        case 5: // specifications
          array.push(element.specifications);
          break;
      }
    });

    return array.sort();
  }

  dataArrangType(data) {
    var array = [];
    data.forEach(element => {
      array.push(element.categoryId);
    });

    return array.sort().toString();
  }

  dataArrangSubType(typeData, subTypeData) {
    var array = [];


    for (var i = 0; i < typeData.length; i++) {

      var subTypeArr = [];
      for (var j = 0; j < subTypeData.length; j++) {
        if (typeData[i].categoryId == subTypeData[j].parentId) {
          subTypeArr.push(subTypeData[j].categoryId);
        }
      }

      array.push({ categoryId: typeData[i].parent_id, typeId: typeData[i].categoryId, subTypeId: subTypeArr });

    }
    // data.forEach(element => {
    //   array.push({typeId:element.parentId,subTypeId:[element.categoryId]});
    // });

    return array.sort();
  }

  getMetaId() {

  }

  ////// Thumbnail genrate ///////
  videoPreview(input, callback) {
    if (input.files) {
      var filesAmount = input.files.length;
      var fileType = input.files[0]['type'];
      var fileSize = input.files[0]['size'];

      if (fileSize <= 10000000) {

        if (fileType == 'video/mp4' || fileType == 'video/3gpp') {

          var video = document.createElement('video');
          video.id = 'video';
          video.src = URL.createObjectURL(input.files[0]);;
          // video.type = '*';
          // video.control = true;
          video.load();
          video.play();
          setTimeout(() => {
            // Do something after 1 second 
            var w = video.videoWidth;//video.videoWidth * scaleFactor;
            var h = video.videoHeight;//video.videoHeight * scaleFactor;
            var canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            var data = canvas.toDataURL("image/jpg");

            $('#thumb_byte_code').attr('src', data);// put the byte data into hidden fields
            // Split the base64 string in data and contentType
            var block = data.split(";");
            // Get the content type of the image
            var contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

            // Convert it to a blob to upload
            var blob = this.b64toBlob(realData, contentType, '');

            video.pause();
            callback({ status: 1, message: 'success', blobImage: blob });
          }, 10000);

        } else {
          callback({ status: 0, message: 'Please select only MP4 and 3GP video.', blobImage: '' });
          $('#pImg').attr('src', 'assets/img/video_placeholder.png');

        }

      } else {
        callback({ status: 0, message: 'Video size should not be greater than 10MB.', blobImage: '' });
        $('#pImg').attr('src', 'assets/img/video_placeholder.png');
      }

    } else {
      callback({ status: 0, message: 'something wrong please try again.', blobImage: '' });
      $('#pImg').attr('src', 'assets/img/video_placeholder.png');
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    var contentType = contentType || '';
    var sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  commaSeparatedSubType(subType) {
    var subTypeArr = [];
    for (var i = 0; i < subType.length; i++) {
      var id = subType[i].subTypeId.toString();

      subTypeArr.push(id)
    }

    return subTypeArr.toString();

  }
  //firebase related method
  showLoader() {
    showLoader();
  }

  hideLoader() {
    hideLoader();
  }
}
