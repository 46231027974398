import {
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ViewChild,
} from "@angular/core";
import {
	SignupService
} from "../../services/signup.service";
import {
	FormBuilder,
	Validators,
	FormGroup,
	FormControl,
	FormArray,
	AbstractControl,
	ValidationErrors
} from "@angular/forms";
import {
	CommonService
} from "../../services/common.service";
import {
	AuthService
} from "../../services/auth.service";
import {
	ActivatedRoute,
	Router
} from "@angular/router";

import {
	GroupRequest
} from "src/app/services/group-request";
import {
	ToastrNotificationService
} from "../../services/toastr-notification.service";
import { retry } from "rxjs/operators";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
	selector: "app-edit-group",
	templateUrl: "./edit-group.component.html",
	styleUrls: ["./edit-group.component.css"],
})
export class EditGroupComponent implements OnInit {
	completeProfileForm: FormGroup;
	completeStepTwo: FormGroup;
	uploadImageForm: FormGroup;
	socialLinksForm: FormGroup;
	optradio: any;
	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	userType = 1;
	stepNo = 2;
	defaultValue;
	images = [];
	photoSkip = 1; // 1== true , 2== false (means photo is selected)
	selectLinkIcon = 0;
	typeIdSelected = [];
	subTypeIdSelected = [];
	galleryImg = [];
	check_profile;
	metaId;
	check;
	id;
	lat;
	lng;
	suburb;
	location;
	userMetaId;
	groupId;
	userMetaIdForGroup;
	stype = [];
	socialtype;
	member = [];
	searchText;
	dataFound;
	total;
	invitedMember;
	limit: any = 20;
	offset: any = 0;
	groupDetails;
	membertotal;
	editGroupdata: any = [];
	profileImage;
	stepTwo;
	experience = [];
	media = [];
	video = [];
	linkDyna = [];
	mediaType = ['facebook', 'twitter', 'instagram'];
	asd1 = [];
	selectedLink;
	allSocialLink = [];
	showAddMoreBtn = true;
	check1;
	isLinkStatus;
	newLink: any = {
		'id': '',
		'type': 'globe',
		'socialLink': '',
		'social_media_type_id': '',
		'image': '',
		'isShow': 'true',
	};
	searchValue;


	@ViewChild("imgDiv", {
		static: false,
	})
	d1: ElementRef;
	@ViewChild("uploadLinksBtn") uploadLinksBtn: ElementRef;
	constructor(
		private signupService: SignupService,
		private fb: FormBuilder,
		private commonService: CommonService,
		private authService: AuthService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private groupRequest: GroupRequest,
		private activatedRoute: ActivatedRoute
	) { }
	ngOnInit(): void {


		$('[data-toggle="tooltip"]').tooltip();
		this.activatedRoute.paramMap.subscribe((param) => {
			this.groupDetails = param.get("id");
		});
		this.metaId = this.authService.getUserMetaId();


		this.signupService.socialType().subscribe(
			(data) => {
				hideLoader();
				data.data.forEach((element) => {
					element.isShow = true;
					this.allSocialLink.push(element);
				});
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}


			}
		);

		// this.signupService.getTypeSubType().subscribe(
		this.signupService.getGroupTypeSubType().subscribe(
			(data) => {
				this.typeSubtype = data.data;
				// this.addTypeListData(this.typeSubtype);
			},
			(err) => {
				this.authService.checkValidToken(err.error);
				this.toastService.showError(err.error.message, "Error");
			}
		);

		// 1 step form
		// this.completeProfileForm = new FormGroup({
		// 	fullname: new FormControl(null, [
		// 		Validators.required,
		// 		Validators.maxLength(30),
		// 	]),

		// 	// 'availability':new FormControl (null, [Validators.required]),
		// 	price: new FormControl(null, [
		// 		Validators.required,
		// 		// Validators.pattern("^[0-9]*$"),
		// 		Validators.pattern("^([0-9]{0,3}((.)[0-9]{0,2}))$"),
		// 		Validators.maxLength(6)

		// 	]),
		// 	typeBlankCheck: new FormControl(null, [Validators.required]),
		// 	subTypeBlankCheck: new FormControl(null, [Validators.required]),
		// 	profileImage: new FormControl(null, [Validators.required]),
		// });


		// 1 step form with value edit case
		this.groupRequest.getStepOne(this.groupDetails).subscribe((res) => {
			hideLoader();

			if(res.data.type){

				this.typeIdSelected = res.data.type.split(",");
			}
			
			if(res.data.subtype){

				this.subTypeIdSelected = res.data.subtype.split(",");
			}

			this.editGroupdata = res.data;
			this.groupId = this.editGroupdata.groupId;
			this.userMetaIdForGroup = this.editGroupdata.userMetaId;
			this.profileImage = this.editGroupdata.profileImage
			this.completeProfileForm = new FormGroup({
				fullname: new FormControl(this.editGroupdata.groupName, [
					Validators.required,
					Validators.maxLength(30),
					this.noWhitespaceValidator
				]),
				price: new FormControl(this.editGroupdata.groupPrice, [
					Validators.required,
					// Validators.pattern("^([0-9]{0,3}((.)[0-9]{0,2}))$"),
					Validators.pattern("^([0-9]{1,6})((.[0-9]{2})?)$"),
					Validators.min(1),
					Validators.max(999999.99),
					Validators.maxLength(9)
				]),

				typeBlankCheck: new FormControl(this.editGroupdata.type, [
					Validators.required,
				]),
				subTypeBlankCheck: new FormControl(this.editGroupdata.subtype, [
					Validators.required,
				]),
				profileImage: new FormControl(this.profileImage, [Validators.required]),
			});
		}, (err) => {
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				//this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}


		});

		this.metaId = this.authService.getUserMetaId();

		// this.signupService.getTypeSubType().subscribe(
		this.signupService.getGroupTypeSubType().subscribe(
			(data) => {
				this.typeSubtype = data.data;
				this.addTypeListData(this.typeSubtype);
			},
			(err) => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);


		// 2 step form  validation
		// this.completeStepTwo = new FormGroup({
		// 	about: new FormControl(null, [
		// 		Validators.maxLength(300),
		// 		Validators.minLength(5),
		// 	]),
		// 	availability: new FormControl(null, [Validators.required]),
		// 	experience: new FormArray([new FormControl(null)]),
		// });

		// get secound Form  info 
		this.groupRequest.getStepTwo(this.groupDetails).subscribe((res) => {
			this.stepTwo = res.data;
			if (res.data.experience) {
				this.experience = JSON.parse(res.data.experience);
			}
			this.completeStepTwo = new FormGroup({
				about: new FormControl(this.stepTwo.about, [
					Validators.required,
					Validators.maxLength(300),
					Validators.minLength(5),
					this.noWhitespaceValidator
				]),
				availability: new FormControl(this.stepTwo.availability, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
				experience: new FormArray([])
			});
			this.patchExperiance(this.experience);

		})


		// 3 step form  validation
		this.uploadImageForm = new FormGroup({
			file: new FormControl(null),
			fileSource: new FormControl(null),
		});


		this.groupRequest.getMedia(this.groupDetails, 1).subscribe((res) => {
			this.media = res.data;

		})


		this.groupRequest.getMedia(this.groupDetails, 2).subscribe((res) => {
			this.video = res.data;

		})


		// 4 step social links
		// this.socialLinksForm = new FormGroup({
		// 	links: new FormArray([new FormControl(null)]),
		// 	socialMedia: new FormControl(null),
		// });



		// 4 step social links
		this.socialLinksForm = new FormGroup({
			links: new FormArray([

			]),
			socialMedia: new FormControl(null),
		});

		this.groupRequest.getSocialLink(this.groupDetails,).subscribe((res) => {
			if (res.data.length > 0) {
				this.linkDyna = res.data;
				this.linkDyna.forEach((element) => {
					let index = this.allSocialLink.findIndex(
						(x) => x.id == element.social_media_type_id
					);

					if (index != -1) {

						this.allSocialLink[index].isShow = false;
					}
					this.stype.push(element.social_media_type_id);

				});
				this.isLinkStatus = true;

			} else {
				this.linkDyna.push(this.newLink);
				this.isLinkStatus = false;

			}

			this.checkSocialTypeExist();
			this.socialLink(res.data)

		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})
		//this.invitedMemberList()
		//this.memberList();

	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	checkSocialTypeExist() {

		var asd = this.allSocialLink;
		this.showAddMoreBtn = false;
		//setTimeout(function () {
		asd.forEach((element) => {
			if (element.isShow == true) {
				this.showAddMoreBtn = true;

			}
		})

		//}, 4000)


	}
	socialLink(data) {

		let link = data;
		if (link.length == 0) {
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [this.noWhitespaceValidator])
			);
		} else {
			link.forEach((element) => {
				(<FormArray>this.socialLinksForm.get('links')).push(
					new FormControl(element.socialLink, [this.noWhitespaceValidator])
				);
			});
		}
	}

	basicInfoStepOne(event) {
		event.stopPropagation();

		if (this.completeProfileForm.invalid) {
			this.validateAllFormFields(this.completeProfileForm);

			return;
		} else {
			var typeStore = this.commonService.dataArrangType(this.typeListData);
			var subTypeStore = this.commonService.dataArrangSubType(
				this.typeListData,
				this.subTypeSelected
			);

			if (typeStore == "") {
				this.toastService.showError('Type is required', "Error");
				return false;
			}

			if (this.subTypeSelected.length == 0) {
				this.toastService.showError('Subtypes is required', "Error");
				return false;
			}

			const formData = new FormData();
			formData.append("name", this.completeProfileForm.value.fullname);
			formData.append("price", this.completeProfileForm.value.price);
			formData.append("typeId", typeStore);
			formData.append("groupId", this.groupId);
			formData.append("typeSubtype", JSON.stringify(subTypeStore));
			formData.append(
				"groupImage",
				this.completeProfileForm.value.profileImage
			);

			formData.append("metaId", this.groupDetails);
			formData.append("onBordingStep", "2");

			showLoader();
			this.groupRequest.stepOne(formData).subscribe(
				(data) => {

					hideLoader();

					if (data.status == 'fail') {
						this.toastService.showError(data.message, "Error");


					} else {
						this.toastService.showSuccess(data.message, "Success");
						this.stepNo = Number(this.stepNo) + 1;

					}

				},

				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
		return;
	}




	patchExperiance(data) {
		let experiance = data;
		experiance.forEach(element => {
			(<FormArray>this.completeStepTwo.get("experience")).push(
				new FormControl(element, [Validators.required, this.noWhitespaceValidator])
			);
		});
	}

	validateAllFormFields(formGroup: FormGroup) {
		//{1}
		Object.keys(formGroup.controls).forEach((field) => {
			//{2}
			const control = formGroup.get(field); //{3}
			if (control instanceof FormControl) {
				//{4}
				control.markAsTouched({
					onlySelf: true,
				});
			} else if (control instanceof FormGroup) {
				//{5}
				this.validateAllFormFields(control); //{6}
			}
		});

		return;
	}

	onCheckboxChangeRole(type, event) {
		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.subTypeSelected = [];
		}
		if (event.target.checked) {
			this.typeListData.push({
				categoryId: type.categoryId,
				name: type.name,
				parent_id: type.parent_id,
			});

			this.subTypeArray.push(type);
			this.typeIdSelected.push(type.categoryId);
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}
			for (var i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i].categoryId == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}

		if (this.typeListData.length == 0) {
			this.completeProfileForm.patchValue({
				typeBlankCheck: "",
			});
		} else {
			this.completeProfileForm.patchValue({
				typeBlankCheck: "1",
			});
		}
	}

	removeType(index) {
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}


		var abc = this.subTypeIdSelected;

		$.each(this.subTypeSelected, function (key, value) {
			var cat = value.categoryId;
			let index = abc.indexOf(cat);
			if (index > -1) {
				abc.splice(index, 1);
			}
		});

		//remove subtype
		for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
			if (this.subTypeSelected[i].parentId == parent_id) {
				this.subTypeSelected.splice(i, 1);
				//this.checkUncheckRole(option.id,false);
			}
		}

		for (i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {
				this.subTypeArray.splice(i, 1);
			}
		}
	}

	onCheckboxChangesubType(subType, event) {
		if (event.target.checked) {
			this.subTypeSelected.push({
				categoryId: subType.categoryId,
				name: subType.name,
				parentId: subType.parent_id,
			});
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		if (this.subTypeSelected.length == 0) {
			this.completeProfileForm.patchValue({
				subTypeBlankCheck: "",
			});
		} else {
			this.completeProfileForm.patchValue({
				subTypeBlankCheck: "1",
			});
		}
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	basicInfoStepTwo(event) {
		
		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			//return false;
			//var metaId = this.authService.getUserMetaId();

			const formData = new FormData();

			if (this.completeStepTwo.value.about) {
				formData.append("about", this.completeStepTwo.value.about);
			}

			formData.append(
				"experience",
				JSON.stringify(this.completeStepTwo.value.experience)
			);
			formData.append("onBordingStep", "3");
			formData.append("availability", this.completeStepTwo.value.availability);
			formData.append("groupId", this.groupId);

			showLoader();
			this.groupRequest.stepTwo(formData).subscribe(
				(data) => {
					hideLoader();
					this.toastService.showSuccess(data.message, "Success");

					this.authService.updateOnBoardingProfileCount(
						1,
						data.data.onBoardingProfileCount
					);
					this.stepNo = Number(this.stepNo) + 1;
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	onFileChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.completeProfileForm.patchValue({
				profileImage: file,
			});
		}
	}


	previous() {
		showLoader();
		this.stepNo = this.stepNo - 1;
		hideLoader();

		// var metaId = this.authService.getUserMetaId();

		this.groupRequest.getMedia(this.userMetaIdForGroup, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;

		})
	}

	UploadImgStepThree() {
		//var metaId = this.authService.getUserMetaId();

		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append("metaId", this.userMetaIdForGroup);
			formData.append("onBordingStep", "4");
			formData.append("type", "1");

			this.signupService.skipPhoto(formData).subscribe(
				(data) => {
					hideLoader();

					this.toastService.showSuccess(data.message, "Success");

					this.stepNo = 5;
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
					this.stepNo = 5;
				}
			);
		} else {
			//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)
			var step = this.authService.getOnBoardingProfileCount();
			//this.stepNo = parseInt(step) + 1;
			this.stepNo = Number(this.stepNo) + 1;

			this.stepNo = 5;
		}
	}
	uploadSingleImg() {
		// var metaId = this.authService.getUserMetaId();
		const formData = new FormData();
		formData.append("media", this.uploadImageForm.value.fileSource);
		formData.append("userType", "3");
		formData.append("metaId", this.userMetaIdForGroup);
		formData.append("onBordingStep", "4");
		formData.append("mediaType", "1");

		showLoader();
		this.signupService.uploadImage(formData).subscribe((data) => {
			hideLoader();
			this.toastService.showSuccess(data.message, "Success");

			let imgData = data.mediaData;

			this.galleryImg.push(imgData);
		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		});
	}
	onUploadFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file,
			});

			this.photoSkip = 2;
			this.uploadSingleImg();
		}
	}

	// skip video
	skipVideo() {
		//var metaId = this.authService.getUserMetaId();

		showLoader();

		const formData = new FormData();

		formData.append("metaId", this.userMetaIdForGroup);
		formData.append("onBordingStep", "4");
		formData.append("type", "2");

		this.signupService.skipPhoto(formData).subscribe(
			(data) => {
				hideLoader();

				this.toastService.showSuccess(data.message, "Success");

				this.stepNo = 6;
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
				//this.error = error.message;
			}
		);
	}
	// upload  video
	onSelectedVideo(event) {
		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		//var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append(
						"authToken",
						this.authService.getAuthorizationToken()
					);
					formData.append("metaId", this.userMetaIdForGroup);
					formData.append("userType", "3");
					formData.append("mediaType", "2");
					formData.append("onBordingStep", "4");
					formData.append("media", file);
					formData.append("video_thumb", res.blobImage, "video_thumb.png");
					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();
						this.authService.checkValidToken(data);

						if (data.status == "fail") {
							this.toastService.showError(data.message, "Error");
						} else {
							this.toastService.showSuccess(data.message, "Success");

							this.authService.updateOnBoardingProfileCount(
								1,
								data.mediaData.onBoardingProfileCount
							);

							//var step = this.authService.getOnBoardingProfileCount();

							//this.stepNo = parseInt(step) + 2;
							// this.stepNo = Number(this.stepNo) + 1;
							this.video.push(data.mediaData);
						}
					}, err => {
						hideLoader();
						if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
							this.authService.checkValidToken(err.error);
							this.toastService.showError('Session Expired', 'Error');

						} else {
							this.toastService.showError(err.error.message, 'Error');
						}
					});
				} else {
					hideLoader();

					this.toastService.showError(res.message, "Error");
				}
			});
		}
	}
	checkLinkValue() {

		if (this.socialLinksForm.get('links').value == 0) {
			this.check1 = 'false';
			return false
		} else {
			this.check1 = 'true';

		}
	}

	uploadLinks() {
		
		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			return false;
		}
		showLoader();

		var metaId = this.authService.getUserMetaId();

		if (this.uploadLinksBtn.nativeElement.textContent == 'Skip') {
			let formData = new FormData();

			formData.append('metaId', this.userMetaIdForGroup);
			formData.append('onBordingStep', '5');
			formData.append('type', this.socialtype);

			this.signupService.skipPhoto(formData).subscribe(
				(data) => {
					hideLoader();
					this.memberList();
					this.invitedMemberList();
					this.stepNo = Number(this.stepNo) + 1;
					this.toastService.showSuccess(data.message, 'Success');

				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
			return;
		}
		if (this.socialLinksForm.invalid) {
			hideLoader();
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');

			return false;
		} else {
			var linksArray = [];

			var linkSocial = this.socialLinksForm.value.links;
			var type = this.stype;

			if (linkSocial) {
				hideLoader();
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({
							link: linkSocial[i],
							type: this.stype[i],
						});
					}
				}
				if (this.stype.length != this.linkDyna.length) {
					this.toastService.showError('Please select Social Link type', 'Error');
					return false;
				}

				linksArray.forEach(function (value) {

					if (value.type == undefined) {
						alert("Please select Type");
						return false;

					}

				});
				this.socialLinksForm.patchValue({
					socialMedia: linksArray,
				});
			}



			showLoader();
			const formData = new FormData();

			formData.append(
				'socialMedia',
				JSON.stringify(this.socialLinksForm.value.socialMedia)
			);

			formData.append('metaId', this.userMetaIdForGroup);
			formData.append('onBordingStep', '5');

			this.signupService.uploadSocialLinks(formData).subscribe(
				(data) => {
					hideLoader();
					this.memberList();
					this.invitedMemberList();
					// localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
					this.toastService.showSuccess(data.message, 'Success');
					this.stepNo = Number(this.stepNo) + 1;

					// this.router.navigate(['/ProfileLivlouder']);
				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}


	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}


	addLinks() {


		if (this.stype.length != this.linkDyna.length) {
			this.toastService.showError('Please select Social Link type', 'Error');
			return false;
		}

		if (this.socialLinksForm.invalid) {
			this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');
			return false;
		} else {
			// this.linkDyna = this.linkDyna;
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [this.noWhitespaceValidator])
			);

			let newLinks: any = {
				'id': '',
				'type': 'globe',
				'socialLink': '',
				'social_media_type_id': '',
				'image': '',
				'isShow': 'true',
			};
			this.linkDyna.push(newLinks);

		}
	}

	removeLinks(index) {

		this.stype.splice(index, 1);
		let indexCheck = this.allSocialLink.findIndex((x) => x.id == this.linkDyna[index].social_media_type_id);

		if (indexCheck != -1) {

			this.allSocialLink[indexCheck].isShow = true;
		}

		this.linkDyna.splice(index, 1);
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index);
		this.checkSocialTypeExist();
	}

	changeIcon(index, val) {

		var arr = [
			`<i class="fab fa-facebook-square"></i>`,
			`<i class="fab fa-twitter-square"></i>`,
			`<i class="fab fa-instagram"></i>`,
		];

		//var neha = $('.iconClass' + index).html(arr[val]);

		var oldId = this.linkDyna[val].social_media_type_id;


		this.linkDyna[val].type = this.allSocialLink[index].type;

		this.linkDyna[val].social_media_type_id = this.allSocialLink[index].id;

		this.allSocialLink[index].isShow = false;
		if (oldId != this.allSocialLink[index].id) {

			let indexnew = this.allSocialLink.findIndex((x) => x.id == oldId);

			if (indexnew != -1) {

				this.allSocialLink[indexnew].isShow = true;
			}
		}

		this.stype[val] = this.allSocialLink[index].id;
		this.checkSocialTypeExist();
	}



	remove_Member(id) {



		const formData = new FormData();
		var current_user_data = JSON.parse(localStorage.getItem("currentUser"));
		var senderMeta = localStorage.getItem('metaId');
		formData.append("userMetaId", senderMeta);
		formData.append("groupId", this.groupId);
		formData.append("metaId", id);
		// alert(senderMeta)
		// alert(this.groupId)
		// alert(id)
		// return false;
		if (this.groupId) {
			showLoader();
			this.groupRequest.removeGroupMember(formData).subscribe(
				res => {
					hideLoader();
					this.toastService.showSuccess(res.message, "Success");

					if (this.router.url == "/groupDetails/" + this.userMetaId) {
						this.router.navigate(["/groupRequest"]);
					} else {
						this.router.navigate(["/groups"]);
					}
				},
				err => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}


	invitedMemberList() {
		var obj: any = {};
		obj.metaId = this.userMetaIdForGroup;
		obj.offset = 0;
		this.groupRequest.invitedMember(obj).subscribe(
			(data) => {

				if (data.status == "success") {
					this.invitedMember = data.data;
					this.total = data.data.length;
					if (this.total == 0) {
						this.dataFound = false;
					} else {
						this.dataFound = true;
					}
					//	this.memberList();

				} else {
					this.invitedMember = [];
					this.dataFound = false;
					this.total = 0;
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}


	addExperience() {
		(<FormArray>this.completeStepTwo.get("experience")).push(
			new FormControl(null, [Validators.required, this.noWhitespaceValidator])
		);
	}

	onKeyPress(event: KeyboardEvent) {
		// if (event.key === 'e') {
		// 	event.preventDefault();
		// }

		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
			event.preventDefault();
			return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}

	removeExperience(index) {
		(<FormArray>this.completeStepTwo.get("experience")).removeAt(index);
	}

	// search member in send invitation list 
	searchMember(searchValue) {
		if (searchValue && searchValue.trim() !== '') {
			this.searchValue = searchValue.trim();
			this.member = [];
			this.offset = 0;
			showLoader();
			this.memberList();
		}
	}


	// member list For send invitation 
	memberList() {
		// alert(this.groupDetails);
		var obj: any = {}
		// var searchText = $("#search").val();
		if (this.searchValue != undefined) {
			obj.search = this.searchValue;

		} else {
			obj.search = '';

		}
		obj.metaId = this.groupDetails;
		obj.offset = this.offset;
		this.groupRequest.memberList(obj).subscribe((data) => {

			hideLoader();
			this.invitedMemberList()
			// this.member =[];

			if (data.status == "success") {

				data.data.forEach(element => {
					this.member = data.data;
				});

				this.membertotal = data.total;

			} else {
				this.member = [];

			}

		}, (err) => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})


	}

	// Form Rest on close icon
	ResetForm() {
		var searchText = $("#search").val("");

		var obj: any = {};
		obj.metaId = 5;

		obj.offset = 0;
		showLoader();
		this.groupRequest.memberList(obj).subscribe(
			(data) => {
				hideLoader();
				if (this.total == 0) {
					this.dataFound = false;
				} else {
					this.dataFound = true;
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}

			}
		);
	}

	// send invitation for group
	sendRequest(id, userId) {
		var current_user_data = JSON.parse(localStorage.getItem("currentUser"));
		var senderMeta = localStorage.getItem('metaId');
		const formData = new FormData();
		formData.append("userId", userId);
		formData.append("userMetaId", this.userMetaIdForGroup);
		formData.append("metaId", id);
		formData.append("senderMetaId", senderMeta);

		this.groupRequest.sendRequest(formData).subscribe(
			(data) => {
				hideLoader();
				$("#inviteMember").modal('hide');
				this.toastService.showSuccess(data.message, "Success");
				//showLoader();
				this.member = [];

				this.memberList();
				this.invitedMemberList();
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	// load more for send invitation


	loadMore() {
		this.offset = this.offset + this.limit;
		this.memberList();
	}

	// cancel invitation for group
	cancleInvitation(id) {
		var current_user_data = JSON.parse(localStorage.getItem("currentUser"));
		const formData = new FormData();

		formData.append("groupRequestId", id);
		showLoader();
		this.groupRequest.cancelRequest(formData).subscribe(
			(data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");
				//showLoader();
				this.member = [];

				this.memberList();
				this.invitedMemberList();
			},
			(err) => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	// show type and subtype
	addTypeListData(typeSubtype) {
		setTimeout(() => {
		  typeSubtype.forEach((element) => {
			element.Type.forEach((element1) => {
			  if (this.typeIdSelected.includes(element1.categoryId)) {
				this.typeListData.push({
				  categoryId: element1.categoryId,
				  name: element1.name,
				  parent_id: element1.parent_id,
				});
				this.subTypeArray.push(element1);
				element1.subType.forEach((element2) => {
				  if (this.subTypeIdSelected.includes(element2.categoryId)) {
					this.subTypeSelected.push({
					  categoryId: element2.categoryId,
					  name: element2.name,
					  parentId: element2.parent_id,
					});
				  }
				});
			  }
			});
		  });
		}, 50);
	}
	  

	// remove image 
	removeImg(imgId, index) {
		$(this)
			.parent(".one")
			.remove();
		const formData = new FormData();
		formData.append("mediaId", imgId);
		formData.append("onBordingStep", "4");

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			res => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}
				this.groupRequest.getMedia(this.groupDetails, 1).subscribe((res) => {
					this.media = res.data;

				})
				this.toastService.showSuccess(res.message, "Success");


			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}

			}
		);
	}


	removideo(imgId, index) {
		$(this)
			.parent(".one")
			.remove();
		const formData = new FormData();
		formData.append("mediaId", imgId);
		formData.append("onBordingStep", "4");

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			res => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}

				this.groupRequest.getMedia(this.groupDetails, 2).subscribe((res) => {
					this.video = res.data;

				})
				this.toastService.showSuccess(res.message, "Success");
			},

			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

}