<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container">
            <div class="gigsList">
                <div class="row">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <h2>Music festival stubs</h2>
                                    <div class="gigBtnActView">
                                        <button routerLink="/chatLivlouder" type="button"
                                            class="btn thm-btn-sm btnCircle"><span
                                                class="fa fa-comment-alt"></span></button>
                                    </div>
                                </div>
                                <div class="jobOpen">
                                    <label>Singer</label>
                                </div>
                                <div class="priceBlk">
                                    <p>$640</p>
                                </div>
                                <p class="gigLcDetail"><i class="fa fa-map-marker-alt"></i> <span>1 East Sherman Lane
                                        Chester, PA 19013</span></p>
                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">Type</h2>
                                    <div class="typeBlk">
                                        <div class="typeItem">Dancer</div>
                                        <div class="typeItem">Security</div>
                                    </div>
                                </div>
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>Sat, 25 Feb, 05:00 PM</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>Sat, 25 Feb, 05:00 PM</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>2 Hr 30 Min</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="assgigAct">
                                    <button type="button" data-toggle="modal" data-target="#reviewModal"
                                        class="btn thm-btn-sm">Give Review</button>
                                </div>
                                <!-- <div class="ratePriceBlk">
                                    <div class="jobOpen">
                                        <label>Assigned</label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Gig</h2>
                                    <p class="aboutText">It is a long established fact that a reader will be distracted
                                        by the readable content of a page when looking at its layout.</p>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Must Have</h2>
                                    <ul class="infoList">
                                        <li>One year in singing</li>
                                        <li>Two years in playback singing</li>
                                        <li>Over 150 Gigs + Completed</li>
                                        <li>5 Years as teaching Guitar at Music Acadamy</li>
                                        <li>3 Years as lead gutarist at Left Hand God</li>
                                    </ul>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <ul class="nav nav-tabs csTabs sideCsTab" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="csTab1-tab" data-toggle="tab" href="#csTab1"
                                        role="tab" aria-controls="csTab1" aria-selected="true">Offers (150)</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="csTab2-tab" data-toggle="tab" href="#csTab2" role="tab"
                                        aria-controls="csTab2" aria-selected="false">Questions (15)</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="csTab1" role="tabpanel"
                                    aria-labelledby="csTab1-tab">
                                    <div class="userInfoItem gigOffersList">

                                        <div class="reviewsList">
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/2.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>3 min ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView ml-auto">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$360</h3>
                                                        </div>
                                                        <div class="">
                                                            <div class="jobOpen">
                                                                <label>Awarded</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a
                                                            reader will be distracted by the readable content of a page
                                                            when looking at its layout. The point of using Lorem Ipsum
                                                            is that it has a more-or-less normal distribution of letters
                                                        </p>
                                                        <button (click)="show = !show">{{ show ? 'Show less': 'Show
                                                            More' }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/3.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Edmund Richards</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView ml-auto">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$150</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a
                                                            reader will be distracted by the readable content of a page
                                                            when looking at its layout.</p>
                                                        <!-- <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/4.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Sophie Flowers</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$240</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a
                                                            reader will be distracted.</p>
                                                        <!-- <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/5.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Danny Reed</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$340</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a
                                                            reader will be distracted by the readable content of a page
                                                            when looking at its layout. The point of using Lorem Ipsum
                                                            is that it has a more-or-less normal distribution of letters
                                                        </p>
                                                        <button (click)="show = !show">{{ show ? 'Show less': 'Show
                                                            More' }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/6.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                    <div class="ratingView">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="offerActBlk">
                                                        <div class="offerPrice">
                                                            <label>Offer Price</label>
                                                            <h3>$450</h3>
                                                        </div>
                                                    </div>
                                                    <div class="offferText">
                                                        <p [class.show]="show">It is a long established fact that a
                                                            reader will be distracted by the readable content of a page
                                                            when looking at its layout. The point of using Lorem Ipsum
                                                            is that it has a more-or-less normal distribution of letters
                                                        </p>
                                                        <button (click)="show = !show">{{ show ? 'Show less': 'Show
                                                            More' }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="csTab2" role="tabpanel" aria-labelledby="csTab2-tab">
                                    <div class="userInfoItem gigOffersList">
                                        <!-- <div class="gigNodata">
                                            <div class="noDataView">
                                                <svg-icon class="svgIcon" src="assets/img/conversation.svg"></svg-icon>
                                                <h2>No Questions Asked Yet</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </div> -->
                                        <div class="">
                                            <div class="replyBox questionBoxView">
                                                <label>Ask your question</label>
                                                <div class="replyInBox">
                                                    <input type="text" class="form-control"
                                                        placeholder="Enter Question">
                                                    <button type="button" class="btn iconBtn"><i
                                                            class="fa fa-paper-plane"></i></button>
                                                </div>
                                                <!-- <div class="text-right"><a (click)="toggle()" href="javascript:void(0);">Cancel</a></div> -->
                                            </div>
                                        </div>
                                        <div class="reviewsList">
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/2.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>3 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> It is a long established fact that a reader
                                                            will be distracted by the readable content of a page when
                                                            looking at its layout.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/3.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Edmund Richards</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <i class="noreplay">No Reply Yet</i></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/4.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Sophie Flowers</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>5 min ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> It is a long established fact that a reader
                                                            will be distracted by the readable content of a page when
                                                            looking at its layout.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/5.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Danny Reed</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <i class="noreplay">No Reply Yet</i></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="reviewsItem">
                                                <div class="reviewsCntBlk">
                                                    <div class="reviewUserImg">
                                                        <a href="javascript:void(0);">
                                                            <img src="assets/img/6.png">
                                                        </a>
                                                    </div>
                                                    <div class="reviewsCnt">
                                                        <a href="javascript:void(0);">
                                                            <h2>Isabella Smith</h2>
                                                        </a>
                                                        <div class="rateTimeMeta">
                                                            <p>1 day ago</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retDec offersInfo">
                                                    <div class="questionBox">
                                                        <h2><span>Q)</span> Lorem ipsum dolor sit amet?</h2>
                                                        <p><span>Ans.</span> <i class="noreplay">No Reply Yet</i></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="reviewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modalHeadCnt">
                    <svg-icon class="svgIcon" src="assets/img/rating.svg"></svg-icon>
                    <h2>Give Review</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <div class="giveReviewBox">
                        <div class="ratingBlk">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <div class="">
                            <div class="form-floating mb-3">
                                <textarea rows="4" type="text" class="form-control textArea" id="floatingInput"
                                    placeholder="Write something..."></textarea>
                                <label for="floatingInput">Write Your Comments <span class="reqField">*</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn thm-btn">Submit</button>
            </div>
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>