import { Component, OnInit, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { NotificationService } from 'src/app/services/notification.service';
declare const showLoader: any;
declare const hideLoader: any;
@Component({
  selector: 'app-notification-list-livlouder',
  templateUrl: './notification-list-livlouder.component.html',
  styleUrls: ['./notification-list-livlouder.component.css']
})
export class NotificationListLivlouderComponent implements OnInit {

  selectedUserTypeId;
  switched_user;
  data;
  current_user_data;
  currentuserId;
  metaId;
  notifyCount: any = 0;
  notificationsList = [];
  profileImage: any;
  offset: number = 0;
  limit: number = 20;
  recordCount: number;
  isMenuOpen: boolean = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    private toastrService: ToastrNotificationService,
    private NotificationService: NotificationService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.profileImage = currentUser.userTypeList[1].profileImage;
    this.selectedUserTypeId = localStorage.getItem('SelectedUser');
    this.metaId = localStorage.getItem('metaId');
    this.currentuserId = currentUser.userId;

    this.getNotificationCount();
    // this.getNotificationList();
    setInterval(() => {
      this.showBadgeCount();
    }, 5000);
  }

  getNotificationCount() {

    this.NotificationService.getNotificationListCount().subscribe(
      data => {

        this.notifyCount = data.data.badge_count;
        localStorage.setItem('badgeCount', this.notifyCount);
      },
      err => {
        // hideLoader();
        if (err.error.status == 'fail' && err.error.message == 'Invalid token') {

          this.authService.checkValidToken(err.error);
          this.toastrService.showError('Session Expired', 'Error');

        } else {

          this.toastrService.showError(err.error.message, 'Error');
        }

      }
    );
  }

  showBadgeCount() {

    this.notifyCount = localStorage.getItem('badgeCount');

  }

  toggleMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
  }

  onDocumentClick(event: MouseEvent): void {
      // Close the menu if clicked outside of it
      if (!this.elementRef.nativeElement.contains(event.target)) {
          this.isMenuOpen = false;
      }
  }

  getLoadmoreList(event) {
    event.stopPropagation();
    this.getNotificationList();
  }

  getListInilialList() {
    this.offset = 0;
    this.notificationsList = [];
    this.getNotificationList();
  }

  getNotificationList() {

    if (this.authService.isLoggedIn() == false) {

      this.router.navigate(["/login"]);
    } else {

      var obj: any = {};
      obj.user_id = this.currentuserId;
      if (this.offset == 0 && this.notificationsList.length == 0) {
        obj.offset = this.offset;
      } else {

        this.offset = this.offset + this.limit;
        obj.offset = this.offset;

      }

      this.NotificationService.getNotificationList(obj).subscribe(
        data => {
          // hideLoader();
          if (data.data.notifications_list != null) {

            let notifyList = data.data.notifications_list;
            this.recordCount = parseInt(data.data.total_records);

            notifyList.forEach(element => {

              if(element.user_type == 3){

                element.postedByImage = element.groupProfileImage;
              }else{
  
                element.postedByImage = element.postedByImage;
              }

              element.body = element.body.replace('{Livlouder}', element.user_name);
              element.body = element.body.replace('{Hirer}', element.user_name);

              //added code...
              element.body = element.body.replace('{gigName}', element.gigName);
              element.body = element.body.replace('{groupName}', element.groupName);
              element.body = element.body.replace('{receiverName}', element.user_receiver_name);
              element.body = element.body.replace('{livlouderPrice}', element.livlouderPrice);


              this.notificationsList.push(element);
            });
          }

        },
        err => {

          if (err.error.status == 'fail' && err.error.message == 'Invalid token') {

            this.authService.checkValidToken(err.error);
            this.toastrService.showError('Session Expired', 'Error');

          } else {

            this.toastrService.showError(err.error.message, 'Error');
          }
        }
      );

    }
  }

  viewNotification(alertId, metaId, userType, notifyType) {

    if (this.metaId != metaId && notifyType.receiver_user_type != 3) {
      this.toastrService.showError('Currently you are login with your other user type or other profile, If you want to check this notification first you need to login with same profile.', 'Error');
      return false;

    }
    var obj: any = {};
    obj.alert_id = alertId;

    this.NotificationService.readNotification(obj).subscribe(
      data => {

        let index = this.notificationsList.findIndex(x => x.alertID === alertId);
        
        if (index !== -1 && this.notificationsList[index].is_read != 1) {
          this.notificationsList[index].is_read = 1;
          this.notifyCount = this.notifyCount - 1;
        }
        let url: string = this.NotificationService.getNotificationListRedirectionUrl(notifyType);

        if (url != '') {

          this.router.navigate([url]); // navigate to other pag 
        }

      },
      err => {
        if (err.error.status == 'fail' && err.error.message == 'Invalid token') {

          this.authService.checkValidToken(err.error);
          this.toastrService.showError('Session Expired', 'Error');

        } else {

          this.toastrService.showError(err.error.message, 'Error');
        }

      }
    );



  }

  getTimeAgo(curretTime, dataTime){
    return this.NotificationService.getTimeAgo(curretTime, dataTime);
  }

}
