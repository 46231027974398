<app-header-hirer></app-header-hirer>
<style>
    .customModalSize {
        margin-top: 10rem;
        max-width: 600px !important;
    }
</style>
<div class="mainWrapper light-bg">
    <section class="chatPage py-3">
        <div class="container">
            <div class="chatApp">
                <div class="chatMainBlock">

                    <div class="conversation">

                        <div class="chatHeader chatHeaderMsg">
                            <div class="chatHeaderAvatar">
                                <div class="">
                                    <a href="#!" class="chatHeaderUser">
                                        <h2>Payment Dispute - {{disputeTitle}}</h2>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="messageBox" id="conversation">
                            <div class="noChatFoundBlock noChatSidebar" *ngIf="chatList.length==0">
                                <div class="noChatFound chat-area mt-5 pt-3">
                                    <div>
                                        <img src="assets/img/3Icon.png" />
                                        <h2>Message not found!</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="messageContainer">

                                <div class="message-body" *ngFor="let message of chatList">
                                    <p class="centerTimeShow pull-left" style="margin-left: 25px;font-weight: 600;" *ngIf="message.name"><span>{{message.name}}</span></p>

                                    <div [ngClass]="{'message-main-sender':message.last_sender_id == hirerID,'message-main-receiver':message.last_sender_id != hirerID}">
                                        <div [ngClass]="{'sender':message.last_sender_id == hirerID,'receiver':message.last_sender_id != metaId,'imgSend':message.image=='1'}">
                                            <div class="message-text" *ngIf="message.image=='0'">
                                                {{message.message}}
                                            </div>
                                            <div class="message-text" *ngIf="message.image=='1'">
                                                <img (click)="imagePreviewModal(message.image_url)" height="100px" width="100px" src="{{message.image_url}}">
                                            </div>
                                            <span class="message-time">
                                        {{message.timestamp | date:'shortTime'}}
                                        <span class="read-tick" [style.color]="message.msg_read_tick ? '#595ca4' : '#b9b8b8' " *ngIf="message.sender_id == sellerId">
                                            <fa-icon [icon]="faCheckDouble"></fa-icon>
                                        </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="chatReply" *ngIf="isClosed ==0">
                            <div class="reply-main">
                                <div id="ta-frame">
                                    <textarea [(ngModel)]="message" class="form-control" rows="1" id="comment"></textarea>
                                </div>
                            </div>
                            <div class="reply-icon reply-recording">
                                <label class="attachFile fileuplodNw">
                                    <input type="file" id="send_image" class="send_image"style="display: none;" (change)="fileUpload($event)">
                                     <input type="hidden">
                                    <i class="fas fa-paperclip fa-rotate-45" aria-hidden="true"></i>
                                    </label>
                            </div>
                            <div class="reply-icon reply-send" (click)="sendMsg()">
                                <i class="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="imgPreview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog customModalSize" role="document">
        <div class="modal-content">
            <div class="modal-headerIcon">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <!-- <div class="modal-body"> -->
                <img src="{{previewImage}}">
            <!-- </div>  -->
            <!-- <div class="modal-footer">
            </div> -->
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>