import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckHirerGuard implements CanActivate {

  constructor(
    private router:Router
  ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let user_type = localStorage.getItem('SelectedUser');
     
      
    if(user_type === '2'){
      
        return true;
  
    }else{
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    }
  
  }
  
}
