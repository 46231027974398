import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA, OnChanges, AfterContentInit  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GigService } from 'src/app/services/gig-service';

@Component({
  selector: 'app-track-gig',
  templateUrl: './track-gig.component.html',
  styleUrls: ['./track-gig.component.css']
})

export class TrackGigComponent implements OnInit,OnChanges,AfterContentInit {
  title = 'Hirer- Track Livlouder';
  userMetaId;
  currentLat:number;
  currentLong:number;
  zoom: number = 8;
  mapShow:boolean = false;
  constructor(
    private gigService:GigService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private titleService:Title
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.activatedRoute.paramMap.subscribe((param)=>{
     
      this.userMetaId = param.get('id') // + used for convert string to number.
     
      this.track(this.userMetaId);
     
   
    
    })
  }

  ngOnChanges(){
    this.track(this.userMetaId);
  }

  ngAfterContentInit(){
  
  }

  sideBarViewOpen() {
    let body = document.getElementsByTagName('body')[0];
    let withcalc = document.getElementById("sideBarView");
    let modalOverlay = document.getElementById("modalOverlay");

      body.classList.add("overflow");
      modalOverlay.classList.add("overlay-show");
      withcalc.classList.add("withcalc");
      //   if(screen.width<=767){
      //       withcalc100.classList.add("withcalc100");
      //   }else{
      //       withcalc.classList.add("withcalc");
      // }
  }
  sideBarViewClose() {
    let body = document.getElementsByTagName('body')[0];
    let withcalc = document.getElementById("sideBarView");
    let modalOverlay = document.getElementById("modalOverlay");

      body.classList.remove("overflow");
      withcalc.classList.remove("withcalc");
      modalOverlay.classList.remove("overlay-show");       
  }

  /**
   * Track livlouder location
   * @param livlouder_meta_id
   */
  track(assignedUserMetaId){

    this.gigService.trackLivlouder(assignedUserMetaId).subscribe(res=>{
    this.currentLat = parseInt(res.data.currentLat);
    this.currentLong = parseInt(res.data.currentLong);
    this.mapShow = true;
    },err=>{
      console.log(err);
    })
  }

}
