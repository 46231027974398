import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
    serverUrl = environment.baseUrl;
    constructor(private http: HttpClient) { }
  

    addCard(formData){
        const httpHeaders = new HttpHeaders({
            'authorization': 'asdffdsafdfdsfsdf',
            'Device-Id': '23232332',
            'Device-Type': '3',
            'Device-Timezone': 'UTC'
          })
      
          //var body = 'email=myusername@gmail.com&password=Pass123@$';
          return this.http.post<any>(`${this.serverUrl}/user/add_card`, formData,{ headers: httpHeaders })
      
    }

    getStripeCusID(){
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      return currentUser.stripeCustomerId;
    }

    getStripeAccountID(){
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      return currentUser.stripeAccountId;
    }

    getCardList(stripeCustId){

      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      var param  =new  HttpParams().set('stripeCustomerId',stripeCustId)
  
      //var body = 'email=myusername@gmail.com&password=Pass123@$';
      return this.http.get<any>(`${this.serverUrl}/user/card_list`,{ headers: httpHeaders,params:param })
    }

    verifyLinkAccount(formData){
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

      //var body = 'email=myusername@gmail.com&password=Pass123@$';
      return this.http.post<any>(`${this.serverUrl}/user/account_link`,formData,{ headers: httpHeaders })
    }

   getStripeBankId(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.stripeBankId;
   } 

   addBankAccount(formData){
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    
    return this.http.post<any>(`${this.serverUrl}/user/bank_account`,formData,{ headers: httpHeaders })

   }

   updateStripeBankId(stripeBankId){
   
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.stripeBankId = stripeBankId;

    localStorage.setItem("currentUser",JSON.stringify(currentUser));

  }

  accountDetails(accountId){
    
      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })

     let params = new HttpParams().set('accountId', accountId);
     return this.http.get<any>(`${this.serverUrl}/user/account_details`, { headers: httpHeaders,params:params })

    
  }


  // remove card 
  removeCard(formData){

    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    return this.http.post<any>(`${this.serverUrl}/user/card/`,formData,{headers: httpHeaders })


  }
  
  updateBankAccount(formData){
    const httpHeaders = new HttpHeaders({
      'authorization': 'asdffdsafdfdsfsdf',
      'Device-Id': '23232332',
      'Device-Type': '3',
      'Device-Timezone': 'UTC'
    })

    
    return this.http.post<any>(`${this.serverUrl}/user/update_account_details`,formData,{ headers: httpHeaders })

  }

    

}