<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="gigDetailsPage sec-pd-30">
        <div class="container">
            <div class="gigsList">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-7">
                        <div class="widgetBox">
                            <div class="gigsDetailsInfo">
                                <div class="gigTitleDtail">
                                    <h2>Booking Summary</h2>
                                </div>
                                <div class="gigTitleDtail">
                                    <h3>{{gigData.title}}</h3>
                                </div>
                                <div class="jobOpen">
                                    <!-- <label>{{gigData.type}}</label> -->
                                    <label>{{gigData.type}}</label><span *ngIf="gigData.typeId != null">(solo)</span>
                                    <span *ngIf="gigData.typeId == null">(group)</span>

                                </div>
                                <div class="priceBlk">
                                    <p>{{gigData.currency}}{{gigData.price}}</p>
                                </div>
                                <p *ngIf="gigData.isRemote != 1" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>{{gigData.location}}</span></p>

                                <p *ngIf="gigData.isRemote == 1" class="gigLcDetail"><i
                                        class="fa fa-map-marker-alt"></i> <span>Remote</span></p>
                                <div class="gigTypeBlk">
                                    <h2 class="infoHead">SubType</h2>
                                    <div class="typeBlk">
                                        <div class="typeItem">{{gigData.subtype}}</div>
                                        <!-- <div class="typeItem">Security</div> -->
                                    </div>
                                </div>
                                <div class="dateFlex">
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>From</p>
                                            <h4>{{gigData.startDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>To</p>
                                            <h4>{{gigData.endDateTime}}</h4>
                                        </div>
                                    </div>
                                    <div class="dateItem">
                                        <div class="gigDateInfo">
                                            <p>Time Duration</p>
                                            <h4>{{gigData.time}}</h4>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="ratePriceBlk">
                                    <div class="jobOpen">
                                        <label>Assigned</label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <div class="widgetBox">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Livlouder Infomation</h2>

                                <ng-container *ngFor="let gigOffer of offers">
                                    <div class="reviewsList">

                                        <div class="reviewsItem">
                                            <div class="reviewsCntBlk">
                                                <div class="reviewUserImg">
                                                    <a href="javascript:void(0);">
                                                        <img src="{{gigOffer.profileImage}}">
                                                    </a>
                                                </div>
                                                <div class="reviewsCnt">
                                                    <a href="javascript:void(0);">
                                                        <h2>{{gigOffer.fullName}}</h2>
                                                    </a>
                                                    <div class="rateTimeMeta">
                                                        <!-- <p>{{gigOffer.agoTime}}</p> -->
                                                    </div>
                                                </div>

                                                <!-- <div class="ratingView ml-auto">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </div> -->
                                                <container_element [ngSwitch]="gigOffer.rating">
                                                    <inner_element *ngSwitchCase="0">
                                                        <div class="ratingView ml-auto">
                                                            <div class="ratingBlk">
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </inner_element>

                                                    <inner_element *ngSwitchCase="1">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star "></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                    </inner_element>

                                                    <inner_element *ngSwitchCase="2">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>

                                                    </inner_element>

                                                    <inner_element *ngSwitchCase="3">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>

                                                    </inner_element>
                                                    <inner_element *ngSwitchCase="4">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star"></i>
                                                        </div>

                                                    </inner_element>
                                                    <inner_element *ngSwitchCase="5">
                                                        <div class="ratingBlk">
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                            <i class="fas fa-star filled"></i>
                                                        </div>

                                                    </inner_element>
                                                </container_element>
                                            </div>

                                            <div class="retDec offersInfo">
                                                <div class="offerActBlk">
                                                    <div class="offerPrice">
                                                        <label>Offer Price</label>
                                                        <h3>${{gigOffer.price}}</h3>
                                                    </div>
                                                    <!-- <div class="" *ngIf="gigData.gigStatus == 1">
                                                        <button class="dropdown-item" href="javascript:void(0)"
                                                            (click)="acceptOfferPopup(gigOffer)"
                                                            class="btn thm-btn-sm">Accept</button>
                                                    </div> -->
                                                    <div class=""
                                                        *ngIf="livloudInfo.userId == gigOffer.userId">
                                                        <button disabled type="button"
                                                            class="btn thm-btn-sm">Accepted</button>
                                                    </div>
                                                </div>
                                                <div class="offferText">
                                                    <p [class.show]="show">{{gigOffer.comment}}</p>
                                                    <button *ngIf="gigOffer.comment.length > 122"
                                                        (click)="show = !show">{{ show ? 'Show less': 'Show
                                                        More' }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="">
                                    <button (click)="gigPayment()" class="btn thm-btn btn-block">Confirm to
                                        Book</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <div class="modal fade csModal" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Payment Deduction</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
            <div class="paymentDeduct">
                <svg-icon class="svgIcon" src="assets/img/payment.svg"></svg-icon>
                <h3>Amount to be paid</h3>
                <h1>$600.00</h1>
                <p>The Amount will get debited from your account and  securely held within an Escrow account until the  the task is complete.</p>
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn thm-btnGray" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn thm-btn">Pay</button>
      </div>
    </div>
  </div>
</div> -->
<app-footer-hirer></app-footer-hirer>