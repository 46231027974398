import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HirerProfileService } from '../../services/hirer-profile.service';
import { AuthService } from '../../services/auth.service';
import { SignupService } from '../../services/signup.service';
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { CommonService } from '../../services/common.service'
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';
import { GroupRequest } from 'src/app/services/group-request';

declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
	selector: 'app-hirer-create-profile',
	templateUrl: './hirer-create-profile.component.html',
	styleUrls: ['./hirer-create-profile.component.css']
})
export class HirerCreateProfileComponent implements OnInit {

	businessType: any;
	basicInfoFrom: FormGroup;
	completeStepTwo: FormGroup;
	socialLinksForm: FormGroup;
	uploadImageForm: FormGroup;
	photoSkip = 1;
	submitStepTwoBtn;
	stepNo = 2;
	userType = 2;
	skipLinks: boolean = true;
	// options: {
	// 	componentRestrictions: {
	// 		country: ['AU']
	// 	}
	// }

	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}
	type12: any = 1;
	lat: any;
	lng: any;
	suburb: any;
	profileDone;
	galleryImg = [];
	type = [];
	mediaType = ['facebook', 'twitter', 'instagram'];
	asd1 = [];
	selectedLink;
	allSocialLink = [];
	media = [];
	video = [];
	linkDyna = [];
	showAddMoreBtn = true;
	newLink: any = {
		'id': '',
		'type': 'globe',
		'socialLink': '',
		'social_media_type_id': '',
		'image': '',
		'isShow': 'true',
	};
	stype = [];
	socialtype;
	location;
	metaId;

	constructor(
		private hirerService: HirerProfileService,
		private authService: AuthService,
		private signupService: SignupService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private commonService: CommonService,
		private firebaseService: FirebaseService,
		private groupRequest: GroupRequest
	) { }

	ngOnInit(): void {

		localStorage.setItem("SelectedUser", '2');

		if (this.authService.getHirerProfile()) {
			this.router.navigate(['/myGigsHirer']);

		}

		this.signupService.socialType().subscribe(
			(data) => {
				hideLoader();
				data.data.forEach((element) => {
					element.isShow = true;
					this.allSocialLink.push(element);
				});
			},
			(err) => {
				alert('Something went wrong please try again');
			}
		);

		this.linkDyna.push(this.newLink);

		var stepNo = this.authService.getHirerBoardingProfileCount();

		this.authService.setUserMetaId(this.userType);

		this.stepNo = parseInt(stepNo) + 1;


		this.hirerService.getBusinessType().subscribe(data => {

			this.businessType = data.data;

		}, err => {
			hideLoader();

			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})

		this.basicInfoFrom = new FormGroup({
			'name': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
			'location': new FormControl(null, [Validators.required]),
			'venue': new FormControl(null, [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]),
			'businessType': new FormControl(1),

			'profileImage': new FormControl(null, [Validators.required]),

		});

		this.completeStepTwo = new FormGroup({
			'about': new FormControl(null, [Validators.maxLength(300), Validators.minLength(5), this.noWhitespaceValidator]),

			'openingHour': new FormArray([
				new FormControl(null, [Validators.required, this.noWhitespaceValidator]),

			])
		})

		this.uploadImageForm = new FormGroup({
			'file': new FormControl(null),
			"fileSource": new FormControl(null)
		})


		this.socialLinksForm = new FormGroup({
			'links': new FormArray([
				new FormControl(null, [this.noWhitespaceValidator]),

			]),
			"socialMedia": new FormControl(null)
		})

		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.metaId = localStorage.getItem('metaId') ?? currentUser.userTypeList[1].userMetaId;
		this.groupRequest.getMedia(this.metaId, 1).subscribe((res) => {
		
			hideLoader();
			this.galleryImg = res.data;
		});
		this.groupRequest.getMedia(this.metaId, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;
		});
	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}

	public handleAddressChange(address: any) {


		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		this.location = address.formatted_address;


	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}

	onFileChange(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.basicInfoFrom.patchValue({

				'profileImage': file

			});

		}



	}


	stepOne(event) {
		if (this.basicInfoFrom.invalid) {

			this.validateAllFormFields(this.basicInfoFrom);
			return false;

		} else {
			
			//this.basicInfoFrom.get('businessType').setValue(this.businessType[0].id);


			// if(this.basicInfoFrom.value.businessType == null){
			// 	this.type12 = this.businessType[0].id
			// 	//this.type12 = this.basicInfoFrom.get('businessType').setValue(this.businessType[0].id);

			// }else{
			// 	this.type12 = this.basicInfoFrom.value.businessType
			// } 
			//alert(this.basicInfoFrom.value.businessType);
			//	return false;

			if (this.location == undefined && this.lat == undefined && this.lng == undefined) {
				this.toastService.showError('Please Select valid Location', 'Error');
				return false;
	
	
			}else {
				var metaId = this.authService.getUserMetaId();

				const formData = new FormData();
				formData.append('location', this.location);
				formData.append('venueName', this.basicInfoFrom.value.venue)
				formData.append('businessType', this.basicInfoFrom.value.businessType);
				formData.append('metaId', metaId)
				formData.append('onBordingStep', '2')
				formData.append('isPrivate', '2')
				formData.append('userType', '2')
				formData.append('profileImage', this.basicInfoFrom.value.profileImage)
				formData.append('latitude', this.lat)
				formData.append('longitude', this.lng)
				formData.append('suburb', this.suburb)
				formData.append('name', this.basicInfoFrom.value.name)

				showLoader();
				this.signupService.stepOne(formData).subscribe((data) => {
					hideLoader();
					this.toastService.showSuccess(data.message, "Success");
					this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)
					//this.returnData = data;
					//this.filterFunction(data)
					//this.localStorageService.set('userDetail',JSON.stringify())

					var step = this.authService.getHirerBoardingProfileCount();

					this.stepNo = parseInt(step) + 1;

				}, err => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				});
			}
		}
	}

	addOpeningHour() {
		(<FormArray>this.completeStepTwo.get('openingHour')).push(new FormControl(null, [Validators.required, this.noWhitespaceValidator]));

	}

	removeOpeningHour(index) {
		(<FormArray>this.completeStepTwo.get('openingHour')).removeAt(index)

	}

	stepTwo() {

		this.submitStepTwoBtn = true;

		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo);
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();


			const formData = new FormData();
			if (this.completeStepTwo.value.about) {
				formData.append('venueAbout', this.completeStepTwo.value.about);
			}

			formData.append('openingTime', JSON.stringify(this.completeStepTwo.value.openingHour));
			formData.append('userType', '2');
			formData.append('metaId', metaId)
			formData.append('onBordingStep', '3')
			formData.append('isPrivate', '2')

			showLoader();

			this.signupService.stepTwo(formData).subscribe((data) => {
				hideLoader();
				this.toastService.showSuccess(data.message, "Success");

				this.authService.updateOnBoardingProfileCount(2, data.data.onBoardingProfileCount)

				var step = this.authService.getHirerBoardingProfileCount();

				this.stepNo = parseInt(step) + 1;

			}, err => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}



	}

	onUploadFile(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file
			});

			this.photoSkip = 2;
			this.uploadSingleImg();

		}
	}

	uploadSingleImg() {
		var metaId = this.authService.getUserMetaId();
		const formData = new FormData();
		formData.append('media', this.uploadImageForm.value.fileSource);
		formData.append('userType', '2');
		formData.append('metaId', metaId)
		formData.append('onBordingStep', '4')
		formData.append('mediaType', '1')



		showLoader();
		this.signupService.uploadImage(formData).subscribe((res) => {
			hideLoader();

			let imgData = res.mediaData

			this.galleryImg.push(imgData);


		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		});
	}

	/**
   * Remove added image
   * @param imgId 
   * @param index 
   */
	removeImg(imgId, index) {

		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader()
		this.signupService.deleteMedia(formData).subscribe(res => {
			hideLoader()
			this.galleryImg.splice(index, 1);
			if (this.galleryImg.length < 1) {
				this.photoSkip = 1;
			}
			this.toastService.showSuccess(res.message, 'Success');
		}, err => {
			hideLoader()
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
			// console.log(err);
		})
	}

	UploadImgStepThree() {
		var metaId = this.authService.getUserMetaId();

		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append('metaId', metaId)
			formData.append('onBordingStep', '4')
			formData.append('type', '2')

			this.signupService.skipPhoto(formData).subscribe((data) => {
				hideLoader();

				this.toastService.showSuccess(data.message, "Success");

				this.stepNo = 5;
			}, err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				this.stepNo = 5;
				//this.error = error.message;
			});
		} else {

			this.stepNo = 5;

		}
	}



	onSelectedVideo(event) {
		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append('authToken', this.authService.getAuthorizationToken());
					formData.append('metaId', userMetaId);
					formData.append('userType', '2');
					formData.append('mediaType', '2');
					formData.append('onBordingStep', '4')
					formData.append('media', file);
					formData.append('video_thumb', res.blobImage, 'video_thumb.png');

					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();
						this.authService.checkValidToken(data);

						if (data.status == 'fail') {
							this.toastService.showError(data.message, 'Error');
						} else {
							this.toastService.showSuccess(data.message, 'Success');


							this.authService.updateOnBoardingProfileCount(2, data.mediaData.onBoardingProfileCount)

							var step = this.authService.getHirerBoardingProfileCount();

							this.stepNo = parseInt(step) + 1;

							this.groupRequest.getMedia(userMetaId, 2).subscribe((res) => {
								this.video = res.data;
							});

							//this.stepNo = 6; 
							//this.getMediaList();
						}
					}, err => {
						hideLoader();

						if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
							this.authService.checkValidToken(err.error);
							this.toastService.showError('Session Expired', 'Error');

						} else {
							this.toastService.showError(err.error.message, 'Error');
						}
					});
				} else {
					hideLoader();
					this.toastService.showError(res.message, 'Error');
					//  toastrError(res.message);
				}
			});

		}
	}

	skipPhoto(metaId) {

		showLoader();

		const formData = new FormData();

		formData.append('metaId', metaId)
		formData.append('onBordingStep', '5')
		formData.append('type', '1')

		this.signupService.skipPhoto(formData).subscribe((data) => {

			hideLoader();
			localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
			this.toastService.showSuccess(data.message, "Success");

			let index = data.userDetail.userTypeList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')),
				hirerUserSignupObj = {

					"online": 1,
					"token": localStorage.getItem('device-token'),
					"type": "2",
					"user_id": data.userDetail.userId,
					"name": data.userDetail.userTypeList[index].fullName,
					"profile_image": data.userDetail.userTypeList[index].profileImage,
					"last_seen": ""
				},

				hirerUserMetaSignupObj = {

					"user_id": data.userDetail.userId,
					"typing": 0,
					"name": data.userDetail.userTypeList[index].fullName,
					"profile_image": data.userDetail.userTypeList[index].profileImage,
					"online": 1,
					"user_type": 2
				};

			this.firebaseService.userHirerPvAddFB(data.userDetail.userId, hirerUserSignupObj);
			this.firebaseService.userMetaAddFB(data.userDetail.userId, hirerUserMetaSignupObj);

			this.router.navigate(['/createProfileDone']);
		}, err => {
			hideLoader();

			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
			this.stepNo = 5;
			//this.error = error.message;
		});

	}

	/**
	 * skip upload video
	 */
	skipVideo() {
		var metaId = this.authService.getUserMetaId();

		showLoader();

		const formData = new FormData();

		formData.append('metaId', metaId)
		formData.append('onBordingStep', '4')
		formData.append('type', '1')

		this.signupService.skipPhoto(formData).subscribe((data) => {
			hideLoader();

			this.toastService.showSuccess(data.message, "Success");

			this.stepNo = 6;
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
			this.stepNo = 5;
			//this.error = error.message;
		});
	}



	addLinks() {


		if (this.stype.length != this.linkDyna.length) {
			this.toastService.showError('Please select Social Link type', 'Error');
			return false;
		}

		// this.socialLinksForm = new FormGroup({
		// 	'links': new FormArray([
		// 		new FormControl(null,[Validators.required]),

		// 	]),
		// 	"socialMedia": new FormControl(null,[Validators.required])
		// })


		var checkvalue = this.socialLinksForm.get('links').value;
		let checkStatus = 'true';
		checkvalue.forEach(element => {

			if (element == null || element == "") {
				checkStatus = 'false';
			}
		});
		if (checkStatus == 'false') {
			// this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');
			return false;
		} else {
			// this.linkDyna = this.linkDyna;
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required, this.noWhitespaceValidator])
			);

			let newLinks: any = {
				'id': '',
				'type': 'globe',
				'socialLink': '',
				'social_media_type_id': '',
				'image': '',
				'isShow': 'true',
			};
			this.linkDyna.push(newLinks);

		}
	}

	// removeLinks(index) {
	// 	(<FormArray>this.socialLinksForm.get('links')).removeAt(index)
	// }

	removeLinks(index) {

		this.stype.splice(index, 1);
		let indexCheck = this.allSocialLink.findIndex((x) => x.id == this.linkDyna[index].social_media_type_id);

		if (indexCheck != -1) {

			this.allSocialLink[indexCheck].isShow = true;
		}

		this.linkDyna.splice(index, 1);
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index);
		this.checkSocialTypeExist();
	}

	checkSocialTypeExist() {

		var asd = this.allSocialLink;
		this.showAddMoreBtn = false;
		//setTimeout(function () {
		asd.forEach((element) => {
			if (element.isShow == true) {
				this.showAddMoreBtn = true;

			}
		})

		//}, 4000)


	}

	changeLinks(event) {
		var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;

		if (val) {
			this.skipLinks = false;
		} else {
			this.skipLinks = true;
		}



	}
	uploadLinks() {


		if (this.socialLinksForm.invalid) {

			this.validateAllFormFields(this.socialLinksForm)
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();
			var val = (<FormArray>this.socialLinksForm.get('links')).at(0).value;
			if (!val) {
				this.skipPhoto(metaId);

				return false;
			}
			var linksArray = [];

			var linkSocial = this.socialLinksForm.value.links;
			var type = this.stype;

			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({
							link: linkSocial[i],
							type: this.stype[i],
						});
					}
				}
				if (this.stype.length != this.linkDyna.length) {
					this.toastService.showError('Please select Social Link type', 'Error');
					return false;
				}
				linksArray.forEach(function (value) {

					if (value.type == undefined) {
						alert('Please select Type');
						return false;
					}
				});

				this.socialLinksForm.patchValue({
					socialMedia: linksArray,
				});
			}


			showLoader();
			const formData = new FormData();
			formData.append(
				'socialMedia',
				JSON.stringify(this.socialLinksForm.value.socialMedia)
			);

			formData.append('metaId', metaId);
			formData.append('onBordingStep', '5');
			formData.append('type', '1')


			this.signupService.uploadSocialLinks(formData).subscribe(
				(data) => {
					hideLoader();
					// console.log('testitghjghjgh',data);
					// console.log('final',index);
					// console.log('finalize',data.userDetail.userTypeList[index]);
					// console.log('finalize',data.userDetail.userTypeList[index].fullName);
					// console.log('finalize',data.userDetail.userTypeList[index].profileImage);


					localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
					this.toastService.showSuccess(data.message, 'Success');
					let index = data.userDetail.userTypeList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')),
						hirerUserSignupObj = {

							"online": 1,
							"token": localStorage.getItem('device-token'),
							"type": "2",
							"user_id": data.userDetail.userId,
							"name": data.userDetail.userTypeList[index].fullName,
							"profile_image": data.userDetail.userTypeList[index].profileImage,
							"last_seen": ""
						},

						hirerUserMetaSignupObj = {

							"user_id": data.userDetail.userId,
							"typing": 0,
							"name": data.userDetail.userTypeList[index].fullName,
							"profile_image": data.userDetail.userTypeList[index].profileImage,
							"online": 1,
							"user_type": 2
						};

					this.firebaseService.userHirerPvAddFB(data.userDetail.userId, hirerUserSignupObj);

					this.firebaseService.userMetaAddFB(data.userDetail.userId, hirerUserMetaSignupObj);



					this.router.navigate(['/createProfileDone']);

				},
				(err) => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
					//this.error = error.message;
				}
			);
		}
	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});

		return false;
	}



	changeIcon(index, val) {

		var arr = [
			`<i class="fab fa-facebook-square"></i>`,
			`<i class="fab fa-twitter-square"></i>`,
			`<i class="fab fa-instagram"></i>`,
		];



		var oldId = this.linkDyna[val].social_media_type_id;


		this.linkDyna[val].type = this.allSocialLink[index].type;

		this.linkDyna[val].social_media_type_id = this.allSocialLink[index].id;

		this.allSocialLink[index].isShow = false;
		if (oldId != this.allSocialLink[index].id) {

			let indexnew = this.allSocialLink.findIndex((x) => x.id == oldId);

			if (indexnew != -1) {

				this.allSocialLink[indexnew].isShow = true;
			}
		}

		this.stype[val] = this.allSocialLink[index].id;
		this.checkSocialTypeExist();
	}

	/**
	 * Remove added image
	 * @param imgId
	 * @param index
	 */
	removideo(imgId, index) {
		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			(res) => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}

				this.groupRequest.getMedia(this.metaId, 2).subscribe((res) => {
					hideLoader();
					this.video = res.data;
				});
				this.toastService.showSuccess(res.message, 'Success');
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}
}
