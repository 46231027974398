import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	ElementRef,
} from "@angular/core";
import {
	FormBuilder,
	Validators,
	FormGroup,
	FormControl,
	FormArray,
	AbstractControl,
	ValidationErrors
} from "@angular/forms";
// import { Router } from '@angular/router';
import { ToastrNotificationService } from "../../services/toastr-notification.service";
import { AuthService } from "../../services/auth.service";
import { SignupService } from "../../services/signup.service";
import { GigService } from "../../services/gig-service";
// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../services/common.service";
import { formatDate } from "@angular/common";
import { Title } from '@angular/platform-browser';

declare var $: any;
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-create-gig",
	templateUrl: "./create-gig.component.html",
	styleUrls: ["./create-gig.component.css"],
})
export class CreateGigComponent implements OnInit, AfterViewInit {
	createGigStepOne: FormGroup;
	createGigStepTwo: FormGroup;
	uploadImageForm: FormGroup;
	typeSubtype: any;
	typeSubtype1: any;
	userType = 2;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	stepNo = 1;
	title = 'Hirer - Create Gig';


	// options: {
	//   componentRestrictions: {
	// 	country: ["AU"];
	//   };
	// };
	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}
	lat: any;
	lng: any;
	suburb: any;
	check: any;
	location = "";
	model;
	endModel;
	imgId: any = [];
	// isChecked;
	// isCheckedName;
	typeIdSelected = [];
	subTypeIdSelected = [];



	//added code...
	typeIdSelected1 = [];

	typeListData1 = [];
	subTypeSelected1 = [];

	subTypeArray1 = [];
	subTypeIdSelected1 = [];





	//added code...
	timezone_offset_min: any;

	formData = new FormData();
	formSubmit;
	galleryImg = [];
	radioButton = 0;
	value;
	value1 = 1;
	solo_group = 1;
	minDate: any;

	//@ViewChild("placesRef") placesRef : GooglePlaceDirective;

	constructor(
		private activatedRoute: ActivatedRoute,
		private toastrService: ToastrNotificationService,
		private authService: AuthService,
		private signupService: SignupService,
		private commonService: CommonService,
		private gigService: GigService,
		private router: Router,
		private titleService: Title,

	) { }

	ngOnInit(): void {



		this.titleService.setTitle(this.title);

		this.activatedRoute.paramMap.subscribe((param) => {
			this.check = param.get("id"); // + used for convert string to number.
		}); // this.check = this.router.snapshot.params.id;

		$("#radio12").trigger("click");
		// this.asd  = $("input[name='remote1']:checked").val();
		// alert(this.asd);

		this.authService.setUserMetaId(this.userType);

		this.createGigStepOne = new FormGroup({
			title: new FormControl(null, [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]),
			location: new FormControl(' ', this.radioButton != 2 ? [Validators.required] : []),
			price: new FormControl(null, [
				Validators.required,
				Validators.min(1),
				Validators.max(999999.99),
				// Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
				Validators.pattern("^([0-9]{1,6})((.[0-9]{2})?)$"),
				Validators.maxLength(9),
			]),
			startDate: new FormControl(null, [
				Validators.required,
				//this.startDateValidate,
			]),
			startTime: new FormControl(null, [
				Validators.required,
				this.timeValidation,
			]),
			endDate: new FormControl(null, [
				Validators.required,
				//this.startDateValidate,
			]),
			endTime: new FormControl(null, [
				Validators.required,
				this.timeValidation,
			]),
			gigType: new FormControl(null, [Validators.required]),

		});

		this.createGigStepTwo = new FormGroup({
			//'type':new FormControl (null, [Validators.required]),
			//'subType':new FormControl (null, [Validators.required]),
			about: new FormControl(null, [
				Validators.required,
				this.noWhitespaceValidator,
				Validators.maxLength(300),
				Validators.minLength(5),
			]),

			mustHave: new FormArray([new FormControl(null, [Validators.required, this.noWhitespaceValidator])]),

			typeBlankCheck: new FormControl(null, [Validators.required]),
			subTypeBlankCheck: new FormControl(null, [Validators.required]),


		});

		this.uploadImageForm = new FormGroup({
			file: new FormControl(null),
			fileSource: new FormControl(null),
		});

		this.signupService.getTypeSubType().subscribe((data) => {
			this.typeSubtype = data.data;
		});

		this.signupService.getGroupTypeSubType().subscribe((data) => {
			this.typeSubtype1 = data.data;
		});

		this.minDate = this.getCurrentDateObject();
	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}


	// date format with object
	getCurrentDateObject() {
		return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	ngAfterViewInit(): void {
		$("#radio12").trigger("click");
		$("#clickid").trigger("click");
	}


	changerdo(value) {
		this.radioButton = value;
		this.value = this.radioButton
	}

	solo_group_type(value) {
	
		if (value == 1) {
			this.typeListData1 = [];
			this.typeIdSelected1 = [];
			this.subTypeArray1 = [];
			this.subTypeIdSelected1 = [];
			this.subTypeSelected1 = [];
		}else {
			this.typeListData = [];
			this.typeIdSelected = [];
			this.subTypeArray = [];
			this.subTypeIdSelected = [];
			this.subTypeSelected = [];
		}
		this.solo_group = value;
		this.value1 = this.solo_group;
		// console.log('val2',this.value1);
	}

	startDateValidate(control: FormControl): { [key: string]: boolean } | null {
		var startdate = $("#startDate").val();
		var enddate = $("#endDate").val();

		if (startdate && enddate) {
			if (new Date(startdate).getTime() > new Date(enddate).getTime()) {
				return { inValidStartDate: true };
			}
		}

		return null;
	}

	timeValidation(control: FormControl): { [key: string]: boolean } | null {
		var startdate = $("#startDate").val();
		var enddate = $("#endDate").val();
		var startTime = $(".startTime").val();
		var endTime = $(".endTime").val();

		if (startdate && startdate) {
			if (new Date(startdate).getTime() == new Date(enddate).getTime()) {
				if (startTime && endTime) {
					if (startTime > endTime) {
						return { inValidTime: true };
					}
				}
			}
		}

		return null;
	}

	public handleAddressChange(address: any) {
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;
	}
	// getValue(){
	// var asd  = $("input[name='remote1']:checked").val();
	// 	alert(asd);
	// }

	createGig($event) {
		if (this.radioButton != 1 && this.location == "") {
			this.toastrService.showError('Location is required', "Error");
			return false;
		}

		if (!this.createGigStepOne.valid) {
			this.validateAllFormFields(this.createGigStepOne);
			return false;
		} else {

			// return false;
			var startDateControl = this.createGigStepOne.value.startDate;
			var startDate = this.formatDate(startDateControl);

			//added code...
			var startTime = this.createGigStepOne.value.startTime;
			var timezone_offset_minutes = new Date().getTimezoneOffset();
			this.timezone_offset_min =
				timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;

			var endDateControl = this.createGigStepOne.value.endDate;
			var endDate = this.formatDate(endDateControl);


			if (startDate > endDate) {

				// alert('if')
				this.toastrService.showError('End date must be equal or greater than start date.', "Error");
				return false;

			}

			if (startDate == endDate) {
				if (startTime >= this.createGigStepOne.value.endTime) {
					// alert('time error');
					this.toastrService.showError('End time must be greater than  start time.', "Error");
					return false;

				}
			}
			// else if(startDate == endDate){
			// 	if(startTime >= this.createGigStepOne.value.endTime){
			// 		alert('errorTime')
			// 	}
			// }else{
			// 	alert('error')
			// }
			// return false;

			this.formData.append("title", this.createGigStepOne.value.title);
			this.formData.append("location", this.location);
			this.formData.append("is_remote", this.value);

			this.formData.append("price", this.createGigStepOne.value.price);
			this.formData.append("jobLat", this.lat);
			this.formData.append("jobLong", this.lng);
			this.formData.append("startDate", startDate);
			this.formData.append("startTime", startTime);
			this.formData.append("endDate", endDate);
			this.formData.append("endTime", this.createGigStepOne.value.endTime);
			this.formData.append("gigFor", this.createGigStepOne.value.gigType);
			this.formData.append("suburb", this.suburb);
			// this.formData.append("timezone_offset_minutes", this.timezone_offset_min);
			this.formData.append("utcDatetime", startDate + ' ' + startTime);

			this.stepNo = 2;
		}
	}

	formatDate(date) {
		var day;
		var month;

		if (date.month.toString().length == 1) {
			month = "0" + date.month;
		} else {
			month = date.month;
		}

		if (date.day.toString().length == 1) {
			day = "0" + date.day;
		} else {
			day = date.day;
		}

		return date.year + "-" + month + "-" + day;
	}

	onCheckboxChangeRole(type, event) {
		// this.subTypeSelected = [];
		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.subTypeIdSelected = [];
		}
	
		if (event.target.checked) {
			const checkTypeData = this.typeListData.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected = [];
				this.subTypeIdSelected = [];
				// this.subTypeSelected = [];
				this.typeListData = [];
				this.typeListData.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.typeIdSelected.indexOf(type.categoryId);
			
			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray = [];
				this.typeIdSelected = [];
				this.subTypeArray.push(type);
				this.typeIdSelected.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}

		if (this.typeListData.length == 0) {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "1" });
		}
	}

	removeType(index) {

		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}

		var abc = this.subTypeIdSelected;

		$.each(this.subTypeSelected, function (key, value) {
			var cat = value.categoryId;
			let index = abc.indexOf(cat);
			if (index > -1) {
				abc.splice(index, 1);
			}
		});


		//remove subtype
		for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
			if (this.subTypeSelected[i].parentId == parent_id) {
				this.subTypeSelected.splice(i, 1);
				//this.checkUncheckRole(option.id,false);
			}
		}

		for (i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {
				this.subTypeArray.splice(i, 1);
			}
		}
	}

	onCheckboxChangesubType(subType, event) {


		if (event.target.checked) {
			this.subTypeSelected.push({
				categoryId: subType.categoryId,
				name: subType.name,
				parentId: subType.parent_id,
			});
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					// $('input:checkbox[value="' + this.subTypeSelected[i].categoryId + '"]').removeAttr('checked');

					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		if (this.subTypeSelected.length == 0) {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "1" });
		}
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	removeSubType1(index) {
		let selectedSubType = this.subTypeSelected1[index].categoryId;
		this.subTypeSelected1.splice(index, 1);
		const itemindex = this.subTypeIdSelected1.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected1.splice(itemindex, 1);
		}
	}

	// removeSubType(index){
	// 	this.subTypeSelected.splice(index,1);
	// }

	//Remove must have field
	removeMustHave(index) {
		(<FormArray>this.createGigStepTwo.get("mustHave")).removeAt(index);
	}

	// Add more must have field
	addMoreMustHave() {
		(<FormArray>this.createGigStepTwo.get("mustHave")).push(
			new FormControl(null, [Validators.required, this.noWhitespaceValidator])
		);
	}

	stepTwo(event) {
		//event.stopPropagation();
		this.formSubmit = true;

		if (this.createGigStepTwo.invalid) {
			this.validateAllFormFields(this.createGigStepTwo);
			return false;
		} else {

			var typeStore = this.commonService.dataArrangType(this.typeListData);
			var subTypeStore = this.commonService.dataArrangSubType(
				this.typeListData,
				this.subTypeSelected
			);
			var commaSeparatedSubType =
				this.commonService.commaSeparatedSubType(subTypeStore);
			var metaId = this.authService.getUserMetaId();


			var typeStore1 = this.commonService.dataArrangType(this.typeListData1);
			var subTypeStore1 = this.commonService.dataArrangSubType(
				this.typeListData1,
				this.subTypeSelected1
			);
			var commaSeparatedSubType1 =
				this.commonService.commaSeparatedSubType(subTypeStore1);



			if (this.value1 == 1) {

				// console.log('typeStore',typeStore);
				// console.log('commaSeparatedSubType',commaSeparatedSubType);return false;

				if (typeStore == "") {
					this.toastrService.showError('Type is required', "Error");
					return false;
				}

				if (commaSeparatedSubType == "") {
					this.toastrService.showError('Subtypes is required', "Error");
					return false;
				}

				this.formData.append("roleId", typeStore);
				this.formData.append("typeId", commaSeparatedSubType);

			} else {

				if (typeStore1 == "") {
					this.toastrService.showError('Type is required', "Error");
					return false;
				}

				if (commaSeparatedSubType1 == "") {
					this.toastrService.showError('Subtypes is required', "Error");
					return false;
				}
				this.formData.append("roleGroupId", typeStore1);
				this.formData.append("typeGroupId", commaSeparatedSubType1);
			}


			this.formData.append("about", this.createGigStepTwo.value.about);
			this.formData.append(
				"specifications",
				JSON.stringify(this.createGigStepTwo.value.mustHave)
			);



			this.stepNo = 3;
		}
	}

	onUploadFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file,
			});
		}

		showLoader();
		const formData = new FormData();
		formData.append("image", this.uploadImageForm.value.fileSource);
		this.gigService.uploadGigImage(formData).subscribe(
			(data) => {
				hideLoader();

				this.imgId.push(data.mediaId);
				this.galleryImg.push(data);
			},
			(err) => {
				hideLoader();
				this.toastrService.showError(err.error.message, "Error");
			}
		);
	}

	/**
	 * Remove added image
	 * @param imgId
	 * @param index
	 */
	removeImg(imgId, index) {
		$(this).parent(".one").remove();
		const formData = new FormData();
		formData.append("mediaId", imgId);
		formData.append("onBordingStep", "4");

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			(res) => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
				}
				this.toastrService.showSuccess(res.message, "Success");
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
				//console.log(err);
			}
		);
	}

	// changeStartDate(){

	// }

	saveGig() {
		
		$("#myApplyId").prop('disabled', true);

		if (this.uploadImageForm.invalid) {
			this.validateAllFormFields(this.uploadImageForm);
			return false;
		}

		if (this.imgId) {
			this.formData.append("imageId", this.imgId);
		}

		this.gigService.createGig(this.formData).subscribe(
			(data) => {
				hideLoader();
				this.toastrService.showSuccess(data.message, "Success");

				if (this.check == undefined || this.check == "") {
					this.router.navigate(["/myGigsHirer"]);
				} else {

					if (this.router.url == `/createGigGroup/${this.check}`) {
						// alert(this.router.url);
						this.router.navigate(["/hirerGroups/details/", this.check]);


					} else {
						// alert('this.router.url');

						this.router.navigate(["/discoverHirer/detail/", this.check]);
					}

				}
			},
			(err) => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
				//this.toastrService.showError(err.error.message, "Error");
			}
		);
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}

	onCheckboxChangeRole1(type, event) {
		// this.typeListData1 = [];
		// this.subTypeSelected1 = [];

		if (event.target.checked == false) {
			this.typeIdSelected1 = [];
			this.typeListData1 = [];
			this.subTypeArray1 = [];
			this.subTypeIdSelected1 = [];
		}

		if (event.target.checked) {
			// this.typeListData1.push({
			// 	categoryId: type.categoryId,
			// 	name: type.name,
			// 	parent_id: type.parent_id,
			// });
			// this.subTypeArray1 = [];
			// this.typeIdSelected1 = [];

			// this.subTypeArray1.push(type);
			// this.typeIdSelected1.push(type.categoryId);

			const checkTypeData = this.typeListData1.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected1 = [];
				this.subTypeSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.typeListData1 = [];
				this.typeListData1.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray1.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.subTypeIdSelected1.indexOf(type.categoryId);

			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray1 = [];
				this.typeIdSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.subTypeArray1.push(type);
				this.typeIdSelected1.push(type.categoryId);
				// this.subTypeIdSelected1.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected1.splice(itemindex, 1);
			}
		}

		if (this.typeListData1.length == 0) {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "1" });
		}
	}

	removeType1(index) {
		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype1 = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype1);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected1 = this.subTypeSelected1.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {

				this.subTypeArray1[i].subType.forEach(element => {

					if (this.subTypeIdSelected1.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected1.indexOf(element.categoryId);
						this.subTypeIdSelected1.splice(idexitem, 1);

					}
				});
				this.subTypeArray1.splice(i, 1);
			}
		}


	}

	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected1.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected1.splice(index, 1);
			}
		}

		if (this.subTypeSelected1.length == 0) {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "1" });
		}
	}

	onKeyPress(event: KeyboardEvent) {
		// if (event.key === 'e') {
		// 	event.preventDefault();
		// }
		// const inputElement = event.target as HTMLInputElement;
		// const value = parseInt(inputElement.value + event.key, 10);
		
		// if (value >= 1000000 || inputElement.value.length == 9) {
		//   	event.preventDefault();
		// }

		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
			event.preventDefault();
			return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}

}
