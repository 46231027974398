import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SignupService } from '../../services/signup.service'
import { FormBuilder, Validators, FormGroup, FormControl, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebaseL.service';
import { Router } from '@angular/router';
import { ToastrNotificationService } from '../../services/toastr-notification.service'
import { GigService } from "src/app/services/gig-service";
import { DataService } from '../../services/data.service';
import { GroupRequest } from 'src/app/services/group-request';

declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-livlouder-create-profile',
	templateUrl: './livlouder-create-profile.component.html',
	styleUrls: ['./livlouder-create-profile.component.css']
})
export class LivlouderCreateProfileComponent implements OnInit {
	completeProfileForm: FormGroup;
	completeStepTwo: FormGroup;
	uploadImageForm: FormGroup;
	socialLinksForm: FormGroup;
	optradio: any;
	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	userType = 1;
	stepNo = 2;
	defaultValue;
	images = [];
	photoSkip = 1; // 1== true , 2== false (means photo is selected)
	selectLinkIcon = 0;
	typeIdSelected = [];
	subTypeIdSelected = [];
	galleryImg = [];
	check_profile;
	metaId;
	check;
	id;
	lat;
	lng;
	suburb;
	location;
	stype = [];
	mediaType = ['facebook', 'twitter', 'instagram'];
	asd1 = [];
	selectedLink;
	allSocialLink = [];
	media = [];
	video = [];
	linkDyna = [];
	socialtype;
	getTypeSubType;

	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }

	}

	showAddMoreBtn = true;
	newLink: any = {
		'id': '',
		'type': 'globe',
		'socialLink': '',
		'social_media_type_id': '',
		'image': '',
		'isShow': 'true',
	};


	@ViewChild('imgDiv', { static: false }) d1: ElementRef;
	@ViewChild('uploadLinksBtn') uploadLinksBtn: ElementRef;
	constructor(
		private signupService: SignupService,
		private fb: FormBuilder,
		private commonService: CommonService,
		private authService: AuthService,
		private toastService: ToastrNotificationService,
		private router: Router,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private firebaseService: FirebaseService,
		private gigService: GigService,
		private dataService : DataService,
		private groupRequest: GroupRequest
	) { }

	ngOnInit(): void {


		this.signupService.socialType().subscribe(
			(data) => {
				hideLoader();
				data.data.forEach((element) => {
					element.isShow = true;
					this.allSocialLink.push(element);
				});
			},
			(err) => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
		this.linkDyna.push(this.newLink);

		const currentUser = JSON.parse(localStorage.getItem('currentUser'));


		if (localStorage.getItem('metaId') === null) {
			this.metaId = currentUser.userTypeList[0].userMetaId;
		} else {
			this.metaId = localStorage.getItem('metaId');
		}
		//this.metaId = currentUser.userTypeList[0].userMetaId;

		this.check_profile = currentUser.livlouderList;

		for (let i = 0; i < this.check_profile.length; i++) {
			this.check = this.check_profile[i].isProfileComplete;
			this.id = this.check_profile[i].userMetaId

			if (this.check_profile[i].userMetaId == this.metaId && this.check_profile[i].isProfileComplete === '1') {
				this.router.navigate(['/gigs']);

			}



			if (this.id == this.metaId) {
				this.stepNo = this.check_profile[i].onBoardingProfileCount;
				//var stepNo  = 1;
			} else {
				this.stepNo = currentUser.userTypeList[0].onBoardingProfileCount;
			}



		}

		localStorage.setItem("SelectedUser", '1');



		this.stepNo = Number(this.stepNo) + 1;
		// this.stepNo = 4;




		$('[data-toggle="tooltip"]').tooltip()

		this.signupService.getTypeSubType().subscribe((data) => {
			//hideLoader();
			this.typeSubtype = data.data;
			//this.filterFunction(data)

		});


		this.completeProfileForm = new FormGroup({
			'fullname': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
			'location': new FormControl(null, [Validators.required]),

			// 'availability':new FormControl (null, [Validators.required]),
			// 'price': new FormControl(null, [Validators.required, Validators.min(1),
			// Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
			// Validators.maxLength(9)]),
			'price': new FormControl(null, [
				Validators.required,
				Validators.min(1),
				Validators.max(999999.99),
				Validators.pattern("^([0-9]{1,6})((.[0-9]{2})?)$"),
				Validators.maxLength(9)
			]),
			'typeBlankCheck': new FormControl(null, [Validators.required]),
			'subTypeBlankCheck': new FormControl(null, [Validators.required]),
			'profileImage': new FormControl(null, [Validators.required]),

		});



		this.completeStepTwo = new FormGroup({
			'about': new FormControl(null, [Validators.maxLength(300), Validators.minLength(5), this.noWhitespaceValidator]),
			'availability': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
			'experience': new FormArray([
				new FormControl(null, [Validators.required, this.noWhitespaceValidator]),

			])
		})

		this.uploadImageForm = new FormGroup({
			'file': new FormControl(null),
			"fileSource": new FormControl(null)
		})


		this.socialLinksForm = new FormGroup({
			'links': new FormArray([
				new FormControl(null),

			]),

			"socialMedia": new FormControl(null)
		})

		this.groupRequest.getMedia(this.metaId, 2).subscribe((res) => {
			hideLoader();
			this.video = res.data;
		});

	}

	// Custom validator function to check for whitespace
	noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value.trim() === '') {
		  return { noWhitespace: true }; // Return an object with the error key
		}
		return null; // Return null if there is no error
	}
	
	public handleAddressChange(address: any) {
		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.suburb = address.name;
		this.location = address.formatted_address;

	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}
	
	onCheckboxChangeRole(type, event) {

		if (event.target.checked) {
			this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

			this.subTypeArray.push(type);
			this.typeIdSelected.push(type.categoryId);

		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);


				}
			}

			//remove subtype
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].parentId == type.categoryId) {
					this.subTypeSelected.splice(i, 1);
				}
			}
			
			for (var i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i].categoryId == type.categoryId) {
					// Remove selected subtype
					let subArray = this.subTypeArray[i].subType;
					for (let si = 0; si < subArray.length; si++) {
						if (subArray[si].parent_id == type.categoryId) {
							let sIndex = this.subTypeIdSelected.indexOf(subArray[si].categoryId);
							if (sIndex > -1) {
								this.subTypeIdSelected.splice(sIndex, 1);
							}
						}
					}
					this.subTypeArray.splice(i, 1);
				}
			}

			//Remove subtype object from array
			// for (i = 0; i < this.subTypeArray.length; i++) {
			// 	if (this.subTypeArray[i] == type.categoryId) {
			// 		this.subTypeArray.splice(i, 1);
			// 	}
			// }

			//remove typeid from selected type id array 
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}

		}

		if (this.typeListData.length == 0) {
			this.completeProfileForm.patchValue({ typeBlankCheck: "" });
		} else {
			this.completeProfileForm.patchValue({ typeBlankCheck: "1" });
		}
	}


	removeType(index) {
		// console.log(this.typeListData[index].categoryId);  == category id 3 for classical 
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		// alert(selectedtype);
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);
		// alert(itemindex);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}
		// var abc = this.subTypeIdSelected;

		// $.each(this.subTypeSelected, function (key, value) {
		// 	// console.log('okk',value.categoryId)
		// 	var cat = value.categoryId;
		// 	let index = abc.indexOf(cat);
		// 	if (index > -1) {
		// 		abc.splice(index, 1);
		// 	}
		// });


		for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
			if (this.subTypeSelected[i].parentId == parent_id) {
				this.subTypeSelected.splice(i, 1);
				//this.checkUncheckRole(option.id,false);
			}
		}

		for (i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {
				// Remove selected subtype
				let subArray = this.subTypeArray[i].subType;
				for (let si = 0; si < subArray.length; si++) {
					if (subArray[si].parent_id == parent_id) {
						let sIndex = this.subTypeIdSelected.indexOf(subArray[si].categoryId);
						if (sIndex > -1) {
							this.subTypeIdSelected.splice(sIndex, 1);
						}
					}
				}
				this.subTypeArray.splice(i, 1);
			}
		}
	}

	onCheckboxChangesubType(subType, event) {



		if (event.target.checked) {

			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
				// console.log(this.subTypeSelected[i].categoryId);
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					// $('input:checkbox[value="' + this.subTypeSelected[i].categoryId + '"]').removeAttr('checked');


					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		if (this.subTypeSelected.length == 0) {
			this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		} else {
			this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		}
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}

	basicInfoStepOne(event) {
		event.stopPropagation();
		//var parent_fieldset = $(this).parents('fieldset');
		//console.log(this.completeProfileForm.value.profileImage);

		if (this.location == undefined && this.lat == undefined && this.lng == undefined) {
			this.toastService.showError('Please Select valid Location', 'Error');
			return false;


		}


		if (this.completeProfileForm.invalid) {
			this.validateAllFormFields(this.completeProfileForm)

			return;
		} else {


			var typeStore = this.commonService.dataArrangType(this.typeListData);
			var subTypeStore = this.commonService.dataArrangSubType(this.typeListData, this.subTypeSelected);
			//var metaId = this.authService.getUserMetaId();

			// var metaId = this.commonService.getMetaId();



			const formData = new FormData();

			formData.append('userType', '1');
			formData.append('name', this.completeProfileForm.value.fullname)
			formData.append('price', this.completeProfileForm.value.price);
			formData.append('typeId', typeStore);

			formData.append('typeSubtype', JSON.stringify(subTypeStore));
			formData.append('profileImage', this.completeProfileForm.value.profileImage)
			formData.append('location', this.location)

			formData.append('metaId', this.metaId)
			formData.append('onBordingStep', '2')
			formData.append('latitude', this.lat)
			formData.append('longitude', this.lng)
			formData.append('suburb', this.suburb)


			showLoader();
			this.signupService.stepOne(formData).subscribe((data) => {
				hideLoader();
				//this.toastService.showSuccess(data.message, "Success");
				// const currentUser = JSON.parse(localStorage.getItem('currentUser'));
				
				// let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);
				
				// if (indexnew1 != -1) {
				// 	currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.data.onBoardingProfileCount;
				// 	localStorage.setItem('currentUser', JSON.stringify(currentUser));

				// }
				// this.stepNo = Number(this.stepNo) + 1;

				var formCurrentData = new FormData();
            	formCurrentData.append("metaId",this.metaId);
				this.gigService.currentUserLogeedIn(formCurrentData).subscribe(
					res1 => {
						// const currentUser = JSON.parse(localStorage.getItem('currentUser'));
						const currentUser = res1.userDetail;

						let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);
						
						if (indexnew1 != -1) {
							currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.data.onBoardingProfileCount;
							localStorage.setItem('currentUser', JSON.stringify(currentUser));
						}
						this.stepNo = Number(this.stepNo) + 1;
						this.dataService.updateData(currentUser.userTypeList[0].profileImage);
					},
					err => {
					  hideLoader();
					  if(err.error.status=='fail' && err.error.message=='Invalid token'){
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');
			  
					  }else{
					  this.toastService.showError(err.error.message, 'Error');
					  }
					}
				);
			
			},
				err => {
					hideLoader();

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				});
		} return;
	}


	onFileChange(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.completeProfileForm.patchValue({

				'profileImage': file

			});

		}

	}

	basicInfoStepTwo(event) {


		if (this.completeStepTwo.invalid) {
			this.validateAllFormFields(this.completeStepTwo)
			return false;
		} else {

			//return false;
			//var metaId = this.authService.getUserMetaId();

			const formData = new FormData();

			if (this.completeStepTwo.value.about) {
				formData.append('about', this.completeStepTwo.value.about);
			}

			formData.append('experience', JSON.stringify(this.completeStepTwo.value.experience))
			formData.append('userType', '1');
			formData.append('metaId', this.metaId)
			formData.append('onBordingStep', '3')
			formData.append('availability', this.completeStepTwo.value.availability)

			showLoader();
			this.signupService.stepTwo(formData).subscribe((data) => {
				hideLoader();

				const currentUser = JSON.parse(localStorage.getItem('currentUser'));

				let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);

				if (indexnew1 != -1) {
					currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.data.onBoardingProfileCount;
					localStorage.setItem('currentUser', JSON.stringify(currentUser));

				}
				this.toastService.showSuccess(data.message, "Success");

				this.stepNo = Number(this.stepNo) + 1;

			}, (err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}


	}

	addExperience() {

		(<FormArray>this.completeStepTwo.get('experience')).push(new FormControl(null, [Validators.required, this.noWhitespaceValidator]));
	}

	removeExperience(index) {
		(<FormArray>this.completeStepTwo.get('experience')).removeAt(index)
	}


	//STEP 4 and 5 UPLOAD IMAGE AND VIDEO

	onUploadFile(event) {

		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file
			});

			this.photoSkip = 2;
			this.uploadSingleImg();

		}


	}

	UploadImgStepThree() {
		//var metaId = this.authService.getUserMetaId();

		if (this.photoSkip == 1) {
			showLoader();

			const formData = new FormData();

			formData.append('metaId', this.metaId)
			formData.append('onBordingStep', '4')
			formData.append('type', '2')

			this.signupService.skipPhoto(formData).subscribe((data) => {
				hideLoader();

				const currentUser = JSON.parse(localStorage.getItem('currentUser'));

				let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);

				if (indexnew1 != -1) {

					currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.data.onBoardingProfileCount;
					localStorage.setItem('currentUser', JSON.stringify(currentUser));

				}

				this.toastService.showSuccess(data.message, "Success");

				this.stepNo = 5;
			}, err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
				//this.toastService.showError(error.error.message, "Error");
				this.stepNo = 5;

			});

		} else {

			//    this.authService.updateOnBoardingProfileCount(data.data.onBoardingProfileCount)
			var step = this.authService.getOnBoardingProfileCount();
			//this.stepNo = parseInt(step) + 1;
			this.stepNo = Number(this.stepNo) + 1;

			this.stepNo = 5;

		}
	}

	uploadSingleImg() {
		// var metaId = this.authService.getUserMetaId();
		const formData = new FormData();
		formData.append('media', this.uploadImageForm.value.fileSource);
		formData.append('userType', '1');
		formData.append('metaId', this.metaId)
		formData.append('onBordingStep', '4')
		formData.append('mediaType', '1')

		showLoader();
		this.signupService.uploadImage(formData).subscribe((data) => {
			hideLoader();
			const currentUser = JSON.parse(localStorage.getItem('currentUser'));

			let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);

			if (indexnew1 != -1) {
				currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.mediaData.onBoardingProfileCount;
				localStorage.setItem('currentUser', JSON.stringify(currentUser));

			}
			this.toastService.showSuccess(data.message, "Success");


			let imgData = data.mediaData

			this.galleryImg.push(imgData);

		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		});
	}

	/**
	 * Remove added image
	 * @param imgId 
	 * @param index 
	 */
	removeImg(imgId, index) {

		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader()
		this.signupService.deleteMedia(formData).subscribe(res => {
			hideLoader()
			this.galleryImg.splice(index, 1);
			if (this.galleryImg.length < 1) {
				this.photoSkip = 1;
			}
			this.toastService.showSuccess(res.message, 'Success');
		}, err => {
			hideLoader()
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
		})
	}

	/**
	 * skip upload video
	 */
	skipVideo() {

		showLoader();

		const formData = new FormData();

		formData.append('metaId', this.metaId)
		formData.append('onBordingStep', '4')
		formData.append('type', '2')

		this.signupService.skipPhoto(formData).subscribe((data) => {
			hideLoader();
			const currentUser = JSON.parse(localStorage.getItem('currentUser'));
			let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);
			if (indexnew1 != -1) {
				currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.data.onBoardingProfileCount;
				localStorage.setItem('currentUser', JSON.stringify(currentUser));

			}
			this.toastService.showSuccess(data.message, "Success");
			this.stepNo = 6;
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
			this.stepNo = 5;
		});
	}


	onSelectedVideo(event) {
		showLoader();
		// this.authService.setUserMetaId(this.checkUserType);
		//var userMetaId = this.authService.getUserMetaId();
		if (event.target.files.length > 0) {

			const file = event.target.files[0];

			this.commonService.videoPreview(event.target, (res) => {
				if (res.status == 1) {
					const formData = new FormData();
					formData.append('authToken', this.authService.getAuthorizationToken());
					formData.append('metaId', this.metaId);
					formData.append('userType', '1');
					formData.append('mediaType', '2');
					formData.append('onBordingStep', '4')
					formData.append('media', file);
					formData.append('video_thumb', res.blobImage, 'video_thumb.png');
					this.signupService.uploadVideo(formData).subscribe((data) => {
						hideLoader();


						const currentUser = JSON.parse(localStorage.getItem('currentUser'));
						let indexnew1 = currentUser.livlouderList.findIndex((x) => x.userMetaId == this.metaId);
						if (indexnew1 != -1) {
							currentUser.livlouderList[indexnew1].onBoardingProfileCount = data.mediaData.onBoardingProfileCount;
							localStorage.setItem('currentUser', JSON.stringify(currentUser));

						}

						this.authService.checkValidToken(data);

						if (data.status == 'fail') {
							this.toastService.showError(data.message, 'Error');
						} else {
							this.toastService.showSuccess(data.message, 'Success');


							//this.authService.updateOnBoardingProfileCount(1, data.mediaData.onBoardingProfileCount)


							this.stepNo = Number(this.stepNo);

							this.groupRequest.getMedia(this.metaId, 2).subscribe((res) => {
								this.video = res.data;
							});
						}
					});
				} else {
					hideLoader();
					this.toastService.showError(res.message, 'Error');

				}
			});

		}
	}
	previous() {
		showLoader();
		this.stepNo = this.stepNo - 1;
		hideLoader();

	}

	onKeyPress(event: KeyboardEvent) {
		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
			event.preventDefault();
			return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}

	limitInputLength(event: any, maxLength: number) {
		const input = event.target as HTMLInputElement;
		if (input.value.length >= maxLength) {
		  event.preventDefault();
		}
	}

	changeIcon(index, val) {

		var arr = [
			`<i class="fab fa-facebook-square"></i>`,
			`<i class="fab fa-twitter-square"></i>`,
			`<i class="fab fa-instagram"></i>`,
		];



		var oldId = this.linkDyna[val].social_media_type_id;


		this.linkDyna[val].type = this.allSocialLink[index].type;

		this.linkDyna[val].social_media_type_id = this.allSocialLink[index].id;

		this.allSocialLink[index].isShow = false;
		if (oldId != this.allSocialLink[index].id) {

			let indexnew = this.allSocialLink.findIndex((x) => x.id == oldId);

			if (indexnew != -1) {

				this.allSocialLink[indexnew].isShow = true;
			}
		}

		this.stype[val] = this.allSocialLink[index].id;
		this.checkSocialTypeExist();
	}


	socialLink(data) {
		let link = data;
		if (link.length == 0) {
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required])
			);
		}
	}

	// upload social Links
	uploadLinks() {


		if (this.socialLinksForm.invalid) {

			this.validateAllFormFields(this.socialLinksForm)
			return false;
		} else {
			//var metaId = this.authService.getUserMetaId();

			if (this.uploadLinksBtn.nativeElement.textContent == 'Skip') {

				let formData = new FormData();

				formData.append('metaId', this.metaId)
				formData.append('onBordingStep', '5')
				formData.append('type', '1')

				this.signupService.skipPhoto(formData).subscribe((data) => {

					hideLoader();
					this.toastService.showSuccess(data.message, "Success");
					localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
					console.log('1', data.userDetail.livlouderList);
					console.log('2', data.userDetail.livlouderList.length);
					if (data.userDetail.livlouderList.length === 1) {

						localStorage.setItem('metaId', data.userDetail.userTypeList[0].userMetaId);
					}

					let index = data.userDetail.livlouderList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')),
						livlouderUserSignupObj = {

							"meta_id": localStorage.getItem('metaId'),
							"online": 1,
							"token": localStorage.getItem('device-token'),
							"type": "1",
							"user_id": data.userDetail.userId,
							"last_seen": ""
						},

						livlouderUserMetaSignupObj = {

							"user_id": data.userDetail.userId,
							"typing": 0,
							"name": data.userDetail.livlouderList[index].fullName,
							"profile_image": data.userDetail.livlouderList[index].profileImage,
							"online": 1,
							"user_type": 1
						};

					this.firebaseService.userAddFB(data.userDetail.userId, livlouderUserSignupObj);
					this.firebaseService.userMetaAddFB(localStorage.getItem('metaId'), livlouderUserMetaSignupObj);
					this.router.navigate(['/createProfileDone']);
				}, err => {
					hideLoader();

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}


				});
				return;
			}
			var linksArray = [];

			var linkSocial = this.socialLinksForm.value.links;
			var type = this.stype;

			if (linkSocial) {
				for (var i = 0; i < linkSocial.length; i++) {
					if (linkSocial[i]) {
						linksArray.push({
							link: linkSocial[i],
							type: this.stype[i],
						});
					}
				}
				if (this.stype.length != this.linkDyna.length) {
					this.toastService.showError('Please select Social Link type', 'Error');
					return false;
				}
				linksArray.forEach(function (value) {

					if (value.type == undefined) {
						alert('Please select Type');
						return false;
					}
				});
				this.socialLinksForm.patchValue({
					socialMedia: linksArray,
				});
			}


			showLoader();
			const formData = new FormData();
			formData.append('socialMedia', JSON.stringify(this.socialLinksForm.value.socialMedia))

			formData.append('metaId', this.metaId)
			formData.append('onBordingStep', '5')

			this.signupService.uploadSocialLinks(formData).subscribe((data) => {

				hideLoader();
				localStorage.setItem('currentUser', JSON.stringify(data.userDetail));
				this.toastService.showSuccess(data.message, "Success");

				if (data.userDetail.livlouderList.length === 1) {
					localStorage.setItem('metaId', data.userDetail.userTypeList[0].userMetaId);
				}

				let index = localStorage.getItem('metaId')? data.userDetail.livlouderList.findIndex(x => x.userMetaId == localStorage.getItem('metaId')) : 0,
					livlouderUserSignupObj = {

						"meta_id": localStorage.getItem('metaId'),
						"online": 1,
						"token": localStorage.getItem('device-token'),
						"type": "1",
						"user_id": data.userDetail.userId,
						"last_seen": ""
					},

					livlouderUserMetaSignupObj = {

						"user_id": data.userDetail.userId,
						"typing": 0,
						"name": data.userDetail.livlouderList[index].fullName,
						"profile_image": data.userDetail.livlouderList[index].profileImage,
						"online": 1,
						"user_type": 1
					};

				this.firebaseService.userAddFB(data.userDetail.userId, livlouderUserSignupObj);
				this.firebaseService.userMetaAddFB(localStorage.getItem('metaId'), livlouderUserMetaSignupObj);
				this.router.navigate(['/createProfileDone']);
			}, err => {
				hideLoader();

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});
		}
	}

	skipPhoto(formData) {
		this.signupService.skipPhoto(formData).subscribe((data) => {
			hideLoader();

			this.toastService.showSuccess(data.message, "Success");

			this.stepNo = 5;
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastService.showError('Session Expired', 'Error');

			} else {
				this.toastService.showError(err.error.message, 'Error');
			}
			//this.toastService.showError(error.error.message, "Error");
			this.stepNo = 5;

		});
	}


	addLinks() {


		if (this.stype.length != this.linkDyna.length) {
			this.toastService.showError('Please select Social Link type', 'Error');
			return false;
		}

		var checkvalue = this.socialLinksForm.get('links').value;
		let checkStatus = 'true';
		checkvalue.forEach(element => {

			if (element == null || element == "") {
				checkStatus = 'false';
			}
		});
		if (checkStatus == 'false') {
			// this.validateAllFormFields(this.socialLinksForm);
			this.toastService.showError('Please Add Social Link', 'Error');
			return false;
		} else {
			// this.linkDyna = this.linkDyna;
			(<FormArray>this.socialLinksForm.get('links')).push(
				new FormControl(null, [Validators.required])
			);

			let newLinks: any = {
				'id': '',
				'type': 'globe',
				'socialLink': '',
				'social_media_type_id': '',
				'image': '',
				'isShow': 'true',
			};
			this.linkDyna.push(newLinks);

		}
	}


	removeLinks(index) {

		this.stype.splice(index, 1);
		let indexCheck = this.allSocialLink.findIndex((x) => x.id == this.linkDyna[index].social_media_type_id);

		if (indexCheck != -1) {

			this.allSocialLink[indexCheck].isShow = true;
		}

		this.linkDyna.splice(index, 1);
		(<FormArray>this.socialLinksForm.get('links')).removeAt(index);
		this.checkSocialTypeExist();
	}

	checkSocialTypeExist() {

		var asd = this.allSocialLink;
		this.showAddMoreBtn = false;
		//setTimeout(function () {
		asd.forEach((element) => {
			if (element.isShow == true) {
				this.showAddMoreBtn = true;

			}
		})

		//}, 4000)


	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});

		return false;
	}

	/**
	 * Remove added image
	 * @param imgId
	 * @param index
	 */
	removideo(imgId, index) {
		$(this).parent('.one').remove();
		const formData = new FormData();
		formData.append('mediaId', imgId);
		formData.append('onBordingStep', '4');

		showLoader();
		this.signupService.deleteMedia(formData).subscribe(
			(res) => {
				hideLoader();
				this.galleryImg.splice(index, 1);
				if (this.galleryImg.length < 1) {
					this.photoSkip = 1;
				}

				this.groupRequest.getMedia(this.metaId, 2).subscribe((res) => {
					hideLoader();
					this.video = res.data;
				});
				this.toastService.showSuccess(res.message, 'Success');
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

}
