<header class="header" [ngClass]="{'innerHeader': router.url !== '/'}">
    <nav id="navbar" class="navbar our-header navbar-expand-lg navbar-light fixed-top"
        [ngClass]="{'our-nav': router.url !== '/'}">
        <div class="container NotifiContainer">
            <a class="navbar-brand"><span>Livloud</span></a>
            <div class="headerItemBlk">
                <div class="headerItem" *ngIf="router.url == '/' || router.url == '/login' || router.url == '/signup'">
                    <div class="lsBtn" routerLinkActive="active">
                        <a class="nav-link" routerLink="/login">Login</a>
                    </div>
                </div>
                <div class="headerItem" *ngIf="router.url != '/' && router.url != '/login' && router.url != '/signup'">
                    <div class="profile dropdown csDropdown">
                        <a id="dropdownMenuLink1" href="javascript:void(0);" class="img-toggle" data-toggle="dropdown"
                            role="button" aria-haspopup="true" aria-expanded="false"><img *ngIf="isLogin == true"
                                src="{{profileImage}}"><img *ngIf="isLogin == false"
                                src="https://giglooker.com/dev/livloud_admin/placeholder/defaultUser.png"></a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink1">
                            <li>
                                <a *ngIf="current_user_data.userTypeList[1].isProfileComplete == 1 && current_user == 2" routerLink="/profileHirer"><i class="fas fa-user"></i>My Profile</a>
                            </li>


                            <li *ngIf="current_user == 2" (click)="switchAccound();"><a href="javascript:void(0);"><i
                                        class="fas fa-sign-out-alt"></i>Switch To Livlouder</a>
                            </li>

                            <li *ngIf="current_user == 1" (click)="switchAccound_hirer();"><a
                                    href="javascript:void(0);"><i class="fas fa-sign-out-alt"></i>Switch To hirer</a>
                            </li>
                            <!-- <li>
                                <a routerLink="/ProfileLivlouder"><i class="fas fa-users"></i>Switch To Livlouder</a>
                            </li> -->
                            <li>
                                <a routerLink="/faq"><i class="fas fa-question-circle"></i>FAQ</a>
                            </li>
                            <li>
                                <a routerLink="/help"><i class="fas fa-headset"></i>Help</a>
                            </li>
                            <li>
                                <!-- {{current_user}} -->
                                <a *ngIf="current_user_data.userTypeList[1].isProfileComplete == 1 && current_user == 2"
                                    routerLink="/settingsHirer"><i class="fas fa-cog"></i>Settings</a>
                            </li>
                            <li (click)="logout();">
                                <a href="javascript:void(0);"><i class="fas fa-sign-out-alt"></i>Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="headerItem" *ngIf="router.url != '/' && router.url != '/login' && router.url != '/signup'">
                    <app-notification-list></app-notification-list>
                </div>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto"
                    *ngIf="router.url == '/' || router.url == '/login' || router.url == '/signup'">

                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/faq">FAQ</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/help">Help</a>
                    </li>
                    <!-- <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/signup">Signup</a>
                    </li> -->
                </ul>
                <!-- <ul class="navbar-nav ml-auto" *ngIf="router.url != '/' && router.url != '/login' && router.url != '/signup'"> -->
                <ul class="navbar-nav ml-auto" *ngIf="current_user_data.userTypeList[1].isProfileComplete == 1 && current_user == 2">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/myGigsHirer">My Gigs</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/discoverHirer">Discover</a>
                        <!-- <a class="nav-link" href="javascript:void(0)">Discover</a> -->
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <!-- <a class="nav-link" routerLink="/chatHirer">Messages</a> -->
                        <!-- <a class="nav-link" href="javascript:void(0)">Messages</a> -->
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>