// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-open-discover-details',
//   templateUrl: './open-discover-details.component.html',
//   styleUrls: ['./open-discover-details.component.css']
// })
// export class OpenDiscoverDetailsComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GigService } from 'src/app/services/gig-service';
import { Openaccess} from "src/app/services/openaccess";

declare var $:any
@Component({
    selector: 'app-open-discover-details',
    templateUrl: './open-discover-details.component.html',
    styleUrls: ['./open-discover-details.component.css']
  })
export class OpenDiscoverDetailsComponent implements OnInit,AfterViewChecked {

  title = "Livlouder - Discover Detail";
  userMetaId;
  data;
  experience=[];
  constructor(
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private gigService:GigService,
    private titleService:Title,
    private Openaccess: Openaccess,

  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
   
    
    this.activatedRoute.paramMap.subscribe(param=>{
      this.userMetaId = param.get('id');
      
     })
 
     this.Openaccess.discoverDetail( this.userMetaId).subscribe(res=>{
       this.data = res.detail;
      //  console.log(this.data);
       if(this.data.experience){
        this.experience = JSON.parse(this.data.experience);
       }
        this.data.rating_review.data.forEach(element => {
          
          element.agotime = this.diffHours(element.created_date,element.current_time);
        });
        
      
     })
  }

  ngAfterViewChecked(){
    $('#imageGallery').lightGallery({
      thumbnail:true,
      selector: '.addedImg',
      download: false
    }); 
    $('#galleryVideos').lightGallery({
      selector: '.addedImg'
    });
  }

  diffHours(t1, t2) 
  {
    var date1 = new Date(t1).getTime() / 1000;

    var date2 = new Date(t2).getTime() / 1000;

    var difference = Math.abs((date2 - date1));
    var diffHr = (difference/60/60);
    var daysDiff = (difference/60/60/24)
    if(isNaN(daysDiff)){
     return 'N/A';
    }
    if(daysDiff < 1){
        if(diffHr < 1){
        
          difference = (difference/60);
          var  hourDiff = Math.floor(difference)+' Min Ago'
        
        }else{
          
          difference = (difference/60/60);
          var  hourDiff = Math.floor(difference)+' Hour Ago'
        }
    }else{
      var  hourDiff = Math.floor(daysDiff)+' Days Ago'
    }
   
    return hourDiff;
  
  }

}
