import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, AfterViewChecked, SimpleChange } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrNotificationService } from '../../services/toastr-notification.service';
import { AuthService } from '../../services/auth.service'
import { SignupService } from '../../services/signup.service'
import { GigService } from '../../services/gig-service';
// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { CommonService } from '../../services/common.service';
import { formatDate } from '@angular/common';
import { PickerModel } from 'src/app/picker-model';
import { EndDatePicker } from 'src/app/end-date-picker';
import { Title } from '@angular/platform-browser';

declare var $: any
declare const toastrSuccess: any;
declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;


@Component({
	selector: 'app-edit-gig',
	templateUrl: './edit-gig.component.html',
	styleUrls: ['./edit-gig.component.css']
})


export class EditGigComponent implements OnInit {
	title = "Hirer- Edit Gig"
	createGigStepOne: FormGroup;
	createGigStepTwo: FormGroup;
	uploadImageForm: FormGroup;
	userType = 2;
	gigId;
	isChecked;
	isCheckedName;
	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	selectedIdSubType;


	typeListData1 = [];
	subTypeArray1 = [];
	subTypeSelected1 = [];
	selectedIdSubType1;
	typeSubtype1: any;


	LocationType;
	EditLocation;
	stepNo = 1;
	private;
	public;

	options = {
		types: [],
		// componentRestrictions: { country: 'AU' }
		componentRestrictions: { country: 'UK' }
	}

	pickerModel: PickerModel;
	lat: any;
	lng: any;
	suburb: any;
	location;
	model;
	endModel;
	imgId: any = [];
	editData;
	formData = new FormData();
	formSubmit;
	endDatePicker: EndDatePicker;
	sTime;
	eTime;
	typeIdSelected = [];
	typeIdSelected1 = [];
	gigFor;
	deletedImageId = [];
	radioButton;
	value;
	value1 = 1;
	solo_group = 1;
	selectedTypeID = 0;
	selectedGroupTypeID = 0;
	minDate: any;

	@ViewChild("radio1") radio1: ElementRef;

	constructor(
		private toastrService: ToastrNotificationService,
		private authService: AuthService,
		private signupService: SignupService,
		private commonService: CommonService,
		private gigService: GigService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title

	) { }




	ngOnInit(): void {

		this.titleService.setTitle(this.title);
		this.authService.setUserMetaId(this.userType);
		var metaId = this.authService.getUserMetaId();

		this.activatedRoute.paramMap.subscribe(param => {
			this.gigId = param.get('id');
			this.gigService.gigDetail(this.gigId, metaId).subscribe((res) => {

				this.editData = res.data;
				this.radioButton = this.editData.isRemote
				this.public = this.editData.gigFor;
				this.value = this.editData.isRemote
				this.EditLocation = this.editData.location

				if (this.editData.typeId != null) {


					this.selectedTypeID = this.editData.typeId.split(',')[0];
					this.typeIdSelected = this.editData.typeId.split(',');
					this.value1 = 1;
					this.selectedIdSubType = this.editData.subtypeId.split(',');
				}
				else {

					this.selectedGroupTypeID = this.editData.groupTypeId.split(',')[0];
					this.value1 = 2;
					this.typeIdSelected1 = this.editData.groupTypeId.split(',');
					this.selectedIdSubType1 = this.editData.groupSubtypeId.split(',');

				}

				this.updateStartPicker(this.editData.startDate);
				this.updateEndPicker(this.editData.endDate);
				this.gigFor = this.editData.gigFor;

				this.createGigStepOne = new FormGroup({
					'title': new FormControl(this.editData.title, [Validators.required]),
					'location': new FormControl(this.editData.location),
					// 'price': new FormControl(this.editData.price, [Validators.required,
					// Validators.pattern("^([0-9]{0,3}((.)[0-9]{0,2}))$"),
					// Validators.maxLength(6)]),
					price: new FormControl(this.editData.price, [
						Validators.required,
						Validators.min(1),
						Validators.max(999999.99),
						// Validators.pattern("^([0-9]{0,6}((.)[0-9]{0,2}))$"),
						Validators.pattern("^([0-9]{1,6})((.[0-9]{2})?)$"),
						Validators.maxLength(9),
					]),
					'startDate': new FormControl(null, [Validators.required]),
					'startTime': new FormControl([Validators.required, this.timeValidation]),
					'endDate': new FormControl(null, [Validators.required]),
					'endTime': new FormControl([Validators.required, this.timeValidation]),

				});

				var stimess = new Date(this.editData.startDate).toLocaleTimeString('it-IT');
				var etimess = new Date(this.editData.endDate).toLocaleTimeString('it-IT');

				this.createGigStepOne.patchValue({
					startTime: stimess,
					endTime: etimess
				});

				this.createGigStepTwo = new FormGroup({
					'about': new FormControl(this.editData.about, [Validators.required, Validators.maxLength(300), Validators.minLength(5)]),
					'mustHave': new FormArray([

					]),
					'typeBlankCheck': new FormControl(null, [Validators.required]),
					'subTypeBlankCheck': new FormControl(null, [Validators.required]),

				});

				this.createGigStepTwo.patchValue({
					typeBlankCheck: '1',
					subTypeBlankCheck: '1'
				})

				this.patchMustHave(this.editData.mustHave);
				//console.log('mushhaveee',this.editData.mustHave)
				this.uploadImageForm = new FormGroup({
					'file': new FormControl(null),
					"fileSource": new FormControl(null)
				})

				// update image ids array
				this.editData.gig_images.forEach(element => {
					this.imgId.push(element.mediaId);
				});

				this.updateEditData(this.editData);

				this.signupService.getTypeSubType().subscribe((data) => {

					this.typeSubtype = data.data;


					this.addTypeListData(this.typeSubtype);
				});

				// if(this.editData.groupTypeId != null || this.editData.groupTypeId != ''){

				this.signupService.getGroupTypeSubType().subscribe((data) => {

					this.typeSubtype1 = data.data;
					// console.log('thistypeSubtype1',this.typeSubtype1);
					this.addTypeListData1(this.typeSubtype1);
				});
				// }

			}, err => {
				console.log('error');
				console.log(err)
			})
		})

		this.minDate = this.getCurrentDateObject();

	}

	onKeyPress(event: KeyboardEvent) {
		const inputElement = event.target as HTMLInputElement;
		
		// Allow backspace, delete, tab, and navigation keys
		if (
			event.key === 'Backspace' ||
			event.key === 'Delete' ||
			event.key === 'Tab' ||
			event.ctrlKey ||
			event.altKey ||
			event.metaKey
		) {
			return;
		}
		
		// Allow decimal point only once
		if (event.key === '.' && inputElement.value.includes('.')) {
			event.preventDefault();
			return;
		}
		
		const newValue = inputElement.value + event.key;
		  
		// Allow value at only 7 digits without decimal point
		if (event.key !== '.' && !inputElement.value.includes('.') && inputElement.value.length == 7) {
			event.preventDefault();
			return;
		}
		// Prevent exceeding 6 digits before decimal or 2 after
		if (!newValue.match(/^([0-9]{0,6})((.[0-9]{0,2})?)?$/)) {
			event.preventDefault();
		}
	}

	// date format with object
	getCurrentDateObject() {
		return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	solo_group_type(value) {
		if (value == 1) {
			this.selectedGroupTypeID = 0;
			this.typeListData1 = [];
			// this.typeIdSelected1 = [];
			this.subTypeArray1 = [];
			this.selectedIdSubType1 = [];
			this.subTypeSelected1 = [];
		}else {
			this.selectedTypeID = 0;
			this.typeListData = [];
			// this.typeIdSelected = [];
			this.subTypeArray = [];
			this.selectedIdSubType = [];
			this.subTypeSelected = [];
		}
		this.solo_group = value;
		this.value1 = this.solo_group;
	}

	patchMustHave(data) {
		let mustHave = JSON.parse(data);

		mustHave.forEach(element => {
			(<FormArray>this.createGigStepTwo.get('mustHave')).push(new FormControl(element, [Validators.required]));

		});
	}
	changerdo(value) {
		this.radioButton = value;
		this.value = this.radioButton
	}


	Private(value) {
		this.private = value;
		this.public = this.private
	}
	updateEditData(data) {

		this.location = data.location;
		this.lat = data.latitude;
		this.lng = data.longitude;
		this.suburb = data.suburb;
	}

	addTypeListData(typeSubtype) {
		// console.log('typeSubtype',typeSubtype);
		typeSubtype.forEach(element => {
			element.Type.forEach(element1 => {

				if (this.typeIdSelected != undefined) {


					if (this.typeIdSelected.includes(element1.categoryId)) {

						this.typeListData.push({ categoryId: element1.categoryId, name: element1.name, parent_id: element1.parent_id })
						this.subTypeArray.push(element1)
						element1.subType.forEach(element2 => {
							if (this.selectedIdSubType.includes(element2.categoryId)) {


								this.subTypeSelected.push({ categoryId: element2.categoryId, name: element2.name, parentId: element2.parent_id })
							}
						});
					}
				}
			});
		});
	}
	addTypeListData1(typeSubtype1) {
		typeSubtype1.forEach(elements => {
			elements.Type.forEach(element11 => {

				// console.log('thistypeIdSelected1',this.typeIdSelected1);

				if (this.typeIdSelected1 != undefined) {

					if (this.typeIdSelected1.includes(element11.categoryId)) {

						this.typeListData1.push({ categoryId: element11.categoryId, name: element11.name, parent_id: element11.parent_id })
						this.subTypeArray1.push(element11)
						element11.subType.forEach(element22 => {
							if (this.selectedIdSubType1.includes(element22.categoryId)) {
								this.subTypeSelected1.push({ categoryId: element22.categoryId, name: element22.name, parentId: element22.parent_id })
							}
						});
					}
				}
			});
		});
	}

	updateStartPicker(startDate) {
		var dateStart = new Date(startDate);

		this.pickerModel = new PickerModel();
		this.pickerModel.day = dateStart.getDate();
		this.pickerModel.month = dateStart.getMonth() + 1;
		this.pickerModel.year = dateStart.getFullYear();

		return;
	}

	updateEndPicker(endDate) {
		var endDates = new Date(endDate);

		this.endDatePicker = new PickerModel();
		this.endDatePicker.day = endDates.getDate();
		this.endDatePicker.month = endDates.getMonth() + 1;
		this.endDatePicker.year = endDates.getFullYear();
	}

	filterData(res) {

		this.editData = res.data;
	}

	startDateValidate(control: FormControl): { [key: string]: boolean } | null {
		var startdate = $('#startDate').val();
		var enddate = $('#endDate').val();

		if (startdate && startdate) {

			if (new Date(startdate).getTime() > new Date(enddate).getTime()) {
				return { 'inValidStartDate': true }
			}
		}

		return null;

	}

	timeValidation(control: FormControl): { [key: string]: boolean } | null {
		var startdate = $('#startDate').val();
		var enddate = $('#endDate').val();
		var startTime = $('.startTime').val();
		var endTime = $('.endTime').val();

		if (startdate && startdate) {

			if (new Date(startdate).getTime() == new Date(enddate).getTime()) {

				if (startTime && endTime) {
					if (startTime > endTime) {
						return { 'inValidTime': true }
					}
				}


			}
		}

		return null;

	}

	public handleAddressChange(address: any) {

		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;
	}

	createGig($event) {


		if (this.radioButton != 1 && this.location == "") {
			this.toastrService.showError('Location is required', "Error");
			return false;
		}

		if (!this.createGigStepOne.valid) {
			this.validateAllFormFields(this.createGigStepOne);

			return false;
		} else {


			var startDateControl = this.createGigStepOne.value.startDate;

			var startDate = this.formatDate(startDateControl);

			var endDateControl = this.createGigStepOne.value.endDate;
			var endDate = this.formatDate(endDateControl);

			if (startDate > endDate) {

				// alert('if')
				this.toastrService.showError('End date must be equal or greater than start date.', "Error");
				return false;

			}

			if (startDate == endDate) {
				if (this.createGigStepOne.value.startTime >= this.createGigStepOne.value.endTime) {
					// alert('time error');
					this.toastrService.showError('End time must be greater than  start time.', "Error");
					return false;

				}
			}

			this.formData.append('title', this.createGigStepOne.value.title)
			this.formData.append('location', this.location)
			this.formData.append("is_remote", this.value);
			this.formData.append('price', this.createGigStepOne.value.price)
			this.formData.append('jobLat', this.lat)
			this.formData.append('jobLong', this.lng)
			this.formData.append('startDate', startDate)
			this.formData.append('startTime', this.createGigStepOne.value.startTime)
			this.formData.append('endDate', endDate)
			this.formData.append('endTime', this.createGigStepOne.value.endTime)
			this.formData.append('gigFor', this.public)
			this.formData.append('suburb', this.suburb)
			this.formData.append('utcDatetime', startDate + ' ' + this.createGigStepOne.value.startTime)
			this.stepNo = 2;
		}

	}

	formatDate(date) {

		var day;
		var month;

		if (date.month.toString().length == 1) {

			month = '0' + date.month;
		} else {
			month = date.month;
		}

		if (date.day.toString().length == 1) {
			day = '0' + date.day;

		} else {
			day = date.day;
		}

		return date.year + '-' + month + '-' + day;

	}

	onCheckboxChangeRole(type, event) {
		// this.typeListData = [];
		// this.subTypeSelected = [];

		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.selectedIdSubType = [];
		}

		if (event.target.checked) {
			// this.typeListData = [];
			// this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

			// //this code for subtype selection. If we check a checkbox than not already selected than reset all value into subtype array and other field.
			// if (type.categoryId != this.typeIdSelected) {
			// 	this.subTypeSelected = [];
			// 	this.selectedIdSubType = [];
			// 	this.subTypeArray = [];
			// 	this.subTypeArray.push(type);
			// }

			const checkTypeData = this.typeListData.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected = [];
				// this.subTypeIdSelected = [];
				this.selectedIdSubType = [];
				this.typeListData = [];
				this.typeListData.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.typeIdSelected.indexOf(type.categoryId);
			
			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray = [];
				this.typeIdSelected = [];
				this.subTypeArray.push(type);
				this.typeIdSelected.push(type.categoryId);
			}

		} else {


			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);

				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}
		}

		if (this.typeListData.length == 0) {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "1" });
		}
	}


	onCheckboxChangeRole1(type, event) {
		// this.typeListData1 = [];
		// this.subTypeSelected1 = [];
		if (event.target.checked == false) {
			this.typeIdSelected1 = [];
			this.typeListData1 = [];
			this.subTypeArray1 = [];
			this.selectedIdSubType1 = [];
		}

		if (event.target.checked) {
			// this.typeListData1 = [];
			// this.typeListData1.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

			// //this code for subtype selection. If we check a checkbox than not already selected than reset all value into subtype array and other field.
			// if (type.categoryId != this.typeIdSelected1) {
			// 	this.subTypeSelected1 = [];
			// 	this.selectedIdSubType1 = [];
			// 	this.subTypeArray1 = [];
			// 	this.subTypeArray1.push(type);
			// }

			const checkTypeData = this.typeListData1.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected1 = [];
				this.subTypeSelected1 = [];
				this.selectedIdSubType1 = [];
				this.typeListData1 = [];
				this.typeListData1.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray1.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.selectedIdSubType1.indexOf(type.categoryId);

			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray1 = [];
				this.typeIdSelected1 = [];
				this.selectedIdSubType1 = [];
				this.subTypeArray1.push(type);
				this.typeIdSelected1.push(type.categoryId);
				// this.selectedIdSubType1.push(type.categoryId);
			}
			
		} else {

			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);

				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}
		}

		if (this.typeListData1.length == 0) {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ typeBlankCheck: "1" });
		}
	}


	onCheckboxChangesubType(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })

		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}
			//Remove selected sub type id
			const index = this.subTypeSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeSelected.splice(index, 1);
			}
		}

		if (this.subTypeSelected.length == 0) {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "1" });
		}
	}


	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })

		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}
			//Remove selected sub type id
			const index = this.subTypeSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeSelected1.splice(index, 1);
			}
		}

		if (this.subTypeSelected1.length == 0) {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "" });
		} else {
			this.createGigStepTwo.patchValue({ subTypeBlankCheck: "1" });
		}
	}


	removeType(index) {

		$("input[type=radio][name=radiobtn]").prop('checked', false);
		this.selectedTypeID = 0;

		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);

		// if (this.typeIdSelected != undefined) {

		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}
		// }
		var abc = this.subTypeSelected;

		$.each(this.subTypeSelected, function (key, value) {
			// console.log('okk',value.categoryId)
			var cat = value.categoryId;
			let index = abc.indexOf(cat);
			if (index > -1) {
				abc.splice(index, 1);
			}
		});

		// $('#check').prop('checked',(value == this.subTypeIdSelected));
		// console.log(this.subTypeIdSelected);

		//remove subtype
		for (var i = this.subTypeSelected.length - 1; i >= 0; i--) {
			if (this.subTypeSelected[i].parentId == parent_id) {
				this.subTypeSelected.splice(i, 1);
				//this.checkUncheckRole(option.id,false);
			}
		}

		for (i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {
				this.subTypeArray.splice(i, 1);
			}
		}
	}
	removeSubType(index) {
		// this.subTypeArray=[];
		// this.typeIdSelected =[];
		//this.subTypeSelected =[];
		let checkvalue = this.subTypeSelected[index];

		this.subTypeSelected.splice(index, 1);

		$('.subtypeBox:checkbox[value="' + checkvalue.categoryId + '"]').prop('checked', false);


	}

	removeType1(index) {

		this.selectedGroupTypeID = 0;

		$("input[type=radio][name=radiobtn1]").prop('checked', false);

		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		var abc = this.subTypeSelected1;

		$.each(this.subTypeSelected1, function (key, value) {
			var cat = value.categoryId;
			let index = abc.indexOf(cat);
			if (index > -1) {
				abc.splice(index, 1);
			}
		});

		//remove subtype
		for (var i = this.subTypeSelected1.length - 1; i >= 0; i--) {
			if (this.subTypeSelected1[i].parentId == parent_id) {
				this.subTypeSelected1.splice(i, 1);
			}
		}

		for (i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {
				this.subTypeArray1.splice(i, 1);
			}
		}
	}

	removeSubType1(index) {

		let checkvalue = this.subTypeSelected1[index];
		this.subTypeSelected1.splice(index, 1);
		$('.subtypeBox:checkbox[value="' + checkvalue.categoryId + '"]').prop('checked', false);


	}


	//Remove must have field
	removeMustHave(index) {
		(<FormArray>this.createGigStepTwo.get('mustHave')).removeAt(index)

	}

	// Add more must have field
	addMoreMustHave() {
		(<FormArray>this.createGigStepTwo.get('mustHave')).push(new FormControl(null, [Validators.required]));

	}

	stepTwo(event) {
		//event.stopPropagation();
		this.formSubmit = true

		if (this.createGigStepTwo.invalid) {

			this.validateAllFormFields(this.createGigStepTwo)
			return false;
		} else {

			var typeStore = this.commonService.dataArrangType(this.typeListData);
			var subTypeStore = this.commonService.dataArrangSubType(this.typeListData, this.subTypeSelected);
			var commaSeparatedSubType = this.commonService.commaSeparatedSubType(subTypeStore);


			var typeStore1 = this.commonService.dataArrangType(this.typeListData1);
			var subTypeStore1 = this.commonService.dataArrangSubType(this.typeListData1, this.subTypeSelected1);
			var commaSeparatedSubType1 = this.commonService.commaSeparatedSubType(subTypeStore1);

			var metaId = this.authService.getUserMetaId();

			this.formData.append('about', this.createGigStepTwo.value.about);
			this.formData.append('specifications', JSON.stringify(this.createGigStepTwo.value.mustHave));

			if (this.value1 == 1) {
				if (typeStore == "") {
					this.toastrService.showError('Type is required', "Error");
					return false;
				}

				if (this.subTypeSelected.length == 0) {
					this.toastrService.showError('Subtypes is required', "Error");
					return false;
				}

				this.formData.append('roleId', typeStore);
				this.formData.append('typeId', commaSeparatedSubType);

			}

			if (this.value1 == 2) {

				if (typeStore1 == "") {
					this.toastrService.showError('Type is required', "Error");
					return false;
				}

				if (this.subTypeSelected1.length == 0) {
					this.toastrService.showError('Subtypes is required', "Error");
					return false;
				}

				this.formData.append("roleGroupId", typeStore1);
				this.formData.append("typeGroupId", commaSeparatedSubType1);
			}

			this.formData.append('gigId', this.gigId)
			this.stepNo = 3;

		}
	}

	onUploadFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.uploadImageForm.patchValue({
				fileSource: file,
			});
		}

		showLoader();
		const formData = new FormData();
		formData.append("image", this.uploadImageForm.value.fileSource);
		this.gigService.uploadGigImage(formData).subscribe(
			(data) => {
				hideLoader();

				this.imgId.push(data.mediaId);
				this.editData.gig_images.push(data);
			},
			(err) => {
				hideLoader();
				this.toastrService.showError(err.error.message, "Error");
			}
		);
	}
	saveGig() {

		if (this.uploadImageForm.invalid) {
			this.validateAllFormFields(this.uploadImageForm);
			return false;
		}

		if (this.imgId) {
			this.formData.append('imageId', this.imgId);
		}

		if (this.deletedImageId.length > 0) {
			let imgIds = this.deletedImageId.toString();
			this.formData.append('deletedImage', imgIds);
		}



		this.gigService.editGig(this.formData).subscribe((data) => {
			hideLoader();

			this.toastrService.showSuccess(data.message, 'Success');
			this.router.navigate(['/myGigsHirer']);
		}, err => {
			hideLoader();
			this.toastrService.showError(err.error.message, 'Error');

		})
	}

	/**
	 * Remove image from gig 
	 * @param imageId 
	 */
	removeImage(imageid) {
		var imgLength = this.editData.gig_images.length;

		for (var i = 0; i < imgLength; i++) {

			if (this.editData.gig_images[i].imageId == imageid) {

				//deletedImage
				this.deletedImageId.push(imageid)
				this.editData.gig_images.splice(i, 1);

			}
		}
	}

	previousStep(stepNo) {
		this.stepNo = stepNo;
	}

	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});

		return false;
	}

}
