<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="discoverDetails mainContent">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                    <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar >
                            <div class="discoverItem sidebar__inner">
                                <div class="disArtistImg">
                                    <a href="javascript:void(0);"><img src="assets/img/defaultGroup.png"></a>
                                </div>
                                <div class="disArtistInfo">
                                    <a href="javascript:void(0);"><h2 class="">DJ The Great and Terrible</h2></a>
                                    <div class="livLouderTypes">
                                        <h5>Dancer | Security</h5>
                                        <p class="mb-2"><span>Hip Hop | Western</span></p>
                                    </div>
                                    <p class="loc"><i class="fa fa-map-marker-alt"></i> <span class="">1 East Sherman Lane Chester, PA 19013</span></p>
                                    <div class="ratePriceBlk">
                                        <div class="rateBlk">
                                            <p><span class="fa fa-star"></span> 4.0</p><span class="totalReeview">(652)</span>
                                        </div>
                                        <div class="priceBlk">
                                            <p>$150/<span>Hr</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="availability">
                                    <h2>Availability</h2>
                                    <p>Available on all day.</p>
                                </div>
                                <div class="socialLinkAdded">
                                    <ul>
                                        <li class="active"><a href=""><span class="fab fa-facebook-square"></span> Facebook</a></li>
                                        <li><a href=""><span class="fab fa-twitter-square"></span> Twitter</a></li>
                                        <li><a href=""><span class="fab fa-instagram"></span> Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Experience</h2>
                                    <ul class="infoList">
                                        <li>One year in singing</li>
                                        <li>Two years in playback singing</li>
                                        <li>Over 150 Gigs + Completed</li>
                                        <li>5 Years as teaching Guitar at Music Acadamy</li>
                                        <li>3 Years as lead gutarist at Left Hand God</li>
                                    </ul>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Photos</h2>
                                    <div id="imageGallery" class="galleryImgAdd galleryProfile">
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-src="assets/img/1.jpg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="userInfoItem">
                                    <h2 class="infoHead">Videos</h2>
                                    <div style="display:none;" id="video1">
                                        <video class="lg-video-object lg-html5" controls preload="none">
                                            <source src="videos/video1.mp4" type="video/mp4">
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div>
                                    <div style="display:none;" id="video2">
                                        <video class="lg-video-object lg-html5" controls preload="none">
                                            <source src="videos/video2.mp4" type="video/mp4">
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div>
                                    <div id="galleryVideos" class="galleryImgAdd galleryProfile">
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-poster="assets/img/1.jpg" data-html="#video1">
                                                <img src="assets/img/1.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg" data-poster="assets/img/1.jpg" data-html="#video2">
                                                <img src="assets/img/1.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/1.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/1.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="log_div gallery-img text-center mt-30">
                                            <div class="addedImg">
                                                <img src="assets/img/1.jpg">
                                                <div class="text-center upload_pic_in_album bsnes-cam">
                                                    <label class="upload_picNew videoPlay">
                                                        <span data-toggle="tooltip" data-placement="top" title="Play Video" class="fas fa-play"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Group Members <span class="NoCount">(30)</span></h2>
                                <div class="invitedMemberList groupMemberList mt-20">
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/2.png">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/3.png">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/1.jpg">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/2.png">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="membItem">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/3.png">
                                            </div>
                                            <div class="membName">
                                                <h2>John Smith</h2>
                                                <p>Singer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="widgetBox mt-30">
                            <div class="userInfoItem">
                                <h2 class="infoHead">Reviews <span class="NoCount">(150)</span></h2>
                                <div class="reviewsList">
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/2.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Isabella Smith</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>3 min ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView ml-auto">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                        </div>
                                    </div>
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/3.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Edmund Richards</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>5 min ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView ml-auto">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                    </div>
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/4.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Sophie Flowers</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>5 min ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted.</p>
                                        </div>
                                    </div>
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/5.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Danny Reed</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>1 day ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                        </div>
                                    </div>
                                    <div class="reviewsItem">
                                        <div class="reviewsCntBlk">
                                            <div class="reviewUserImg">
                                                <a href="javascript:void(0);">
                                                <img src="assets/img/6.png">
                                                </a>
                                            </div>
                                            <div class="reviewsCnt">
                                                <a href="javascript:void(0);">
                                                    <h2>Isabella Smith</h2>
                                                </a>
                                                <div class="rateTimeMeta">
                                                    <p>3 min ago</p>
                                                </div>
                                            </div>
                                            <div class="ratingView">
                                                <div class="ratingBlk">
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star filled"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="retDec">
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder></app-footer-livlouder>