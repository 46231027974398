<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <label>
                            <input #searchText type="text" id="search" (keyup)="searchGig(searchText.value)"
                                class="form-control" placeholder="Search Gig..."
                                style="padding: 0px 46px 0px 12px;overflow: hidden !important;">
                            <!-- <span class="fa fa-search"  (click)="searchGig(searchText.value)"></span> -->
                            <span class="fa fa-search"></span>
                        </label>
                    </div>
                </div>
                <div class="filterItem ml-auto">
                    <div class="filterIconGrp">
                        <div class="filterIconItem">
                            <div class="dropdown smallDropdown">
                                <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <button id="activeicon" class="btn iconBtn dropdown-toggle" title="Sort By"
                                        data-toggle="tooltip" type="button">
                                        <span class="fa fa-sort-amount-down"></span>
                                    </button>
                                </span>
                                <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">
                                    <!-- <a id="allF" class="dropdown-item" href="javascript:void(0)"
                                        (click)="allGig();">All</a>
                                    <a id="hotF" (click)="filterhot(1)" class="dropdown-item"
                                        href="javascript:void(0)">Featured(Hot)</a> -->
                                    <a id="priceF" class="dropdown-item" href="javascript:void(0)" (mouseover)='over()'
                                        (mouseout)='out()'>Price</a>
                                    <div class="dropdown-content" style="display: none;" id="priceFilter">
                                        <!-- <a class="dropdown-item" id="asc" value="asc" (click)="filterPrice('asc')" (mouseover)='over()' href="javascript:void(0)">Low to high</a>
                                        <a class="dropdown-item" (click)="filterPrice('desc')" (mouseover)='over()' href="javascript:void(0)">High to low</a> -->


                                        <a class="dropdown-item" id="asc" value="asc" (click)="filterPrice('asc')"
                                            (mouseover)='over()' href="javascript:void(0)">Low to high</a>
                                        <a class="dropdown-item" id="desc" value="desc" (click)="filterPrice('desc')"
                                            (mouseover)='over()' href="javascript:void(0)">High to low</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="filterIconItem">
                            <button title="Saved Gigs" (click)="savedGig()" data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span class="fa fa-heart"></span>
                            </button>
                        </div> -->

                        <div *ngIf="isLogin == true" class="filterIconItem">
                            <button *ngIf="isLiked == false" title="Saved Gigs" (click)="savedGig()"
                                data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span [ngClass]="isLiked == false ? 'far fa-heart' : 'fas fa-heart'"></span>

                            </button>
                            <button *ngIf="isLiked == true" title="Saved Gigs" (click)="allGig()" data-toggle="tooltip"
                                class="btn iconBtn" type="button">
                                <!-- <span  [ngClass]="isLiked == false ? 'far fa-heart' : 'fas fa-heart'"></span> -->
                                <span class="fa fa-heart"></span>

                            </button>
                        </div>


                        <div *ngIf="isLogin == false" class="filterIconItem">
                            <button routerLink="/login" title="Saved Gigs" data-toggle="tooltip" class="btn iconBtn"
                                type="button">
                                <span [ngClass]="isLiked == false ? 'far fa-heart' : 'fas fa-heart'"></span>

                            </button>
                        </div>

                        <div *ngIf="isLogin == true" class="filterIconItem">
                            <button routerLink="/gigMapList" title="View on Map" data-toggle="tooltip" class="btn iconBtn"
                                type="button">
                                <span class="fa fa-map"></span>
                            </button>
                        </div>

                        <div *ngIf="isLogin == false" class="filterIconItem">
                            <button routerLink="/login" title="View on Map" data-toggle="tooltip" class="btn iconBtn"
                                type="button">
                                <span class="fa fa-map"></span>
                            </button>
                        </div>


                        <div class="filterIconItem">
                            <button id="FilterIconActive" title="Filter" data-toggle="tooltip"
                                (click)="sideBarViewOpen();" class="btn iconBtn" type="button">
                                <span class="fa fa-filter"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gigsList mt-30">
                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/no_gig.png">
                            <h2>Active gigs will appear here!</h2>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <ng-container *ngFor="let gig of gigLists">
                        <div class="col-md-12 col-lg-6">
                            <div class="gigItem" *ngIf="!gig.adImage">
                                <div class="disArtistInfo">
                                    <a [routerLink]="['/gigs/details',gig.gigId]">
                                        <h2 class="textEllipsis">{{gig.title}}</h2>
                                    </a>
                                    <h5 class="textEllipsis">{{gig.time}}</h5>
                                    <p class="mb-1"><i class="fa fa-calendar"></i> <span
                                            class="textEllipsis">{{gig.endDateTime}}</span></p>
                                    <!-- <p><i class="fa fa-map-marker-alt"></i> <span class="textEllipsis">{{gig.location}}</span></p> -->

                                    <p *ngIf="gig.isRemote == 0 && gig.gigStatus == 2 && isLogin == true|| gig.isRemote == null && gig.gigStatus==2 && isLogin == true"
                                        class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                        <span>{{gig.location}}</span>
                                    </p>
                                    <p *ngIf="gig.isRemote == 0 && gig.gigStatus == 1 && isLogin == true|| gig.isRemote == null && gig.gigStatus==1 && isLogin == true"
                                        class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                        <span>{{gig.suburb}}</span>
                                    </p>
                                    <p *ngIf="gig.isRemote == 1 && isLogin == true" class="gigLcDetail"><i
                                            class="fa fa-map-marker-alt"></i> <span>Remote</span></p>


                                    <p *ngIf="gig.isRemote == 0 && gig.gigStatus == 2 && isLogin == false|| gig.isRemote == null && gig.gigStatus==2 && isLogin == false"
                                        class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                        <span>{{gig.location}}</span>
                                    </p>
                                    <p *ngIf="gig.isRemote == 0 && gig.gigStatus == 1 && isLogin == false|| gig.isRemote == null && gig.gigStatus==1 && isLogin == false"
                                        class="gigLcDetail"><i class="fa fa-map-marker-alt"></i>
                                        <span>{{gig.suburb}}</span>
                                    </p>
                                    <p *ngIf="gig.isRemote == 1 && isLogin == false" class="gigLcDetail"><i
                                            class="fa fa-map-marker-alt"></i> <span>Remote</span></p>

                                    <div class="ratePriceBlk">
                                        <div class="priceBlk">
                                            <p>{{gig.currency}}{{gig.price}}</p>
                                        </div>
                                        <!-- <div *ngIf="isLogin == true " class="jobOpen">
                                            <label>{{gig.is_open === '1' ? 'Open' : 'Expired'}}</label>
                                        </div> -->

                                        <div class="jobOpen" *ngIf="gig.isGigCancel == 0 && gig.is_open == 1 && gig.gigStatus == 1">
                                            <label>Open</label>
                                        </div>

                                        <div class="jobExpired" *ngIf="gig.isGigCancel == 1">
                                            <label>Cancelled</label>
                                        </div>

                                        <div class="jobOpen" *ngIf="gig.isGigCancel == 0 && gig.gigStatus == 2">
                                            <label>Assigned</label>
                                        </div>

                                        <div class="jobOpen" *ngIf="gig.isGigCancel == 0 && gig.gigStatus == 3">
                                            <label>Work In Progress</label>
                                        </div>

                                        <div class="jobExpired" *ngIf="gig.isGigCancel == 0 && gig.gigStatus > 3">
                                            <label>Completed</label>
                                        </div>
                                        
                                        <!-- <div *ngIf="isLogin == false && gig.is_open == 1  " class="jobOpen">
                                            <label>Open</label>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="gigListImg">
                                    <a [routerLink]="['/gigs/details',gig.gigId]">
                                        <div *ngIf="gig.gig_images">
                                            <img *ngIf="gig.gig_images.length > 0" src="{{gig.gig_images[0].image_url}}"
                                                onerror="this.src='assets/img/defaultJob.png';">
                                            <img *ngIf="gig.gig_images.length == 0" src="/assets/img/defaultJob.png"
                                                onerror="this.src='assets/img/defaultJob.png';">
                                        </div>
                                    </a>
                                    <!-- <a routerLink="/gigDetails"><img src="assets/img/defaultJob.png"></a> -->
                                </div>
                            </div>

                            <div class="gigItem advertisements" *ngIf="gig.adImage">
                                <div>
                                    <!--                                 <a href="{{gig.adLink}}" target="_blank">
 --> <a href="{{gig.adLink}}" target="_blank" (click)="addCount(gig.id)">
                                        <img src="{{gig.adImage}}">
                                    </a>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
            </div>
            <!-- {{total}} {{limit}} {{offset}} {{(offset+limit)}} -->
            <div class="text-center" *ngIf="total > (offset+limit)">
                <!-- <div class="text-center"> -->
                <button class="btn btn-outline-info" (click)="loadMore()">Load More</button>
            </div>
        </div>
    </section>
</div>

<div id="sideBarView" class="sideBarSec">
    <div class="sideBarHead clearfix">
        <h3>Filter
            <span class="filterAct">
                <button type="button" (click)="sideBarReset();" class="btn btn-light">Reset</button>
                <button type="button" (click)="applyFilters();" class="btn thm-btn">Apply</button>
                <span class="closeCateg" (click)="sideBarViewClose();"><span class="fa fa-times"></span></span>
            </span>
        </h3>
    </div>
    <div class="sideBarBody">
        <div class="filterBody">
            <div class="csForm">


                <label style="margin-right: 20px;">
                    <input type="radio" name="filterOption" value="all" checked>
                    All
                </label>
                
                <label>
                <input type="radio" name="filterOption" value="featured">
                Featured (Hot)      
                </label>

                <div class="form-floating mb-30">
                    <input ngx-google-places-autocomplete [options]='locationoptions' #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event);" type="text" class="form-control"
                        id="floatingInput" placeholder="Enter Location" (keydown.space)="preventSpace($event)" [value]="locationValue">



                    <!-- <input type="email" class="form-control" id="floatingInput" placeholder="Enter Location"> -->
                    <label for="floatingInput">Location</label>
                </div>




                <div class="csLabelBox mb-3">
                    <!-- <h2>Start Date</h2> -->
                    <div class="row">
                        <div class="dateIcon col-12">
                            <div class="form-floating">
                                <input class="form-control" placeholder="yyyy-mm-dd" id="startDate" name="dp"
                                    [(ngModel)]="model" [minDate]="minDate" ngbDatepicker
                                    #d="ngbDatepicker" (click)="d.toggle()" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        type="button"></button>
                                </div>
                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                <label for="floatingInput">Select Start Date</label>

                            </div>
                        </div>
                        <!-- <div class="col-6">
                            <div class="form-floating">
                                <input type="time" class="form-control dateNtime startTime" id="floatingInput" placeholder="Select">
                                <label for="floatingInput">Select Time</label>
                               
                            </div>
                        </div> -->
                    </div>
                </div>







                <div class="csLabelBox mb-3">
                    <!-- <h2>End Date</h2> -->
                    <div class="row">
                        <div class="dateIcon col-12">
                            <div class="form-floating">
                                <input class="form-control" placeholder="yyyy-mm-dd" id="endDate" name="dp1"
                                    [(ngModel)]="endModel" [minDate]="minDate" ngbDatepicker
                                    #d1="ngbDatepicker" (click)="d1.toggle()" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                        type="button"></button>
                                </div>
                                <!-- <input type="email" class="form-control dateNtime" formControlName="date" id="floatingInput" placeholder="Select" readonly> -->
                                <label for="floatingInput">Select End Date</label>

                            </div>
                        </div>

                    </div>
                </div>


                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Distance</label>
                            <div id="distance_filter"><b><span>{{ sliderForm.value.sliderControl[0] }}</span> Km -
                                    <span>{{ sliderForm.value.sliderControl[1] }}</span> Km</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControl"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Price</label>
                            <div id="priceTo"><b>$<span>{{ sliderForm.value.sliderControlPrice[0] }}</span> - $<span>{{
                                        sliderForm.value.sliderControlPrice[1] }}</span></b></div>
                        </div>
                        <ngx-slider [(ngModel)]="hidghValue" (ngModelChange)="onSliderChange($event)" [options]="priceSlider" formControlName="sliderControlPrice"
                            (mouseup)="sliderEvent()"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <!-- <label class="inLabel">By Role</label> -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add
                                    Type</a>
                            </div>
                        </div>
                        <div class="TypeSlect"><!---->
                            <div class="mediaItem" *ngFor="let type of typeListData, let i=index;">
                                <h6>{{type.name}}<span (click)="removeType(i)"><i class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>


                    <!-- group type   -->
                    <!--  group type  -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Group Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal1" class="">Add
                                    Group Type</a>
                            </div>
                        </div>
                        <div class="TypeSlect">
                            <!---->
                            <div class="mediaItem" *ngFor="let type of typeListData1, let i=index;">
                                <h6>{{type.name}}<span (click)="removeType1(i)"><i class="fa fa-times"></i></span>
                                </h6>
                            </div>
                        </div>
                    </div>




                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal"
                                    class="">Add Subtypes</a>
                            </div>
                        </div>
                        <div class="TypeSlect">

                            <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                <h6>{{selectedSubType.name}}<span (click)="removeSubType(i)"><i
                                            class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>



                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Group Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal1"
                                    class="">Add Group Subtypes</a>
                            </div>
                        </div>
                        <div class="TypeSlect">

                            <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected1; let i=index;">
                                <h6>{{selectedSubType.name}}<span (click)="removeSubType1(i)"><i
                                            class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
<div id="modalOverlay" class="sidebarOverlay"></div>
<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype">
                        <div class="typesHead"> {{ category.name }}</div>
                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="typeIdSelected.includes( type.categoryId)"
                                                checked value="type.categoryId"
                                                (click)="onCheckboxChangeRole(type,$event)">
                                            <input type="checkbox" *ngIf="!typeIdSelected.includes( type.categoryId)"
                                                value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>
<div class="modal fade csModal" id="subtypeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="subTypeIdSelected.includes( subT.categoryId)"
                                                checked value="subT.categoryId"
                                                (click)="onCheckboxChangesubType(subT,$event)">
                                            <input type="checkbox" *ngIf="!subTypeIdSelected.includes( subT.categoryId)"
                                                value="subT.categoryId" (click)="onCheckboxChangesubType(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray.length == 0">Please select type first</div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>




<div class="modal fade csModal" id="roleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Group Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype1">
                        <div class="typesHead"> {{ category.name }}</div>
                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="typeIdSelected1.includes( type.categoryId)"
                                                checked value="type.categoryId"
                                                (click)="onCheckboxChangeRole1(type,$event)">
                                            <input type="checkbox" *ngIf="!typeIdSelected1.includes( type.categoryId)"
                                                value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>



                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      <button type="button" class="btn btn-primary">Save changes</button>
    </div> -->
        </div>
    </div>
</div>






<div class="modal fade csModal" id="subtypeModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Group Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray1">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="subTypeIdSelected1.includes( subT.categoryId)"
                                                checked value="subT.categoryId"
                                                (click)="onCheckboxChangesubType1(subT,$event)">
                                            <input type="checkbox"
                                                *ngIf="!subTypeIdSelected1.includes( subT.categoryId)"
                                                value="subT.categoryId" (click)="onCheckboxChangesubType1(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                                <!--                      
  onCheckboxChangeRole1
  onCheckboxChangesubType1 -->

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray1.length == 0">Please select group type first</div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>



<app-footer-livlouder></app-footer-livlouder>