<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <label>
                            <input type="text" #search id="searchText" (keyup)="searchMyTask(search.value)"
                                class="form-control" placeholder="Search Member..."
                                style="padding: 0px 46px 0px 12px;overflow: hidden !important;">
                            <span class="fa fa-search"></span>
                        </label>
                    </div>
                </div>
                <div class="filterItem ml-auto">
                    <div class="filterIconGrp">
                        <div class="filterIconItem">
                            <div class="dropdown smallDropdown">
                                <span class="d-inline-block" id="dropdownMenuButton" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <button id="activeicon" class="btn iconBtn dropdown-toggle activeFilterIcon"
                                        title="Sort By" data-toggle="tooltip" type="button">
                                        <span class="fa fa-sort-amount-down"></span>
                                    </button>
                                </span>
                                <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">

                                    <a id="allF" class="dropdown-item activeFilterIcon" (click)="allGig()"
                                        href="javascript:void(0)">All</a>
                                    <a id="showAssignSelected" class="dropdown-item" (click)="assignedGig()"
                                        href="javascript:void(0)">Assigned</a>
                                    <a id="showCompletedSelected" class="dropdown-item" (click)="completedGig()"
                                        href="javascript:void(0)">Completed</a>
                                </div>
                            </div>
                        </div>
                        <div class="filterIconItem">
                            <button routerLink="/gigsMap" title="View on Map" data-toggle="tooltip" class="btn iconBtn"
                                type="button">
                                <span class="fa fa-map"></span>
                            </button>
                        </div>
                        <!-- <div class="filterIconItem">
                            <button title="Filter" data-toggle="tooltip" (click)="sideBarViewOpen();" class="btn iconBtn" type="button">
                                <span class="fa fa-filter"></span>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>


            <div class="gigsList mt-30">

                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/no_gig.png">
                            <h2>No Record Found</h2>
                        </div>
                    </div>
                </div>

                <div class="row">


                    <ng-container *ngFor="let taskList of taskList">

                        <div class="col-md-12 col-lg-6">
                            <div class="gigItem">
                                <div class="disArtistInfo">

                                    <a [routerLink]="['/mygig/details',taskList.gigId]">
                                        <h2 class="textEllipsis">{{taskList.title}}</h2>
                                    </a>
                                    <h5 class="textEllipsis">{{taskList.time}}</h5>
                                    <p class="mb-1"><i class="fa fa-calendar"></i> <span
                                            class="textEllipsis">{{taskList.startDateTime}}</span></p>
                                    <!-- <p><i class="fa fa-map-marker-alt"></i> <span
                                            class="textEllipsis">{{taskList.location}}</span></p> -->
                                    <p *ngIf="taskList.isRemote == 0 || taskList.isRemote == null" class="textEllipsis isGigLocation">
                                        <i class="fa fa-map-marker-alt"></i> <span>{{taskList.location}}</span></p>
                                    <p *ngIf="taskList.isRemote == 1" class="textEllipsis"><i
                                            class="fa fa-map-marker-alt"></i> <span>Remote</span></p>
                                    <div class="ratePriceBlk">
                                        <div class="priceBlk">
                                            <p *ngIf="taskList.amount != undefined">${{taskList.amount}}</p>
                                            <p *ngIf="taskList.amount == undefined">${{taskList.price}}</p>
                                        </div>

                                        <div class="jobExpired" *ngIf="taskList.isGigCancel == 1">

                                            <label>Cancelled</label>
                                        </div>

                                        <div class="jobOpen"
                                            *ngIf="taskList.isGigCancel == 0 && taskList.gigStatus == 2">

                                            <label>Assigned</label>
                                        </div>

                                        <div class="jobOpen"
                                            *ngIf="taskList.isGigCancel == 0 && taskList.gigStatus == 3">

                                            <label>Work In Progress</label>
                                        </div>

                                        <div class="jobExpired"
                                            *ngIf="taskList.isGigCancel == 0 && taskList.gigStatus > 3">

                                            <label>Completed</label>
                                        </div>

                                    </div>
                                </div>


                                <div class="gigListImg">
                                    <!-- {{taskList.gig_images}} -->

                                    <a [routerLink]="['/mygig/details/',taskList.gigId]">
                                        <div *ngIf="taskList.gig_images">
                                            <img *ngIf="taskList.gig_images.length > 0"
                                                src="{{taskList.gig_images[0].image_url}}"
                                                onerror="this.src='assets/img/defaultJob.png';">
                                            <img *ngIf="taskList.gig_images.length == 0"
                                                src="/assets/img/defaultJob.png"
                                                onerror="this.src='assets/img/defaultJob.png';">
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>

                    </ng-container>

                </div>
            </div>
            <!--             <div class="text-center">
                <button class="btn btn-outline-info">Load More</button>
            </div> -->

            <div class="text-center" *ngIf="total > (offset+limit)">
                <button class="btn btn-outline-info" (click)="loadMore();">Load More</button>
            </div>
            <!-- <div class="text-center">
                <button class="btn btn-outline-info">Load More</button>
            </div> -->
        </div>
    </section>
</div>

<div id="sideBarView" class="sideBarSec">
    <div class="sideBarHead clearfix">
        <h3>Filter
            <span class="filterAct">
                <button type="button" class="btn btn-light">Reset</button>
                <!-- <button type="button" (click)="sideBarViewClose();" class="btn thm-btn">Apply</button>
            <span class="closeCateg" (click)="sideBarViewClose();"><span class="fa fa-times"></span></span> -->

                <button type="button" class="btn thm-btn">Apply</button>
                <span class="closeCateg"><span class="fa fa-times"></span></span>
            </span>
        </h3>
    </div>
    <div class="sideBarBody">
        <div class="filterBody">
            <div class="csForm">
                <div class="form-floating mb-30">
                    <input type="email" class="form-control" id="floatingInput" placeholder="Enter Location">
                    <label for="floatingInput">Location</label>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Distance</label>
                            <div><b>{{ sliderForm.value.sliderControl[0] }} Km - {{ sliderForm.value.sliderControl[1] }}
                                    Km</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControl"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Price</label>
                            <div><b>${{ sliderForm.value.sliderControlPrice[0] }} - ${{
                                    sliderForm.value.sliderControlPrice[1] }}</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControlPrice"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <!-- <label class="inLabel">By Role</label> -->
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Type</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add
                                    Role</a>
                            </div>
                        </div>
                        <div class="TypeSlect">
                            <!---->
                            <div class="mediaItem">
                                <h6>Dancer<span><i class="fa fa-times"></i></span></h6>
                            </div>
                            <div class="mediaItem">
                                <h6>Actor<span><i class="fa fa-times"></i></span></h6>
                            </div>
                        </div>
                    </div>
                    <div class="addTagBlk mb-3">
                        <div class="addTagBlkTitle">
                            <label>Subtypes</label>
                            <div class="textLink">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal" class="">Add
                                    Role</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="modalOverlay" class="sidebarOverlay"></div>
<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock">
                        <div class="typesHead">Dancer</div>
                        <div class="typesOption">
                            <ul>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="typesBlock">
                        <div class="typesHead">Singer</div>
                        <div class="typesOption">
                            <ul>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="7">
                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> Hip Hop
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>
<app-footer-livlouder></app-footer-livlouder>