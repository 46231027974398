import { Component, OnInit } from "@angular/core";

import { Options } from "@angular-slider/ngx-slider";
import { FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { Settingdata } from "src/app/services/settings";
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
// typeSubtype;

@Component({
	selector: 'app-faq-page',
	templateUrl: './faq-page.component.html',
	styleUrls: ['./faq-page.component.css']
})
export class FaqPageComponent implements OnInit {
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([20, 80]),
		sliderControlPrice: new FormControl([0, 90])
	});

	options: Options = {
		floor: 0,
		ceil: 100,
		step: 5
	};

	faq = [];
	selectedUser:any;
	constructor(
		private authService: AuthService,
		private Settingdata: Settingdata,
		private toastrService: ToastrNotificationService,

	) { }

	ngOnInit(): void {

		this.selectedUser =  localStorage.getItem("SelectedUser");
		$('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
		showLoader();
		this.Settingdata.getFaq().subscribe(result => {
			hideLoader();
			this.faq = result.data;
		},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});
	}
}
