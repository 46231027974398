import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend, HttpHeaders } from '@angular/common/http';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';


declare const toastrError:any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userMetaId:string;
  serverUrl = environment.baseUrl;
  errorData: {};
  private http: HttpClient;

  user:object;
  constructor(handler: HttpBackend,private router: Router) {
    this.http = new HttpClient(handler);
   
  }

  // login(loginData) {
  //   return this.http.post<any>(`${this.serverUrl}service/login`, loginData)
  //   .pipe(catchError(this.handleError));
  // }

  



  getContent(){
    return this.http.get<any>(`${this.serverUrl}user/getContent`)
    .pipe(catchError(this.handleError));
  }

  changeNotificationStatus(notifiyData) {
    return this.http.post<any>(`${this.serverUrl}user/changeNotificationStatus`, notifiyData)
    .pipe(catchError(this.handleError));
  }

  getAllUserTypeDetails(authToken) {
    return this.http.get<any>(`${this.serverUrl}service/getAllUserTypeDetails?authToken=${authToken}`)
    .pipe(catchError(this.handleError));
  }

  // getAllNotifications(authToken,userMetaId,limit,offset) {
  //   return this.http.get<any>(`${this.serverUrl}user/getAllNotifications?authToken=${authToken}&userMetaId=${userMetaId}&limit=${limit}&offset=${offset}`)
  //   .pipe(catchError(this.handleError));
  // }







      
        


  //   getAllNotifications(offset) {


  //       const httpHeaders = new HttpHeaders({
  //         'authorization': 'asdffdsafdfdsfsdf',
  //         'Device-Id': '23232332',
  //         'Device-Type': '3',
  //         'Device-Timezone': 'UTC'
  //       });

  //     // const offset = 0;
  //     const params = new HttpParams().set('user_id', user_id);

  //   return this.http.get<any>(`${this.serverUrl}alert/alertList?offset=${offset}`,{ headers: httpHeaders,params:params })
  //   .pipe(catchError(this.handleError));
  // }
  
  
  getWebPushNotifiction(authToken,userMetaId) {
    return this.http.get<any>(`${this.serverUrl}user/getWebPushNotifiction?authToken=${authToken}&userMetaId=${userMetaId}`)
    .pipe(catchError(this.handleError));
  }

  createAccountLink(bankData){
    return this.http.post<any>(`${this.serverUrl}user/createAccountLink`, bankData)
    .pipe(catchError(this.handleError));
  }

  getAccountDetails(authToken,stripeAccountId) {
    return this.http.get<any>(`${this.serverUrl}user/getAccountDetails?authToken=${authToken}&stripeAccountId=${stripeAccountId}`)
    .pipe(catchError(this.handleError));
  }


  addBankAccount(bankData) {
    return this.http.post<any>(`${this.serverUrl}user/addBankAccount`, bankData)
    .pipe(catchError(this.handleError));
  }




  updateAccountDetails(bankData) {
    return this.http.post<any>(`${this.serverUrl}user/updateAccountDetails`, bankData)
    .pipe(catchError(this.handleError));
  }




  isLoggedIn(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser!=null && currentUser.authToken!=''){
      return true;
    }else{
      return false;
    }
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(!currentUser){
      return false
    }
    return currentUser.authToken;
  }

  getUserRole(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser!=null && currentUser.authToken!=''){
      if(!currentUser.legalName){
        return "legalName";

      }
      else if(currentUser.isProfileComplete==1){
         return "profileDone";
      }
      else if(currentUser.isProfileComplete==0){
        return "completeProfile";
      }else if(currentUser.isProfileComplete==1 && typeof(currentUser.current_user_type) == 'undefined'){
        return "selectProfile";
      }
      return currentUser.current_user_type;
    }
  }

  getHirerProfile(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser.userTypeList[1].isProfileComplete == 1){
      return true;
    }else{
      return false;
    }
  }

  getLivLouderProfile(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser.userTypeList[0].isProfileComplete == 1){
      return true;
    }else{
      return false;
    }
  }

  getCurrentRole(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var userType = "";
    currentUser.userTypeList.forEach(element => {
      if(element.userMetaId == currentUser.currentUserMetaId){
        userType =  element.userTypeName;
      }
    });
    return userType;
  }

  getUserData(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

  setUserMetaId(userType){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

   
    currentUser.userTypeList.forEach(element => {
      // console.log('element' , currentUser.userTypeList)
      
      if(element.userType == userType){
        this.userMetaId =  element.userMetaId;
        localStorage.setItem('metaId',this.userMetaId);
      }
     
    });
  }

  getUserMetaId(){
    return this.userMetaId;
  }

  getCurrentMetaId(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.currentUserMetaId;
  }

  getCurrentUserDetails(){
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let setDetails;
    currentUser.userTypeList.forEach((item, index) => {
      if(currentUser.loginGroupId!=0){

        if(item.userMetaId==currentUser.loginGroupId){
          setDetails =  currentUser.userTypeList[index];
        }
      }else{

        if(item.userMetaId==this.getCurrentMetaId()){
          setDetails = currentUser.userTypeList[index];
        }

      }
    });

    return setDetails;

  }

  checkValidToken(data){
    if(data.status=='fail' && data.message=='Invalid token'){
      
     
      this.logout();
      this.router.navigate(['/login']);
      return false;
    }else{
      return true;
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('metaId');
    localStorage.removeItem('selectedUser');

    this.router.navigate(['/login']);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  getOnBoardingProfileCount(){

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.userTypeList[0].onBoardingProfileCount;
  }

  updateOnBoardingProfileCount(userType,boardingCount){
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
   // var typeList =currentUser.userTypeList;
    // currentUser.userTypeList[0].onBoardingProfileCount = boardingCount;
    currentUser.userTypeList.forEach(element => {
      if(element.userType == userType){
        element.onBoardingProfileCount =  boardingCount;
      }
    });
     localStorage.setItem("currentUser",JSON.stringify(currentUser));

     return ;
  }

  getHirerBoardingProfileCount(){

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.userTypeList[1].onBoardingProfileCount;
  }

}
