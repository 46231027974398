import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  siteUrl = environment.siteUrl;

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_message) => {
        _message.onTokenRefresh = _message.onTokenRefresh.bind(_message);
        _message.onBackgroundMessage = _message.onBackgroundMessage.bind(_message);
      }
    )
  }

  requestPermission() {
    // ask notification permision and  set it to in local storage
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem('device-token', token)

      }
    )
  }

  receiveMessage() {

    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // get current  user type form locat storage
        const currentUserType = localStorage.getItem('SelectedUser');
        // get notification user type from payload
        const notificationUserType = payload.data.userType;
        //add default base url for notifiction click redirection
        payload.data.url = this.siteUrl;
        // check current login user type and notification user type
        if (currentUserType == notificationUserType) {
          //when user type match set page url for  click redirection according to notifiction type
          payload.data.url = this.getNotificationRedirectionUrl(payload);
        }
        // console.log('payload=',payload);
        // set notification option value
        const NotificationOptions = {
          body: payload.notification.body,
          data: payload.data,
          icon: 'assets/img/logo-144.png'
        }
        navigator.serviceWorker.getRegistration('../firebase-cloud-messaging-push-scope').then(registration => {
          registration.showNotification(payload.notification.title, NotificationOptions);
        });
        this.currentMessage.next(payload.name);

       let badgeCount =  localStorage.getItem('badgeCount');
       let badgeCountNew:any = parseInt(badgeCount)+1;
       localStorage.setItem('badgeCount',badgeCountNew);

      })
  }

  getNotificationRedirectionUrl(payload: any) {

      // console.log('payload',payload);return false;


    var type = payload.data.notificationType;
    var id = payload.data.reference_id;
    const receiverMetaId = payload.data.userReceiverMetaId;
    const groupId = payload.data.sender_user_meta_id;

      //FOR DISPUTE CHAT IF PAYLOAD COMES DIFFERENT...
      if(type == undefined){

        if(payload.data.disputeChat != undefined){

          id = payload.data.gig_id;
          type = payload.data.disputeChat;
        }

      }

    switch (type) {
      // send  invitaion 
      case 'send_invitation':

        // return this.siteUrl + '#/groups/details/' + id;
        return this.siteUrl + '#/groupRequest/' + id;
        break;

        // payment release request 
        case 'payment_release_request':

          return this.siteUrl + '#/myGigsHirer/detail/' + id;
          break;

      // payment request by livlouder 
      case 'payment_request_by_livlouder':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

      // make offer by group 
      case 'make_offer_by_group':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

      // change gig status
      case 'changed_status_by_livlouder':

        return this.siteUrl + '#/myGigsHirer/detail/' + id;
        break;
      // review 
      case 'review_by_livlouder':
        // set url for hirer 
        let url = this.siteUrl + '#/profileHirer';
        // if user type is livlouder than se livlouder profile
        if (payload.data.receiverUserType == 1) {
          url = this.siteUrl + '#/ProfileLivlouder';
        }
        return url
        break;

      case 'group_req_accept':

        return this.siteUrl + '#/discover/details/' + groupId;
        break;

      case 'group_req_reject':

        return this.siteUrl + '#/groups';
        break;

      case 'make_offer':

        return this.siteUrl + '#/myGigsHirer/detail/' + id;
        break;

        case 'make_offer_by_group':

        return this.siteUrl + '#/gigs/details/' + id;
        break;

      case 'accept_offer':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

      case 'payment_released_by_hirer':
        return this.siteUrl + '#/mygig/details/' + id;
        break;

      case 'review_by_hirer':

        return this.siteUrl + '#/ProfileLivlouder';
        break;

      case 'tip_by_hirer':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

      case 'book_me_request':

        // return this.siteUrl + '#/gigs/details/' + id;
        return this.siteUrl + '#/bookRequest';
        break;

      case 'reject_book_me_request':

        return this.siteUrl + '#/ProfileLivlouder';
        break;

      case 'accept_book_me_request':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

//added code...
        case 'payment_requested_by_livlouder':

        return this.siteUrl + '#/myGigsHirer/detail/' + id;
        break;

        case 'payment_released_by_livlouder':

        return this.siteUrl + '#/groups/details/' + groupId;
        break;

        case 'give_answer':

        return this.siteUrl + '#/mygig/details/' + id;
        break;

        case 'ask_question':

        return this.siteUrl + '#/myGigsHirer/detail/' + id;
        break;

        // return this.siteUrl + '#/gigs/details/' + id;

        // set url for hirer 
        let urlNew = this.siteUrl+'#/myGigsHirer/detail/'+ id;
        // if user type is livlouder than se livlouder profile
        if(payload.data.receiverUserType == 1){
          urlNew = this.siteUrl+'#/gigs/details/' + id;
        }
        return urlNew;
        break;

      case 'send_report_by_livlouder':

        return this.siteUrl + '#/discoverHirer/detail/' + groupId;
        break;

      case 'send_report_by_hirer':
        
        return this.siteUrl + '#/posted-by-profile/' +  groupId;
        break;

        case 'dispute':
          let disputeUrl = this.siteUrl + '#/myGigsHirer/detail/' + id;
          if(payload.data.receiverUserType == 1){
            disputeUrl = this.siteUrl + '#/gigs/details/' + id;
          }
          return disputeUrl;
          break;

        case 'disputeChat':
          let disputeChat = this.siteUrl + '#/myGigsHirer/detail/' + id;
          if(payload.data.receiverUserType == 1){
            disputeChat = this.siteUrl + '#/gigs/details/' + id;
          }
          return disputeChat;
          break;

      case 'send_report':

        // return this.siteUrl;
        return this.siteUrl + '#/myGigsHirer/detail/' + id;

        break;

      default:
        return this.siteUrl;
        break;
    }


  }


}
