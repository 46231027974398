<app-header-hirer *ngIf="selectedUser == 2"></app-header-hirer>
<app-header-livlouder *ngIf="selectedUser == 1"></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="gigsMap mt-30" *ngIf="gigMapType ==1">
                <agm-map [latitude]="lat" [longitude]="lng" style="height:450px !important;">
                    <ng-container *ngFor="let location of locations">
                        <agm-marker [latitude]="location.latitude" [longitude]="location.longitude">
                            <agm-info-window>
                                <strong>{{location.title}}</strong>

                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </agm-map>
            </div>

            <div class="gigsMap mt-30" *ngIf="gigMapType ==2">
                <agm-map [latitude]="lat" [longitude]="lng" style="height:450px !important;">
                    <!-- <ng-container> -->
                    <agm-direction [origin]="routeCredencial.origin" [destination]="routeCredencial.destination">
                    </agm-direction>
                    <!-- </ng-container> -->
                </agm-map>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder *ngIf="selectedUser == 1"></app-footer-livlouder>
<app-footer-hirer *ngIf="selectedUser == 2"></app-footer-hirer>