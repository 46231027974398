import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse,HttpBackend,HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare const toastrError:any;
@Injectable({
  providedIn: 'root'
})

export class Openaccess{
    serverUrl = environment.baseUrl;
    constructor(private http: HttpClient) { }

  
    getList(param){

      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      // let params = new HttpParams().set('', param);
     return this.http.get<any>(`${this.serverUrl}/open_acess/gig`, { headers: httpHeaders,params:param })

    }


    getTypeSubType(){
      const httpHeaders = new HttpHeaders({
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      return this.http.get<any>(`${this.serverUrl}/category/typeSubtype`,{ headers: httpHeaders})
    }

    gigDetail(gigId){

      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      let params = new HttpParams().set('gigId', gigId);
     return this.http.get<any>(`${this.serverUrl}/open_acess/detail`, { headers: httpHeaders,params:params })
    }


    discoverDetail(userMetaId){

      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      let params = new HttpParams().set('userMetaId', userMetaId);
     return this.http.get<any>(`${this.serverUrl}/open_acess/livlouder_detail`, { headers: httpHeaders,params:params })
    }

    discoverlist(param){

      const httpHeaders = new HttpHeaders({
        'authorization': 'asdffdsafdfdsfsdf',
        'Device-Id': '23232332',
        'Device-Type': '3',
        'Device-Timezone': 'UTC'
      })
      // let params = new HttpParams().set('', param);
     return this.http.get<any>(`${this.serverUrl}/open_acess/livlouder`, { headers: httpHeaders,params:param })

    }
  }