<!-- <app-header-hirer *ngIf="selectedUser == 2"></app-header-hirer>
<app-header-livlouder *ngIf="selectedUser == 1"></app-header-livlouder> -->
<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="sec-pd-30">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-left head-txt mb-30">
                        <h2>Help</h2>
                    </div>
                </div>
            </div>
            <div class="faqBlock">
                <div class="panel-group" id="accordion">
                    <!-- <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" class="collapsed">I am totally new to this "website thing". How does the whole process work?</a> </h4>
                        </div>

                        <div id="collapseOne" class="panel-collapse collapse" aria-expanded="false" style="height: 0px;">
                            <div class="panel-body">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                                    a type specimen book.</p>
                            </div>
                        </div>
                    </div> -->

                    <!--======= ACCORDING 2 =========-->
                    <div class="panel panel-default" *ngFor="let help of help; let i = index; ">
                        <div class="panel-heading" >
                            <h4 class="panel-title"> <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed" aria-expanded="false">{{help.question}}</a> </h4>
                        </div>

                        <div id="collapse{{i}}" class="panel-collapse collapse" aria-expanded="false">
                            <div class="panel-body">
                                <p>Ans : {{help.answer}}</p>
                            </div>
                        </div>
                    </div>

                    


                   
            
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder *ngIf="selectedUser == 1"></app-footer-livlouder>
<app-footer-hirer *ngIf="selectedUser == 2"></app-footer-hirer>