import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GigService } from "src/app/services/gig-service";
import { AuthService } from "src/app/services/auth.service";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;
@Component({
	selector: "app-discover-details",
	templateUrl: "./discover-details.component.html",
	styleUrls: ["./discover-details.component.css"]
})
export class DiscoverDetailsComponent implements OnInit, AfterViewChecked {
	reportForm: FormGroup;

	title = "Livlouder - Discover Detail";
	userMetaId;
	data;
	experience = [];
	isLogin;
	reportTitle;
	is_favourite;
	groupList;
	dataFound;
	userId;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private gigService: GigService,
		private titleService: Title,
		private authService: AuthService,
		private toastrService: ToastrNotificationService
	) { }

	ngOnInit(): void {
		if (this.authService.isLoggedIn() == true) {
			this.authService.setUserMetaId(1);
		}

		this.isLogin = this.authService.isLoggedIn() == true; // check  user logged in or not 
		this.titleService.setTitle(this.title); // set title 

		// get id from url 
		this.activatedRoute.paramMap.subscribe(param => {
			this.userMetaId = param.get("id");
		});

		if (this.authService.isLoggedIn() == true) {
			// discover list api 
			this.gigService.discoverDetail(this.userMetaId).subscribe(res => {
				this.data = res.detail;
				this.userId = this.data.userId;
				
				this.is_favourite = this.data.is_favourite;

				if (this.data.experience) {
					this.experience = JSON.parse(this.data.experience);
				}
				this.data.rating_review.data.forEach(element => {
					element.agotime = this.diffHours(
						element.created_date,
						element.current_time
					);
				});
			}, (err) => {
				hideLoader()

				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});

		} else {
			this.gigService.discoverDetailOpen(this.userMetaId).subscribe(res => {
				this.data = res.detail;
				if (this.data.experience) {
					this.experience = JSON.parse(this.data.experience);
				}
				this.data.rating_review.data.forEach(element => {
					element.agotime = this.diffHours(
						element.created_date,
						element.current_time
					);
				});
			});
		}

		if (this.authService.isLoggedIn() == true) {
			//get report title
			this.gigService.getReportTitle().subscribe(res => {
				this.reportTitle = res.data;
			}, (err) => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					//this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			});
		}


		this.reportForm = new FormGroup({
			title: new FormControl(null, Validators.required),
			description: new FormControl(null, [
				Validators.required,
				Validators.maxLength(300),
				Validators.minLength(5)
			])
		});
	}

	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;

		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs(date2 - date1);
		var diffHr = difference / 60 / 60;
		var daysDiff = difference / 60 / 60 / 24;
		if (isNaN(daysDiff)) {
			return "N/A";
		}
		if (daysDiff < 1) {
			if (diffHr < 1) {
				difference = difference / 60;
				var hourDiff = Math.floor(difference) + " Min Ago";
			} else {
				difference = difference / 60 / 60;
				var hourDiff = Math.floor(difference) + " Hour Ago";
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + " Days Ago";
		}

		return hourDiff;
	}


	// Like Livlouder 
	likeLivlouder() {
		var metaId = this.authService.getUserMetaId();

		var formData = new FormData();
		formData.append("userId", metaId);
		formData.append("metaId", this.userMetaId);

		showLoader();
		this.gigService.likeLivlouder(formData).subscribe(res => {
			hideLoader();

			this.is_favourite = res.is_favourite;

			this.toastrService.showSuccess(res.message, "Success");
		}, (err) => {
			hideLoader()

			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		}
		);
	}

	// Report Gig 
	reportLivouder() {
		if (this.reportForm.invalid) {
			this.validateAllFormFields(this.reportForm);
			return false;
		} else {
			var metaId = this.authService.getUserMetaId();

			const formData = new FormData();
			formData.append("titleId", this.reportForm.value.title);
			formData.append("description", this.reportForm.value.description);
			formData.append("reportToMetaId", this.userMetaId);
			formData.append("reportByMetaId", metaId);
			formData.append("reportByUserType", "1");

			showLoader();
			this.gigService.reportLivlouder(formData).subscribe(
				res => {
					if ((status = "success")) {
						$("#reportUser").modal("hide");
						hideLoader();
						this.reportForm.reset();

						this.toastrService.showSuccess(res.message, "Success");
					}
				},
				err => {
					hideLoader();
					$("#reportUser").modal("hide");

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');

					} else {
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}
	// function for validate form
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}

	// My Group List For Invite Member
	groupListForAdmin() {
		$("#groupModel").modal("hide");
		const currentUser = JSON.parse(localStorage.getItem("currentUser"));

		var obj: any = {};
		obj.userId = currentUser.userId;
		showLoader();
		this.gigService.groupListAdmin(obj).subscribe(
			res => {

				
				hideLoader();
				var data = res;
				if (data.message == 'Found') {

					this.groupList = data.data;
					this.dataFound = true;
				} else {

					this.groupList = [];
					this.dataFound = false;
				}
			},
			err => {

				hideLoader();
				// if(err.error.status=='fail' && err.error.message=='Invalid token'){
				// 	this.authService.checkValidToken(err.error);
				// 	$("#groupModel").modal("hide");

					//this.toastrService.showError('Session Expired', 'Error');

				// }else{
				// this.toastrService.showError(err.error.message, 'Error');
				// }

			}
		);
	}

	// invite member for group
	inviteMember() {
		var id = $('input[name="groupChoose"]:checked').val();
		this.userMetaId;
		if (id == undefined) {
			this.toastrService.showError('Please select group', "Error");
			return false;
		}

		var current_user_data = JSON.parse(localStorage.getItem('currentUser'));
		var senderMeta = localStorage.getItem('metaId');
		const formData = new FormData();
		formData.append("userMetaId", id); //userMetaId of group
		formData.append("metaId", this.userMetaId); // who receives inviatation meta Id
		formData.append("userId", this.userId); // who receives inviatation user Id
		formData.append("senderMetaId", senderMeta); //who sends inviatation
		showLoader();
		this.gigService.inviteMember(formData).subscribe(
			res => {
				hideLoader()
				$("#groupModel").modal("hide");

				this.toastrService.showSuccess(res.message, "Success");

				$("#groupModel").modal("hide");

			}, (err) => {
				$("#groupModel").modal("hide");

				hideLoader()
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastrService.showError(err.error.message, 'Error');
				}
			})

	}
}
