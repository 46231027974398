// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-open-gig-details',
//   templateUrl: './open-gig-details.component.html',
//   styleUrls: ['./open-gig-details.component.css']
// })
// export class OpenGigDetailsComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { subscribeOn } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { GigService } from 'src/app/services/gig-service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { PaymentService } from 'src/app/services/payment.service';
import { Openaccess } from "src/app/services/openaccess";

declare var $: any
declare const showLoader: any;
declare const hideLoader: any;

@Component({
  selector: 'app-open-gig-details',
  templateUrl: './open-gig-details.component.html',
  styleUrls: ['./open-gig-details.component.css']
})
export class OpenGigDetailsComponent implements OnInit {
  show = false;
  public show_dialog: boolean = false;
  stripeUlr;
  gigData;
  gigId;
  mustHave;
  images;
  agoTime;
  myOffer;
  offers;
  qusAns;
  reportTitle;
  is_favourite;
  offerForm: FormGroup;
  reportForm: FormGroup;
  bankAccountForm: FormGroup;
  title = 'Livloud- Gig Detail';
  metaId;
  popupStatus;
  subtype: any = [];
  isLogin;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gigService: GigService,
    private authService: AuthService,
    private toastrService: ToastrNotificationService,
    private titleService: Title,
    private payService: PaymentService,
    private fb: FormBuilder,
    private Openaccess: Openaccess,

  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.isLogin = this.authService.isLoggedIn() == true;

    // this.authService.setUserMetaId(1);

    $('#imageGallery').lightGallery({
      thumbnail: true,
      selector: '.addedImg',
      download: false
    });

    this.offerForm = new FormGroup({
      'offerDescription': new FormControl(null, [Validators.maxLength(300), Validators.minLength(5)]),
      'offerPrice': new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
    });

    this.reportForm = new FormGroup({
      'title': new FormControl(null, Validators.required),
      'description': new FormControl(null, [Validators.required, Validators.maxLength(300), Validators.minLength(5)]),
    });

    showLoader();
    // var metaId = this.authService.getUserMetaId();
    // this.metaId = metaId;


    this.activatedRoute.paramMap.subscribe((param) => {
      this.gigId = param.get('id');
    })
    //this.gigId = 57;
    this.Openaccess.gigDetail(this.gigId).subscribe(res => {

      this.filterDetail(res)
      console.log(res);

    }, err => {
      hideLoader();
      this.toastrService.showError(err.error.message, "Error");
      this.authService.checkValidToken(err.error);

      console.log(err);
    })

    //get report title
    this.gigService.getReportTitle().subscribe(res => {
      this.reportTitle = res.data;
    })

    this.bankAccountForm = this.fb.group({
      accountName: ['', Validators.required],
      bsdCode: ['', Validators.required],
      accountNo: ['', Validators.required],
      // address:['',Validators.required]
    })

  }

  ngAfterViewChecked() {
    $('#imageGallery').lightGallery({
      thumbnail: true,
      selector: '.addedImg',
      download: false
    });
    $('#galleryVideos').lightGallery({
      selector: '.addedImg'
    });
  }

  // Ask Question 
  askQuestion(question) {

    if (this.authService.isLoggedIn() == false) {

      this.router.navigate(["/login"]);
    } else {

      var metaId = this.authService.getUserMetaId();
      var formData = new FormData();
      formData.append('metaId', metaId);
      formData.append('gigId', this.gigId);
      formData.append('question', question)

      this.gigService.gigQuestion(formData).subscribe(res => {

        this.gigDetail(metaId);
        $('#question1').val('');

      }, err => {
        hideLoader();

        this.toastrService.showError(err.error.message, "Error");
        this.authService.checkValidToken(err.error);

        console.log(err);

      })
    }
  }



  /**
   * Report gig
   * 
   */
  reportGig() {

    if (this.reportForm.invalid) {
      this.validateAllFormFields(this.reportForm);
      return false;
    } else {

      const formData = new FormData();
      formData.append('titleId', this.reportForm.value.title)
      formData.append('description', this.reportForm.value.description)
      formData.append('userType', '1')
      formData.append('gigId', this.gigId)

      showLoader();
      this.gigService.reportGig(formData).subscribe(res => {

        if (status = 'success') {
          $('#reportUser').modal('hide');
          hideLoader();
          this.reportForm.reset();

          this.toastrService.showSuccess(res.message, 'Success')
        }

      }, err => {
        hideLoader();
        console.log(err);
        this.toastrService.showError(err.error.message, 'Error');
      })

    }

  }

  /**
   * save gig make as favorite
   */
  saveGigAsFavorite() {
    var metaId = this.authService.getUserMetaId();

    var formData = new FormData();
    formData.append('gigId', this.gigId);
    formData.append('metaId', metaId);

    showLoader();
    this.gigService.saveGig(formData).subscribe(res => {
      hideLoader();

      this.is_favourite = res.is_favourite

      this.toastrService.showSuccess(res.message, 'Success');

    });
  }


  toggle() {
    this.show_dialog = !this.show_dialog;
  }

  /**
   * 
   * @param event 
   * @returns void
   */
  offerGig(event) {
    if (!this.offerForm.valid) {
      this.validateAllFormFields(this.offerForm);

      return false;
    } else {


      var metaId = this.authService.getUserMetaId();
      var formData = new FormData();
      formData.append('metaId', metaId);
      formData.append('gigId', this.gigId);
      formData.append('price', this.offerForm.value.offerPrice);
      formData.append('comment', this.offerForm.value.offerDescription)
      showLoader();
      this.gigService.livlouderOfferGig(formData).subscribe(res => {
        hideLoader();
        $('#makeAnOffer').modal('hide');
        this.gigDetail(metaId);
      })

    }
  }

  gigDetail(metaId) {
    this.gigService.gigDetail(this.gigId, metaId).subscribe(res => {
      this.filterDetail(res)


    });
  }



  filterDetail(res) {
    hideLoader();
    this.gigData = res.data;
    this.mustHave = JSON.parse(res.data.mustHave);
    this.myOffer = this.gigData.myOffer;
    this.offers = this.gigData.offers;
    this.is_favourite = this.gigData.is_favourite
    this.offers.forEach((data) => {

      data.agoTime = this.diffHours(data.posted_time, this.gigData.current_time);
    })

    this.subtype = this.gigData.subtype.split(',');
    this.qusAns = this.gigData.qusAns;
    this.qusAns.forEach((data) => {

      data.agoTime = this.diffHours(data.posted_time, this.gigData.current_time);
    })

    this.agoTime = this.diffHours(this.gigData.posted_time, this.gigData.current_time)

    return;

  }

  diffHours(t1, t2) {
    var date1 = new Date(t1).getTime() / 1000;

    var date2 = new Date(t2).getTime() / 1000;

    var difference = Math.abs((date2 - date1));
    var diffHr = (difference / 60 / 60);
    var daysDiff = (difference / 60 / 60 / 24)
    if (daysDiff < 1) {
      if (diffHr < 1) {

        difference = (difference / 60);
        var hourDiff = Math.floor(difference) + ' Min Ago'

      } else {

        difference = (difference / 60 / 60);
        var hourDiff = Math.floor(difference) + ' Hour Ago'
      }
    } else {
      var hourDiff = Math.floor(daysDiff) + ' Days Ago'
    }

    return hourDiff;

  }

  checkBankAccount() {
    //"ba_1J2GezQbwiQe07U7br4qGXk2"
    if (this.payService.getStripeBankId()) {
      $('#makeAnOffer').modal('show');

    } else {
      showLoader();
      var formData = new FormData();
      formData.append('accountId', this.payService.getStripeAccountID());

      //


      //

      this.payService.verifyLinkAccount(formData).subscribe(res => {
        hideLoader();
        if (res.data.for == 3) {
          var linkUrl = res.data.linkData.url
          this.stripeUlr = res.data.linkData.url;
          this.popupStatus = 1;
          $('#confirmModal').modal('show');

        }
        if (res.data.for == 2) {
          hideLoader();
          this.popupStatus = 2;
          $('#confirmModal').modal('show');
          // if(confirm("Please add account ")){
          //   $('#addBank').modal('show');
          // }

        }



      })
    }

  }

  addBankAccount() {
    if (this.bankAccountForm.invalid) {
      this.validateAllFormFields(this.bankAccountForm)
      return false
    } else {

      showLoader()

      var formData = new FormData();
      formData.append('holderName', this.bankAccountForm.value.accountName)
      formData.append('routingNumber', '110000')
      formData.append('accountNo', this.bankAccountForm.value.accountNo)

      this.payService.addBankAccount(formData).subscribe(res => {
        hideLoader();
        if (res.status == 'success') {
          this.toastrService.showSuccess(res.message, 'Success');
          this.payService.updateStripeBankId(res.stripeBankId);
          this.resetBankForm();
        } else {
          this.toastrService.showError(res.message, 'Error');
        }

      }, err => {
        console.log(err);
      })
    }

  }

  stripeVerifyLink() {
    $('#confirmModal').modal('hide');
    // 1 for verify stripe account , 2 for add bank detail
    if (this.popupStatus == 1) {
      //https://giglooker.com/dev/livloud/stripe-success
      //https://giglooker.com/livloudNew/stripe-success
      window.location.href = this.stripeUlr;
    } else {
      $('#addBank').modal('show');
    }

  }

  resetBankForm() {
    this.bankAccountForm.reset();
    // this.bankAccountForm.pristine ;
    // this.bankAccountForm.reset(this.bankAccountForm.value); //to remove only validation error
    $('#addBank').modal('hide');

  }

  resetOfferForm() {
    $('#makeAnOffer').modal('hide');
    this.offerForm.reset();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });

    return false;
  }


  /**
   * Cancel gig
   */
  cancelGig() {
    var metaId = this.authService.getUserMetaId();
    var price = this.getOfferPrice();
    var formData = new FormData();
    formData.append('gigId', this.gigId);
    formData.append('userType', '1');
    formData.append('amount', price);
    formData.append('hirerMetaId', this.gigData.postedBymetaId);
    formData.append('livlouderMetaId', metaId);


    showLoader();
    this.gigService.cancelGig(formData).subscribe(res => {

      $('#cancelGigAlert').modal('hide');
      hideLoader();
      this.toastrService.showSuccess(res.message, 'Success');
      this.router.navigate(['/gigs']);
    }, err => {
      $('#cancelGigAlert').modal('hide');
      this.toastrService.showError(err.error.message, 'Error');
    })
  }

  getOfferPrice() {

    let price;
    this.gigData.offers.forEach(element => {
      if (element.isAssign == 1) {

        price = element.price;
      }
    });

    return price;

  }

  startGig() {

    var formData = new FormData();
    formData.append('gigStatus', '3')
    formData.append('gigId', this.gigId)


    showLoader();
    this.gigService.changeGigStatus(formData).subscribe(res => {
      hideLoader();
      if (res.status == 'success') {
        this.toastrService.showSuccess(res.message, 'Success')
        location.reload();
      }
    }, err => {
      hideLoader();
      console.log(err.error.message);
    })

  }

  endGig() {
    var formData = new FormData();
    formData.append('gigStatus', '4')
    formData.append('gigId', this.gigId)


    this.gigService.changeGigStatus(formData).subscribe(res => {

      hideLoader();
      if (res.status == 'success') {
        this.toastrService.showSuccess(res.message, 'Success')
        location.reload();
      }
    }, err => {
      hideLoader();
      console.log(err.error.message);
    })

  }
  test() {
    // alert('hiiiiiii');
    this.router.navigate(['/login']);

  }
  requestPayment() {
    var formData = new FormData();
    formData.append('gigStatus', '5')
    formData.append('gigId', this.gigId)


    this.gigService.changeGigStatus(formData).subscribe(res => {

      hideLoader();
      if (res.status == 'success') {
        this.toastrService.showSuccess(res.message, 'Success')
        location.reload();
      }
    }, err => {
      hideLoader();
      console.log(err.error.message);
    })
  }

}

