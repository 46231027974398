<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="topFilterBlk">
                <div class="filterItemSearch">
                    <div class="searchBar searchbarPage">
                        <!-- <label>
                            <input type="text" #search id="searchText" (keyup)="searchDiscover(search.value)" class="form-control" placeholder="Search Member...">
                            <span class="fa fa-search"></span>
                        </label> -->
                        <label>
                            <input *ngIf='isLiked == false' type="text" #search id="searchText"
                                (keyup)="searchDiscover(search.value)" class="form-control"
                                placeholder="Search Member..."
                                style="padding: 0px 46px 0px 12px;overflow: hidden !important;">
                            <input *ngIf='isLiked == true' type="text" #search id="searchText"
                                (keyup)="likedLivlouder()" class="form-control" placeholder="Search Member..."
                                style="padding: 0px 46px 0px 12px;overflow: hidden !important;">
                            <span class="fa fa-search"></span>
                        </label>
                    </div>
                </div>
                <div class="filterItem ml-auto">
                    <div class="filterIconGrp">
                        <div class="filterIconItem">
                            <div class="dropdown smallDropdown">
                                <span class="d-inline-block" id="dropdownMenuButton " data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <button class="btn iconBtn dropdown-toggle activeFilterIcon" id="activeicon"
                                        title="Sort By" data-toggle="tooltip" type="button">
                                        <span class="fa fa-sort-amount-down"></span>
                                    </button>
                                </span>
                                <div class="dropdown-menu dropdown-right" aria-labelledby="dropdownMenuButton">
                                    <a id="allF" class="dropdown-item activeFilterIcon" href="javascript:void(0)"
                                        (click)="allDiscover()">All</a>
                                    <a id="hotF" (click)="filterhot(1)" class="dropdown-item"
                                        href="javascript:void(0)">Featured(hot)</a>
                                    <!-- <a class="dropdown-item" href="javascript:void(0)">Rating</a> -->
                                    <a id="priceF" class="dropdown-item" href="javascript:void(0)" (mouseover)='over()'
                                        (mouseout)='out()'>Price</a>
                                    <div class="dropdown-content" style="display: none;" id="priceFilter">
                                        <a class="dropdown-item" id="priceasc" (click)="filterPrice('asc')"
                                            (mouseover)='over()' href="javascript:void(0)">Low to high</a>
                                        <a class="dropdown-item" id="pricedesc" (click)="filterPrice('desc')"
                                            (mouseover)='over()' href="javascript:void(0)">High to low</a>

                                    </div>
                                    <a id="rateF" class="dropdown-item" href="javascript:void(0)" (mouseover)='over1()'
                                        (mouseout)='out1()'>Rate</a>

                                    <div class="dropdown-content" style="display: none;" id="priceFilter1">
                                        <a class="dropdown-item" id="rateasc" (click)="filterRate('asc')"
                                            (mouseover)='over1()' href="javascript:void(0)">Low to high</a>
                                        <a class="dropdown-item" id="ratedesc" (click)="filterRate('desc')"
                                            (mouseover)='over1()' href="javascript:void(0)">High to low</a>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="filterIconItem">
                            <button *ngIf="isLiked == false" title="Liked Livlouders" (click)="likedLivlouder()"
                                data-toggle="tooltip" class="btn iconBtn" type="button">
                                <span [ngClass]="isLiked == false ? 'far fa-heart' : 'fas fa-heart'"></span>

                            </button>
                            <button *ngIf="isLiked == true" title="Liked Livlouders" (click)="allDiscover()"
                                data-toggle="tooltip" class="btn iconBtn" type="button">
                                <!-- <span  [ngClass]="isLiked == false ? 'far fa-heart' : 'fas fa-heart'"></span> -->
                                <span class="fa fa-heart"></span>

                            </button>
                        </div>
                        <div class="filterIconItem">
                            <button title="Filter" id="FilterIconActive" data-toggle="tooltip"
                                (click)="sideBarViewOpen();" class="btn iconBtn" type="button">
                                <span class="fa fa-filter"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gigsList discoverList mt-30">
                <div *ngIf="!dataFound">
                    <div class="noDataBlk">
                        <div class="noDataCnt">
                            <img src="assets/img/no_livlouder.png">
                            <h2>No Livlouders active at the moment</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <ng-container *ngFor="let list of lists">
                        <div class="col-md-12 col-lg-6">
                            <div class="gigItem">
                                <div class="disArtistInfo">
                                    <a *ngIf="list.userType == '1' "
                                        [routerLink]="['/discoverHirer/detail',list.userMetaId]">
                                        <h2 class="textEllipsis">{{list.liveLouderName | uppercase}}</h2>
                                    </a>
                                    <a *ngIf="list.userType == '3' "
                                        [routerLink]="['/hirerGroups/details/',list.userMetaId]">
                                        <h2 class="textEllipsis">{{list.groupName | uppercase}}</h2>
                                    </a>
                                    <h5 *ngIf="list.type" class="textEllipsis">{{list.type}}</h5>
                                    <h5 *ngIf="list.type == ''  || list.type == null " class="textEllipsis">
                                        {{list.gtype}}</h5>



                                    <p *ngIf="list.subType" class="mb-2"><span
                                            class="textEllipsis">{{list.subType}}</span></p>
                                    <p *ngIf="list.subType == '' || list.subType == null  " class="mb-2"><span
                                            class="textEllipsis">{{list.gsubType}}</span></p>
                                    <p *ngIf="list.isHot == '1'" class="mb-2"><span
                                            class="textEllipsis">{{list.isHot}}hot</span></p>

                                    <p *ngIf="list.suburb == '' || list.suburb == null"><i
                                            class="fa fa-map-marker-alt"></i> <span class="textEllipsis">NA</span>
                                    </p>

                                    <p *ngIf="list.suburb != '' && list.suburb != null"><i
                                            class="fa fa-map-marker-alt"></i> <span
                                            class="textEllipsis isGigLocation">{{list.suburb}}</span>
                                    </p>

                                    <div class="ratePriceBlk">
                                        <div class="priceBlk">
                                            <p *ngIf="list.userType != '3'">
                                                {{list.currency}}{{list.price}}/<span>Hr</span></p>
                                            <p *ngIf="list.userType =='3'">
                                                {{list.currency}}{{list.groupPrice}}/<span>Hr</span></p>
                                        </div>
                                        <div class="rateBlk">
                                            <p><span class="fa fa-star"></span>{{list.rating}}</p><span
                                                class="totalReeview">({{list.totalUserRating}})</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="gigListImg">
                                    <a *ngIf="list.userType != '3'"
                                        [routerLink]="['/discoverHirer/detail',list.userMetaId]"><img
                                            [src]="list.profileImage"></a>
                                    <a *ngIf="list.userType == '3'"
                                        [routerLink]="['/hirerGroups/details',list.userMetaId]"><img
                                            [src]="list.groupProfileImage"></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
            <!-- <div class="text-center" *ngIf="total > test(offset,limit)">
                <button class="btn btn-outline-info" (click)="loadMore();">Load More{{total}} {{test(offset,limit)}}</button>
            </div> -->

            <div class="text-center" *ngIf="total > (offset+limit)">
                <button class="btn btn-outline-info" (click)="loadMore();">Load More</button>
            </div>
        </div>
    </section>
</div>

<div id="sideBarView" class="sideBarSec">
    <div class="sideBarHead clearfix">
        <h3>Filter
            <span class="filterAct">
                <button type="button" (click)="sideBarReset();" class="btn btn-light">Reset</button>
                <button type="button" (click)="applyFilter123();" class="btn thm-btn">Apply</button>
                <span class="closeCateg" (click)="sideBarViewClose();"><span class="fa fa-times"></span></span>
            </span>
        </h3>
    </div>
    <div class="sideBarBody">
        <div class="filterBody">
            <div class="csForm">
                <div class="form-floating mb-30">
                    <input ngx-google-places-autocomplete [options]='locationoptions' #placesRef="ngx-places"
                        (onAddressChange)="handleAddressChange($event);" type="text" class="form-control"
                        id="floatingInput" placeholder="Enter Location" (keydown.space)="preventSpace($event)" [value]="locationValue">

                    <!-- <input type="email" class="form-control" id="floatingInput" placeholder="Enter Location"> -->
                    <label for="floatingInput">Location</label>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Distance</label>
                            <div id="distance_filter"><b><span>{{ sliderForm.value.sliderControl[0] }}</span> Km -
                                    <span>{{ sliderForm.value.sliderControl[1] }}</span> Km</b></div>
                        </div>
                        <ngx-slider [options]="options" formControlName="sliderControl"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">
                    <form [formGroup]="sliderForm">
                        <div class="rangeValue">
                            <label class="inLabel">Prices</label>
                            <div id="priceTo"><b>$<span>{{ sliderForm.value.sliderControlPrice[0] }}</span> - $<span>{{
                                        sliderForm.value.sliderControlPrice[1] }}</span></b></div>
                        </div>
                        <ngx-slider [(ngModel)]="hidghValue" (ngModelChange)="onSliderChange($event)" [options]="priceSlider" formControlName="sliderControlPrice"></ngx-slider>
                    </form>
                </div>
                <div class="form-group">

                    <form [formGroup]="neha">

                        <!-- <label class="inLabel">By Role</label> -->
                        <div class="addTagBlk mb-3">
                            <div class="addTagBlkTitle">
                                <label>Type</label>
                                <div class="textLink">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal"
                                        class="">Add Solo Livlouders Types</a>
                                </div>
                            </div>
                            <div class="TypeSlect"><!---->
                                <div class="mediaItem" *ngFor="let type of typeListData, let i=index;">
                                    <h6>{{type.name}}<span (click)="removeType(i)"><i class="fa fa-times"></i></span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="addTagBlk mb-3">
                            <div class="addTagBlkTitle">
                                <label>Group Type</label>
                                <div class="textLink">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#roleModal1"
                                        class="">Add Group Livlouder Types</a>
                                </div>
                            </div>
                            <div class="TypeSlect"><!---->
                                <div class="mediaItem" *ngFor="let type of typeListData1, let i=index;">
                                    <h6>{{type.name}}<span (click)="removeType1(i)"><i class="fa fa-times"></i></span>
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div class="addTagBlk mb-3">
                            <div class="addTagBlkTitle">
                                <label>Subtypes</label>
                                <div class="textLink">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal"
                                        class="">Add Solo Livlouders Subtypes</a>
                                </div>
                            </div>
                            <div class="TypeSlect">

                                <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected; let i=index;">
                                    <h6>{{selectedSubType.name}}<span (click)="removeSubType(i)"><i
                                                class="fa fa-times"></i></span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="addTagBlk mb-3">
                            <div class="addTagBlkTitle">
                                <label>Group Subtypes</label>
                                <div class="textLink">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#subtypeModal1"
                                        class="">Add Group Livlouder Subtypes</a>
                                </div>
                            </div>
                            <div class="TypeSlect">

                                <div class="mediaItem" *ngFor="let selectedSubType of subTypeSelected1; let i=index;">
                                    <h6>{{selectedSubType.name}}<span (click)="removeSubType1(i)"><i
                                                class="fa fa-times"></i></span></h6>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="modalOverlay" class="sidebarOverlay"></div>
<div class="modal fade csModal" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype">
                        <div class="typesHead"> {{ category.name }}</div>
                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="typeIdSelected.includes( type.categoryId)"
                                                checked value="type.categoryId"
                                                (click)="onCheckboxChangeRole(type,$event)">
                                            <input type="checkbox" *ngIf="!typeIdSelected.includes( type.categoryId)"
                                                value="type.categoryId" (click)="onCheckboxChangeRole(type,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>



<div class="modal fade csModal" id="roleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Group Type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor="let category of typeSubtype1">
                        <div class="typesHead"> {{ category.name }}</div>
                        <div class="typesOption">
                            <ul *ngFor="let type of category.Type">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="typeIdSelected1.includes( type.categoryId)"
                                                checked value="type.categoryId"
                                                (click)="onCheckboxChangeRole1(type,$event)">
                                            <input type="checkbox" *ngIf="!typeIdSelected1.includes( type.categoryId)"
                                                value="type.categoryId" (click)="onCheckboxChangeRole1(type,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{type.name}}
                                        </label>
                                    </div>
                                </li>



                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
        </div>
    </div>
</div>

<div class="modal fade csModal" id="subtypeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="subTypeIdSelected.includes( subT.categoryId)"
                                                checked value="subT.categoryId"
                                                (click)="onCheckboxChangesubType(subT,$event)">
                                            <input type="checkbox" *ngIf="!subTypeIdSelected.includes( subT.categoryId)"
                                                value="subT.categoryId" (click)="onCheckboxChangesubType(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray.length == 0">Please select type first</div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>

<div class="modal fade csModal" id="subtypeModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choose Group Subtypes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="typeListBlk">
                    <div class="typesBlock" *ngFor=" let subType of subTypeArray1">
                        <div class="typesHead">{{subType.name}}</div>
                        <div class="typesOption">
                            <ul *ngFor="let subT of subType.subType">
                                <li>
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" *ngIf="subTypeIdSelected1.includes( subT.categoryId)"
                                                checked value="subT.categoryId"
                                                (click)="onCheckboxChangesubType1(subT,$event)">
                                            <input type="checkbox"
                                                *ngIf="!subTypeIdSelected1.includes( subT.categoryId)"
                                                value="subT.categoryId" (click)="onCheckboxChangesubType1(subT,$event)">

                                            <span class="cr">
                                                <i class="cr-icon fa fa-check"></i>
                                            </span> {{subT.name}}
                                        </label>
                                    </div>
                                </li>

                                <!--                      
onCheckboxChangeRole1
onCheckboxChangesubType1 -->

                            </ul>
                        </div>
                    </div>

                    <div *ngIf="subTypeArray1.length == 0">Please select group type first</div>

                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>