<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="discoverPage sec-pd-30">
        <div class="container">
            <div class="profilePage mainContent">
                <div class="row">
                    <div class="col-md-5 col-lg-4">
                    <!-- containerSelector=".mainContent"  innerWrapperSelector=".sidebar__inner"-->
                        <div stickySidebar >
                            <div class="discoverItem sidebar__inner profileSideInfo">
                                <div class="disArtistImg profileImage">
                                    <a href="javascript:void(0);"><img src="assets/img/2.png"></a>
                                </div>
                                <div class="disArtistInfo profileInfoBlk">
                                    <a href="javascript:void(0);"><h2 class="">John Smith</h2></a>
                                    <p class="loc"><i class="fa fa-map-marker-alt"></i> <span class="">1 East Sherman Lane Chester, PA 19013</span></p>
                                </div>
                                <div class="mb-20"> 
                                <div class="btnActGroup ">
                                    <div class="btnActItem bookMeBtn">
                                        <button routerLink="/ProfileLivlouder" class="btn thm-btn btn-block">Switch To Livlouder</button>
                                        <!-- <button data-toggle="modal" data-target="#swithProfile" class="btn thm-btn btn-block">Switch To Livlouder</button> -->
                                    </div>
                                    <div class="btnActItem ml-auto">
                                        <div class="">
                                            <span class="d-inline-block">
                                            <button routerLink="/createHirerProfilePrivate" class="btn iconBtn" title="Edit Profile" data-toggle="tooltip" type="button">
                                                <span class="fa fa-edit"></span>
                                            </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <!-- <div class="availability">
                                    <h2>Business Type</h2>
                                    <p>Restaurant</p>
                                </div> -->
                                <!-- <div class="socialLinkAdded">
                                    <ul>
                                        <li class="active"><a href=""><span class="fab fa-facebook-square"></span> Facebook</a></li>
                                        <li><a href=""><span class="fab fa-twitter-square"></span> Twitter</a></li>
                                        <li><a href=""><span class="fab fa-instagram"></span> Instagram</a></li>
                                    </ul>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-lg-8">
                        <div class="widgetBox">
                            <div class="userDetails">
                                <div class="userInfoItem">
                                    <h2 class="infoHead">About Me</h2>
                                    <p class="aboutText">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="swithProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Switch Profile To</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="fa fa-times"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="invitedMemberList switchProfileList modalListScroll">
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/8.png">
                        </div>
                        <div class="membName">
                            <h2>DJ The Great and Terrible</h2>
                            <p>Group</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/2.png">
                        </div>
                        <div class="membName">
                            <h2>John Smith</h2>
                            <p>Hirer</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/3.png">
                        </div>
                        <div class="membName">
                            <h2>Isabella</h2>
                            <p>Livlouder</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/9.png">
                        </div>
                        <div class="membName">
                            <h2>DJ The Great and Terrible</h2>
                            <p>Group</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/4.png">
                        </div>
                        <div class="membName">
                            <h2>Jamse</h2>
                            <p>Hirer</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="membItem">
                <label class="selectGroupBook">
                    <input type="radio" name="groupChoose">
                    <div class="membInfoBlk">
                        <div class="membImg">
                            <img src="assets/img/5.png">
                        </div>
                        <div class="membName">
                            <h2>John Williams</h2>
                            <p>Livlouder</p>
                        </div>
                        <div class="membAct radioCheck">
                            <span class="fa fa-check-circle"></span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <a routerLink="/createGig" class="btn thm-btn" data-dismiss="modal">Create New Gig</a> -->
        <button type="button" class="btn thm-btn">Continue</button>
      </div>
    </div>
  </div>
</div>
<app-footer-hirer></app-footer-hirer>