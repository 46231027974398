import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import { ToastrNotificationService } from "../../services/toastr-notification.service";

declare var $: any;
declare var $: any
// declare const toastrSuccess: any;
// declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
    selector: "app-profile-livlouder",
    templateUrl: "./profile-livlouder.component.html",
    styleUrls: ["./profile-livlouder.component.css"]
})
export class ProfileLivlouderComponent implements OnInit {
    title = "Livlouder Profile";
    userMetaId;
    data;
    experience = [];
    type = [];
    subtype = [];
    socialMedia = [];
    user_type;
    switched_user;
    current_user;
    groupMember = [];
    groupMemberCount;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private gigService: GigService,
        private toastService: ToastrNotificationService,
        private titleService: Title,
        private authService: AuthService,

    ) { }

    ngOnInit(): void {
        // alert(localStorage.getItem('metaId'));
        // this.authService.setUserMetaId(localStorage.getItem('metaId'));
        this.titleService.setTitle(this.title);

        this.gigService.discoverDetail(localStorage.getItem('metaId')).subscribe(res => {
            this.data = res.detail;

            if (this.data.experience) {
                this.experience = JSON.parse(this.data.experience);
            }
            this.data.rating_review.data.forEach(element => {
                element.agotime = this.diffHours(
                    element.created_date,
                    element.current_time
                );
            });

            if (this.data.type) {
                this.type = this.data.type.split("|");
            }
            if (this.data.subType) {
                this.subtype = this.data.subType.split("|");
            }
            this.socialMedia = this.data.social_media;
            this.groupMemberCount = this.data.group.length;
            this.groupMember = this.data.group;
        },
            err => {
                this.authService.checkValidToken(err.error);
                if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
                    this.authService.checkValidToken(err.error);
                    this.toastService.showError('Session Expired', 'Error');

                } else {
                    this.toastService.showError(err.error.message, 'Error');
                }

                //this.toastService.showError(err.error.message, "Error");
            });
    }

    ngAfterViewChecked() {
        $("#imageGallery").lightGallery({
            thumbnail: true,
            selector: ".addedImg",
            download: false
        });
        $("#galleryVideos").lightGallery({
            selector: ".addedImg"
        });
    }

    diffHours(t1, t2) {
        var date1 = new Date(t1).getTime() / 1000;

        var date2 = new Date(t2).getTime() / 1000;

        var difference = Math.abs(date2 - date1);
        var diffHr = difference / 60 / 60;
        var daysDiff = difference / 60 / 60 / 24;
        if (isNaN(daysDiff)) {
            return "N/A";
        }
        if (daysDiff < 1) {
            if (diffHr < 1) {
                difference = difference / 60;
                var hourDiff = Math.floor(difference) + " Min Ago";
            } else {
                difference = difference / 60 / 60;
                var hourDiff = Math.floor(difference) + " Hour Ago";
            }
        } else {
            var hourDiff = Math.floor(daysDiff) + " Days Ago";
        }

        return hourDiff;
    }

    // Swith Account 
    switchAccound() {
        this.current_user = localStorage.getItem('SelectedUser');

        if (this.current_user == 1) {
            this.switched_user = 2;
        } else {
            this.switched_user = 1;
        }
        this.gigService.switchAccound(this.switched_user, this.current_user).subscribe(res => {
            localStorage.setItem('currentUser', JSON.stringify(res.userDetail));

            localStorage.setItem('SelectedUser', '2');
            showLoader();
            var formData = new FormData();
            formData.append("metaId", localStorage.getItem('metaId'));
            this.gigService.currentUserLogeedIn(formData).subscribe(
                res1 => {

                    localStorage.setItem('currentUser', JSON.stringify(res1.userDetail));
                    localStorage.setItem('SelectedUser', '2');

                    const userData = JSON.parse(localStorage.getItem('currentUser'));
                    hideLoader();

                    if (userData.userTypeList[1].isProfileComplete == '0') {
                        this.router.navigate(["/userType"]);
                    } else {
                        this.router.navigate(["/myGigsHirer"]);
                    }
                },
                err => {
                    this.authService.checkValidToken(err.error);

                    this.toastService.showError(err.error.message, "Error");


                }
            );
        }, err => {
            this.authService.checkValidToken(err.error);

            this.toastService.showError('Session Expired', "Error");


        }
        );
    }
}
