<app-header-hirer></app-header-hirer>
<div class="mainWrapper light-bg">
    <section class="settingPage sec-pd-30">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <div class="settingsTab">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active show" id="setTab0-tab" data-toggle="tab" href="#setTab0"
                                    role="tab" aria-controls="setTab0" aria-selected="false"><span
                                        class="fa fa-credit-card"></span>Make Payment</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="setTab1-tab" data-toggle="tab" href="#setTab1" role="tab"
                                    aria-controls="setTab1" aria-selected="false"><span
                                        class="fa fa-university"></span>Receive Payment</a>
                            </li>
                            <li class="nav-item">
                                <!-- <a class="nav-link" id="setTab2-tab" data-toggle="tab" href="#setTab2" role="tab"
                                    aria-controls="setTab2" aria-selected="false"><span
                                        class="fa fa-file-pdf"></span>Terms & Conditions</a> -->

                                        <a class="nav-link" id="setTab2-tab" data-toggle="tab" href="#" (click)="termsAndConditions()" role="tab"
                                        aria-controls="setTab2" aria-selected="false"><span
                                            class="fa fa-file-pdf"></span>Terms & Conditions</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="setTab3-tab" data-toggle="tab" href="#" (click)="privacyAndPolicy()" role="tab"
                                    aria-controls="setTab3" aria-selected="false"><span
                                        class="fa fa-file-pdf"></span>Privacy Policy</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="setTab4-tab" data-toggle="tab" href="#" (click)="contactNumber()"
                                    role="tab" aria-controls="setTab4" aria-selected="false"><span
                                        class="fas fa-phone" style="rotate: 90deg;"></span>Contact Number</a>
                            </li>
                            
                            <li class="nav-item">
                                <a class="nav-link" id="setTab4-tab" data-toggle="tab" href="#" (click)="faq()"
                                    role="tab" aria-controls="setTab4" aria-selected="false"><span
                                        class="fa fa-file-pdf"></span>FAQ</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="setTab4-tab" data-toggle="tab" href="#" (click)="help()"
                                    role="tab" aria-controls="setTab4" aria-selected="false"><span
                                        class="fa fa-file-pdf"></span>Help</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="settingTabCnt">
                        <div class="tab-content">
                            <div class="tab-pane active show" id="setTab0" role="tabpanel"
                                aria-labelledby="setTab0-tab">
                                <div class="tabHead">
                                    <h2>Make Payment</h2>
                                </div>
                                <div class="tabsCnt">
                                    <div *ngIf="cardLists.length < 1" class="tabsNoData  mb-20">
                                        <div class="noDataView">
                                            <svg-icon class="svgIcon" src="assets/img/credit-card.svg"></svg-icon>
                                            <h2>Not Added Credit Card Yet!</h2>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                            <button data-toggle="modal" data-target="#addCard" type="button"
                                                class="mt-10 btn thm-btn">Add Credit Card</button>
                                        </div>
                                    </div>
                                    <div class="cardListBlk">
                                        <ng-container *ngFor="let list of cardLists">
                                            <div class="acrdItems">
                                                <label class="crItemInfo ripple">
                                                    <div class="cardImg"><img src="assets/img/credit-card.svg"></div>
                                                    <div class="crInfo">
                                                        <h2>{{list.brand}}</h2>
                                                        <p>xxxx xxxx xxxx {{list.last4}}</p>
                                                    </div>
                                                    <div class="cardAct">
                                                        <span (click)="confimpopup(list.id)" data-toggle="tooltip"
                                                            title="Remove Card" class="cardremove fa fa-trash"></span>
                                                    </div>
                                                </label>
                                            </div>
                                        </ng-container>
                                        <!-- <div class="acrdItems">
                                           
                                            <label class="crItemInfo ripple">
                                                <div class="cardImg"><img src="assets/img/card/discover.png"></div>
                                                <div class="crInfo">
                                                    <h2>Discover Network</h2>
                                                    <p>xxxx xxxx xxxx 1542</p>
                                                </div>
                                                <div class="cardAct">
                                                    <span data-toggle="tooltip" title="Remove Card" class="cardremove fa fa-trash"></span>
                                                </div>
                                            </label>
                                        </div> -->
                                    </div>

                                    <div class="textLink" *ngIf="cardLists.length > 0">
                                        <button data-toggle="modal" data-target="#addCard" type="button"
                                            class="mt-10 btn thm-btn">Add Credit Card</button>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="setTab1" role="tabpanel" aria-labelledby="setTab1-tab">
                                <div class="tabHead">
                                    <h2>Receive Payment</h2>
                                </div>
                                <div class="tabsCnt">
                                    <div class="tabsNoData  mb-20" *ngIf="details == undefined">
                                        <div class="noDataView">
                                            <svg-icon class="svgIcon" src="assets/img/bank.svg"></svg-icon>
                                            <h2>Not Added Bank Details!</h2>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                            <!-- <button data-toggle="modal" data-target="#addBank" type="button" class="mt-10 btn thm-btn">Add Bank Account</button> -->
                                            <button data-toggle="modal" (click)="checkBankAccount()" type="button"
                                                class="mt-10 btn thm-btn">Add Bank Account</button>

                                        </div>
                                    </div>
                                    <div class="cardListBlk">
                                        <!-- <div class="acrdItems">
                                            <label class="crItemInfo ripple">
                                                <div class="cardImg"><img src="assets/img/card/bank.png"></div>
                                                <div class="crInfo">
                                                    <h2>Bank of America</h2>
                                                    <p>xxxx xxxx xxxx 1542</p>
                                                </div>
                                                <div class="cardAct">
                                                    <span data-toggle="tooltip" title="Remove Card" class="cardremove fa fa-trash"></span>
                                                </div>
                                            </label>
                                        </div> -->
                                        <div class="acrdItems" *ngIf="details">
                                            <label class="crItemInfo ripple">
                                                <div class="cardImg"><img src="assets/img/card/bank.png"></div>
                                                <div class="crInfo">
                                                    {{accountHolderName}}
                                                    <!-- <h2>{{details.account_holder_name}}</h2> -->
                                                    <p>xxxx xxxx xxxx {{lastFour}}</p>
                                                </div>
                                                <!-- <div class="cardAct">
                                                    <span data-toggle="tooltip" title="Remove Card" class="cardremove fa fa-trash"></span>
                                                </div> -->
                                            </label>
                                        </div>
                                    </div>
                                    <div class="textLink" *ngIf="details">
                                        <button data-toggle="modal" data-target="#updateBank" type="button"
                                            class="mt-10 btn thm-btn">Update Bank Account</button>
                                        <!-- <button data-toggle="modal" (click)="updateBankAccount()" type="button" class="mt-10 btn thm-btn">Add Bank Account</button> -->

                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="setTab2" role="tabpanel" aria-labelledby="setTab2-tab">
                                <div class="tabHead">
                                    <h2>Terms & Conditions</h2>
                                </div>
                                <div class="tabsCnt">
                                    <div class="trm-sec">
                                        <div class="trm-title">
                                            <ul class="without-dsc">
                                                <li>These <b>Terms and Conditions</b> outline the rules and regulations
                                                    for the use of livloud Website</li>
                                                <li><b>livloud is located at:</b> Dallas TX , United States</li>
                                            </ul>
                                            <p>By accessing this website we assume you accept these <b>Terms and
                                                    Conditions</b> in full. Do not continue to use livloud website if
                                                you do not accept all of the <b>Terms and Conditions</b> stated on this
                                                page.</p>
                                            <p>The following Terminology applies to these <b>Terms and Conditions</b>,
                                                Privacy Statement and Disclaimer Notice and any or all Agreements:
                                                “Client”, “You” and “Your” refers to you, the person accessing this
                                                website and accepting the Company’s <b>Terms and Conditions</b>. “The
                                                Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
                                                “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or
                                                either the Client or ourselves. All terms refer to the offer, aceptance
                                                and consideration of payment necessary to undertake the process of our
                                                assistance to the Client in the most appropriate manner, whether by
                                                formal meetings of a fixed duration, or any other means, for the express
                                                purpose of meeting the Client’s needs in respect of provision of the
                                                Company’s stated services/products, in accordance with and subject to,
                                                prevailing law of <b>United States</b>. Any use of the above Terminology
                                                or other words in the singular, plural,capitalisation and/or he/she or
                                                they, are taken as interchangeable and therefore as referring to
                                                same.Cookies We employ the use of cookies. By using <b>livloud</b>
                                                Website you consent to the use of cookies in accordance with
                                                <b>livloud’s</b> Privacy Policy.Most of the Modern day interactive
                                                Websites use cookies to enable us to retrieve user details for each
                                                visit. Cookies are used in some areas of our site to enable the
                                                functionality of this area and ease of use for those people visiting.
                                                Some of our affiliate / advertising partners may also use
                                                cookies.License Unless otherwise stated, livloud and/or it’s licensors
                                                own the intellectual property rights for all material on <b>livloud</b>.
                                                All intellectual property rights are reserved. You may view and/or print
                                                pages from <a href="javascript:void(0)">http://livloud.tech</a> for your
                                                own personal use subject to restrictions set in these terms and
                                                conditions. <b>You must not:</b>
                                            </p>
                                            <ul>
                                                <li>Republish material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                                <li>Sell, rent or sub-license material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                                <li>Reproduce, duplicate or copy material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                            </ul>
                                            <p>Redistribute content from livloud (unless content is specifically made
                                                for redistribution).Hyperlinking to our Content</p>
                                            <ul class="without-dsc">
                                                <li><b>The following organizations may link to our Web site without
                                                        prior written approval:</b></li>
                                            </ul>
                                            <ul>
                                                <li>Government agencies;</li>
                                                <li>Search engines;</li>
                                                <li>News organizations;</li>
                                                <li>Online directory distributors when they list us in the directory may
                                                    link to our Web site in the same manner as they hyperlink to the Web
                                                    sites of other listed businesses; and Systemwide Accredited
                                                    Businesses except soliciting non-profit organizations, charity
                                                    shopping malls,and charity fundraising groups which may not
                                                    hyperlink to our Web site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>These organizations may link to our home page, to publications or
                                                        to other Web site information so long as the link:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>Is not in any way misleading; </li>
                                                <li>Does not falsely imply sponsorship, endorsement or approval of the
                                                    linking party and its products or services; and</li>
                                                <li>Fits within the context of the linking party's site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>We may consider and approve in our sole discretion other link
                                                        requests from the following types of organizations:</b></li>
                                            </ul>
                                            <ul>
                                                <li>Commonly-known consumer and/or business information sources such as
                                                    <b>Chambers of Commerce</b>, <b>American
                                                        Automobile Association</b>, <b>AARP</b> and <b>Consumers
                                                        Union</b>;
                                                </li>
                                                <li>dot.com community sites;</li>
                                                <li>Associations or other groups representing charities, including
                                                    charity giving sites,
                                                    online directory distributors;</li>
                                                <li>Internet portals;</li>
                                                <li>Accounting, law and consulting firms whose primary clients are
                                                    businesses</li>
                                                <li>Educational institutions and trade associations.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>We will approve link requests from these organizations if we
                                                        determine that:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>The link would not reflect unfavorably on us or our accredited
                                                    businesses <b>(for example, trade associations or other
                                                        organizations representing inherently suspect types of business,
                                                        such as work-at-home opportunities, shall not be allowed to
                                                        link)</b>;</li>
                                                <li>The organization does not have an unsatisfactory record with us;
                                                </li>
                                                <li>The benefit to us from the visibility associated with the hyperlink
                                                    outweighs the absence of ; and</li>
                                                <li>Where the link is in the context of general resource information or
                                                    is otherwise consistent with editorial content in a newsletter or
                                                    similar product furthering the mission of the organization.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>These organizations may link to our home page, to publications or
                                                        to other Web site information so long as
                                                        the link:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>is not in any way misleading;</li>
                                                <li>does not falsely imply sponsorship, endorsement or approval of the
                                                    linking party and it products or services; and</li>
                                                <li>fits within the context of the linking party's site.</li>
                                            </ul>
                                            <div class="alert alert-secondary" role="alert">
                                                If you are among the organizations listed in paragraph 2 above and are
                                                interested in linking to our website,
                                                you must notify us by sending an e-mail to <a
                                                    href="javascript:void(0)">livloud@livloud.tech</a>. Please include
                                                your name, your organization name, contact information <b>(such as a
                                                    phone number and/or e-mail address)</b> as well as the URL of your
                                                site, a list of any URLs from which you intend to link to our Web site,
                                                and a list of the URL(s) on our site to which you would like to link.
                                                Allow 2-3 weeks for a response.
                                            </div>
                                            <ul class="without-dsc">
                                                <li><b>Approved organizations may hyperlink to our Web site as
                                                        follows:</b></li>
                                            </ul>
                                            <ul>
                                                <li>By Use of our corporate name; or</li>
                                                <li>By use of the uniform resource locator (Web address) being linked
                                                    to; or</li>
                                                <li>By use of any other description of our Web site or material being
                                                    linked to that makes sense within the
                                                    context and format of content on the linking party's site.</li>
                                            </ul>
                                            <div class="alert alert-danger">
                                                <strong>No use of livloud’s logo or other artwork will be allowed for
                                                    linking absent a trademark license
                                                    agreement.</strong>
                                            </div>
                                            <ul class="without-dsc">
                                                <li><b>Iframes</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>Without prior approval and express written permission, you may not
                                                    create frames around our Web pages or
                                                    use other techniques that alter in any way the visual presentation
                                                    or appearance of our Web site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Reservation of Rights</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>We reserve the right at any time and in its sole discretion to
                                                    request that you remove all links or any particular link to our Web
                                                    site. You agree to immediately remove all links to our Web site upon
                                                    such request. We also
                                                    reserve the right to amend these terms and conditions and its
                                                    linking policy at any time. By continuing
                                                    to link to our Web site, you agree to be bound to and abide by these
                                                    linking terms and conditions.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Removal of links from Our website</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>If you find any link on our Web site or any linked web site
                                                    objectionable for any reason, you may contact
                                                    us about this. We will consider requests to remove links but will
                                                    have no obligation to do so or to respond directly to you.</li>
                                                <li>Whilst we endeavour to ensure that the information on this website
                                                    is correct, we do not warrant its completeness
                                                    or accuracy; nor do we commit to ensuring that the website remains
                                                    available or that the material on the website is kept up to date.
                                                </li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Content Liability</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>We shall have no responsibility or liability for any content
                                                    appearing on your Web site. You agree to indemnify
                                                    and defend us against all claims arising out of or based upon your
                                                    Website. No link(s) may appear on any page on your Web site or
                                                    within any context containing content or materials that may be
                                                    interpreted as libelous, obscene or criminal, or which infringes,
                                                    otherwise violates, or advocates the infringement or other violation
                                                    of, any third party rights.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Disclaimer</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>To the maximum extent permitted by applicable law, we exclude all
                                                    representations, warranties and conditions relating to our website
                                                    and the use of this website (including, without limitation, any
                                                    warranties implied by law in respect of satisfactory quality,
                                                    fitness for purpose and/or the use of reasonable care and skill).
                                                    <b>Nothing in this disclaimer will:</b>
                                                </li>
                                            </ul>
                                            <div class="margin-left">
                                                <ul>
                                                    <li>limit or exclude our or your liability for death or personal
                                                        injury resulting from negligence;</li>
                                                    <li>limit or exclude our or your liability for fraud or fraudulent
                                                        misrepresentation;</li>
                                                    <li>limit any of our or your liabilities in any way that is not
                                                        permitted under applicable law; or</li>
                                                    <li>Exclude any of our or your liabilities that may not be excluded
                                                        under applicable law.</li>
                                                </ul>
                                                <ul class="without-dsc">
                                                    <li><b>The limitations and exclusions of liability set out in this
                                                            Section and elsewhere in this disclaimer: </b></li>
                                                </ul>
                                                <ul class="lst-alphbt">
                                                    <li>Are subject to the preceding paragraph; and </li>
                                                    <li>Govern all liabilities arising under the disclaimer or in
                                                        relation to the subject matter of this disclaimer, including
                                                        liabilities arising in contract, in tort(including negligence)
                                                        and for breach of statutory duty</li>
                                                </ul>
                                                <div class="alert alert-danger">
                                                    <strong>To the extent that the website and the information and
                                                        services on the website are provided free of charge,we will not
                                                        be liable for any loss or damage of any nature.</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="setTab3" role="tabpanel" aria-labelledby="setTab3-tab">
                                <div class="tabHead">
                                    <h2>Privacy Policy</h2>
                                </div>
                                <div class="tabsCnt">
                                    <div class="trm-sec">
                                        <div class="trm-title">
                                            <ul class="without-dsc">
                                                <li>These <b>Terms and Conditions</b> outline the rules and regulations
                                                    for the use of livloud Website</li>
                                                <li><b>livloud is located at:</b> Dallas TX , United States</li>
                                            </ul>
                                            <p>By accessing this website we assume you accept these <b>Terms and
                                                    Conditions</b> in full. Do not continue to use livloud website if
                                                you do not accept all of the <b>Terms and Conditions</b> stated on this
                                                page.</p>
                                            <p>The following Terminology applies to these <b>Terms and Conditions</b>,
                                                Privacy Statement and Disclaimer Notice and any or all Agreements:
                                                “Client”, “You” and “Your” refers to you, the person accessing this
                                                website and accepting the Company’s <b>Terms and Conditions</b>. “The
                                                Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company.
                                                “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or
                                                either the Client or ourselves. All terms refer to the offer, aceptance
                                                and consideration of payment necessary to undertake the process of our
                                                assistance to the Client in the most appropriate manner, whether by
                                                formal meetings of a fixed duration, or any other means, for the express
                                                purpose of meeting the Client’s needs in respect of provision of the
                                                Company’s stated services/products, in accordance with and subject to,
                                                prevailing law of <b>United States</b>. Any use of the above Terminology
                                                or other words in the singular, plural,capitalisation and/or he/she or
                                                they, are taken as interchangeable and therefore as referring to
                                                same.Cookies We employ the use of cookies. By using <b>livloud</b>
                                                Website you consent to the use of cookies in accordance with
                                                <b>livloud’s</b> Privacy Policy.Most of the Modern day interactive
                                                Websites use cookies to enable us to retrieve user details for each
                                                visit. Cookies are used in some areas of our site to enable the
                                                functionality of this area and ease of use for those people visiting.
                                                Some of our affiliate / advertising partners may also use
                                                cookies.License Unless otherwise stated, livloud and/or it’s licensors
                                                own the intellectual property rights for all material on <b>livloud</b>.
                                                All intellectual property rights are reserved. You may view and/or print
                                                pages from <a href="javascript:void(0)">http://livloud.tech</a> for your
                                                own personal use subject to restrictions set in these terms and
                                                conditions. <b>You must not:</b>
                                            </p>
                                            <ul>
                                                <li>Republish material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                                <li>Sell, rent or sub-license material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                                <li>Reproduce, duplicate or copy material from <a
                                                        href="javascript:void(0)">http://livloud.tech</a></li>
                                            </ul>
                                            <p>Redistribute content from livloud (unless content is specifically made
                                                for redistribution).Hyperlinking to our Content</p>
                                            <ul class="without-dsc">
                                                <li><b>The following organizations may link to our Web site without
                                                        prior written approval:</b></li>
                                            </ul>
                                            <ul>
                                                <li>Government agencies;</li>
                                                <li>Search engines;</li>
                                                <li>News organizations;</li>
                                                <li>Online directory distributors when they list us in the directory may
                                                    link to our Web site in the same manner as they hyperlink to the Web
                                                    sites of other listed businesses; and Systemwide Accredited
                                                    Businesses except soliciting non-profit organizations, charity
                                                    shopping malls,and charity fundraising groups which may not
                                                    hyperlink to our Web site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>These organizations may link to our home page, to publications or
                                                        to other Web site information so long as the link:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>Is not in any way misleading; </li>
                                                <li>Does not falsely imply sponsorship, endorsement or approval of the
                                                    linking party and its products or services; and</li>
                                                <li>Fits within the context of the linking party's site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>We may consider and approve in our sole discretion other link
                                                        requests from the following types of organizations:</b></li>
                                            </ul>
                                            <ul>
                                                <li>Commonly-known consumer and/or business information sources such as
                                                    <b>Chambers of Commerce</b>, <b>American
                                                        Automobile Association</b>, <b>AARP</b> and <b>Consumers
                                                        Union</b>;
                                                </li>
                                                <li>dot.com community sites;</li>
                                                <li>Associations or other groups representing charities, including
                                                    charity giving sites,
                                                    online directory distributors;</li>
                                                <li>Internet portals;</li>
                                                <li>Accounting, law and consulting firms whose primary clients are
                                                    businesses</li>
                                                <li>Educational institutions and trade associations.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>We will approve link requests from these organizations if we
                                                        determine that:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>The link would not reflect unfavorably on us or our accredited
                                                    businesses <b>(for example, trade associations or other
                                                        organizations representing inherently suspect types of business,
                                                        such as work-at-home opportunities, shall not be allowed to
                                                        link)</b>;</li>
                                                <li>The organization does not have an unsatisfactory record with us;
                                                </li>
                                                <li>The benefit to us from the visibility associated with the hyperlink
                                                    outweighs the absence of ; and</li>
                                                <li>Where the link is in the context of general resource information or
                                                    is otherwise consistent with editorial content in a newsletter or
                                                    similar product furthering the mission of the organization.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>These organizations may link to our home page, to publications or
                                                        to other Web site information so long as
                                                        the link:</b></li>
                                            </ul>
                                            <ul class="lst-alphbt">
                                                <li>is not in any way misleading;</li>
                                                <li>does not falsely imply sponsorship, endorsement or approval of the
                                                    linking party and it products or services; and</li>
                                                <li>fits within the context of the linking party's site.</li>
                                            </ul>
                                            <div class="alert alert-secondary" role="alert">
                                                If you are among the organizations listed in paragraph 2 above and are
                                                interested in linking to our website,
                                                you must notify us by sending an e-mail to <a
                                                    href="javascript:void(0)">livloud@livloud.tech</a>. Please include
                                                your name, your organization name, contact information <b>(such as a
                                                    phone number and/or e-mail address)</b> as well as the URL of your
                                                site, a list of any URLs from which you intend to link to our Web site,
                                                and a list of the URL(s) on our site to which you would like to link.
                                                Allow 2-3 weeks for a response.
                                            </div>
                                            <ul class="without-dsc">
                                                <li><b>Approved organizations may hyperlink to our Web site as
                                                        follows:</b></li>
                                            </ul>
                                            <ul>
                                                <li>By Use of our corporate name; or</li>
                                                <li>By use of the uniform resource locator (Web address) being linked
                                                    to; or</li>
                                                <li>By use of any other description of our Web site or material being
                                                    linked to that makes sense within the
                                                    context and format of content on the linking party's site.</li>
                                            </ul>
                                            <div class="alert alert-danger">
                                                <strong>No use of livloud’s logo or other artwork will be allowed for
                                                    linking absent a trademark license
                                                    agreement.</strong>
                                            </div>
                                            <ul class="without-dsc">
                                                <li><b>Iframes</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>Without prior approval and express written permission, you may not
                                                    create frames around our Web pages or
                                                    use other techniques that alter in any way the visual presentation
                                                    or appearance of our Web site.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Reservation of Rights</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>We reserve the right at any time and in its sole discretion to
                                                    request that you remove all links or any particular link to our Web
                                                    site. You agree to immediately remove all links to our Web site upon
                                                    such request. We also
                                                    reserve the right to amend these terms and conditions and its
                                                    linking policy at any time. By continuing
                                                    to link to our Web site, you agree to be bound to and abide by these
                                                    linking terms and conditions.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Removal of links from Our website</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>If you find any link on our Web site or any linked web site
                                                    objectionable for any reason, you may contact
                                                    us about this. We will consider requests to remove links but will
                                                    have no obligation to do so or to respond directly to you.</li>
                                                <li>Whilst we endeavour to ensure that the information on this website
                                                    is correct, we do not warrant its completeness
                                                    or accuracy; nor do we commit to ensuring that the website remains
                                                    available or that the material on the website is kept up to date.
                                                </li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Content Liability</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>We shall have no responsibility or liability for any content
                                                    appearing on your Web site. You agree to indemnify
                                                    and defend us against all claims arising out of or based upon your
                                                    Website. No link(s) may appear on any page on your Web site or
                                                    within any context containing content or materials that may be
                                                    interpreted as libelous, obscene or criminal, or which infringes,
                                                    otherwise violates, or advocates the infringement or other violation
                                                    of, any third party rights.</li>
                                            </ul>
                                            <ul class="without-dsc">
                                                <li><b>Disclaimer</b></li>
                                            </ul>
                                            <ul class="lst-square">
                                                <li>To the maximum extent permitted by applicable law, we exclude all
                                                    representations, warranties and conditions relating to our website
                                                    and the use of this website (including, without limitation, any
                                                    warranties implied by law in respect of satisfactory quality,
                                                    fitness for purpose and/or the use of reasonable care and skill).
                                                    <b>Nothing in this disclaimer will:</b>
                                                </li>
                                            </ul>
                                            <div class="margin-left">
                                                <ul>
                                                    <li>limit or exclude our or your liability for death or personal
                                                        injury resulting from negligence;</li>
                                                    <li>limit or exclude our or your liability for fraud or fraudulent
                                                        misrepresentation;</li>
                                                    <li>limit any of our or your liabilities in any way that is not
                                                        permitted under applicable law; or</li>
                                                    <li>Exclude any of our or your liabilities that may not be excluded
                                                        under applicable law.</li>
                                                </ul>
                                                <ul class="without-dsc">
                                                    <li><b>The limitations and exclusions of liability set out in this
                                                            Section and elsewhere in this disclaimer: </b></li>
                                                </ul>
                                                <ul class="lst-alphbt">
                                                    <li>Are subject to the preceding paragraph; and </li>
                                                    <li>Govern all liabilities arising under the disclaimer or in
                                                        relation to the subject matter of this disclaimer, including
                                                        liabilities arising in contract, in tort(including negligence)
                                                        and for breach of statutory duty</li>
                                                </ul>
                                                <div class="alert alert-danger">
                                                    <strong>To the extent that the website and the information and
                                                        services on the website are provided free of charge,we will not
                                                        be liable for any loss or damage of any nature.</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal fade csModal" id="addCard" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add New Card</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="formModal">
                    <div class="formModalBlk">
                        <div class="cardAddBlk">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="inLabel">Enter Card Details <span class="req">*</span></label>
                                        <div class="cardFieldView">
                                            <ngx-stripe-card (change)="onChange($event)"
                                                [elementsOptions]="elementsOptions"
                                                [options]="cardOptions"></ngx-stripe-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cardSequrity">
                                <p>Safe payment online using your credit card. We are secure, we will not share your
                                    details.</p>
                                <div class="cardType">
                                    <img src="assets/img/card/american-express.png">
                                    <img src="assets/img/card/discover.png">
                                    <img src="assets/img/card/mastercard.png">
                                    <img src="assets/img/card/visa.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn thm-btnGray" data-dismiss="modal">Close</button>
                <button (click)="buy()" type="button" class="btn thm-btn">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade csModal" id="addBank" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="bankAccountForm" (ngSubmit)="addBankAccount();">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Bank Acount</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="formModal">
                        <div class="formModalBlk">
                            <div class="bankFormBlk">
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountName" class="form-control"
                                        placeholder="Enter Name">
                                    <label for="floatingInput">Name <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountName').touched && bankAccountForm.get('accountName').invalid">
                                        Account name is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="bsdCode" class="form-control"
                                        placeholder="Enter BSB Code">
                                    <label for="floatingInput">BSB Code <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('bsdCode').touched && bankAccountForm.get('bsdCode').invalid">
                                        BSD code is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountNo" class="form-control"
                                        placeholder="Enter Account Number">
                                    <label for="floatingInput">Account Number <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountForm.get('accountNo').touched && bankAccountForm.get('accountNo').invalid">
                                        Account no is required field.
                                    </small>
                                </div>
                                <!-- <div class="form-floating mb-3">
                                    <input type="text" formControlName="address" class="form-control" placeholder="Enter Address">
                                    <label for="floatingInput">Registered Address <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="bankAccountForm.get('address').touched && bankAccountForm.get('address').invalid">
                                        Address is required field.
                                    </small>
                                </div> -->
                                <!-- <div class="form-floating mb-3">
                                    <select type="text" class="form-control" id="floatingInput" >
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>Canada</option>
                                        <option>Japan</option>
                                    </select>
                                    <label for="floatingInput">Select Country <span class="reqField">*</span></label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" *ngIf="details == undefined">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>


<!-- delete card model -->


<!-- delete card  -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you want to delete this card.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" (click)="removeCard()" class="btn btn-danger">Delete</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade csModal" id="updateBank" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form [formGroup]="bankAccountFormUpdate" (ngSubmit)="updateBankAccount();">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Bank Acount</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="formModal">
                        <div class="formModalBlk">
                            <div class="bankFormBlk">
                                <div class="form-floating mb-3">

                                    <!-- <input type="hidden" formControlName="accountId" class="form-control" placeholder="Enter Name"> -->
                                    <input type="hidden" name="accountId" ng-init="model.value=1" value="1">


                                    <input type="text" formControlName="accountName" class="form-control"
                                        placeholder="Enter Name" id="accountHolderName">
                                    <label for="floatingInput">Name <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountFormUpdate.get('accountName').touched && bankAccountFormUpdate.get('accountName').invalid">
                                        Account name is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="bsdCode" class="form-control"
                                        placeholder="Enter BSB Code">
                                    <label for="floatingInput">BSB Code <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountFormUpdate.get('bsdCode').touched && bankAccountFormUpdate.get('bsdCode').invalid">
                                        BSB code is required field.
                                    </small>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" formControlName="accountNo" class="form-control"
                                        placeholder="Enter Account Number">
                                    <label for="floatingInput">Account Number <span class="reqField">*</span></label>
                                    <small class="text-danger"
                                        *ngIf="bankAccountFormUpdate.get('accountNo').touched && bankAccountFormUpdate.get('accountNo').invalid">
                                        Account no. is required field.
                                    </small>
                                </div>
                                <!-- <div class="form-floating mb-3">
                                    <input type="text" formControlName="address" class="form-control" placeholder="Enter Address">
                                    <label for="floatingInput">Registered Address <span class="reqField">*</span></label>
                                    <small class="text-danger" *ngIf="bankAccountForm.get('address').touched && bankAccountForm.get('address').invalid">
                                        Address is required field.
                                    </small>
                                </div> -->
                                <!-- <div class="form-floating mb-3">
                                    <select type="text" class="form-control" id="floatingInput" >
                                        <option>India</option>
                                        <option>USA</option>
                                        <option>Canada</option>
                                        <option>Japan</option>
                                    </select>
                                    <label for="floatingInput">Select Country <span class="reqField">*</span></label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" *ngIf="details">
                    <button type="button" class="btn thm-btnGray" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn thm-btn">update</button>
                </div>
            </div>
        </form>
    </div>
</div>
<app-footer-hirer></app-footer-hirer>