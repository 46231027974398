<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="boxView">
                        <div class="loginProfile">
                            <div class="setupPrHead">
                                <h2>Select Your Profile Role</h2>
                                <p>Become a Hirer or Livlouder, Post Gigs and accept offer, Track your Livlouder, Rate and release</p>
                            </div>
                            <div class="invitedMemberList switchProfileList mt-30">
                                <!-- <div class="membItem">
                                    <label class="selectGroupBook">
                                        <input type="radio" name="groupChoose">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/8.png">
                                            </div>
                                            <div class="membName">
                                                <h2>DJ The Great and Terrible</h2>
                                                <p>Group</p>
                                            </div>
                                            <div class="membAct radioCheck">
                                                <span class="fa fa-check-circle"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div> -->
                              <ng-container *ngFor = "let list  of list">
<!--   								<h3>{{list.isProfileComplete}}</h3>
 -->
                                <div class="membItem">
                                    <label class="selectGroupBook">
                                    	    <input type="hidden" name="userMetaId" value="{{list.userMetaId}}" ng-model="userMetaId" />

                                    	    <input type="hidden" class="cp" name="isProfileComplete" value="{{list.isProfileComplete}}" ng-model="isProfileComplete" />
                                            <!-- <h2>{{list.userMetaId}}</h2> -->

                                        <!-- <input name="groupChoose" [(ngModel)]="groupChoose" type="radio" [value]="3"/> -->
                                        <input type="radio" id="as_{{list.userMetaId}}" class="as" name="groupChoose" value="{{list.userMetaId}}" (change)="userMetaIdGet($event)">
                                        <div class="membInfoBlk">
                                            <div class="membImg" *ngIf="list.isProfileComplete =='1'">
                                                <img src="{{list.profileImage}}">
                                            </div>
                                            <div class="membImg" *ngIf="list.isProfileComplete =='0'">
                                                <img src="https://giglooker.com/dev/livloud_admin/placeholder/defaultUser.png">
                                            </div>
                                            <!-- {{list.isProfileComplete}}
                                            {{list.userMetaId}} -->


                                        <div *ngIf="list.isProfileComplete =='1'">
                                            <div class="membName">
                                                <h2>{{list.fullName}}</h2>

                                                <p>{{list.type}}</p>
                                            </div>
                                            </div>
                                             <div id="inComplete" *ngIf="list.isProfileComplete =='0'">
                                                Incomplete Profile

                                            </div>
                                            <!-- <div class="membName">

                                                <h2>{{list.isProfileComplete}}</h2>
                                                <h2>{{list.userMetaId}}</h2>
                                                <h2>{{list.fullName}}</h2>
                                                <p>{{list.type}}</p>
                                            </div> -->
                                            <div class="membAct radioCheck">
                                                <span class="fa fa-check-circle"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                </ng-container>
                                <!-- <div class="membItem">
                                    <label class="selectGroupBook">
                                        <input type="radio" name="groupChoose">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/9.png">
                                            </div>
                                            <div class="membName">
                                                <h2>DJ The Great and Terrible</h2>
                                                <p>Group</p>
                                            </div>
                                            <div class="membAct radioCheck">
                                                <span class="fa fa-check-circle"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="membItem">
                                    <label class="selectGroupBook">
                                        <input type="radio" name="groupChoose">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/4.png">
                                            </div>
                                            <div class="membName">
                                                <h2>Jamse</h2>
                                                <p>Hirer</p>
                                            </div>
                                            <div class="membAct radioCheck">
                                                <span class="fa fa-check-circle"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="membItem">
                                    <label class="selectGroupBook">
                                        <input type="radio" name="groupChoose">
                                        <div class="membInfoBlk">
                                            <div class="membImg">
                                                <img src="assets/img/5.png">
                                            </div>
                                            <div class="membName">
                                                <h2>John Williams</h2>
                                                <p>Livlouder</p>
                                            </div>
                                            <div class="membAct radioCheck">
                                                <span class="fa fa-check-circle"></span>
                                            </div>
                                        </div>
                                    </label>
                                </div> -->
                            </div>
                            <div>
                            </div>
                            <div class="text-center mb-3">
                            <button (click) = "createNewProfile(1)" class="textBtn">CREATE NEW PROFILE</button>
                            </div>
                            <!-- <a _ngcontent-cpr-c104="" href="createNewProfile()" class="color-base">CREATE NEW PROFILE</a> -->
                            <div class="text-center">
                                <button (click)="test()" type="button" class="btn thm-btn">Continue</button>
                                <!-- <button routerLink="/ProfileLivlouder" *ngIf="groupChoose == 1" type="button" class="btn thm-btn">Continue</button>
                                <button routerLink="/profileHirer" *ngIf="groupChoose == 2" type="button" class="btn thm-btn">Continue</button>
                                <button routerLink="/ProfileLivlouder" *ngIf="groupChoose == 3" type="button" class="btn thm-btn">Continue</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder></app-footer-livlouder>