import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import {AuthService} from '../../services/auth.service';
import { GigService } from "src/app/services/gig-service";
import { ToastrNotificationService } from "../../services/toastr-notification.service";
import { BookMeRequest } from 'src/app/services/book-me-request';
import { DataService } from '../../services/data.service';


declare const showLoader: any;
 declare const hideLoader: any;


@Component({
  selector: 'app-header-livlouder',
  templateUrl: './header-livlouder.component.html',
  styleUrls: ['./header-livlouder.component.css']
})


export class HeaderLivlouderComponent implements OnInit {
  data;
  switched_user;
  current_user;
  profileImage:any;
  isLogin;
  DefaultprofileImage : any;
  total;
  metaId;

  constructor(@Inject(DOCUMENT) document, 

  private bookMeRequest: BookMeRequest,

  public router : Router,
  private authService:AuthService,
  private gigService: GigService,
  private toastService: ToastrNotificationService,
  private dataService : DataService

  
  ) { }

  ngOnInit() {
    this.dataService.sharedData$.subscribe(data => {
			this.profileImage = data;
		});
    if(this.authService.isLoggedIn()==true){
      this.isLogin = true;

      this.metaId = localStorage.getItem('metaId');
      var obj: any = {}
      obj.metaId = this.metaId;

      this.bookMeRequest.getBookMeRequest(obj).subscribe(data => {
        this.total = data.data.length;
      });

    }else{
      this.isLogin = false;

    }
    
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log('currentUser',currentUser.userId)
    if(currentUser){
      this.profileImage = currentUser.userTypeList[0].profileImage;
    }

    var obj: any = {}
    obj.metaId = localStorage.getItem('metaId');;


    // const offset = 0;
    // this.authService.getAllNotifications(offset,currentUser.userId).subscribe(data => {
    //   console.log('data-res',data);
    //     // this.total = data.data.length;
    //   });


  }
  
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 50) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
     } else {
      let element = document.getElementById('navbar');
        element.classList.remove('sticky'); 
     }
  }

  logout(){
    this.authService.logout();
    
  }


  switchAccound() {
    
    this.current_user = localStorage.getItem('SelectedUser');

        if(this.current_user  == 1){
            this.switched_user = 2;
        }else{
        this.switched_user = 1;
        }
        this.gigService.switchAccound(this.switched_user, this.current_user).subscribe(res => {
            // console.log("Data is received - Result - ", res);
            this.data = res.res;
            showLoader();
            var formData = new FormData();
            formData.append("metaId",'2');
            this.gigService.currentUserLogeedIn(formData).subscribe(
                res1 => {
                    localStorage.setItem('currentUser', JSON.stringify(res1.userDetail));
                    localStorage.setItem('SelectedUser','2');

                    const userData = JSON.parse(localStorage.getItem('currentUser'));
                    hideLoader();

                    if(userData.userTypeList[1].isProfileComplete == '0'){
                        this.router.navigate(["/userType"]);
                    }else{
                        this.router.navigate(["/myGigsHirer"]);
                    }
                },
                err => {
                  hideLoader();
                  if(err.error.status=='fail' && err.error.message=='Invalid token'){
                    this.authService.checkValidToken(err.error);
                    this.toastService.showError('Session Expired', 'Error');
          
                  }else{
                  this.toastService.showError(err.error.message, 'Error');
                  }
                }
            );
        },err =>{
          hideLoader();

          if(err.error.status=='fail' && err.error.message=='Invalid token'){
            this.authService.checkValidToken(err.error);
            this.toastService.showError('Session Expired', 'Error');
  
          }else{
          this.toastService.showError(err.error.message, 'Error');
          }
        });
    }

  reloadPage(){
    if(this.router.url == '/gigs'){
      location.reload();
    }
   
    
  }


  openacessGig(){
  //  this.router.navigate(['/OpenAcessGig']); // navigate to other page
  if(this. router.url == '/OpenAcessGig'){
    this.router.navigate(['/OpenAcessGig']); // navigate to other page
    }else{
      this.router.navigate(['/gigs']); // navigate to other page

    }


  }

  openacessDiscover(){
    // this.router.navigate(['/OpenAcessDiscover']); // navigate to other page

    if(this. router.url == '/OpenAcessGig'){
      this.router.navigate(['/OpenAcessDiscover']); // navigate to other page
      }else{
        this.router.navigate(['/discover']); // navigate to other page
  
      }
  }

  //  Fqq 
  faq(){
	  this.router.navigate(['/faq']); // navigate to other page

	}

}
