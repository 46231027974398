import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
 
@Component({
  selector: 'app-stripe-success',
  templateUrl: './stripe-success.component.html',
  styleUrls: ['./stripe-success.component.css']
})
export class StripeSuccessComponent implements OnInit {

  selectedUser:any; 
  constructor() { }

  ngOnInit(): void {

    this.selectedUser =  localStorage.getItem("SelectedUser");
  }

  serverUrl = environment.siteUrl;

}
