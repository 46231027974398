<app-header-livlouder></app-header-livlouder>
<div class="mainWrapper light-bg">
    <section class="setupProfilePage sec-pd-30">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="boxView">
                        <div class="boxViewForm">
                            <div class="setupPrHead">
                                <h2>Select User Type</h2>
                                <p></p>
                            </div>
                            <div class="form-group" id="userType1">

                                <div class="userType mb-30 text-center" *ngIf="profileCompleteHirer == profileCompleteLivlouder">
                                    <div class="Ustype">
                                        <span class="radio-inline">
                                            <input [value]="1" [(ngModel)]="optradio" (click)="checkLivlouder()" type="radio" name="optradio">
                                            <label><span><i class="fas fa-user-secret"></i></span>
                                                <p>I want to be a livlouder </p>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="Ustype" >
                                        <span class="radio-inline">
                                            <input [value]="2" [(ngModel)]="optradio" (click)="checkHirer();" type="radio" name="optradio">
                                            <label><span><i class="fas fa-user-tie"></i></span>
                                                <p>I want to be a hirer</p>
                                            </label>
                                        </span>
                                    </div>
                                </div>


                                <div class="userType mb-30 text-center" *ngIf="profileCompleteHirer != profileCompleteLivlouder">
                                    <div class="Ustype" *ngIf="profileCompleteHirer != 1 && selectedUser == 1">
                                        <span class="radio-inline">
                                            <input [value]="1" [(ngModel)]="optradio" (click)="checkLivlouder()" type="radio" name="optradio">
                                            <label><span><i class="fas fa-user-secret"></i></span>
                                                <p>I want to be a livlouder </p>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="Ustype" *ngIf="profileCompleteHirer != 1 && selectedUser == 2" >
                                        <span class="radio-inline">
                                            <input [value]="2" [(ngModel)]="optradio" (click)="checkHirer();" type="radio" name="optradio">
                                            <label><span><i class="fas fa-user-tie"></i></span>
                                                <p>I want to be a hirer</p>
                                            </label>
                                        </span>
                                    </div>
                                </div>



                            </div>
                            <div class="form-group" *ngIf="optradio == '2'">
                                <div class="userType mb-30 text-center">
                                    <div class="Ustype">
                                        <span class="radio-inline">
                                            <input [value]="1" [(ngModel)]="optradio1" type="radio" name="optradio1">
                                            <label><span><i class="fas fa-user-lock"></i></span>
                                                <p>Private Hirer</p>
                                            </label>
                                        </span>
                                    </div>
                                    <div class="Ustype">
                                        <span class="radio-inline">
                                            <input [value]="2" [(ngModel)]="optradio1" type="radio" name="optradio1" >
                                            <label><span><i class="fas fa-building"></i></span>
                                                <p>Venue</p>
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="appAction text-center mt-30">
                                <!-- <a href="trip.html" class="btn thm-btnGray">Cancel</a>
                                &nbsp; -->
                                
                                <a *ngIf="optradio == '1'" routerLink="/createLivlouderProfile" class="btn thm-btn">Continue</a>
                                <!-- <a *ngIf="optradio == '1'" routerLink="/createLivlouderProfile" class="btn thm-btn">Continue</a> -->

                                <a *ngIf="optradio1 == '1' && optradio != '1'" routerLink="/createHirerProfilePrivate" class="btn thm-btn">Continue</a>
                                <a *ngIf="optradio1 == '2' && optradio != '1'" routerLink="/createHirerProfile" class="btn thm-btn">Continue</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer-livlouder></app-footer-livlouder>