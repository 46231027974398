import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HirerProfileService } from 'src/app/services/hirer-profile.service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
import { NotificationService } from 'src/app/services/notification.service';
declare const showLoader: any;
declare const hideLoader: any;


@Component({
  selector: 'app-header-hirer',
  templateUrl: './header-hirer.component.html',
  styleUrls: ['./header-hirer.component.css']
})
export class HeaderHirerComponent implements OnInit {


  current_user;
  switched_user;
  data;
  current_user_data;
  currentuserId;
  isLogin;
  profileImage: any;
  constructor(@Inject(DOCUMENT) document,
    public router: Router,
    public authService: AuthService,
    private hirerDetail: HirerProfileService,
    private toastrService: ToastrNotificationService,
    private NotificationService: NotificationService,


  ) { }

  ngOnInit(): void {

    if (this.authService.isLoggedIn() == true) {
      this.isLogin = true;

    } else {
      this.isLogin = false;

    }

    this.current_user_data = JSON.parse(localStorage.getItem('currentUser'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.profileImage = currentUser.userTypeList[1].profileImage;

    this.current_user = localStorage.getItem('SelectedUser');
    this.currentuserId = currentUser.userId;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }

  logout() {
    this.authService.logout();

  }

  //  swicth account hirer to liveloud
  switchAccound() {

    this.current_user = localStorage.getItem('SelectedUser');
    this.current_user_data = JSON.parse(localStorage.getItem('currentUser'));

    if (this.current_user_data.userTypeList[0].isProfileComplete == 0) {
      // this.router.navigate(["/createLivlouderProfile"]);
      this.router.navigate(["/loginProfile"]);
      return false;
    }

    if (this.current_user == 2) {
      this.switched_user = 1;
      var metaid = this.current_user_data.userTypeList[0].userMetaId
    } else {
      this.switched_user = 2;
      var metaid = this.current_user_data.userTypeList[1].userMetaId
    }
    this.hirerDetail.switchAccound(this.switched_user, this.current_user).subscribe(res => {
      this.data = res.res;
      showLoader();
      var formData = new FormData();
      formData.append("metaId", metaid);
      this.hirerDetail.currentUserLogeedIn(formData).subscribe(
        res1 => {
          localStorage.setItem('currentUser', JSON.stringify(res.userDetail));
          localStorage.setItem('SelectedUser', '1');

          // localStorage.setItem('user_deta', res1);


          hideLoader();
          // alert('hellooooo')
          //this.toastrService.showSuccess(res.message,'Success');
          this.router.navigate(["/loginProfile"]);
        },
        err => {
          this.authService.checkValidToken(err.error);
          this.toastrService.showError(err.error.message, "Error");
        }
      );
    }, (err) => {
      this.authService.checkValidToken(err.error);
      this.toastrService.showError(err.error.message, "Error");
    });
  }


  switchAccound_hirer() {

    this.current_user = localStorage.getItem('SelectedUser');

    this.current_user_data = JSON.parse(localStorage.getItem('currentUser'));

    if (this.current_user_data.userTypeList[0].isProfileComplete == 0) {
      this.router.navigate(["/userType"]);
      return false;
    }


    if (this.current_user == 1) {
      this.switched_user = 2;
      var metaid = this.current_user_data.userTypeList[1].userMetaId

    } else {
      this.switched_user = 1;
      var metaid = this.current_user_data.userTypeList[0].userMetaId


    }
    this.hirerDetail.switchAccound(this.switched_user, this.current_user).subscribe(res => {

      this.data = res.res;
      showLoader();
      var formData = new FormData();
      formData.append("metaId", metaid);
      this.hirerDetail.currentUserLogeedIn(formData).subscribe(
        res1 => {

          localStorage.setItem('currentUser', JSON.stringify(res.userDetail));
          localStorage.setItem('SelectedUser', '2');

          hideLoader();
          this.router.navigate(["/myGigsHirer"]);
        },
        err => {
          if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
            this.authService.checkValidToken(err.error);
            this.toastrService.showError('Session Expired', 'Error');

          } else {
            this.toastrService.showError(err.error.message, 'Error');
          }
        }
      );
    }, (err) => {
      if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
        this.authService.checkValidToken(err.error);
        this.toastrService.showError('Session Expired', 'Error');

      } else {
        this.toastrService.showError(err.error.message, 'Error');
      }
    });
  }



}
