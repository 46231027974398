import { Component, OnInit } from '@angular/core';

import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';
import { GigService } from '../../services/gig-service';
import { ToastrNotificationService } from 'src/app/services/toastr-notification.service';
//import {} from '../../services/toastr-notification.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { SignupService } from 'src/app/services/signup.service';

declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: 'app-my-gigs-hirer',
	templateUrl: './my-gigs-hirer.component.html',
	styleUrls: ['./my-gigs-hirer.component.css']
})
export class MyGigsHirerComponent implements OnInit {

	hidghValue: any = 0;
	lowValue: any = 0;
	setPriceValue: boolean = false;
	sliderForm: FormGroup = new FormGroup({
		sliderControl: new FormControl([0, 300]),
		sliderControlPrice: new FormControl([this.lowValue, this.hidghValue])
	});


	// minValue: number = 50;
	// maxValue: number = 200;
	options: Options = {
		floor: 0,
		ceil: 300,
		step: 5
	};

	sliderValue;
	sliderEvents: boolean = false;

	priceSlider: Options = {
		floor: this.lowValue,
		ceil: this.hidghValue,
		step: 5
	};






	locationoptions = {
		// componentRestrictions: {
		// 	country: ['UK']
		// }

		componentRestrictions: { country: 'UK' }
	};
	model;
	endModel;
	gigLists: any;
	gigImage: any;
	title = 'Hirer - Gig Lists';
	timeout: any;
	lat = "";
	lng = "";
	location;
	suburb;
	limit: any = 20;
	offset: any = 0;
	priceFilter;
	total: any;
	dataFound = false;

	typeSubtype: any;
	typeListData = [];
	subTypeArray = [];
	subTypeSelected = [];
	typeIdSelected = [];
	subTypeIdSelected = [];

	//added code...
	typeSubtype1: any;
	typeIdSelected1 = [];

	typeListData1 = [];
	subTypeSelected1 = [];

	subTypeArray1 = [];
	subTypeIdSelected1 = [];

	assigned;
	completed;
	minvalue;
	maxvalue;
	ispricesame;
	minDate: any;
	constructor(
		private gigService: GigService,
		private toastrService: ToastrNotificationService,
		private titleService: Title,
		private authService: AuthService,
		private signupService: SignupService
	) { }

	ngOnInit(): void {
		// alert(this.priceSlider.ceil)

		$("#allF").addClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');
		// this.authService.setUserMetaId(2);
		let searchText = $('#search').val();
		this.titleService.setTitle(this.title);
		// $(document).on('hover', '[data-toggle="tooltip"]', function () { $(this).tooltip('show'); });
		$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });

		var offset = '0';
		const params = new HttpParams().set('offset', offset)
			.set('search_text', searchText);

		this.getList(params)

		this.signupService.getTypeSubType().subscribe((data) => {

			this.typeSubtype = data.data;
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				// this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		});

		this.signupService.getGroupTypeSubType().subscribe((data) => {

			this.typeSubtype1 = data.data;
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				// this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		});

		this.minDate = this.getCurrentDateObject();
	}

	// date format with object
	getCurrentDateObject() {
		return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
	}

	public handleAddressChange(address: any) {

		this.lat = address.geometry.location.lat();
		this.lng = address.geometry.location.lng();
		this.location = address.formatted_address;
		this.suburb = address.name;


	}

	preventSpace(event: KeyboardEvent): void {
		const inputElement = event.target as HTMLInputElement;
		const cursorPosition = inputElement.selectionStart;
		
		if (event.key === ' ' && cursorPosition === 0) {
		  event.preventDefault();
		}
	}

	//Price slider event .if slide than price range between 0-450 
	sliderEvent() {
		this.sliderEvents = true;
	}
	changeLowValue() {
		//this.lowValue = this.minvalue;
		//alert(this.lowValue)


		// lowValue = '2';

	}
	changeHighValue() {
		// this.hidghValue = thi;
	}

	setNewCeil(newCeil: number, newfloor: number): void {
		// Parse new values
		const parsedFloor = typeof newfloor === 'string' ? parseFloat(newfloor) : newfloor;
		const parsedCeil = typeof newCeil === 'string' ? parseFloat(newCeil) : newCeil;

		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, this.priceSlider);
		newOptions.ceil = parsedCeil;
		newOptions.floor = parsedFloor;
		this.priceSlider = newOptions; 
		this.hidghValue = parsedCeil;
	}

	// Method to handle changes in the slider value
	onSliderChange(newValue: number) {
		this.hidghValue = newValue;
	}
	
	getList(params) {

		showLoader();
		this.gigService.getGigList(params).subscribe((data) => {
			hideLoader();
			
			// this.changeLowValue();
			// this.changeHighValue();

			if (data.data_found == true) {

				this.dataFound = data.data_found;
				this.gigLists = data.data.final_data;

				const gig_list = this.gigLists.filter(e => e.price !== this.gigLists[0].price);
				this.ispricesame = gig_list.length;
				if (this.ispricesame == 0) {

					this.minvalue = 0;
				} else {

					this.minvalue = data.data.min_max_price.minPrice;
				}

				this.maxvalue = data.data.min_max_price.maxPrice;
				if (!this.setPriceValue) {
					this.setPriceValue = true;
					this.setNewCeil(this.maxvalue, this.minvalue);
				}
				//this.changeLowValue();
				// this.changeHighValue();
				this.total = data.data.total_records;
			}
			else {

				this.dataFound = data.data_found;
				this.gigLists = [];
				this.total = 0;
			}
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		})
		return;
	}

	getListNew(params) {
		showLoader();
		this.gigService.getGigList(params).subscribe((data) => {
			hideLoader();
			this.minvalue = data.data.min_max_price.minPrice;
			this.maxvalue = data.data.min_max_price.maxPrice;
			this.setNewCeil(this.maxvalue, this.minvalue)
			if (data.data_found == true) {
				this.dataFound = data.data_found;
				var newList = data.data.final_data;
				this.gigLists = this.gigLists.concat(newList);
				this.total = data.data.total_records;
			}
			else {
				this.dataFound = data.data_found;
				this.gigLists = [];
				this.total = 0;
			}
		}, err => {
			hideLoader();
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				//this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		})
		return;
	}

	// load more 
	loadMore() {
		this.offset = this.offset + this.limit;
		var flag = 1;
		this.applyFilter(this.offset, flag);
	}

	searchGig(searchText) {

		this.offset = 0;
		const params = new HttpParams().set('search_text', searchText);

		clearTimeout(this.timeout);
		var $this = this;
		this.timeout = setTimeout(function () {

			$this.getList(params);

		}, 1000);

	}

	//   searchDiscover(text) {
	//   var obj: any = {}
	//   obj.search = text;
	//   clearTimeout(this.timeout);
	//   var $this = this;
	//   this.timeout = setTimeout(function () {

	//     $this.discover(obj);

	//   }, 1000);

	// }

	/**
	 * Get all hirer gig
	 */
	allGig() {

		$('#startDate').val('');
		$('#endDate').val('');

		$("#allF").addClass('activeFilterIcon');


		$("#showAssignSelected").removeClass('activeFilterIcon');
		$("#showCompletedSelected").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');

		let searchText = $('#search').val();
		this.offset = 0;
		this.assigned = "";
		this.completed = "";
		const params = new HttpParams()
			.set('search_text', searchText);

		this.getList(params);
	}

	/**
	 * get assigned gig
	 */
	assignedGig() {
		$("#allF").removeClass('activeFilterIcon');

		$("#showAssignSelected").addClass('activeFilterIcon');
		$("#showCompletedSelected").removeClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');


		let searchText = $('#search').val();
		this.offset = 0;
		const params = new HttpParams().set('search_text', searchText)
			.set('assigned', '2');

		this.assigned = 2;
		this.getList(params);

	}

	/**
	 * get completed gig
	 */
	completedGig() {
		$("#allF").removeClass('activeFilterIcon');

		$("#showAssignSelected").removeClass('activeFilterIcon');
		$("#showCompletedSelected").addClass('activeFilterIcon');
		$("#activeicon").addClass('activeFilterIcon');


		let searchText = $('#search').val();
		this.offset = 0;
		const params = new HttpParams().set('search_text', searchText)
			.set('completed', '4');

		this.completed = 4;
		this.getList(params);

	}


	applyFilter123() {
		this.offset = 0;

		$("#FilterIconActive").addClass('activeFilterIcon');
		$("#activeicon").removeClass('activeFilterIcon');
		$("#allF").removeClass('activeFilterIcon');

		this.applyFilter();
	}

	/**
	 * Apply Filter
	 */
	// applyFilter(offset?) {
	async applyFilter(offset?, flag?) {


		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");

		let searchText = $('#search').val();
		var obj: any = {}
		obj.search = searchText;

		obj.date = $('#startDate').val();
		obj.endDate = $('#endDate').val();

		var startDate = new Date($('#startDate').val());
		var endDate = new Date($('#endDate').val());  
		if (startDate > endDate) {
	  
		  this.toastrService.showError('End date must be equal or greater than start date.', "Error");
		  return false;
		}


		let distanceMin = $('#distance_filter b span:first').text();
		let distanceMax = $('#distance_filter b').find('span:eq(1)').text();
		obj.distanceMin = distanceMin;
		obj.distanceMax = distanceMax;


		let location = $('#floatingInput').val();
		obj.location = location;

		if (this.assigned == 2) {

			obj.completed = '';
			obj.assigned = this.assigned;
		} else {
			obj.assigned = '';

		}

		if (this.completed == 4) {

			obj.assigned = '';
			obj.completed = this.completed;
		} else {

			obj.completed = '';
		}

		// obj.completed = this.completed;

		//price slider filter apply than this code will execute
		if (this.sliderEvents) {

			let priceFrom = $('#priceTo b span:first').text();
			let priceTo = $('#priceTo b').find('span:eq(1)').text();
			obj.priceFrom = priceFrom;
			obj.priceTo = priceTo;
		}

		let type = this.typeIdSelected.toString();
		let subType = this.subTypeIdSelected.toString();



		if (type != '' && subType == '') {

			obj.onlyType = type;
		} else {

			obj.type = type;
			obj.subtype = subType;
		}

		let grouptype = this.typeIdSelected1.toString();
		let groupsubtype = this.subTypeIdSelected1.toString();

		if (grouptype != '' && groupsubtype == '') {

			obj.onlyGtype = grouptype;
		} else {

			obj.grouptype = grouptype;
			obj.groupsubtype = groupsubtype;
		}



		if (!offset) {
			obj.offset = '0';
		} else {
			obj.offset = this.offset;
		}

		let paramObj = this.createParam(obj);

		// this.getList(paramObj);


		if (flag == 1) {
			this.getListNew(paramObj);
		} else {

			this.gigLists = this.getList(paramObj);
		}
	}

	/**
   * Create http param for filter gig
   * @param obj 
   * @returns 
   */
	createParam(obj) {
		var metaId = this.authService.getUserMetaId();

		let params = new HttpParams()
			.set('offset', ("offset" in obj) ? obj.offset : "0")
			.set('distanceMax', '')
			.set('search_text', ("search" in obj) ? obj.search : "")
			.set('assigned', ("assigned" in obj) ? obj.assigned : "")
			.set('completed', ("completed" in obj) ? obj.completed : "")
			.set('distanceMin', ("distanceMin" in obj) ? obj.distanceMin : "")
			.set('distanceMax', ("distanceMax" in obj) ? obj.distanceMax : "")
			.set('currentLat', this.lat)
			.set('currentLong', this.lng)
			.set('metaId', metaId)
			.set('rate', '')


			.set('type', ("type" in obj) ? obj.type : "")
			.set('subtype', ("subtype" in obj) ? obj.subtype : "")


			.set('grouptype', ("grouptype" in obj) ? obj.grouptype : "")
			.set('groupsubtype', ("groupsubtype" in obj) ? obj.groupsubtype : "")

			.set('onlyType', ("onlyType" in obj) ? obj.onlyType : "")
			.set('onlyGroupType', ("onlyGtype" in obj) ? obj.onlyGtype : "")


			.set('hot', '')
			// .set('price', ("price" in obj) ? obj.price : "")
			.set('all', '')
			.set('location', ("location" in obj) ? obj.location : "")
			.set('priceTo', ("priceTo" in obj) ? obj.priceTo : "")
			.set('rating', '')
			.set('priceFrom', ("priceFrom" in obj) ? obj.priceFrom : "")

			.set('date', ("date" in obj) ? obj.date : "")
			.set('endDate', ("endDate" in obj) ? obj.endDate : "")

		return params;
	}



	sideBarViewOpen() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.add("overflow");
		modalOverlay.classList.add("overlay-show");
		withcalc.classList.add("withcalc");
		//   if(screen.width<=767){
		//       withcalc100.classList.add("withcalc100");
		//   }else{
		//       withcalc.classList.add("withcalc");
		// }
	}
	sideBarViewClose() {
		let body = document.getElementsByTagName('body')[0];
		let withcalc = document.getElementById("sideBarView");
		let modalOverlay = document.getElementById("modalOverlay");

		body.classList.remove("overflow");
		withcalc.classList.remove("withcalc");
		modalOverlay.classList.remove("overlay-show");
	}

	sideBarReset() {

		location.reload();
	}

	onCheckboxChangeRole(type, event) {
		// if (event.target.checked) {
		// 	this.typeListData.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray.push(type);
		// 	this.typeIdSelected.push(type.categoryId);

		// } else {



		// 	for (var i = 0; i < this.typeListData.length; i++) {
		// 		if (this.typeListData[i].categoryId == type.categoryId) {
		// 			this.typeListData.splice(i, 1);


		// 		}
		// 	}

		// 	for (var i = 0; i < this.subTypeArray.length; i++) {
		// 		if (this.subTypeArray[i].categoryId == type.categoryId) {
		// 			this.subTypeArray.splice(i, 1);


		// 		}
		// 	}

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray.length; i++) {
		// 		if (this.subTypeArray[i] == type.categoryId) {
		// 			this.subTypeArray.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected.splice(itemindex, 1);
		// 	}

		// }

		if (event.target.checked == false) {
			this.typeIdSelected = [];
			this.typeListData = [];
			this.subTypeArray = [];
			this.subTypeIdSelected = [];
			this.subTypeSelected = [];
		}
	
		if (event.target.checked) {
			const checkTypeData = this.typeListData.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected = [];
				this.subTypeIdSelected = [];
				// this.subTypeSelected = [];
				this.typeListData = [];
				this.typeListData.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.typeIdSelected.indexOf(type.categoryId);
			
			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeSelected = [];
				this.subTypeArray = [];
				this.typeIdSelected = [];
				this.subTypeArray.push(type);
				this.typeIdSelected.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData.length; i++) {
				if (this.typeListData[i].categoryId == type.categoryId) {
					this.typeListData.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray.length; i++) {
				if (this.subTypeArray[i] == type.categoryId) {
					this.subTypeArray.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected.splice(itemindex, 1);
			}
		}
	}


	removeType(index) {
		// console.log(index);
		let parent_id = this.typeListData[index].categoryId;
		var selectedtype = this.typeListData[index].categoryId;
		this.typeListData.splice(index, 1);
		const itemindex = this.typeIdSelected.indexOf(selectedtype);

		if (itemindex > -1) {
			this.typeIdSelected.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected = this.subTypeSelected.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray.length; i++) {
			if (this.subTypeArray[i].categoryId == parent_id) {

				this.subTypeArray[i].subType.forEach(element => {

					if (this.subTypeIdSelected.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected.indexOf(element.categoryId);
						this.subTypeIdSelected.splice(idexitem, 1);

					}
				});
				this.subTypeArray.splice(i, 1);
			}
		}


	}


	onCheckboxChangesubType(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected.length; i++) {
				if (this.subTypeSelected[i].categoryId == subType.categoryId) {
					this.subTypeSelected.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}

	removeSubType(index) {
		let selectedSubType = this.subTypeSelected[index].categoryId;
		this.subTypeSelected.splice(index, 1);
		const itemindex = this.subTypeIdSelected.indexOf(selectedSubType);
		if (index > -1) {
			this.subTypeIdSelected.splice(itemindex, 1);
		}
	}



	// code for group type filter 

	removeType1(index) {
		let parent_id = this.typeListData1[index].categoryId;
		var selectedtype1 = this.typeListData1[index].categoryId;
		this.typeListData1.splice(index, 1);
		const itemindex = this.typeIdSelected1.indexOf(selectedtype1);

		if (itemindex > -1) {
			this.typeIdSelected1.splice(itemindex, 1);
		}

		//remove subtype

		this.subTypeSelected1 = this.subTypeSelected1.filter(function (obj) {
			return obj.parentId !== parent_id;
		});


		for (var i = 0; i < this.subTypeArray1.length; i++) {
			if (this.subTypeArray1[i].categoryId == parent_id) {

				this.subTypeArray1[i].subType.forEach(element => {

					if (this.subTypeIdSelected1.includes(element.categoryId)) {

						let idexitem = this.subTypeIdSelected1.indexOf(element.categoryId);
						this.subTypeIdSelected1.splice(idexitem, 1);

					}
				});
				this.subTypeArray1.splice(i, 1);
			}
		}


	}


	onCheckboxChangeRole1(type, event) {

		// if (event.target.checked) {
		// 	this.typeListData1.push({ categoryId: type.categoryId, name: type.name, parent_id: type.parent_id })

		// 	this.subTypeArray1.push(type);
		// 	this.typeIdSelected1.push(type.categoryId);

		// } else {



		// 	for (var i = 0; i < this.typeListData1.length; i++) {
		// 		if (this.typeListData1[i].categoryId == type.categoryId) {
		// 			this.typeListData1.splice(i, 1);


		// 		}
		// 	}

		// 	for (var i = 0; i < this.subTypeArray1.length; i++) {
		// 		if (this.subTypeArray1[i].categoryId == type.categoryId) {
		// 			this.subTypeArray1.splice(i, 1);


		// 		}
		// 	}

		// 	// console.log( 'subcat',this.subTypeArray)

		// 	// this.removeType(type);

		// 	//Remove subtype object from array
		// 	for (i = 0; i < this.subTypeArray1.length; i++) {
		// 		if (this.subTypeArray1[i] == type.categoryId) {
		// 			this.subTypeArray1.splice(i, 1);
		// 		}
		// 	}

		// 	//remove typeid from selected type id array 
		// 	const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
		// 	if (itemindex > -1) {
		// 		this.typeIdSelected1.splice(itemindex, 1);
		// 	}

		// }

		if (event.target.checked == false) {
			this.typeIdSelected1 = [];
			this.typeListData1 = [];
			this.subTypeArray1 = [];
			this.subTypeIdSelected1 = [];
			this.subTypeSelected1 = [];
		}

		if (event.target.checked) {
			const checkTypeData = this.typeListData1.map((el)=> el.categoryId == type.categoryId);
			
			if (!checkTypeData[0]) {
				this.typeIdSelected1 = [];
				this.subTypeSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.typeListData1 = [];
				this.typeListData1.push({
					categoryId: type.categoryId,
					name: type.name,
					parent_id: type.parent_id,
				});
			}
			const checkSubTypeData = this.subTypeArray1.map((el)=> el.categoryId == type.categoryId);
			const checkSubTypeSelectedData = this.subTypeIdSelected1.indexOf(type.categoryId);

			if (!checkSubTypeData[0] && checkSubTypeSelectedData == -1) {
				this.subTypeArray1 = [];
				this.typeIdSelected1 = [];
				this.subTypeIdSelected1 = [];
				this.subTypeArray1.push(type);
				this.typeIdSelected1.push(type.categoryId);
				// this.subTypeIdSelected1.push(type.categoryId);
			}
		} else {
			for (var i = 0; i < this.typeListData1.length; i++) {
				if (this.typeListData1[i].categoryId == type.categoryId) {
					this.typeListData1.splice(i, 1);
				}
			}

			//Remove subtype object from array
			for (i = 0; i < this.subTypeArray1.length; i++) {
				if (this.subTypeArray1[i] == type.categoryId) {
					this.subTypeArray1.splice(i, 1);
				}
			}

			//remove typeid from selected type id array
			const itemindex = this.typeIdSelected1.indexOf(type.categoryId);
			if (itemindex > -1) {
				this.typeIdSelected1.splice(itemindex, 1);
			}
		}
	}



	onCheckboxChangesubType1(subType, event) {

		if (event.target.checked) {
			this.subTypeSelected1.push({ categoryId: subType.categoryId, name: subType.name, parentId: subType.parent_id })
			this.subTypeIdSelected1.push(subType.categoryId);
		} else {
			for (var i = 0; i < this.subTypeSelected1.length; i++) {
				if (this.subTypeSelected1[i].categoryId == subType.categoryId) {
					this.subTypeSelected1.splice(i, 1);
					//this.checkUncheckRole(option.id,false);
				}
			}

			//Remove selected sub type id
			const index = this.subTypeIdSelected1.indexOf(subType.categoryId);
			if (index > -1) {
				this.subTypeIdSelected1.splice(index, 1);
			}
		}

		// if (this.subTypeSelected.length == 0) {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "" });
		// } else {
		// 	this.completeProfileForm.patchValue({ subTypeBlankCheck: "1" });
		// }
	}


	removeSubType1(index) {
		let selectedSubType1 = this.subTypeSelected1[index].categoryId;
		this.subTypeSelected1.splice(index, 1);
		const itemindex = this.subTypeIdSelected1.indexOf(selectedSubType1);
		if (index > -1) {
			this.subTypeIdSelected1.splice(itemindex, 1);
		}
	}


	addCount(id) {
		const formData = new FormData();

		formData.append("adId", id);
		this.gigService.addCount(formData).subscribe((data) => {

		}, err => {
			if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			} else {
				this.toastrService.showError(err.error.message, 'Error');
			}
		})
	}

}
