import { Component, OnInit, ViewChild } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";

import { StripeService, StripeCardComponent } from "ngx-stripe";
import {
	StripeCardElementOptions,
	StripeElementsOptions
} from "@stripe/stripe-js";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { PaymentService } from "src/app/services/payment.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
	AuthService
} from "src/app/services/auth.service";


declare var $: any;
declare const showLoader: any;
declare const hideLoader: any;

@Component({
	selector: "app-settings-hirer",
	templateUrl: "./settings-hirer.component.html",
	styleUrls: ["./settings-hirer.component.css"]
})
export class SettingsHirerComponent implements OnInit {
	@ViewChild(StripeCardComponent) card: StripeCardComponent;

	paymentForm: FormGroup;
	bankAccountForm: FormGroup;
	bankAccountFormUpdate: FormGroup;
	stripeCardValid: boolean = false;
	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: "#666EE8",
				color: "#31325F",
				fontWeight: "300",
				fontSize: "15px",
				"::placeholder": {
					color: "#ccc"
				}
			}
		}
	};

	cardLists = [];
	accountDetails;

	get validForm() {
		return this.paymentForm.valid && this.stripeCardValid;
	}

	elementsOptions: StripeElementsOptions = {
		locale: "en"
	};
	stripAccount;
	bankDetails;
	details;
	accountHolderName;
	lastFour;
	updateDetails;
	rotingNumber;
	cardId;

	constructor(
		private fb: FormBuilder,
		private stripeService: StripeService,
		private toastrService: ToastrNotificationService,
		private payService: PaymentService,
		private router: Router,
		private authService: AuthService,

	) {}

	ngOnInit(): void {
		$('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

		this.paymentForm = this.fb.group({
			name: ["", [Validators.required]]
		});

		this.bankAccountForm = this.fb.group({
			accountName: ["", Validators.required],
			bsdCode: ["", Validators.required],
			accountNo: ["", Validators.required]
			// address:['',Validators.required]
		});

		this.cardList();
		this.accountDetail();
		this.updateBankvalidation()

		// alert($('#accountHolderName').val());

	}

	onChange({ type, event }) {
		if (type === "change") {
			this.stripeCardValid = event.complete;
		}
	}

	buy() {
		showLoader();

		this.stripeService
			.createToken(this.card.element, { name: this.paymentForm.value.name })
			.subscribe(result => {
				if (result.token) {
					this.card.element.clear();

					$("#addCard").modal("hide");

					this.addCard(result.token.id, result.token.card.id);
				} else if (result.error) {
					hideLoader();
					this.toastrService.showError(result.error.message, "Error");
				}
			});
	}

	addCard(tokenId, customerId) {
		var formData = new FormData();
		formData.append("sourceId", tokenId);
		formData.append("stripeCustomerId", this.payService.getStripeCusID());

		this.payService.addCard(formData).subscribe(
			res => {
				hideLoader();
				this.cardList();
				if (res.status == 'fail') {
					this.toastrService.showError(res.message, 'Error');
				}else {
					this.toastrService.showSuccess(res.message,'Success');
				}
			},
			err => {
				if(err.error.status=='fail' && err.error.message=='Invalid token'){
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				}else{
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	cardList() {
		let stripeCustId = this.payService.getStripeCusID();

		this.payService.getCardList(stripeCustId).subscribe(
			res => {
				this.cardLists = res.data;
			},
			err => {
				if(err.error.status=='fail' && err.error.message=='Invalid token'){
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				}else{
					this.toastrService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	accountDetail() {
		this.stripAccount = this.payService.getStripeAccountID();

		this.bankDetails = this.payService
			.accountDetails(this.stripAccount)
			.subscribe(
				res => {
					this.details = res.data.external_accounts.data[0];
					if (this.details) {
						this.accountHolderName = this.details.account_holder_name;
						this.lastFour = this.details.last4;
						this.rotingNumber = this.details.routing_number;
						hideLoader();
						this.updateBankvalidation()


					}
				},
				err => {
					if(err.error.status=='fail' && err.error.message=='Invalid token'){
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');
	
					}else{
						this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
	}
	updateBankvalidation(){

		this.bankAccountFormUpdate = this.fb.group({
			accountName: [this.accountHolderName, Validators.required],
			accountNo: ['xxxxx'+this.lastFour, Validators.required],
			bsdCode: [this.rotingNumber, Validators.required],
		});
	}

	resetBankForm(){

		this.bankAccountForm.reset();
		$('#addBank').modal('hide');
	  }

	addBankAccount() {
		if (this.bankAccountForm.invalid) {
			//console.log('inside submit account')
			this.validateAllFormFields(this.bankAccountForm);
			return false;
		} else {
			const formData = new FormData();

			formData.append("holderName", this.bankAccountForm.value.accountName);
			formData.append("routingNumber", this.bankAccountForm.value.bsdCode);
			formData.append("accountNo", this.bankAccountForm.value.accountNo);
			// formData.append('address', this.bankAccountForm.value.address)
			// formData.append('country', this.bankAccountForm.value.country)
			this.payService.addBankAccount(formData).subscribe(
				res => {
					showLoader();

					if(res.status == 'fail'){
						hideLoader();
				
						this.toastrService.showError(res.message, "Error");
				
					  }else{
						hideLoader();
				
						this.toastrService.showSuccess("Added Sucessfully", "Success");
						this.resetBankForm();
						this.accountDetail();
				
						showLoader();
				
					  }
					
					//this.cardList();
				},
				err => {
					// this.toastrService.showError(err.error.message, "Error");
					// console.log(err.error.message);
					if(err.error.status=='fail' && err.error.message=='Invalid token'){
						this.authService.checkValidToken(err.error);
						this.toastrService.showError('Session Expired', 'Error');
	
					}else{
					this.toastrService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}

	checkBankAccount() {
		//"ba_1J2GezQbwiQe07U7br4qGXk2"
		if (this.payService.getStripeBankId()) {
			$("#addBank").modal("show");
		} else {
			showLoader();
			var formData = new FormData();
			formData.append("accountId", this.payService.getStripeAccountID());

			this.payService.verifyLinkAccount(formData).subscribe(res => {
				hideLoader();
				if (res.data.for == 3) {
					var linkUrl = res.data.linkData.url;
					window.location.href = linkUrl;
				}
				if (res.data.for == 2) {
					hideLoader();
					if (confirm("Please add account ")) {
						$("#addBank").modal("show");
					}
				}
			});
		}
	}

	verifyAccount() {
		var formData = new FormData();
		formData.append("accountId", this.payService.getStripeAccountID());

		this.payService.verifyLinkAccount(formData).subscribe(res => {
			//https://connect.stripe.com/setup/c/e4RMhym10ScJ
			//https://giglooker.com/dev/livloud/stripe-success
			// console.log(res);
		},err =>{
			if(err.error.status=='fail' && err.error.message=='Invalid token'){
				this.authService.checkValidToken(err.error);
				this.toastrService.showError('Session Expired', 'Error');

			}else{
				this.toastrService.showError(err.error.message, 'Error');
			}
		});
	}

	updateBankAccount() {
		
		if (this.bankAccountFormUpdate.invalid) {
			this.validateAllFormFields(this.bankAccountFormUpdate);
			return false;
		}

		this.stripAccount = this.payService.getStripeAccountID();
		const formData = new FormData();
		formData.append("holderName", this.bankAccountFormUpdate.value.accountName);
		formData.append("routingNumber", this.bankAccountFormUpdate.value.bsdCode);
		formData.append("accountNo", this.bankAccountFormUpdate.value.accountNo);
		formData.append("accountId", this.stripAccount);
		showLoader();
		this.payService.updateBankAccount(formData).subscribe(
			res => {
			if(res.status == 'fail'){
				hideLoader();

				this.toastrService.showError(res.message, "Error");

			}else{
				hideLoader();

				this.toastrService.showSuccess("Update Sucessfully", "Success");
				this.accountDetail();
				//this.bankAccountFormUpdate.reset();

				$("#updateBank").modal("hide");
				showLoader();

			}

			},
			err => {
				if(err.error.status=='fail' && err.error.message=='Invalid token'){
					this.authService.checkValidToken(err.error);
					this.toastrService.showError('Session Expired', 'Error');

				}else{
					this.toastrService.showError(err.error.message, 'Error');
				}

			}
		);
	}



	confimpopup(id){
		this.cardId = id;
		$("#confirmModal").modal("show");
	  
	  
	  }
		removeCard(){
		  var id =   this.cardId;
	  
		  const formData = new FormData();
			  formData.append("card_id", id);
		  showLoader();
		  this.payService.removeCard(formData).subscribe(
			res => {
			  if(res.status == 'fail'){
				hideLoader();
		
				this.toastrService.showError(res.message, "Error");
		
			  }else{
				hideLoader();
				$("#confirmModal").modal("hide");
	  
				this.toastrService.showSuccess(res.message, "Success");
				this.cardList();
			  }
			})
	  
		}

	contactNumber(){
		this.router.navigate(['/contactNumberUpdate']); // navigate to other page
	}

	termsAndConditions(){
		this.router.navigate(['/termsAndConditions']); // navigate to other page
	}

	privacyAndPolicy(){
		this.router.navigate(['/privacyAndPolicy']); // navigate to other page
	}

	faq(){
	this.router.navigate(['/faq']); // navigate to other page

	}

	help(){
		this.router.navigate(['/help']); // navigate to other page
	
	}

	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});

		return false;
	}
}
