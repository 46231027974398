// import { Component, OnInit } from '@angular/core';

// declare var $:any

// @Component({
//   selector: 'app-discover-group-details-hirer',
//   templateUrl: './discover-group-details-hirer.component.html',
//   styleUrls: ['./discover-group-details-hirer.component.css']
// })
// export class DiscoverGroupDetailsHirerComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//     $('#imageGallery').lightGallery({
//       thumbnail:true,
//       selector: '.addedImg',
//       download: false
//     }); 
//     $('#galleryVideos').lightGallery({
//       selector: '.addedImg'
//     }); 
//   }

// }
import { Component, OnInit, AfterViewChecked, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GigService } from "src/app/services/gig-service";
import { ToastrNotificationService } from "../../services/toastr-notification.service";
import { GroupRequest } from "src/app/services/group-request";
import { PaymentService } from "src/app/services/payment.service";
import { StripeService, StripeCardComponent } from "ngx-stripe";

import {
	StripeCardElementOptions,
	StripeElementsOptions,
} from "@stripe/stripe-js";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";


declare var $: any;
declare var $: any
// declare const toastrSuccess: any;
// declare const toastrError: any;
declare const showLoader: any;
declare const hideLoader: any;


@Component({
  selector: 'app-discover-group-details-hirer',
  templateUrl: './discover-group-details-hirer.component.html',
  styleUrls: ['./discover-group-details-hirer.component.css']
})
export class DiscoverGroupDetailsHirerComponent implements OnInit {

	@ViewChild(StripeCardComponent) card: StripeCardComponent;
	paymentForm: FormGroup;




	title = "Livlouder Profile";
	userMetaId;
	data;
	experience = [];
	type = [];
	subtype = [];
	socialMedia = [];
	user_type;
	switched_user;
	current_user;
	gigId;
	groupId;
	groupMember = [];
	groupMemberCount;
	metaId;
	timeout;
	discoverList;
	dataFound;
	total;
	cardLists;
	getUserType;
	stripeCardValid: boolean = false;
	groupAdminMetaId;
	source = "";



	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: "#666EE8",
				color: "#31325F",
				fontWeight: "300",
				fontSize: "15px",
				"::placeholder": {
					color: "#ccc",
				},
			},
		},
	};
	elementsOptions: StripeElementsOptions = {
		locale: "en",
	};
	get validForm() {
		return this.paymentForm.valid && this.stripeCardValid;
	}


	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private gigService: GigService,
		private toastService: ToastrNotificationService,
		private titleService: Title,
		private authService: AuthService,
		private groupRequest: GroupRequest,
		private payService: PaymentService,
		private stripeService: StripeService,
		private fb: FormBuilder,




	) { }

	ngOnInit(): void {
		this.cardList();
		// alert('hiiiii');
		this.paymentForm = this.fb.group({
			name: ["", [Validators.required]],
		});

		// alert(localStorage.getItem('metaId'));
		// this.authService.setUserMetaId(localStorage.getItem('metaId'));
		//this.titleService.setTitle(this.title);
		this.titleService.setTitle(this.title);
		this.authService.setUserMetaId("1");
		this.metaId = this.authService.getUserMetaId();
		this.activatedRoute.paramMap.subscribe(param => {
			this.gigId = param.get("id");
		});

		this.groupRequest.getGroupDetail(this.gigId).subscribe(res => {
			this.data = res.data;
			this.groupAdminMetaId = res.data.groupAdminMetaId;
			this.getUserType = res.data.userType;

			if (this.data.experience) {
				this.experience = JSON.parse(this.data.experience);
			}
			this.data.rating_review.data.forEach(element => {
				element.agotime = this.diffHours(
					element.created_date,
					element.current_time
				);
			}, err => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			});




			if (this.data.type) {
				this.type = this.data.type.split("|");
			}
			if (this.data.subType) {
				this.subtype = this.data.subType.split("|");
			}

			if (this.data.groupMember.count > 0) {
				this.groupMemberCount = this.data.groupMember.count
				this.groupMember = this.data.groupMember.data;
			}
			this.socialMedia = this.data.social_media;
			this.userMetaId = this.data.userMetaId;
			this.groupId = this.data.groupId;
		},
			err => {
				this.authService.checkValidToken(err.error);

				//this.toastService.showError(err.error.message, "Error");
			});
	}

	ngAfterViewChecked() {
		$("#imageGallery").lightGallery({
			thumbnail: true,
			selector: ".addedImg",
			download: false
		});
		$("#galleryVideos").lightGallery({
			selector: ".addedImg"
		});
	}

	diffHours(t1, t2) {
		var date1 = new Date(t1).getTime() / 1000;

		var date2 = new Date(t2).getTime() / 1000;

		var difference = Math.abs(date2 - date1);
		var diffHr = difference / 60 / 60;
		var daysDiff = difference / 60 / 60 / 24;
		if (isNaN(daysDiff)) {
			return "N/A";
		}
		if (daysDiff < 1) {
			if (diffHr < 1) {
				difference = difference / 60;
				var hourDiff = Math.floor(difference) + " Min Ago";
			} else {
				difference = difference / 60 / 60;
				var hourDiff = Math.floor(difference) + " Hour Ago";
			}
		} else {
			var hourDiff = Math.floor(daysDiff) + " Days Ago";
		}

		return hourDiff;
	}



	delete_group_pop_up() {
		$("#delete_group1").modal("show");
		return false;
	}
	leave_group_pop_up() {
		$("#leave_group").modal("show");
		return false;
	}



	leave_group() {
		const formData = new FormData();
		formData.append("groupId", this.groupId);
		formData.append("metaId", this.metaId);

		if (this.groupId) {
			showLoader();
			this.groupRequest.leaveGroup(formData).subscribe(
				res => {
					$("#leave_group").modal("hide");
					hideLoader();
					this.toastService.showSuccess(res.message, "Success");

					if (this.router.url == "/groupDetails/" + this.userMetaId) {
						this.router.navigate(["/groupRequest"]);
					} else {
						this.router.navigate(["/groups"]);
					}
				},
				err => {
					hideLoader();
					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}
	remove_Member(){
		alert('hello');
	}


	acceptrejectReq(status, groupRequestId) {
		var formData = new FormData();
		formData.append("metaId", this.metaId);
		formData.append("groupRequestId", groupRequestId);
		formData.append("status", status);

		showLoader();
		this.groupRequest.acceptRejectRequest(formData).subscribe(
			res => {
				hideLoader();
				this.toastService.showSuccess(res.message, "Success");
				this.timeout = setTimeout(function () {
					this.router.navigate(["/groupRequest"]);

				}, 1500);
			},
			err => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}


	delete_group() {
		const formData = new FormData();
		formData.append("groupId", this.groupId);
		formData.append("userMetaId", this.userMetaId);

		if (this.groupId) {
			showLoader();
			this.groupRequest.deleteGroup(formData).subscribe(
				res => {
					$("#delete_group1").modal("hide");
					hideLoader();
					this.toastService.showSuccess(res.message, "Success");

					if (this.router.url == "/groupDetails/" + this.userMetaId) {
						this.router.navigate(["/groupRequest"]);
					} else {
						this.router.navigate(["/groups"]);
					}
				},
				err => {
					hideLoader();
					$("#delete_group1").modal("hide");

					if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
						this.authService.checkValidToken(err.error);
						this.toastService.showError('Session Expired', 'Error');

					} else {
						this.toastService.showError(err.error.message, 'Error');
					}
				}
			);
		}
	}



	// book me Flow 
	myDiscoverList() {
		
		this.gigService.getPrivateGig(this.userMetaId).subscribe(res => {
			hideLoader();

			if (res.message == "Found") {

				this.discoverList = res.data.gig;
				this.total = res.data.total_records;
				this.dataFound = true;
			} else {

				this.discoverList = [];
				this.dataFound = false;
				this.total = 0;
			}
		}, err => {
			$('#bookMe').modal('hide');
			// hideLoader();


			// if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
			// 	this.authService.checkValidToken(err.error);
			// 	this.toastrService.showError('Session Expired', 'Error');

			// } else {
			// 	this.toastrService.showError(err.error.message, 'Error');
			// }
		});
	}

	checkGigPopup() {

		var gigList = [];
		$('input[name="groupChoose"]:checked').each(function () {
			gigList.push(this.value);
		});

		if (gigList.length == 0) {

			this.toastService.showError("Please choose a gig first", "Error");
		} else {

			$('#bookMeRequest').modal('show');
		}

	}


	getAll() {

		$('#bookMeRequest').modal('hide');
		$('#addCard').modal('show');
	}


	// alert(this.)
	confirmRequest() {

		var gigList = [];
		$('input[name="groupChoose"]:checked').each(function () {
			gigList.push(this.value);
		});

		this.source = $('input[name="radio1"]:checked').val();
		this.authService.setUserMetaId(2);

		var formData = new FormData();
		formData.append('gigId', gigList.toString())
		formData.append('hirerMetaId', this.authService.getUserMetaId())
		formData.append('metaId', this.userMetaId)
		formData.append('userId', this.data.userId)
		formData.append('source', this.source)


		// console.log('what',formData);return false;
		showLoader();
		this.gigService.gigRequest(formData).subscribe(res => {
			// console.log('formData',this.activatedRoute.snapshot.params.id);return false;
			// console.log('source',res);
			hideLoader();

			// {status: "success", message: "Gig request has sent successfully"}
			$('#bookMe').modal('hide');
			this.toastService.showSuccess(res.message, 'Success');
			// var idd = this.activatedRoute.snapshot.params.id;
			// this.router.navigate(['discoverHirer/detail/', this.userMetaId]);
		}, err => {
			hideLoader();
			this.toastService.showError(err.error.message, 'Error');

		})
	}

	buy() {
		showLoader();

		this.stripeService
			.createToken(this.card.element, { name: this.paymentForm.value.name })
			.subscribe((result) => {

				if (result.token) {
					this.resetCardForm();

					this.cardList();

					this.addCard(result.token.id, result.token.card.id);

					// console.log(result.token);
					this.source = result.token.id;

				} else if (result.error) {
					hideLoader();
					this.toastService.showError(result.error.message, "Error");
					// console.log(result.error.message);
				}
			});
	}
	addCard(tokenId, customerId) {
		var formData = new FormData();
		formData.append("sourceId", tokenId);
		formData.append("stripeCustomerId", this.payService.getStripeCusID());

		this.payService.addCard(formData).subscribe(
			(res) => {
				hideLoader();
				// console.log(res);
				this.cardList();
				// $('#addCard').modal('hide');
				if (res.status == 'fail') {
					this.toastService.showError(res.message, 'Error');
				}else {
					this.toastService.showSuccess(res.message,'Success');
				}
			},
			(err) => {
				hideLoader();
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					this.toastService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}
	//  card list 
	cardList() {
		let stripeCustId = this.payService.getStripeCusID();

		this.payService.getCardList(stripeCustId).subscribe(
			(res) => {
				this.cardLists = res.data;
			},
			(err) => {
				if (err.error.status == 'fail' && err.error.message == 'Invalid token') {
					this.authService.checkValidToken(err.error);
					//this.toastrService.showError('Session Expired', 'Error');

				} else {
					this.toastService.showError(err.error.message, 'Error');
				}
			}
		);
	}

	onChange({ type, event }) {
		// console.log("type", type);
		if (type === "change") {
			this.stripeCardValid = event.complete;
		}
	}
	// confm payment 
	resetCardForm() {

		this.card.element.clear();
	}

}
